import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import SubappHeader from "../../_app/components/SubappHeader";

import { Link } from 'react-router-dom';
import { withRouter, RouteComponentProps } from "react-router";
import { get, first, isEmpty, isArray, isString } from 'lodash';
import { parse as parseQueryString } from 'query-string';
import { APP_ROUTES } from '../../_app/constants';
import { css } from "emotion";

type Props = RouteComponentProps & {};

type State = {
  purchaseId: string,
};

export class StripePurchaseCompletedPage extends Component<Props, State> {

  static getPurchaseId(thing: string | string[] | undefined): string {
    if (isArray(thing) && !isEmpty(thing)) {
      return first(thing) || '';
    }
    if (isString(thing)) {
      return thing;
    }
    return '';
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      purchaseId: '',
    };
  }

  componentDidMount() {
    const queryString = get(this.props, 'location.search', '');
    const query = parseQueryString(queryString);
    this.setState({
      ...this.state,
      purchaseId: StripePurchaseCompletedPage.getPurchaseId(query.purchase!),
    });
  }

  renderPurchase() {
    const { purchaseId } = this.state;
    return <div>Purchase ID: {purchaseId}</div>;
  }

  renderNoPurchaseId() {
    return <div>
      I'm sorry, but no purchase ID was provided in the request.
      If you are looking for a previous transaction, please go to the
      <Link to={APP_ROUTES.credits.history}>Transaction History</Link> page.
    </div>;
  }

  render() {
    const { purchaseId } = this.state;
    return <div className={`${cn.root}`}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <SubappHeader className={cn.sectionHeadline}>
            Credit Purchasing History
          </SubappHeader>
        </Grid>
        <Grid item xs={12}>
          {
            isEmpty(purchaseId) ?
              this.renderNoPurchaseId() :
              this.renderPurchase()
          }
          <Link to={APP_ROUTES.credits.index}>Go buy more credits!</Link>
        </Grid>
      </Grid>
    </div>;
  }
}

// classes
const cn = {
  root: css`
    margin-bottom: 130px;
  `,
  sectionHeadline: css`
    padding-bottom: 0.6rem;
  `,
};

export default withRouter(StripePurchaseCompletedPage);
