import React from 'react';
import { css } from 'react-emotion';
import { colors, elements } from '@peachjar/components';
import { BREAKPOINTS } from '../constants';

const { Note } = elements.typography;

const SpecialLink = ({ hrefURI, children }) => (
  <div className={classNames.linkBox}>
    <a
      href={hrefURI}
      className={classNames.betaLink}
      data-testid="click-exit-beta"
    >
      <Note className={classNames.betaText}>{children}</Note>
    </a>
  </div>
);

const classNames = {
  linkBox: css`
    padding: 1rem 0.2rem;
    cursor: pointer;

    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      padding: 1rem;
    }
  `,
  betaLink: css`
    display: flex;
    align-items: center;
    margin-left: auto;
    color: ${colors.prussian};
    &:hover {
      color: ${colors.jungle};
      text-decoration: underline;
    }
  `,
  betaText: css`
    margin-left: 0.5rem;
  `,
};

export default SpecialLink;
