import React from 'react';
import { elements } from '@peachjar/components';

const { Note } = elements.typography;

const SchoolFinderLegend = () => (
  <>
    <div className="layout-row layout-align-center-center p-1">
      <svg width="28px" height="36px" viewBox="0" version="1.1">
        <defs>
          <path
            d="M112,157.23348 C112,163.40859 120.23348,172.524229 120.23348,172.524229 C120.23348,172.524229 128.46696,163.40859 128.46696,157.23348 C128.46696,152.681542 124.785419,149 120.23348,149 C115.681542,149 112,152.681542 112,157.23348 Z"
            id="path-1"
          ></path>
          <filter
            x="-54.7%"
            y="-29.8%"
            width="209.3%"
            height="180.5%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
        </defs>
        <g
          id="Org-Portal---Mary"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="School_finder_Pins"
            transform="translate(-106.000000, -145.000000)"
          >
            <g id="pin_available">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-2)"
                href="#path-1"
              ></use>
              <path
                stroke="#FFFFFF"
                strokeWidth="2"
                d="M111,157.23348 C111,152.129257 115.129257,148 120.23348,148 C125.337703,148 129.46696,152.129257 129.46696,157.23348 C129.46696,158.80662 129.003281,160.571915 128.151609,162.504905 C127.413045,164.181182 126.39932,165.945407 125.179185,167.753822 C124.26088,169.114881 123.278716,170.423264 122.29642,171.633593 C121.952352,172.057534 121.632504,172.43933 121.344871,172.77319 C121.170778,172.975263 121.045012,173.117647 120.975583,173.194515 L120.23348,174.016129 L119.491378,173.194515 C119.421948,173.117647 119.296183,172.975263 119.122089,172.77319 C118.834456,172.43933 118.514608,172.057534 118.170541,171.633593 C117.188245,170.423264 116.20608,169.114881 115.287776,167.753822 C114.06764,165.945407 113.053915,164.181182 112.315351,162.504905 C111.46368,160.571915 111,158.80662 111,157.23348 Z"
                fill="#757D99"
                fillRule="evenodd"
              ></path>
            </g>
          </g>
        </g>
      </svg>
      <Note muted>Available</Note>
    </div>
    <div className="layout-row layout-align-center-center p-1">
      <svg width="28px" height="36px" viewBox="0" version="1.1">
        <defs>
          <path
            d="M403,157.4 C403,163.7 411,173 411,173 C411,173 419,163.7 419,157.4 C419,152.756 415.422857,149 411,149 C406.577143,149 403,152.756 403,157.4 Z"
            id="path-1"
          ></path>
          <filter
            x="-56.2%"
            y="-29.2%"
            width="212.5%"
            height="179.2%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
        </defs>
        <g
          id="Org-Portal---Mary"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="School_finder_Pins"
            transform="translate(-397.000000, -145.000000)"
          >
            <g id="pin_selected">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-2)"
                href="#path-1"
              ></use>
              <path
                stroke="#FFFFFF"
                stroke-width="2"
                d="M402,157.4 C402,152.214333 406.013651,148 411,148 C415.986349,148 420,152.214333 420,157.4 C420,158.994538 419.55137,160.787938 418.726536,162.753619 C418.009964,164.461302 417.025906,166.259525 415.841288,168.103087 C414.949394,169.491096 413.995376,170.825529 413.041201,172.059994 C412.70696,172.492418 412.396244,172.881859 412.116823,173.222403 C411.947704,173.428517 411.825537,173.573743 411.758105,173.652133 L411,174.53343 L410.241895,173.652133 C410.174463,173.573743 410.052296,173.428517 409.883177,173.222403 C409.603756,172.881859 409.29304,172.492418 408.958799,172.059994 C408.004624,170.825529 407.050606,169.491096 406.158712,168.103087 C404.974094,166.259525 403.990036,164.461302 403.273464,162.753619 C402.44863,160.787938 402,158.994538 402,157.4 Z"
                fill="#61C16C"
                fillRule="evenodd"
              ></path>
            </g>
          </g>
        </g>
      </svg>
      <Note muted>Selected</Note>
    </div>
  </>
);

export default SchoolFinderLegend;
