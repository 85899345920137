import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { colors, elements } from '@peachjar/components';
import { get, debounce } from 'lodash';
import DealsContext from '../../../../../../../../../_app/context/DealsContext';
import { School, MapVariables } from '../../types';
import { AudienceFilterType } from '../Filters/AudienceFilter';
import { GradeFilterType } from '../Filters/GradeFilter';
import EmptyListMessage from './EmptyListMessage';

const { Paragraph, Note } = elements.typography;

const { silver, leaf, slate } = colors;

type Props = {
  areFiltersSetToDefault: boolean;
  areInitialMapBoundsSet: boolean;
  totalHiddenSchools: number;
  totalNearbySchools: number;
  listItems: any[];
  unfilteredSchools: School[];
  checkboxSelections: any[];
  toggleCheckbox: (
    audienceListItem: School | any,
    setStateFn: (newCheckboxSelections: Record<any, any>) => void,
    checkboxSelections: School[]
  ) => School[];
  onSelectionUpdated: (checkboxSelections: Array<Record<string, any>>) => void;
  setCheckboxSelections: () => void;
  setGradeFilter: (filter: AudienceFilterType) => void;
  setAudienceFilter: (filter: GradeFilterType) => void;
  setMapVariables: (mapVariables: MapVariables) => void;
  setSearchValue: (value: string) => void;
  setInputValue: (value: string) => void;
  setPreviousRedoSearchZoom: (zoom: number | null) => void;
  map: any;
  classes;
};

const styles = theme => ({
  root: {
    width: 'auto',
    flex: '1 1 auto',
    height: '300px',
    maxHeight: '600px',
    padding: '1rem',
    border: `1px solid ${silver}`,
    borderBottomLeftRadius: '3px',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
  },
  checkboxRoot: {
    color: slate,
    '&$checked': {
      color: leaf,
    },
  },
  listItemRoot: {
    paddingTop: '0 !important',
    paddingBottom: '1rem !important',
    flex: 'none',
  },
  listItemTextRoot: {
    position: 'relative',
    top: '0.5rem',
    padding: '0 0.5rem',
  },
  listItemTextPrimary: {
    fontSize: '1rem !important',
  },
  listItemTextSecondary: {
    fontSize: '0.8125rem !important',
    color: `${slate} !important`,
  },
  checked: {},
});

export const OrgSchoolCheckboxList = ({
  areFiltersSetToDefault,
  areInitialMapBoundsSet,
  listItems,
  totalHiddenSchools,
  totalNearbySchools,
  checkboxSelections,
  toggleCheckbox,
  setCheckboxSelections,
  onSelectionUpdated,
  setGradeFilter,
  setAudienceFilter,
  setMapVariables,
  setSearchValue,
  setInputValue,
  setPreviousRedoSearchZoom,
  map,
  classes,
}: Props) => {
  const { setSchoolDistributionList } = useContext(DealsContext);

  const handleCheckboxClick = (event, schoolData) => {
    const updatedCheckboxSelections = toggleCheckbox(
      schoolData,
      setCheckboxSelections,
      checkboxSelections
    );
    onSelectionUpdated(updatedCheckboxSelections);

    // External updates for tray
    handleExternalUpdates(schoolData);
  };

  const handleExternalUpdates = debounce(school => {
    const schoolId = get(school, 'schoolId');
    const label = get(school, 'name');
    const districtName = get(school, 'district.name');
    const districtId = get(school, 'districtId');
    const audienceType = get(school, 'audienceType');
    const isPostOnly = get(school, 'isPostOnly', null);
    const isOptionalPostOnly = get(school, 'isOptionalPostOnly', null);
    const isChecked = get(school, 'isChecked', false);
    const highGrade = get(school, 'highestGradeOffered', '');
    const lowGrade = get(school, 'lowestGradeOffered', '');

    setSchoolDistributionList({
      schoolId,
      districtId,
      audienceType,
      schoolName: label,
      districtName,
      isPostOnly,
      isOptionalPostOnly,
      isPostOnlyChecked: isPostOnly,
      highGrade,
      lowGrade
    });
  }, 0);

  return (
    <List className={`${classes.root} layout-column`} id="schoolList">
      {listItems.length === 0 && areInitialMapBoundsSet && (
        <EmptyListMessage
          areFiltersSetToDefault={areFiltersSetToDefault}
          setGradeFilter={setGradeFilter}
          setAudienceFilter={setAudienceFilter}
          totalHiddenSchools={totalHiddenSchools}
          totalNearbySchools={totalNearbySchools}
          setMapVariables={setMapVariables}
          setSearchValue={setSearchValue}
          setInputValue={setInputValue}
          setPreviousRedoSearchZoom={setPreviousRedoSearchZoom}
          map={map}
        />
      )}
      {listItems.map(item => {
        const schoolId = get(item, 'schoolId');
        const label = get(item, 'name');
        const districtName = get(item, 'district.name');
        // const districtId = get(item, 'districtId');
        const audienceType = get(item, 'audienceType');

        return (
          <ListItem
            className="p-2"
            id={schoolId}
            classes={{ root: classes.listItemRoot }}
            key={`selection${schoolId}`}
            role="listitem"
            dense
            button
            onClick={e => handleCheckboxClick(e, item)}
          >
            <Checkbox
              data-testid={`${label.split(' ').join('')}Checkbox`}
              className="p-0"
              classes={{
                root: classes.checkboxRoot,
                checked: classes.checked,
              }}
              checked={
                !!checkboxSelections.find(
                  selection =>
                    schoolId === selection.schoolId &&
                    audienceType === selection.audienceType
                )
              }
              tabIndex={-1}
              disableRipple
            />
            <ListItemText
              classes={{
                root: classes.listItemTextRoot,
                primary: classes.listItemTextPrimary,
                secondary: classes.listItemTextSecondary,
              }}
              primary={label}
              primaryTypographyProps={{ component: Paragraph }}
              secondary={districtName}
              secondaryTypographyProps={{ component: Note }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default withStyles(styles)(OrgSchoolCheckboxList);
