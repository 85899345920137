import * as React from 'react';
import { css } from 'emotion';
import { BREAKPOINTS } from '../../constants';
import { colors, components, elements } from '@peachjar/components';
import { SIDEBAR_WIDTH, PEACHJAR_ACCOUNT_LINK } from '../../constants';
import SpecialLink from '../SpecialLink';
import arrowBackSVG from '../../../assets/arrow_back.svg';

const { Note } = elements.typography;
const { VerticalNav } = components;

type Props = {
  children: React.ReactNode;
};

const Sidebar = ({ children }: Props) => (
  <div className={classNames.sidebar}>
    <div className={classNames.sidebarNav}>
      <VerticalNav label="Main Site Navigation">{children}</VerticalNav>
    </div>
    <div className={classNames.sidebarContent}>
      <div className={classNames.noteBox}>
        <Note bold>Note:</Note>{' '}
        <Note>
          Can't find what you are looking for? Return to the other areas to find
          it.
        </Note>
      </div>
      <hr className={classNames.divider} />
      <SpecialLink hrefURI={PEACHJAR_ACCOUNT_LINK}>
        <img
          className={classNames.backArrow}
          src={arrowBackSVG}
          alt="Back to Other Areas"
          width="18"
          height="14"
        />
        Other Areas
      </SpecialLink>
    </div>
  </div>
);

const classNames = {
  sidebar: css`
    display: flex;
    flex-flow: column nowrap;
    min-width: ${SIDEBAR_WIDTH.sm};
    max-width: ${SIDEBAR_WIDTH.sm};
    padding: 0.5rem;
    padding-top: 1.5rem;
    border-right: 1px solid ${colors.mercury};
    overflow: auto;

    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      min-width: ${SIDEBAR_WIDTH.md};
      max-width: ${SIDEBAR_WIDTH.md};
    }
  `,
  sidebarNav: css`
    flex: 0 0 auto;
  `,
  sidebarContent: css`
    flex: 1 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
  `,
  noteBox: css`
    line-height: 1.23;
    padding: 1rem 1rem 1rem 0.5rem;
    margin: 1rem 0.2rem;
    border-radius: 3px;
    background-color: #ffe6aa;

    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      padding: 1rem;
      margin: 1rem 0.5rem;
    }
  `,
  divider: css`
    margin-top: 0;
    margin-bottom: 0;
    width: 90%;
  `,
  backArrow: css`
    padding-right: 4px;
    vertical-align: bottom;
  `,
};

export default Sidebar;
