import gql from 'graphql-tag';

const GET_SCHOOL_FINDER_AUTOCOMPLETIONS_QUERY = gql`
query GetSchoolFinderAutocompletions($filter: String, $sessionToken: String, $centerPoint: GeocodeInput) {
  getDistricts(filter: $filter, paginationOptions: { limit: 3 }) {
    districts {
      name
      placeId
      location {
        city
        stateShort
      }
    }
    total
  }
  getSchools(filter: $filter, centerPoint: $centerPoint, paginationOptions: { limit: 3 }) {
    schools {
      name
      placeId
      district {
        name
      }
      location {
        city
        stateShort
      }
    }
    total
  }
  getPlacesAutocomplete(query: $filter, sessionToken: $sessionToken, centerPoint: $centerPoint) {
    places {
      description
      placeId
    }
  }
}
`;

export default GET_SCHOOL_FINDER_AUTOCOMPLETIONS_QUERY;
