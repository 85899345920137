/* global window */

import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useGoogleMap } from '@react-google-maps/api';
import { css } from 'react-emotion';
import { colors } from '@peachjar/components';

const { jungle } = colors;

const RedoSearchButton = ({
  showRedoSearch,
  setShowRedoSearch,
  setMapVariables,
  getCurrentCenter,
  currentCenter,
  getCurrentBounds,
  previousRedoSearchZoom,
  setPreviousRedoSearchZoom,
  setSearchValue,
  setInputValue,
}) => {
  const map = useGoogleMap();
  const maps = window.google.maps;
  const position = window.google.maps.ControlPosition.TOP_CENTER;
  const controlDiv = document.createElement('div');
  const controlUI = document.createElement('div');
  const controlText = document.createElement('div');

  controlUI.className = styles.controlUi;
  controlDiv.appendChild(controlUI);

  controlText.className = styles.controlText;
  controlText.innerHTML = 'Search This Area';
  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', () => {
    setMapVariables({
      center: getCurrentCenter(map),
      redoMapBounds: getCurrentBounds(map),
    });
    setSearchValue('Current Map Area');
    setInputValue('Current Map Area');
    setPreviousRedoSearchZoom(map.getZoom());

    // Remove redo search
    setShowRedoSearch(false);
  });

  useEffect(() => {
    const controls = map.controls[position];
    const hasControls = !!controls.length;

    if (showRedoSearch && controls.length === 0) {
      controls.push(controlDiv);
    }

    if (hasControls && !showRedoSearch) {
      controls.clear();
    }
  });

  return createPortal(_, controlDiv);
};

const styles = {
  controlUi: css`
    background-color: ${jungle};
    border: 1px solid ${jungle};
    padding: 0.5rem 1rem;
    height: 32px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 22px;
    text-align: center;
    margin-top: 1rem;

    &:hover {
      background-color: ${jungle};
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    }

    &:not([disabled]):not(.disabled):active {
      background-color: ${jungle};
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
    }

    &:disabled,
    &[disabled],
    &.disabled {
      opacity: 1;
      background-color: #dedede;
      color: slate;
      font-weight: normal;
    }
  `,
  controlText: css`
    color: #fff;
    font-family: 'Proxima Nova', Arial, sans-serif;
    line-height: 16px;
    font-size: 13px;
  `,
};

export default RedoSearchButton;
