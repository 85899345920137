/* eslint-disable graphql/template-strings */
import gql from 'graphql-tag';

const FETCH_CAMPAIGN_TYPE_BY_ID = gql`
  query fetchCampaignTypeById($campaignId: String!) {
    campaignById(campaignId: $campaignId) {
      id
      type
      legacyFlyerId
      application {
        id
      }
    }
  }
`;

export default FETCH_CAMPAIGN_TYPE_BY_ID;
