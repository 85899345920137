import React from 'react';

export default function HouseGrayscaleSvg(props) {
  return (
    <div style={{ height: '56px', width: '68px' }}>
      <svg
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 139.68 115.61"
        {...props}
      >
        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.house-cls-1{fill:#999;}.house-cls-2{fill:#848484;}.house-cls-3{fill:#b2b2b2;}.house-cls-4{fill:#d6d6d6;}.house-cls-5{fill:#adadad;}',
            }}
          />
        </defs>
        <title>Asset 16</title>
        <g id="House_Layer_2" data-name="House Layer 2">
          <g id="House_Layer_2-2" data-name="House Layer 2">
            <path
              className="house-cls-1"
              d="M92.69,9.74h23.38a0,0,0,0,1,0,0V33.61A1.37,1.37,0,0,1,114.69,35H94.06a1.37,1.37,0,0,1-1.37-1.37V9.74A0,0,0,0,1,92.69,9.74Z"
            />
            <path
              className="house-cls-1"
              d="M.63,47.07,69,.25a1.47,1.47,0,0,1,1.63,0L139,47.07a1.44,1.44,0,0,1-.82,2.62H1.44A1.43,1.43,0,0,1,.63,47.07Z"
            />
            <path
              className="house-cls-2"
              d="M69.07.25a1.47,1.47,0,0,1,1.63,0l68.35,46.82a1.43,1.43,0,0,1-.81,2.62H69.89Z"
            />
            <path
              className="house-cls-3"
              d="M119.35,115.61H20.23a1.44,1.44,0,0,1-1.44-1.44V50.55a1.45,1.45,0,0,1,.64-1.2L69,16.23a1.45,1.45,0,0,1,1.6,0l49.56,33.12a1.45,1.45,0,0,1,.64,1.2v63.62A1.43,1.43,0,0,1,119.35,115.61Z"
            />
            <rect
              className="house-cls-2"
              x="90.81"
              y="6.99"
              width="27.13"
              height="2.75"
              rx="1.37"
            />
            <path
              className="house-cls-4"
              d="M72,56.74H109a.72.72,0,0,1,.72.72v58.16a0,0,0,0,1,0,0H71.25a0,0,0,0,1,0,0V57.46A.72.72,0,0,1,72,56.74Z"
            />
            <path
              className="house-cls-5"
              d="M74.6,60.12h31.33a.72.72,0,0,1,.72.72v54.77a0,0,0,0,1,0,0H73.88a0,0,0,0,1,0,0V60.84A.72.72,0,0,1,74.6,60.12Z"
            />
            <rect
              className="house-cls-4"
              x="79.88"
              y="63.24"
              width="8.69"
              height="8.69"
              rx="0.72"
            />
            <rect
              className="house-cls-4"
              x="92.04"
              y="63.24"
              width="8.69"
              height="8.69"
              rx="0.72"
            />
            <rect
              className="house-cls-4"
              x="79.88"
              y="74.02"
              width="8.69"
              height="8.69"
              rx="0.72"
            />
            <rect
              className="house-cls-4"
              x="92.04"
              y="74.02"
              width="8.69"
              height="8.69"
              rx="0.72"
            />
            <path
              className="house-cls-4"
              d="M80.6,89.61h4.81a.72.72,0,0,1,.72.72v25.28a0,0,0,0,1,0,0H79.88a0,0,0,0,1,0,0V90.33A.72.72,0,0,1,80.6,89.61Z"
            />
            <path
              className="house-cls-4"
              d="M87.73,89.61h4.81a.72.72,0,0,1,.72.72v25.28a0,0,0,0,1,0,0H87a0,0,0,0,1,0,0V90.33A.72.72,0,0,1,87.73,89.61Z"
            />
            <path
              className="house-cls-4"
              d="M94.87,89.61h4.81a.72.72,0,0,1,.72.72v25.28a0,0,0,0,1,0,0H94.15a0,0,0,0,1,0,0V90.33A.72.72,0,0,1,94.87,89.61Z"
            />
            <circle className="house-cls-5" cx="103.52" cy="86.21" r="2.1" />
            <path
              className="house-cls-4"
              d="M47.84,53.06c-8.92-.42-16.28,5.69-16.28,14.52H31.5V94.27a.72.72,0,0,0,.72.72H61.91a.73.73,0,0,0,.72-.72V68C62.63,59.65,56.2,53.46,47.84,53.06Z"
            />
            <path
              className="house-cls-5"
              d="M47.81,55.16A13.6,13.6,0,0,0,33.55,68.74h0V92a.72.72,0,0,0,.72.72H60a.72.72,0,0,0,.72-.72V69.13A13.85,13.85,0,0,0,47.81,55.16Z"
            />
            <path
              className="house-cls-4"
              d="M56.75,67.83a9.81,9.81,0,0,0-19.62,0h0"
            />
            <rect
              className="house-cls-4"
              x="37.5"
              y="71.92"
              width="8.02"
              height="8.02"
              rx="0.72"
            />
            <rect
              className="house-cls-4"
              x="48.73"
              y="71.92"
              width="8.02"
              height="8.02"
              rx="0.72"
            />
            <rect
              className="house-cls-4"
              x="37.5"
              y="81.87"
              width="8.02"
              height="8.02"
              rx="0.72"
            />
            <rect
              className="house-cls-4"
              x="48.73"
              y="81.87"
              width="8.02"
              height="8.02"
              rx="0.72"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
