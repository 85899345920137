import * as React from 'react';
import gql from 'graphql-tag';
import idx from 'idx';
import { Query } from 'react-apollo';
import getFeatureFlags from '../flagging/flags';
import RolesEnum, { Roles } from '../RolesEnum';
import { redirect } from '../redirect';

// import { Sod } from '../__generated__/ViewerQuery';
import config from '../../config';
import { NOTIFICATIONS, APP_ROUTES } from '../constants';

type AllowedRoles = Roles[];
type UserRoles = Roles[];

const { REACT_APP_PEACHJAR_ACCOUNT_LINK } = config;
const { errorKey } = NOTIFICATIONS;

// specifically for the brand bar
export const getAccountType = sod => {
  switch (sod) {
    case 'district':
      return 'District Account';
    case 'org':
      return 'Organization Account';
    case 'volunteer':
      return 'Volunteer Account';
    default:
      return 'School Account';
  }
};

/**
 * Tech Debt (DEV-3436): The permission system is not well thought out.
 * We need to create a more robust system not specifically tailored to
 * the beta approval center.
 */
export const getRolesFromPermissions = (
  approverLevel?: Object,
  adminLevel?: Object,
  uploaderLevel?: Object,
  staffLevel?: Object,
  volunteerLevel?: Object
): UserRoles => {
  const roles = [];

  if (approverLevel === 'school') {
    roles.push(RolesEnum.SCHOOL_APPROVER);
  }
  if (approverLevel === 'district') {
    roles.push(RolesEnum.DISTRICT_APPROVER);
  }
  if (adminLevel === 'school') {
    roles.push(RolesEnum.SCHOOL_ADMIN);
  }
  if (adminLevel === 'district') {
    roles.push(RolesEnum.DISTRICT_ADMIN);
  }
  if (uploaderLevel === 'school') {
    roles.push(RolesEnum.SCHOOL_UPLOADER);
  }
  if (uploaderLevel === 'district') {
    roles.push(RolesEnum.DISTRICT_UPLOADER);
  }
  if (uploaderLevel === 'org') {
    roles.push(RolesEnum.ORG);
  }
  if (staffLevel === 'district') {
    roles.push(RolesEnum.DISTRICT_STAFF);
  }
  if (staffLevel === 'school') {
    roles.push(RolesEnum.SCHOOL_STAFF);
  }
  if (volunteerLevel === 'district') {
    roles.push(RolesEnum.DISTRICT_VOLUNTEER);
  }
  if (volunteerLevel === 'school') {
    roles.push(RolesEnum.SCHOOL_VOLUNTEER);
  }

  return roles;
};

// TODO: write some basic tests right now
export const shouldUserSeeChildren = (
  allowedRolesForApp: AllowedRoles,
  userRoles: UserRoles
): boolean => {
  // If no roles were specified, assume all roles can view children
  if (!Array.isArray(allowedRolesForApp)) {
    return true;
  }

  return allowedRolesForApp.reduce((userShouldSeeApp, nextRoleSet) => {
    if (userShouldSeeApp) {
      return true;
    }
    if (typeof nextRoleSet === 'string') {
      return userRoles.includes(nextRoleSet);
    }
    if (Array.isArray(nextRoleSet)) {
      // check that user has all the roles in the set
      return nextRoleSet.every(role => userRoles.includes(role));
    }
    return false;
  }, false);
};

const PERMISSIONS_QUERY = gql`
  query PermissionsQuery {
    sodUser: profile {
      id
      userId
      permission {
        adminLevel
        approverLevel
        uploaderLevel
        staffLevel
        volunteerLevel
      }
    }
  }
`;

// TODO: this could be a query
type Props = {
  children: React.ReactNode;
  roles?: AllowedRoles;
};

const getUserRoleData = queryData => ({
  approverLevel:
    idx(queryData, _ => _.sodUser.permission.approverLevel) || null,
  adminLevel: idx(queryData, _ => _.sodUser.permission.adminLevel) || null,
  uploaderLevel:
    idx(queryData, _ => _.sodUser.permission.uploaderLevel) || null,
  staffLevel: idx(queryData, _ => _.sodUser.permission.staffLevel) || null,
  volunteerLevel:
    idx(queryData, _ => _.sodUser.permission.volunteerLevel) || null,
});

const RoleCheck = ({ children, roles }: Props) => (
  <Query query={PERMISSIONS_QUERY} fetchPolicy="cache-only">
    {({ data }) => {
      const {
        approverLevel,
        adminLevel,
        uploaderLevel,
        staffLevel,
        volunteerLevel,
      } = getUserRoleData(data);
      const userRoles = getRolesFromPermissions(
        approverLevel,
        adminLevel,
        uploaderLevel,
        staffLevel,
        volunteerLevel
      );

      return shouldUserSeeChildren(roles, userRoles) ? children : null;
    }}
  </Query>
);

// Enhancing RoleChecWithRedirect with the ability to share roles via context
export const RolesContext = React.createContext({
  uploaderLevel: '',
});
export const RolesProvider = RolesContext.Provider;
export const useRolesContext = () => React.useContext(RolesContext);

export const RoleCheckWithRedirect = ({ children, roles }: Props) => (
  <Query query={PERMISSIONS_QUERY} fetchPolicy="cache-only">
    {({ data }) => {
      const flags = getFeatureFlags().then(res => res);
      const {
        approverLevel,
        adminLevel,
        uploaderLevel,
        staffLevel,
        volunteerLevel,
      } = getUserRoleData(data);
      const userRoles = getRolesFromPermissions(
        approverLevel,
        adminLevel,
        uploaderLevel,
        staffLevel,
        volunteerLevel
      );

      if (shouldUserSeeChildren(roles, userRoles)) {
        return (
          <RolesProvider
            value={{
              uploaderLevel,
              approverLevel,
              adminLevel,
              staffLevel,
              volunteerLevel,
            }}
          >
            {children}
          </RolesProvider>
        );
      }

      if (flags.portal_nav === 1) {
        localStorage.setItem(errorKey, 'roleDenied');
        redirect(APP_ROUTES.dashboard);
        return null;
      }
      redirect(REACT_APP_PEACHJAR_ACCOUNT_LINK);
      return null;
    }}
  </Query>
);

RoleCheck.defaultProps = { roles: null };
RoleCheckWithRedirect.defaultProps = { roles: null };

export default RoleCheck;
