import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { elements } from '@peachjar/components';
import { Switch, Route, matchPath } from 'react-router-dom';
import { get } from 'lodash';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { css } from 'emotion';
import config from '../../config';
import portalBffApolloClient from '../../_app/apollo/portalBFF.apolloClient';
import { TabContentWrapper, Wrapper } from '../shared';
import CreateCampaignFragments from './gql/CreateCampaignFragments.graphql';
import Prepare from './Prepare';
import Details from './Details';
import Delivery from './Delivery';
import Summary from './Summary';
import Confirmation from './Confirmation';
import { rehydrateCache } from './gql/utils';
import TabberWrapper from './components/TabberWrapper';
import SaveFailedModal from './components/SaveFailedModal';
import CAMPAIGN_FRAGMENT from './gql/CampaignFragment.graphql';
import ProcessModeBar from './ProcessModeBar';
import Breadcrumb from '../../components/MiniBreadcrumb';
import MapCampaignType from './components/MapCampaignType';
import LoadingSpinner from '../../_app/components/FullPageSpinner';
import {
  COMMUNITY_FREE_TYPE,
  resetCommunityFreeConfig,
} from '../campaigns.duck';

const { Headline1 } = elements.typography;
const { COMMUNITY_FREE_QUESTIONNAIRE_URL } = config;

type Props = {
  sodUser: {
    [key: string]: any;
  };
  apolloHelpers: Object;
  history: Object;
  location: Object;
  districts: Array;
  campaigns: Object;
  rehydratePrepareCache: (args: Object) => void;
  rehydrateDetailsCache: (args: Object) => void;
  rehydrateDeliveryCache: (args: Object) => void;
  rehydrateSummaryCache: (args: Object) => void;
  uploaderLevel: string;
  rehydrateCTAData: any;
  rehydratePrimaryCTA: any;
  rehydrateSecondaryCTA: any;
  handleUseUploaderQuery: (bool: boolean) => void;
  actions: {
    resetCommunityFreeConfig: () => void;
  };
};

type State = {
  shouldPerformDisabledClickValidation: boolean;
  saveFailed: {
    showModal: boolean;
    campaignId: string;
  };
};

class CreateCampaign extends React.PureComponent<Props, State> {
  static fragments: { [key: string]: any };

  state = {
    shouldPerformDisabledClickValidation: false,
    saveFailed: {
      showModal: false,
      campaignId: '',
    },
  };

  componentDidMount() {
    const {
      handleUseUploaderQuery,
      shouldPerformDisabledClickValidation,
    } = this.props;

    if (!shouldPerformDisabledClickValidation) {
      // portalBffApolloClient.resetStore();
    }
    // When campaigns index mounts, we swap VIEWER_QUERY with UPLOADER_QUERY
    handleUseUploaderQuery(true);
  }

  /*
  UPLOADER_QUERY stays active througout all campaigns routes, ex: create-campaign, create-campaign/prepare, etc. Until we refactor our top level query with a permanent solution, when campaigns index unmounts, and is going to a non-campaigns route, the window redirect ensures the VIEWER_QUERY is back in use. Unable to call handleUseUploader(false) when umounting; lead to issues when navigating from a campaigns route to /all-approvals and /my-approvals - type error around graphql-anywhere filter because VIEWER_QUERY isn't swapped quick enough
  */
  componentWillUnmount() {
    const { isApprover } = this.props;
    if (!window.location.pathname.includes('/campaigns') && isApprover)
      window.location.href = window.location.pathname;

    this.props.actions.resetCommunityFreeConfig();
  }

  handleShouldPerformDisabledClickValidation = (boolean: boolean): void => {
    this.setState({ shouldPerformDisabledClickValidation: boolean });
  };

  removeCampaignFromCache = (campaignId: string): void => {
    const client = portalBffApolloClient;

    const campaign = client.readFragment({
      id: campaignId,
      fragment: CAMPAIGN_FRAGMENT,
      fragmentName: 'Campaign_fragment',
    });

    // remove campaign from cache only if found
    if (campaign) {
      campaign.isDeleted = true;

      client.writeFragment({
        id: campaignId,
        fragment: CAMPAIGN_FRAGMENT,
        data: campaign,
        fragmentName: 'Campaign_fragment',
      });
    }
  };

  onSaveFailed = (campaignId: string): void => {
    this.setState({
      saveFailed: {
        showModal: true,
        campaignId,
      },
    });
  };

  onSaveFailedConfirmed = () => {
    const {
      saveFailed: { campaignId },
    } = this.state;
    const { history } = this.props;

    this.setState(
      {
        saveFailed: {
          showModal: false,
          campaignId: '',
        },
      },
      () => {
        this.removeCampaignFromCache(campaignId);
        history.push('/campaigns');
      }
    );
  };

  render() {
    const {
      sodUser,
      history,
      location,
      campaigns,
      districts,
      flags,
      rehydratePrepareCache,
      rehydrateDetailsCache,
      rehydrateDeliveryCache,
      rehydrateSummaryCache,
      rehydrateCTAData,
      rehydratePrimaryCTA,
      rehydrateSecondaryCTA,
      uploaderLevel,
    } = this.props;

    // @ts-ignore
    const { org_uploader } = flags;

    const { shouldPerformDisabledClickValidation, saveFailed } = this.state;

    const matchSummary = matchPath(location.pathname, {
      path: '/campaigns/create-campaign/:campaignId/summary',
    });
    const matchConfirmation = matchPath(location.pathname, {
      path: '/campaigns/create-campaign/:campaignId/confirmation',
    });
    const matchPrepare = matchPath(location.pathname, {
      path: '/campaigns/create-campaign/:campaignId/prepare',
    });
    const matchDetails = matchPath(location.pathname, {
      path: '/campaigns/create-campaign/:campaignId/details',
    });
    const matchDelivery = matchPath(location.pathname, {
      path: '/campaigns/create-campaign/:campaignId/delivery',
    });

    const manualMatch =
      matchSummary ||
      matchConfirmation ||
      matchPrepare ||
      matchDetails ||
      matchDelivery;

    // Must manually match to get campaign id param at this level
    const campaignId = get(manualMatch, 'params.campaignId');

    const isSummaryRoute =
      manualMatch &&
      manualMatch.path === '/campaigns/create-campaign/:campaignId/summary';
    const isConfirmationRoute =
      manualMatch &&
      manualMatch.path ===
        '/campaigns/create-campaign/:campaignId/confirmation';

    const remoteCampaign =
      campaigns && campaigns.find(campaign => campaign.id === campaignId);

    /* ------------------
      Start Rehydrate Cache
      ------------------ */
    // When shouldPerformDisabledValidation is toggled, this component re-renders. The check below prevents unnecessary rehydration that may cause unintended behavior
    if (
      remoteCampaign &&
      !shouldPerformDisabledClickValidation &&
      !isConfirmationRoute
    ) {
      let defaultSelections = [];
      if (
        districts.length > 0 &&
        (uploaderLevel === 'school' || uploaderLevel === 'district')
      ) {
        defaultSelections = districts[0].audiences.parents;
      }

      rehydrateCache({
        defaultSelections,
        remoteCampaign,
        rehydratePrepareCache,
        rehydrateDetailsCache,
        rehydrateDeliveryCache,
        rehydrateSummaryCache,
        rehydratePrimaryCTA,
        rehydrateSecondaryCTA,
        rehydrateCTAData,
      });
    }
    /* ----------------
    End Rehydrate Cache
    ---------------- */

    return (
      <MapCampaignType campaignId={campaignId}>
        {({ campaignType, applicationId }) => {
          const isCommunityFree = campaignType === COMMUNITY_FREE_TYPE;
          const communityFreeConfig = {
            campaignType,
            applicationId,
            isCommunityFree,
          };

          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ProcessModeBar
                campaigns={campaigns}
                uploaderLevel={uploaderLevel}
                communityFreeConfig={communityFreeConfig}
              />

              <Wrapper>
                {isCommunityFree && !isConfirmationRoute && (
                  <div className={cn.breadcrumb}>
                    <Breadcrumb
                      linkTo={`${COMMUNITY_FREE_QUESTIONNAIRE_URL}?aid=${applicationId}`}
                      text="Questionnaire"
                      externalLink={true}
                    />
                  </div>
                )}
                <Headline1
                  className={`mb-4 ${
                    (matchDelivery || matchSummary) &&
                    org_uploader === 1 &&
                    uploaderLevel === 'org'
                      ? cn.headlineMargin
                      : ''
                  }`}
                >
                  Send &amp; Post Flyer
                </Headline1>
                {!isSummaryRoute && !isConfirmationRoute && (
                  <TabberWrapper
                    history={history}
                    paramsCampaignId={campaignId}
                    handleShouldPerformDisabledClickValidation={
                      this.handleShouldPerformDisabledClickValidation
                    }
                  />
                )}
                <TabContentWrapper>
                  <Switch>
                    <Route
                      path="/campaigns/create-campaign/:id/prepare"
                      render={() => (
                        <Prepare
                          communityFreeConfig={communityFreeConfig}
                          history={history}
                          remoteCampaign={remoteCampaign}
                          onSaveFailed={this.onSaveFailed}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/campaigns/create-campaign/:id/details"
                      render={() => (
                        <Details
                          campaignId={campaignId}
                          remoteCampaign={remoteCampaign}
                          shouldPerformDisabledClickValidation={
                            shouldPerformDisabledClickValidation
                          }
                          handleShouldPerformDisabledClickValidation={
                            this.handleShouldPerformDisabledClickValidation
                          }
                          onSaveFailed={this.onSaveFailed}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/campaigns/create-campaign/:id/delivery"
                      render={() => (
                        <Delivery
                          districts={districts}
                          history={history}
                          campaignId={campaignId}
                          uploaderLevel={uploaderLevel}
                          onSaveFailed={this.onSaveFailed}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/campaigns/create-campaign/:id/summary"
                      render={() => (
                        <Summary
                          sodUser={sodUser}
                          history={history}
                          remoteFlyerPages={get(
                            remoteCampaign,
                            'flyer.flyerPages'
                          )}
                          communityFreeConfig={communityFreeConfig}
                          campaignId={campaignId}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/campaigns/create-campaign/:id/confirmation"
                      render={() => (
                        <Confirmation
                          history={history}
                          campaignId={campaignId}
                          communityFreeConfig={communityFreeConfig}
                        />
                      )}
                    />
                  </Switch>
                </TabContentWrapper>

                {saveFailed.showModal && (
                  <SaveFailedModal onOkay={this.onSaveFailedConfirmed} />
                )}
              </Wrapper>
            </MuiPickersUtilsProvider>
          );
        }}
      </MapCampaignType>
    );
  }
}

CreateCampaign.fragments = CreateCampaignFragments;

const cn = {
  breadcrumb: css`
    margin-top: -60px;
    margin-bottom: 32px;

    &.plusHeight {
      margin-top: 20px;
      margin-bottom: -28px;
    }
  `,
  headlineMargin: css`
    margin-top: 4.5rem !important;
  `,
};

export default connect(
  state => {
    const flags = get(state, 'flags');
    return { flags };
  },
  dispatch => ({
    actions: bindActionCreators({ resetCommunityFreeConfig }, dispatch),
  })
)(CreateCampaign);
