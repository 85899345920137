import * as React from 'react';
import { css } from 'react-emotion';
import JpegPage from './JpegPage';
import OcrEditField from './OcrEditField';
import OcrPagesFragments from './gql/OcrPagesFragments.graphql';

const styles = {
  pageSection: css`
    margin-bottom: 1.5rem;
  `,
};

type Props = {
  pages: { jpegUrl: string, userText: string }[],
  setUserTextEditsState: (number, string) => void,
  userTextEdits: any,
};

export const OcrPages = ({
  pages,
  setUserTextEditsState,
  userTextEdits,
}: Props): React.ReactNode =>
  pages.map((page, i) => (
    <div key={`page${i + 0}`} className={styles.pageSection}>
      <div className="layout-row">
        <OcrEditField
          index={i}
          userText={page.userText}
          setUserTextEditsState={setUserTextEditsState}
          userTextEdit={userTextEdits[i] || ''}
        />
        <JpegPage
          jpegUrl={page.jpegUrl}
          pageNumber={i + 1}
          totalPages={pages.length}
        />
      </div>
    </div>
  ));

OcrPages.fragments = OcrPagesFragments;

export default OcrPages;
