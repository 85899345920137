import React from 'react';
import DistrictAudiences from './DistrictAudiences';
// import OrgAudiences from './OrgAudiences';

const Audiences = ({ className, selectedAudience }) => (
  <div className={`layout-row ${className}`}>
    <DistrictAudiences selectedAudience={selectedAudience} />
    {/* {uploaderLevel === 'org' && (
      <OrgAudiences selectedAudience={selectedAudience} />
    )} */}
  </div>
);

export default Audiences;
