import React, { useEffect, useState } from 'react';
import idx from 'idx';
import * as uuid from 'uuid';
import { css } from 'emotion';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { colors } from '@peachjar/components';
import config from '../../../config';

type PurchaseProps = {
  orderId: string;
  paymentType: string;
};

type Props = {
  loading: boolean;
  handlePurchase: (val: PurchaseProps) => void;
  handleError: () => void;
  purchaseDetails: {
    type: 'credit' | 'flyer';
    value: string;
  };
};

const { PAYPAL_PUBLISHABLE_CLIENT_ID } = config;

/** MUI panel overrides */
const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 46,
    '&$expanded': {
      minHeight: 46,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: '0',
  },
})(MuiExpansionPanelDetails);

const PayPalButton = ({
  loading,
  handlePurchase,
  purchaseDetails,
  handleError,
}: Props) => {
  const [paypalExpanded, setPaypalExpanded] = useState(false);

  const { type, value } = purchaseDetails;

  const togglePanel = () => setPaypalExpanded(!paypalExpanded);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_PUBLISHABLE_CLIENT_ID}&disable-funding=credit,card`;
    script.async = false;
    document.getElementsByTagName('head')[0].appendChild(script);

    script.onload = () => {
      if (document.getElementById('paypal-button-container')) {
        window.paypal
          .Buttons({
            createOrder(data, actions) {
              // Set up the transaction
              return actions.order.create({
                purchase_units: [
                  {
                    item: {
                      name: `${type} purchase`,
                      quantity: 40,
                    },
                    invoice_id: `${type}-${Date.now()}`,
                    reference_id: uuid.v4(),
                    amount: {
                      currency_code: 'USD',
                      value: value.replace(',', ''), // example: 2.50
                    },
                  },
                ],
              });
            },
            onApprove(data, actions) {
              return actions.order.get().then(details => {
                const { id: orderId, status } = details;

                if (orderId && status.toLowerCase() === 'approved') {
                  handlePurchase({ orderId, paymentType: 'paypal' });
                }
              });
            },
            onError(err) {
              handleError();
            },
            style: {
              color: 'gold',
              label: 'pay',
              height: 44,
            },
          })
          .render('#paypal-button-container');
      }
    };
  }, []);

  useEffect(() => {
    if (loading) {
      setPaypalExpanded(false);
    }
  }, [loading]);

  return (
    <ExpansionPanel expanded={paypalExpanded}>
      <ExpansionPanelSummary>
        <button 
          className={`${cn.summaryExpander} ${loading ? cn.buttonDisabled : ''}`}
          onClick={togglePanel}
          disabled={loading}
        >
          Checkout with a PayPal account
        </button>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div
          id="paypal-button-container"
          className={cn.paymentBtnContainer}
        ></div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const cn = {
  summaryExpander: css`
    color: ${colors.jungle} !important;
    font-size: 13px;
    border: none;
    width: 100%;
    padding: 0 !important;
    text-align: right;
    outline: none !important;
    background-color: transparent;

    &:hover {
      text-decoration: none;
    }
  `,
  paymentBtnContainer: css`
    width: 100%;
    padding: 0 20px;
  `,
  buttonDisabled: css`
    color: ${colors.slate} !important;
  `,
};

export default PayPalButton;
