import React from 'react';
import { withStyles, Radio as MUIRadio } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import { colors } from '@peachjar/components';
import { RadioProps } from '@material-ui/core/Radio'
import AnyProps from '../../_app/AnyProps'

type StyleProps = {
  classes: { [key: string]: string },
};

type OwnProps = {
  colorClass: 'jungle' | 'rose' | 'peach' | 'dragon',
  disabled: boolean,
};

type Props = AnyProps & StyleProps & OwnProps;

const Radio = (props: Props) => {
  const { classes, colorClass, disabled } = props;
  const propsToPass: Partial<RadioProps> = { ...props };
  delete propsToPass.color;
  delete propsToPass.classes;

  return (
    <MUIRadio
      // When Radio is used in a RadioGroup + FormControl, some props are passed...
      {...propsToPass}
      classes={{
        root: (!disabled) ? classes[colorClass] : '',
        checked: classes.checked,
      }}
      className={classes.size}
      icon={<RadioButtonUncheckedIcon className={classes.sizeIcon} />}
      checkedIcon={<RadioButtonCheckedIcon className={classes.sizeIcon} />}
    />
  );
};

const classes = {
  jungle: {
    color: colors.slate,
    '&$checked': {
      color: colors.jungle,
    },
  },
  peach: {
    color: colors.slate,
    '&$checked': {
      color: colors.peach,
    },
  },
  dragon: {
    color: colors.slate,
    '&$checked': {
      color: colors.dragon,
    },
  },
  checked: {},
  size: {
    width: 32,
    height: 32,
  },
  sizeIcon: {
    fontSize: 16,
  },
};

export default withStyles(classes)(Radio);
