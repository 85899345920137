import CreditService from "../CreditService";
import ApolloClient from "apollo-client";

import { CreditBalance } from "../CommonModels";

import gql from "graphql-tag";

const GetCreditPurchasingQuoteQuery = gql`
  query GetCreditPurchasingQuoteQuery{
    getMyCreditBalance {
      numberOfCredits
      msrpValue
      updatedAt
    }
  }
`;

type GetCreditBalanceResponse = {
  getMyCreditBalance: CreditBalance,
};

export default class GqlCreditService implements CreditService {

  constructor(protected client: ApolloClient<any>) {}

  async getBalance(): Promise<CreditBalance> {
    const result = await this.client.query<GetCreditBalanceResponse>({
      query: GetCreditPurchasingQuoteQuery,
    });
    const { data: { getMyCreditBalance } } = result;
    return getMyCreditBalance;
  }
}
