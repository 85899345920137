
import * as React from 'react';
import { components } from '@peachjar/components';

const { VerticalNav } = components;

type Props = {
  label: string,
  children: React.ReactNode,
};

const SidebarNavSection = ({ label, children }: Props) => (
  <VerticalNav.Section label={label}>
    {children}
    {/* <VerticalNav.Divider /> */}
  </VerticalNav.Section>
);

export default SidebarNavSection;
