import React from 'react';
import gql from 'graphql-tag';
import { elements } from '@peachjar/components';
import { css, cx } from 'react-emotion';
import PrimaryCTA from './PrimaryCTA';
import SecondaryCTAsGroup from './SecondaryCTAsGroup';
import { NoteInlineNotification } from '../../../../../_app/components/InlineNotification';
import { RolesContext } from '../../../../../_app/components/RoleCheck';
import CallsToActionFragments from './gql/CallsToActionFragments.graphql';

const { Headline2, Paragraph } = elements.typography;

export const ctaPrimaryKey = 'primaryCTA';
export const ctaPrimaryCTADataKey = 'primaryCTAData';
export const ctaSecondaryKey = 'secondaryCTA';
export const ctaSecondaryCTADataKey = 'secondaryCTAData';

export class CallsToAction extends React.Component {
  static fragments;

  static contextType = RolesContext;

  state = {};

  handleIsValid = (name, isValid) => {
    const {
      performValidation,
      isPrimaryCTASelected,
      secondaryCTA,
    } = this.props;
    const { isPrimaryCTAValid, isSecondaryCTAValid } = this.state;

    const props = {};
    let shouldSetState = false;
    if (name === 'primaryCTA' && isPrimaryCTAValid !== isValid) {
      props.isPrimaryCTAValid = isValid;
      shouldSetState = true;
    }
    if (name === 'secondaryCTA' && isSecondaryCTAValid !== isValid) {
      props.isSecondaryCTAValid = isValid;
      shouldSetState = true;
    }

    if (shouldSetState) {
      this.setState(props, () => {
        performValidation({
          target: {
            name: 'callsToAction',
            value: {
              ...this.state,
              isPrimaryCTASelected,
              isSecondaryCTASelected:
                secondaryCTA !== undefined && secondaryCTA !== '',
            },
          },
        });
      });
    }
  };

  interceptAndHandleCTAData = ({ name, data }) => {
    const { handleCTAData } = this.props;
    // hack until campaigns refactor. Move out to redux
    localStorage.setItem(
      `${this.props.campaignId}-${name}`,
      JSON.stringify(data)
    );

    handleCTAData({
      variables: {
        name,
        data,
      },
    });
  };

  render() {
    const {
      campaignId,
      isPrimaryCTASelected,
      primaryCTA,
      primaryCTAData,
      secondaryCTA,
      secondaryCTAData,
      handlePrimaryCTASelection,
      handleSecondaryCTASelection,
      performValidation,
      shouldPerformDisabledClickValidation,
    } = this.props;

    const { uploaderLevel } = this.context;
    const isOrg = uploaderLevel === 'org';

    const actualPrimaryCTA = localStorage.getItem(
      `${campaignId}-${ctaPrimaryKey}`
    );
    const actualPrimaryCTAData = JSON.parse(
      localStorage.getItem(`${campaignId}-${ctaPrimaryCTADataKey}`)
    );
    const actualSecondaryCTA = localStorage.getItem(
      `${campaignId}-${ctaSecondaryKey}`
    );
    const actualSecondaryCTAData = JSON.parse(
      localStorage.getItem(`${campaignId}-${ctaSecondaryCTADataKey}`)
    );

    return (
      <>
        <div>
          <Headline2
            className={css`
              line-height: 1;
            `}
          >
            Add Call-to-Action Buttons
          </Headline2>
          {isOrg && (
            <div className={(styles.notification, 'mt-4')}>
              <NoteInlineNotification
                messageLabel="Tip"
                message="Flyers with Call-to-Action buttons see an increase in user engagement over flyers without Call-to-Action buttons."
              />
            </div>
          )}

          <div className="mt-4">
            <Paragraph>
              Choose one primary CTA button to help parents take action on this
              flyer. (optional)
            </Paragraph>
          </div>
        </div>
        <div className="mt-4">
          <div>
            <SecondaryCTAsGroup
              name="secondaryCTA"
              secondaryCTA={actualSecondaryCTA}
              secondaryCTAData={actualSecondaryCTAData}
              handleCTAData={this.interceptAndHandleCTAData}
              shouldPerformDisabledClickValidation={
                shouldPerformDisabledClickValidation
              }
              isOrg={isOrg}
              handleSelection={name => {
                localStorage.setItem(`${campaignId}-${ctaSecondaryKey}`, name);
                handleSecondaryCTASelection({ variables: { name } });
                performValidation({
                  target: {
                    name: 'callsToAction',
                    value: {
                      ...this.state,
                      isPrimaryCTASelected,
                      isSecondaryCTASelected: true,
                    },
                  },
                });
              }}
              handleCancel={name => {
                localStorage.removeItem(`${campaignId}-${ctaSecondaryKey}`);
                handleSecondaryCTASelection({ variables: { name } });
                performValidation({
                  target: {
                    name: 'callsToAction',
                    value: {
                      ...this.state,
                      isPrimaryCTASelected,
                      isSecondaryCTASelected: false,
                    },
                  },
                });
              }}
              handleIsValid={this.handleIsValid}
            />

            <div>
              <Paragraph>
                Add a Learn More CTA Button to provide more information for
                parents about this flyer. For example, link to your website or a
                more comprehensive PDF. (optional)
              </Paragraph>
            </div>
            <div className="mt-4">
              <PrimaryCTA
                name="primaryCTA"
                isPrimaryCTASelected={isPrimaryCTASelected}
                primaryCTA={actualPrimaryCTA}
                primaryCTAData={actualPrimaryCTAData}
                handleCTAData={this.interceptAndHandleCTAData}
                shouldPerformDisabledClickValidation={
                  shouldPerformDisabledClickValidation
                }
                handleSelection={name => {
                  localStorage.setItem(`${campaignId}-${ctaPrimaryKey}`, name);
                  handlePrimaryCTASelection({ variables: { name } });
                  performValidation({
                    target: {
                      name: 'callsToAction',
                      value: {
                        ...this.state,
                        isPrimaryCTASelected: true,
                        isSecondaryCTASelected:
                          secondaryCTA !== undefined && secondaryCTA !== '',
                      },
                    },
                  });
                }}
                handleCancel={name => {
                  localStorage.removeItem(`${campaignId}-${ctaPrimaryKey}`);
                  handlePrimaryCTASelection({ variables: { name } });
                  performValidation({
                    target: {
                      name: 'callsToAction',
                      value: {
                        ...this.state,
                        isPrimaryCTASelected: false,
                        isSecondaryCTASelected:
                          secondaryCTA !== undefined && secondaryCTA !== '',
                      },
                    },
                  });
                }}
                handleIsValid={this.handleIsValid}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  notification: css`
    max-width: 410px;
  `,
};

CallsToAction.fragments = CallsToActionFragments;
// NOTE: Edge case bug, if you select a Secondary CTA and click finish later, and immediately edit the same flyer, validation thinks isSecondaryCTASelected in validate is true. Tested like 50 times, and shoudn't come up in beta. We need to refactor CTAs all together soon.

// CallsToAction is valid when either CTA is selected and valid
export default CallsToAction;
