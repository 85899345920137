
import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { css } from 'react-emotion';
import idx from 'idx';
import { Mutation } from 'react-apollo';
import { components, elements } from '@peachjar/components';

import withLoading from '../withLoading';
import {
  STRINGS,
  MAX_ALLOWED_APPROVER_NOTES_CHARACTERS,
} from '../../constants';

const { notifySuccess, notifyError } = components.Notifications;
const { Modal, TextInput } = components;
const { ButtonFlatLarge } = components.Buttons;
const { Paragraph } = elements.typography;

const ButtonFlatLargeWithLoading = withLoading(ButtonFlatLarge);

type ReduxDispatchProps = {
  handleSuccess: () => void,
  handleError: () => void,
};

type OwnProps = {
   closeModal: () => void,
   flyer: any, // TODO: GraphQL generated type (fragment on Flyer)
};

type Props = ReduxDispatchProps & OwnProps;

type State = {
  draftNoteAboutSeller?: string,
};

const UPDATE_FLYER_APPROVER_NOTE = gql`
  mutation UpdateThatPrivateDistrictNoteAboutThatUploader(
    $input: UpdatePrivateDistrictNoteAboutUploaderInput!
  ) {
    updatePrivateDistrictNoteAboutUploader(input: $input) {
      uploaderId
      note
    }
  }
`;

class ApproverNotesModal extends React.Component<Props, State> {
  static fragments: { [key: string]: any };

  state = { draftNoteAboutSeller: null };

  onChange = (e: SyntheticKeyboardEvent<HTMLInputElement>): void => {
    // Prevent against pasting or typing in explanations over character limit
    const note = idx(e, _ => _.currentTarget.value) || '';

    if (note.length > MAX_ALLOWED_APPROVER_NOTES_CHARACTERS) {
      const shortenedNote = note.slice(
        0,
        MAX_ALLOWED_APPROVER_NOTES_CHARACTERS
      );
      this.setState({ draftNoteAboutSeller: shortenedNote });
    } else {
      this.setState({ draftNoteAboutSeller: note });
    }
  };

  render() {
    const { closeModal, handleSuccess, handleError } = this.props;
    const noteAboutSeller =
      idx(this.props, _ => _.flyer.approvals[0].noteAboutSeller) || '';
    const sellerId = idx(this.props, _ => _.flyer.sellerId);
    const { draftNoteAboutSeller } = this.state;
    const isApproverNoteDirty =
      draftNoteAboutSeller !== noteAboutSeller && draftNoteAboutSeller !== null;

    return (
      <Modal width="sm" close={closeModal}>
        <Modal.Header>Approver Notes</Modal.Header>
        <Modal.Body className="pt-3">
          <Paragraph className={classNames.blockText}>
            Notes about this organization (not the flyer) for other approvers or
            personal reference.
          </Paragraph>
          <div className={classNames.textFieldWrapper}>
            <TextInput
              autoFocus
              fullWidth
              multiline
              label="Leave a note..."
              onChange={this.onChange}
              value={
                draftNoteAboutSeller !== null
                  ? draftNoteAboutSeller
                  : noteAboutSeller
              }
              data-testid="field-approverNotes"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonFlatLarge
            onClick={closeModal}
            data-testid="click-cancel-approverNotes"
          >
            Cancel
          </ButtonFlatLarge>
          <Mutation
            mutation={UPDATE_FLYER_APPROVER_NOTE}
            onCompleted={handleSuccess}
            onError={handleError}
            awaitRefetchQueries
            variables={{
              input: {
                uploaderId: sellerId,
                note: draftNoteAboutSeller,
              },
            }}
            refetchQueries={() => ['ViewerQuery']}
          >
            {(updateFlyerApproverNote, { loading }) => (
              <ButtonFlatLargeWithLoading
                data-testid="submit-approverNotes"
                disabled={!isApproverNoteDirty}
                loading={loading}
                onClick={updateFlyerApproverNote}
              >
                Submit
              </ButtonFlatLargeWithLoading>
            )}
          </Mutation>
        </Modal.Footer>
      </Modal>
    );
  }
}

const classNames = {
  blockText: css`
    display: block;
    margin-bottom: 1.5rem;
  `,
  icon: css`
    font-size: 1.5rem;
  `,
  textFieldWrapper: css`
    margin-left: 4.875rem;
    margin-right: 4.875rem;
    margin-top: 2.5rem;
  `,
};

ApproverNotesModal.fragments = {
  flyer: gql`
    fragment ApproverNotesModal on Flyer {
      # id
      sellerId
      approvals {
        id
        noteAboutSeller
      }
    }
  `,
};

const mapDispatchToProps = (
  dispatch,
  { closeModal }: OwnProps
): ReduxDispatchProps => ({
  handleSuccess: () => {
    dispatch(notifySuccess(STRINGS.APPROVER_NOTES_SUCCESS_MESSAGE));
    closeModal();
  },
  handleError: () => {
    dispatch(notifyError(STRINGS.APPROVER_NOTES_ERROR_MESSAGE));
    closeModal();
  },
});

export default connect(
  null,
  mapDispatchToProps
)(ApproverNotesModal);
