import { combineReducers } from 'redux';
import { components } from '@peachjar/components';
import { reducer as flyerApprovalsReducer } from '../all_approvals/approvals.duck';
import { reducer as creditPurchasingReducer } from '../credit_purchasing/credits-purchasing.duck';
import { reducer as campaignsReducer } from '../campaigns/campaigns.duck';
import { reducer as flagsReducer } from './flagging/flags.duck';
import { reducer as dealsReducer } from '../deals/redux/deals.duck';
import { reducer as errorsReducer } from './errors/erros.duck';
const { notificationsReducer } = components.Notifications;

const rootReducer = combineReducers({
  campaigns: campaignsReducer,
  notifications: notificationsReducer,
  flyerApprovals: flyerApprovalsReducer,
  creditPurchasing: creditPurchasingReducer,
  deals: dealsReducer,
  flags: flagsReducer,
  errors: errorsReducer,
});

export default rootReducer;
