const SET_SCHOOL_POST_ONLY = 'deals/SET_SCHOOL_POST_ONLY';
const REMOVE_SCHOOL_POST_ONLY = 'deals/REMOVE_SCHOOL_POST_ONLY';

const initialState = {
  postOnly: [],
};

type RootState = {
  postOnly: string[];
};

export function setPostOnlyForSchool(schoolId: string) {
  return async dispatch => {
    dispatch({
      type: SET_SCHOOL_POST_ONLY,
      payload: schoolId,
    });
  };
}

export function removePostOnlyForSchool(schoolId: string) {
  return async dispatch => {
    dispatch({
      type: REMOVE_SCHOOL_POST_ONLY,
      payload: schoolId,
    });
  };
}

export function reducer(
  state: RootState = initialState,
  { type, payload }: { type: string; payload: string }
) {
  switch (type) {
    case SET_SCHOOL_POST_ONLY:
      if (!state.postOnly.includes(payload)) {
        return {
          ...state,
          postOnly: [...state.postOnly, payload],
        };
      }
      return state;
    case REMOVE_SCHOOL_POST_ONLY:
      return {
        ...state,
        postOnly: [...state.postOnly].filter(item => item !== payload),
      };
    default:
      return state;
  }
}
