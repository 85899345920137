import React from 'react';
import config from '../config';

const { TRANSACTION_HISTORY_URL } = config;

const RedirectTransactionHistory = () => {
  React.useEffect(() => {
    window.location.href = TRANSACTION_HISTORY_URL;
  }, []);

  return null;
};

export default RedirectTransactionHistory;
