import * as React from 'react';
import JssProvider from 'react-jss/lib/JssProvider';
import Raven from 'raven-js';
import App, { ViewerQueryVariables } from './App';
import LoadingSpinner from '../components/LoadingSpinner';
import AppDrawerProvider from './store/AppDrawer/AppDrawerProvider';
import DealsProvider from './store/Deals/DealsProvider';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Query, ApolloProvider } from 'react-apollo';
import { components } from '@peachjar/components';
import { createGenerateClassName } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import { GEOCORE_LOGIN_URL } from '../constants';

import gql from 'graphql-tag';
import idx from 'idx';
import portalBFFClient from './apollo/portalBFF.apolloClient';
import store from './store';
import AppErrorBoundary from './AppErrorBoundary';
import { pageVisibilityApi } from '../helpers/pageVisibilityUtils';

const { visibilityChange } = pageVisibilityApi();
const history = createBrowserHistory();

const { ModalProvider } = components;

const generateClassName = createGenerateClassName({
  productionPrefix: 'jss',
  seed: 'portal',
});

export const VIEWER_QUERY = gql`
  query ViewerQuery(
    #    $MyApprovals_sodUser_limit: Int
    #    $MyApprovals_sodUser_offset: Int
    #    $MyApprovals_sodUser_filter: String
    #    $AllApprovals_sodUser_limit: Int
    #    $AllApprovals_sodUser_offset: Int
    #    $AllApprovals_sodUser_filter: String
    #    $PendingApprovals_sodUser_sod: Sod
    #    $PendingApprovals_sodUser_sodId: ID
    $Reporting_sodUser_limit: Int
    $Reporting_sodUser_offset: Int
    $Reporting_sodUser_filter: String
  ) {
    sodUser: profile {
      id
      userId
      firstName
      lastName
      email
      loginType
      hierarchy {
        id
        type
      }
      scopes
      roles {
        roles
        forEntity {
          entityId
          entityType
        }
      }
      organization {
        id
        orgCategory
        name
        address {
          address
          googlePlaceId
        }
        type
        orgId
        phone
        url
        email
        taxId
        lod
        originalFilename
      }
      ...App_sodUser
    }
    flyerReasons {
      ...App_flyerReasons
    }
  }
  ${App.fragments.sodUser}
  ${App.fragments.flyerReasons}
`;

export type UpdateViewerQueryVariables = (updates: any) => void;

// todo: decouple query variables and updateQueryVariable methods to enable decoupled querying
const initialQueryVariables: ViewerQueryVariables = {
  MyApprovals_sodUser_limit: 10,
  MyApprovals_sodUser_offset: 0,
  MyApprovals_sodUser_filter: null,
  AllApprovals_sodUser_limit: 10,
  AllApprovals_sodUser_offset: 0,
  AllApprovals_sodUser_filter: null,
  PendingApprovals_sodUser_sod: null,
  PendingApprovals_sodUser_sodId: null,
  Reporting_sodUser_limit: 15,
  Reporting_sodUser_offset: 0,
  Reporting_sodUser_filter: null,
};

type Props = {};

type State = {
  queryVariables: ViewerQueryVariables;
  useUploaderQuery: boolean;
  userId?: number | null;
};

class AppContainer extends React.Component<Props, State> {
  state = {
    queryVariables: initialQueryVariables,
    useUploaderQuery: false,
    userId: null,
  };

  componentDidMount(){
    document.addEventListener(visibilityChange, this.reloadPageOnSwitchAccount, false);
  }

  componentWillUnmount(){    
    document.removeEventListener(visibilityChange, this.reloadPageOnSwitchAccount, false);
  }

  reloadPageOnSwitchAccount = async () => {
    const isSwitched = await this.isSwitchedAccount()
    if(isSwitched){      
        window.location.reload();     
    }
  }

  isSwitchedAccount = async () => {        
    const variables = this.getVariables();
    const { data } = await portalBFFClient.query({
      query:VIEWER_QUERY,
      variables: variables,
      fetchPolicy: 'no-cache',
    });

    const currentUserId = idx(data, _ => _.sodUser.id) || null;
    const { userId } = this.state;
        
    return currentUserId !== userId;
  }

  getVariables = () => {
    const { queryVariables } = this.state;
    const variables = Object.entries(queryVariables).reduce(
      (result, [key, val]) =>
        val !== null ? { ...result, [key]: val } : result,
      {}
    );
    return variables;
  }

  updateQueryVariables = (updates: ViewerQueryVariables): void => {
    const { queryVariables } = this.state;
    const newQueryVariables = { ...queryVariables, ...updates };
    this.setState({ queryVariables: newQueryVariables });
  };

  resetQueryVariables = (): void => {
    this.setState({ queryVariables: initialQueryVariables });
  };

  handleUseUploaderQuery = (bool: boolean): void => {
    this.setState({ useUploaderQuery: bool });
  };

  isGqlAuthError = (error: any): boolean =>
    error.message.includes('is not authenticated');

  // No window typings currently :(
  // https://github.com/facebook/flow/issues/6709
  handleGqlError(error: any, win: any, raven: any) {
    // If this is an auth error, redirect to login page.
    if (this.isGqlAuthError(error)) {
      const targetURL = window.location.href;
      const target = `${GEOCORE_LOGIN_URL}?target=${encodeURIComponent(
        targetURL
      )}`;
      win.location = target;
      return <LoadingSpinner />;
    } else {
      // For non-auth related errors, send to Sentry
      raven.captureException(error);

      console.error(`GraphQL Error: ${error}`);
    }
  }

  checkUserIsStaffOrVolunteer = (sodUser : any):boolean => {    
    if(!sodUser) return false;  
    const scopes = idx(sodUser, _ => _.scopes) || null;
    return scopes && ["volunteer", "staff"].some(scope => scopes.includes(scope));
  }

  render() {
    // TODO: tech debt maybe filter out nulls if we can't accept on server
    const { useUploaderQuery } = this.state;
    const variables = this.getVariables();
    return (
      <Provider store={store}>
        <Router history={history}>
        <JssProvider generateClassName={generateClassName}>
          <AppErrorBoundary>
            <ApolloProvider client={portalBFFClient}>
                <DealsProvider>
                  <AppDrawerProvider>
                    <ModalProvider>
                      <Query
                        query={VIEWER_QUERY}
                        variables={variables}
                        displayName="ViewerQuery"
                        fetchPolicy="network-only"
                        errorPolicy="all"                  
                        partialRefetch
                        onCompleted={data => {
                          const userId =
                            idx(data, _ => _.sodUser.userId);
                          const userEmail =
                            idx(data, _ => _.sodUser.email) || 'Unknown email';
                          Raven.setUserContext({
                            id: userId || 'Unknown id',
                            email: userEmail,
                          });
                                                 
                          this.setState({ userId })
                        }}
                      >
                        {({
                          data,
                          refetch,
                          networkStatus,
                          loading,
                          error,
                          client,
                        }) => {
                          if (error) {
                            const sodUser = idx(data, _ => _.sodUser);
                            if(this.checkUserIsStaffOrVolunteer(sodUser)){
                              this.handleGqlError(error, window, Raven);
                            }
                            else {
                            return this.handleGqlError(error, window, Raven);
                            }
                          }

                          // TODO (Tech Debt): create type for the Apollo helpers that we can pass around
                          const apolloHelpers = {
                            loading,
                            error,
                            networkStatus,
                            refetch,
                            client,
                          };

                          const gqlStuff = {
                            apolloHelpers,
                            updateQueryVariables: this.updateQueryVariables,
                            resetQueryVariables: this.resetQueryVariables,
                            currentQueryVariables: variables,
                            handleUseUploaderQuery: this.handleUseUploaderQuery,
                            useUploaderQuery,
                            refetchViewerQuery: refetch,
                          };

                          return (
                            <App
                              sodUser={idx(data, _ => _.sodUser)}
                              flyerReasons={idx(data, _ => _.flyerReasons)}
                              gqlStuff={gqlStuff}
                            />
                          );
                        }}
                      </Query>
                    </ModalProvider>
                  </AppDrawerProvider>
                </DealsProvider>
            </ApolloProvider>
          </AppErrorBoundary>
          </JssProvider>
        </Router>
      </Provider>
    );
  }
}

export default AppContainer;
