import React from 'react';
import { css } from 'emotion';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InlineErrors from '../../components/InlineErrors';

const PromoCodeErrors = ({ errors, columnSpans }) => (
  <>
    <TableRow className={cn.promoRowBorder}></TableRow>
    <TableRow>
      <TableCell align="left" className={cn.promoCell} colSpan={columnSpans}>
        <InlineErrors errors={errors} />
      </TableCell>
    </TableRow>
  </>
);

const cn = {
  promoRowBorder: css`
    height: 8px !important;
  `,
  promoCell: css`
    position: relative;
    border-bottom: none !important;
    padding: 0 17px !important;
    background-color: #ffebe5;
  `,
};

export default PromoCodeErrors;
