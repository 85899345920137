import { graphql, compose } from 'react-apollo';
import { get } from 'lodash'
import {
  PREPARE_QUERY,
  SET_USER_TEXTS_MUTATION,
} from './gql/Prepare.graphql';
import Prepare from './Prepare';

const prepareConfig = {
  props: ({
    ownProps,
    data,
  }) => {
    const flyerId = get(data, 'campaignPrepare.flyerId', null)
    return ({
      ...ownProps,
      flyerId,
    })
  },
};

export default compose(
  graphql(PREPARE_QUERY, prepareConfig),
  graphql(SET_USER_TEXTS_MUTATION, { name: 'setUserTexts' })
)(Prepare);
