import generateSubappConfig from '../_app/generateSubappConfig';
import Dashboard from './Dashboard';
import { APP_ROUTES } from '../_app/constants';

const getReportingSubappConfig = portalNavFlag =>
  generateSubappConfig({
    path: APP_ROUTES.dashboard,
    isLive: portalNavFlag === 1,
    navItemConfig: {
      label: 'Dashboard',
    },
    appConfig: {
      Component: Dashboard,
      fragmentOnUser: Dashboard.fragments.sodUser,
    },
  });

export default getReportingSubappConfig;
