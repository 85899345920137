import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { colors } from '@peachjar/components';
import { get } from 'lodash';

const ITEM_HEIGHT = 32;
const MAX_ITEM_LENGTH = 10;
const PADDING_HEIGHT = 16;
const BOTTOM_PADDING_HEIGHT = 8;

const { silver, jungle, slate, leaf } = colors;

const styles = theme => ({
  root: {
    width: 'auto',
    flex: '1 1 auto',
    padding: '1rem',
    border: `1px solid ${silver}`,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
    'border-radius': '0 0 3px 3px',
  },
  checkboxRoot: {
    color: slate,
    '&$checked': {
      color: jungle,
    },
  },
  checkboxRootNew: {
    color: slate,
    '&$checked': {
      color: leaf,
    },
  },
  checked: {},
  maxHeight: {
    height: `${ITEM_HEIGHT * MAX_ITEM_LENGTH +
      PADDING_HEIGHT +
      BOTTOM_PADDING_HEIGHT}px`,
  },
  noHeight: {},
  listItemHeight: { height: `${ITEM_HEIGHT}px` },
});

const SchoolCheckboxList = ({
  setIsSingleCheckboxAltered,
  listItems,
  checkboxSelections,
  toggleCheckbox,
  setCheckboxSelections,
  onSelectionUpdated,
  classes,
}) => {
  const listHeightClass =
    listItems && listItems.length >= 10 ? classes.maxHeight : classes.noHeight;

  return (
    <List
      className={classes.root}
      classes={{
        root: listHeightClass,
      }}
    >
      {listItems.map(item => {

        const schoolId = get(item, 'schoolId');
        const label = get(item, 'name');

        return (
          <ListItem
            className={`pt-1 pr-2 pb-1 pl-2 ${classes.listItemHeight}`}
            key={`selection${schoolId}`}
            role="listitem"
            dense
            button
            onClick={() => {
              const updatedCheckboxSelections = toggleCheckbox(
                item,
                setCheckboxSelections,
                checkboxSelections
              );
              onSelectionUpdated(updatedCheckboxSelections);
              setIsSingleCheckboxAltered(true);
            }}
          >
            <Checkbox
              data-testid={`${label.split(' ').join('')}Checkbox`}
              className="p-0"
              classes={{
                root: classes.checkboxRootNew,
                checked: classes.checked,
              }}
              checked={
                !!checkboxSelections.find(
                  selection =>
                    item.schoolId === selection.schoolId &&
                    item.audienceType === selection.audienceType
                )
              }
              tabIndex={-1}
              disableRipple
            />
            <ListItemText primary={label} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default withStyles(styles)(SchoolCheckboxList);
