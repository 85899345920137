import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { get, filter } from 'lodash';
import { components } from '@peachjar/components';
import { FLYER_REPORTING_FRAGMENT } from '../../../../_app/components/AppDrawer/FlyerDetails';

const { ButtonFlatLarge } = components.Buttons;

const GET_REPORTING_FLYERS = gql`
  query getReportingFlyers($limit: Int!, $offset: Int!) {
    sodUser {
      id
      flyerReporting {
        myFlyers(limit: $limit, offset: $offset) {
          items {
            flyerId
            distributionStatus
          }
        }
        allFlyers(limit: $limit, offset: $offset) {
          items {
            flyerId
            distributionStatus
          }
        }
      }
    }
  }
`;

export const REMOVE_POSTING = gql`
  mutation removeMyFlyer($flyerId: Int!) {
    removeMyFlyer(flyerId: $flyerId) {
      id
    }
  }
`;

export const GET_FLYERS_WITH_APPROVAL_STATUS_COUNT = gql`
  query flyersWithApprovalStatusCount {
    sodUser: profile {
      id
      uploader {
        flyersWithApprovalStatusCount {
          id
          title
          campaignEndDate
          imageUrl
          numberOfDistributions
          pendingStatusCount
          approvedStatusCount
          deniedStatusCount
        }
      }
    }
  }
`;

export const updateFactory = flyerId => {
  const update = cache => {
    const queryResult = cache.readQuery({
      query: GET_FLYERS_WITH_APPROVAL_STATUS_COUNT,
    });

    const sodUserId = get(
      queryResult,
      'sodUser.userId',
      null
    );

    const flyers = get(
      queryResult,
      'sodUser.uploader.flyersWithApprovalStatusCount'
    );

    const flyersWithoutRemovedFlyer = filter(
      flyers,
      flyer => flyer.id !== flyerId
    );

    // refetchQueries not updating all instances of flyer report status so also need to update via readFragment :(
    const reportingResult = cache.readFragment({
      id: sodUserId,
      fragment: FLYER_REPORTING_FRAGMENT,
    });

    const {
      flyerReporting: { allFlyers, myFlyers },
    } = reportingResult;
    const allFlyerContext = allFlyers.items.find(item => item.flyerId === flyerId);
    const myFlyerContext = myFlyers.items.find(item => item.flyerId === flyerId);

    if (allFlyerContext) {
      allFlyerContext.distributionStatus = 'removed';
      allFlyerContext.removalDate = Math.round(new Date().getTime() / 1000);
    }

    if (myFlyerContext) {
      myFlyerContext.distributionStatus = 'removed';
      myFlyerContext.removalDate = Math.round(new Date().getTime() / 1000);
    }

    
    queryResult.sodUser.uploader.flyersWithApprovalStatusCount = flyersWithoutRemovedFlyer;
    
    cache.writeFragment({
      id: sodUserId,
      fragment: FLYER_REPORTING_FRAGMENT,
      data: reportingResult,
    });

    cache.writeQuery({
      query: GET_FLYERS_WITH_APPROVAL_STATUS_COUNT,
      data: queryResult,
    });
  };

  return update;
};

const MutationButton = ({ flyerId, onCompleted, onError }) => {
  const update = updateFactory(flyerId);
  return (
    <Mutation
      mutation={REMOVE_POSTING}
      onCompleted={onCompleted}
      update={update}
      refetchQueries={() => [
        {
          query: GET_REPORTING_FLYERS,
          variables: {
            limit: 15,
            offset: 0,
          },
        },
      ]}
      onError={onError}
    >
      {removeMyFlyer => (
        <ButtonFlatLarge
          onClick={() => removeMyFlyer({ variables: { flyerId } })}
        >
          Submit
        </ButtonFlatLarge>
      )}
    </Mutation>
  );
};

export default MutationButton;
