import React, { useState, Component, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { css } from 'react-emotion';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { components, colors } from '@peachjar/components';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { CSVLink, CSVDownload } from "react-csv";

import { SET_CHECKBOX_SELECTIONS_MUTATION } from '../../../gql/Delivery.graphql';
import { GET_SCHOOLS_BY_ID_QUERY } from './checkboxSelectionsQuery.graphql';
import { getSelectionsWithDistrictGuidelines } from '../SodSelections/Org/OrgSearchForSchools/utils';
import DistrictGuidelines from '../SodSelections/Org/OrgSearchForSchools/DistrictGuidelines';
import portalBffApolloClient from '../../../../../../_app/apollo/portalBFF.apolloClient';
import { getTypenamedCheckboxSelections, mapCheckboxSelections } from '../../../../gql/utils';

const { leaf } = colors;

const {
  Notifications: { notifyError, notifySuccess },
  Buttons: { ButtonSecondarySmall },
} = components;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    'justify-content': 'flex-end',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  updateButton: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  inputRoot: {},
  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${leaf} !important`,
  },
  link: {
    color: `${colors.jungle}`,
    fontSize: '13px',
    // marginTop: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit,
    '&:hover': {
      color: '#c5e8c9',
      textDecoration: 'none',
    }
  },
  linkContainer: {
    fontSize: '13px',
    marginTop: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
  }
  
});

function doTheseArraysContainTheSameValues(arrary1, array2) {
  // Here JSON stringify converts the items in the array into a string.
  // Most if not all objects being compared can be represented as a string, uniquely.
  // Sort is used to get the list of items in order for comparison.
  return JSON.stringify(arrary1.sort()) === JSON.stringify(array2.sort());
}



export const ManagedServices = ({
  classes,
  displayErrorMessage,
  displaySuccessMessage,
  campaignVariables,
  checkboxSelections,
  setCheckboxSelections,
  campaignId,
  onSelectionUpdated
}) => {

  const savedSelections = checkboxSelections.map(
    selection => selection.schoolId
  );
  const savedSelectionsCSV = savedSelections.join('\n');
  let dataToBeDownloaded = null
  
  const [downloadData, setDownloadData] = useState(null);

  const [schoolIDsTextFieldValue, setSchoolIDsTextFieldValue] = useState(
    savedSelectionsCSV
  );
  const [schoolIDs, setSchoolIDs] = useState(savedSelections);
  const [csvData, setCsvData] = useState('');
  const [selectedCount, setSelectedCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  if (savedSelections.length !== 0 && schoolIDsTextFieldValue === '') {
    setSchoolIDs(savedSelections);
    setSchoolIDsTextFieldValue(savedSelectionsCSV);
  }

  const [getSchoolsByIdTask, { error, data }] = useLazyQuery(GET_SCHOOLS_BY_ID_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { schoolIds: schoolIDs},
    client: portalBffApolloClient,
    onCompleted: (data) => {
      if (data.getSchoolsBySchoolId.total > 0) {

        const checkSelections = data.getSchoolsBySchoolId.items.map(s => {
          return {
            ...s,
            isPostOnlyChecked: false
          }
        })

        setCheckboxSelections({
          variables: {
            checkboxSelections: checkSelections
          }
        });

        const responseSchoolIDs = data.getSchoolsBySchoolId.items.map(
          selection => selection.schoolId
        );
        const didSchoolFinderRemoveSelections =
          JSON.stringify(schoolIDs.sort()) !==
          JSON.stringify(responseSchoolIDs.sort());

        if (didSchoolFinderRemoveSelections) {
          displayErrorMessage(
            'Error: SchoolFinder was unable to find some of the schools from the list and removed them from the selections. Selections have been updated regardless.'
          );
          setSchoolIDs(responseSchoolIDs);
          setSchoolIDsTextFieldValue(responseSchoolIDs.join('\n'));
        } else {
          displaySuccessMessage('Successfully updated the school selection.');

        }

        setSelectedCount(data.getSchoolsBySchoolId.total);

        // prep the CSV
        setCsvData([
          ['schoolId', 'schoolName', 'districtId', 'districtName'],
          ...data.getSchoolsBySchoolId.items.map(s => {
            return [s.schoolId, s.name, s.districtId, s.district.name]
          })
        ])

        setIsLoading(false)

      } else {
        setIsLoading(false)
      }
    },
    onError: (error) => {
      displayErrorMessage(
        'Error: Occurred getting information for the list of Schools, update list of IDs to try again.'
      );
      console.log('Error: Sending school IDs to school finder', error);
      setIsLoading(false)
    }
  });

  useEffect(() => {
      setIsLoading(true)
      getSchoolsByIdTask();
  }, []);

  const setTextFieldValue = e => {
    setSchoolIDsTextFieldValue(e.target.value);
    setCsvData('');
  }

  const updateSchoolIDsFromSchoolIDTextField = e => {

    setIsLoading(true)

    const newSchoolIDList = schoolIDsTextFieldValue
      .split('\n')
      .map(schoolID => parseInt(schoolID.trim()));

    if (newSchoolIDList.includes(NaN)) {
      displayErrorMessage(
        'Error parsing the list of school IDs. Please enter a newline seperated list of numbers.'
      );
      setIsLoading(false)
    } else {
      if (
        newSchoolIDList.length &&
        !doTheseArraysContainTheSameValues(schoolIDs, newSchoolIDList)
      ) {
        setCsvData('');
        setSchoolIDs(newSchoolIDList);
        getSchoolsByIdTask();

      } else {
        setIsLoading(false)
      }
      
    }
  };

  return (
    <>
      <form className={classes.container} noValidate autoComplete="off">
        <TextField
          id="filled-full-width"
          style={{ margin: 8 }}
          className={classes.textField}
          multiline
          fullWidth
          rows={10}
          onChange={e => setTextFieldValue(e)}
          value={schoolIDsTextFieldValue}
          InputProps={{
            classes: {
              root: classes.inputRoot,
              notchedOutline: classes.notchedOutline,
            },
          }}
          variant="outlined"
        />
        {
          csvData.length > 1 ? 
          <div
            className={classes.linkContainer}
          >Selected {selectedCount.toLocaleString()} schools. <CSVLink 
            className={classes.link}
            filename={"schools-list.csv"}
            data={csvData}
            target="_blank" 
          >
            Download the list
          </CSVLink> to verify</div> : null
        }
        <ButtonSecondarySmall
          className={classes.updateButton}
          onClick={updateSchoolIDsFromSchoolIDTextField}
          isLoading={isLoading}
        >
          Update School Selection
        </ButtonSecondarySmall>
      </form>

    </>
  );
};
const mapDispatchToProps = dispatch => ({
  displayErrorMessage: errorMessage => {
    dispatch(notifyError(errorMessage));
  },
  displaySuccessMessage: successMessage => {
    dispatch(notifySuccess(successMessage));
  }
});

const enhance = compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
);

export default enhance(ManagedServices);
