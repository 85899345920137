import React from 'react';
import { css } from 'emotion';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { elements, colors } from '@peachjar/components';
import historyIcon from '../../assets/icon_approvalHistory.svg';
import { components } from '@peachjar/components';
import { APP_ROUTES } from '../../_app/constants';
import { withRouter } from 'react-router-dom';

const {
  typography: { Paragraph, Note, Headline2 },
} = elements;

const {
  Buttons: { ButtonPrimarySmall },
} = components;

const StarterKitBanner = ({
  history,
  headline,
  subheadline,
  cta,
  widgetHeadline1,
  widgetHeadline2,
  widgetHeadline3,
  widgetSubHeadline1,
  widgetSubHeadline2,
  widgetSubHeadline3,
  iconSrc = historyIcon,
  variant = 'default',
  classes,
  children,
  ...rest
}) => {
  return (
    <Grid item xs={12} {...rest}>
      <Paper className={`${cn.welcomeContent} ${classes.elevation2} ${cn.pop}`}>
        <div className={cn.flexRow}>
          <div className={cn.icon}>
            <img src={iconSrc} alt="Metrics Icon" />
          </div>
          <div className={cn.kicker}>
            <h3 className={cn.headline}>{headline}</h3>
            <Paragraph>{subheadline}</Paragraph>
            <ButtonPrimarySmall
              className={`${cn.primaryBtn} ${classes.primaryBtn}`}
              onClick={() => {
                history.push(APP_ROUTES.deals);
              }}
            >
              {cta}
            </ButtonPrimarySmall>
          </div>

          <div className={isIE ? cn.ieTextWidget : cn.textWidget}>
            <div className={cn.textSubContainer}>
              <Headline2 className={cn.widgetHeadline}>
                {widgetHeadline2}
              </Headline2>
              <Note className={cn.widgetSubHeadline}>{widgetSubHeadline2}</Note>
            </div>
          </div>
          <div className={isIE ? cn.ieTextWidget : cn.textWidget}>
            <div className={cn.textSubContainer}>
              <Headline2 className={cn.widgetHeadline}>
                {widgetHeadline3}
              </Headline2>
              <Note className={cn.widgetSubHeadline}>{widgetSubHeadline3}</Note>
            </div>
          </div>
          <div className={isIE ? cn.ieTextWidget : cn.textWidget}>
            <div className={cn.textSubContainer}>
              <Headline2 className={cn.widgetHeadline}>
                {widgetHeadline1}
              </Headline2>
              <Note className={cn.widgetSubHeadline}>{widgetSubHeadline1}</Note>
            </div>
          </div>
        </div>
        {children && (
          <div className={cn.flexColumn}>
            <div className={cn.ctasContainer}>
              <div className={cn.ctas}>{children}</div>
            </div>
          </div>
        )}
      </Paper>
    </Grid>
  );
};

const isIE =
  window.location.hash === !!window.MSInputMethodContext &&
  !!document.documentMode;

// Override default material-ui styles
const materialOverrides = {
  elevation2: {
    boxShadow: 'none',
  },
  primaryBtn: {
    display: 'flex',
    marginTop: '16px',
    marginBottom: '8px',
  },
};

const cn = {
  ctas: css`
    margin-top: 14px;
  `,
  ctasContainer: css`
    padding-top: 4px;
    padding-left: 66px;
    display: flex;
    flex-direction: column;
  `,
  flexColumn: css`
    display: flex;
    flex-direction: column;
  `,
  flexRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  headline: css`
    font-size: 17px;
    font-weight: 600;
  `,
  icon: css`
    padding: 0 14px 0 0;
  `,
  ieTextWidget: css`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 10px;
    padding-right: 10px;
    width: 27%;
    height: 0%;
    background: ${colors.platinum};
    border-radius: 3px;
    margin: 4px;
  `,
  kicker: css`
    padding-top: 9px;
    width: 32%;
  `,

  pop: css`
    border-top: 3px solid #61c16c;
  `,
  primaryBtn: css`
    display: flex;
  `,
  textWidget: css`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding-top: 24px;
    padding-right: 12px;
    padding-bottom: 24px;
    padding-left: 12px;
    width: 27%;
    height: 0%;
    background: ${colors.platinum};
    border-radius: 3px;
    margin: 4px;
  `,

  textSubContainer: css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
  welcomeContent: css`
    padding: 16px 16px 16px 16px;
  `,
  widgetHeadline: css`
    text-align: center;
    padding: 0px;
    line-height: 1.3;
  `,
  widgetSubHeadline: css`
    text-align: center;
    line-height: 1.3;
  `,
};

export default withRouter(withStyles(materialOverrides)(StarterKitBanner));
