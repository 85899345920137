import React from 'react';

import { css } from 'react-emotion';
import { colors } from '@peachjar/components';

const { prussian, silver, white } = colors;

const styles = {
  selectionBox: css`
    background-color: ${white};
    border-radius: 7px;
    min-height: 100px;
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }
  `,
  unselected: css`
    border: 1px solid ${silver};
  `,
  selected: css`
    border: 1px solid ${prussian};
  `,
};

const SelectionBox = ({
  children,
  className,
  dataTestId,
  isSelected,
  ...props
}) => (
  <button
    type="button"
    className={`flex ${styles.selectionBox} ${
      isSelected ? styles.selected : styles.unselected
    } ${className}`}
    data-testid={dataTestId}
    {...props}
  >
    {children}
  </button>
);

export default SelectionBox;
