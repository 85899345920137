import { get } from 'lodash';
import config from '../../config';
import * as SI from './Stripe.types';

const { STRIPE_PUBLISHABLE_API_KEY } = config;

declare global {
  interface Window {
    Stripe: any;
  }
}

class StripeStub {
  constructor(...args: any[]) {}
    // tslint:disable-next-line
    elements() {
      return  {
        create() {
          //
        }
      }
    }
}

export default function useStripe(): SI.IUseStripeAPI {
  const StripeV3 = get(window, 'Stripe', StripeStub);
  const stripe = new StripeV3(STRIPE_PUBLISHABLE_API_KEY);
  const elements = stripe.elements();

  const createElement = (stripeElement: SI.IStripeElement): any => {
    const { type, options = {} } = stripeElement;

    // create an instance of a stripe element
    const element = elements.create(type, {...options });
    // return the element to perform other UI actions (example: mounting the element to the DOM)
    return element;
  };

  const getStripeToken = async (data: SI.IStripeForm): Promise<SI.IStripeResponse> => {
    const { element } = data;
    const { token, error: stripeError } = await stripe.createToken(element);

    return {
      error: stripeError ? stripeError.message : null,
      token: stripeError ? '' : token.id,
    };
  };

  return {
    createElement,
    getStripeToken
  };
}
