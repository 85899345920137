
import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { Mutation } from 'react-apollo';
import idx from 'idx';
import { components } from '@peachjar/components';

import FlyerReasonModal from './FlyerReasonModal';
import { DeclineReason } from '../../approvals.duck';
import {
  DeletionReasonModalContainer_flyerReasons
} from './__generated__/DeletionReasonModalContainer_flyerReasons';
import {
  STRINGS,
  MAX_ALLOWED_DENIAL_REASON_EXPLANATION_CHARACTERS,
} from '../../constants';

const { notifySuccess, notifyError } = components.Notifications;

const SUBMIT_FLYER_DELETION_UPDATE_MUTATION = gql`
  mutation SubmitFlyerDeletionUpdateMutation($input: DeleteFlyerInput!) {
    deleteFlyer(input: $input) {
      id
    }
  }
`;

type ReduxDispatchProps = {
   handleSuccess: () => void,
   handleError: () => void,
};

type OwnProps = {
   approvalIdsForDeletion: [string], // TODO: type this
   closeModal: () => void,
   flyerTitle: string,
   districtName?: string,
   flyerReasons: DeletionReasonModalContainer_flyerReasons[],
};

export type Props = OwnProps & ReduxDispatchProps;

type State = {
  deletionReason?: DeclineReason,
  deletionReasonExplanation?: string,
};

type ReasonNames = {
  label?: string,
  value?: string,
}[];

export class DeletionReasonModalContainer extends React.Component<
  Props,
  State
> {
  static fragments: { [key: string]: any };

  state = {
    deletionReason: null,
    deletionReasonExplanation: null,
  };

  onReasonChange = (reasonNames: ReasonNames) => {
    return (e: any) => {
      const val = idx(e, _ => _.target.value) || '';

      if (reasonNames.map(r => r.value).includes(val)) {
        this.setState({
          deletionReason: val,
        });
      }
    }
  };

  onReasonExplanationChange = (
    e: SyntheticKeyboardEvent<HTMLInputElement>
  ): void => {
    // Prevent against pasting or typing in explanations over predefined character limit
    const explanation = idx(e, _ => _.currentTarget.value) || '';

    if (explanation.length > MAX_ALLOWED_DENIAL_REASON_EXPLANATION_CHARACTERS) {
      const shortenedExplanation = explanation.slice(
        0,
        MAX_ALLOWED_DENIAL_REASON_EXPLANATION_CHARACTERS
      );
      this.setState({ deletionReasonExplanation: shortenedExplanation });
    } else {
      this.setState({ deletionReasonExplanation: explanation });
    }
  };

  handleSubmit = (deleteFlyerMutation: Function): Function => (): void => {
    const { deletionReason, deletionReasonExplanation } = this.state;
    const { approvalIdsForDeletion } = this.props;

    deleteFlyerMutation({
      variables: {
        input: {
          approvalIds: approvalIdsForDeletion,
          deletionReason,
          ...(deletionReasonExplanation ? { deletionReasonExplanation } : {}),
        },
      },
    });
  };

  render() {
    const { closeModal, handleSuccess, handleError, flyerReasons, flyerTitle, districtName = '' } = this.props;
    const { deletionReason, deletionReasonExplanation } = this.state;
    // Deletion reason select options
    const deletionReasonNames = flyerReasons
      .filter(r => (r.isDeletion && !r.retiredAt))
      .map(r => ({value: r.code, label: r.displayName}));
    // Deletion reason code -> verbiage lookup
    const deletionReasonVerbiages = flyerReasons
      .filter(r => (r.isDeletion && !r.retiredAt))
      .reduce((acc, r, idx) => {
        return {
          ...acc,
          [r.code]: r.suggestedVerbiage,
        };
      }, {});

    return (
      <Mutation
        mutation={SUBMIT_FLYER_DELETION_UPDATE_MUTATION}
        onCompleted={handleSuccess}
        onError={handleError}
        awaitRefetchQueries
        refetchQueries={() => ['ViewerQuery']}
      >
        {(deleteFlyerMutation, { loading }) => (
          <FlyerReasonModal
            closeModal={closeModal}
            onSubmit={this.handleSubmit(deleteFlyerMutation)}
            onReasonChange={this.onReasonChange(deletionReasonNames)}
            onReasonExplanationChange={this.onReasonExplanationChange}
            reason={deletionReason}
            reasonExplanation={deletionReasonExplanation}
            reasonNames={deletionReasonNames}
            reasonVerbiages={deletionReasonVerbiages}
            isSubmitButtonLoading={loading}
            sellerCompanyName={flyerTitle}
            districtName={districtName}
            isDenialMode={false}
          />
        )}
      </Mutation>
    );
  }
}

DeletionReasonModalContainer.fragments = {
  flyerReasons: gql`
    fragment DeletionReasonModalContainer_flyerReasons on FlyerReason {
      code
      displayName
      suggestedVerbiage
      isDeletion
      retiredAt
    }
  `,
}

const mapDispatchToProps = (
  dispatch,
  { closeModal }: OwnProps
): ReduxDispatchProps => ({
  handleSuccess: () => {
    dispatch(notifySuccess(STRINGS.DELETE_FLYER_SUCCESS_MESSAGE));
    closeModal();
  },
  handleError: () => {
    dispatch(notifyError(STRINGS.DELETE_FLYER_ERROR_MESSAGE));
    closeModal();
  },
});

export default connect(
  null,
  mapDispatchToProps
)(DeletionReasonModalContainer);
