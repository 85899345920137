export const getDistrictRemoved = (districtId: number, approvalRemovalSet) => {
  let district = 0;
  let removedDistrict = 0;
  approvalRemovalSet.forEach(item => {
    if (item.districtId === districtId) {
      district++;
    }
    if (
      item.districtId === districtId &&
      item.distributionStatus === 'removed'
    ) {
      removedDistrict++;
    }
  });

  return district === removedDistrict;
};

export const getCheckedDistrictPartial = (
  districtId: number,
  approvalRemovalSet
) => {
  let districtArr: any[] = [];
  let checkedSet = 0;
  approvalRemovalSet.forEach(item => {
    if (
      item.districtId === districtId &&
      item.distributionStatus !== 'removed' &&
      item.distributionStatus !== 'declined'
    ) {
      districtArr.push(item);
    }
  });
  for (let i = 0; i < districtArr.length; i++) {
    if (districtArr[i].status === true) {
      checkedSet++;
    }
  }

  return checkedSet > 0 && checkedSet < districtArr.length;
};

export const getDistrictDisabled = (districtId: number, approvalRemovalSet) => {
  let districtCount = 0;
  let invalidCount = 0;
  approvalRemovalSet.forEach(item => {
    if (item.districtId === districtId) {
      districtCount++;
    }
    if (
      item.districtId === districtId &&
      (item.distributionStatus === 'removed' ||
        item.distributionStatus === 'declined')
    ) {
      invalidCount++;
    }
  });
  return districtCount === invalidCount ? true : false;
};

export const getCheckedAll = approvalRemovalSet => {
  let initialCheck = 0;
  for (let i = 0; i < approvalRemovalSet.length; i++) {
    if (
      approvalRemovalSet[i].distributionStatus !== 'removed' &&
      approvalRemovalSet[i].distributionStatus !== 'declined'
    ) {
      initialCheck++;
    }
  }
  if (initialCheck === 0) {
    return false;
  }
  let subSet = approvalRemovalSet.filter(obj => {
    return (
      obj.distributionStatus !== 'declined' &&
      obj.distributionStatus !== 'removed'
    );
  });
  for (let i = 0; i < subSet.length; i++) {
    if (subSet[i].status === false) {
      return false;
    }
  }
  return true;
};

export const getCheckedAllPartial = approvalRemovalSet => {
  if (getCheckedAll(approvalRemovalSet)) {
    return false;
  }
  let subSet = approvalRemovalSet.filter(obj => {
    return (
      obj.distributionStatus !== 'declined' &&
      obj.distributionStatus !== 'removed'
    );
  });
  for (let i = 0; i < subSet.length; i++) {
    if (subSet[i].status === true) {
      return true;
    }
  }
  return false;
};

export const getAllDisabled = approvalRemovalSet => {
  let disabledCount = 0;
  approvalRemovalSet.forEach(item => {
    if (
      item.distributionStatus === 'removed' ||
      item.distributionStatus === 'declined'
    ) {
      disabledCount++;
    }
  });
  return approvalRemovalSet.length === disabledCount ? true : false;
};

export const getCheckedDistrict = (districtId: number, approvalRemovalSet) => {
  let districtArr: any[] = [];
  let holder = true;
  approvalRemovalSet.forEach(item => {
    if (
      item.districtId === districtId &&
      item.distributionStatus !== 'removed' &&
      item.distributionStatus !== 'declined'
    ) {
      districtArr.push(item);
    }
  });
  if (!districtArr.length) {
    return false;
  }
  for (let i = 0; i < districtArr.length; i++) {
    if (districtArr[i].status === false) {
      holder = false;
    }
  }
  return holder;
};

export const handleChange = (
  id: number,
  approvalRemovalSet,
  setApprovalRemovalSet,
  setShowError
) => {
  let newArr = [...approvalRemovalSet];
  let changedApproval = newArr.find(approval => {
    return approval.id === id;
  });
  changedApproval.status = !changedApproval.status;
  if (changedApproval.status === true) {
    setShowError(false);
  }

  setApprovalRemovalSet(newArr);
};

export const getApprovalIdArray = approvalRemovalSet => {
  let arr: any[] = [];
  approvalRemovalSet
    .filter(obj => {
      return obj.status === true;
    })
    .forEach(obj => {
      arr.push(obj.id);
    });
  return arr.length ? arr.sort() : false;
};

export const handleDistrictSelect = (
  event,
  approvalRemovalSet,
  setApprovalRemovalSet,
  setShowError
) => {
  let newArr = [...approvalRemovalSet];
  let updatedSubset = newArr.filter(approval => {
    return approval.districtId.toString() === event.target.value;
  });
  updatedSubset.forEach(obj => {
    if (
      obj.distributionStatus !== 'removed' &&
      obj.distributionStatus !== 'declined'
    ) {
      return (obj.status = event.target.checked);
    }
  });
  if (event.target.checked === true) {
    setShowError(false);
  }
  setApprovalRemovalSet(newArr);
};

export const getCheckedItem = (id, approvalRemovalSet) => {
  let holder = false;
  approvalRemovalSet.forEach(item => {
    if (item.id === id) {
      holder = item.status;
    }
  });
  return holder;
};

export const handleSelectAll = (
  event,
  approvalRemovalSet,
  setApprovalRemovalSet,
  setShowError
) => {
  let newArr = [...approvalRemovalSet];
  newArr.forEach(obj => {
    if (
      obj.distributionStatus !== 'removed' &&
      obj.distributionStatus !== 'declined'
    ) {
      return (obj.status = event.target.checked);
    }
  });
  if (event.target.checked === true) {
    setShowError(false);
  }
  setApprovalRemovalSet(newArr);
};

export const setInitialApprovalState = (
  alphabetizedByDistrict,
  setApprovalRemovalSet
) => {
  const createInitialState = alphabetizedByDistrict.map(approval => {
    let approvalObj: any = {};
    approvalObj.id = approval.id;
    approvalObj.districtId = approval.districtId;
    approvalObj.status = false;
    approvalObj.distributionStatus = approval.distributionStatus;
    return approvalObj;
  });
  setApprovalRemovalSet(createInitialState);
};
