import React, { useState, useEffect } from 'react';
import idx from 'idx';
import PromoCodeInput from './PromoCodeInput';

const PromoCodeContainer: React.FunctionComponent = ({
  handlePromoCodeSubmission,
}) => {
  const [promoCode, setPromoCode] = useState({
    value: '',
    dirty: false,
  });
  const [isHovering, setIsHovering] = useState(false);
  const [panelExpanded, setPanelExpanded] = useState(false);

  let inputEl;

  const handleInputBlur = () => {
    if (!promoCode.value && !isHovering) {
      setPanelExpanded(false);
    }
    setPromoCode({ ...promoCode });
  };

  const handlePanelChange = () => {
    if (!panelExpanded) {
      inputEl.focus();
    }
  };

  const handleFocus = () => {
    if (inputEl) {
      inputEl.select();
    }
  };

  const handleSubmit = () => {
    if (promoCode.value) {
      handlePromoCodeSubmission(promoCode.value);
    }
  };

  const handleKeysSubmit = evt => {
    if (evt.which === 13 || evt.keyCode === 13) {
      evt.preventDefault();
      handleSubmit();
    }
  };

  const registerInput = (name: string) =>
    (inputEl = document.querySelector(`[name="${name}"]`));

  const mergedProps = {
    promoCode,
    isHovering,
    panelExpanded,
    onExpand: setPanelExpanded,
    onHover: setIsHovering,
    onUpdate: setPromoCode,
    onBlur: handleInputBlur,
    onFocus: handleFocus,
    registerInput,
    onPanelChange: handlePanelChange,
    handleSubmit,
    handleKeysSubmit,
  };

  return <PromoCodeInput {...mergedProps} />;
};

export default PromoCodeContainer;
