import { withRouter } from 'react-router-dom';
import { omit, isEmpty, get } from 'lodash';
import { graphql, compose } from 'react-apollo';
import Details from './Details';

import {
  DETAILS_QUERY,
  SET_DETAILS_MUTATION,
  SET_DETAILS_BOOLEAN_MUTATION,
  HANDLE_PRIMARY_CTA_SELECTION_MUTATION,
  HANDLE_SECONDARY_CTA_SELECTION_MUTATION,
  HANDLE_CTA_DATA_MUTATION,
  HANDLE_CAMPAIGN_DETAILS_FORM_VALIDATION_MUTATION,
} from './gql/Details.graphql';
import { SET_DELIVERY_CACHE_MUTATION } from '../Delivery/gql/Delivery.graphql';
import { DELIVERY_QUERY } from '../Delivery/gql/Delivery.graphql';

export const detailsConfig = {
  props: ({
    ownProps,
    data: {
      loading,
      campaignDetails: {
        flyerTitle,
        participationFee,
        lowParticipationFee,
        highParticipationFee,
        eventLocation,
        deadlineDate,
        startDate,
        endDate,
        categoryMain,
        categoryAdd1,
        categoryAdd2,
        isPrimaryCTASelected,
        primaryCTA,
        secondaryCTA,
        primaryCTAData,
        secondaryCTAData,
        formValidation,
      },
    },
  }: any) => ({
    ...ownProps,
    loadingDetails: loading,
    flyerTitle,
    participationFee,
    lowParticipationFee,
    highParticipationFee,
    eventLocation,
    deadlineDate,
    startDate,
    endDate,
    categoryMain,
    categoryAdd1,
    categoryAdd2,
    isPrimaryCTASelected,
    primaryCTA,
    secondaryCTA,
    primaryCTAData,
    secondaryCTAData,
    formValidation,
  }),
};

export const deliveryConfig = {
  props: ({
    ownProps,
    data: {
      loading,
      campaignDelivery: {
        campaignStartDate,
        postExpirationDate,
        numberOfDistributions,
        selectedAudience,
        checkboxSelections,
      },
    },
  }) => {
    // this is due to apollo link state not allowing district
    // within checkboxSelections to be null for some reason
    const cleanedCheckboxSelections = checkboxSelections.map(
      checkboxSelection => {
        const district = get(checkboxSelection, 'district');
        return typeof district === 'object' && isEmpty(district)
          ? omit(checkboxSelection, 'district')
          : checkboxSelection;
      }
    );

    return {
      ...ownProps,
      loadingDelivery: loading,
      campaignStartDate,
      postExpirationDate,
      numberOfDistributions,
      selectedAudience,
      checkboxSelections: cleanedCheckboxSelections,
    };
  },
};

export default compose(
  graphql(DETAILS_QUERY, detailsConfig),
  graphql(DELIVERY_QUERY, deliveryConfig),
  graphql(SET_DETAILS_MUTATION, { name: 'setDetails' }),
  graphql(SET_DELIVERY_CACHE_MUTATION, { name: 'setDelivery' }),
  graphql(SET_DETAILS_BOOLEAN_MUTATION, { name: 'setDetailsBoolean' }),
  graphql(HANDLE_PRIMARY_CTA_SELECTION_MUTATION, {
    name: 'handlePrimaryCTASelection',
  }),
  graphql(HANDLE_SECONDARY_CTA_SELECTION_MUTATION, {
    name: 'handleSecondaryCTASelection',
  }),
  graphql(HANDLE_CTA_DATA_MUTATION, { name: 'handleCTAData' }),
  graphql(HANDLE_CAMPAIGN_DETAILS_FORM_VALIDATION_MUTATION, {
    name: 'handleCampaignDetailsFormValidation',
  })
)(withRouter(Details));
