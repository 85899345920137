import React from 'react';
import { css } from 'emotion';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { colors, components } from '@peachjar/components';

const {
  Buttons: { ButtonFlatSmall },
} = components;

const SearchDetails = ({
  totalCount,
  countPerPage,
  pageNumber,
  searchQuery,
  onReset,
  classes,
}) => {
  const getShownResultsDetails = () => {
    let fromCount = countPerPage * pageNumber - (countPerPage - 1);
    let toCount = fromCount + countPerPage - 1;
    if (toCount > totalCount) {
      toCount = totalCount;
    }
    if (totalCount === 0) {
      fromCount = 0;
    }
    return `${fromCount} - ${toCount} of ${totalCount} matching "${searchQuery}"`;
  };

  return (
    <Grid item xs={12} className={cn.searchDetailsContainer}>
      <Paper className={`${cn.paper} ${classes.elevation2}`}>
        <div className={cn.searchDetails}>
          <p>{getShownResultsDetails()}</p>
        </div>
        <div className={cn.searchReset}>
          <ButtonFlatSmall onClick={onReset} className={cn.resetCTA}>
            Clear Results
          </ButtonFlatSmall>
        </div>
      </Paper>
    </Grid>
  );
};

const cn = {
  searchDetailsContainer: css``,
  paper: css`
    display: flex;
    flex-direction: row;
    padding: 13px 27px;
    justify-content: space-between;
  `,
  searchDetails: css`
    > p {
      color: ${colors.slate};
      margin: 0;
      padding: 3px 0 0 0;
    }
  `,
  searchReset: css`
    > p {
      margin: 0;
      padding: 0;
    }
  `,
  resetCTA: css`
    /* color: ${colors.jungle};

    &:hover {
      color: ${colors.jungle};
    } */
  `,
};

const styles = {
  elevation2: {
    boxShadow: 'none',
  },
};

export default withStyles(styles)(SearchDetails);
