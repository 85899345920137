import React from 'react';
import { components } from '@peachjar/components';
import { graphql, compose } from 'react-apollo';
import { some, get, isEmpty, omit } from 'lodash';
import {
  mapCampaignDetailsToGql,
  mapCampaignDeliveryToGql,
} from '../gql/utils';
import portalBffApolloClient from '../../../_app/apollo/portalBFF.apolloClient';
import { getFurthestCompletedTab } from '../../shared/utils';
import { gradesToAges } from '../../shared/constants';
import CAMPAIGN_DETAILS_VALIDATION_CLIENT_QUERY from '../gql/CampaignDetailsValidationClientQuery.graphql';
import SAVE_CAMPAIGN_MUTATION from '../gql/SaveCampaignMutation.graphql';
import { PREPARE_QUERY } from '../Prepare/gql/Prepare.graphql';
import { DETAILS_QUERY } from '../Details/gql/Details.graphql';
import { DELIVERY_QUERY } from '../Delivery/gql/Delivery.graphql';
import {
  ctaPrimaryKey,
  ctaPrimaryCTADataKey,
} from '../Details/components/CallsToAction/CallsToAction';

const { Tabber: PJComponentsTabber } = components;

const TabberWrapper = ({
  history,
  paramsCampaignId,
  saveCampaign,
  campaignPrepare,
  campaignDetails,
  campaignDeliverySettings,
  isCampaignDetailsFormValid,
  handleShouldPerformDisabledClickValidation,
  detailsLoaded,
  prepareLoaded,
  deliveryLoaded,
}) => {
  if (![detailsLoaded, prepareLoaded, deliveryLoaded].every(i => i)) {
    return null;
  }

  return (
    <PJComponentsTabber
      tabs={generateTabs(
        history,
        paramsCampaignId,
        saveCampaign,
        handleShouldPerformDisabledClickValidation,
        {
          campaignPrepare,
          campaignDetails,
          campaignDeliverySettings,
          isCampaignDetailsFormValid,
        }
      )}
    />
  );
};

const detailsConfig = {
  props: ({ ownProps, data }) => {
    if (data.loading) {
      return { ...ownProps, detailsLoaded: false };
    }
    return {
      ...ownProps,
      campaignDetails: data.campaignDetails,
      detailsLoaded: true,
    };
  },
};

const deliveryConfig = {
  props: ({ ownProps, data }) => {
    if (data.loading) {
      return { ...ownProps, deliveryLoaded: false };
    }

    const campaignDelivery = get(data, 'campaignDelivery', {});
    const checkboxSelections = get(
      data,
      'campaignDelivery.checkboxSelections',
      []
    );
    // this is due to apollo link state not allowing district
    // within checkboxSelections to be null for some reason
    const cleanedCheckboxSelections = checkboxSelections.map(
      checkboxSelection => {
        const district = get(checkboxSelection, 'district');
        return typeof district === 'object' && isEmpty(district)
          ? omit(checkboxSelection, 'district')
          : checkboxSelection;
      }
    );

    return {
      ...ownProps,
      campaignDeliverySettings: {
        ...campaignDelivery,
        checkboxSelections: cleanedCheckboxSelections,
      },
      deliveryLoaded: true,
    };
  },
};

const prepareConfig = {
  props: ({ ownProps, data }) => {
    if (data.loading) {
      return { ...ownProps, prepareLoaded: false };
    }

    return {
      ...ownProps,
      campaignPrepare: data.campaignPrepare,
      prepareLoaded: true,
    };
  },
};

export default compose(
  graphql(CAMPAIGN_DETAILS_VALIDATION_CLIENT_QUERY, {
    props: ({ data }) => {
      const isFormValid = get(
        data,
        'campaignDetails.formValidation.isFormValid',
        false
      );
      return { isCampaignDetailsFormValid: isFormValid };
    },
  }),
  graphql(DELIVERY_QUERY, deliveryConfig),
  graphql(DETAILS_QUERY, detailsConfig),
  graphql(PREPARE_QUERY, prepareConfig),
  graphql(SAVE_CAMPAIGN_MUTATION, {
    name: 'saveCampaign',
    options: {
      client: portalBffApolloClient,
      // refetchQueries: ['getMyCampaigns'],
      // awaitRefetchQueries: true,
    },
  })
)(TabberWrapper);
export const Tabber = React.memo(TabberWrapper);

export const generateTabs = (
  history,
  paramsCampaignId,
  saveCampaign,
  handleShouldPerformDisabledClickValidation,
  campaignData
) => {
  const {
    isCampaignDetailsFormValid,
    campaignSummary,
    campaignPrepare,
  } = campaignData;

  const { userTexts, flyerId, campaignId: id } = campaignPrepare || {};

  const campaignDetails = mapCampaignDetailsToGql({
    ...campaignData.campaignDetails,
    minGradeLevel: campaignData.campaignDeliverySettings.minGradeLevel,
    maxGradeLevel: campaignData.campaignDeliverySettings.maxGradeLevel,
  });
  const campaignDeliverySettings = mapCampaignDeliveryToGql(
    campaignData.campaignDeliverySettings
  );

  const campaignId = paramsCampaignId === 'new' ? id : paramsCampaignId;

  // Unlocking tabs when sending user to furthest completed route
  const unlockTab2 = getFurthestCompletedTab(campaignDetails) === 'details';
  const unlockTab3 =
    getFurthestCompletedTab(campaignDeliverySettings) === 'delivery';

  const primaryCTAType = localStorage.getItem(`${campaignId}-${ctaPrimaryKey}`);
  const primaryCTAData = localStorage.getItem(
    `${campaignId}-${ctaPrimaryCTADataKey}`
  );

  const tabs = [
    {
      id: 1,
      isActive: history.location.pathname.includes('prepare'),
      icon: 'LightUpload',
      label: 'Upload & Review Flyer',
      labelSubText: null,
      onClickHandler: () => {
        saveCampaign({
          variables: {
            campaignId,
            campaignPrepare: { flyerId, userTexts },
            campaignDetails,
            campaignDeliverySettings,
            campaignSummary,
          },
        }).then(() =>
          history.push(
            `/campaigns/create-campaign/${campaignId}/prepare/preview`
          )
        );
      },
      isLocked: false,
    },
    {
      id: 2,
      isActive: history.location.pathname.includes('details'),
      icon: 'PenAndPaper',
      label: 'Set Flyer Details',
      labelSubText: null,
      onClickHandler: () => {
        saveCampaign({
          variables: {
            campaignId,
            campaignPrepare: { flyerId, userTexts },
            campaignDetails:
              primaryCTAType && primaryCTAData
                ? {
                    ...campaignDetails,
                    primaryCallToAction: {
                      type: primaryCTAType,
                      data: JSON.parse(primaryCTAData),
                    },
                  }
                : campaignDetails,
            campaignDeliverySettings,
            campaignSummary,
          },
        }).then(() =>
          history.push(`/campaigns/create-campaign/${campaignId}/details`)
        );
      },
      isLocked:
        some(userTexts, userText => userText.length === 0) ||
        (unlockTab2 && !unlockTab2),
    },
    {
      id: 3,
      isActive: history.location.pathname.includes('delivery'),
      icon: 'CheckList',
      label: 'Pick Flyer Delivery',
      labelSubText: null,
      onClickHandler: () =>
        saveCampaign({
          variables: {
            campaignId,
            campaignPrepare: { flyerId, userTexts },
            campaignDetails,
            campaignDeliverySettings,
            campaignSummary,
          },
        }).then(() =>
          history.push(`/campaigns/create-campaign/${campaignId}/delivery`)
        ),
      lockedOnClickHandler: () =>
        handleShouldPerformDisabledClickValidation(true),
      isLocked: !isCampaignDetailsFormValid && !unlockTab3,
    },
  ];
  return tabs;
};
