import * as React from 'react';
import portalBffApolloClient from '../../../../_app/apollo/portalBFF.apolloClient';
import SAVE_CAMPAIGN_MUTATION from '../../gql/SaveCampaignMutation.graphql';
import MutationButton from '../../../../components/MutationButton';
import {
  mapCampaignDetailsToGql,
  mapCampaignDeliveryToGql,
} from '../../gql/utils';
import {
  ctaPrimaryKey,
  ctaPrimaryCTADataKey,
} from '../components/CallsToAction/CallsToAction';

// TODO: Review flow types after refactor
type Props = {
  campaignId: any;
  flyerTitle: any;
  participationFee: any;
  eventLocation: any;
  deadlineDate: any;
  startDate: any;
  endDate: any;
  categoryMain: any;
  categoryAdd1: any;
  categoryAdd2: any;
  handleSuccess: any;
  handleError: any;
  children: any;
  disabled: any;
  isPrimaryCTASelected: boolean;
  primaryCTA: any;
  primaryCTAData: any;
  isSecondaryCTASelected: boolean;
  secondaryCTA: any;
  secondaryCTAData: any;
};

const SaveCampaignDetailsButton = ({
  campaignId,
  flyerTitle,
  participationFee,
  eventLocation,
  deadlineDate,
  startDate,
  endDate,
  categoryMain,
  categoryAdd1,
  categoryAdd2,
  handleSuccess,
  handleError,
  children,
  disabled,
  isPrimaryCTASelected,
  primaryCTA,
  primaryCTAData,
  isSecondaryCTASelected,
  secondaryCTA,
  secondaryCTAData,
}: Props) => {
  const campaignDetails = mapCampaignDetailsToGql({
    flyerTitle,
    participationFee,
    eventLocation,
    deadlineDate,
    startDate,
    endDate,
    categoryMain,
    categoryAdd1,
    categoryAdd2,
    isPrimaryCTASelected,
    primaryCTA,
    primaryCTAData,
    isSecondaryCTASelected,
    secondaryCTA,
    secondaryCTAData,
  });

  const primaryCTAType = localStorage.getItem(`${campaignId}-${ctaPrimaryKey}`);
  const primaryCTADataFromMemory = localStorage.getItem(
    `${campaignId}-${ctaPrimaryCTADataKey}`
  );

  const mergedCampaignDetails =
    primaryCTAType && primaryCTAData
      ? {
          ...campaignDetails,
          primaryCallToAction: {
            type: primaryCTAType,
            data: JSON.parse(primaryCTADataFromMemory),
          },
        }
      : campaignDetails;

  const variables = { campaignId, campaignDetails: mergedCampaignDetails };

  return (
    <MutationButton
      client={portalBffApolloClient}
      mutation={SAVE_CAMPAIGN_MUTATION}
      variables={variables}
      // refetchQueries={() => ['getMyCampaigns']}
      handleSuccess={handleSuccess}
      handleError={handleError}
      disabled={disabled}
    >
      {children}
    </MutationButton>
  );
};

export default SaveCampaignDetailsButton;
