
import * as React from 'react';
import { filter } from 'graphql-anywhere';
import { components } from '@peachjar/components';

import {
  Sod,
  PendingApprovals_sodUser,
} from '../__generated__/PendingApprovals_sodUser';

import RegionSelectionModal from './RegionSelectionModal';

const { ButtonSecondarySmall } = components.Buttons;

type Props = {
   sodUser: PendingApprovals_sodUser,
   showModal: (component: React.Component, any: any) => void,
   closeModal: () => void,
   changeRegion: (sod: Sod, sodId: number, regionName: string) => void,
};

class RegionSelectionButton extends React.Component<Props> {
  static fragments: { [key: string]: any };

  componentDidMount() {
    const { showModal, closeModal, sodUser, changeRegion } = this.props;
    showModal(RegionSelectionModal, {
      sodUser: filter(RegionSelectionModal.fragments.sodUser, sodUser || {}),
      changeRegion,
      closeModal,
    });
  }

  render() {
    const { sodUser, changeRegion, showModal, closeModal } = this.props;

    return (
      <ButtonSecondarySmall
        onClick={() =>
          showModal(RegionSelectionModal, {
            sodUser: filter(
              RegionSelectionModal.fragments.sodUser,
              sodUser || {}
            ),
            changeRegion,
            closeModal,
          })
        }
      >
        Change Site
      </ButtonSecondarySmall>
    );
  }
}

export default RegionSelectionButton;
