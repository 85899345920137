import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import GradeLevels from '@peachjar/school-finder-api/dist/api/GradeLevels';
import { gradesToAges, gradeFilterDefault } from '../../../shared/constants';
import { getTypenamedCheckboxSelections } from '../../gql/utils';
// Defaults

export const deliveryDefaults = {
  campaignDelivery: {
    __typename: 'CampaignDelivery',
    minGradeLevel: gradeFilterDefault.min,
    maxGradeLevel: gradeFilterDefault.max,
    selectedAudience: 'parents',
    checkboxSelections: [],
    areDistrictGuidelinesConfirmed: false,
    campaignStartDate: null,
    numberOfDistributions: 1,
    postExpirationDate: null,
    formValidation: {
      __typename: 'CampaignDeliveryFormValidation',
      isFormValid: false,
    },
  },
};

// Queries
/* eslint-disable */

export const DELIVERY_QUERY = gql`
  query campaignDelivery {
    campaignDelivery @client {
      __typename
      minGradeLevel
      maxGradeLevel
      selectedAudience
      checkboxSelections {
        districtId
        schoolId
        name
        ncesId
        audienceType
        lowestGradeOffered
        highestGradeOffered
        isPostOnly: orgPostOnly
        isOptionalPostOnly: orgOptionalPostOnly
        district {
          name
          url
          isPostOnly: orgPostOnly
          isOptionalPostOnly: orgOptionalPostOnly
        }
      }
      areDistrictGuidelinesConfirmed
      campaignStartDate
      numberOfDistributions
      postExpirationDate
      formValidation {
        __typename
        isFormValid
      }
    }
  }
`;

// TODO: Determine if we want a flexible mutation handler, that accepts multiple types. Instead of writing mutations for each item in cache.

export const SET_DELIVERY_CACHE_MUTATION = gql`
  mutation setDeliveryCache($name: String!, $value: StringOrNumber!) {
    setDeliveryCache(name: $name, value: $value) @client
  }
`;

export const SET_SELECTED_AUDIENCE_MUTATION = gql`
  mutation setSelectedAudience($type: String!) {
    setSelectedAudience(type: $type) @client
  }
`;

export const SET_CHECKBOX_SELECTIONS_MUTATION = gql`
  mutation setCheckboxSelections($checkboxSelections: [Audience]!) {
    setCheckboxSelections(checkboxSelections: $checkboxSelections) @client
  }
`;

export const TOGGLE_DISTRICT_GUIDELINES_CONFIRMED_MUTATION = gql`
  mutation toggleDistrictGuidelinesConfirmed($isConfirmed: Boolean!) {
    toggleDistrictGuidelinesConfirmed(isConfirmed: $isConfirmed) @client
  }
`;

export const REHYDRATE_DELIVERY_CACHE = gql`
  mutation rehydrateDeliveryCache(
    $minGradeLevel: String
    $maxGradeLevel: String
    $campaignStartDate: Date
    $postExpirationDate: Date
    $numberOfDistributions: Int
    $areDistrictGuidelinesConfirmed: Boolean
    $checkboxSelections: CheckboxSelections
    $selectedAudience: String
  ) {
    rehydrateDeliveryCache(
      minGradeLevel: $minGradeLevel
      maxGradeLevel: $maxGradeLevel
      campaignStartDate: $campaignStartDate
      postExpirationDate: $postExpirationDate
      numberOfDistributions: $numberOfDistributions
      areDistrictGuidelinesConfirmed: $areDistrictGuidelinesConfirmed
      checkboxSelections: $checkboxSelections
      selectedAudience: $selectedAudience
    ) @client
  }
`;

export const HANDLE_CAMPAIGN_DELIVERY_FORM_VALIDATION_MUTATION = gql`
  mutation handleCampaignDeliveryFormValidation(
    $formValidation: CampaignDeliveryFormValidation
  ) {
    handleCampaignDeliveryFormValidation(formValidation: $formValidation)
      @client
  }
`;
/* eslint-enable */

// Trigger Functions

export const setSelectedAudience = (_obj, { type }, { cache }) => {
  cache.writeQuery({
    query: DELIVERY_QUERY,
    data: {
      campaignDelivery: {
        __typename: 'CampaignDelivery',
        selectedAudience: type,
      },
    },
  });
  return null;
};

export const setCheckboxSelections = (
  _obj,
  { checkboxSelections },
  { cache }
) => {
  // unfortunately, until we can get the schema codegen working again,
  // we need to generate our own type(typename) to send to apollo
  const typenamedCheckboxSelections = getTypenamedCheckboxSelections(
    checkboxSelections
  );

  cache.writeQuery({
    query: DELIVERY_QUERY,
    data: {
      campaignDelivery: {
        __typename: 'CampaignDelivery',
        checkboxSelections: typenamedCheckboxSelections,
      },
    },
  });
  return null;
};

export const toggleDistrictGuidelinesConfirmed = (
  _obj,
  { isConfirmed },
  { cache }
) => {
  cache.writeQuery({
    query: DELIVERY_QUERY,
    data: {
      campaignDelivery: {
        __typename: 'CampaignDelivery',
        areDistrictGuidelinesConfirmed: isConfirmed,
      },
    },
  });
  return null;
};

export const setDeliveryCache = (_obj, { name, value }, { cache }) => {
  cache.writeQuery({
    query: DELIVERY_QUERY,
    data: {
      campaignDelivery: {
        __typename: 'CampaignDelivery',
        [name]: value,
      },
    },
  });
};

export const rehydrateDeliveryCache = (
  _obj,
  {
    minGradeLevel,
    maxGradeLevel,
    campaignStartDate,
    numberOfDistributions,
    postExpirationDate,
    areDistrictGuidelinesConfirmed,
    checkboxSelections,
    selectedAudience,
  },
  { cache }
) => {
  const typenamedCheckboxSelections = getTypenamedCheckboxSelections(
    checkboxSelections
  );

  cache.writeQuery({
    query: DELIVERY_QUERY,
    data: {
      campaignDelivery: {
        __typename: 'CampaignDelivery',
        minGradeLevel: minGradeLevel || '',
        maxGradeLevel: maxGradeLevel || '',
        campaignStartDate: campaignStartDate
          ? new Date(campaignStartDate)
          : null,
        postExpirationDate: postExpirationDate
          ? new Date(postExpirationDate)
          : null,
        numberOfDistributions,
        areDistrictGuidelinesConfirmed,
        checkboxSelections: typenamedCheckboxSelections,
        selectedAudience,
      },
    },
  });
  return null;
};

export const handleCampaignDeliveryFormValidation = (
  _obj,
  { formValidation },
  { cache }
) => {
  cache.writeQuery({
    query: DELIVERY_QUERY,
    data: {
      campaignDelivery: {
        __typename: 'CampaignDeliveryFormValidation',
        formValidation,
      },
    },
  });
  return null;
};

// Resolvers

export const deliveryResolvers = {
  setSelectedAudience,
  setCheckboxSelections,
  toggleDistrictGuidelinesConfirmed,
  setDeliveryCache,
  rehydrateDeliveryCache,
  handleCampaignDeliveryFormValidation,
};
