import CallsToAction from './CallsToAction';
import withValidation from '../../../../shared/components/withValidation';

// NOTE: Edge case bug, if you select a Secondary CTA and click finish later, and immediately edit the same flyer, validation thinks isSecondaryCTASelected in validate is true. Tested like 50 times, and shoudn't come up in beta. We need to refactor CTAs all together soon.

// CallsToAction is valid when either CTA is selected and valid
export const validate = ({
  isPrimaryCTASelected,
  isPrimaryCTAValid,
  isSecondaryCTASelected,
  isSecondaryCTAValid,
}) => {
  if (isPrimaryCTASelected === true) {
    if (isSecondaryCTASelected === true) {
      return isPrimaryCTAValid === true && isSecondaryCTAValid === true;
    }
    return isPrimaryCTAValid === true;
  }

  if (isSecondaryCTASelected === true) {
    return isSecondaryCTAValid === true;
  }
  return true;
};

const validateOnMount = (
  { isPrimaryCTASelected, secondaryCTA },
  { isPrimaryCTAValid, isSecondaryCTAValid }
) => {
  const isSecondaryCTASelected =
    secondaryCTA !== undefined && secondaryCTA !== '' && secondaryCTA !== null;
  return validate({
    isPrimaryCTASelected,
    isPrimaryCTAValid,
    isSecondaryCTASelected,
    isSecondaryCTAValid,
  });
};

export default withValidation(CallsToAction, validate, validateOnMount);
