import gql from 'graphql-tag';

const FLYER_DETAILS_QUERY = gql`
  query flyerDetails($id: Int) {
    sodUser: profile {
      id
      userId
      flyerReporting {
        flyerDetailReport(id: $id) {
          flyerId
          campaign {
            id
            legacyFlyerId
            ownerId
            createdBy
            title
            minGradeLevel
            maxGradeLevel
            sellerCompanyName
            submitDate: submittedAt
            participationFee
            eventLocation
            deadlineDate
            startDate
            endDate
            primaryCategory
            optionalCategories
            primaryCallToAction {
              id
              type
              data
            }
            secondaryCallToAction {
              id
              type
              data
            }
            deliverySettings {
              id
              targetAudiences
              selectedAudience
              startDate
            }
            flyer {
              id
              numberOfPages
              flyerPages {
                id
                jpegUrl
              }
            }
          }
          approvals {
            id
            districtId
            schoolId
          }
          approvalStatusCounts {
            approvedStatusCount
            pendingStatusCount
            deniedStatusCount
            totalCount
          }
          flyerReport {
            flyerId
            title
            expirationDate
            thumbnailUrl
            distributions
            distributionStatus
            submittedDate
            distributionsFirstDate
            distributionsSchoolCount
            removalDate
            statusBeforeRemoval
          }
        }
      }
    }
  }
`;
export { FLYER_DETAILS_QUERY };
