
import * as React from 'react';
import { Location } from 'react-router-dom';
// import { GraphQLStuff } from '../types';

type Props = {
  //  gqlStuff: GraphQLStuff,
   location: Location,
};

const ParentsManagement = ({ location }: Props) => (
  <pre>
    <code>{JSON.stringify(location, null, 4)}</code>
  </pre>
);

export default ParentsManagement;
