import gql from 'graphql-tag';

// Defaults

export const detailsDefaults = {
  campaignDetails: {
    __typename: 'CampaignDetails',
    flyerTitle: '',
    participationFee: '',
    lowParticipationFee: '',
    highParticipationFee: '',
    eventLocation: '',
    deadlineDate: null,
    startDate: null,
    endDate: null,
    categoryMain: null,
    categoryAdd1: null,
    categoryAdd2: null,
    isPrimaryCTASelected: false,
    primaryCTA: '',
    secondaryCTA: '',
    primaryCTAData: {
      __typename: 'CampaignDetails',
      websiteUrl: '',
    },
    secondaryCTAData: {
      __typename: 'CampaignDetails',
      androidAppUrl: '',
      iosAppUrl: '',
      webAppUrl: '',
      phoneNumber: '',
      websiteUrl: '',
      donateWebsiteUrl: '',
      email: '',
    },
    formValidation: {
      __typename: 'CampaignDetails',
      isFormValid: false,
    },
  },
};

// Queries
/* eslint-disable */
export const DETAILS_QUERY = gql`
  query campaignDetails {
    campaignDetails @client {
      __typename
      flyerTitle
      participationFee
      lowParticipationFee
      highParticipationFee
      eventLocation
      deadlineDate
      startDate
      endDate
      categoryMain
      categoryAdd1
      categoryAdd2
      isPrimaryCTASelected
      primaryCTA
      secondaryCTA
      primaryCTAData {
        __typename
        websiteUrl
      }
      secondaryCTAData {
        __typename
        webAppUrl
        phoneNumber
        websiteUrl
        donateWebsiteUrl
        email
      }
      formValidation {
        __typename
        isFormValid
      }
    }
  }
`;

export const SET_DETAILS_MUTATION = gql`
  mutation setDetails($name: String!, $value: String!) {
    setDetails(name: $name, value: $value) @client
  }
`;

export const SET_DETAILS_BOOLEAN_MUTATION = gql`
  mutation setDetailsBoolean($name: String!, $value: Boolean!) {
    setDetailsBoolean(name: $name, value: $value) @client
  }
`;

export const HANDLE_PRIMARY_CTA_SELECTION_MUTATION = gql`
  mutation handlePrimaryCTASelection($name: String!) {
    handlePrimaryCTASelection(name: $name) @client
  }
`;

export const HANDLE_SECONDARY_CTA_SELECTION_MUTATION = gql`
  mutation handleSecondaryCTASelection($name: String!) {
    handleSecondaryCTASelection(name: $name) @client
  }
`;

export const REHYDRATE_DETAILS_CACHE = gql`
  mutation rehydrateDetailsCache(
    $flyerTitle: String
    $participationFee: String
    $lowParticipationFee: String
    $highParticipationFee: String
    $eventLocation: String
    $deadlineDate: Date
    $startDate: Date
    $endDate: Date
    $categoryMain: String
    $categoryAdd1: String
    $categoryAdd2: String
    $isPrimaryCTASelected: Boolean
  ) {
    rehydrateDetailsCache(
      flyerTitle: $flyerTitle
      participationFee: $participationFee
      lowParticipationFee: $lowParticipationFee
      highParticipationFee: $highParticipationFee
      eventLocation: $eventLocation
      deadlineDate: $deadlineDate
      startDate: $startDate
      endDate: $endDate
      categoryMain: $categoryMain
      categoryAdd1: $categoryAdd1
      categoryAdd2: $categoryAdd2
      isPrimaryCTASelected: $isPrimaryCTASelected
    ) @client
  }
`;

export const HANDLE_CTA_DATA_MUTATION = gql`
  mutation handleCTAData($name: String, $data: CTAData) {
    handleCTAData(name: $name, data: $data) @client
  }
`;

export const HANDLE_CAMPAIGN_DETAILS_FORM_VALIDATION_MUTATION = gql`
  mutation handleCampaignDetailsFormValidation(
    $formValidation: CampaignDetailsFormValidation
  ) {
    handleCampaignDetailsFormValidation(formValidation: $formValidation) @client
  }
`;
/* eslint-enable */
// Trigger Functions

export const setDetails = (_obj, { name, value }, { cache }) => {
  cache.writeQuery({
    query: DETAILS_QUERY,
    data: {
      campaignDetails: {
        __typename: 'CampaignDetails',
        [name]: value,
      },
    },
  });
  return null;
};

export const setDetailsBoolean = (_obj, { name, value }, { cache }) => {
  cache.writeQuery({
    query: DETAILS_QUERY,
    data: {
      campaignDetails: {
        __typename: 'CampaignDetails',
        [name]: value,
      },
    },
  });
  return null;
};

export const handlePrimaryCTASelection = (_obj, { name }, { cache }) => {
  cache.writeQuery({
    query: DETAILS_QUERY,
    data: {
      campaignDetails: {
        __typename: 'CampaignDetails',
        isPrimaryCTASelected: name !== '',
        primaryCTA: name,
      },
    },
  });
  return null;
};

export const handleSecondaryCTASelection = (_obj, { name }, { cache }) => {
  cache.writeQuery({
    query: DETAILS_QUERY,
    data: {
      campaignDetails: {
        __typename: 'CampaignDetails',
        secondaryCTA: name,
      },
    },
  });
  return null;
};

export const handleCTAData = (_obj, { name, data }, { cache }) => {
  cache.writeQuery({
    query: DETAILS_QUERY,
    data: {
      campaignDetails: {
        __typename: 'CampaignDetails',
        [name]: data,
      },
    },
  });
  return null;
};

export const rehydrateDetailsCache = (
  _obj,
  {
    flyerTitle,
    lowParticipationFee,
    highParticipationFee,
    participationFee,
    eventLocation,
    deadlineDate,
    startDate,
    endDate,
    categoryMain,
    categoryAdd1,
    categoryAdd2,
    isPrimaryCTASelected,
  },
  { cache }
) => {
  cache.writeQuery({
    query: DETAILS_QUERY,
    data: {
      campaignDetails: {
        __typename: 'CampaignDetails',
        flyerTitle,
        lowParticipationFee: lowParticipationFee || '',
        highParticipationFee: highParticipationFee || '',
        participationFee: lowParticipationFee || '',
        eventLocation: eventLocation || '', // Ensures eventLocation is set in cache as string. Apollo treats empty string as null in mutation, and sets optional mutation variables as null.
        deadlineDate: deadlineDate ? new Date(deadlineDate) : null,
        startDate: startDate ? new Date(startDate) : null, // Ensures dates are rehydrated as date objects
        endDate: endDate ? new Date(endDate) : null,
        categoryMain,
        categoryAdd1,
        categoryAdd2,
        isPrimaryCTASelected,
        // primaryCTA,
        // secondaryCTA,
        // primaryCTAData,
        // secondaryCTAData,
      },
    },
  });
  return null;
};

export const handleCampaignDetailsFormValidation = (
  _obj,
  { formValidation },
  { cache }
) => {
  cache.writeQuery({
    query: DETAILS_QUERY,
    data: {
      campaignDetails: {
        __typename: 'CampaignDetailsFormValidation',
        formValidation,
      },
    },
  });
  return null;
};

// Resolvers

export const detailsResolvers = {
  setDetails,
  setDetailsBoolean,
  handlePrimaryCTASelection,
  handleSecondaryCTASelection,
  handleCTAData,
  rehydrateDetailsCache,
  handleCampaignDetailsFormValidation,
};
