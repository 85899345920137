export const clientSupportsCopyToClipboard = (): boolean => document.queryCommandSupported('copy');

export const copyToClipboard = (event: HTMLElement, textAreaRef: HTMLTextAreaElement): object => {
  if(!clientSupportsCopyToClipboard()) {
    return {
      status: 'ERROR'
    };
  }

  textAreaRef.current.select(); //select text to copy
  document.execCommand('copy'); //copy to clipboard
  event.target.focus();

  //copied!
  return {
    status: 'SUCCESS'
  }
};
