import gql from 'graphql-tag';
import LearnMoreFragments from '../components/LearnMore/gql/LearnMoreFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment PrimaryCTA_getMyCampaigns on Campaign {
      ...LearnMore_getMyCampaigns
    }
    ${LearnMoreFragments.getMyCampaigns}
  `,
};
