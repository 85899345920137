import React from 'react';
import Grid from "@material-ui/core/Grid";
import { CreditBalance as Balance } from '../CommonModels';

type Props = Balance & {
  showMsrp: boolean,
};

export default function CreditBalance(props: Props) {
  const { numberOfCredits, showMsrp, msrpValue, updatedAt } = props;
  return <Grid container direction="column">
    <Grid><h5>Credit Balance</h5></Grid>
    <Grid>{numberOfCredits} credits {showMsrp ? `($${msrpValue})` : ''}</Grid>
    <Grid>Updated: {updatedAt}</Grid>
  </Grid>;
}
