import generateSubappConfig from '../_app/generateSubappConfig';
import { APP_ROUTES } from '../_app/constants';
import { orgSettingsAllowedRoles } from '../_app/RolesEnum';
import RedirectOrgSettings from '../_app/components/RedirectOrgSettings';

const orgSettingsSubappConfig = generateSubappConfig({
  path: APP_ROUTES.orgSettings,
  allowedRoles: orgSettingsAllowedRoles,
  isLive: true,
  navItemConfig: {
    label: 'Org Settings',
    sideNavEnabled: false,
  },
  appConfig: {
    Component: RedirectOrgSettings,
    fragmentOnUser: {},
  },
});

export default orgSettingsSubappConfig;
