import React from 'react';

const HappyPeople = props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 502 296"
    style={{ enableBackground: 'new 0 0 502 296' }}
    xmlSpace="preserve"
    width={502}
    height={296}
    {...props}
  >
    <style
      type="text/css"
      dangerouslySetInnerHTML={{
        __html:
          '\n\t.st0{filter:url(#Adobe_OpacityMaskFilter);}\n\t.st1{fill:#FFFFFF;}\n\t.st2{mask:url(#mask-2_1_);fill:#FFFFFF;}\n\t.st3{fill:#C6C9D5;}\n\t.st4{fill:#47506B;}\n\t.st5{fill:#B5B5B5;}\n\t.st6{filter:url(#Adobe_OpacityMaskFilter_1_);}\n\t.st7{mask:url(#mask-6_1_);fill:#2D344B;}\n\t.st8{fill:#DFE1E7;}\n\t.st9{fill:#757C99;}\n\t.st10{fill:#F0F2F6;}\n\t.st11{fill:#FFEFC8;}\n\t.st12{fill:#FCC35D;}\n\t.st13{fill:#F36E21;}\n\t.st14{fill:#48A349;}\n\t.st15{fill:#AC732A;}\n\t.st16{fill:#FAA918;}\n\t.st17{fill:#A3D1A4;}\n\t.st18{fill:#FFDA80;}\n\t.st19{fill:#2D344B;}\n\t.st20{filter:url(#Adobe_OpacityMaskFilter_2_);}\n\t.st21{mask:url(#mask-8_1_);fill:#2D344B;}\n\t.st22{fill:#9EA3B7;}\n',
      }}
    />
    <g id="Components-2-Copy" transform="translate(-1536.000000, -1538.000000)">
      <g id="Group-328" transform="translate(1536.000000, 1538.000000)">
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter"
            filterUnits="userSpaceOnUse"
            x={0}
            y={276}
            width={502}
            height="19.9"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>
        <mask
          maskUnits="userSpaceOnUse"
          x={0}
          y={276}
          width={502}
          height="19.9"
          id="mask-2_1_"
        >
          <g className="st0">
            <polygon
              id="path-1_1_"
              className="st1"
              points="0,276 502,276 502,296 0,296 				"
            />
          </g>
        </mask>
        <path
          id="Fill-4"
          className="st3"
          d="M234.8,284.8h52.6c2.4,0,4.4-1.8,4.1-3.9c0,0-22.6-13.3-22.3-17.5v-41.6h-34.4V284.8z
                M234.8,284.8h-52.6c-2.4,0-4.3-1.8-4.1-3.9c0,0,22.6-13.3,22.3-17.5l-1.9-42.8l36.3,1.2V284.8z"
        />
        <path
          id="Fill-10"
          className="st4"
          d="M268.5,244.7h-68.7c-0.3,0-0.5-0.1-0.5-0.3v-22c0-0.2,0.2-0.3,0.5-0.3h68.7
              c0.3,0,0.5,0.1,0.5,0.3v22C268.9,244.5,268.7,244.7,268.5,244.7"
        />
        <path
          id="Fill-12"
          className="st5"
          d="M417.1,239.9H40.1c-2.2,0-4-1.8-4-4v-18.5h385v18.5C421.1,238.1,419.3,239.9,417.1,239.9"
        />
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_1_"
            filterUnits="userSpaceOnUse"
            x="36.1"
            y={0}
            width={385}
            height="222.1"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>
        <mask
          maskUnits="userSpaceOnUse"
          x="36.1"
          y={0}
          width={385}
          height="222.1"
          id="mask-6_1_"
        >
          <g className="st6">
            <polygon
              id="path-5_1_"
              className="st1"
              points="36.1,0 421.1,0 421.1,222.1 36.1,222.1 				"
            />
          </g>
        </mask>
        <path
          id="Fill-14"
          className="st7"
          d="M417.1,222.1h-377c-2.2,0-4-1.8-4-4V7.9c0-4.3,3.5-7.9,7.8-7.9h367.7c5.2,0,9.5,4.2,9.5,9.5
              v208.7C421.1,220.3,419.3,222.1,417.1,222.1"
        />
        <path
          id="Fill-17"
          className="st8"
          d="M411.6,217.4H46.2c-2.5,0-4.5-2-4.5-4.5V21.5c0-3.4,2.7-6.1,6.1-6.1h360.5
              c4.3,0,7.7,3.5,7.7,7.7V213C416,215.4,414,217.4,411.6,217.4"
        />
        <path
          id="Fill-19"
          className="st9"
          d="M416,21.7H41.7v-6.6c0-5.9,4.8-10.7,10.7-10.7h353.3c5.7,0,10.3,4.6,10.3,10.3V21.7z"
        />
        <path
          id="Fill-21"
          className="st10"
          d="M57.9,13.7c0,1.3-1.1,2.3-2.4,2.3c-1.3,0-2.4-1-2.4-2.3s1.1-2.3,2.4-2.3
              C56.9,11.4,57.9,12.4,57.9,13.7"
        />
        <path
          id="Fill-23"
          className="st11"
          d="M66.3,13.7c0,1.3-1.1,2.3-2.4,2.3c-1.3,0-2.4-1-2.4-2.3s1.1-2.3,2.4-2.3
              C65.2,11.4,66.3,12.4,66.3,13.7"
        />
        <path
          id="Fill-25"
          className="st12"
          d="M74.6,13.7c0,1.3-1.1,2.3-2.4,2.3c-1.3,0-2.4-1-2.4-2.3s1.1-2.3,2.4-2.3
              C73.6,11.4,74.6,12.4,74.6,13.7"
        />
        <path
          id="Fill-27"
          className="st10"
          d="M144.7,133.5H104c-3.9,0-7-3.1-7-7V75.5c0-3.9,3.1-7,7-7h40.7c3.9,0,7,3.1,7,7v51.1
              C151.7,130.4,148.6,133.5,144.7,133.5"
        />
        <path
          id="Fill-29"
          className="st10"
          d="M144.7,205.4H104c-3.9,0-7-3.1-7-7v-51.1c0-3.9,3.1-7,7-7h40.7c3.9,0,7,3.1,7,7v51.1
              C151.7,202.3,148.6,205.4,144.7,205.4"
        />
        <path
          id="Fill-31"
          className="st10"
          d="M214.4,133.5h-40.7c-3.9,0-7-3.1-7-7V75.5c0-3.9,3.1-7,7-7h40.7c3.9,0,7,3.1,7,7v51.1
              C221.4,130.4,218.2,133.5,214.4,133.5"
        />
        <path
          id="Fill-33"
          className="st10"
          d="M214.4,205.4h-40.7c-3.9,0-7-3.1-7-7v-51.1c0-3.9,3.1-7,7-7h40.7c3.9,0,7,3.1,7,7v51.1
              C221.4,202.3,218.2,205.4,214.4,205.4"
        />
        <path
          id="Fill-35"
          className="st10"
          d="M284,133.5h-40.7c-3.9,0-7-3.1-7-7V75.5c0-3.9,3.1-7,7-7H284c3.9,0,7,3.1,7,7v51.1
              C291,130.4,287.9,133.5,284,133.5"
        />
        <path
          id="Fill-37"
          className="st10"
          d="M284,205.4h-40.7c-3.9,0-7-3.1-7-7v-51.1c0-3.9,3.1-7,7-7H284c3.9,0,7,3.1,7,7v51.1
              C291,202.3,287.9,205.4,284,205.4"
        />
        <path
          id="Fill-39"
          className="st10"
          d="M353.6,133.5h-40.7c-3.9,0-7-3.1-7-7V75.5c0-3.9,3.1-7,7-7h40.7c3.9,0,7,3.1,7,7v51.1
              C360.6,130.4,357.5,133.5,353.6,133.5"
        />
        <path
          id="Fill-41"
          className="st10"
          d="M353.6,205.4h-40.7c-3.9,0-7-3.1-7-7v-51.1c0-3.9,3.1-7,7-7h40.7c3.9,0,7,3.1,7,7v51.1
              C360.6,202.3,357.5,205.4,353.6,205.4"
        />
        <path
          id="Fill-43"
          className="st13"
          d="M128.6,49.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.8
              c0.1-0.3,0.2-0.6,0.2-0.9c0-0.3-0.1-0.6-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.1-0.6-0.2-0.9-0.2
              c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.6v1.9c0.1,0.2,0.2,0.4,0.4,0.6
              c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.3,0.6,0.3C128.2,49,128.4,49.1,128.6,49.1 M129.1,50.4c-0.5,0-1.1-0.1-1.5-0.4
              c-0.4-0.3-0.8-0.7-1-1.1v4.4h-1.5V42.9h1.3v1.3c0.3-0.4,0.6-0.8,1.1-1c0.4-0.3,1-0.4,1.5-0.4c0.5,0,0.9,0.1,1.4,0.3
              c0.4,0.2,0.8,0.5,1.1,0.8c0.3,0.3,0.6,0.7,0.7,1.2c0.2,0.5,0.3,0.9,0.2,1.4c0,0.5-0.1,1-0.2,1.5c-0.1,0.4-0.4,0.8-0.7,1.2
              c-0.3,0.3-0.6,0.6-1,0.8C130,50.3,129.6,50.4,129.1,50.4"
        />
        <path
          id="Fill-45"
          className="st13"
          d="M139.1,46.1c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.3-0.5-0.5-0.7c-0.2-0.2-0.4-0.3-0.7-0.4
              c-0.3-0.1-0.5-0.2-0.8-0.2c-0.5,0-1.1,0.2-1.5,0.6c-0.2,0.2-0.3,0.4-0.5,0.7c-0.1,0.3-0.2,0.6-0.2,0.8L139.1,46.1z M136.9,50.4
              c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.5-1.2-0.8c-0.3-0.3-0.6-0.8-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.5c0-0.5,0.1-1,0.3-1.5
              c0.2-0.5,0.4-0.9,0.8-1.2c0.3-0.4,0.7-0.6,1.2-0.8c0.5-0.2,1-0.3,1.5-0.3c0.5,0,1,0.1,1.5,0.3c0.4,0.2,0.8,0.5,1.2,0.8
              c0.3,0.3,0.6,0.8,0.7,1.2c0.2,0.5,0.3,0.9,0.3,1.4v0.3v0.2h-5.8c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.5,0.5,0.7
              c0.4,0.4,0.9,0.6,1.5,0.6c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5l1.3,0.4
              c-0.3,0.6-0.7,1-1.2,1.4C138.3,50.3,137.6,50.4,136.9,50.4z"
        />
        <path
          id="Fill-47"
          className="st13"
          d="M144.2,49.3c0.3,0,0.7-0.1,1-0.2c0.3-0.1,0.6-0.3,0.8-0.6c0.2-0.1,0.3-0.3,0.3-0.5v-1
              c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.6-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.3,0.4c-0.3,0.2-0.5,0.6-0.5,0.9c0,0.4,0.1,0.7,0.4,0.9
              C143.4,49.2,143.8,49.3,144.2,49.3 M143.7,50.4c-0.3,0-0.7,0-1-0.2c-0.3-0.1-0.5-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.5-0.7
              c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.6-0.7c0.3-0.2,0.6-0.4,1-0.5c0.4-0.1,0.8-0.2,1.2-0.2
              c0.3,0,0.7,0,1,0.1c0.3,0.1,0.6,0.1,0.9,0.2v-0.5c0-0.5-0.1-0.9-0.5-1.3c-0.4-0.3-0.8-0.5-1.3-0.5c-0.4,0-0.8,0.1-1.1,0.2
              c-0.4,0.2-0.8,0.4-1.1,0.6l-0.5-1c0.8-0.6,1.8-0.9,2.9-1c0.8-0.1,1.7,0.2,2.3,0.8c0.6,0.6,0.9,1.4,0.8,2.2v2.7
              c0,0.3,0.1,0.5,0.4,0.5v1.4l-0.4,0.1h-0.3c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.3-0.4-0.3-0.6v-0.5c-0.3,0.4-0.7,0.8-1.2,1
              C144.8,50.3,144.3,50.4,143.7,50.4"
        />
        <path
          id="Fill-49"
          className="st13"
          d="M149,46.6c0-0.5,0.1-1,0.3-1.4c0.3-0.9,1-1.7,1.9-2c0.5-0.2,1-0.3,1.5-0.3
              c0.7,0,1.3,0.2,1.9,0.5c0.5,0.3,0.9,0.8,1.2,1.3l-1.5,0.5c-0.2-0.3-0.4-0.6-0.7-0.7c-0.3-0.2-0.7-0.3-1-0.3
              c-0.6,0-1.1,0.2-1.5,0.7c-0.2,0.2-0.3,0.5-0.5,0.8c-0.1,0.3-0.2,0.7-0.2,1c0,0.3,0,0.7,0.2,1c0.1,0.3,0.3,0.6,0.5,0.8
              c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.5,0.2,0.8,0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.3
              c0.1-0.1,0.2-0.2,0.2-0.4l1.5,0.5c-0.3,0.6-0.7,1-1.2,1.4c-0.6,0.4-1.3,0.5-2,0.5c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.6-1.1-0.9
              c-0.3-0.4-0.6-0.8-0.8-1.2C149.1,47.6,149,47.1,149,46.6"
        />
        <path
          id="Fill-51"
          className="st13"
          d="M163.5,50.3H162v-4.1c0-0.5-0.1-1-0.4-1.5c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0-0.6,0.1
              c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.4,0.3-0.5,0.5c-0.1,0.2-0.3,0.4-0.3,0.7v4.4h-1.5V40.1h1.5v4.4c0.5-1,1.6-1.6,2.7-1.6
              c0.4,0,0.8,0.1,1.1,0.2c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.4,0.1,0.8,0.1,1.1L163.5,50.3z"
        />
        <path
          id="Fill-53"
          className="st13"
          d="M165.2,41.7v-1.7h1.5v1.7L165.2,41.7z M164.4,53c-0.3,0-0.6,0-0.9-0.2
              c-0.3-0.1-0.6-0.3-0.8-0.5l0.7-1c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.5,0.1c0.6,0,1-0.5,1-1V43h1.5v7.5
              c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.2-0.5,0.4-0.8,0.5C165,52.9,164.7,53,164.4,53z"
        />
        <path
          id="Fill-55"
          className="st13"
          d="M170.9,49.3c0.3,0,0.7-0.1,1-0.2c0.3-0.1,0.6-0.3,0.8-0.6c0.2-0.1,0.3-0.3,0.3-0.5v-1
              c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.6-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.3,0.4c-0.3,0.2-0.5,0.6-0.5,0.9c0,0.4,0.1,0.7,0.4,0.9
              C170.1,49.2,170.5,49.3,170.9,49.3 M170.4,50.4c-0.3,0-0.7,0-1-0.2c-0.3-0.1-0.5-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.5-0.7
              c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.4-0.5,0.6-0.7c0.3-0.2,0.6-0.4,1-0.5c0.4-0.1,0.8-0.2,1.2-0.2
              c0.3,0,0.7,0,1,0.1c0.3,0.1,0.6,0.1,0.9,0.2v-0.5c0-0.5-0.1-0.9-0.5-1.3c-0.4-0.3-0.8-0.5-1.3-0.5c-0.4,0-0.8,0.1-1.1,0.2
              c-0.4,0.2-0.8,0.4-1.1,0.6l-0.5-1c0.8-0.6,1.8-0.9,2.9-1c0.8-0.1,1.7,0.2,2.3,0.8c0.6,0.6,0.9,1.4,0.8,2.2v2.7
              c0,0.3,0.1,0.5,0.4,0.5v1.3l-0.4,0.1h-0.4c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.3-0.4-0.3-0.6v-0.5c-0.3,0.4-0.7,0.8-1.2,1
              C171.5,50.3,171,50.4,170.4,50.4"
        />
        <path
          id="Fill-57"
          className="st13"
          d="M180.3,44.3c-0.5,0-1.1,0.1-1.6,0.4c-0.5,0.2-0.8,0.6-1,1.1v4.5h-1.5v-7.3h1.4v1.6
              c0.2-0.5,0.6-0.9,1-1.2c0.4-0.3,0.8-0.5,1.3-0.5h0.4L180.3,44.3z"
        />
        <path
          id="Fill-59"
          className="st13"
          d="M117.8,48c-0.3-6.9-7.5-7.5-7.5-7.5c-3.5-0.7-4.7,1.8-4.2,4.2c0,0,1.2,9.9,2.4,13.8
              C110.7,56.3,118.1,54.9,117.8,48"
        />
        <path
          id="Fill-61"
          className="st14"
          d="M105.6,31.9c2,1.9,3.8,4.3,4.1,7c0.2,1.8-0.3,3.6-1.2,5c-2.4-0.5-4.2-2.6-4.3-5.1
              C104.1,36.4,104.6,34,105.6,31.9"
        />
        <path
          id="Fill-63"
          className="st15"
          d="M109,43.9c0.6-5,6.1-5.3,6.1-5.3l-0.6-1.5c-3.3,0.6-5.9,3.4-6.5,6.8
              C108.3,43.9,108.7,43.9,109,43.9"
        />
        <path
          id="Fill-65"
          className="st16"
          d="M115.1,49.7c0-5.9-6.8-5.8-6.8-5.8c-0.8-1.1-2.1-1.7-3.4-1.6c-1.3,0-2.6,0.2-3.9,0.6l-0.2,0.2
              c-2.6,2.8-2.6,7.1-0.1,10c2.4,2.9,6.3,4.3,7.8,5.4C108.6,56.7,115.1,54.6,115.1,49.7"
        />
        <path
          id="Fill-67"
          className="st17"
          d="M103.3,38.4c-2-0.6-4.2-0.9-6.3-0.9c0.7,2,1.9,3.7,3.5,5.1c2.2,1.8,5.1,2.3,7.8,1.3
              C108.3,42.7,106.4,39.3,103.3,38.4"
        />
        <path
          id="Fill-69"
          className="st8"
          d="M115.7,89.7h-7.7c-0.8,0-1.5-0.7-1.5-1.5V77.9c0-0.8,0.7-1.5,1.5-1.5h7.7c0.8,0,1.5,0.7,1.5,1.5
              v10.3C117.2,89,116.6,89.7,115.7,89.7"
        />
        <path
          id="Fill-71"
          className="st8"
          d="M142,78.6h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H142c0.6,0,1.1,0.5,1.1,1.1
              C143,78.1,142.5,78.6,142,78.6"
        />
        <path id="Fill-73" className="st8" d="M119.6,79.8H136" />
        <path
          id="Fill-75"
          className="st8"
          d="M135.7,81.9h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C136.8,81.4,136.3,81.9,135.7,81.9"
        />
        <path
          id="Fill-77"
          className="st8"
          d="M115.4,107.3h-7c-1,0-1.8-0.8-1.8-1.8v-9.6c0-1,0.8-1.8,1.8-1.8h7c1,0,1.8,0.8,1.8,1.8v9.6
              C117.2,106.5,116.4,107.3,115.4,107.3"
        />
        <path
          id="Fill-79"
          className="st8"
          d="M142,96.3h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H142c0.6,0,1.1,0.5,1.1,1.1
              C143,95.9,142.5,96.3,142,96.3"
        />
        <path id="Fill-81" className="st8" d="M119.6,95.1H136" />
        <path
          id="Fill-83"
          className="st8"
          d="M135.7,99.6h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C136.8,99.2,136.3,99.6,135.7,99.6"
        />
        <path
          id="Fill-85"
          className="st8"
          d="M115.8,125.7H108c-0.8,0-1.4-0.6-1.4-1.4v-10.4c0-0.8,0.6-1.4,1.4-1.4h7.8
              c0.8,0,1.4,0.6,1.4,1.4v10.4C117.2,125.1,116.6,125.7,115.8,125.7"
        />
        <path
          id="Fill-87"
          className="st8"
          d="M142,114.7h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H142c0.6,0,1.1,0.5,1.1,1.1
              C143,114.3,142.5,114.7,142,114.7"
        />
        <path
          id="Fill-89"
          className="st8"
          d="M135.7,118.1h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C136.8,117.6,136.3,118.1,135.7,118.1"
        />
        <path
          id="Fill-91"
          className="st8"
          d="M184.1,89.7h-7.7c-0.8,0-1.5-0.7-1.5-1.5V77.9c0-0.8,0.7-1.5,1.5-1.5h7.7c0.8,0,1.5,0.7,1.5,1.5
              v10.3C185.6,89,185,89.7,184.1,89.7"
        />
        <path
          id="Fill-93"
          className="st8"
          d="M210.3,78.6h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C211.4,78.1,210.9,78.6,210.3,78.6"
        />
        <path id="Fill-95" className="st8" d="M188,79.8h16.4" />
        <path
          id="Fill-97"
          className="st8"
          d="M204.1,81.9h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C205.2,81.4,204.7,81.9,204.1,81.9"
        />
        <path
          id="Fill-99"
          className="st8"
          d="M183.8,107.3h-7c-1,0-1.8-0.8-1.8-1.8v-9.6c0-1,0.8-1.8,1.8-1.8h7c1,0,1.8,0.8,1.8,1.8v9.6
              C185.6,106.5,184.8,107.3,183.8,107.3"
        />
        <path
          id="Fill-101"
          className="st8"
          d="M210.3,96.3h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C211.4,95.9,210.9,96.3,210.3,96.3"
        />
        <path id="Fill-103" className="st8" d="M188,95.1h16.4" />
        <path
          id="Fill-105"
          className="st8"
          d="M204.1,99.6h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C205.2,99.2,204.7,99.6,204.1,99.6"
        />
        <path
          id="Fill-107"
          className="st8"
          d="M184.2,125.7h-7.8c-0.8,0-1.4-0.6-1.4-1.4v-10.4c0-0.8,0.6-1.4,1.4-1.4h7.8
              c0.8,0,1.4,0.6,1.4,1.4v10.4C185.6,125.1,185,125.7,184.2,125.7"
        />
        <path
          id="Fill-109"
          className="st8"
          d="M210.3,114.7h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C211.4,114.3,210.9,114.7,210.3,114.7"
        />
        <path
          id="Fill-111"
          className="st8"
          d="M204.1,118.1h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C205.2,117.6,204.7,118.1,204.1,118.1"
        />
        <path
          id="Fill-113"
          className="st8"
          d="M253.8,89.7h-7.7c-0.8,0-1.5-0.7-1.5-1.5V77.9c0-0.8,0.7-1.5,1.5-1.5h7.7
              c0.8,0,1.5,0.7,1.5,1.5v10.3C255.3,89,254.6,89.7,253.8,89.7"
        />
        <path
          id="Fill-115"
          className="st8"
          d="M280,78.6h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H280c0.6,0,1.1,0.5,1.1,1.1
              C281,78.1,280.6,78.6,280,78.6"
        />
        <path id="Fill-117" className="st8" d="M257.6,79.8H274" />
        <path
          id="Fill-119"
          className="st8"
          d="M273.8,81.9h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C274.8,81.4,274.3,81.9,273.8,81.9"
        />
        <path
          id="Fill-121"
          className="st8"
          d="M253.5,107.3h-7c-1,0-1.8-0.8-1.8-1.8v-9.6c0-1,0.8-1.8,1.8-1.8h7c1,0,1.8,0.8,1.8,1.8v9.6
              C255.3,106.5,254.5,107.3,253.5,107.3"
        />
        <path
          id="Fill-123"
          className="st8"
          d="M280,96.3h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H280c0.6,0,1.1,0.5,1.1,1.1
              C281,95.9,280.6,96.3,280,96.3"
        />
        <path id="Fill-125" className="st8" d="M257.6,95.1H274" />
        <path
          id="Fill-127"
          className="st8"
          d="M273.8,99.6h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C274.8,99.2,274.3,99.6,273.8,99.6"
        />
        <path
          id="Fill-129"
          className="st8"
          d="M253.8,125.7H246c-0.8,0-1.4-0.6-1.4-1.4v-10.4c0-0.8,0.6-1.4,1.4-1.4h7.8
              c0.8,0,1.4,0.6,1.4,1.4v10.4C255.3,125.1,254.6,125.7,253.8,125.7"
        />
        <path
          id="Fill-131"
          className="st8"
          d="M280,114.7h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H280c0.6,0,1.1,0.5,1.1,1.1
              C281,114.3,280.6,114.7,280,114.7"
        />
        <path
          id="Fill-133"
          className="st8"
          d="M273.8,118.1h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C274.8,117.6,274.3,118.1,273.8,118.1"
        />
        <path
          id="Fill-135"
          className="st8"
          d="M323.4,89.7h-7.7c-0.8,0-1.5-0.7-1.5-1.5V77.9c0-0.8,0.7-1.5,1.5-1.5h7.7
              c0.8,0,1.5,0.7,1.5,1.5v10.3C324.9,89,324.2,89.7,323.4,89.7"
        />
        <path
          id="Fill-137"
          className="st8"
          d="M349.6,78.6h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C350.7,78.1,350.2,78.6,349.6,78.6"
        />
        <path id="Fill-139" className="st8" d="M327.2,79.8h16.4" />
        <path
          id="Fill-141"
          className="st8"
          d="M343.4,81.9h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C344.5,81.4,344,81.9,343.4,81.9"
        />
        <path
          id="Fill-143"
          className="st8"
          d="M323.1,107.3h-7c-1,0-1.8-0.8-1.8-1.8v-9.6c0-1,0.8-1.8,1.8-1.8h7c1,0,1.8,0.8,1.8,1.8v9.6
              C324.9,106.5,324.1,107.3,323.1,107.3"
        />
        <path
          id="Fill-145"
          className="st8"
          d="M349.6,96.3h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C350.7,95.9,350.2,96.3,349.6,96.3"
        />
        <path id="Fill-147" className="st8" d="M327.2,95.1h16.4" />
        <path
          id="Fill-149"
          className="st8"
          d="M343.4,99.6h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C344.5,99.2,344,99.6,343.4,99.6"
        />
        <path
          id="Fill-151"
          className="st8"
          d="M323.5,125.7h-7.8c-0.8,0-1.4-0.6-1.4-1.4v-10.4c0-0.8,0.6-1.4,1.4-1.4h7.8
              c0.8,0,1.4,0.6,1.4,1.4v10.4C324.9,125.1,324.3,125.7,323.5,125.7"
        />
        <path
          id="Fill-153"
          className="st8"
          d="M349.6,114.7h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C350.7,114.3,350.2,114.7,349.6,114.7"
        />
        <path
          id="Fill-155"
          className="st8"
          d="M343.4,118.1h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C344.5,117.6,344,118.1,343.4,118.1"
        />
        <path
          id="Fill-157"
          className="st8"
          d="M115.7,160.6h-7.7c-0.8,0-1.5-0.7-1.5-1.5v-10.3c0-0.8,0.7-1.5,1.5-1.5h7.7
              c0.8,0,1.5,0.7,1.5,1.5v10.3C117.2,160,116.6,160.6,115.7,160.6"
        />
        <path
          id="Fill-159"
          className="st8"
          d="M142,149.5h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H142c0.6,0,1.1,0.5,1.1,1.1
              C143,149,142.5,149.5,142,149.5"
        />
        <path id="Fill-161" className="st8" d="M119.6,150.7H136" />
        <path
          id="Fill-163"
          className="st8"
          d="M135.7,152.8h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C136.8,152.3,136.3,152.8,135.7,152.8"
        />
        <path
          id="Fill-165"
          className="st8"
          d="M115.4,178.3h-7c-1,0-1.8-0.8-1.8-1.8v-9.6c0-1,0.8-1.8,1.8-1.8h7c1,0,1.8,0.8,1.8,1.8v9.6
              C117.2,177.5,116.4,178.3,115.4,178.3"
        />
        <path
          id="Fill-167"
          className="st8"
          d="M142,167.3h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H142c0.6,0,1.1,0.5,1.1,1.1
              C143,166.8,142.5,167.3,142,167.3"
        />
        <path id="Fill-169" className="st8" d="M119.6,166H136" />
        <path
          id="Fill-171"
          className="st8"
          d="M135.7,170.6h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C136.8,170.1,136.3,170.6,135.7,170.6"
        />
        <path
          id="Fill-173"
          className="st8"
          d="M115.8,196.7H108c-0.8,0-1.4-0.6-1.4-1.4v-10.4c0-0.8,0.6-1.4,1.4-1.4h7.8
              c0.8,0,1.4,0.6,1.4,1.4v10.4C117.2,196,116.6,196.7,115.8,196.7"
        />
        <path
          id="Fill-175"
          className="st8"
          d="M142,185.7h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H142c0.6,0,1.1,0.5,1.1,1.1
              C143,185.2,142.5,185.7,142,185.7"
        />
        <path
          id="Fill-177"
          className="st8"
          d="M135.7,189.1h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C136.8,188.6,136.3,189.1,135.7,189.1"
        />
        <path
          id="Fill-179"
          className="st8"
          d="M184.1,160.6h-7.7c-0.8,0-1.5-0.7-1.5-1.5v-10.3c0-0.8,0.7-1.5,1.5-1.5h7.7
              c0.8,0,1.5,0.7,1.5,1.5v10.3C185.6,160,185,160.6,184.1,160.6"
        />
        <path
          id="Fill-181"
          className="st8"
          d="M210.3,149.5h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C211.4,149,210.9,149.5,210.3,149.5"
        />
        <path id="Fill-183" className="st8" d="M188,150.7h16.4" />
        <path
          id="Fill-185"
          className="st8"
          d="M204.1,152.8h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C205.2,152.3,204.7,152.8,204.1,152.8"
        />
        <path
          id="Fill-187"
          className="st8"
          d="M183.8,178.3h-7c-1,0-1.8-0.8-1.8-1.8v-9.6c0-1,0.8-1.8,1.8-1.8h7c1,0,1.8,0.8,1.8,1.8v9.6
              C185.6,177.5,184.8,178.3,183.8,178.3"
        />
        <path
          id="Fill-189"
          className="st8"
          d="M210.3,167.3h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C211.4,166.8,210.9,167.3,210.3,167.3"
        />
        <path id="Fill-191" className="st8" d="M188,166h16.4" />
        <path
          id="Fill-193"
          className="st8"
          d="M204.1,170.6h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C205.2,170.1,204.7,170.6,204.1,170.6"
        />
        <path
          id="Fill-195"
          className="st8"
          d="M184.2,196.7h-7.8c-0.8,0-1.4-0.6-1.4-1.4v-10.4c0-0.8,0.6-1.4,1.4-1.4h7.8
              c0.8,0,1.4,0.6,1.4,1.4v10.4C185.6,196,185,196.7,184.2,196.7"
        />
        <path
          id="Fill-197"
          className="st8"
          d="M210.3,185.7h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C211.4,185.2,210.9,185.7,210.3,185.7"
        />
        <path
          id="Fill-199"
          className="st8"
          d="M204.1,189.1h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C205.2,188.6,204.7,189.1,204.1,189.1"
        />
        <path
          id="Fill-201"
          className="st8"
          d="M253.8,160.6h-7.7c-0.8,0-1.5-0.7-1.5-1.5v-10.3c0-0.8,0.7-1.5,1.5-1.5h7.7
              c0.8,0,1.5,0.7,1.5,1.5v10.3C255.3,160,254.6,160.6,253.8,160.6"
        />
        <path
          id="Fill-203"
          className="st8"
          d="M280,149.5h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H280c0.6,0,1.1,0.5,1.1,1.1
              C281,149,280.6,149.5,280,149.5"
        />
        <path id="Fill-205" className="st8" d="M257.6,150.7H274" />
        <path
          id="Fill-207"
          className="st8"
          d="M273.8,152.8h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C274.8,152.3,274.3,152.8,273.8,152.8"
        />
        <path
          id="Fill-209"
          className="st8"
          d="M253.5,178.3h-7c-1,0-1.8-0.8-1.8-1.8v-9.6c0-1,0.8-1.8,1.8-1.8h7c1,0,1.8,0.8,1.8,1.8v9.6
              C255.3,177.5,254.5,178.3,253.5,178.3"
        />
        <path
          id="Fill-211"
          className="st8"
          d="M280,167.3h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H280c0.6,0,1.1,0.5,1.1,1.1
              C281,166.8,280.6,167.3,280,167.3"
        />
        <path id="Fill-213" className="st8" d="M257.6,166H274" />
        <path
          id="Fill-215"
          className="st8"
          d="M273.8,170.6h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C274.8,170.1,274.3,170.6,273.8,170.6"
        />
        <path
          id="Fill-217"
          className="st8"
          d="M253.8,196.7H246c-0.8,0-1.4-0.6-1.4-1.4v-10.4c0-0.8,0.6-1.4,1.4-1.4h7.8
              c0.8,0,1.4,0.6,1.4,1.4v10.4C255.3,196,254.6,196.7,253.8,196.7"
        />
        <path
          id="Fill-219"
          className="st8"
          d="M280,185.7h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H280c0.6,0,1.1,0.5,1.1,1.1
              C281,185.2,280.6,185.7,280,185.7"
        />
        <path
          id="Fill-221"
          className="st8"
          d="M273.8,189.1h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C274.8,188.6,274.3,189.1,273.8,189.1"
        />
        <path
          id="Fill-223"
          className="st8"
          d="M323.4,160.6h-7.7c-0.8,0-1.5-0.7-1.5-1.5v-10.3c0-0.8,0.7-1.5,1.5-1.5h7.7
              c0.8,0,1.5,0.7,1.5,1.5v10.3C324.9,160,324.2,160.6,323.4,160.6"
        />
        <path
          id="Fill-225"
          className="st8"
          d="M349.6,149.5h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C350.7,149,350.2,149.5,349.6,149.5"
        />
        <path id="Fill-227" className="st8" d="M327.2,150.7h16.4" />
        <path
          id="Fill-229"
          className="st8"
          d="M343.4,152.8h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C344.5,152.3,344,152.8,343.4,152.8"
        />
        <path
          id="Fill-231"
          className="st8"
          d="M323.1,178.3h-7c-1,0-1.8-0.8-1.8-1.8v-9.6c0-1,0.8-1.8,1.8-1.8h7c1,0,1.8,0.8,1.8,1.8v9.6
              C324.9,177.5,324.1,178.3,323.1,178.3"
        />
        <path
          id="Fill-233"
          className="st8"
          d="M349.6,167.3h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C350.7,166.8,350.2,167.3,349.6,167.3"
        />
        <path id="Fill-235" className="st8" d="M327.2,166h16.4" />
        <path
          id="Fill-237"
          className="st8"
          d="M343.4,170.6h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C344.5,170.1,344,170.6,343.4,170.6"
        />
        <path
          id="Fill-239"
          className="st8"
          d="M323.5,196.7h-7.8c-0.8,0-1.4-0.6-1.4-1.4v-10.4c0-0.8,0.6-1.4,1.4-1.4h7.8
              c0.8,0,1.4,0.6,1.4,1.4v10.4C324.9,196,324.3,196.7,323.5,196.7"
        />
        <path
          id="Fill-241"
          className="st8"
          d="M349.6,185.7h-22.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h22.1c0.6,0,1.1,0.5,1.1,1.1
              C350.7,185.2,350.2,185.7,349.6,185.7"
        />
        <path
          id="Fill-243"
          className="st8"
          d="M343.4,189.1h-15.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h15.9c0.6,0,1.1,0.5,1.1,1.1
              C344.5,188.6,344,189.1,343.4,189.1"
        />
        <path
          id="Fill-245"
          className="st13"
          d="M30,167.8c2,3.3,5.5,3.6,7.3,2.6s1.7-10.8-1.7-12.3C32.4,156.7,28.7,165.7,30,167.8"
        />
        <path
          id="Fill-247"
          className="st18"
          d="M21.6,130.9c0-0.2-4.1-0.4-3.2-3c0.8-2.6,2.6-2.9,3.8-2.2c1.2,0.7,0.8,4.8,0.8,4.8L21.6,130.9
              z"
        />
        <path
          id="Fill-249"
          className="st18"
          d="M37.3,150.5c0,0,5,5.9,5.5,8.8c0.5,2.9-3.4,3.9-3.5,5.2c-0.2,1.3,1.9,3.9,1.9,3.9l4.3-1.7
              c0,0,6.7,13.4-0.5,16.1c-7.2,2.7-12.4-12.9-12.4-12.9s2.8,0.1,3.7-1.3c0.9-1.4-3.4-6.6-3.4-6.6s-2.4-4.9-0.5-9
              C34.2,148.9,35.2,148.3,37.3,150.5"
        />
        <path
          id="Fill-251"
          className="st16"
          d="M35.7,145.9c2.4,0.9,3.3,3.2,3.4,4.1c0.1,0.9-0.1,2.8-0.1,2.8l-2.4-2.7c0,0-0.1,1.2-0.9,2.4
              c-0.7,1.2-1.4,1.3-1.4,1.3s0.6,3-0.3,5.8c-0.9,2.8-1.4,3.7-1.4,3.7l-1.6,7.5c0,0,2.6,12-0.6,17.5c-3.2,5.5-11.2,9.3-18.3,3.2
              c-7.1-6.1-5.9-10.4-5.9-10.4s-1.4-1.6-1.2-3.8c0.1-2.2,1-4.2,1-4.2s-2.4-4.8,1.2-8.2c3.6-3.4,9-0.6,11.3-3.1
              c2.3-2.6-2.1-7.5,1.1-12.5c3.1-4.9,8.6-2.5,8.6-2.5S31.2,144.1,35.7,145.9"
        />
        <path
          id="Fill-253"
          className="st18"
          d="M48.7,125.6c0,0-2-0.1-2.9-0.9c-0.9-0.9-0.8-1.9,0.5-3c1.3-1.1,2.8-0.7,3.7-0.1s0.5,4,0.5,4
              L48.7,125.6z"
        />
        <path
          id="Fill-255"
          className="st18"
          d="M58.5,245.6c-3.2,5.1-31.3,6.2-31.3,6.2s-0.5,0.6-1.2,1.2c-1.2,1-3,2.3-4.5,1.6
              c-2.5-1.1,5.1-4.9,5.1-4.9s0.1,0,0.3-0.1c2.6-0.5,20.7-4.1,23-6.8c2.4-2.9-5.9-9.6-5.9-9.6l11-5.1
              C54.9,228.2,61.7,240.4,58.5,245.6"
        />
        <path
          id="Fill-257"
          className="st18"
          d="M69.4,243.5c-3.3,6.8-18.2,25.8-18.2,25.8s0,1.2-0.1,2.5c-0.1,1.8-0.5,3.8-1.5,3.6
              c-1.8-0.3-1.1-7.1-1.1-7.1s0.3-0.4,0.8-1.2c3-4.5,13.4-20.3,13.4-24c0-4.3-6.3-14.6-6.3-14.6l12.1-5.6
              C68.5,222.8,72.7,236.7,69.4,243.5"
        />
        <path
          id="Fill-259"
          className="st19"
          d="M26,253c-1.2,1.1-3,2.3-4.5,1.6c-2.5-1.1,5.1-4.9,5.1-4.9s0.1,0,0.3-0.1
              c-1.1,0.7-2.9,2.1-2.4,2.8C24.7,253,25.4,253.1,26,253"
        />
        <path
          id="Fill-261"
          className="st19"
          d="M51.1,271.8c-0.1,1.8-0.5,3.8-1.5,3.6c-1.8-0.3-1.1-7.1-1.1-7.1s0.3-0.4,0.8-1.2
              c-0.1,2.4-0.3,6.4,0.7,6.1C50.5,273,50.9,272.4,51.1,271.8"
        />
        <path
          id="Fill-263"
          className="st13"
          d="M35.9,201.2c0,0-2.6,5.8-0.7,15.7c1.9,10,9.1,22.2,9.1,22.2l26.2-15.3c0,0-3.6-11.4-7.3-16.8
              c-3.7-5.3-7.1-8.7-7.1-8.7L35.9,201.2z"
        />
        <path
          id="Fill-265"
          className="st4"
          d="M53.9,184.8c-0.7-6.7-1.9-16.2-1.9-16.2s4.5-18.4,3.7-25.3c-0.8-6.9-5-18.4-5-18.4l-2,0.4
              c0,0,1.7,7.6,1.7,16.9s-3.2,21.1-3.2,21.1l-6.1,4.7l1.2,1.9l-1.1,2.3l-3.6-0.8l-1.5-3l-4.4-0.9c0-0.4-0.7-9.7-2.7-17.7
              c-2-8.2-6-20.5-6-20.5l-1.5,1.1c0,0,1.9,11.1,2.1,19.9c0.2,8.4-0.8,13.1,0.8,19.4c0.6,2.5,1.5,4.3,2.5,5.3
              c1.3,2.5,3.2,6.5,4.6,10.7c2.5,7.1,4.3,16.6,4.3,16.6s4.9,1.8,12,0.3c7.2-1.5,8.2-4.5,8.2-4.5S54.6,191.5,53.9,184.8"
        />
        <path
          id="Fill-267"
          className="st1"
          d="M34.5,169.7c-0.6,1.6,2.2,6.2,4.6,6.4c2.4,0.3,2.4-2.5,2.4-2.5s2.3,1.5,3.3,0.2
              c0.9-1.3,0.3-3.7-0.8-4.9c-1-1.1-3.1-0.8-3.1-0.8s1.4,3-0.9,3.2c-1.6,0.1-4.1-2.8-4.1-2.8S34.9,168.7,34.5,169.7"
        />
        <path
          id="Fill-269"
          className="st12"
          d="M410.7,110.7c0,0,3.2-2,2.5-2.4c-0.7-0.4-1.8,0.6-1.8,0.6s1.6-6.9-0.2-7.1
              c-1.8-0.2-3.2,6.8-3,7.9c0.2,1.1,1.6,2.1,1.6,2.1L410.7,110.7z"
        />
        <path
          id="Fill-271"
          className="st4"
          d="M420.3,288.1c0.3,1.5,9.3-0.5,9.3-1.3c0.1-0.8-0.1-3.5-0.1-3.5l-3.4-0.2
              C426.2,283.2,420.1,287,420.3,288.1"
        />
        <path
          id="Fill-273"
          className="st4"
          d="M469.6,284.6c0,0-0.6,2.3,0.1,3c0.7,0.7,9.1,1.8,9.4,1.1c0.4-0.7-6.9-4.5-6.9-4.5L469.6,284.6z
              "
        />
        <path
          id="Fill-275"
          className="st19"
          d="M428.8,200c0,0.5-3.1,84.2-3.1,84.2l4.2,0.3c0,0,10.5-67.9,14.6-67.9
              c4.1,0,25.1,68.8,25.1,68.8l3.1-0.5l-14-84.2l-18.2-5.3L428.8,200z"
        />
        <path
          id="Fill-277"
          className="st4"
          d="M408.7,111.3l2,0.2c0,0,0.2,14,3.1,20.1c3,6.1,15.4,17.1,15.4,17.1l-2,18.4
              c0,0-14.3-17.9-18.7-29.5C404.2,125.9,408.7,111.3,408.7,111.3"
        />
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_2_"
            filterUnits="userSpaceOnUse"
            x="422.1"
            y="147.6"
            width="37.3"
            height="60.6"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>
        <mask
          maskUnits="userSpaceOnUse"
          x="422.1"
          y="147.6"
          width="37.3"
          height="60.6"
          id="mask-8_1_"
        >
          <g className="st20">
            <path
              id="path-7_1_"
              className="st1"
              d="M408.2,136.9c4.4,11.8,18.8,29.9,18.8,29.9l2.1-18.6c0,0-12.5-11.1-15.5-17.3
                      c-3-6.2-3.2-20.4-3.2-20.4l-2.1-0.2C408.3,110.3,403.8,125.1,408.2,136.9"
            />
          </g>
        </mask>
        <path
          id="Fill-280"
          className="st21"
          d="M459.4,206.6l-21.9-5.1l-11.7,6.6c0,0,2.3-14-0.9-24.2c-3.6-11.2-3.6-34.7-0.2-34.7
              c0.9,0,1.7-1.3,5.9-1.5c4.3-0.2,9.6-0.3,14.1,0c4.9,0.3,8.9,0.8,9.9,1.8C457.4,152.1,459.4,206.6,459.4,206.6"
        />
        <path
          id="Fill-283"
          className="st4"
          d="M460.6,205.3l-21.7-5.2l-11.6,6.7c0,0,0.3-13.7-0.9-24.4c-1.2-10.8-4.3-33-2.4-35.1
              c0.6-0.7,3.8-1.1,8-1.4c4.2-0.2,9.5-0.3,13.9,0c4.8,0.3,8.8,0.8,9.8,1.8C458.6,150.3,460.6,205.3,460.6,205.3"
        />
        <path
          id="Fill-285"
          className="st19"
          d="M451.1,155.6c-1.9,2.4-1.6,9.6,3,14.9c4.5,5.2,8.7,6.4,9.5,9.3c0.8,2.9-0.4,20.8-0.4,20.8
              l1.9,0.8c0,0,6.6-18.5,5.7-24c-0.9-5.5-11.1-20.7-13.1-22.4S452.9,153.5,451.1,155.6"
        />
        <polygon
          id="Fill-287"
          className="st1"
          points="453.6,204.5 442.4,205.5 441.2,171.5 462.1,173.6 465.8,193.9 		"
        />
        <path
          id="Fill-289"
          className="st16"
          d="M475.2,175.2c-0.4,0-31.8-2-31.8-2l0.9,33.1l32.4-2.6L475.2,175.2z"
        />
        <path
          id="Fill-291"
          className="st1"
          d="M446,145.9c-1.1,1.4-2.8,4.1-4.1,9.1c-2.1,8.3-4.8,22.7-4.8,22.7s-3.4-16.5-4.1-23.3
              c-0.4-4.1,0-7.1,1.1-8.4C438.4,145.7,441.5,145.7,446,145.9"
        />
        <path
          id="Fill-293"
          className="st12"
          d="M463.3,203.5c-0.3,0.2-5.1,1.4-4.3,2.3c0.8,0.8,2.7,1,4.2,0.5c1.4-0.5,1.9-2,1.9-2
              L463.3,203.5z"
        />
        <path
          id="Fill-295"
          className="st12"
          d="M434.5,128.5c-1.4,1.5-3.4,13.4-1.8,14.3c1.6,0.9,4.4-0.6,4.9,0.5c0.5,1.1-0.1,3.2-0.1,3.2
              s-1.4,1.3,0.4,1.8c1.8,0.5,4-1.8,4-1.8s-0.6-3.5,0.1-5.9c0.7-2.5,2.1-4.9,1.9-8.1C443.6,129.4,438.4,124.4,434.5,128.5"
        />
        <path
          id="Fill-297"
          className="st13"
          d="M431.7,129.1c0.5,1.7,9.5,0.9,9.5,0.9s-1.1,4.9-0.7,5.2c0.4,0.3,1.1-1,1.9,0.1
              c0.8,1.1-1.2,4.1-1.2,4.1l0.7,1.2c0,0,2.2-1.9,2.9-5.3c0.7-3.4,0.6-4-0.1-5.3c-0.7-1.2-1.4-1.1-1.4-1.1s1.8-1-1.6-2.6
              C438.3,124.8,431.2,127.4,431.7,129.1"
        />
        <path
          id="Fill-299"
          className="st4"
          d="M451.1,149.7c-1.9,2.8-1.6,11.6,3,17.8s8.7,7.7,9.5,11.1c0.8,3.4-0.4,25-0.4,25l1.9,1
              c0,0,6.6-22.2,5.7-28.8c-0.9-6.6-11.1-24.9-13.1-26.9C455.7,146.9,452.9,147.2,451.1,149.7"
        />
        <path
          id="Fill-301"
          className="st4"
          d="M399.7,283.1c-1,0.1-0.9-1.8-0.8-3.6c0.1-1.1,0.1-2.2,0-2.6c-0.4-1.2-21.2-25.1-23.3-28.7
              c-2.1-3.6-3.5-13.6-3.4-15.8l12.3-1.2c0,0-0.4,9.8,0.3,13.8c0.7,4,15.3,28,16.1,30.3c0.1,0.4,0.2,0.8,0.3,1.3
              C401.5,279.1,401,282.9,399.7,283.1"
        />
        <path
          id="Fill-303"
          className="st4"
          d="M365.1,231.2L345.9,282c0,0-0.5,0.3-1.1,0.8c-1.6,1-4.4,2.6-5.1,1.9c-0.6-0.6,1.3-2,2.9-3.1
              c0.7-0.4,1.3-0.8,1.7-1.1l8.7-52.4L365.1,231.2z"
        />
        <path
          id="Fill-305"
          className="st16"
          d="M344.7,282.8c-1.6,1-4.4,2.6-5.1,1.9c-0.6-0.6,1.3-2,2.9-3.1c0,0.4,0.1,0.7,0.5,0.9
              C343.6,282.7,344.2,282.8,344.7,282.8"
        />
        <path
          id="Fill-307"
          className="st16"
          d="M399.7,283.8c-1,0.1-0.9-1.8-0.8-3.6c0.2,0.1,0.4,0.2,0.7,0.2c0.8-0.1,1.3-1.9,1.5-3.1
              C401.5,279.7,401,283.6,399.7,283.8"
        />
        <path
          id="Fill-309"
          className="st22"
          d="M366.7,144.8c0,0,2.9-1.2,5.5,1.7c2.5,2.9,0.5,5,4.2,6.5c3.8,1.4,10.3-1.3,13.8,4.8
              c3.4,6.1,1.4,11.6,1.4,11.6s1.8,8.3-3.6,11.5c-5.3,3.2-10.1,3.8-14-1c-3.9-4.8-7-19.7-7-19.7L366.7,144.8z"
        />
        <path
          id="Fill-311"
          className="st4"
          d="M391.4,127.3c0,0-3.9,13.6-7.3,22.6c-3.4,8.9-9.5,13.9-9.5,13.9s-2.8,4.6,2.2,8.8
              c2.8,2.3,5.1-0.1,7.6-4.4c2.5-4.3,5.4-14.8,7-25.2s1.8-15.8,1.8-15.8S391.6,126,391.4,127.3"
        />
        <path
          id="Fill-313"
          className="st4"
          d="M360.8,164.6c1.1,1.8,1.1,4.7-0.2,7.4c-1,2.2-3.3,4.1-5.5,1.4c-2.2-2.7-8.6-13.7-11.7-23.3
              c-3.1-9.6-3.9-21.4-3.9-21.4l1.3-0.4c0,0,3.5,12.9,7.4,19.4C352.1,154.3,359.5,162.4,360.8,164.6"
        />
        <path
          id="Fill-315"
          className="st4"
          d="M366.2,148.3c-1.7,0-3.6,0.6-3.8,4.4c-0.2,3.8-1.6,7.4-0.5,8.8c1,1.4,3.4,0.5,3.8,1.2
              c0.4,0.8,0.5,1.9,0.1,2.5c-0.5,0.5-3.8,0.4-3.8,0.4s2.7,8.8,5.4,9.3c2.7,0.5,6.3-10.1,6.3-10.1s-3.4,0.7-4,0
              c-0.5-0.7-1.2-1.9-0.5-3.8c0.6-1.9,2.9-4,2.5-7.3C371.1,150.6,368.8,148.3,366.2,148.3"
        />
        <path
          id="Fill-317"
          className="st22"
          d="M366,144.5c0,0-3.4,0.3-4.4,3.9c-1,3.6,0.6,6.5,0.6,6.5s0.2-2.3,1.2-3.7c1-1.4,2-1.8,2-1.8
              s1.4,0.4,3.4,3.5c2,3.1,2.2,4.9,2.2,4.9s2.5-0.9,2.3-5.8C372.9,147.3,369.2,144,366,144.5"
        />
        <path
          id="Fill-319"
          className="st13"
          d="M354.9,173.7c0,0,2.8,6.4,3.8,12c1,5.6,1.1,12.5,1.1,12.5s2.5,3.8,8.2,4.5
              c5.7,0.6,12.4-3.6,12.4-3.6s-1-7.9-0.4-13.8c0.5-5.9,2.4-13.5,2.4-13.5s-2.8,1-4.9-1.5c-2-2.5-2.4-6.8-2.4-6.8l-3,1.7
              c0,0-1.2,7.8-4.7,7.6c-3.5-0.3-4.1-7.1-4.1-7.1l-3.1-1.8c0,0,0.9,4-1.1,7.3C357.3,174.1,354.9,173.7,354.9,173.7"
        />
        <path
          id="Fill-321"
          className="st12"
          d="M351.3,231.9c0,0,6.7,3,17,3.6c10.4,0.5,18.5-2.2,18.5-2.2s2.5-14.1,0.2-22.8
              c-2.3-8.6-6.8-12.9-6.8-12.9s-4.3,4.3-9.9,4.2c-5.6-0.1-10.5-4.1-10.5-4.1s-3.9,9.8-5.6,18.7C352.5,225.2,351.3,231.9,351.3,231.9
              "
        />
        <path
          id="Fill-323"
          className="st4"
          d="M392,127.7c1.1,0.8,4,0.2,5.1-1.7s1.4-4.9,0.3-5.2c-1-0.3-1,3.5-2.7,3.7
              c-1.8,0.2-3.2,1.5-3.2,1.5S391.1,127,392,127.7"
        />
        <path
          id="Fill-325"
          className="st4"
          d="M339.9,130.3c-1.3,0.3-3.7-1.5-3.9-3.7c-0.2-2.2,0.9-5,1.9-4.8c1.1,0.2-0.6,3.6,0.9,4.5
              c1.5,0.9,2.2,2.8,2.2,2.8S341.1,130,339.9,130.3"
        />
      </g>
    </g>
  </svg>
);

export default HappyPeople;
