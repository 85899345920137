import React from 'react';
import { css } from 'react-emotion';
import { components, colors } from '@peachjar/components';

const { ButtonFlatLarge } = components.Buttons;
const { Modal } = components;

const styles = {
  body: css`
    padding-top: 1rem;
  `,
  footer: css`
    padding-top: 0.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;

    && {
      margin: 0px;
    }
  `,
  children: css`
    padding-left: 0.5rem;
  `,
};

const GenericModal = ({ header, message, onCancel, children, dataTestId }) => (
  <Modal dataTestId={dataTestId} width="sm">
    <React.Fragment>
      <Modal.Header color={colors.rose}>{header}</Modal.Header>
      <Modal.Body>
        <div className={styles.body}>{message}</div>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <ButtonFlatLarge data-testid="removeModalCancel" onClick={onCancel}>
          Cancel
        </ButtonFlatLarge>
        <div className={styles.children}>{children}</div>
      </Modal.Footer>
    </React.Fragment>
  </Modal>
);

export default GenericModal;
