const RolesEnum = {
  SCHOOL_ADMIN: 'SCHOOL_ADMIN',
  DISTRICT_ADMIN: 'DISTRICT_ADMIN',
  SCHOOL_APPROVER: 'SCHOOL_APPROVER',
  DISTRICT_APPROVER: 'DISTRICT_APPROVER',
  SCHOOL_UPLOADER: 'SCHOOL_UPLOADER',
  DISTRICT_UPLOADER: 'DISTRICT_UPLOADER',
  SCHOOL_STAFF: 'SCHOOL_STAFF',
  DISTRICT_STAFF: 'DISTRICT_STAFF',
  SCHOOL_VOLUNTEER: 'SCHOOL_VOLUNTEER',
  DISTRICT_VOLUNTEER: 'DISTRICT_VOLUNTEER',
  ORG: 'ORG',
  COMMUNITY_FREE: 'COMMUNITY_FREE',
};

export const pendingApprovalsAllowedRoles = [
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.DISTRICT_APPROVER,
];

export const myApprovalsAllowedRoles = [
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.DISTRICT_APPROVER,
];

export const allApprovalsAllowedRoles = [
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.DISTRICT_APPROVER,
];

export const approvalsAllowedRoles = [
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.DISTRICT_APPROVER,
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
];

export const campaignsAllowedRoles = [
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.ORG,
  RolesEnum.COMMUNITY_FREE,
];

export const distributionAllowedRoles = [
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.DISTRICT_APPROVER,
];

export const reportingAllowedRoles = [
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.ORG,
  RolesEnum.COMMUNITY_FREE,
];

export const dealsAllowedRoles = [RolesEnum.ORG, RolesEnum.COMMUNITY_FREE];

export const transactionsAllowedRoles = [
  RolesEnum.ORG,
  RolesEnum.COMMUNITY_FREE,
];

export const helpAllowedRoles = [
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.DISTRICT_APPROVER,
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.ORG,
  RolesEnum.COMMUNITY_FREE,
  RolesEnum.DISTRICT_STAFF,
  RolesEnum.SCHOOL_STAFF,
  RolesEnum.DISTRICT_VOLUNTEER,
  RolesEnum.SCHOOL_VOLUNTEER,
];

export const accountInfoAllowedRoles = [
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.DISTRICT_APPROVER,
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.ORG,
  RolesEnum.COMMUNITY_FREE,
  RolesEnum.DISTRICT_STAFF,
  RolesEnum.SCHOOL_STAFF,
  RolesEnum.DISTRICT_VOLUNTEER,
  RolesEnum.SCHOOL_VOLUNTEER,
];

export const orgSettingsAllowedRoles = [RolesEnum.ORG];

export type Roles = $Keys<typeof RolesEnum>;
export default RolesEnum;
