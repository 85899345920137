import { get } from 'lodash';

const indexOfEnd = (string: string, substring: string) => {
  const index = string.indexOf(substring);
  return index === -1 ? -1 : index + substring.length;
};

const getPdfUrlFromResponse = (response) => {
  const responseUrl = get(response, 'config.url', '');
  const pdfUrl = responseUrl.slice(0, indexOfEnd(responseUrl, 'pdf'));
  return pdfUrl;
};

export { getPdfUrlFromResponse, indexOfEnd };
