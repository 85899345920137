import gql from 'graphql-tag';

export default {
  getMyCampaigns: gql`
    fragment CampaignSlatContents_getMyCampaigns on Campaign {
      title
      id
      legacyFlyerId
      updatedAt
      createdAt
    }
  `,
};
