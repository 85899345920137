import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { css, cx } from 'react-emotion';
import { colors, components, elements } from '@peachjar/components';
import { getFurthestCompletedTab } from '../../shared/utils';
import CampaignSlatContentsFragments from './gql/CampaignSlatContentsFragments.graphql';
import CommunityFreeBadge from '../../../_app/components/CommunityFreeBadge';
import { COMMUNITY_FREE_TYPE, STANDARD_TYPE } from '../../campaigns.duck';
import config from '../../../config';

const { silver, mint } = colors;
const { notifyWarn } = components.Notifications;
const { SplitButton } = components.Buttons;
const { Tag } = elements;
const { Label, Paragraph } = elements.typography;

const { COMMUNITY_FREE_QUESTIONNAIRE_URL } = config;

const styles = {
  campaignImage: css`
    width: 75px;
    height: 100px;
    border: 1px solid ${silver};
    border-radius: 3px;
  `,
  cardContainer: css`
    position: relative;
  `,
  campaignInfo: css`
    padding: 1rem;
  `,
  commFreeBadge: css`
    position: absolute;
    top: -14px;
    left: -25px;
  `,
};

export const CampaignSlatContents = React.memo(
  ({
    campaign: {
      title,
      id,
      flyer: { flyerPages },
      isSubmitted,
      updatedAt,
      createdAt,
      paymentStatus,
    },
    campaign,
    history,
    onRemoveDraftCampaign,
    handleWarning,
  }) => {
    const campaignType = get(campaign, 'type', STANDARD_TYPE);
    const applicationId = get(campaign, 'application.id', null);
    const isCommFreeDraft = campaignType === COMMUNITY_FREE_TYPE;
    const tab = getFurthestCompletedTab(campaign);
    // If there is an error submitting the flyer after it's paid we want to show a "Submission in progress" status and not allow user to edit the draft. Tempoary solution until we can ensure atomic pay and submit
    const flyerPaidButNotSubmitted = paymentStatus === 'paid' && !isSubmitted;
    const splitButtonConfig = {
      primaryButtonHandler: event => {
        event.stopPropagation();
        if (isCommFreeDraft) {
          window.location.href = `${COMMUNITY_FREE_QUESTIONNAIRE_URL}?aid=${applicationId}`;
          return;
        }
        history.push(`/campaigns/create-campaign/${id}/${tab}`);
      },
      menuItems: [
        {
          label: 'Delete Draft',
          clickHandler: e => {
            e.stopPropagation();
            onRemoveDraftCampaign(id, title);
          },
        },
      ],
    };

    return (
      <div className={cx('layout-row', styles.cardContainer)}>
        <div className="layout-row flex-50">
          <img
            className={styles.campaignImage}
            src={get(flyerPages, '[0].jpegUrl')}
            alt="Flyer Preview"
          />

          <div className={styles.campaignInfo}>
            <div>
              <Label bold>{title === '' ? 'Untitled' : title}</Label>
            </div>
            <div>
              <Paragraph muted>
                Edited {getDateString(updatedAt || createdAt)}
              </Paragraph>
            </div>
          </div>
        </div>
        <div className="layout-row layout-align-space-between-center flex-auto">
          <Tag
            className={css`
              background-color: ${flyerPaidButNotSubmitted ? mint : silver};
              display: inline;
              visibility: ${!flyerPaidButNotSubmitted && 'hidden'};
              padding: 0.25rem 0.5rem !important;
            `}
            categoryName={`${
              flyerPaidButNotSubmitted ? 'Submission in Progress' : 'Draft'
            }`}
          />
          <div className="layout-row">
            <SplitButton
              splitButtonConfig={splitButtonConfig}
              disabled={flyerPaidButNotSubmitted}
            >
              Edit Draft
            </SplitButton>
          </div>
        </div>
        {isCommFreeDraft && (
          <div className={styles.commFreeBadge}>
            <CommunityFreeBadge />
          </div>
        )}
      </div>
    );
  }
);

const getDateString = date => {
  const dateObject = new Date(date);
  const dateOptions = {
    weekday: 'short',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return `${dateObject.toLocaleDateString(
    'en-US',
    dateOptions
  )} at ${dateObject.toLocaleTimeString('en-US', timeOptions)}`;
};

CampaignSlatContents.fragments = CampaignSlatContentsFragments;

const mapDispatchToProps = dispatch => ({
  handleWarning: () => {
    dispatch(
      notifyWarn(
        'Flyer can’t be edited or deleted while submission is in progress. If you need assistance, contact us at (858) 997-2117.'
      )
    );
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(CampaignSlatContents));
