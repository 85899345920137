import React from 'react';
import { css } from 'react-emotion';
import { elements } from '@peachjar/components';

const { Note } = elements.typography;

const styles = {
  schoolItem: css`
    display: block;
    padding: 0.25rem 0;
  `,
};

const SchoolItem = ({ name }) => (
  <Note className={styles.schoolItem}>{name}</Note>
);

export default SchoolItem;
