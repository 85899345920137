import SecondaryCTAsGroup from './SecondaryCTAsGroup';
import withValidation from '../../../../../shared/components/withValidation';

const validate = ({ isSelected, isValid }) =>
  isSelected === true && isValid === true;

const validateOnMount = ({ secondaryCTA }, { isValid }) => {
  const isSelected = secondaryCTA !== undefined && secondaryCTA !== '';
  return validate({ isSelected, isValid });
};

export default withValidation(SecondaryCTAsGroup, validate, validateOnMount);
