import React, { useContext } from 'react';
import idx from 'idx';
import { css } from 'emotion';
import { colors, components } from '@peachjar/components';
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { centsToDollars, calcCostPerUnit } from '../../helpers/order';
import PromoCodeContainer from './PromoCode/PromoCodeContainer';
import { FLYER_PURCHASE_DISCOUNT_CODES } from '../../deals/constants';
import PromoCodeErrors from './PromoCode/PromoCodeErrors';
import DealsContext from '../context/DealsContext';
import closeSVG from '../../assets/close-icon.svg';

type Props = {
  quote: {
    [key: string]: any;
  };
  numberOfDistributions: number;
  classes: {
    [key: string]: any;
  };
  errors: {
    [k: string]: any;
  };
  handlePromoCodeSubmission: (code: string) => void;
  handlePromoCodeRemoval: (code: string) => void;
};

const { AVAILABLE_CREDITS_APPLIED } = FLYER_PURCHASE_DISCOUNT_CODES;

const FlyerPurchaseSummary: React.FunctionComponent<Props> = ({
  quote: purchaseQuote,
  numberOfDistributions,
  errors,
  onReset,
  handlePromoCodeSubmission = noop,
  handlePromoCodeRemoval = noop,
  classes,
}) => {
  const { creditBalance: currentCredits } = useContext(DealsContext);
  const additionalDiscounts =
    idx(purchaseQuote, _ => _.additionalDiscounts) || [];
  const total = idx(purchaseQuote, _ => _.total) || null;
  const totalCredits = idx(total, _ => _.numberOfCredits) || 0;
  const totalDue = idx(purchaseQuote, _ => _.total.priceInCents) || 0;
  const subtotalCents = idx(purchaseQuote, _ => _.subtotalAfterDiscounts.priceInCents) || 0;
  const subtotalCredits = idx(purchaseQuote, _ => _.subtotalAfterDiscounts.numberOfCredits) || 0;

  const msrpSubtotal = idx(purchaseQuote, _ => _.msrpSubtotal) || null;
  const msrpCredits = idx(msrpSubtotal, _ => _.numberOfCredits) || 0;
  const msrpPriceInCents = idx(msrpSubtotal, _ => _.priceInCents) || 0;
  const distributionDiscounts =
    idx(purchaseQuote, _ => _.distributionDiscounts) || [];
  const schoolList = idx(purchaseQuote, _ => _.audiences) || [];
  const discountPercent = idx(purchaseQuote, _ => _.total.discountPercent) || 0;
  const numberOfCreditsDue =
    idx(purchaseQuote, _ => _.total.numberOfCredits) || 0;
  const dollarDiscounts = idx(purchaseQuote, _ => _.dollarDiscounts) || null;

  const availableCreditsDiscount = distributionDiscounts.find(
    ({ code }) => code === AVAILABLE_CREDITS_APPLIED
  );
  const availableCredits =
    idx(availableCreditsDiscount, _ => _.creditsSaved) || null;
  const newCreditBalance = availableCredits
    ? currentCredits - availableCredits
    : currentCredits;
  const hasAdditionalDiscounts =
    Array.isArray(additionalDiscounts) && additionalDiscounts.length >= 1;
  const hasDistributionDiscounts =
    Array.isArray(distributionDiscounts) && distributionDiscounts.length >= 1;
  const schoolListLength = schoolList.length;
  const hasErrors: boolean = Array.isArray(errors) && errors.length >= 1;
  const hasDollarDiscounts: boolean =
    Array.isArray(dollarDiscounts) && dollarDiscounts.length >= 1;

  return (
    <MuiThemeProvider theme={toolTipTheme}>
      <div className={cn.orderSummary} id="flyer-purchase-order-summary">
        <Paper className={`${classes.root} ${classes.elevation2}`}>
          <hr className={cn.divvy} />
          <Table className={classes.table}>
            <TableBody>
              <TableRow className={cn.highlightRow}>
                <TableCell
                  align="left"
                  className={`${cn.cell} ${classes.tableCell} ${cn.padLeft} ${cn.col1}`}
                >
                  {schoolListLength} School(s)
                </TableCell>
                <TableCell
                  align="right"
                  className={`${cn.cell} ${classes.tableCell} ${cn.col2}`}
                >
                  {distributionFrequency(numberOfDistributions)}
                </TableCell>
                <TableCell
                  align="right"
                  className={`${cn.cell} ${classes.tableCell} ${cn.col3}`}
                >
                  {msrpCredits} c
                </TableCell>
                <TableCell
                  align="right"
                  className={`${cn.cell} ${classes.tableCell} ${cn.padRight} ${cn.col4}`}
                >
                  {centsToDollars(msrpPriceInCents)}
                </TableCell>
              </TableRow>
              {hasDistributionDiscounts && (
                <DiscountRows
                  discounts={distributionDiscounts}
                  classes={classes}
                  handleClick={handlePromoCodeRemoval}
                />
              )}
              {hasAdditionalDiscounts && (
                <DiscountRows
                  discounts={additionalDiscounts}
                  classes={classes}
                  handleClick={handlePromoCodeRemoval}
                />
              )}
              <TableRow className={cn.diffRow}>
                <TableCell
                  align="left"
                  className={`${cn.cell} ${classes.tableCell} ${cn.padLeft} highlight`}
                  colSpan="2"
                >
                  Subtotal
                </TableCell>
                <TableCell
                  align="right"
                  className={`${cn.cell} ${classes.tableCell} ${cn.col3} highlight`}
                >
                  {subtotalCredits} c
                </TableCell>
                <TableCell
                  align="right"
                  className={`${cn.cell} ${classes.tableCell} ${cn.col4} ${cn.padRight} highlight`}
                >
                  {centsToDollars(subtotalCents)}
                </TableCell>
              </TableRow>
              {hasErrors && <PromoCodeErrors errors={errors} columnSpans="4" />}
              {hasDollarDiscounts && (
                <DiscountRows
                  discounts={dollarDiscounts}
                  classes={classes}
                  handleClick={handlePromoCodeRemoval}
                />
              )}
              <TableRow>
                <TableCell
                  align="left"
                  className={`${cn.cell} ${classes.tableCell} ${cn.padLeft} ${cn.padRight}`}
                  colSpan="4"
                >
                  <PromoCodeContainer
                    handlePromoCodeSubmission={handlePromoCodeSubmission}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={cn.diffRow}>
                <TableCell
                  align="left"
                  className={`${cn.cell} ${classes.tableCell} ${cn.padLeft} highlight`}
                  colSpan="2"
                >
                  Total
                </TableCell>
                <TableCell
                  align="right"
                  className={`${cn.cell} ${classes.tableCell} ${cn.col3} highlight`}
                >
                  {totalCredits} c
                </TableCell>
                <TableCell
                  align="right"
                  className={`${cn.cell} ${classes.tableCell} ${cn.padRight} ${cn.col4} highlight`}
                >
                  {centsToDollars(totalDue)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className={cn.creditBalance}>
            <span className={cn.balanceText}>
              You saved {totalDue === 0 ? '0' : discountPercent}%{' '}
              <span className={cn.costPer}>
                (avg. cost per credit{' '}
                {totalDue === 0
                  ? '$0.00'
                  : calcCostPerUnit(totalDue, numberOfCreditsDue)}
                )
              </span>
            </span>
            <span className={cn.balanceText}>
              New credit balance {newCreditBalance} c
            </span>
          </div>
        </Paper>
      </div>
    </MuiThemeProvider>
  );
};

const muiStyles = theme => ({
  root: {
    width: '100%',
    marginTop: '0',
    overflowX: 'auto',
  },
  table: {
    fontFamily: 'Proxima Nova, Arial, Helvetica, sans-serif',
  },
  tableCell: {
    padding: '4px 8px',
  },
  elevation2: {
    boxShadow: 'none',
  },
  button: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#E9F6E9',
    },
  },
  child: {
    backgroundColor: '#E9F6E9',
  },
});

const toolTipTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#2d344b',
        borderRadius: '2px',
        fontSize: '13px',
        padding: '8px',
      },
    },
  },
});

const distributionFrequency = distributions => {
  if (distributions > 1) {
    return `x ${distributions} Distributions`;
  }

  return 'x 1 Distribution';
};

const RemoveableElement = ({ code, classes, handleClick }) => (
  <div
    onClick={() => {
      handleClick(code);
    }}
  >
    <Tooltip title="Clear Item" placement="top" className={cn.ripple}>
      <IconButton
        className={`${classes.button} ${cn.iconButtonRight}`}
        arial-label="Clear Item"
      >
        <img src={closeSVG} className={cn.cellIconClear} />
      </IconButton>
    </Tooltip>
  </div>
);

const noop = () => {};

const DiscountRows = ({ discounts, classes, handleClick }) => {
  return discounts.map(
    (
      {
        code,
        type,
        creditsSaved,
        savingsInCents,
        friendlyName,
        friendlyDescription,
      },
      index
    ) => (
      <TableRow key={`${code}-${index}`}>
        <TableCell
          align="left"
          className={`${cn.cell} ${classes.tableCell} ${cn.padLeft}`}
          colSpan="2"
        >
          {friendlyName}{' '}
          <span className={`${cn.discountInfo} discount-description`}>
            {friendlyDescription && friendlyDescription.length
              ? `(${friendlyDescription})`
              : null}
          </span>
        </TableCell>
        <TableCell
          align="right"
          className={`${cn.cell} ${classes.tableCell} ${cn.col3}`}
        >
          &nbsp;
        </TableCell>
        <TableCell
          align="right"
          className={`${cn.cell} ${classes.tableCell} ${cn.col4} ${cn.padRight}`}
        >
          -{centsToDollars(savingsInCents)}
          {type === 'promo' && (
            <RemoveableElement
              code={code}
              classes={classes}
              handleClick={handleClick}
            />
          )}
        </TableCell>
      </TableRow>
    )
  );
}

const cn = {
  orderSummary: css``,
  diffRow: css`
    background-color: ${colors.platinum};

    .highlight {
      font-weight: bold;
    }
  `,
  creditBalance: css`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  balanceText: css`
    color: ${colors.prussian};
    font-size: 13px;
  `,
  costPer: css`
    color: ${colors.slate};
  `,
  sectionHeader: css`
    font-size: 19px;
    color: ${colors.prussian};
    border-bottom: 1px solid ${colors.silver};
    padding-bottom: 18px;
  `,
  cell: css`
    font-size: 16px !important;
    color: ${colors.prussian};
    position: relative;
    border-bottom: none !important;
  `,
  cellLink: css`
    color: ${colors.jungle} !important;
  `,
  shade: css`
    color: ${colors.slate};
  `,
  cellIcon: css`
    height: 12px;
    width: 12px;
    cursor: pointer;
  `,
  cellIconClear: css`
    height: 14px;
    width: 14px;
    cursor: pointer;
  `,
  cellIconPlacement: css`
    position: absolute;
    left: 10px;
    top: 16px;
  `,
  iconButton: css`
    position: absolute !important;
    left: 0px;
    top: 5px;
  `,
  iconButtonRight: css`
    position: absolute !important;
    right: 0px;
    top: 5px;
  `,
  divvy: css`
    height: 0px;
    margin: 0;
    border-top: 1px solid ${colors.silver};
  `,
  discountInfo: css`
    color: ${colors.slate};
    font-size: 16px;
  `,
  ripple: css`
    background-position: center;
    transition: background 0.8s;

    &:hover {
      padding: 12px;
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
      border-radius: 20px;
    }

    &:active {
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
    }
  `,
  highlightRow: css`
    border-bottom: 1px solid ${colors.silver};
  `,
  padLeft: css`
    padding-left: 32px !important;
  `,
  padRight: css`
    padding-right: 36px !important;
  `,
  col1: css`
    width: 26%;
  `,
  col2: css`
    width: 37%;
  `,
  col3: css`
    width: 14%;
  `,
  col4: css`
    width: 23%;
  `,
};

export default withStyles(muiStyles)(FlyerPurchaseSummary);
