
import * as React from 'react';
import { css } from 'emotion';
import { elements, components } from '@peachjar/components';

const { Badge, VerticalNav } = components;
const { Note } = elements.typography;

type Props = {
  path: string,
  label: string,
  badge?: React.ReactNode,
};

const SidebarItem = ({ path, label, badge }: Props) => (
  <VerticalNav.Item path={path} label={label}>
    <Note className={classNames.spaceBetween}>
      {label}
      {badge}
    </Note>
  </VerticalNav.Item>
);

SidebarItem.defaultProps = {
  badge: null,
};

const classNames = {
  spaceBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
};

export default SidebarItem;
