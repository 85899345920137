import React from 'react';
import UTCDatePicker from '../../../../shared/components/UTCDatePicker';

const PostExpirationDate = ({
  setDeliveryCache,
  postExpirationDate,
  minDate,
  maxDate,
  onChange,
  error,
}) => (
  <>
    <UTCDatePicker
      name="postExpirationDate"
      label="Post Expiration Date"
      data-testid="postExpirationDateInput"
      value={postExpirationDate}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
      error={error}
    />
  </>
);

export default PostExpirationDate;
