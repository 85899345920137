import * as React from 'react';
import { css } from 'react-emotion';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { components, elements, colors } from '@peachjar/components';
import AudienceType from '@peachjar/school-finder-api/dist/api/AudienceType';
import { SCHOOL_FINDER_AUDIENCE_FILTER_DEFAULTS } from './constants';
const {
  typography: { Paragraph, Note },
} = elements;
const { leaf } = colors;

export type AudienceFilterType = {
  [AudienceType.Parents]: boolean;
  [AudienceType.Staff]: boolean;
};

type Props = {
  audienceFilter: AudienceFilterType;
  classes: Record<string, any>;
  setAudienceFilter: (filter: AudienceFilterType) => void;
  togglePopper: (event: React.SyntheticEvent) => void;
  areAudienceFiltersDefault: boolean;
};

const Filter = ({
  audienceFilter,
  setAudienceFilter,
  classes,
  togglePopper,
  areAudienceFiltersDefault,
}) => (
  <div className={styles.container}>
    <div className={styles.innerPadding}>
      <div className="layout-row layout-align-start-start">
        <div>
          <Switch
            classes={{
              switchBase: classes.colorSwitchBase,
              checked: classes.colorChecked,
              bar: classes.colorBar,
            }}
            className={`flex-none ${styles.switchWrapper}`}
            onChange={() =>
              setAudienceFilter({
                [AudienceType.Parents]: !audienceFilter[AudienceType.Parents],
                [AudienceType.Staff]: audienceFilter[AudienceType.Staff],
              })
            }
            checked={audienceFilter.parents}
          />
        </div>
        <div className={styles.switchTextContainer}>
          <Paragraph className={`${styles.block}`}>Parents</Paragraph>
          <Note className={styles.block} muted>
            All subscribed parents at each school are available for
            distribution.
          </Note>
        </div>
      </div>
      <div className="layout-row layout-align-start-start">
        <div>
          <Switch
            classes={{
              switchBase: classes.colorSwitchBase,
              checked: classes.colorChecked,
              bar: classes.colorBar,
            }}
            className={`flex-none ${styles.switchWrapper}`}
            onChange={() =>
              setAudienceFilter({
                [AudienceType.Parents]: audienceFilter[AudienceType.Parents],
                [AudienceType.Staff]: !audienceFilter[AudienceType.Staff],
              })
            }
            checked={audienceFilter[AudienceType.Staff]}
          />
        </div>
        <div className={styles.switchTextContainer}>
          <Paragraph className={`${styles.block}`}>Staff</Paragraph>
          <Note className={styles.block} muted>
            School staff groups are made available by some districts for
            distribution.
          </Note>
        </div>
      </div>
    </div>
    <div
      className={`${areAudienceFiltersDefault
        ? styles.buttonsWrapperSingle
        : styles.buttonsWrapperDouble} mt-2`

      }
    >
      {!areAudienceFiltersDefault && (
        <Button
          className={classes.button}
          onClick={() => setAudienceFilter(SCHOOL_FINDER_AUDIENCE_FILTER_DEFAULTS)}
        >
          <Note className={styles.buttonText}>Reset</Note>
        </Button>
      )}
      <Button className={classes.button} onClick={event => togglePopper(event)}>
        <Note className={styles.buttonText}>Done</Note>
      </Button>
    </div>
  </div>
);

const styles = {
  container: css`
    width: 319px;
    padding: 0.5rem;
  `,
  wrapper: css`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  `,
  innerPadding: css`
    padding: 0.5rem 1rem;
  `,
  buttonsWrapperSingle: css`
    display: flex;
    justify-content: flex-end;
  `,
  buttonsWrapperDouble: css`
    display: flex;
    justify-content: space-between;
  `,
  buttonText: css`
    color: ${colors.jungle};
  `,
  copy: css`
    color: #9c9c9c;
  `,
  block: css`
    display: block;
  `,
  switchTextContainer: css`
    margin-top: 0.75rem;
  `,
  switchWrapper: css`
    margin-left: -12px;
  `,
};

const muiStyles = () => ({
  button: {
    textTransform: 'none',
    height: '32px',
    width: '64px',
  },
  colorSwitchBase: {
    '&$colorChecked': {
      color: leaf,
      '& + $colorBar': {
        backgroundColor: leaf,
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

export default withStyles(muiStyles)(Filter);
