const config = require('../../config');

const { ENVIRONMENT } = config.default;

if (ENVIRONMENT === 'development') {
  // eslint-disable-next-line no-console
  console.log('Sentry not loaded in development');
  module.exports = () => {};
} else {
  // eslint-disable-next-line global-require
  module.exports = require('./configureSentry.js');
}
