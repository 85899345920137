import React, { Component } from 'react';
import { get } from 'lodash';
import { Switch, Route } from 'react-router-dom';
import Upload from './components/Upload';
import Ocr, { Ocr as HOClessOcr } from './components/Ocr';
import PrepareFragments from './gql/PrepareFragments.graphql';
import { CommunityFreeConfig } from '../types';

type State = {
  flyerId: string;
};

type Props = {
  communityFreeConfig: CommunityFreeConfig;
  remoteCampaign: Object;
  flyerId: string;
  history: Object;
  setUserTexts: ({ variables: Object }) => Promise<any>;
  onSaveFailed: (campaignId: string) => void;
};

export class Prepare extends Component<Props, State> {
  static fragments: { [key: string]: any };

  render() {
    const {
      remoteCampaign,
      history,
      setUserTexts,
      onSaveFailed,
      communityFreeConfig,
    } = this.props;
    const { flyerId } = this.props;

    return (
      <Switch>
        <Route
          exact
          path="/campaigns/create-campaign/:id/prepare/preview"
          render={() => (
            <Ocr
              campaign={remoteCampaign}
              history={history}
              campaignId={get(remoteCampaign, 'id')}
              setUserTexts={setUserTexts}
              onSaveFailed={onSaveFailed}
            />
          )}
        />
        <Route
          path="/campaigns/create-campaign/:id/prepare/upload"
          render={() => (
            <Upload
              communityFreeConfig={communityFreeConfig}
              remoteCampaign={remoteCampaign}
              history={history}
              flyerId={flyerId}
            />
          )}
        />
      </Switch>
    );
  }
}

if (Ocr) {
  Prepare.fragments = PrepareFragments;
} else {
  Prepare.fragments = {
    getMyCampaigns: '',
  };
}

export default Prepare;
