
import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { cx, css } from 'react-emotion';
import { colors, elements } from '@peachjar/components';
import { Status } from '../../approvals.duck';
import Radio from '../Radio';

const { Note } = elements.typography;

type Props = {
   approvals: Array<{
     id: string,
     schoolName: string,
  }>,
   handleApprovalStatusChange: (
    e: any
  ) => void,
   stagedApprovals: {
    [approvalId: string]: Status,
  },
  handleBulkApprovalStatusChange: (
    e: any
  ) => void,
};

const SchoolApprovalSelectionTable = ({
  approvals,
  stagedApprovals,
  handleApprovalStatusChange,
  handleBulkApprovalStatusChange,
}: Props) => (
  <Table className="border rounded">
    <TableHead>
      <TableRow>
        <TableCell className="border-right">Name</TableCell>
        <TableCell className="border-right px-0 text-center">
          <Radio
            checked={Object.values(stagedApprovals).every(
              status => status === 'approved'
            )}
            value="approved"
            name="approve-all"
            aria-label="Approve for all schools"
            data-value="approved"
            data-testid="click-radio-approveAll"
            onClick={handleBulkApprovalStatusChange}
            colorClass="jungle"
          />
          Approve
        </TableCell>

        <TableCell className="border-right px-0 text-center">
          <Radio
            checked={Object.values(stagedApprovals).every(
              status => status === 'pending'
            )}
            value="pending"
            name="hold-all"
            aria-label="Hold for all schools"
            data-value="pending"
            data-testid="click-radio-holdAll"
            onClick={handleBulkApprovalStatusChange}
            colorClass="peach"
          />
          Hold
        </TableCell>

        <TableCell className="border-right px-0 text-center">
          <Radio
            checked={Object.values(stagedApprovals).every(
              status => status === 'denied'
            )}
            value="denied"
            name="deny-all"
            aria-label="Deny for all schools"
            data-value="denied"
            data-testid="click-radio-denyAll"
            onClick={handleBulkApprovalStatusChange}
            colorClass="dragon"
          />
          Deny
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {approvals.map(({ id, schoolName }) => {
        const localStatus = stagedApprovals[id];

        return (
          <TableRow className={classes.row} key={id}>
            <TableCell className="border-right">
              <Note>{schoolName}</Note>
            </TableCell>
            <TableCell
              className={cx('border-right px-0 text-center', classes.width20)}
            >
              <Radio
                checked={localStatus === 'approved'}
                value="approved"
                name={`approve-${id}`}
                aria-label={`Approve for ${schoolName}`}
                data-value="approved"
                data-id={id}
                data-testid={`click-radio-approve-${id}`}
                onClick={handleApprovalStatusChange}
                colorClass="jungle"
              />
            </TableCell>

            <TableCell
              className={cx('border-right px-0 text-center', classes.width20)}
            >
              <Radio
                checked={localStatus === 'pending'}
                value="pending"
                name={`hold-${id}`}
                aria-label={`Hold for ${schoolName}`}
                data-id={id}
                data-testid={`click-radio-hold-${id}`}
                data-value="pending"
                onClick={handleApprovalStatusChange}
                colorClass="peach"
              />
            </TableCell>

            <TableCell
              className={cx('border-right px-0 text-center', classes.width20)}
            >
              <Radio
                checked={localStatus === 'denied'}
                value="denied"
                name={`deny-${id}`}
                aria-label={`Deny for ${schoolName}`}
                data-value="denied"
                data-id={id}
                data-testid={`click-radio-deny-${id}`}
                onClick={handleApprovalStatusChange}
                colorClass="dragon"
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);

const classes = {
  row: css`
    &:nth-of-type(odd) {
      background-color: ${colors.platinum};
    }
  `,
  width20: css`
    width: 20% !important;
  `,
};

export default SchoolApprovalSelectionTable;
