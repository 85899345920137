import gql from 'graphql-tag';

export default {
  getMyCampaigns: gql`
    fragment ParticipationFee_getMyCampaigns on Campaign {
      participationFee
      lowParticipationFee
      highParticipationFee
    }
  `,
};