
import * as React from 'react';
import { css, cx } from 'react-emotion';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import idx from 'idx';
import { colors, svg, elements, components } from '@peachjar/components';

import { setApprovalStatus } from '../approvals.duck';
import { Status, StagedFlyer } from '../approvals.duck';
import {
  ApprovalCard_flyerReasons,
} from './__generated__/ApprovalCard_flyerReasons';
import getFormattedDate from '../getFormattedDate';
import FlyerApprovalButton from './FlyerApprovalButton';
import SchoolApprovalModal from './modals/SchoolApprovalModal';
import ApproverMessageModal from './modals/ApproverMessageModal';
import ApproverNotesModal from './modals/ApproverNotesModal';
import DenialReasonModalContainer from './modals/DenialReasonModalContainer';
import FlyerDetailLightBoxModal from './modals/FlyerDetailLightBoxModal';
import InfoPopover from '../../components/InfoPopover/InfoPopover';
import { getStagedFlyers } from '../selectors';
import generatePreviewFlyerURL from '../../../src/helpers/generatePreviewFlyerURL';

const { Label, Paragraph, Note } = elements.typography;
const { MaterialIcon } = elements;
const { Tag } = elements;
const { ButtonFlatSmall } = components.Buttons;
const { CircleCheck } = svg;

const SECONDS_PER_DAY = 86400;
const DAYS_PER_MONTH = 30;
const DAYS_PER_PSEUDO_MONTH = 28;
const MIN_PSEUDO_MONTHS = 1;

type ModalComponent = React.ComponentType<any>;
type ReduxDispatchProps = {
   onStageFlyer: (stagedFlyer: StagedFlyer) => void,
};

type OwnProps = {
   flyer: any,
   flyerReasons: ApprovalCard_flyerReasons[],
   stagedFlyerStatus?: Status | 'mixed',
   sod: 'school' | 'district',
   showModal: (ModalComponent, {}) => void,
   closeModal: () => void,
   shouldUserSeeApproverNotesButton: boolean,
};

export type Props = ReduxDispatchProps & OwnProps;

const onEnter = (
  callback: Function
): ((e: SyntheticKeyboardEvent<HTMLElement>) => void) => e => {
  if (e.key === 'Enter') {
    callback();
  }
};

export class ApprovalCard extends React.Component<Props> {
  static fragments: { [key: string]: any };

  showFlyerDetailLightBoxModalOnEnter = onEnter(
    this.showFlyerDetailLightBoxModal
  );

  showFlyerDetailLightBoxModal = () => {
    const { showModal, closeModal, flyer, sod } = this.props;
    const approvalData = {
      sod,
      districtId: idx(this.props, _ => _.flyer.approvals[0].districtId) || null,
      schoolId: idx(this.props, _ => _.flyer.approvals[0].schoolId) || null,
      flyerId: idx(this.props, _ => _.flyer.fml) || null,
    };

    const previewFlyerURL = generatePreviewFlyerURL(approvalData);
    return showModal(FlyerDetailLightBoxModal, {
      closeModal,
      flyer: filter(FlyerDetailLightBoxModal.fragments.flyer, flyer),
      previewFlyerURL,
    });
  };

  showSchoolApprovalModal = () => {
    const { showModal, closeModal, flyer, flyerReasons } = this.props;
    return showModal(SchoolApprovalModal, {
      showModal,
      closeModal,
      flyer: filter(SchoolApprovalModal.fragments.flyer, flyer),
      flyerReasons: filter(SchoolApprovalModal.fragments.flyerReasons, flyerReasons),
    });
  };

  showApproverMessageModal = () => {
    const { showModal, closeModal, flyer } = this.props;
    return showModal(ApproverMessageModal, {
      closeModal,
      flyer: filter(ApproverMessageModal.fragments.flyer, flyer),
    });
  };

  showApproverNotesModal = () => {
    const { showModal, closeModal, flyer } = this.props;
    return showModal(ApproverNotesModal, {
      closeModal,
      flyer: filter(ApproverNotesModal.fragments.flyer, flyer),
    });
  };

  showDenialReasonModal = () => {
    const { showModal, closeModal, flyer, flyerReasons, onStageFlyer } = this.props;
    const stagedFlyer = this.getStagedFlyer('denied');

    return showModal(DenialReasonModalContainer, {
      closeModal,
      onStageFlyer,
      stagedFlyer,
      flyer: filter(DenialReasonModalContainer.fragments.flyer, flyer),
      flyerReasons: filter(DenialReasonModalContainer.fragments.flyerReasons, flyerReasons),
    });
  };

  getStagedFlyer = (status: Status): StagedFlyer => {
    const flyerId = idx(this.props, _ => _.flyer.fml) || '';
    const flyerApprovals = idx(this.props, _ => _.flyer.approvals) || [];
    const formattedApprovals = flyerApprovals.reduce((acc, next) => {
      const nextId = next ? next.id : '';
      return {
        ...acc,
        [nextId]: status,
      };
    }, {});

    return {
      flyerId,
      approvals: formattedApprovals,
    };
  };

  stageApprovals = status => {
    const { onStageFlyer } = this.props;
    const stagedFlyer = this.getStagedFlyer(status);

    onStageFlyer(stagedFlyer);
  };

  stageApproves = () => this.stageApprovals('approved');

  stagePendings = () => this.stageApprovals('pending');

  render() {
    const {
      stagedFlyerStatus,
      sod,
      shouldUserSeeApproverNotesButton,
      flyerReasons,
    } = this.props;
    const id = idx(this.props, _ => _.flyer.fml) || null;
    const title = idx(this.props, _ => _.flyer.title) || '';
    const categoryName = idx(this.props, _ => _.flyer.categoryName) || '';
    const submitDate = idx(this.props, _ => _.flyer.submitDate) || 0;
    const duration = idx(this.props, _ => _.flyer.duration) || 0;
    const endDate = idx(this.props, _ => _.flyer.endDate) || 0;
    const eventDate = idx(this.props, _ => _.flyer.eventDate) || 0;
    const postDate = idx(this.props, _ => _.flyer.postDate) || 0;
    const sellerType = idx(this.props, _ => _.flyer.sellerType) || 'profit';
    const sellerNote = idx(this.props, _ => _.flyer.sellerNote) || '';
    const sellerEmail = idx(this.props, _ => _.flyer.sellerEmail) || '';
    const sellerUrl = idx(this.props, _ => _.flyer.sellerUrl) || '';
    const sellerPhone = idx(this.props, _ => _.flyer.sellerPhone) || '';
    const sellerCompanyName =
      idx(this.props, _ => _.flyer.sellerCompanyName) || '';
    const sellerTaxId = idx(this.props, _ => _.flyer.sellerTaxId) || '';
    const sellerTaxLodUrl = idx(this.props, _ => _.flyer.sellerTaxLodUrl) || '';
    const flyerThumbnailImage = idx(
      this.props,
      _ => _.flyer.districtPages[0].cdnImageUrl
    );
    const lastStatus =
      idx(this.props, _ => _.flyer.approvals[0].lastStatus) || null;
    const lastStatusUpdate =
      idx(this.props, _ => _.flyer.approvals[0].lastStatusUpdate) || 0;

    const pageCount =
      idx(this.props, _ => _.flyer.districtPages[0].pageCount) || 0;
    const schoolCount = idx(this.props, _ => _.flyer.approvals.length) || 0;

    const durationDays = Math.ceil((endDate - postDate) / SECONDS_PER_DAY  / DAYS_PER_MONTH);
    // const durationPseudoMonths = Math.max(
    //   MIN_PSEUDO_MONTHS,
    //   Math.floor(durationDays / DAYS_PER_PSEUDO_MONTH)
    // );
    const durationPseudoMonths = Math.ceil(duration / SECONDS_PER_DAY / DAYS_PER_MONTH) || durationDays;
    const hasMultiplePages = pageCount > 1;
    const hasMultipleSchools = schoolCount > 1;
    const isDistrict = sod === 'district';
    const isDistrictAndHasMultipleSchools = isDistrict && hasMultipleSchools;
    const hasEvent = !!eventDate;
    const isStatusApprove = stagedFlyerStatus === 'approved';
    const isStatusHold = stagedFlyerStatus === 'pending';
    const isStatusDeny = stagedFlyerStatus === 'denied';
    const isStatusMixed = stagedFlyerStatus === 'mixed';
    const hasSellerNote =
      typeof sellerNote === 'string' && sellerNote.length > 0;

    return (
      <div className={cx('layout-row', classNames.card)}>
        {/* Flyer Section */}
        <div className={`${classNames.w42}`}>
          <div className="layout-row">
            <div
              role="button"
              tabIndex="0"
              className={cx(`flex-33 px-3 ${classNames.textCenter} mb-2`, classNames.pointer)}
              onClick={this.showFlyerDetailLightBoxModal}
              onKeyPress={this.showFlyerDetailLightBoxModalOnEnter}
            >
              <div className={classNames.thumbImgWrapper}>
                <div className={classNames.flyerImgWrapper}>
                  <img
                    className={classNames.flyerImage}
                    src={flyerThumbnailImage}
                    alt={`${title} Thumbnail`}
                  />
                </div>
                {hasMultiplePages && (
                  <div>
                    <Note muted>{pageCount} pages</Note>
                  </div>
                )}
              </div>
              <span className={classNames.cardLink}>
                Text Version
              </span>
            </div>
            <div className="flex-66 pl-2 pr-3">
              <span className={cx('mb-2', classNames.titleWrapper)}>
                <Label bold className={classNames.titleLabel}>
                  {title}
                </Label>
                &nbsp;
                <Note muted className={classNames.flyerIdText}>
                  #{id}
                </Note>
              </span>

              <Tag categoryName={categoryName} />

              <ul className="list-unstyled mt-2">
                <li>
                  <Paragraph>
                    Submitted: {getFormattedDate(submitDate)}
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Scheduled posting: {getFormattedDate(postDate)}
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    {durationPseudoMonths > 0
                      ? `Distributions: ${durationPseudoMonths}`
                      : `Distribution: ${durationPseudoMonths}`}
                  </Paragraph>
                </li>
                {hasEvent && (
                  <li>
                    <Paragraph>Event:&nbsp;</Paragraph>
                    <Paragraph color="dragon">
                      {getFormattedDate(eventDate)}
                    </Paragraph>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {/* End Flyer Section */}

        <div className={classNames.divider} />

        {/* Uploader Section */}
        <div className="flex px-3 d-flex flex-column">
          <div className={cx('layout-row', classNames.flexGrow1)}>
            <div className="flex-50">
              <div className={classNames.orgGroupContainer}>
                <div className={classNames.orgnameContainer}>
                  <div className={classNames.infoTooltipCompensationContainer}>
                    <span className={classNames.forProfitTextAlignment}>
                      <Paragraph bold>{sellerCompanyName}</Paragraph>
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-2">
                <InfoPopover
                  iconName="info_outlined"
                  data={{
                    sellerType,
                    sellerTaxId,
                    sellerTaxLodUrl,
                    sellerEmail,
                    sellerUrl,
                    sellerPhone,
                    sellerCompanyName,
                    lastStatus,
                    lastStatusUpdate
                  }}
                />
              </div>
            </div>
            <div className={cx('flex-50 px-3', classNames.breakWord)}>
              {hasSellerNote && (
                <>
                  <div className="mb-2">
                    <Note bold>Uploader Message:</Note>
                  </div>
                  <Note className="mt-2">{sellerNote}</Note>
                </>
              )}
            </div>
          </div>
          {/* End Uploader Section */}

          {/* Approver Flat Buttons */}
          <div className="layout-row">
            <div className="flex mt-2 mb-2">
              {shouldUserSeeApproverNotesButton && (
                <a className={classNames.cardLink} onClick={this.showApproverNotesModal} href="javascript:void(0);">Approver Notes</a>
              )}
            </div>
            <div className={`flex px-3 mt-2 mb-2 ${classNames.msgUploaderLink}`}>
              <a className={classNames.cardLink} onClick={this.showApproverMessageModal} href="javascript:void(0);">Message Uploader</a>
            </div>
          </div>
          {/* End Approver Flat Buttons */}

          {/* Button Group */}
          <div className={cx('layout-row', classNames.rowMargin)}>
            <div className="flex-100 p-0 d-flex">
              <FlyerApprovalButton
                isActive={isStatusApprove}
                variant="approve"
                onClick={this.stageApproves}
                data-testid="click-approvalButton-approve"
              >
                <CircleCheck size="16" fill={colors.jungle} />
                <span className="ml-1">
                  {isDistrictAndHasMultipleSchools ? 'Approve all' : 'Approve'}
                </span>
              </FlyerApprovalButton>
              <FlyerApprovalButton
                isActive={isStatusHold}
                variant="hold"
                onClick={this.stagePendings}
                data-testid="click-approvalButton-hold"
              >
                <MaterialIcon
                  color={colors.peach}
                  name="remove_circle_outline"
                  className={classNames.buttonIcon}
                />
                <span className="ml-1">
                  {isDistrictAndHasMultipleSchools ? 'Hold all' : 'Hold'}
                </span>
              </FlyerApprovalButton>
              <FlyerApprovalButton
                isActive={isStatusDeny}
                variant="deny"
                onClick={this.showDenialReasonModal}
                data-testid="click-approvalButton-deny"
              >
                <MaterialIcon
                  color={colors.dragon}
                  name="highlight_off"
                  className={classNames.buttonIcon}
                />
                <span className="ml-1">
                  {isDistrictAndHasMultipleSchools ? 'Deny all' : 'Deny'}
                </span>
              </FlyerApprovalButton>
              {isDistrict && (
                <FlyerApprovalButton
                  isActive={isStatusMixed}
                  variant="mixed"
                  onClick={this.showSchoolApprovalModal}
                  data-testid="click-approvalButton-mixed"
                >
                  <MaterialIcon name="list" className={classNames.buttonIcon} />
                  &nbsp;
                  {schoolCount} School
                  {schoolCount > 1 && 's'}
                  <MaterialIcon
                    name="chevron_right"
                    className={cx(classNames.buttonIcon, classNames.largeIcon)}
                  />
                </FlyerApprovalButton>
              )}
            </div>
          </div>
          {/* End Button Group */}
        </div>
      </div>
    );
  }
}

ApprovalCard.fragments = {
  flyer: gql`
    fragment ApprovalCard on Flyer {
      fml: id
      title
      description
      categoryId
      categoryName
      sellerId
      sellerType
      sellerEmail
      sellerUrl
      sellerPhone
      sellerNote
      sellerCompanyName
      sellerTaxId
      sellerTaxLodUrl
      submitDate: submittedAt
      postDate
      endDate
      eventDate
      districtPages {
        id
        pageNumber
        imageUrl
        cdnImageUrl
        pageCount
      }
      approvals {
        id
        lastStatus
        lastStatusUpdate
        schoolId
        districtId
      }
      ...SchoolApprovalModal_flyer
      ...FlyerDetailLightBoxModal
      ...ApproverNotesModal
      ...ApproverMessageModal
      ...DenialReasonModalContainer_flyer
    }
    ${DenialReasonModalContainer.fragments.flyer}
    ${ApproverMessageModal.fragments.flyer}
    ${SchoolApprovalModal.fragments.flyer}
    ${FlyerDetailLightBoxModal.fragments.flyer}
    ${ApproverNotesModal.fragments.flyer}
  `,
  flyerReasons: gql`
    fragment ApprovalCard_flyerReasons on FlyerReason {
      ...DenialReasonModalContainer_flyerReasons
      ...SchoolApprovalModal_flyerReasons
    }
    ${DenialReasonModalContainer.fragments.flyerReasons}
    ${SchoolApprovalModal.fragments.flyerReasons}
  `,
};

const classNames = {
  orgGroupContainer: css`
    display: flex;
  `,
  orgnameContainer: css`
    flex-grow: 0;
  `,
  orginfoContainer: css`
    flex-grow: 0;
  `,
  negativeMarginLeft3: css`
    margin-left: -1rem;
  `,
  cardLink: css`
    color: ${colors.jungle};
    font-size: 13px;

    &:hover {
      color: #c5e8c9;
      text-decoration: none;
    }
  `,
  w42: css`
    width: 42%;
  `,
  rowMargin: css`
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  `,
  textCenter: css`
    text-align: center;
  `,
  flexGrow1: css`
    flex: 1 auto;
  `,
  titleWrapper: css`
    display: flex;
    max-height: 3em;
    overflow: hidden;
  `,
  titleLabel: css`
    display: flex;
    width: 100%;
  `,
  flyerIdText: css`
    display: flex;
  `,
  flyerImage: css`
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    max-width: 100%;
    max-height: 100%;
    word-break: break-word;
  `,
  flyerImgWrapper: css`
    height: 140px;
    overflow: hidden;
  `,
  // min-height probally not the best way - set it up to match mock for now
  thumbImgWrapper: css`
    width: 100%;
    min-height: 170px;
    text-align: center;
    border-radius: 6px;
    border: solid 1px ${colors.silver};
    padding-top: 0.5rem;
    margin-right: 0;
  `,
  card: css`
    padding-top: 1rem;
    border-radius: 6px;
    box-shadow: 0 0 4px 0 ${colors.stone};
    background-color: white;
  `,
  divider: css`
    outline: 1px solid ${colors.mercury};
    margin-top: -1rem;
  `,
  pointer: css`
    cursor: pointer;
  `,
  link: css`
    text-decoration: underline;
  `,
  buttonIcon: css`
    line-height: 0;
  `,
  largeIcon: css`
    font-size: 1.5rem;
  `,
  breakWord: css`
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  `,
  infoTooltipCompensationContainer: css`
    // This compensates for iconButton padding and forces it to bleed into gutter (per mock).
    margin-top: 0;
  `,
  nonProfitTextAlignment: css`
    vertical-align: middle;
  `,
  forProfitTextAlignment: css`
    line-height: 1.45;
    word-break: break-all;
  `,
  msgUploaderLink: css`
    padding-left: 2.9rem !important;
  `,
};

const mapStateToProps = (state, props) => {
  const apolloFlyerId = idx(props, _ => _.flyer.fml);
  const stagedFlyers = getStagedFlyers(state);
  const reduxApprovalStatuses = stagedFlyers[apolloFlyerId];
  const reduxApprovals = reduxApprovalStatuses
    ? reduxApprovalStatuses.approvals
    : [];
  const propApprovals = idx(props, _ => _.flyer.approvals) || [];
  const isStatus =
    reduxApprovalStatuses &&
    propApprovals.reduce(
      (acc, { id }) => acc && typeof reduxApprovals[id] === 'string',
      true
    );
  const status = isStatus
    ? Object.values(reduxApprovals).reduce((acc, next) =>
        acc !== next ? 'mixed' : acc
      )
    : null;

  return {
    stagedFlyerStatus: status,
  };
};

const mapDispatchToProps = (dispatch): ReduxDispatchProps => ({
  onStageFlyer: (stagedFlyer: StagedFlyer) => {
    dispatch(setApprovalStatus(stagedFlyer));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalCard);
