// @ts-ignore
import * as StripeV3 from 'stripe-v3';

import { get } from 'lodash';
import { apolloClient as creditsApolloClient } from '../_app/apollo/portalBFF.apolloClient';

import CreditService from "./CreditService";
import CreditPurchasingService from "./CreditPurchasingService";
import GqlCreditPurchasingService from "./services/GqlCreditPurchasingService";
import GqlCreditService from './services/GqlCreditService';
import config from '../config';

// This is to avoid situations where the UI won't load because the Stripe object is not available
class StripeStub {
  // tslint:disable-next-line
  constructor(...args: any[]) {}
  // tslint:disable-next-line
  redirectToCheckout() {}
}

const Stripe = get(window, 'Stripe', StripeStub) as any as StripeV3;

const stripe = new Stripe(config.STRIPE_PUBLISHABLE_API_KEY);

export type Dependencies = {
  creditPurchasingService: CreditPurchasingService,
  creditService: CreditService,
  stripe: StripeV3,
};

export default function getDependencies(): Dependencies {
  return {
    creditPurchasingService: new GqlCreditPurchasingService(creditsApolloClient),
    creditService: new GqlCreditService(creditsApolloClient),
    stripe,
  };
}
