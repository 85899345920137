import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import toString from 'lodash/toString';
import { connect } from 'react-redux';
import { components } from '@peachjar/components';
import LoadingSpinner from '../components/LoadingSpinner';
import AccountInformation from './AccountInformationStaff';
import accountsBFFClient from '../_app/apollo/accountsBFF.client';
import {
  GET_STAFF_ACCOUNT_INFO,
  UPDATE_MY_PROFILE,
  CHANGE_NOTIFICATION_PREFERENCES,
  GET_NOTIFICATION_PREFERENCES
} from '../account_info/accountInfo.graphql';
import { NOTIFICATIONS } from '../_app/constants';

export enum AccountInfoGroupType {
  District = 'district',
  School = 'school',
}

enum SubscriptionStatuses {
  Unsubscribed = 'unsubscribed',
  Subscribed = 'active',
}

export type Field = {
  value: string;
  error: boolean;
  textLimit: number;
};

export type SubscriptionOption = {
  groupName: string;
  groupType: string;
  groupId: number;
  hierarchyName: string;
  hierarchyType: string;
  hierarchyId: number;
  roles: string[];
  isChecked: boolean;
  subscribed: boolean;
};

type Props = {
  handleNotifySuccess: (key: string) => void;
  handleNotifyError: (key: string) => void;
};

const TEXT_LIMIT = 50;

const {
  Notifications: { notifySuccess, notifyError },
} = components;

const AccountInformationContainer: React.FunctionComponent<Props> = ({
  handleNotifySuccess,
  handleNotifyError,
}) => {
  const [userId, setUserId] = useState<string>()
  const [firstName, setFirstName] = useState<Field>({
    value: '',
    error: false,
    textLimit: TEXT_LIMIT,
  });
  const [lastName, setLastName] = useState<Field>({
    value: '',
    error: false,
    textLimit: TEXT_LIMIT,
  });
  const [email, setEmail] = useState<string>('');
  const [subscriptions, setSubscriptions] = useState<SubscriptionOption[]>([]);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [scopes, setScopes] = useState<string[]>([]);

  const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setFirstName({
      ...firstName,
      value: inputValue,
      error: inputValue.length === 0 || inputValue.length > TEXT_LIMIT,
    });
  };

  const onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setLastName({
      ...lastName,
      value: inputValue,
      error: inputValue.length === 0 || inputValue.length > TEXT_LIMIT,
    });
  };

  const onSubscriptionChange = async(
    isChecked: boolean
  ) => {

    setIsSubscribed(isChecked)
  };

  const onCancel = () => {
    window.location.reload();
  };

  const onUpdate = async () => {

    try {
      const profileResponse = await accountsBFFClient.mutate({
        mutation: UPDATE_MY_PROFILE,
        variables: {
          input: {
            firstName: firstName.value,
            lastName: lastName.value,
          },
        },
      });

      const changeNotificationsInput = {
        refId: 'staff',
        notifType: 'flyer-email',
        notifTypeId: userId,
        notifStatus: isSubscribed
          ? SubscriptionStatuses.Subscribed
          : SubscriptionStatuses.Unsubscribed
      }

      await accountsBFFClient.mutate({
        mutation: CHANGE_NOTIFICATION_PREFERENCES,
        variables: {
          input: changeNotificationsInput
        },
      });

      const fname = get(profileResponse, 'data.updateProfile.firstName');
      const lname = get(profileResponse, 'data.updateProfile.lastName');

      setFirstName({ ...firstName, value: fname });
      setLastName({ ...lastName, value: lname });

      handleNotifySuccess('staffAccountSubscriptionSuccess');
    } catch (e) {
      console.log('Unable to update user subscriptions.');
      handleNotifyError('genericError');
    }
  };

  useEffect(() => {
    const getDeps = async () => {
      const response = await accountsBFFClient.query({
        query: GET_STAFF_ACCOUNT_INFO,
        fetchPolicy: 'network-only',
      });

      const userProfileId = get(response, 'data.profile.id', '');
      const userFirstName = get(response, 'data.profile.firstName', '');
      const userLastName = get(response, 'data.profile.lastName', '');
      const userEmail = get(response, 'data.profile.email', '');
      const userSubscriptions = get(response, 'data.profile.subscriptions', []);
      const userSubscriptionOptions = get(
        response,
        'data.profile.academicGroupMemberships',
        []
      );


      // an option with groupType of district indicates a District Staff user
      const districtSubscription = userSubscriptionOptions.find(
        ({ groupType }) => groupType === AccountInfoGroupType.District
      );

      if (districtSubscription) {
        const subscriptionMatch = userSubscriptions.find(
          ({ hierarchyId }) =>
            parseInt(hierarchyId, 10) === districtSubscription.hierarchyId
        );
        const subd =
          !!subscriptionMatch &&
          subscriptionMatch.status === SubscriptionStatuses.Subscribed;
        districtSubscription.isChecked = subd;
        districtSubscription.subscribed = subd;
        districtSubscription.groupName = 'All District Schools';
      }

      const responseNotifications = await accountsBFFClient.query({
        query: GET_NOTIFICATION_PREFERENCES,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            refId: 'staff',
            notifType: 'flyer-email',
            notifTypeId: userProfileId,
          }
        }
      });
      const { data: { getNotificationPreferences }} = responseNotifications;

      if(getNotificationPreferences
        && getNotificationPreferences.notifStatus === SubscriptionStatuses.Subscribed){
          setIsSubscribed(true)
      }

      const mappedSubscriptionOptions = !!districtSubscription
        ? [districtSubscription]
        : userSubscriptionOptions.map(option => {
            const subscribed = userSubscriptions.find(
              ({ hierarchyId }) => hierarchyId === toString(option.hierarchyId)
            );

            const isSubscribed =
              !!subscribed &&
              subscribed.status === SubscriptionStatuses.Subscribed;

            return {
              ...option,
              isChecked: isSubscribed,
              subscribed: isSubscribed,
            };
          });

      setFirstName({ ...firstName, value: userFirstName });
      setLastName({ ...lastName, value: userLastName });
      setEmail(userEmail);
      setUserId(userProfileId)
      setSubscriptions(mappedSubscriptionOptions);

      setScopes(get(response, 'data.profile.scopes', []));
    };

    getDeps();
  }, []);

  if (!email) {
    return <LoadingSpinner />;
  }

  const mergedProps = {
    firstName,
    lastName,
    email,
    subscribed: subscriptions.filter(({ subscribed }) => subscribed),
    subscriptionOptions: subscriptions,
    isSubscribed,
    handleCancel: onCancel,
    handleUpdate: onUpdate,
    handleFirstNameChange: onFirstNameChange,
    handleLastNameChange: onLastNameChange,
    handleSubscriptionChange: onSubscriptionChange,
    scopes,
  };


  return <AccountInformation {...mergedProps} />;
};

export default connect(
  null,
  dispatch => ({
    handleNotifySuccess: (key: string): void => {
      dispatch(notifySuccess(NOTIFICATIONS[key]));
    },
    handleNotifyError: (key: string): void => {
      dispatch(notifyError(NOTIFICATIONS[key]));
    },
  })
)(AccountInformationContainer);
