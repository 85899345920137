export default function sanitizeUrlInput(event, removable = false) {
  const url = event.target.value;
  // reset protocol when deleting input text
  if (url === 'htt') {
    return 'http://';
  }

  if (url === '' && removable) {
    return '';
  }

  if (!url.includes('http')) {
    return `http://${url}`;
  }

  return url;
}
