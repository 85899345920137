import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { compose } from 'react-apollo';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { components, elements } from '@peachjar/components';
import TextInput from './components/TextInput';
import portalBffApolloClient from '../_app/apollo/portalBFF.apolloClient';
import accountsBff from '../_app/apollo/accountsBFF.client';
import SubappHeader from '../_app/components/SubappHeader';
import {
  FIRST_NAME_MAX_LENGTH_ERROR_MESSAGE,
  MISSED_FIELD_ERROR_MESSAGE,
  INVALID_EMAIL_ADDRESS,
} from './account_info_details/constants';
import { NOTIFICATIONS } from '../_app/constants';
import { validateEmailFormat } from '../helpers/formsHelpers';
import styles from './accountInfo.styles';
import {
  GET_ORG_ACCOUNT_INFORMATION,
  UPDATE_MY_PROFILE,
  VERIFY_CHANGE_EMAIL,
} from './accountInfo.graphql';
import LoadingSpinner from '../components/LoadingSpinner';
import SpinningSubmitButton from '../components/SpinningSubmitButton';
import MessageBanner from '../_app/components/Banners/MessageBanner';
import emailSendIcon from '../assets/email-send-icon.svg';

const { notifyWarn, notifyError, notifySuccess } = components.Notifications;
const { Note } = elements.typography;
const { ButtonFlatLarge } = components.Buttons;

const AccountInfo = ({ classes, gqlStuff, handleError, handleSuccess }) => {
  const { refetchViewerQuery } = gqlStuff;

  const { loading, data, error } = useQuery(GET_ORG_ACCOUNT_INFORMATION, {
    client: portalBffApolloClient,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  // Rest of Account Info form state
  const [firstNameState, setFirstNameState] = useState(
    get(data, 'profile.firstName', '')
  );
  const [firstNameErrorState, setFirstNameErrorState] = useState('');
  const firstNameError = firstNameErrorState;

  const [lastNameState, setLastNameState] = useState(
    get(data, 'profile.lastName', '')
  );
  const [lastNameErrorState, setLastNameErrorState] = useState('');
  const lastNameError = lastNameErrorState;

  const [emailAddressState, setEmailAddressState] = useState(
    get(data, 'profile.email', '')
  );
  const [oldEmailAddressState, setOldEmailAddressState] = useState(
    get(data, 'profile.email', '')
  );

  const [emailAddressErrorState, setEmailAddressErrorState] = useState('');
  const [showBannerVerifyEmail, setShowBannerVerifyEmail] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const emailAddressError = emailAddressErrorState;
  const userId = get(data, 'profile.userId', 0);
  const hierarchyType = get(data, 'profile.hierarchy.type', 'organization');

  const [updateMyProfile, { loading: updateMyProfileLoading }] = useMutation(
    UPDATE_MY_PROFILE,
    {
      client: portalBffApolloClient,
    }
  );

  const [
    verifyChangeEmail,
    { loading: verifyChangeEmailLoading },
  ] = useMutation(VERIFY_CHANGE_EMAIL, {
    client: accountsBff,
  });

  const variables = {
    input: {
      firstName: firstNameState,
      lastName: lastNameState,
      email: oldEmailAddressState,
    },
  };

  const isFormInvalid = function() {
    return (
      !firstNameState.length ||
      !lastNameState.length ||
      !emailAddressState.length ||
      firstNameErrorState.length > 0 ||
      lastNameErrorState.length > 0 ||
      emailAddressErrorState.length > 0
    );
  };

  // renders errors for missed fields if user clicks disabled save button
  const disabledClickShowErrors = () => {
    const fieldErrorPairs = [
      [firstNameState, setFirstNameErrorState],
      [lastNameState, setLastNameErrorState],
      [emailAddressState, setEmailAddressErrorState],
    ];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < fieldErrorPairs.length; i++) {
      if (!fieldErrorPairs[i][0]) {
        fieldErrorPairs[i][1](MISSED_FIELD_ERROR_MESSAGE);
      }
    }
  };

  const emailCheck = email => {
    const isEmailValid = validateEmailFormat(email);

    if (!email.length) {
      setEmailAddressErrorState(MISSED_FIELD_ERROR_MESSAGE);
      return;
    }

    if (!isEmailValid) {
      setEmailAddressErrorState(INVALID_EMAIL_ADDRESS);
      return;
    }

    // email valid
    setEmailAddressState(email);
    setEmailAddressErrorState('');
  };

  const handleInputChange = (evt, field) => {
    const updateStateMapping = {
      firstName: setFirstNameState,
      lastName: setLastNameState,
      email: setEmailAddressState,
    };
    const inputValue = evt.target.value;
    updateStateMapping[field](inputValue);
    setIsUpdated(true);
  };

  const handleFirstNameKeyUp = evt => {
    if (evt.keyCode !== 9) {
      if (!firstNameState) {
        setFirstNameErrorState(MISSED_FIELD_ERROR_MESSAGE);
      } else if (firstNameState.length > 50) {
        setFirstNameErrorState(FIRST_NAME_MAX_LENGTH_ERROR_MESSAGE);
      } else {
        setFirstNameErrorState('');
      }
    }
  };

  const handleFirstNameBlur = evt => {
    const target = evt.target.value;
    if (!target) {
      setFirstNameErrorState(MISSED_FIELD_ERROR_MESSAGE);
    } else {
      setFirstNameErrorState('');
    }
  };

  const handleLastNameKeyUp = evt => {
    if (evt.keyCode !== 9) {
      if (!lastNameState) {
        setLastNameErrorState(MISSED_FIELD_ERROR_MESSAGE);
      } else if (lastNameState.length > 50) {
        setLastNameErrorState(FIRST_NAME_MAX_LENGTH_ERROR_MESSAGE);
      } else {
        setLastNameErrorState('');
      }
    }
  };

  const handleLastNameBlur = evt => {
    const target = evt.target.value;
    if (!target) {
      setLastNameErrorState(MISSED_FIELD_ERROR_MESSAGE);
    } else {
      setLastNameErrorState('');
    }
  };

  const handleEmailKeyUp = () =>
    emailCheck(
      emailAddressState,
      setEmailAddressState,
      setEmailAddressErrorState
    );

  const handleEmailBlur = evt => {
    if (!evt.target.value) {
      setEmailAddressErrorState(MISSED_FIELD_ERROR_MESSAGE);
    }
  };

  const onSubmit = async () => {
    try {
      if (oldEmailAddressState !== emailAddressState) {
        const verifyVariables = {
          input: {
            userId,
            email: emailAddressState,
            hierarchyType,
          },
        };

        const {
          errors: verifyErrors,
          data: verifyData,
        } = await verifyChangeEmail({
          variables: verifyVariables,
        });
        if (verifyErrors) {
          handleError(verifyErrors[0].message);
          return;
        }

        if (verifyData) {
          setEmailAddressState(verifyData.verifyChangeEmail.email);
          setOldEmailAddressState(verifyData.verifyChangeEmail.email);
          setShowBannerVerifyEmail(true);
        }
      }
      await updateMyProfile({ variables });
      setIsUpdated(false);
      handleSuccess('accountInfoSaveSuccess');
    } catch (e) {
      const errorMessage = e.message.replace('GraphQL error: ', '') || e;
      if (errorMessage === 'An internal error has occurred.') {
        handleError(NOTIFICATIONS.accountInfoEmailAlreadyExists);
      } else {
        handleError(errorMessage);
      }
    }
  };

  return (
    <>
      <div>
        <SubappHeader>Account Information</SubappHeader>
        <Paper className={`${styles.container} ${classes.elevation2}`}>
          <div className={styles.subContainer}>
            <div>
              <TextInput
                label="First Name"
                name="firstName"
                onChange={evt => handleInputChange(evt, 'firstName')}
                onKeyUp={evt => handleFirstNameKeyUp(evt)}
                onBlur={evt => handleFirstNameBlur(evt)}
                value={firstNameState}
                error={!!firstNameError}
                data-testid="input-org-account-firstNameInput"
              />
            </div>
            <div className={styles.fieldContainer}>
              <FormHelperText style={{ textAlign: 'right' }}>
                <div
                  className={`layout-row layout-align-${
                    firstNameError ? 'space-between' : 'end'
                  }`}
                >
                  {firstNameError && (
                    <Note className={styles.errorText}>{firstNameError}</Note>
                  )}
                </div>
              </FormHelperText>
            </div>
          </div>
          {/* end first name */}
          {/* start last name */}
          <div className={styles.subContainer}>
            <div>
              <TextInput
                label="Last Name"
                name="lastName"
                onChange={evt => handleInputChange(evt, 'lastName')}
                onKeyUp={evt => handleLastNameKeyUp(evt)}
                onBlur={evt => handleLastNameBlur(evt)}
                value={lastNameState}
                error={!!lastNameError}
                data-testid="lastNameInput"
              />
            </div>
            <div className={styles.fieldContainer}>
              <FormHelperText style={{ textAlign: 'right' }}>
                <div
                  className={`layout-row layout-align-${
                    lastNameError ? 'space-between' : 'end'
                  }`}
                >
                  {lastNameError && (
                    <Note className={styles.errorText}>{lastNameError}</Note>
                  )}
                </div>
              </FormHelperText>
            </div>
          </div>
          {/* end last name */}
          {/* start email address */}
          <div className={styles.subContainer}>
            <div>
              <TextInput
                label="Email Address"
                name="emailAddress"
                type="email"
                onChange={evt => handleInputChange(evt, 'email')}
                onKeyUp={handleEmailKeyUp}
                onBlur={evt => handleEmailBlur(evt)}
                value={emailAddressState}
                error={!!emailAddressError}
                data-testid="emailAddressInput"
              />
            </div>
            <div className={styles.fieldContainer}>
              <FormHelperText>
                <div
                  className={`layout-row layout-align-${
                    emailAddressError ? 'flex-start' : 'end'
                  }`}
                >
                  {emailAddressError && (
                    <Note className={styles.errorText}>
                      {emailAddressError}
                    </Note>
                  )}
                </div>
              </FormHelperText>
            </div>
            {showBannerVerifyEmail && (
              <MessageBanner
                iconSrc={emailSendIcon}
                subheadline="Your email change has been saved. Please check your email and click the link to verify. Upon verifying your email address, your email will be updated."
                variant="orange"
              />
            )}
          </div>
          <div className={styles.bottomSubContainer}>
            <div className={styles.bottomButtonContainer}>
              <ButtonFlatLarge
                className={styles.cancelButton}
                onClick={() => window.location.reload()}
              >
                Cancel
              </ButtonFlatLarge>

              <div
                onClick={() => {
                  if (isFormInvalid()) {
                    disabledClickShowErrors();
                  }
                }}
              >
                <SpinningSubmitButton
                  disabled={isFormInvalid() || !isUpdated}
                  dataTestId="savebtn"
                  handleClick={onSubmit}
                  loading={updateMyProfileLoading || verifyChangeEmailLoading}
                >
                  Save
                </SpinningSubmitButton>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};

const materialUIstyles = {
  elevation2: {
    boxShadow: 'none',
  },
};

const mapDispatchToProps = dispatch => ({
  handleWarning: key => {
    dispatch(notifyWarn(NOTIFICATIONS[key]));
  },
  handleError: error => {
    dispatch(notifyError(error));
  },
  handleSuccess: key => {
    dispatch(notifySuccess(NOTIFICATIONS[key]));
  },
});

AccountInfo.fragments = {
  sodUser: gql`
    fragment AccountInfo_sodUser on SodUser {
      id
    }
  `,
};

const enhance = compose(
  withStyles(materialUIstyles),
  connect(null, mapDispatchToProps)
);

export default enhance(AccountInfo);
