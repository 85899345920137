import React from 'react';
import { css, cx } from 'emotion';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { elements, components, colors } from '@peachjar/components';

type Content = {
  headline: string,
  subheadline: string,
  subheadlineDashboard: string,
  subheadlineDeals: string,
  subheadline2: string,
  details: string,
  cta: string,
  ctaApplied: string,
};

type ModalAction = () => void;

type Props = {
  content: Content,
  promoApplied: boolean,
  classes: {
    [k: string]: any,
  },
  bannerImage: string,  
  credits: number,
  handleClick: (showModal: ModalAction, closeModal: ModalAction) => void,
};

const { typography: { Headline2, Paragraph } } = elements;
const { Buttons: { ButtonPrimarySmall }, ModalConsumer } = components;

const CyberSaleBanner = ({
  content,
  promoApplied,
  bannerImage,  
  classes,
  credits,  
  handleClick,
}: Props) => {
  const {
    headline,
    subheadlineDashboard,
    details,
    cta,    
  } = content;
  
  return (
    <Grid item xs={12}>
      <Paper
        className={`${cn.banner} ${
          classes.elevation2
        }`}
      >
        <div className={cn.illustration}>
          <img src={bannerImage} alt={headline} />
        </div>
        <div className={cn.offerDetails}>
          <div className={cn.content}>
            <Headline2 className={cn.headline}>{headline}</Headline2>
            <Paragraph
              className={cn.subheadline} 
              dangerouslySetInnerHTML={{ __html: subheadlineDashboard }}
            />            
            <Paragraph
              className={cn.details}
              dangerouslySetInnerHTML={{ __html: details }}
            />
          </div>
        </div>
        <div className={cn.cta}>
          <ModalConsumer>
            {({ showModal, closeModal }) => (
              <ButtonPrimarySmall
                data-testid="click-cyber-deal-20"
                onClick={() => handleClick(showModal, closeModal)}
                className={cn.ctaButton}                
              >
                {cta}
              </ButtonPrimarySmall>
            )}
          </ModalConsumer>
        </div>
      </Paper>          
    </Grid>
  );
};

const materialOverrides = {
  elevation2: {
    boxShadow: 'none',
  },
};

const cn = {
  banner: css`
    display: flex;
    flex-direction: 'row';
  `,    
  headline:css`
    font-weight: 600;
    font-size: 25px;
  `,
  subheadline: css`      
    font-size: 19px;
  `, 
  subheadline2: css`
    display: block;
  `,
  illustration: css``,
  offerDetails: css``,
  content: css`
    padding: 28px 80px 0px 32px;
  `,
  details: css`
    font-size: 13px;
    display: block;
    margin-top: 6px;
    color: ${colors.slate};
  `,
  cta: css`    
    margin-top: 72px;
  `,   
  ctaButton: css``,
};

export default withStyles(materialOverrides)(CyberSaleBanner);
