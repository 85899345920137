import gql from 'graphql-tag';
import CampaignSlatListFragments from './CampaignSlatListFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment DraftCampaigns_getMyCampaigns on Campaign {
      ...CampaignSlatList_getMyCampaigns
    }
    ${CampaignSlatListFragments.getMyCampaigns}
  `,
};
