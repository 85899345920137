import React, { useState, useEffect } from 'react';
import { css } from 'react-emotion';
import { components, elements, colors } from '@peachjar/components';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  feeRange,
  MISSED_FIELD_ERROR_MESSAGE,
} from '../../../../../shared/constants';
import ParticipationFeeFragments from './gql/ParticipationFeeFragments.graphql';

const { Dropdown } = components;
const { typography } = elements;
const { Note } = typography;
const { dragon } = colors;

export const ParticipationFee = React.memo(
  ({
    participationFee,
    setDetails,
    performValidation,
    shouldPerformDisabledClickValidation,
    isOrg,
  }) => {
    const [error, setError] = useState(false);

    useEffect(() => {
      performValidation({ target: { value: isOrg ? participationFee : true } });
      if (isOrg && shouldPerformDisabledClickValidation) {
        setError(!participationFee);
      }
    });

    return (
      <>
        <Dropdown
          label="Participation Fee Average"
          options={feeRange}
          error={error}
          width="100%"
          name="participationFee"
          value={generateDropdownValue(participationFee)}
          onChange={event => {
            setDetails({
              variables: {
                name: 'participationFee',
                value: event.target.value,
              },
            });
          }}
          data-testid="flyerParticipationFee"
        />
        {error && (
          <FormHelperText>
            <Note
              className={css`
                color: ${dragon};
              `}
            >
              {MISSED_FIELD_ERROR_MESSAGE}
            </Note>
          </FormHelperText>
        )}
      </>
    );
  }
);

ParticipationFee.fragments = ParticipationFeeFragments;

export default ParticipationFee;

export const generateDropdownValue = (val, useLabel) => {
  // There were changes to read from mysql that affected the participationFee. Its being parsed and saved as a lowParticipationFee and highParticipationFee when submitted to geocore. Before it's submitted to Geocore, the participation fee in postgres is now being saved as "$51.00" versus "$51.00 - $100.00". This function will return the full string needed by the dropdown if the full range isn't provied by in the partiicpationFee. val.includes handles use cases in reporting, and displaying participation fee in the drawer.

  let dropdownValue = '';

  // If val is returned from the db. Includ
  if (val === '$0.00' || (val && val.includes('$0.00')))
    dropdownValue = useLabel ? feeRange[0].label : feeRange[0].value;
  else if (val === '$1.00' || (val && val.includes('$1.00')))
    dropdownValue = useLabel ? feeRange[1].label : feeRange[1].value;
  else if (val === '$51.00' || (val && val.includes('$51.00')))
    dropdownValue = useLabel ? feeRange[2].label : feeRange[2].value;
  else if (val === '$101.00' || (val && val.includes('$101.00')))
    dropdownValue = useLabel ? feeRange[3].label : feeRange[3].value;
  else if (val === '$201.00' || (val && val.includes('$201.00')))
    dropdownValue = useLabel ? feeRange[4].label : feeRange[4].value;
  // If val is changed by the dropdown
  else dropdownValue = val;

  return dropdownValue;
};

export const formatParticipationFee = generateDropdownValue;
