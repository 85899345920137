import * as React from 'react';

const NoPendingApprovalsLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="416"
    height="449"
    viewBox="0 0 416 449"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FCFDFD"
        d="M156.31 163.94l-3.61-.56a11.52 11.52 0 0 1-9.62-13.15l5.08-32.72a1.37 1.37 0 0 1 1.56-1.14A32.51 32.51 0 0 1 173.4 120c.355.064.673.26.89.55.21.288.3.647.25 1l-5.08 32.73a11.52 11.52 0 0 1-13.15 9.66z"
      />
      <path
        fill="#FCEFC8"
        d="M155.67 161c-4.11-1.06-6.92-2.77-8.45-5.27-1.75-2.84-1.37-6.06-1.09-8.84.24-2.42.74-3.24 1.8-8.88.3-1.6.52-2.92.66-3.81a21.57 21.57 0 0 1 13-1.52 21.08 21.08 0 0 1 8.66 4c-.22 1.41-.44 2.81-.67 4.22a73.41 73.41 0 0 0-1.81 9.25c-.31 2.44-.38 4.1-1.54 6.08a9.88 9.88 0 0 1-10.6 4.8l.04-.03z"
      />
      <path
        fill="#DFE1E7"
        d="M185.3 174a19.9 19.9 0 0 1-2.81-3.4c-2.28-4.49-9-6.79-7.1-13.25 1.51-5.22.72-10-1.19-14.87-1.7-2-3.34-4.08-5.11-6-.76-.83-1.76-1.81-3-1.13-1.24.68-.86 2-.73 3.14.36 3.18 1.9 6 3 8.91 3.46 9-1 16.24-10.14 16.73 4.22 6.73 8.16 13.63 13.06 19.91 1.37 2.38 4.57 6.61 5.94 9l23.92-2.17c-4.66-5-11.14-11.87-15.84-16.87z"
      />
      <path
        fill="#747C99"
        d="M406.53 245.49c-3.62-7.17-17-27.4-85.57-43.37a455.25 455.25 0 0 0-99.43-11.76h-13.82a537.24 537.24 0 0 0-78 8.44c-10 1.84-22.68 4.2-35.78 7.46l-1.48.28c-12.41 2.34-15.12 2.32-17.08.68-2.12-1.78-2.43-4.67-2.55-6.1-1-12.31 12.47-24.2 24.81-40.25a161.6 161.6 0 0 0 19.31-31.4C122 118.38 128.61 104 129 84.23c.12-6.74-1.38-30.87-16.25-53.78-5.56-8.51-13.1-13.45-14.86-14.95A67.32 67.32 0 0 0 72.68 2.32C58.93-1.6 51.74.24 46.25 2.05a59.84 59.84 0 0 0-19 10C12 23 6.55 38.3 4.85 43.34 3.34 47.78-6.29 81.87 6.69 99.13c3.73 5 6 4.75 8.91 10.33 4.56 8.65 6.21 22.46 6.76 27.06.32 2.61.69 6.45 3.56 8.55a7.69 7.69 0 0 0 6.78 1.1c3.59-1.24 4.38-5.24 7.47-11.79A79.1 79.1 0 0 1 48 121.21c6.23-8.1 10.27-13.19 16-20.89 2.61-3.53 6.55-8.86 6.77-16 .14-4.78.62-4.73.25-9s-2.1-8.21 3.37-10.62c4-1.75 6-.25 6.88.34 6.81 4.58 5.86 13.19 6.45 18a41.28 41.28 0 0 1-2.76 20.44C80 115.7 74.62 123.13 64.5 133.13c-30.33 30-34.41 33.72-40.32 41.91a145.72 145.72 0 0 0-15.41 27.42c-5.59 13-20.38 67 10.12 102.71a74.22 74.22 0 0 0 16.82 14.49 40.4 40.4 0 0 0 4 2.38c61.84 32.23 187.77 31 187.77 31s104.36-1 162-31a48 48 0 0 0 14.23-11.48 52.23 52.23 0 0 0 8-16.69 65.73 65.73 0 0 0 2.46-18 76.52 76.52 0 0 0-7.64-30.38z"
      />
      <path
        fill="#C5C9D6"
        d="M96.58 420.91a12.45 12.45 0 0 1-3.78 8.37c-4.13 4.22-5.5 10.18-8.84 15-1.16 1.67-2 3.55-4.35 3.64-1.46.05-3.18.6-4.32-.54-1.39-1.4.18-2.74.61-4.07.5-1.53 1.4-3 1.06-5.38l19.62-17.02z"
      />
      <path
        fill="#DFE1E7"
        d="M291.94 167.29c-8.3 10.75-20.11 18.79-31.65 25.06l-25.05-1.6c1-6.09 5.65-11.62 10-15.09a40.89 40.89 0 0 1 32.83-9c4.55.72 9.24.46 13.87.63z"
      />
      <path
        fill="#C5C9D6"
        d="M99.29 420a39.41 39.41 0 0 1 3.63-21.5 20.81 20.81 0 0 0 1.74 4.42c1 1.87 1.69 2.43 2.45 4.19a13.05 13.05 0 0 1 1 4.08c.13 3.28-3.6 6.72-8.82 8.81z"
      />
      <path
        fill="#47506B"
        d="M213.51 268.23c-38.42-.63-91-4.21-106.13-7.56-15.13-3.35-27.46-11.94-27.24-19.3.52-17.13 73.7-31.63 135.84-31.66 62.41 0 135.8 14.5 136.32 31.66.22 7.35-12.07 15.93-27.24 19.3-15.17 3.37-67.71 6.93-106.13 7.56h-5.42z"
      />
      <path
        fill="#F4A941"
        d="M325.17 24.86c3.85.76 11.61 74 13 75.64.05.06.9 1 2 2.43 1.44 1.79 1.84 2.46 4.32 5.21a235.56 235.56 0 0 0 19.86 20.11c1.2 1.51 2.4 3 3.59 4.52 5.52 5.52 11.043 11.033 16.57 16.54 1.52 1.51 2.84 3.28 4.94 4.1.55 2.7 3.13 4.06 4.24 6.4A108.24 108.24 0 0 1 404 175.08a122.28 122.28 0 0 1 6.75 14.73c3.7 9.07 5.58 13.63 4.69 15.28-3.66 6.79-25.39 2.52-38.16-1.8-7.55-2.54-23.53-8.16-37.5-23.7-8.08-9-10.17-12.48-10.72-15.07-2-9.29 4.32-13.09 4.3-23.63 0-7.49-3.19-8.8-6.79-15.1-.73-1.28-2-3.13-4.5-11.17s-6.74-21.49-8.22-31.29c-4.22-27.87 8.69-58.98 11.32-58.47z"
      />
      <path
        fill="#DFE1E7"
        d="M357 178.11c-4.79-10-1.18-19.11 3-28.16 2.53-3.94 1.55-7.58-.39-11.52-2.1-4.28-3.48-8.92-5.17-13.4l-7.12-20.4c-4.17 1.18-8.3 2.61-12.74 2.28l-2.46.06c-6 6.67-9.92 14.3-10.81 23.34-.4 3.23.9 6.05 2 8.94 1.63 4.14 5.75 6.19 8 9.8a173.568 173.568 0 0 1-2.09 4.94c-8.61 9.26-18.59 15.41-31.88 14-2.9 3-7 7.36-11.67 13s-7.17 8.71-8.44 12c-1.15 3-3.27 10.47 1.78 22.14 10.75-8.24 23.79-8.39 36.28-9.88 18.08-2.17 33.55-10 48.14-20.2-2.32-2.14-5.55-3.45-6.43-6.94z"
      />
      <path
        fill="#C5C9D6"
        d="M174.32 233a34.11 34.11 0 0 0-13.43 2c-10.92 4-17.12 12.72-20.69 18.41-15 23.92-25.66 59.64-32.78 85.48-3.57 13-7.33 27.37-14.6 47.73-2.86 8-5.41 14.58-7.17 19 .81-16.2 1.28-29.34 1.56-38.38.38-12 1.22-19.34 1.24-31.78 0-8.22 1.28-16.45 2-24.67 1.61-10.12 3.1-20.25 4.87-30.34a186.76 186.76 0 0 1 9.31-34.54c2.92-7.65 7.26-14.32 15.17-19.37 11.5-7.34 24.23-5.44 28.55-4.59a81.17 81.17 0 0 1 11.45 3.58 80.07 80.07 0 0 1 14.52 7.47z"
      />
      <path
        fill="#FADA82"
        d="M415.5 204.69c.58-.13-.45-8.52-2.45-17.12-1.5-6.43-3.05-10.84-6.14-19.4a255.36 255.36 0 0 0-13.91-32.4c-2.42-4.69-2.5-4.25-4.69-8.74a236.06 236.06 0 0 1-11.16-26c-.95-1.68-1.89-3.36-2.84-5-3-7.19-6.08-14.37-9.09-21.57-.83-2-2-3.86-1.94-6.12-2.28-1.53-2.56-4.43-4.28-6.34a106.69 106.69 0 0 0-10.29-15.26A124.29 124.29 0 0 0 337.57 35c-7-6.84-10.52-10.29-12.4-10.09-7.66.84-11.87 22.58-12.68 36-.48 7.95-1.29 24.87 7.85 43.66a84.92 84.92 0 0 0 26.94 31.58c5.74 4.18 6.56 3.54 20.85 12.4 13 8.05 19.5 12 24.27 16.37a72.49 72.49 0 0 1 17.86 24.35c3.67 8.37 4.74 15.53 5.24 15.42z"
      />
      <path
        fill="#F6C35F"
        d="M399 80.32c-6.61-9.51-15.95-14.18-27.78-13.12-2.78-.55-5.33.49-7.93 1.14 0 2.26 1.11 4.14 1.94 6.12 3 7.2 6.06 14.38 9.09 21.57 1 1.67 1.89 3.35 2.84 5a236.06 236.06 0 0 0 11.16 26c1.35 3 1.32 6.8 4.69 8.74 2.16-1.58 4.69-2.68 6.33-4.94a25.19 25.19 0 0 0 9.05-15.5c2.34-13.11-2.11-24.58-9.39-35.01z"
      />
      <path
        fill="#DFE1E7"
        d="M384.12 222.93a70.06 70.06 0 0 0-.85-8.44c-.42-2.62-1-7.48-2.84-11.45a35.71 35.71 0 0 0-12.23-14.2c-13.15 6.68-25.77 14.53-32.6 28.25-.52 3.23-1.62 6.77-2.14 10 .2 2.43-1.22 4.83-.48 7.21 1 3.28 4.33 6.53 5.28 9.76l11.11-11a148 148 0 0 0-3.14 18.44c-.46 4.15-.67 7.33-1.44 11.39-.4 2.23-7.06 30.33-8.3 31.67-2.68 2.87-5.56 6-8.56 9.23v-.1c-5.88 6.47-11.82 12.89-17.7 19.36-2.79 3-7.23 8-9.46 10.56v-.18c-.24.35-.49.67-.74 1l-.65.74-1.2 1.35a34.44 34.44 0 0 1-3.73 3.72 22.83 22.83 0 0 1-7.39 3.78c-6.5 2-13.64 3.25-19.88 6-11.47 5-17 15.25-22.33 25.61a3.44 3.44 0 0 0-.23 1 2.14 2.14 0 0 0 1 2.55c1.11.52 1.94-.21 2.56-1 1.26-1.55 2.31-3.26 3.61-4.76 3.38-3.88 6-8.51 11.5-12.54-1 2.91-1.65 4.38-2 5.92-.58 2.38-5.14 4.38-2.22 6.62 2.61 2 5-1.82 6.62-3.72a19.11 19.11 0 0 1 15.67-7.16c6.76 0 12.49-2.7 17.54-7.47l1.75-1.45c10.88-9.31 23.83-17 28.86-20.22.84-.54 3.8-2.43 7.73-5.2 1.28-.91 2.6-1.86 4-2.88 3.05-2.27 7.34-7.29 10.52-9.35 7.5-4.89 9.67-9.6 11.42-17.95 4.24-20.16 16.65-47.08 19.62-59.09l.62-3.33a67.27 67.27 0 0 0 .7-12.67z"
      />
      <path
        fill="#2C334A"
        d="M368.31 188.81c-1.36-1.59-2.66-3.26-4.86-3.76a134.73 134.73 0 0 1-22.76 13.11c-6.29 2.77-14.58 6.19-25.38 7.09-15.15 1.26-35.29-2.6-36.93-10.73-.58-2.91 1.57-6.83 7.31-13.52 3.79-4.41 7.77-8.66 11.67-13-1.72-.91-3.58-.79-5.42-.73-8.3 10.75-20.11 16.64-31.65 22.91a28.38 28.38 0 0 0-9.21 10.89c-2 4.27-2.34 8-2.78 12.94a67.65 67.65 0 0 0 .21 14.12 42.83 42.83 0 0 1-1 8.12 41.74 41.74 0 0 1-3 8.8c-4.76 9.62-11.87 15.58-23.23 15l5.83 8 4.18-.18c1.82.05 3.64.14 5.46.15 18.25.09 36.36-2.07 54.53-3.18 9.14-.88 30.43-3.18 34.37-4.37 1.68-.5 3.63-2.76 4.72-4.4 1.09-1.64 5.32-8 8-12.06a39.09 39.09 0 0 1-3.55-14.53 27.58 27.58 0 0 1 1.68-12.12 28.36 28.36 0 0 1 3.94-6.6c7-9.88 16.99-16.42 27.87-21.95z"
      />
      <path
        fill="#47506B"
        d="M6.67 99.06c3.72 4.94 6 4.75 8.9 10.32 4.56 8.65 6.22 22.47 6.77 27.07.31 2.61.69 6.44 3.56 8.54a7.7 7.7 0 0 0 6.77 1.11c3.6-1.25 4.38-5.24 7.47-11.79A78.94 78.94 0 0 1 48 121.13c6.22-8.09 10.26-13.18 16-20.88 2.61-3.54 6.55-8.87 6.77-16 .15-4.78.62-4.73.25-9C56.55 86.48 46.31 87.62 39.4 86.29c-7.32-1.41-14.62-6.29-18.27-3.14-2.46 2.15-.72 5.74-3.43 9.85-2.59 3.92-7.32 5.42-11.03 6.06z"
      />
      <path
        fill="#DFE1E7"
        d="M101.29 419a12.58 12.58 0 0 1-.88 4.07 13.09 13.09 0 0 1-2.89 4.3c-3.31 3.16-6.33 1.9-11.37 4.59a20 20 0 0 0-5.15 3.97 48.39 48.39 0 0 1-8 7.18c-3.18 2.25-4.7 2.73-5.5 2.94a13.58 13.58 0 0 1-8.14-.57 2.38 2.38 0 0 1-.11-1.32c.34-1.5 2.3-2.23 2.72-2.4 2-.83 2.61-2.67 7.06-13.26 1.54-3.66 2.31-5.48 2.39-5.63a43 43 0 0 1 8.17-10.37c2.94-2.94 3.22-2.67 4.54-4.44 2.26-3 2.93-5.85 3.83-9.08 1.19-4.26 2.92-8.37 4.06-12.64a1686.564 1686.564 0 0 1 13.36-47.43c8.8-29.64 18.05-59.15 34.85-85.53 7.89-12.39 18.28-21.07 34.12-20.36 9.83.59 18.57 4.15 26.74 9.47 7.57 4.93 13.77 11.39 20.24 17.55l5.83 8-43.28 1.7c-2 .07-3.49.32-3.45 3 .15 9.6-3.64 18.13-7.63 26.54-6.58 13.86-15.08 26.54-23.92 39l-2 2.89c-3.81 5.42-6.47 9.23-6.84 9.75-3.69 5.27-17.43 23-26.46 35.43-8.45 11.62-11.25 16.48-12.19 23.43a35.78 35.78 0 0 0-.1 9.22z"
      />
      <path
        fill="#FCFCFD"
        d="M180.388 90.46l2.265 1.082-29.775 62.257-2.264-1.083z"
      />
    </g>
  </svg>
);

export default NoPendingApprovalsLogo;
