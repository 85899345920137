import gql from 'graphql-tag';
import ProvideDetailsFormFragments from '../components/ProvideDetails/gql/ProvideDetailsFormFragments.graphql';
import CallsToActionFragments from '../components/CallsToAction/gql/CallsToActionFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment Details_getMyCampaigns on Campaign {
      id
      minGradeLevel
      maxGradeLevel
      ...ProviderDetailsForm_getMyCampaigns
      ...CallsToAction_getMyCampaigns
    }
    ${ProvideDetailsFormFragments.getMyCampaigns}
    ${CallsToActionFragments.getMyCampaigns}
  `,
};
