import React, { useState } from 'react';
import { get } from 'lodash';
import { css, cx } from 'react-emotion';
import { components } from '@peachjar/components';
import { colors } from '@peachjar/components';
import OrgSchoolCheckboxList from './OrgSchoolCheckboxList';
import OrgCheckboxListHeader from './OrgCheckboxListHeader';
import SchoolFinder from '../SchoolFinder';
import { School, MapVariables } from '../../types';
import SchoolFinderLegend from '../SchoolFinder/components/SchoolFinderLegend';
import { AudienceFilterType } from '../Filters/AudienceFilter';
import { GradeFilterType } from '../Filters/GradeFilter';
import { SCHOOL_SORT_BY_DISTANCE_VALUE } from './constants';

const { SpinnerLarge } = components.LoadingIndicators;
const { silver, dragon } = colors;

type Props = {
  schools: School[];
  totalHiddenSchools: number;
  totalNearbySchools: number;
  radius: number;
  center: { latitude: number; longitude: number };
  loading: boolean;
  error: any;
  areFiltersSetToDefault: boolean;
  areInitialMapBoundsSet: boolean;
  initialMapBounds: any;
  hasCommFreeSchoolSelectionLimitError: boolean;
  communityFreeSchoolSelectionLimit: number;
  setInitialMapBounds: (bounds: any) => void;
  zoom: number | null;
  selectedAudience: string;
  toggleCheckbox: (
    audienceListItem: School | any,
    setStateFn: (newCheckboxSelections: Record<any, any>) => void,
    checkboxSelections: School[]
  ) => School[];
  toggleSubsetOfCheckboxes: () => School[];
  checkboxSelections: School[];
  setCheckboxSelections: () => void;
  onSelectionUpdated: (checkboxSelections: School[]) => void;
  previousRedoSearchZoom: number | null;
  setPreviousRedoSearchZoom: (zoom: number | null) => void;
  setSearchValue: (value: string) => void;
  mapVariables: MapVariables;
  setMapVariables: (mapVariables: MapVariables) => void;
  setInputValue: (value: string) => void;
  setGradeFilter: (filter: AudienceFilterType) => void;
  setAudienceFilter: (filter: GradeFilterType) => void;
  map: any;
  setMap: (map: any) => void;
};

function sortSchoolsByDistance(schools: School[]) {
  return schools;
}

function sortSchoolsAlphabetically(schools: School[]) {
  return schools
    .map(s => s)
    .sort((school1: School, school2: School) => {
      return get(school1, 'name') > get(school2, 'name')
        ? 1
        : get(school1, 'name') < get(school2, 'name')
        ? -1
        : 0;
    });
}

function sortSchools(schools, schoolsSortStyle) {
  return schoolsSortStyle == SCHOOL_SORT_BY_DISTANCE_VALUE
    ? sortSchoolsByDistance(schools)
    : sortSchoolsAlphabetically(schools);
}

const OrgSchoolSelector = ({
  loading,
  error,
  areFiltersSetToDefault,
  areInitialMapBoundsSet,
  schools = [],
  totalHiddenSchools,
  totalNearbySchools,
  radius,
  zoom,
  center,
  initialMapBounds,
  setInitialMapBounds,
  mapVariables,
  setMapVariables,
  selectedAudience,
  toggleSubsetOfCheckboxes,
  toggleCheckbox,
  checkboxSelections,
  setCheckboxSelections,
  onSelectionUpdated,
  previousRedoSearchZoom,
  setPreviousRedoSearchZoom,
  setSearchValue,
  setInputValue,
  setGradeFilter,
  setAudienceFilter,
  map,
  setMap,
  hasCommFreeSchoolSelectionLimitError,
  communityFreeSchoolSelectionLimit,
}: Props) => {
  const [schoolsSortStyle, setSchoolsSortStyle] = useState(
    SCHOOL_SORT_BY_DISTANCE_VALUE
  );

  // Only show pin markers in school list
  const searchedSchools = schools.filter(school => school.isPin === 1);
  const schoolsSortedByHeaderDropdown = schoolsSortStyle
    ? sortSchools(searchedSchools, schoolsSortStyle)
    : searchedSchools;

  return (
    <div>
      <div
        className={cx(
          'layout-row layout-align-stretch-center',
          `${
            hasCommFreeSchoolSelectionLimitError ? styles.schoolLimitError : ''
          }`
        )}
      >
        {(loading || error) && (
          <div
            className={`flex-auto layout-row layout-align-center-center ${styles.silverBorder} ${styles.mapContainer}`}
          >
            {loading && !error && (
              <div>
                <SpinnerLarge color="jungle" />
                <div style={{ textAlign: 'center' }} className="mt-3">
                  Loading
                </div>
              </div>
            )}
            {error && (
              <div>Uh oh! We encountered an error. Please try again.</div>
            )}
          </div>
        )}
        {!loading && !error && (
          <>
            <div className="flex-50 layout-column">
              <OrgCheckboxListHeader
                schools={schoolsSortedByHeaderDropdown}
                toggleSubsetOfCheckboxes={toggleSubsetOfCheckboxes}
                setCheckboxSelections={setCheckboxSelections}
                checkboxSelections={checkboxSelections}
                onSelectionUpdated={onSelectionUpdated}
                onSchoolsSortStyleUpdate={setSchoolsSortStyle}
              />
              <OrgSchoolCheckboxList
                areFiltersSetToDefault={areFiltersSetToDefault}
                areInitialMapBoundsSet={areInitialMapBoundsSet}
                totalHiddenSchools={totalHiddenSchools}
                totalNearbySchools={totalNearbySchools}
                listItems={schoolsSortedByHeaderDropdown}
                toggleCheckbox={toggleCheckbox}
                setCheckboxSelections={setCheckboxSelections}
                checkboxSelections={checkboxSelections}
                onSelectionUpdated={onSelectionUpdated}
                setGradeFilter={setGradeFilter}
                setAudienceFilter={setAudienceFilter}
                setMapVariables={setMapVariables}
                setSearchValue={setSearchValue}
                setInputValue={setInputValue}
                setPreviousRedoSearchZoom={setPreviousRedoSearchZoom}
                map={map}
              />
            </div>
            <div
              className={`flex-50 ${styles.silverBorder} ${styles.mapContainer}`}
            >
              <SchoolFinder
                schools={schools}
                radius={radius}
                zoom={zoom}
                center={center}
                mapVariables={mapVariables}
                areInitialMapBoundsSet={areInitialMapBoundsSet}
                initialMapBounds={initialMapBounds}
                setInitialMapBounds={setInitialMapBounds}
                setMapVariables={setMapVariables}
                checkboxSelections={checkboxSelections}
                previousRedoSearchZoom={previousRedoSearchZoom}
                setPreviousRedoSearchZoom={setPreviousRedoSearchZoom}
                setSearchValue={setSearchValue}
                setInputValue={setInputValue}
                map={map}
                setMap={setMap}
              />
            </div>
          </>
        )}
      </div>

      <div className="layout-row layout-align-end">
        {hasCommFreeSchoolSelectionLimitError && (
          <div className={styles.schoolLimitErrorText}>
            Flyers being distributed through the Community Free Program are
            limited to {communityFreeSchoolSelectionLimit} or fewer schools.
          </div>
        )}
        <SchoolFinderLegend />
      </div>
    </div>
  );
};

const styles = {
  schoolsWithGradeRange: css`
    padding-bottom: 1rem;
  `,
  silverBorder: css`
    border: 1px solid ${silver};
  `,
  mapContainer: css`
    height: 600px;
    min-height: 600px;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  `,
  schoolLimitError: css`
    border: 1px solid ${dragon};
    border-radius: 4px;
  `,
  schoolLimitErrorText: css`
    color: ${dragon};
    font-size: 13px;
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    width: 82%;
    margin-top: 5px;
  `,
};

export default OrgSchoolSelector;
