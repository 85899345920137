
import React from 'react';
import { css } from 'emotion';
import { connect } from 'react-redux';
import config from '../config';
import { colors, components } from '@peachjar/components';
import sadTurtleSVG from '../assets/sad_turtle.svg';
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons';
import { cleanMessagesErrors, ErrorMessage } from '../_app/errors/erros.duck';

const logoLinkUrl =
  config.DASHBOARD_URL || 'https://www.peachjar.com'

type ReduxDispatchProps = {
  cleanGraphqlErrors: () => void,  
};

type ReduxStateProps = {
  graphqlError: ErrorMessage
};
  
type Props = ReduxDispatchProps & ReduxStateProps & {};


const { Brandbar: PJBrandbar } = components;

const ErrorBoundaryPage = ({ cleanGraphqlErrors, graphqlError }: Props) => {

  React.useEffect(()=> {    
    document.querySelector('#root').style.backgroundColor = '#FFF'
  },[]);

  return (
  <>
    <PJBrandbar
      logoURI={logoLinkUrl}
      className={cn.brandbar}
      dark={true}
    />
    
    <div className={cn.content}>
      <div>
        <img src={sadTurtleSVG} width="541" height="455" />
        <p className={cn.text}>
          Uh oh! We seem to have stumbled...
        </p>
        <p className={cn.description}>
          Refresh the page now or try again later.
        </p>
        <ButtonSecondarySmall onClick={() => {
            window.location.reload();
            if(graphqlError.hasError) cleanGraphqlErrors();
          }}>
          Refresh
        </ButtonSecondarySmall>
        {graphqlError.hasError && (
          <div className={cn.boxErrorWrapper}>            
            <div className={cn.boxError}>
              {graphqlError.operationName && (
                <p> 
                  Operation Name: { graphqlError.operationName}
                </p>
              )}                           
              {graphqlError.messages.map(message => 
                  <p>{message}</p>
              )}              
            </div>
          </div>
        )}
      </div>
    </div>
  </>
  );
}

const cn = {
  brandbar: css`
      z-index: 50;
  `,
  content: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    padding-top: 50px;    
    overflow: auto;    
    height: 100vh;
    overflow-y: scroll;
  `,
  text: css`
   font-size: 25px;
   font-weight: bold;
   line-height: 32px;
   color: ${colors.prussian};
   padding-top: 40px;
   margin-bottom: 8px;
 `,
  description: css`
    font-size: 16px;  
    line-height: 24px;
    color: ${colors.prussian};    
  `,
  boxErrorWrapper: css`    
   display: flex;
   justify-content: center;
   margin-bottom: 80px;
   font-size:10px;
   padding-top: 30px;
   color: #707070;
   & p { 
     margin: 0px;
   }
  `,
  boxError: css`
    margin-top: 20px;
    padding-bottom: 100px;
    width: 80%;
    padding: 16px;    
    text-align: left;
    background-color: #F5F5F5;
    border-radius: 5px;
  `,

}

const mapStateToProps = (state): ReduxStateProps => ({
  graphqlError: state.errors
});

const mapDispatchToProps = (dispatch): ReduxDispatchProps => ({
  cleanGraphqlErrors: () => {
    dispatch(cleanMessagesErrors());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBoundaryPage);