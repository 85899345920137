import gql from 'graphql-tag';
import CAMPAIGN_FRAGMENT from './CampaignFragment.graphql';

/* eslint-disable */
const SAVE_CAMPAIGN_MUTATION = gql`
  mutation saveCampaign(
    $campaignId: String!
    $campaignPrepare: PrepareInput
    $campaignDetails: DetailsInput
    $campaignDeliverySettings: DeliverySettingsInput
    $campaignSummary: SummaryInput
  ) {
    saveCampaign(
      campaignId: $campaignId
      campaignPrepare: $campaignPrepare
      campaignDetails: $campaignDetails
      campaignDeliverySettings: $campaignDeliverySettings
      campaignSummary: $campaignSummary
    ) {
      title
      eventLocation
      ...fragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`;

export default SAVE_CAMPAIGN_MUTATION;
