import React, { useState, useEffect } from 'react';
import { css } from 'react-emotion';
import { elements, InfoTooltip, colors } from '@peachjar/components';
import FormHelperText from '@material-ui/core/FormHelperText';
import { differenceInCalendarDays, addDays, subDays } from 'date-fns';
import CampaignStartDate from './CampaignStartDate';
import PostExpirationComponent from './PostExpirationDate';
import Distributions from './Distributions';
import withValidation from '../../../../shared/components/withValidation';
import { WarnInlineNotification } from '../../../../../_app/components/InlineNotification';
import { tooltipCopy } from '../constants';
import { DATE_ERROR_MESSAGE } from '../../../../shared/constants';
import localDateToMidnightUTC from '../../../../shared/components/localDateToMidnightUTC';
import utcDateToMidnightLocal from '../../../../shared/components/utcDateToMidnightLocal';

const { dragon } = colors;
const { Paragraph, Headline2, Note } = elements.typography;

const DISTRIBUTION_TARGET_DATE_WARNING =
  'Approvals may take up to a week to process. We recommend you pick a Distribution Target Date that is more than 7 days away.';

export const getPostExpirationDateRange = (
  distributions,
  campaignStart,
  today
) => {
  let minDate;
  let maxDate;

  const localCampaignStart = utcDateToMidnightLocal(campaignStart);

  // Minus 1 to include today as today. This value is added below.
  const distributionInDays = distributions * 30 - 1;
  const defaultDate = campaignStart
    ? addDays(localCampaignStart, distributionInDays)
    : addDays(today, distributionInDays);

  if (distributions === 1) {
    minDate = addDays(localCampaignStart, 1);
    maxDate = addDays(localCampaignStart, 29);
  } else {
    minDate = subDays(defaultDate, 29);
    maxDate = addDays(localCampaignStart, distributionInDays);
  }

  return { min: minDate, max: maxDate };
};

const getToday = () => localDateToMidnightUTC(new Date());

const SetDistributions = ({
  setDeliveryCache,
  campaignStartDate,
  numberOfDistributions,
  postExpirationDate,
  performValidation,
  shouldPerformDisabledClickValidation,
  today = getToday(),
  isOrg,
}) => {
  const [targetDistributionError, setTargetDistributionError] = useState(false);
  const [postExpirationError, setPostExpirationError] = useState(false);
  const [
    targetDistributionMinDateError,
    setTargetDistributionMinDateError,
  ] = useState(false);
  const [postExpirationMinDateError, setPostExpirationMinDateError] = useState(
    false
  );
  const [showOrgDateWarning, setShowOrgDateWarning] = useState(false);
  const [myCampaignStartDate, setMyCampaignStartDate] = useState(
    campaignStartDate
  );
  const [postExpirationDateRange, setPostExpirationDateRange] = useState({
    min: null,
    max: null,
  });

  useEffect(() => {
    // Sang's code - determine if mirrored local value is needed
    if (myCampaignStartDate !== null && campaignStartDate === null) {
      setMyCampaignStartDate(null);
    }

    if (myCampaignStartDate === null && campaignStartDate !== null) {
      setMyCampaignStartDate(campaignStartDate);
    }

    // Show warning message for org users if distribution target date is within 6 days
    setShowOrgDateWarning(
      isOrg && differenceInCalendarDays(new Date(campaignStartDate), today) < 6
    );

    performValidation({
      target: {
        value: {
          campaignStartDate,
          numberOfDistributions,
          postExpirationDate,
        },
      },
    });
  });

  useEffect(() => {
    // Recalculate min and max dates for postExpirationDate
    setPostExpirationDateRange(
      getPostExpirationDateRange(
        numberOfDistributions,
        campaignStartDate,
        today
      )
    );
  }, [numberOfDistributions, campaignStartDate]);

  useEffect(() => {
    if (shouldPerformDisabledClickValidation) {
      setTargetDistributionError(campaignStartDate === null);
      setPostExpirationError(postExpirationDate === null);
    }

    if (campaignStartDate) {
      // Campaign date (in UTC) cannot be before today (in UTC)
      setTargetDistributionError(campaignStartDate === null);
      setTargetDistributionMinDateError(
        campaignStartDate < localDateToMidnightUTC(new Date())
      );
    }

    if (postExpirationDate && campaignStartDate) {
      setPostExpirationError(postExpirationDate === null);
      setPostExpirationMinDateError(postExpirationDate < campaignStartDate);
    }

    if (postExpirationDate === null) {
      setPostExpirationMinDateError(false);
    }
  }, [
    shouldPerformDisabledClickValidation,
    campaignStartDate,
    postExpirationDate,
  ]);

  return (
    <>
      <Headline2
        className={css`
          line-height: 1;
        `}
      >
        Set Distribution(s)
      </Headline2>
      <div>
        <strong>
          <Paragraph>
            Each distribution results in your flyer being emailed to families
            and posted to the schools website flyerboard.
          </Paragraph>
        </strong>
      </div>
      <div
        className={css`
          width: 50%;
        `}
      >
        <div className="layout-row layout-align-start-end mt-3">
          <div className="flex-auto">
            <CampaignStartDate
              setDeliveryCache={setDeliveryCache}
              campaignStartDate={myCampaignStartDate}
              numberOfDistributions={numberOfDistributions}
              error={targetDistributionError || targetDistributionMinDateError}
              onClose={() =>
                setTargetDistributionError(myCampaignStartDate === null)
              }
              onChange={date => {
                setMyCampaignStartDate(date);
                setTargetDistributionError(date === null);
              }}
            />
          </div>
          <div className="flex-none">
            <InfoTooltip
              content={
                isOrg
                  ? tooltipCopy.org_distributionTargetDate
                  : tooltipCopy.sod_distributionTargetDate
              }
            />
          </div>
        </div>
        {(targetDistributionError || targetDistributionMinDateError) && (
          <FormHelperText>
            <Note className={styles.errorText}>
              {targetDistributionMinDateError
                ? DATE_ERROR_MESSAGE
                : 'You missed this field.'}
            </Note>
          </FormHelperText>
        )}
        {showOrgDateWarning && !!campaignStartDate && (
          <div style={{ paddingTop: '8px' }}>
            <WarnInlineNotification
              messageLabel="Warning"
              message={DISTRIBUTION_TARGET_DATE_WARNING}
              dataTestId="orgInlineWarning"
            />
          </div>
        )}
        <div className={showOrgDateWarning ? 'mt-4' : styles.mt40}>
          <Paragraph>How many monthly distributions do you want?</Paragraph>
        </div>

        <div className="mt-3">
          <Distributions
            setDeliveryCache={setDeliveryCache}
            campaignStartDate={campaignStartDate}
            numberOfDistributions={numberOfDistributions}
            maxDistributions={12}
          />
        </div>

        {!!campaignStartDate && (
          <div className="layout-row layout-align-start-end mt-4">
            <div className="flex-auto">
              <PostExpirationComponent
                setDeliveryCache={setDeliveryCache}
                postExpirationDate={postExpirationDate}
                error={postExpirationError || postExpirationMinDateError}
                onChange={date => {
                  setDeliveryCache({
                    variables: { name: 'postExpirationDate', value: date },
                  });
                }}
                minDate={postExpirationDateRange.min}
                maxDate={postExpirationDateRange.max}
              />
            </div>
            <div className="flex-none">
              <InfoTooltip content={tooltipCopy.postExpirationDate} />
            </div>
          </div>
        )}
        {!!campaignStartDate &&
          (postExpirationError || postExpirationMinDateError) && (
            <FormHelperText>
              <Note className={styles.errorText}>
                {postExpirationMinDateError
                  ? DATE_ERROR_MESSAGE
                  : 'You missed this field.'}
              </Note>
            </FormHelperText>
          )}
      </div>
    </>
  );
};

const validate = ({
  campaignStartDate,
  numberOfDistributions,
  postExpirationDate,
}) => {
  const todayUTC = localDateToMidnightUTC(new Date());

  return (
    numberOfDistributions &&
    campaignStartDate !== null &&
    campaignStartDate >= todayUTC &&
    postExpirationDate !== null &&
    postExpirationDate > campaignStartDate
  );
};

const validateOnMount = ({
  campaignStartDate,
  numberOfDistributions,
  postExpirationDate,
}) => {
  if (campaignStartDate && numberOfDistributions) {
    validate({ campaignStartDate, numberOfDistributions, postExpirationDate });
  }
};

const styles = {
  errorText: css`
    color: ${dragon};
  `,
  mt40: css`
    margin-top: 40px;
  `,
};

export default withValidation(SetDistributions, validate, validateOnMount);

export { SetDistributions };
