import {
  ctaPrimaryKey,
  ctaPrimaryCTADataKey,
  ctaSecondaryKey,
  ctaSecondaryCTADataKey,
} from '../Create-Campaign/Details/components/CallsToAction/CallsToAction';

export function purgeCTAMemory(campaignId: string): void {
  localStorage.removeItem(`${campaignId}-${ctaPrimaryKey}`);
  localStorage.removeItem(`${campaignId}-${ctaPrimaryCTADataKey}`);
  localStorage.removeItem(`${campaignId}-${ctaSecondaryKey}`);
  localStorage.removeItem(`${campaignId}-${ctaSecondaryCTADataKey}`);
}

export default purgeCTAMemory;
