import React, { useContext } from 'react';
import { connect } from 'react-redux';
import Slider from '@material-ui/lab/Slider';
import { addDays } from 'date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import { colors, elements } from '@peachjar/components';
import { useDealsContext } from '../../../../../_app/context/DealsContext';
import {
  InputLabel,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import get from 'lodash/get';
import { COMMUNITY_FREE_TYPE, STANDARD_TYPE } from '../../../../campaigns.duck';

const { slate, leaf } = colors;
const { Note } = elements.typography;

const sliderTheme = createMuiTheme({
  overrides: {
    MuiSlider: {
      track: { backgroundColor: leaf },
      thumb: { backgroundColor: leaf },
    },
  },
});

export const Distributions = ({
  setDeliveryCache,
  numberOfDistributions,
  maxDistributions,
  isCommunityFree,
}) => {
  // NOTE: There is an event propigation bug in Material UI Lab Slider component. Ternary in onChange ensures a value not greater than the max distribution is selected.
  const numberOfDistributionsValue =
    numberOfDistributions <= maxDistributions
      ? numberOfDistributions
      : maxDistributions;

  const { setNumberOfDistributions } = useDealsContext();
  const distributionDisclaimerText = isCommunityFree
    ? 'Flyers being distributed through the Community Free program are limited to 1 monthly distribution.'
    : 'All distributions are 30 days apart.';

  return (
    <MuiThemeProvider theme={sliderTheme}>
      <InputLabel style={{ color: slate, fontFamily: 'Proxima Nova' }}>
        Distributions: {numberOfDistributions}
      </InputLabel>
      <div className="mt-3" style={{ maxWidth: '435px' }}>
        <Slider
          data-testid="distributionSlider"
          min={1}
          max={12}
          step={1}
          value={numberOfDistributionsValue}
          disabled={isCommunityFree}
          onChange={(event, val) => {
            // Clear post expiration date after number of distribution update
            setDeliveryCache({
              variables: {
                name: 'postExpirationDate',
                value: null,
              },
            });
            setDeliveryCache({
              variables: {
                name: 'numberOfDistributions',
                value: val,
              },
            });
            setNumberOfDistributions(val);
          }}
        />
      </div>

      <div className="pt-2">
        <FormHelperText>
          <Note style={{ color: slate }}>{distributionDisclaimerText}</Note>
        </FormHelperText>
      </div>
    </MuiThemeProvider>
  );
};

export default connect(state => {
  const campaignType = get(
    state,
    'campaigns.communityFree.type',
    STANDARD_TYPE
  );
  return {
    isCommunityFree: campaignType === COMMUNITY_FREE_TYPE,
  };
})(Distributions);
