import { Quote, StripePurchaseAuthorization, StripeCheckoutDetails, LineItem } from '../CommonModels';

import ApolloClient from "apollo-client";
import CreditPurchasingService from "../CreditPurchasingService";

import gql from "graphql-tag";

const GetCreditPurchasingQuoteQuery = gql`
  query GetCreditPurchasingQuoteQuery($details: CreditPurchaseDetailsInput!){
    getCreditPurchasingQuote(creditPurchaseDetails: $details) {
      hasError
      lineItems {
        lineItemId
        numberOfCredits
        error
        price
        msrpValue
        pricePerCredits
        deal {
          id
          name
          description
          dealPercent
          imageUrls
        }
      }
      total {
        numberOfCredits
        price
        msrpValue
      }
    }
  }
`;

type GetCreditPurchasingQuoteResponse = {
  getCreditPurchasingQuote: Quote,
};

const CheckoutWithStripeMutation = gql`
  mutation CheckoutWithStripeMutation($input: StripeCheckoutInput!){
    checkoutWithStripe(checkOut: $input) {
      session
    }
  }
`;

type CheckoutWithStripeResponse = {
  checkoutWithStripe: {
    session: string,
  },
};

export default class GqlCreditPurchasingService implements CreditPurchasingService {

  constructor(
      protected client: ApolloClient<any>
  ) {}

  async getCreditPurchasingQuote(lineItems: LineItem[], promotionCode?: string): Promise<Quote> {
    const result = await this.client.query<GetCreditPurchasingQuoteResponse>({
      query: GetCreditPurchasingQuoteQuery,
      variables: {
        details: {
          lineItems,
          promotionCode,
        },
      },
    });
    const { data: { getCreditPurchasingQuote } } = result;
    return getCreditPurchasingQuote;
  }

  async checkoutWithStripe(authorization: StripePurchaseAuthorization): Promise<StripeCheckoutDetails> {
    const result = await this.client.mutate<CheckoutWithStripeResponse>({
      mutation: CheckoutWithStripeMutation,
      variables: {
        input: authorization,
      },
    });
    const { data: { checkoutWithStripe } } = result;
    return checkoutWithStripe;
  }
}
