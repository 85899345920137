import ApolloClient from 'apollo-boost';
import config from '../../config';

const { BFF_ACCOUNTS_GRAPHQL_URI } = config;

export const accountsBffClient = new ApolloClient({
  uri: BFF_ACCOUNTS_GRAPHQL_URI,
  credentials: 'include',
});

export default accountsBffClient;
