import { css } from 'emotion';
import { colors } from '@peachjar/components';

const PromoCodeInputStyles = {
  inputWidth: css`
    width: 70% !important;
  `,
  promoCodeExpansionPanel: css``,
  promoCodeForm: css`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;
  `,
  promoCodeCTA: css`
    margin-left: 16px !important;
  `,
  promoLink: css`
    color: ${colors.jungle};
    cursor: pointer;

    &.hover {
      color: #c5e8c9;
    }
  `,
  imageIcon: css`
    height: 12px;
    width: 12px;
    cursor: pointer;
  `,
  imageIconPlacement: css`
    position: absolute;
    left: -21px;
    top: 18px;
  `,
  noPointer: css`
    cursor: default !important;
  `,
};

export default PromoCodeInputStyles;
