import React from 'react';
import { css } from 'react-emotion';
import { elements } from '@peachjar/components';
import UhOhTurtleSvg from './UhOhTurtle.svg';

const { Paragraph, Note } = elements.typography;

const styles = {
  block: css`
    display: block;
  `,
  paragraphMarginBottom: css`
    margin-bottom: 1.25rem;
  `,
  muted: css`
    /* This is the 'iron' color. It needs to be added to our component library. It's the new muted color */
    color: #969696;
  `,
  textAlignCenter: css`
    text-align: center;
  `,
  maxWidth: css`
    max-width: 300px;
  `,
};

const GenericNoSchoolsMessage = () => (
  <div className="flex-auto layout-column layout-align-start-center pt-0 p-3">
    <UhOhTurtleSvg />

    <div className={`${styles.textAlignCenter}`}>
      <Paragraph className={`${styles.block} ${styles.paragraphMarginBottom}`}>
        Sorry, no schools found in this area.
      </Paragraph>

      <Note className={`${styles.block} ${styles.muted} ${styles.maxWidth}`}>
        Try searching a larger area, an alternative location, or a different
        spelling.
      </Note>
    </div>
  </div>
);

export default GenericNoSchoolsMessage;
