import { createSelector } from 'reselect';
import {
  ApprovalForMutation,
  ApprovalsState,
  StagedFlyer,
  StagedFlyersById,
} from './approvals.duck';

// GETTERS
const getApprovalsState = (state): ApprovalsState => state.flyerApprovals;

export const getStagedFlyers = createSelector(
  getApprovalsState,
  approvalsState => approvalsState.flyers
);

// GETTERS (computed)
export const getNumberOfStagedFlyers = createSelector(
  getStagedFlyers,
  (stagedFlyers: StagedFlyersById): number => Object.keys(stagedFlyers).length
);

// Convert shape of flyers object in redux state to shape required by approvals mutation
export const getStagedFlyerApprovals = createSelector(
  getStagedFlyers,
  (stagedFlyers: StagedFlyersById): Array<ApprovalForMutation> =>
      Object.values(stagedFlyers).reduce(
      (allFlyerApprovalStatuses, stagedFlyer: StagedFlyer) => {
        const {
          approvals,
          denialReason,
          denialReasonExplanation,
        } = stagedFlyer;

        // Convert shape of approvals array on flyer in redux state to shape required by approvals mutation
        const approvalsArrayForOneFlyer = Object.entries(approvals).map(
          ([approvalId, status]) => ({
            approvalId,
            status,
          })
        );

        // Add denialReason and denialReasonExplanation fields to denied approvals
        const approvalsIncludingDenialReasonForOneFlyer = approvalsArrayForOneFlyer.map(
          (approval: ApprovalForMutation): ApprovalForMutation =>
            approval.status === 'denied'
              ? {
                  ...approval,
                  denialReason,
                  denialReasonExplanation,
                }
              : approval
        );

        return [
          ...allFlyerApprovalStatuses,
          ...approvalsIncludingDenialReasonForOneFlyer,
        ];
      },
      []
    )
);
