
import * as React from 'react';
import gql from 'graphql-tag';
import { css } from 'emotion';
import idx from 'idx';
import Grid from '@material-ui/core/Grid';
import { elements } from '@peachjar/components';
import SubappHeader from '../_app/components/SubappHeader';
import DashboardCard from '../components/DashboardCard';
import getHighestSodLevel from '../helpers/getHighestSodLevel';
import { Approvals_sodUser } from './__generated__/Approvals_sodUser';
import approvalHistorySVG from '../assets/icon_approvalHistory.svg';
import pendingApprovalsSVG from '../assets/icon_pendingApprovals.svg';
import { APP_ROUTES } from '../_app/constants';
import { RoleCheckWithRedirect } from '../_app/components/RoleCheck';
import { approvalsAllowedRoles } from '../_app/RolesEnum';

const { Paragraph } = elements.typography;

type Props = {
   sodUser: Approvals_sodUser,
};

const approvalsRoleConfig = {
  // show pending approvals and all approvals cards for APPROVERS
  APPROVER: [
    {
      link: APP_ROUTES.approvals.pending,
      icon: pendingApprovalsSVG,
      headline: 'Pending Approvals',
    },
    {
      link: APP_ROUTES.approvals.history.allApprovals,
      icon: approvalHistorySVG,
      headline: 'Approval History',
    },
  ],
  // show approval history overview card for ADMINS
  ADMIN: [
    {
      link: APP_ROUTES.approvals.history.allApprovals,
      icon: approvalHistorySVG,
      headline: 'Approval History',
    },
  ],
  UPLOADER: [
    {
      link: '',
      icon: '',
      headline: '',
    },
  ],
};

const sectionHeadlines = {
  APPROVER: 'View and take action on your Pending Approvals or review your Approval History.',
  ADMIN: 'Review your Approval History.'
};

const getDashboardItems = role => {
  return approvalsRoleConfig[role].map((o, idx) => {
    return (
      <Grid item xs={12} sm={4} key={idx}>
        <div className={cn.card}>
          <DashboardCard
            link={o.link}
            icon={o.icon}
            headline={o.headline}
          />
        </div>
      </Grid>
    );
  });

}

const Approvals = ({ sodUser }: Props) => {
  const role = getHighestSodLevel(sodUser);

  return (
    <RoleCheckWithRedirect roles={approvalsAllowedRoles}>
      <div className={`view-approvals ${cn.root}`}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <SubappHeader className={cn.header}>
              Approvals
            </SubappHeader>
            <Paragraph className={cn.subheadline} data-testid="text-approvals-subheadline">
              { sectionHeadlines[role] }
            </Paragraph>
          </Grid>
          { getDashboardItems(role) }
        </Grid>
      </div>
    </RoleCheckWithRedirect>
  );
};

//classes
const cn = {
  root: css`
    flex-grow: 1;
  `,
  card: css`
    margin-top: 1rem;
  `,
  header: css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding-bottom: 0.5rem;
  `,
  subheader: css`
    font-weight: normal;
  `,
  headerRow: css`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  `,
  subheadline: css`
    font-size: 16px;
    display: block;
    padding-top: 0.5rem;
  `,
};

export default Approvals;
