/* global window */

import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { css } from 'react-emotion';
import MemoizedMap from './components/MemoizedMap';

const SchoolFinder = ({
  initialMapBounds,
  areInitialMapBoundsSet,
  setInitialMapBounds,
  radius,
  zoom,
  center,
  mapVariables,
  setMapVariables,
  schools,
  checkboxSelections,
  setPreviousRedoSearchZoom,
  previousRedoSearchZoom,
  setSearchValue,
  setInputValue,
  map,
  setMap,
}) => {
  const [schoolList, setSchoolList] = useState([]);
  const [showRedoSearch, setShowRedoSearch] = useState(false);
  const [currentCenter, setCurrentCenter] = useState(null);
  const [shouldUpdateCurrentBounds, setShouldUpdateCurrentBounds] = useState(
    true
  );

  useEffect(() => {
    if (areInitialMapBoundsSet) {
      addTypesToSchools();
    }
  }, [schools, areInitialMapBoundsSet]);

  // TODO: Cary - potential memory leak, setting state on unmounted component
  // set to back to undefined on unmount
  useEffect(() => () => setInitialMapBounds(null), []);

  const addTypesToSchools = () => {
    const schoolsWithTypes = schools.map(school => ({
      ...school,
      type: checkboxSelections.find(
        selection => selection.schoolId === school.schoolId
      )
        ? 'selected'
        : 'default',
    }));

    setSchoolList(schoolsWithTypes);
  };

  return (
    <div
      style={{
        overflow: 'hidden',
        height: '100%',
        width: '100%',
      }}
    >
      <MemoizedMap
        map={map}
        schoolList={schoolList}
        setMap={setMap}
        mapVariables={mapVariables}
        setMapVariables={setMapVariables}
        center={center}
        currentCenter={currentCenter}
        setCurrentCenter={setCurrentCenter}
        zoom={zoom}
        initialMapBounds={initialMapBounds}
        shouldUpdateCurrentBounds={shouldUpdateCurrentBounds}
        setShouldUpdateCurrentBounds={setShouldUpdateCurrentBounds}
        areInitialMapBoundsSet={areInitialMapBoundsSet}
        setInitialMapBounds={setInitialMapBounds}
        previousRedoSearchZoom={previousRedoSearchZoom}
        setPreviousRedoSearchZoom={setPreviousRedoSearchZoom}
        setSearchValue={setSearchValue}
        setInputValue={setInputValue}
        showRedoSearch={showRedoSearch}
        setShowRedoSearch={setShowRedoSearch}
      />
    </div>
  );
};

export default SchoolFinder;
