import { prepareDefaults } from '../../campaigns/Create-Campaign/Prepare/gql/Prepare.graphql';
import { detailsDefaults } from '../../campaigns/Create-Campaign/Details/gql/Details.graphql';
import { deliveryDefaults } from '../../campaigns/Create-Campaign/Delivery/gql/Delivery.graphql';
import { summaryDefaults } from '../../campaigns/Create-Campaign/Summary/gql/Summary.graphql';

const defaults = {
  ...prepareDefaults,
  ...detailsDefaults,
  ...deliveryDefaults,
  ...summaryDefaults,
};

export default defaults;
