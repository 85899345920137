
import * as React from 'react';
import { css } from 'react-emotion';
import { colors, elements, svg } from '@peachjar/components';

const { Note } = elements.typography;
const { MaterialIcon } = elements;
const { CircleCheck } = svg;

type StatusIconProps = {
   status?: string,
};

const StatusIcon = ({ status }: StatusIconProps) => {
  if (status === 'approved') {
    return <CircleCheck size="16" fill={colors.jungle} />;
  }
  if (status === 'pending') {
    return <Note>&ndash; &ndash;</Note>;
  }
  if (status === 'hold') {
    return (
      <MaterialIcon
        color={colors.peach}
        name="remove_circle_outline"
        className={classNames.zeroLineHeight}
      />
    );
  }
  return (
    <MaterialIcon
      color={colors.dragon}
      name="highlight_off"
      className={classNames.zeroLineHeight}
    />
  );
};

const classNames = {
  zeroLineHeight: css`
    line-height: 0;
  `,
};

export default StatusIcon;
