import React from 'react';
import { css } from 'emotion';
import colors from '@peachjar/ui/dist/lib/styles/colors';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { useDealsContext } from '../../../_app/context/DealsContext';

const PostOnlyBanner = ({}) => {
  // @ts-ignore
  const { setSummaryTray } = useDealsContext();

  const handleViewPostOnlyDetails = () => setSummaryTray(true);

  return (
    <div className={styles.container}>
      <div className={styles.contentLeft}>
        <InfoIcon />
      </div>
      <div className={styles.contentRight}>
        <h5>Post Only Options</h5>
        <p>
          You have selected 1 district that provide a post only option for
          distributing flyers, which results in discounted pricing in leiu of
          sending in email.
        </p>
        <p className={styles.cta} onClick={handleViewPostOnlyDetails}>
          View Details
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: normal;
    background-color: ${colors.platinum};
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    padding: 20px;
    margin: 24px 0;
    border-radius: 3px;
    border: 1px solid ${colors.silver};
  `,
  contentLeft: css`
    width: 30px;
    text-align: center;
    margin-right: 15px;
  `,
  contentRight: css`
    h5 {
      font-size: 16px;
      font-weight: 600;
      font-family: inherit;
      color: ${colors.prussian};
      margin-bottom: 1px;
    }
    p {
      font-size: 13px;
      font-family: inherit;
      margin-bottom: 0;
      color: ${colors.prussian};
    }
  `,
  cta: css`
    color: ${colors.jungle} !important;
    font-size: 13px;
    margin-top: 2px;
    cursor: pointer;

    &:hover {
      color: ${colors.jungle};
      text-decoration: underline;
    }
  `,
};

export default PostOnlyBanner;
