import React from 'react';
import { css } from 'emotion';
import colors from '@peachjar/ui/dist/lib/styles/colors';
import Note from '@peachjar/ui/dist/lib/typography/Note'
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const muiStyles = theme => ({
  button: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#E9F6E9',
    },
  },
});

const tooltipText =
  "When the Post Only option is selected, an email notification will not be sent but your flyer will still be posted on the school's flyer board for up to 4 weeks.";

type Props = {
  classes: any;
};

const PostOnlyInput: React.FunctionComponent<Props> = ({
  classes,
}) => {
  return (
    <>
      <div className={cn.districtOptions}>
        <Note>Post Only</Note>
        <span className="tooltipContainer">
          <Tooltip title={tooltipText} placement="right" className={cn.ripple}>
            <IconButton
              className={`${classes.button}`}
              arial-label="Clear Item"
            >
              <InfoIcon
                fontSize="small"
                style={{
                  color: `${colors.prussian}`,
                  fontSize: '16px',
                }}
              />
            </IconButton>
          </Tooltip>
        </span>
      </div>
    </>
  );
};

const cn = {
  ripple: css`
    background-position: center;
    transition: background 0.8s;

    &:hover {
      padding: 12px;
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
      border-radius: 20px;
    }

    &:active {
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
    }
  `,
  districtOptions: css`
    display: flex;
    align-items: center;
    & .tooltipContainer {
      margin-right: -13px;
    }

    & .MuiFormControlLabel-root {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }

    & .MuiCheckbox-root {
      padding: 0 4px 0 0 !important;
    }

    & .MuiFormControlLabel-label {
      font-size: 13px !important;
      padding-top: 2px !important;
    }

    & .MuiIconButton-root[aria-label='tooltip'] {
      /* margin-left: -9px !important; */
    }
  `,
};

export default withStyles(muiStyles)(PostOnlyInput);
