import React from 'react';
import HelpOrgs from './Help';
import HelpStaff from './HelpStaff';
import useAccountContext, {
  AccountContextApi,
} from '../hooks/useAccountContext';

type Props = {
  [k: string]: any;
};

const HelpContainer: React.FunctionComponent<Props> = ({ ...rest }) => {
  const {
    orgUploaderFlag,
    uploaderLevel,
  } = useAccountContext() as AccountContextApi;
  const MappedComponent =
    uploaderLevel === 'org' && orgUploaderFlag ? HelpOrgs : HelpStaff;

  return <MappedComponent {...rest} />;
};

export default HelpContainer;
