import React from 'react';
import { Mutation } from 'react-apollo';
import SpinningSubmitButton from './SpinningSubmitButton';

const MutationButton = ({
  mutation,
  variables,
  handleError,
  handleSuccess,
  update,
  refetchQueries,
  client,
  something,
  children,
  disabled,
  loading: moreLoading,
  ...rest
}) => (
  <Mutation
    mutation={mutation}
    variables={variables}
    onError={handleError}
    onCompleted={handleSuccess}
    update={update}
    refetchQueries={refetchQueries}
    awaitRefetchQueries={!!refetchQueries}
    client={client}
  >
    {(mutate, { loading, error }) => {
      const props = {
        loading: loading || moreLoading,
        error,
        handleClick: () => mutate(),
        disabled,
        ...rest,
      };

      return <SpinningSubmitButton {...props}>{children}</SpinningSubmitButton>;
    }}
  </Mutation>
);

export default MutationButton;
