import React from 'react';
import { css } from 'react-emotion';
import { get } from 'lodash';
import SchoolCheckboxList from './SchoolCheckboxList';
import DistrictCheckboxListHeader from './DistrictCheckboxListHeader';
import SchoolsWithGradeRange from './SchoolsWithGradeRange';

type Props = {
  districts: Object[];
  selectedAudience: string;
  toggleCheckbox: () => Object[];
  toggleCheckboxes: () => Object[];
  toggleAllCheckboxes: () => Object[];
  checkboxSelections: [Object];
  setCheckboxSelections: () => void;
  onSelectionUpdated: () => void;
  minGradeLevel: string;
  maxGradeLevel: string;
};

const styles = {
  schoolsWithGradeRange: css`
    padding-bottom: 1rem;
  `,
};

const SchoolSelector = ({
  isSingleCheckboxAltered,
  setIsSingleCheckboxAltered,
  uploaderLevel,
  districts,
  selectedAudience,
  toggleAllCheckboxes,
  toggleCheckboxes,
  toggleCheckbox,
  checkboxSelections,
  setCheckboxSelections,
  onSelectionUpdated,
}: Props) => {
  const isDistrictOrSchool =
    uploaderLevel === 'district' || uploaderLevel === 'school';
  const targetAudiences = get(districts, `[0].audiences.${selectedAudience}`);
  const district = get(districts, `[0]`);

  return (
    <>
      <DistrictCheckboxListHeader
        uploaderLevel={uploaderLevel}
        setIsSingleCheckboxAltered={setIsSingleCheckboxAltered}
        district={district}
        selectedAudience={selectedAudience}
        toggleAllCheckboxes={toggleAllCheckboxes}
        setCheckboxSelections={setCheckboxSelections}
        checkboxSelections={checkboxSelections}
        onSelectionUpdated={onSelectionUpdated}
      />
      <SchoolCheckboxList
        setIsSingleCheckboxAltered={setIsSingleCheckboxAltered}
        listItems={targetAudiences}
        toggleCheckbox={toggleCheckbox}
        setCheckboxSelections={setCheckboxSelections}
        checkboxSelections={checkboxSelections}
        onSelectionUpdated={onSelectionUpdated}
      />
    </>
  );
};

export default SchoolSelector;
