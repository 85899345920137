import generateSubappConfig from '../_app/generateSubappConfig';
import CreateCampaign from './Create-Campaign';
import { campaignsAllowedRoles } from '../_app/RolesEnum';
import RedirectCampaigns from '../_app/components/RedirectCampaigns';
import { APP_ROUTES } from '../_app/constants';

const getCampaignsSubappConfig = () =>
  generateSubappConfig({
    path: '/simple-submission',
    isLive: true,
    allowedRoles: campaignsAllowedRoles,
    navItemConfig: {
      label: 'Post & Notify',
    },
    appConfig: {
      Component: RedirectCampaigns,
      fragmentOnUser: {},
    },
  });

export default getCampaignsSubappConfig;
