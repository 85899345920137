import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { components, elements } from '@peachjar/components';
import { grades as gradeLabelValues, gradeOrdinal, ordinalGrades } from '../../../../../shared/constants';
import Popper from '../../../../../shared/components/Popper';
import SODSchoolFilter from './SODSchoolFilter';

import { get } from 'lodash';


const { Dropdown } = components;
const {
  typography: { Paragraph, Note },
} = elements;

type Props = {
  setCheckboxSelections: (args: Object) => void,
  isSingleCheckboxAltered: Boolean,
  setIsSingleCheckboxAltered: (args: Object) => void,
  classes: Object,
  districts: Array<Object>,
  selectedAudience: String,
  checkboxSelections: Array<Object>,
};

const SchoolListPopper = ({
  setCheckboxSelections,
  isSingleCheckboxAltered,
  setIsSingleCheckboxAltered,
  classes,
  districts,
  selectedAudience,
  checkboxSelections
}: Props) => {
  let min: number = 15;
  let max: number = 0;

  const schools: Array<Object> = get(districts, `0.audiences.${selectedAudience}`, []);
  const areAllSelected = schools.every((school) => {
    return checkboxSelections.find((selection) => {
      return school.schoolId === selection.schoolId;
    })
  });

  schools.forEach((s) => {
    if (s.lowestGradeOffered != 'N') {
      let low = gradeOrdinal[s.lowestGradeOffered]
      if (low < min) {
        min = low;
      }
    }
    if (s.highestGradeOffered != 'N') {
      let high = gradeOrdinal[s.highestGradeOffered]
      if (high > max) {
        max = high;
      }
    }
  })

  if (schools.length === 0) {
    min = gradeOrdinal['ECE'];
    max = gradeOrdinal['CE'];
  }

  let minStr: String = ordinalGrades[min];
  let maxStr: String = ordinalGrades[max];

  const [tempMinMax, setTempMinMax] = useState({min: "", max: ""})
  const [gradeMinMax, setGradeMinMax] = useState({min: areAllSelected ? minStr : '', max: areAllSelected ? maxStr : ''});
  const areCheckboxesAlteredOrGradeFiltersDefault: boolean = isSingleCheckboxAltered || (!gradeMinMax.min && !gradeMinMax.max);


  useEffect( () => {
    if (isSingleCheckboxAltered) {
      setGradeMinMax({min: '', max: ''})
    }
  }, [isSingleCheckboxAltered])

  let anchorOrigin: Object = {
    vertical: 'bottom',
    horizontal: 'left',
  };

  let transformOrigin: Object = {
    vertical: 'top',
    horizontal: 'left',
  };

  return (
    <Popper
    autoFocus={true}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    paperProps={{ 'style': { marginTop: '48px' } }}
    popperContent={togglePopper => (
      <SODSchoolFilter
        tempMinMax={tempMinMax}
        setTempMinMax={setTempMinMax}
        setCheckboxSelections={setCheckboxSelections}
        checkboxSelections={checkboxSelections}
        isSingleCheckboxAltered={isSingleCheckboxAltered}
        setIsSingleCheckboxAltered={setIsSingleCheckboxAltered}
        districts={districts}
        selectedAudience={selectedAudience}
        togglePopper={togglePopper}
        gradeMinMax={gradeMinMax}
        setGradeMinMax={setGradeMinMax}
        districtMin={minStr}
        districtMax={maxStr}
      />
    )} >
      {anchorElement => {
        const arrow = anchorElement ? (
          <KeyboardArrowUp />
        ) : (
          <KeyboardArrowDown />
        );
        return (
          <Button
            className={
              areCheckboxesAlteredOrGradeFiltersDefault
                ? classes.button
                : classes.buttonFiltered
            }
            variant="outlined"
          >
            {areCheckboxesAlteredOrGradeFiltersDefault
              ? 'Grades...'
              : `Grades ${gradeMinMax.min} \u2013 ${gradeMinMax.max}`}{' '}
            {arrow}
          </Button>
        );
      }}
    </Popper>
  )
}

const styles = {
};

const muiStyles = () => ({
  button: {
    textTransform: 'none',
    fontFamily: 'Proxima Nova',
  },
  buttonFiltered: {
    textTransform: 'none',
    fontFamily: 'Proxima Nova',
    color: '#ffffff',
    backgroundColor: '#576183',
    '&:hover': { backgroundColor: '#47506B' },
  },
});

export default withStyles(muiStyles)(SchoolListPopper);