import React from 'react';
import { components, elements } from '@peachjar/components';
import { css } from 'react-emotion';
import PdfPortraitSvg from '../../../../../../assets/PdfPortrait.svg';

const { ButtonSecondarySmall } = components.Buttons;
const { Label, Note } = elements.typography;

const styles = {
  listItem: css`
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  `,
  statusBox: css`
    min-height: 56px;
    padding: 16px 19px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
  `,
  guidelines: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
};

const FLYER_DESIGN_GUIDELINES_URL =
  'https://info.peachjar.com/peachjar-flyer-upload-requirements';

const ReviewFlyerGuidelines = () => (
  <>
    <div className={styles.guidelines}>
      <Label>Review Flyer Guidelines</Label>
      <ButtonSecondarySmall
        onClick={() => window.open(FLYER_DESIGN_GUIDELINES_URL, '_blank')}
      >
        Flyer Design Guidelines
      </ButtonSecondarySmall>
    </div>
    <div>
      <Note>
        We have created some simple guidelines on how to prepare your flyer file
        for upload.
      </Note>
    </div>

    <div className="layout-row layout-align-space-between-center">
      <ul className="mt-2 flex-none" style={{ listStyleType: 'disc' }}>
        <li className={styles.listItem}>
          <Note>PDF format required</Note>
        </li>
        <li className={styles.listItem}>
          <Note>8.5&quot; x 11&quot;</Note>
        </li>
        <li className={styles.listItem}>
          <Note>Portrait orientation preferred</Note>
        </li>
        <li className={styles.listItem}>
          <Note>1-4 pages in length</Note>
        </li>
        <li className={styles.listItem}>
          <Note>Under 50 MB in size</Note>
        </li>
        <li className={styles.listItem}>
          <Note>12pt minimum font size</Note>
        </li>
        <li className={styles.listItem}>
          <Note>Links embedded in the flyer PDF are not supported</Note>
        </li>
      </ul>

      <div className="mt-5 layout-row layout-align-center-center flex-auto">
        <PdfPortraitSvg />
      </div>
    </div>
  </>
);

export default ReviewFlyerGuidelines;
