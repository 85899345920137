import React from 'react';
import { css } from 'react-emotion';
import { elements, components } from '@peachjar/components';
import HidingTurtlePNG from '../../../../../../../../../../../assets/hiding_turtle.png';
import { SCHOOL_FINDER_AUDIENCE_FILTER_DEFAULTS } from '../../../Filters/constants';
import { GradeFilterType } from '../../../Filters/GradeFilter';
import { AudienceFilterType } from '../../../Filters/AudienceFilter';

const { Paragraph, Note } = elements.typography;
const { ButtonFlatSmall } = components.Buttons;

const styles = {
  block: css`
    display: block;
  `,
  marginBottom: css`
    && {
      margin-bottom: 0.75rem;
    }
  `,
  muted: css`
    /* This is the 'iron' color. It needs to be added to our component library. It's the new muted color */
    color: #969696;
  `,
  textAlignCenter: css`
    text-align: center;
  `,
  maxWidth: css`
    max-width: 300px;
  `,
  image: css`
    height: 225px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  `,
};

type Props = {
  totalHiddenSchools: number,
  gradeFilterDefault: GradeFilterType,
  setGradeFilter: (filter: AudienceFilterType) => void,
  setAudienceFilter: (filter: GradeFilterType) => void,
};

export const resetFilters = (
  setAudienceFilter,
  setGradeFilter,
  gradeFilterDefault
) => {
  setGradeFilter({ min: gradeFilterDefault.min, max: gradeFilterDefault.max });
  setAudienceFilter(SCHOOL_FINDER_AUDIENCE_FILTER_DEFAULTS);
};

const HiddenSchoolsMessage = ({
  totalHiddenSchools,
  gradeFilterDefault,
  setGradeFilter,
  setAudienceFilter,
}: Props) => (
  <div className="flex-auto layout-column layout-align-start-center pt-0 p-3">
    <img className={styles.image} src={HidingTurtlePNG} />

    <div className={`${styles.textAlignCenter}`}>
      <Paragraph className={`${styles.block} ${styles.marginBottom}`}>
        No schools found in this area,<br />
        but {totalHiddenSchools} are filtered from view.
      </Paragraph>

      <ButtonFlatSmall
        className={styles.marginBottom}
        onClick={() =>
          resetFilters(setAudienceFilter, setGradeFilter, gradeFilterDefault)
        }
      >
        Reset Filters
      </ButtonFlatSmall>

      <Note
        className={`${styles.block} ${styles.muted} ${styles.maxWidth}`}
      >
        Reset filters to view them.
      </Note>
    </div>
  </div>
);

export default HiddenSchoolsMessage;
