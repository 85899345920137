import gql from 'graphql-tag';
import MessageToApproverFragments from '../gql/MessageToApproverFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment Summary_getMyCampaigns on Campaign {
      legacyFlyerId
      ...MessageToApprover_getMyCampaigns
    }
    ${MessageToApproverFragments.getMyCampaigns}
  `,
};
