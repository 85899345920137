
import * as React from 'react';
import { css, cx } from 'emotion';
import gql from 'graphql-tag';
import idx from 'idx';
import { elements } from '@peachjar/components';

import { ApprovalsSidebarNavItemBadge_sodUser } from './__generated__/ApprovalsSidebarNavItemBadge_sodUser';

// const { Badge } = components;
const { Note } = elements.typography;

type BadgeProps = {
  children: string | number,
  color?: string,
  className ?: string,
};

export const Badge = ({ color, children, className }: BadgeProps) => (
  <Note className={cx(classNames.badge(color), className)}>{children}</Note>
);

const classNames = {
  badge: color => css`
    display: inline-block;
    padding: 0.125rem 0.25rem;
    background-color: ${color};
    border-radius: 10px;
    margin-left: 0.25rem;
    font-weight: normal;
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
    min-width: 1.5rem;
    text-align: center;
  `,
};

Badge.defaultProps = {
  color: '#c5e8c9', // Some variation on mint
  className: '',
};

type Props = {
  sodUser?: ApprovalsSidebarNavItemBadge_sodUser,
};

const ApprovalsSidebarNavItemBadge = ({ sodUser }: Props) => {
  // const requestsCount = (idx(sodUser, _ => _.requests) || []).length;
  // if you have access to multiple regions, show the total
  // does a user always have access to one region, or would this be an empty list?
  const regions = idx(sodUser, _ => _.approvalCenterRegions) || [];
  const totalRequestsCount = regions.reduce(
    (total, { pendingApprovalCount }) => total + pendingApprovalCount,
    0
  );
  return <Badge>{totalRequestsCount}</Badge>;
};

ApprovalsSidebarNavItemBadge.fragments = {
  sodUser: gql`
    fragment ApprovalsSidebarNavItemBadge_sodUser on SodUser {
      id
      requests: pendingFlyerApprovalRequests {
        fml: id
      }
      approvalCenterRegions {
        id
        pendingApprovalCount
      }
    }
  `,
};

export default ApprovalsSidebarNavItemBadge;
