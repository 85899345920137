import { css } from "emotion";

import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import SubappHeader from "../../_app/components/SubappHeader";
import PurchaseCredits from './PurchaseCredits';

export type Props = {};

export default class PurchaseCreditsPage extends Component<Props> {

  render() {
    return (
      <div className={`view-purchasing ${cn.viewPurchasing}`}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <SubappHeader className={cn.sectionHeadline}>
              Purchase Credits!!!!!
            </SubappHeader>
          </Grid>
          <Grid item xs={12}>
            <PurchaseCredits />
          </Grid>
        </Grid>
      </div>
    );
  }
}

// classes
const cn = {
  viewPurchasing: css`
    margin-bottom: 130px;
  `,
  sectionHeadline: css`
    padding-bottom: 0.6rem;
  `,
};
