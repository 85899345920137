import React from 'react';
import { css } from 'emotion';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import twitterIcon from '../assets/twitter-icon.svg';
import chevronRight from '../assets/icon-chevron-right.svg';

interface Props {
  shareUrl: string,
  title: string,
  classes: object,
}

const TwitterShareButton = ({ shareUrl, title, classes }: Props) => (
  <Button href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${title}&hashtags=peachjar`} className={classes.button} target="_blank">
    <img className={cn.twitterIcon} src={twitterIcon} height="16" width="16" />
    Twitter
    <img className={cn.arrow} src={chevronRight} alt="" height="8" width="8"/>
  </Button>
);

const styles = () => ({
  button: {
    backgroundColor: '#55ACEE',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '100',
    textTransform: 'capitalize',
    padding: '6px 13px',
    '&:hover': {
      backgroundColor: '#55ACEE',
      cursor: 'pointer',
      color: '#fff',
    }
  },
});

const cn = {
  twitterIcon: css`
    margin: -1px 8px 0 0;
  `,
  arrow: css`
    margin: 0 0 0 14px;
  `,
}

export default withStyles(styles)(TwitterShareButton);
