import React from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

import { useQuery } from '@apollo/react-hooks';
import { GET_ACCOUNT_CONTEXT } from '../account_info/accountInfo.graphql';
import { Profile, AcademicGroupMembership } from '../_api/Profile'

import client from '../_app/apollo/accountsBFF.client';

export type AccountContextApi = {
  orgUploaderFlag: boolean;
  userId: string | number;
  uploaderLevel: string;
  profile: Profile;
};

function getUploaderLevel(memberships: AcademicGroupMembership[]): string {
  const districtUploader = memberships.find(m => m.groupType === 'district' && m.roles.includes('uploader'))
  if (districtUploader) {
    return 'district'
  }
  const schoolUploader = memberships.find(m => m.groupType === 'school' && m.roles.includes('uploader'))
  if (schoolUploader) {
    return 'school'
  }
  return ''
}

export default function useAccountContext():
  | AccountContextApi
  | React.ReactNode {
  const { loading, data, error } = useQuery<{ profile: Profile }>(GET_ACCOUNT_CONTEXT, { client });

  if (loading) {
    return <LoadingSpinner />;
  }

  // todo: log error somewhere
  const profile = data!.profile!

  const orgUploaderFlag = profile.hierarchy.type === 'organization'
  const userId = profile.userId
  const uploaderLevel = orgUploaderFlag ? 'org' : getUploaderLevel(profile.academicGroupMemberships)

  return {
    userId,
    orgUploaderFlag,
    uploaderLevel,
    profile,
  };
}
