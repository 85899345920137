import React from 'react';
import config from '../../config';

const { REACT_APP_PORTAL_BFF_URL } = config;

const ExternalRedirect = ({ location }) => {
  const { pathname } = location;

  React.useEffect(() => {
    if (pathname) {
      window.location.href = `${REACT_APP_PORTAL_BFF_URL}${pathname}`;
    }
  }, []);

  return null;
};

export default ExternalRedirect;
