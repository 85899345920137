import React from 'react';
import { graphql, compose } from 'react-apollo';
import { TextInput, colors, elements } from '@peachjar/components';
import FormHelperText from '@material-ui/core/FormHelperText';
import { css } from 'react-emotion';
import { get } from 'lodash';
import withValidation from '../../../../shared/components/withValidation';
import { MISSED_FIELD_ERROR_MESSAGE } from '../../../../shared/constants';

import ARE_SAFE_URLS_QUERY from '../../gql/AreSafeUrlsQuery.graphql';

const { dragon } = colors;
const { Note } = elements.typography;

const dragonClass = css`
   {
    color: ${dragon};
  }
`;

export const INVALID_URL = 'Enter a valid web address.';
export const UNREACHABLE_URL =
  'Your URL can not be validated due to unsafe content or an unknown destination.';
export const UNSAFE_URL =
  'Your URL can not be validated due to unsafe content or an unknown destination.';

const isEmptyUrl = url => url === undefined || url === null || url.length === 0;

export const isValidUrl = url => {
  const regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/; // eslint-disable-line
  return regex.test(url);
};

// eslint-disable-next-line consistent-return
const getErrorMessage = (
  url,
  isValid,
  isSafe,
  isReachable,
  shouldPerformDisabledClickValidation
) => {
  if (isEmptyUrl(url) && shouldPerformDisabledClickValidation)
    return MISSED_FIELD_ERROR_MESSAGE;
  if (isEmptyUrl(url) || (isValid && isSafe && isReachable)) {
    return '';
  }
  if (isValid && isSafe && isReachable === false) return UNREACHABLE_URL;
  if (isValid && isSafe === false) return UNSAFE_URL;
  if (!isValid) return INVALID_URL;
  return '';
};

export class UrlInput extends React.Component {
  componentDidUpdate() {
    const {
      name,
      isValid,
      isSafe,
      isReachable,
      performValidation,
    } = this.props;

    performValidation({
      target: {
        name,
        value: {
          isValid,
          isSafe,
          isReachable,
        },
      },
    });
  }

  render() {
    const {
      name,
      label,
      placeholder,
      url,
      handleInputChange,
      isValid,
      isSafe,
      isReachable,
      dataTestId,
      shouldPerformDisabledClickValidation,
    } = this.props;

    const errorMessage = getErrorMessage(
      url,
      isValid,
      isSafe,
      isReachable,
      shouldPerformDisabledClickValidation
    );

    return (
      <>
        <TextInput
          fullWidth
          type="url"
          placeholder={placeholder}
          name={name}
          error={errorMessage !== ''}
          label={label}
          onChange={event => {
            const eventValue = event.target.value.trim();
            handleInputChange({
              ...event,
              target: { name, value: eventValue },
            });
          }}
          value={url}
          data-testid={dataTestId}
        />
        <FormHelperText>
          <Note className={dragonClass} data-testid="urlInputErrorMessage">
            {errorMessage}
          </Note>
        </FormHelperText>
      </>
    );
  }
}
const graphqlConfig = {
  options: ({ url }) => ({
    variables: {
      urls: [url],
    },
  }),
  skip: ({ url }) => isEmptyUrl(url) || !isValidUrl(url),
  props: ({ data, ownProps }) => {
    const { loading } = data;
    if (loading) return { ...ownProps };

    const { url } = ownProps;

    return {
      ...ownProps,
      isValid:
        isEmptyUrl(url) ||
        (isValidUrl(url) && url !== 'http://' && url !== 'https://'),
      isSafe: get(data, 'areSafeUrls.0.isSafe'),
      isReachable: get(data, 'areSafeUrls.0.isReachable'),
    };
  },
};

const validate = ({ isValid, isSafe, isReachable }) =>
  isValid === true && isSafe === true && isReachable === true;

const validateOnMount = () => true;

export default withValidation(
  compose(graphql(ARE_SAFE_URLS_QUERY, graphqlConfig))(UrlInput),
  validate,
  validateOnMount
);
