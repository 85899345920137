import gql from 'graphql-tag';

const CreditPurchaseQuoteFragment = gql`
  fragment CreditPurchaseQuote on CreditQuoteEnvelope {
    purchaseOrderId
    quote {
      id
      additionalDiscounts {
        id
        code
        type
        value
        valueType
        purchaserType
        purchaserIds
        schools
        evaluator
        conditions
        unit
        discountedUnit
        friendlyName
        friendlyDescription
        startsAt
        expiresAt
        createdAt
        updatedAt
        creditsSaved
        count
        savingsInCents
      }
      dollarDiscounts {
        id
        code
        type
        savingsInCents
        friendlyName
        friendlyDescription
        unit
        value
        valueType
      }
      msrpSubtotal {
        numberOfCredits
        priceInCents
      }
      subtotalAfterDiscounts {
        numberOfCredits
        priceInCents
      }
      subtotalMinusAccountBalance {
        numberOfCredits
        priceInCents
      }
      total {
        numberOfCredits
        msrpPriceInCents
        priceInCents
        discountPercent
      }
    }
    errors {
      type
      code
      message
    }
  }
`;

const FlyerPurchaseQuoteFragment = gql`
  fragment FlyerPurchaseQuote on FlyerQuoteEnvelope {
    purchaseOrderId
    quote {
      id
      additionalDiscounts {
        id
        code
        type
        value
        valueType
        purchaserType
        purchaserIds
        schools
        evaluator
        conditions
        unit
        discountedUnit
        friendlyName
        friendlyDescription
        startsAt
        expiresAt
        createdAt
        updatedAt
        creditsSaved
        count
        savingsInCents
      }
      dollarDiscounts {
        id
        code
        type
        savingsInCents
        friendlyName
        friendlyDescription
        unit
        value
        valueType
      }
      total {
        numberOfCredits
        msrpPriceInCents
        priceInCents
        discountPercent
      }
      audiences {
        numberOfCredits
        msrpPriceInCents
        distributions {
          occurrence
          numberOfCredits
        }
        # discountsApplied
        schoolName
        districtName
        schoolId
        districtId
        audienceType
        highGrade
        lowGrade
      }
      msrpSubtotal {
        numberOfCredits
        priceInCents
      }
      subtotalAfterDiscounts {
        numberOfCredits
        priceInCents
      }
      accountBalance {
        numberOfCredits
        priceInCents
      }
      subtotalMinusAccountBalance {
        numberOfCredits
        priceInCents
      }
      distributionDiscounts {
        code
        type
        value
        valueType
        conditions
        unit
        discountedUnit
        friendlyName
        friendlyDescription
        creditsSaved
        count
        savingsInCents
      }
    }
    errors {
      type
      code
      message
    }
  }
`;

const CreditPackagesFragment = gql`
  fragment CreditPackages on CreditPackage {
    id
    code
    type
    value
    valueType
    purchaserType
    purchaserIds
    schools
    evaluator
    conditions
    unit
    discountedUnit
    startsAt
    expiresAt
    createdAt
    updatedAt
    imageUrl
    schoolRate
    msrp
    discountedPrice
    amount
  }
`;

export const GET_CREDITS = gql`
  query addCredits {
    addCredits @client {
      credits
    }
  }
`;

export const ADD_CREDITS_MUTATION = gql`
  mutation AddCredits($credits: Int!) {
    addCredits(credits: $credits) @client
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const SUBMIT_PURCHASE_CREDITS = gql`
  mutation purchaseCredits($input: CreditPurchaseDetailsWithPaymentInput) {
    purchaseCredits(creditPurchaseDetails: $input) {
      ...CreditPurchaseQuote
    }
  }
  ${CreditPurchaseQuoteFragment}
`;

export const SUBMIT_FLYER_PURCHASE = gql`
  mutation purchaseFlyer($input: FlyerPurchaseDetailsWithPaymentInput) {
    purchaseFlyer(flyerPurchaseDetails: $input) {
      ...FlyerPurchaseQuote
    }
  }
  ${FlyerPurchaseQuoteFragment}
`;

export const GET_DEALS_AND_PURCHASE_QUOTE = gql`
  query getCreditPurchaseQuote($input: CreditPurchaseDetailsInput) {
    packages: getCreditPackages {
      ...CreditPackages
    }
    quote: getCreditPurchaseQuote(creditPurchaseDetails: $input) {
      ...CreditPurchaseQuote
    }
  }
  ${CreditPurchaseQuoteFragment}
  ${CreditPackagesFragment}
`;

// eslint-disable-next-line import/prefer-default-export
export const GET_CREDIT_PURCHASE_QUOTE = gql`
  query getCreditPurchaseQuote($input: CreditPurchaseDetailsInput) {
    getCreditPurchaseQuote(creditPurchaseDetails: $input) {
      ...CreditPurchaseQuote
    }
  }
  ${CreditPurchaseQuoteFragment}
`;

export const GET_FLYER_PURCHASE_QUOTE = gql`
  query getFlyerPurchaseQuote($input: FlyerPurchaseDetailsInput) {
    getFlyerPurchaseQuote(flyerPurchaseDetails: $input) {
      ...FlyerPurchaseQuote
    }
  }
  ${FlyerPurchaseQuoteFragment}
`;

export const GET_CREDIT_PACKAGES = gql`
  query {
    getCreditPackages {
      ...CreditPackages
    }
  }
  ${CreditPackagesFragment}
`;
