export default function localDateToMidnightUTC(d: Date): Date {
  if (d == null) {
    return null;
  }

  return new Date(
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
    )
  );
}
