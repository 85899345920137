import React, { useContext } from 'react';
import idx from 'idx';
import Raven from 'raven-js';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { css } from 'emotion';
import { components, colors } from '@peachjar/components';
import { apolloClient as bffClient } from '../apollo/portalBFF.apolloClient';
import DealsContext from '../context/DealsContext';
import CreditsModal from '../components/PurchaseModal';
import { NOTIFICATIONS } from '../constants';

const {
  ModalConsumer,
  Buttons: { ButtonFlatSmall },
  Notifications: { notifyError },
} = components;

type Props = {
  handleError: (key: string) => void;
  variant?: string;
};

const CreditsMenu: React.FunctionComponent<Props> = ({
  variant = 'brandbar',
  handleError,
}) => {
  const { creditBalance } = useContext(DealsContext);

  return (
    <ModalConsumer>
      {({ showModal, closeModal }) => (
        <div
          className={`${cn.creditsMenu} ${variant}`}
          data-testid="menu-credits-brandbar"
        >
          <span className="creditAmount">{creditBalance} credits</span>
          <ButtonFlatSmall
            className="addCreditsCTA"
            onClick={() =>
              showModal(CreditsModal, {
                showModal,
                closeModal,
              })
            }
          >
            Add...
          </ButtonFlatSmall>
        </div>
      )}
    </ModalConsumer>
  );
};

const cn = {
  creditsMenu: css`
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    margin-right: 8px;

    &.brandbar {
      .creditAmount {
        color: ${colors.prussian};
        font-size: 13px;
        font-weight: 600;
        padding-right: 8px;
      }
      .addCreditsCTA {
        font-size: 13px;
      }
    }

    &.processbar {
      .creditAmount {
        color: ${colors.white};
        font-size: 13px;
        font-weight: 600;
        padding-right: 30px;
      }
      .addCreditsCTA {
        display: none !important;
      }
    }
  `,
};

const mapDispatchToProps = dispatch => ({
  handleError: key => dispatch(notifyError(NOTIFICATIONS[key])),
});

export default connect(
  null,
  mapDispatchToProps
)(CreditsMenu);
