import * as React from 'react';
import { css } from 'emotion';
import { elements } from '@peachjar/components';
import NoPendingApprovalsLogo from './NoPendingApprovalsLogo';

const { Label } = elements.typography;

const NoPendingApprovalsView = () => (
  <div className="d-block mx-auto text-center">
    <NoPendingApprovalsLogo />
    <div className={classNames.captionContainer}>
      <Label>
        Sit back and relax - you don&apos;t have any pending approvals right
        now.
      </Label>
    </div>
  </div>
);

const classNames = {
  captionContainer: css`
    margin: 2.625rem auto 0 auto;
    width: 32rem;
  `,
};

export default NoPendingApprovalsView;
