import GradeLevels from '@peachjar/school-finder-api/dist/api/GradeLevels';
import { range } from 'lodash';

export const MAX_FLYER_TITLE_LENGTH = 45;
export const MISSED_FIELD_ERROR_MESSAGE = 'You missed this field.';
export const FLYER_TITLE_MAX_LENGTH_ERROR_MESSAGE = `Enter ${MAX_FLYER_TITLE_LENGTH} characters or less.`;
export const DATE_ERROR_MESSAGE =
  'This date is no longer valid. Select a new date.';

/** Community Free */
export const DEFAULT_COMM_FREE_SCHOOL_SELECTION_LIMIT = 25;
export const COMM_FREE_SCHOOL_SELECTION_LIMIT_KEY = 'pj_cf_school_limit';
export const COMM_FREE_NO_ACCESS_VALUE = '0';
export const COMM_FREE_APPLICATION_STATUS = Object.freeze({
  draft: 'draft',
  associatedWithCampaign: 'associated_with_campaign',
  submitted: 'submitted',
  pending: 'pending',
  accepted: 'accepted',
  declined: 'declined',
});

export const gradeFilterDefault = {
  min: GradeLevels.ECE,
  max: GradeLevels.CE,
};

export const gradesToAges = {
  [GradeLevels.ECE]: '3',
  [GradeLevels.PK]: '4',
  [GradeLevels.KG]: '5',
  [GradeLevels.FIRST]: '6',
  [GradeLevels.SECOND]: '7',
  [GradeLevels.THIRD]: '8',
  [GradeLevels.FOURTH]: '9',
  [GradeLevels.FIFTH]: '10',
  [GradeLevels.SIXTH]: '11',
  [GradeLevels.SEVENTH]: '12',
  [GradeLevels.EIGTH]: '13',
  [GradeLevels.NINTH]: '14',
  [GradeLevels.TENTH]: '15',
  [GradeLevels.ELEVENTH]: '16',
  [GradeLevels.TWELFTH]: '17',
  [GradeLevels.CE]: '18',
};

export const agesToGrades = {
  '3': GradeLevels.ECE,
  '4': GradeLevels.PK,
  '5': GradeLevels.KG,
  '6': GradeLevels.FIRST,
  '7': GradeLevels.SECOND,
  '8': GradeLevels.THIRD,
  '9': GradeLevels.FOURTH,
  '10': GradeLevels.FIFTH,
  '11': GradeLevels.SIXTH,
  '12': GradeLevels.SEVENTH,
  '13': GradeLevels.EIGTH,
  '14': GradeLevels.NINTH,
  '15': GradeLevels.TENTH,
  '16': GradeLevels.ELEVENTH,
  '17': GradeLevels.TWELFTH,
  '18': GradeLevels.CE,
};

export const grades = [
  { label: 'ECE', value: GradeLevels.ECE },
  { label: 'PK', value: GradeLevels.PK },
  { label: 'KG', value: GradeLevels.KG },
  { label: '1', value: GradeLevels.FIRST },
  { label: '2', value: GradeLevels.SECOND },
  { label: '3', value: GradeLevels.THIRD },
  { label: '4', value: GradeLevels.FOURTH },
  { label: '5', value: GradeLevels.FIFTH },
  { label: '6', value: GradeLevels.SIXTH },
  { label: '7', value: GradeLevels.SEVENTH },
  { label: '8', value: GradeLevels.EIGTH },
  { label: '9', value: GradeLevels.NINTH },
  { label: '10', value: GradeLevels.TENTH },
  { label: '11', value: GradeLevels.ELEVENTH },
  { label: '12', value: GradeLevels.TWELFTH },
  { label: 'CE', value: GradeLevels.CE },
];

// Note range does not include the ending index so range(0,5) is
// [0, 1, 2, 3, 4] which is why my ages look like they include an
// extra age
export const gradeToAgeRange = [
  { grade: GradeLevels.ECE, ages: range(0, 6) },
  { grade: GradeLevels.PK, ages: range(2, 7) },
  { grade: GradeLevels.KG, ages: range(4, 8) },
  { grade: GradeLevels.FIRST, ages: range(5, 9) },
  { grade: GradeLevels.SECOND, ages: range(6, 10) },
  { grade: GradeLevels.THIRD, ages: range(7, 11) },
  { grade: GradeLevels.FOURTH, ages: range(8, 12) },
  { grade: GradeLevels.FIFTH, ages: range(9, 13) },
  { grade: GradeLevels.SIXTH, ages: range(10, 14) },
  { grade: GradeLevels.SEVENTH, ages: range(11, 15) },
  { grade: GradeLevels.EIGTH, ages: range(12, 16) },
  { grade: GradeLevels.NINTH, ages: range(13, 17) },
  { grade: GradeLevels.TENTH, ages: range(14, 18) },
  { grade: GradeLevels.ELEVENTH, ages: range(15, 19) },
  { grade: GradeLevels.TWELFTH, ages: range(16, 20) },
  { grade: GradeLevels.CE, ages: range(17, 101) },
];

export const ages = [
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18+', value: '18' },
];

export const gradeOrdinal = {
  [GradeLevels.ECE]: 0,
  [GradeLevels.PK]: 1,
  [GradeLevels.KG]: 2,
  [GradeLevels.FIRST]: 3,
  [GradeLevels.SECOND]: 4,
  [GradeLevels.THIRD]: 5,
  [GradeLevels.FOURTH]: 6,
  [GradeLevels.FIFTH]: 7,
  [GradeLevels.SIXTH]: 8,
  [GradeLevels.SEVENTH]: 9,
  [GradeLevels.EIGTH]: 10,
  [GradeLevels.NINTH]: 11,
  [GradeLevels.TENTH]: 12,
  [GradeLevels.ELEVENTH]: 13,
  [GradeLevels.TWELFTH]: 14,
  [GradeLevels.CE]: 15,
};

export const ordinalGrades = {
  0: GradeLevels.ECE,
  1: GradeLevels.PK,
  2: GradeLevels.KG,
  3: GradeLevels.FIRST,
  4: GradeLevels.SECOND,
  5: GradeLevels.THIRD,
  6: GradeLevels.FOURTH,
  7: GradeLevels.FIFTH,
  8: GradeLevels.SIXTH,
  9: GradeLevels.SEVENTH,
  10: GradeLevels.EIGTH,
  11: GradeLevels.NINTH,
  12: GradeLevels.TENTH,
  13: GradeLevels.ELEVENTH,
  14: GradeLevels.TWELFTH,
  15: GradeLevels.CE,
};

export const feeRange = [
  { label: 'Free', value: '$0.00' },
  { label: '$1 \u2013 $50', value: '$1.00-$50.00' },
  { label: '$50 \u2013 $100', value: '$51.00-$100.00' },
  { label: '$100 \u2013 $200', value: '$101.00-$200.00' },
  { label: '$200+', value: '$201.00' },
];

// Geocore Categories
export const geocoreCategories = [
  {
    category: 'Educational Learning & Instruction',
    subCategory: 'Engineering & Robotics',
  },
  {
    category: 'Educational Learning & Instruction',
    subCategory: 'Foreign Language',
  },
  { category: 'Educational Learning & Instruction', subCategory: 'Writing' },
  { category: 'Educational Learning & Instruction', subCategory: 'Science' },
  { category: 'Educational Learning & Instruction', subCategory: 'Technology' },
  { category: 'Educational Learning & Instruction', subCategory: 'Math' },
  { category: 'Educational Learning & Instruction', subCategory: 'History' },
  { category: 'Educational Learning & Instruction', subCategory: 'English' },
  { category: 'Educational Learning & Instruction', subCategory: 'Tutoring' },
  {
    category: 'Educational Learning & Instruction',
    subCategory: "Driver's Education",
  },
  {
    category: 'Educational Learning & Instruction',
    subCategory: 'Reading & Literature',
  },
  { category: 'Community Events', subCategory: 'Charities' },
  { category: 'Community Events', subCategory: 'Family Events' },
  { category: 'Community Events', subCategory: 'Festivals' },
  { category: 'Community Events', subCategory: 'Health Fair' },
  { category: 'Community Events', subCategory: 'Museums' },
  { category: 'Community Events', subCategory: 'Fundraisers' },
  { category: 'Community Events', subCategory: 'Concerts' },
  { category: 'Community Events', subCategory: 'Conservation' },
  { category: 'Community Events', subCategory: 'Multi-Cultural' },
  { category: 'Community Events', subCategory: "Farmer's Market" },
  { category: 'Community Events', subCategory: 'Shopping' },
  { category: 'Sports & Fitness', subCategory: 'Baseball/T-ball' },
  { category: 'Sports & Fitness', subCategory: 'Basketball' },
  { category: 'Sports & Fitness', subCategory: 'Cheerleading' },
  { category: 'Sports & Fitness', subCategory: 'Football' },
  { category: 'Sports & Fitness', subCategory: 'Golf' },
  { category: 'Sports & Fitness', subCategory: 'Gymnastics & Tumbling' },
  { category: 'Sports & Fitness', subCategory: 'Hockey' },
  { category: 'Sports & Fitness', subCategory: 'Lacrosse' },
  { category: 'Sports & Fitness', subCategory: 'Martial Arts' },
  { category: 'Sports & Fitness', subCategory: 'Running' },
  { category: 'Sports & Fitness', subCategory: 'Skiing & Snowboarding' },
  { category: 'Sports & Fitness', subCategory: 'Soccer' },
  { category: 'Sports & Fitness', subCategory: 'Softball' },
  { category: 'Sports & Fitness', subCategory: 'Swimming' },
  { category: 'Sports & Fitness', subCategory: 'Tennis' },
  { category: 'Sports & Fitness', subCategory: 'Volleyball' },
  { category: 'Sports & Fitness', subCategory: 'Wrestling' },
  { category: 'Sports & Fitness', subCategory: 'Yoga' },
  { category: 'Sports & Fitness', subCategory: 'Multi-Sport' },
  { category: 'Sports & Fitness', subCategory: 'Water Polo' },
  { category: 'Sports & Fitness', subCategory: 'Gyms' },
  { category: 'Sports & Fitness', subCategory: 'Bowling' },
  { category: 'Camps', subCategory: 'Music & Art' },
  { category: 'Camps', subCategory: 'Sports & Fitness' },
  { category: 'Camps', subCategory: 'Multi-Activity' },
  { category: 'Camps', subCategory: 'Professional Sports' },
  { category: 'Camps', subCategory: 'STEM' },
  { category: 'Camps', subCategory: 'Entertainment' },
  { category: 'Music & Arts', subCategory: 'Arts & Crafts' },
  { category: 'Music & Arts', subCategory: 'Music' },
  { category: 'Music & Arts', subCategory: 'Performing Arts' },
  { category: 'Music & Arts', subCategory: 'Dance' },
  { category: 'Music & Arts', subCategory: 'Theater' },
  { category: 'Music & Arts', subCategory: 'Museum' },
  { category: 'Music & Arts', subCategory: 'Painting' },
  { category: 'Higher Ed/College Prep', subCategory: 'College Preparation' },
  {
    category: 'Higher Ed/College Prep',
    subCategory: 'Colleges & Universities',
  },
  { category: 'Higher Ed/College Prep', subCategory: 'Scholarships & Grants' },
  { category: 'Higher Ed/College Prep', subCategory: 'College Savings Plans' },
  { category: 'Higher Ed/College Prep', subCategory: 'College Fairs' },
  { category: 'Higher Ed/College Prep', subCategory: 'SAT & ACT Prep' },
  { category: 'Higher Ed/College Prep', subCategory: 'Enrollment' },
  { category: 'Parent Resources', subCategory: 'Apps' },
  { category: 'Parent Resources', subCategory: 'School Transportation' },
  { category: 'Parent Resources', subCategory: 'Preschools' },
  { category: 'Parent Resources', subCategory: 'Daycare' },
  { category: 'Parent Resources', subCategory: 'Seminars' },
  { category: 'Parent Resources', subCategory: 'Parenting Advice' },
  { category: 'Parent Resources', subCategory: 'Home Services' },
  { category: 'Parent Resources', subCategory: 'Banking' },
  { category: 'Parent Resources', subCategory: 'Financial Assistance' },
  { category: 'Parent Resources', subCategory: 'Insurance' },
  { category: 'Parent Resources', subCategory: 'Counseling' },
  { category: 'Career & Personal Development', subCategory: 'Leadership' },
  { category: 'Career & Personal Development', subCategory: 'Empowerment' },
  {
    category: 'Career & Personal Development',
    subCategory: 'Character Development',
  },
  {
    category: 'Career & Personal Development',
    subCategory: 'Jobs & Recruitment',
  },
  {
    category: 'Career & Personal Development',
    subCategory: 'Workforce Training',
  },
  { category: 'Career & Personal Development', subCategory: 'Networking' },
  { category: 'Career & Personal Development', subCategory: 'Career Fairs' },
  {
    category: 'Career & Personal Development',
    subCategory: 'Entrepreneurship',
  },
  {
    category: 'Career & Personal Development',
    subCategory: 'Exchange Programs',
  },
  { category: 'Health & Wellness', subCategory: 'Student Accident Insurance' },
  { category: 'Health & Wellness', subCategory: 'Health Services' },
  { category: 'Health & Wellness', subCategory: 'Safety Information' },
  { category: 'Health & Wellness', subCategory: 'Nutrition' },
  { category: 'Health & Wellness', subCategory: 'Research' },
  { category: 'Health & Wellness', subCategory: 'Health Insurance' },
  { category: 'Health & Wellness', subCategory: 'Preventative' },
  { category: 'Health & Wellness', subCategory: 'Pharmacy' },
  { category: 'Health & Wellness', subCategory: 'Associations' },
  { category: 'Youth Groups', subCategory: 'Youth Center' },
  { category: 'Youth Groups', subCategory: 'Youth Association' },
  { category: 'Youth Groups', subCategory: 'Empowerment' },
  { category: 'Youth Groups', subCategory: 'Character Development' },
  { category: 'City/State Resources', subCategory: 'Parks & Rec' },
  { category: 'City/State Resources', subCategory: 'Health Department' },
  { category: 'City/State Resources', subCategory: 'Fire Department' },
  { category: 'City/State Resources', subCategory: 'Police Department' },
  { category: 'City/State Resources', subCategory: 'Library' },
  { category: 'School Activiites & Events', subCategory: 'Book Events' },
  {
    category: 'School Activiites & Events',
    subCategory: 'Calendars & Schedules',
  },
  {
    category: 'School Activiites & Events',
    subCategory: 'Clothing & Food Drives',
  },
  { category: 'School Activiites & Events', subCategory: 'Clubs' },
  { category: 'School Activiites & Events', subCategory: 'Festivals & Fairs' },
  { category: 'School Activiites & Events', subCategory: 'Field Trips' },
  { category: 'School Activiites & Events', subCategory: 'Fundraisers' },
  { category: 'School Activiites & Events', subCategory: 'Lunch Menus' },
  { category: 'School Activiites & Events', subCategory: 'Multi-Cultural' },
  { category: 'School Activiites & Events', subCategory: 'Newsletters' },
  { category: 'School Activiites & Events', subCategory: 'Open House' },
  { category: 'School Activiites & Events', subCategory: 'Picture Day' },
  { category: 'School Activiites & Events', subCategory: 'School Dances' },
  { category: 'School Activiites & Events', subCategory: 'School Holidays' },
  { category: 'School Activiites & Events', subCategory: 'School Spirit' },
  { category: 'School Activiites & Events', subCategory: 'Sports' },
  { category: 'School Activiites & Events', subCategory: 'Walks & Runs' },
  { category: 'School Activiites & Events', subCategory: 'Walkthroughs' },
  { category: 'School Activiites & Events', subCategory: 'Yearbook' },
].map(c => ({
  label: `${c.category} > ${c.subCategory}`,
  value: `${c.category} > ${c.subCategory}`,
}));

// Campaign Categories for School Users
export const campaignCategories = [
  { label: 'Events > Book Fairs', value: 'Events > Book Fairs' },
  {
    label: 'Events > Clothing & Food Drives',
    value: 'Events > Clothing & Food Drives',
  },
  { label: 'Events > Fundraisers', value: 'Events > Fundraisers' },
  { label: 'Events > Walks & Runs', value: 'Events > Walks & Runs' },
  { label: 'Events > School Dances', value: 'Events > School Dances' },
  { label: 'Events > Meet & Greet', value: 'Events > Meet & Greet' },
  { label: 'Events > School Sports', value: 'Events > School Sports' },
  { label: 'Events > Music & Arts', value: 'Events > Music & Arts' },
  { label: 'Events > Field Trips', value: 'Events > Field Trips' },
  { label: 'Events > PTA/PTO Events', value: 'Events > PTA/PTO Events' },
  {
    label: 'Events > Reading & Writing',
    value: 'Events > Reading & Writing',
  },
  { label: 'Events > Open House', value: 'Events > Open House' },
  {
    label: 'Events > Wildlife & Conservation',
    value: 'Events > Wildlife & Conservation',
  },
  {
    label: 'Events > Festivals & Fairs',
    value: 'Events > Festivals & Fairs',
  },
  { label: 'Events > Multi-Cultural', value: 'Events > Multi-Cultural' },
  { label: 'Events > Holiday Events', value: 'Events > Holiday Events' },
  {
    label: 'Resources > Career Assistance',
    value: 'Resources > Career Assistance',
  },
  {
    label: 'Resources > College Resources',
    value: 'Resources > College Resources',
  },
  { label: 'Resources > SAT & ACT', value: 'Resources > SAT & ACT' },
  {
    label: 'Resources > English Language Learners',
    value: 'Resources > English Language Learners',
  },
  {
    label: 'Resources > Support Services',
    value: 'Resources > Support Services',
  },
  { label: 'Resources > Tutoring', value: 'Resources > Tutoring' },
  {
    label: 'Resources > Transportation',
    value: 'Resources > Transportation',
  },
  {
    label: 'Resources > Nutrition & Wellness',
    value: 'Resources > Nutrition & Wellness',
  },
  {
    label: 'Resources > Safety & Health',
    value: 'Resources > Safety & Health',
  },
  {
    label: 'Resources > Apparel & Gear',
    value: 'Resources > Apparel & Gear',
  },
  { label: 'Resources > School Clubs', value: 'Resources > School Clubs' },
  {
    label: 'Resources > School Supplies',
    value: 'Resources > School Supplies',
  },
  { label: 'Resources > Counseling', value: 'Resources > Counseling' },
  {
    label: 'News & Updates > School Announcements',
    value: 'News & Updates > School Announcements',
  },
  {
    label: 'News & Updates > Lunch Menus',
    value: 'News & Updates > Lunch Menus',
  },
  {
    label: 'News & Updates > Picture Day',
    value: 'News & Updates > Picture Day',
  },
  {
    label: 'News & Updates > Yearbook',
    value: 'News & Updates > Yearbook',
  },
  {
    label: 'News & Updates > Volunteer Opportunities',
    value: 'News & Updates > Volunteer Opportunities',
  },
  {
    label: 'News & Updates > Calendars & Schedules',
    value: 'News & Updates > Calendars & Schedules',
  },
  {
    label: 'News & Updates > Newsletters',
    value: 'News & Updates > Newsletters',
  },
  {
    label: 'News & Updates > Academic Updates',
    value: 'News & Updates > Academic Updates',
  },
  {
    label: 'News & Updates > School Spirit',
    value: 'News & Updates > School Spirit',
  },
  {
    label: 'Staff > Career Opportunities',
    value: 'Staff > Career Opportunities',
  },
  { label: 'Staff > Volunteering', value: 'Staff > Volunteering' },
  {
    label: 'Staff > Continued Education',
    value: 'Staff > Continued Education',
  },
  {
    label: 'Staff > Staff Discounts & Offers',
    value: 'Staff > Staff Discounts & Offers',
  },
  { label: 'Staff > Employee Events', value: 'Staff > Employee Events' },

  {
    label: 'Staff > Technology Updates',
    value: 'Staff > Technology Updates',
  },
  { label: 'Staff > Human Resources', value: 'Staff > Human Resources' },
  {
    label: 'Staff > Holiday Announcements',
    value: 'Staff > Holiday Announcements',
  },
];
