import React from 'react';
import { css } from 'react-emotion';
import { elements, InfoTooltip, colors } from '@peachjar/components';

const { prussian } = colors;
const { Note } = elements.typography;

export const NoteInlineNotification = ({
  message,
  messageLabel,
  dataTestId,
}) => (
  <div
    data-testid={dataTestId}
    className={css`
      padding: 24px;
      border-radius: 3px;
      margin-left: -5px;
      margin-top: 1rem;
      background-color: #dfe1e7;
      display: flex;
      flex-direction: column;
    `}
  >
    <Note style={{ color: prussian }}>
      <strong>{messageLabel}: </strong>
      {message}
    </Note>
  </div>
);

export const WarnInlineNotification = ({
  message,
  messageLabel,
  dataTestId,
}) => (
  <div
    data-testid={dataTestId}
    className={css`
      padding: 24px;
      border-radius: 3px;
      margin-left: -5px;
      margin-top: 1rem;
      background-color: #fffae5;
      border: 1px solid #814026;
      display: flex;
      flex-direction: column;
      max-width: 435px;
    `}
  >
    <Note style={{ color: '#814026' }}>
      <strong>{messageLabel}: </strong>
      {message}
    </Note>
  </div>
);
