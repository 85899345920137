import React, { Component } from 'react';
import { css } from 'react-emotion';
import { elements } from '@peachjar/components';
import Collapse from '@material-ui/core/Collapse';
import SchoolItem from './SchoolItem';

const { Paragraph } = elements.typography;

const styles = {
  schoolListHeader: css`
    cursor: pointer;
  `,
  schoolList: css`
    overflow-y: scroll;
    max-height: 400px;
    min-height: 1rem;
  `,
};

class SchoolList extends Component {
  state = {
    isExpanded: false,
  };

  expandSchoolList = () => () => {
    const { isExpanded } = this.state;

    this.setState({
      isExpanded: !isExpanded,
    });
  };

  howManySchools = (schools, isTargettingParents) => {
    if (isTargettingParents) {
      return `${schools.length} total`;
    }

    return `${schools.length} ${
      schools.length > 1 ? 'Schools / Groups' : 'School / Group'
    }`;
  };

  render() {
    const { isExpanded } = this.state;
    const { schools, isTargettingParents } = this.props;

    return (
      <>
        <Paragraph
          className={`layout-row layout-align-start-center ${
            styles.schoolListHeader
          }`}
          onClick={this.expandSchoolList()}
        >
          {this.howManySchools(schools, isTargettingParents)}{' '}
          {isExpanded ? (
            <span className="material-icons">arrow_drop_up</span>
          ) : (
            <span className="material-icons">arrow_drop_down</span>
          )}
        </Paragraph>

        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <div className={styles.schoolList}>
            {schools.map(school => (
              <SchoolItem key={school.schoolId} {...school} />
            ))}
          </div>
        </Collapse>
      </>
    );
  }
}

export default SchoolList;
