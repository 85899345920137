import React from 'react';
import AccountInfoOrgs from './AccountInfo';
import AccountInfoStaff from '../account_information_staff/AccountInformationStaff.container';
import useAccountContext, {
  AccountContextApi,
} from '../hooks/useAccountContext';

const AccountInfoContainer = ({ ...rest }) => {
  const {
    userId,
    orgUploaderFlag,
    uploaderLevel,
  } = useAccountContext() as AccountContextApi;

  const RenderComponent =
    uploaderLevel === 'org' && orgUploaderFlag
      ? AccountInfoOrgs
      : AccountInfoStaff;

  return <RenderComponent sodUser={{ id: userId }} {...rest} />;
};

export default AccountInfoContainer;
