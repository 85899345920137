import React from 'react';
import { css } from 'react-emotion';
import { TextInput, components } from '@peachjar/components';
import SearchIcon from '@material-ui/icons/Search';
import { CURRENT_MAP_AREA_COPY } from './constants';
import { getCurrentBounds, getCurrentCenter } from '../utils';
import Filters from '../Filters';
import Autocomplete from './components/Autocomplete';
import { Geocode } from '../../types';
import { AudienceFilterType } from '../Filters/AudienceFilter';
import { GradeFilterType } from '../Filters/GradeFilter';

const { ButtonSecondarySmall } = components.Buttons;

type Props = {
  inputValue: string;
  placeId: string;
  searchByValue: string;
  setInputValue: (input: string) => void;
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
  setSearchByValue: (searchByValue: string) => void;
  setPlaceId: (placeId: string) => void;
  setMapVariables: (mapVariables: any) => void;
  gradeFilter: GradeFilterType,
  audienceFilter: AudienceFilterType,
  setGradeFilter: (filter: AudienceFilterType) => void;
  setAudienceFilter: (filter: GradeFilterType) => void;
  formattedAddress: string;
  classes?: any;
  map: any;
  center: Geocode;
};

export const SearchBar = ({
  inputValue,
  searchByValue,
  placeId,
  setInputValue,
  setSearchValue,
  searchValue,
  setSearchByValue,
  setPlaceId,
  gradeFilter,
  audienceFilter,
  setGradeFilter,
  setAudienceFilter,
  setMapVariables,
  formattedAddress,
  map,
  center,
}: Props) => {
  function handleSearchSubmission(option) {
    if (searchValue === CURRENT_MAP_AREA_COPY) {
      // setSearchValue(searchValue);
      // setPlaceId(option.placeId);
      // setSearchByValue(option.searchBy);
      setMapVariables({
        center: getCurrentCenter(map),
        redoMapBounds: getCurrentBounds(map),
      });
    } else {
      setPlaceId(option.placeId);
      setSearchByValue(option.searchBy);
      // setSearchValue(searchValue);
      // reset mapVariables
      setMapVariables({
        center: { latitude: null, longitude: null },
        redoMapBounds: null,
      });
    }
  }

  return (
    <div className={styles.container}>
      <div className="layout-row flex-50">
        <SearchIcon className="mr-1" />

        <Autocomplete
          formattedAddress={formattedAddress}
          className={`flex-auto ${styles.searchInput}`}
          fullWidth
          center={center}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setSearchValue={setSearchValue}
          onSelect={async option => {
            if (option) {
              setInputValue(option.value);
              setSearchValue(option.value);
              handleSearchSubmission(option);
            }
          }}
        />

        <ButtonSecondarySmall
          onClick={event => {
            handleSearchSubmission({
              value: searchValue,
              placeId,
              searchBy: searchByValue,
            });
          }}
        >
          Search
        </ButtonSecondarySmall>
      </div>

      <div className="flex-50">
        <Filters
          audienceFilter={audienceFilter}
          gradeFilter={gradeFilter}
          setAudienceFilter={setAudienceFilter}
          setGradeFilter={setGradeFilter}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css`
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  `,
  searchInput: css`
    margin-right: 1rem;
    max-width: 350px;
  `,
};

export default SearchBar;
