import gql from 'graphql-tag';

export const REMOVE_FLYER_MUTATION = gql`
  mutation removeMyFlyer($flyerId: Int!) {
    removeMyFlyer(flyerId: $flyerId) {
      id
    }
  }
`;

export const FLYER_DETAILS_QUERY = gql`
  query flyerDetails($id: Int) {
    sodUser: profile {
      id
      userId
      permission {
        uploaderLevel
      }
      flyerReporting {
        flyerDetailReport(id: $id) {
          campaign {
            id
            createdBy
            ownerId
          }
        }
      }
    }
  }
`;

export const FLYER_METRICS_QUERY = gql`
  query getFlyerMetrics($flyerIds: [String!]) {
    getFlyerMetrics(flyerIds: $flyerIds) {
      flyerId
      uniqueDeliveries {
        email
        flyerBoard
      }
      uniqueDisplays {
        email
        flyerBoard
      }
      uniqueViews {
        email
        flyerBoard
      }
      uniqueActions {
        email
        flyerBoard
      }
    }
  }
`;
