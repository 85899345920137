import React, { useEffect } from 'react';
import { css } from 'emotion';
import gql from 'graphql-tag';
import { SnackbarProvider, useSnackbar } from 'notistack';
import colors from '@peachjar/ui/dist/lib/styles/colors';
import WarningIcon from '@peachjar/ui/dist/lib/components/Icons/WarningIcon';
import TypographyRedux from '@peachjar/ui/dist/lib/typography/TypographyRedux';
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons';
import portalBFFClient from '../_app/apollo/portalBFF.apolloClient';
import config from '../config';

const {
  Dark: { Note },
} = TypographyRedux;

const DRAFT_QUERY = gql`
  query ApprovalSettingsDraftQuery {
    getApprovalSettings {
      isDraft
    }
  }
`;

const rootStyle = css`
  top: 58px !important;
`;

const notificationStyle = css`
  border-radius: 3px !important;
  width: 1000px !important;
  max-width: none !important;
  padding: 12px 16px !important;
  background-color: ${colors.blonde} !important;
  border: 1px solid ${colors.soil};
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
  justify-content: space-between !important;
  & .MuiIcon-root {
    width: inherit;
    height: inherit;
    margin-right: 8px;
  }
`;

const ApprovalSettingsDraftNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  async function queryApprovalSettingsDraft() {
    const { data } = await portalBFFClient.query({
      query: DRAFT_QUERY,
      fetchPolicy: 'no-cache',
    });
    return data.getApprovalSettings && data.getApprovalSettings.isDraft;
  }

  useEffect(() => {
    queryApprovalSettingsDraft().then(isDraft => {
      if (isDraft) {
        enqueueSnackbar(
          <Note style={{ marginLeft: '8px' }}>
            You have unsaved Approval Settings changes. Go to Approval Settings
            to review and activate your workflow.
          </Note>,
          {
            variant: 'warning',
            persist: true,
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
            action: (
              <ButtonSecondarySmall
                href={`${config.REACT_APP_PORTAL_BFF_URL}/settings/approvals`}
              >
                Go to Approval Settings
              </ButtonSecondarySmall>
            ),
          }
        );
      }
    });
  }, [enqueueSnackbar]);

  return <></>;
};

export default function() {
  return (
    <SnackbarProvider
      classes={{
        variantWarning: notificationStyle,
        containerAnchorOriginTopCenter: rootStyle,
      }}
      iconVariant={{
        warning: <WarningIcon />,
      }}
    >
      <ApprovalSettingsDraftNotification />
    </SnackbarProvider>
  );
}
