import { get, findIndex, reject, differenceBy, toString } from 'lodash';
import { School } from './Org/types';

export const toggleCheckbox = (
  audienceListItem: School | any,
  setStateFn: (newCheckboxSelections: Record<any, any>) => void,
  checkboxSelections: School[]
) => {
  if (!audienceListItem) {
    return checkboxSelections;
  }

  const newCheckboxSelections = [...checkboxSelections];
  const foundSelectionIndex = newCheckboxSelections.findIndex(
    selection => selection.schoolId === audienceListItem.schoolId
  );

  if (foundSelectionIndex > -1) {
    newCheckboxSelections.splice(foundSelectionIndex, 1);
  } else {
    newCheckboxSelections.push(audienceListItem);
  }

  setStateFn({
    variables: {
      checkboxSelections: newCheckboxSelections,
    },
  });

  return newCheckboxSelections;
};

export const toggleCheckboxes = (
  targetSchools,
  setStateFn,
  checkboxSelections
) => {
  const newCheckboxSelections =
    checkboxSelections.length === targetSchools.length ? [] : targetSchools;

  setStateFn({
    variables: {
      checkboxSelections: newCheckboxSelections,
    },
  });

  return newCheckboxSelections;
};

export const toggleSubsetOfCheckboxes = (
  targetSchools,
  setStateFn,
  checkboxSelections
): Object[] => {
  // Only work with pin marker schools
  const filteredTargetSchools = targetSchools.filter(
    targetSchool => targetSchool.isPin === 1
  );

  const checkboxSelectionsSubset = checkboxSelections.filter(selection =>
    filteredTargetSchools.find(
      school => school.schoolId === selection.schoolId && school.isPin === 1
    )
  );

  const hasSelectedEntireSubset =
    checkboxSelectionsSubset.length === filteredTargetSchools.length;

  const newCheckboxSelections: Object[] = hasSelectedEntireSubset
    ? reject(checkboxSelections, selection =>
        checkboxSelectionsSubset.includes(selection)
      )
    : [
        ...checkboxSelections,
        ...differenceBy(filteredTargetSchools, checkboxSelections, 'schoolId'),
      ];

  setStateFn({
    variables: {
      checkboxSelections: newCheckboxSelections,
    },
  });

  return newCheckboxSelections;
};

export const getAreAllItemsSelected = (
  checkboxSelections,
  selectedAudience,
  district
) => {
  const allOfSelectedAudience = get(district, `audiences.${selectedAudience}`);
  if (!allOfSelectedAudience || allOfSelectedAudience.length === 0) {
    return false;
  }

  return allOfSelectedAudience.every(school => {
    return checkboxSelections.find(selectedSchool => {
      return selectedSchool.schoolId === school.schoolId;
    });
  });
};

export const getOtherAudience = selectedAudience => {
  return selectedAudience === 'parents' ? 'staff' : 'parents';
};

export const getOnlyItemsFromOtherAudience = (
  checkboxSelections,
  selectedAudience,
  district
) => {
  const otherAudience = getOtherAudience(selectedAudience);
  let allOfOtherAudience = get(district, `audiences.${otherAudience}`);
  if (!allOfOtherAudience) {
    allOfOtherAudience = [];
  }

  return checkboxSelections.filter(selected => {
    return allOfOtherAudience.find(school => {
      return selected.schoolId === school.schoolId;
    });
  });
};

export const toggleAllCheckboxes = (
  district,
  selectedAudience,
  setStateFn,
  checkboxSelections
) => {
  const allOfSelectedAudience = get(district, `audiences.${selectedAudience}`);
  const areAllItemsSelected = getAreAllItemsSelected(
    checkboxSelections,
    selectedAudience,
    district
  );
  const onlyItemsFromOtherAudience = getOnlyItemsFromOtherAudience(
    checkboxSelections,
    selectedAudience,
    district
  );
  // Here I am combining all checkboxes selected for the other audience so that
  // when appending all of the current audiences option I don't lose the other
  // audience's selections
  const newCheckboxSelections = areAllItemsSelected
    ? onlyItemsFromOtherAudience
    : [...onlyItemsFromOtherAudience, ...allOfSelectedAudience];

  setStateFn({
    variables: {
      checkboxSelections: newCheckboxSelections,
    },
  });

  return newCheckboxSelections;
};
