export default function utcDateToMidnightLocal(d: Date): Date {
  if (d == null) {
    return null;
  }

  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
  );
};
