export const shortDateFormatter = date => {
  const shortDate = date
    .toDateString()
    .split(' ')
    .slice(1);

  return shortDate
    .reduce(
      (acc, curr, idx) =>
        idx === 1 ? acc.concat(`${curr},`) : acc.concat(curr),
      []
    )
    .join(' ');
};

export const getDateString = (date, dateOptions) => {
  const dateObject = new Date(date);
  return `${dateObject.toLocaleDateString('en-US', dateOptions)}`;
};

export const dehyphenateAndCapitalize = string =>
  string
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const splitCapsWords = string => string.split(/(?=[A-Z])/).join(' ');

export const getFurthestCompletedTab = campaign => {
  const campaignTruthyValues = {};
  Object.keys(campaign).forEach(key => {
    if (campaign[key]) {
      campaignTruthyValues[key] = campaign[key];
    }
  });
  const completedSteps = Object.keys(campaignTruthyValues);
  const flyerTitle = campaign && campaign.title;

  if (flyerTitle === 'Untitled') return 'prepare/preview';

  // Note: targetDistricts not rehydrated fast enough to determine delivery route. Unable to determine when to send user to delivery route. When a user edits an existing campaign, the furthest tab we can send them to is details.
  // if (
  //   (campaignTruthyValues.targetAudiences &&
  //     campaignTruthyValues.targetAudiences.length > 0) ||
  //   ((completedSteps.includes(
  //     'title' && 'participationFee' && 'eventLocation' && 'primaryCategory'
  //   ) &&
  //     (campaign.isSignupRequired && !!campaign.deadlineDate)) ||
  //     !campaign.isSignupRequired)
  // )
  //   return 'delivery';

  // if (
  //   campaignTruthyValues.targetAudiences &&
  //   campaignTruthyValues.targetAudiences.length > 0
  // )
  //   return 'delivery';

  if (
    completedSteps.includes(
      'title' ||
        'participationFee' ||
        'eventLocation' ||
        'deadlineDate' ||
        'startDate' ||
        'endDate' ||
        'primaryCategory' ||
        'primaryCallToAction' ||
        'secondaryCallToAction'
    )
  )
    return 'details';
  return 'prepare/preview';
};

export const convertGradeToNumber = (val, gradesArr) =>
  gradesArr.find(grade => grade.value === val).label;

export const convertGradeToAge = (val, agesArr) =>
  agesArr.find(age => age.value === val).label;
