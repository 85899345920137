import * as React from 'react';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import { Location } from 'react-router';

type Props = {
   location: Location,
   scrollToRef: React.Ref<any>,
   children: React.ReactNode,
};

export class ScrollToTop extends React.Component<Props> {
  componentDidMount() {
    const { scrollToRef } = this.props;

    const current = get(scrollToRef, 'current');
    if (current) current.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps: Object) {
    const { location, scrollToRef } = this.props;

    if (location !== prevProps.location) {
      const current = get(scrollToRef, 'current');
      if (current) current.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default withRouter(ScrollToTop);
