import * as React from 'react';
import { css, cx } from 'react-emotion';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { components, elements, colors } from '@peachjar/components';
import { grades, gradeOrdinal, gradeFilterDefault } from '../../../../../../../../shared/constants';
import GradeLevels from '@peachjar/school-finder-api/dist/api/GradeLevels';
import { findIndex, findLastIndex } from 'lodash';
const { Dropdown } = components;
const {
  typography: { Paragraph, Note },
} = elements;

export type GradeFilterType = {
  min: GradeLevels,
  max: GradeLevels,
};

export const handleMinGradeFilterChange = (setGradeFilter, max, newMin) => {
  if (gradeOrdinal[max] >= gradeOrdinal[newMin]) {
    setGradeFilter({ min: newMin, max });
  } else {
    setGradeFilter({ min: max, max: newMin });
  }
};

export const handleMaxGradeFilterChange = (setGradeFilter, min, newMax) => {
  if (gradeOrdinal[newMax] >= gradeOrdinal[min]) {
    setGradeFilter({ min, max: newMax });
  } else {
    setGradeFilter({ min: newMax, max: min });
  }
};

const Filter = ({ gradeFilter, setGradeFilter, classes, togglePopper }) => {
  const { min, max } = gradeFilter;

  // Default is now KG to 12
  const areGradeFiltersDefault = min === gradeFilterDefault.min && max === gradeFilterDefault.max;

  return (
    <div className={styles.container}>
      <div className={styles.innerPadding}>
        <div className={cx(styles.wrapper, 'mt-2')}>
          <Dropdown
            label="Grade"
            options={grades}
            width="40%"
            name="minGradeLevelFilter"
            value={min || ''}
            onChange={event => {
                const newMin = event.target.value;
                handleMinGradeFilterChange(setGradeFilter, max, newMin);
              }
            }

          />
          <Paragraph>to</Paragraph>
          <Dropdown
            label="Grade"
            options={grades}
            width="40%"
            name="maxGradeLevelFilter"
            value={max || ''}
            onChange={event => {
              const newMax = event.target.value;
              handleMinGradeFilterChange(setGradeFilter, min, newMax);
            }
          }
          />
        </div>
        <div className="mt-4">
          <Note muted>
            <strong>Early Child Ed.</strong> grades ECE &ndash; KG (ages 0 &ndash;
            4)
            <br />
            <strong>Elementary</strong> grades KG &ndash; 8 (ages 4 &ndash;
            15)
            <br />
            <strong>Middle</strong> grades 5 &ndash; 9 (ages 9 &ndash; 16)
            <br />
            <strong>High</strong> grades 7 &ndash; 12 (ages 11 &ndash; 18&#43;)
            <br />
            <strong>Continuing Ed.</strong> grades CE (ages 18&#43;)
            <br />
          </Note>
        </div>
        <div className="mt-2">
          <Note className={styles.block} muted>
            Common grade span configurations can vary depending on the district.
          </Note>
        </div>
      </div>
      <div
        className={
          areGradeFiltersDefault
            ? styles.buttonsWrapperSingle
            : styles.buttonsWrapperDouble
        }
      >
        {!areGradeFiltersDefault && (
          <Button
            className={classes.button}
            onClick={() => setGradeFilter({ min: gradeFilterDefault.min, max: gradeFilterDefault.max })}
          >
            <Note className={styles.buttonText}>Reset</Note>
          </Button>
        )}
        <Button
          className={classes.button}
          onClick={event => togglePopper(event)}
        >
          <Note className={styles.buttonText}>Done</Note>
        </Button>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    width: 319px;
    padding: 24px 8px 8px 8px;
  `,
  wrapper: css`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  `,
  innerPadding: css`
    padding: 16px;
  `,
  buttonsWrapperSingle: css`
    display: flex;
    justify-content: flex-end;
  `,
  buttonsWrapperDouble: css`
    display: flex;
    justify-content: space-between;
  `,
  buttonText: css`
    color: ${colors.jungle};
  `,
  copy: css`
    color: #9c9c9c;
  `,
  block: css`
    display: block;
  `,
};

const muiStyles = () => ({
  button: {
    textTransform: 'none',
    height: '32px',
    width: '64px',
  },
});

export default withStyles(muiStyles)(Filter);
