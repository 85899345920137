import { capitalize } from 'lodash';
import { AudienceFilterType } from './AudienceFilter';
import AudienceType from '@peachjar/school-finder-api/dist/api/AudienceType';

export const getAudienceFilterButtonLabel = (
  audienceFilter: AudienceFilterType
) => {
  const totalFiltersAvailable = Object.keys(audienceFilter).length;
  const numberOfActiveFilters = Object.keys(audienceFilter).filter((filterType) => audienceFilter[filterType]).length;

  if (numberOfActiveFilters === totalFiltersAvailable) {
    return 'Audience: All';
  } else if (numberOfActiveFilters === 0) {
    return 'Audience';
  } else if (numberOfActiveFilters === 1) {
    const currentFilterLabel = Object.keys(audienceFilter).find(
      key => audienceFilter[key] === true
    );

    if (currentFilterLabel === AudienceType.Staff) {
      return 'Audience: Staff';
    } else if (currentFilterLabel === AudienceType.Parents) {
      return 'Audience: Parents';
    }
  }
};
