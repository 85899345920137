import React from 'react';
import { elements, InfoTooltip } from '@peachjar/components';
import { css } from 'react-emotion';
import FlyerTitle from './FlyerTitle';
import EventLocation from './EventLocation';
import { tooltipCopy } from '../constants';
import { RolesContext } from '../../../../../_app/components/RoleCheck';
import ProvideDetailsFormFragments from './gql/ProvideDetailsFormFragments.graphql';

const { Headline2 } = elements.typography;

export default class ProvideDetailsForm extends React.PureComponent {
  static fragments;

  static contextType = RolesContext;

  state = {};

  handleIsValid = (name, isValid) => {
    const { performValidation } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    if (this.state[name] !== isValid) {
      this.setState({ [name]: isValid }, () => {
        performValidation({
          target: {
            name: 'provideDetails',
            value: this.state,
          },
        });
      });
    }
  };

  render() {
    const {
      flyerTitle,
      participationFee,
      eventLocation,
      setDetails,
      remoteCampaign,
      shouldPerformDisabledClickValidation,
    } = this.props;

    const { uploaderLevel } = this.context;
    const isOrg = uploaderLevel === 'org';
    const isDistrictOrSchool =
      uploaderLevel === 'district' || uploaderLevel === 'school';

    return (
      <>
        <div>
          <Headline2
            className={css`
              line-height: 1;
            `}
          >
            Provide Basic Info
          </Headline2>
        </div>
        <div className={`${css({ marginRight: '40px' })} mt-4`}>
          <FlyerTitle
            name="flyerTitle"
            flyerTitle={flyerTitle}
            remoteFlyerTitle={remoteCampaign && remoteCampaign.title}
            setDetails={setDetails}
            handleIsValid={this.handleIsValid}
            shouldPerformDisabledClickValidation={
              shouldPerformDisabledClickValidation
            }
          />
        </div>
        <div className="layout-row layout-align-start-end mt-3">
          <div className="flex-auto">
            <EventLocation
              name="eventLocation"
              eventLocation={eventLocation}
              setDetails={setDetails}
              handleIsValid={this.handleIsValid}
            />
          </div>
          <div className="flex-none">
            <InfoTooltip
              dataTestId="tooltip-eventLocation"
              content={tooltipCopy.eventLocation}
            />
          </div>
        </div>
      </>
    );
  }
}

ProvideDetailsForm.fragments = ProvideDetailsFormFragments;
