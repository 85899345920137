import { PARENT_APP_URL } from '../constants';

const generatePreviewFlyerURL = ({ sod, flyerId, districtId, schoolId }) => {
  if (sod && sod.toLowerCase() === 'district') {
    return `${PARENT_APP_URL}/flyers/${flyerId}/districts/${districtId}`;
  } else {
    return `${PARENT_APP_URL}/flyers/${flyerId}/schools/${schoolId}`;
  }
};

export default generatePreviewFlyerURL;
