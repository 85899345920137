import gql from 'graphql-tag';

// Defaults

export const summaryDefaults = {
  campaignSummary: {
    __typename: 'CampaignSummary',
    messageToApprover: '',
    legacyFlyerId: 0,
    formValidation: {
      __typename: 'CampaignSummaryFormValidation',
      isFormValid: true,
    },
  },
};

// Queries
/* eslint-disable */
export const SUMMARY_QUERY = gql`
  query campaignSummary {
    campaignSummary @client {
      __typename
      legacyFlyerId
      messageToApprover
      formValidation {
        __typename
        isFormValid
      }
    }
  }
`;

export const GET_CAMPAIGN_CALL_TO_ACTIONS = gql`
  query getCampaignCallToActions($campaignId: String!) {
    campaignById(campaignId: $campaignId) {
      id
      primaryCallToAction {
        id
        type
        data
      }
      secondaryCallToAction {
        id
        type
        data
      }
    }
  }
`;

export const SET_SUMMARY_CACHE_MUTATION = gql`
  mutation setSummary($name: String!, $value: String!) {
    setSummary(name: $name, value: $value) @client
  }
`;

export const REHYDRATE_SUMMARY_CACHE = gql`
  mutation rehydrateSummaryCache(
    $messageToApprover: String
    $legacyFlyerId: Int
  ) {
    rehydrateSummaryCache(
      messageToApprover: $messageToApprover
      legacyFlyerId: $legacyFlyerId
    ) @client
  }
`;

export const HANDLE_CAMPAIGN_SUMMARY_FORM_VALIDATION_MUTATION = gql`
  mutation handleCampaignSummaryFormValidation(
    $formValidation: CampaignSummaryFormValidation
  ) {
    handleCampaignSummaryFormValidation(formValidation: $formValidation) @client
  }
`;
/* eslint-enable */
// Trigger Functions

export const setSummary = (_obj, { name, value }, { cache }) => {
  cache.writeQuery({
    query: SUMMARY_QUERY,
    data: {
      campaignSummary: {
        __typename: 'CampaignSummary',
        [name]: value,
      },
    },
  });
  return null;
};

export const rehydrateSummaryCache = (
  _obj,
  { messageToApprover, legacyFlyerId },
  { cache }
) => {
  cache.writeQuery({
    query: SUMMARY_QUERY,
    data: {
      campaignSummary: {
        __typename: 'CampaignSummary',
        messageToApprover,
        legacyFlyerId,
      },
    },
  });
  return null;
};

export const handleCampaignSummaryFormValidation = (
  _obj,
  { formValidation },
  { cache }
) => {
  cache.writeQuery({
    query: SUMMARY_QUERY,
    data: {
      campaignSummary: {
        __typename: 'CampaignSummaryFormValidation',
        formValidation,
      },
    },
  });
  return null;
};

// Resolvers

export const summaryResolvers = {
  setSummary,
  rehydrateSummaryCache,
  handleCampaignSummaryFormValidation,
};
