import React, { useState } from 'react';
import { css, cx } from 'react-emotion';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { components, elements, colors } from '@peachjar/components';
import { intersection, range } from 'lodash';
import {
  grades as gradeLabelValues,
  gradeOrdinal,
  ordinalGrades,
} from '../../../../../shared/constants';
import GradeLevels from '@peachjar/school-finder-api/dist/api/GradeLevels'
import { get } from 'lodash';
import { getOnlyItemsFromOtherAudience } from './utils';

const { Dropdown } = components;
const {
  typography: { Paragraph, Note },
} = elements;

type minMaxObject = { min: string, max: string };

type Props = {
  tempMinMax: minMaxObject,
  setTempMinMax: (args: minMaxObject) => void,
  checkboxSelections: object[],
  classes: any,
  districtMax: string,
  districtMin: string,
  districts: any[],
  gradeMinMax: minMaxObject,
  selectedAudience: string,
  setCheckboxSelections: (args: object) => void,
  setGradeMinMax: (args: minMaxObject) => void,
  setIsSingleCheckboxAltered: (args: any) => void,
  isSingleCheckboxAltered: boolean,
  togglePopper: (args: any) => void,
};

const SODSchoolFilter = ({
  tempMinMax,
  setTempMinMax,
  checkboxSelections,
  classes,
  districtMax,
  districtMin,
  districts,
  gradeMinMax,
  selectedAudience,
  setCheckboxSelections,
  setGradeMinMax,
  setIsSingleCheckboxAltered,
  isSingleCheckboxAltered,
  togglePopper,
}: Props) => {
  const { min, max }: minMaxObject = gradeMinMax;

  const areGradeFiltersDefault: boolean = !min && !max;

  const schools: any[] = get(districts, `0.audiences.${selectedAudience}`, []);

  const minMaxGradeLabelValues: Array<{label: string, value: string}> = gradeLabelValues.filter(lv => {
    return (
      gradeOrdinal[lv.value] >= gradeOrdinal[districtMin] &&
      gradeOrdinal[lv.value] <= gradeOrdinal[districtMax]
    );
  });

  function updateGradeLevelSelection(min, max) {
    const gradesSelected = range(gradeOrdinal[min] - 1, gradeOrdinal[max] + 1);
    const newSelection = schools.filter(school => {
      const schoolRange = range(gradeOrdinal[school.lowestGradeOffered], gradeOrdinal[school.highestGradeOffered]);
      const includeSchool =
        (school.lowestGradeOffered === GradeLevels.N && school.highestGradeOffered === GradeLevels.N) ||
        intersection(gradesSelected, schoolRange).length > 0;
      return includeSchool;
    });

    const onlyItemsFromOtherAudience = getOnlyItemsFromOtherAudience(checkboxSelections, selectedAudience, districts[0]);
    setCheckboxSelections({ variables: { checkboxSelections: [...newSelection, ...onlyItemsFromOtherAudience] } });
  }

  return (
    <div className={filterStyles.container}>
      <div className={filterStyles.innerPadding}>
        <div className={cx(filterStyles.wrapper, 'mt-2')}>
          <Dropdown
            label="Grade"
            options={minMaxGradeLabelValues}
            width="40%"
            name="minGradeLevelFilter"
            value={min || ''}
            onChange={event => setGradeMinMax({ min: event.target.value, max })}
          />
          <Paragraph>to</Paragraph>
          <Dropdown
            label="Grade"
            options={minMaxGradeLabelValues}
            width="40%"
            name="maxGradeLevelFilter"
            value={max || ''}
            onChange={event => setGradeMinMax({ min, max: event.target.value })}
          />
        </div>
        <div className="mt-4">
          <Note className={filterStyles.copy}>
            {gradeOrdinal['ECE'] >= gradeOrdinal[districtMin] &&
              gradeOrdinal['ECE'] <= gradeOrdinal[districtMax] && (
                <>
                  <strong>ECE</strong> Early Childhood Education
                  <br />
                </>
              )}
            {gradeOrdinal['PK'] >= gradeOrdinal[districtMin] &&
              gradeOrdinal['PK'] <= gradeOrdinal[districtMax] && (
                <>
                  <strong>PK</strong> Pre - Kindergarten
                  <br />
                </>
              )}
            {gradeOrdinal['KG'] >= gradeOrdinal[districtMin] &&
              gradeOrdinal['KG'] <= gradeOrdinal[districtMax] && (
                <>
                  <strong>KG</strong> Kindergarten
                  <br />
                </>
              )}
            {gradeOrdinal['CE'] >= gradeOrdinal[districtMin] &&
              gradeOrdinal['CE'] <= gradeOrdinal[districtMax] && (
                <>
                  <strong>CE</strong> Continuing Education
                  <br />
                </>
              )}
          </Note>
        </div>
      </div>
      <div
        className={
          areGradeFiltersDefault
            ? filterStyles.buttonsWrapperSingle
            : filterStyles.buttonsWrapperDouble
        }
      >
        {!areGradeFiltersDefault && (
          <Button
            className={classes.button}
            onClick={() => {
              setGradeMinMax(tempMinMax);
              togglePopper(event);
            }}
          >
            <Note className={filterStyles.buttonText}>Cancel</Note>
          </Button>
        )}
        <Button
          className={classes.button}
          onClick={event => {
            function updateMinMaxState(min, max) {
              setTempMinMax({ min: min, max: max });
              setGradeMinMax({ min: min, max: max });
              updateGradeLevelSelection(min, max);
            }

            if (gradeOrdinal[max] < gradeOrdinal[min]) {
              updateMinMaxState(max, min);
            } else if (min && max) {
              updateMinMaxState(min, max);
            } else if (min && !max) {
              updateMinMaxState(min, min);
            } else if (!min && max) {
              updateMinMaxState(max, max);
            }

            setIsSingleCheckboxAltered(false);
            togglePopper(event);
          }}
        >
          <Note className={filterStyles.buttonText}>Select</Note>
        </Button>
      </div>
    </div>
  );
};

const filterStyles: any = {
  container: css`
    width: 319px;
    padding: 0px 8px 8px 8px;
  `,
  wrapper: css`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  `,
  innerPadding: css`
    padding: 16px;
  `,
  buttonsWrapperSingle: css`
    display: flex;
    justify-content: flex-end;
  `,
  buttonsWrapperDouble: css`
    display: flex;
    justify-content: space-between;
  `,
  buttonText: css`
    color: ${colors.jungle};
  `,
  copy: css`
    color: #9c9c9c;
  `,
};

const filterMUIStyles: () => void = () => ({
  button: {
    textTransform: 'none',
    height: '32px',
    width: '64px',
  },
});

export default withStyles(filterMUIStyles)(SODSchoolFilter);
