import { graphql, compose } from 'react-apollo';
import { DETAILS_QUERY } from '../Details/gql/Details.graphql';
import { DELIVERY_QUERY } from '../Delivery/gql/Delivery.graphql';
import {
  SUMMARY_QUERY,
  SET_SUMMARY_CACHE_MUTATION,
  HANDLE_CAMPAIGN_SUMMARY_FORM_VALIDATION_MUTATION,
} from './gql/Summary.graphql';
import { detailsConfig } from '../Details/index';
import { deliveryConfig } from '../Delivery';
import Summary from './components/Summary';

const summaryConfig = {
  props: ({ ownProps, data }) => {
    const { loading, campaignSummary } = data;

    if (loading) {
      return {
        ...ownProps,
        loadingSummary: loading,
      };
    }

    return {
      ...ownProps,
      loadingSummary: loading,
      messageToApprover: campaignSummary.messageToApprover,
      legacyFlyerId: campaignSummary.legacyFlyerId,
    };
  },
};

export default compose(
  graphql(DETAILS_QUERY, detailsConfig),
  graphql(DELIVERY_QUERY, deliveryConfig),
  graphql(SUMMARY_QUERY, summaryConfig),
  graphql(SET_SUMMARY_CACHE_MUTATION, { name: 'setSummary' }),
  graphql(HANDLE_CAMPAIGN_SUMMARY_FORM_VALIDATION_MUTATION, {
    name: 'handleCampaignSummaryFormValidation',
  })
)(Summary);
