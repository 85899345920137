import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { components } from '@peachjar/components';
import { css } from 'react-emotion';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { get } from 'lodash';
import ExitProcessMode from './components/ExitProcessMode';
import FlyerOrderTray from '../../../_app/components/FlyerOrderSummaryContainer';
import config from '../../../config';

const { Brandbar: PJBrandbar } = components;
const { DASHBOARD_URL } = config;

const classNames = {
  brandbar: css`
    z-index: 50;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
  `,
  brandbarContent: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  `,
  innerPillStyles: css`
    display: flex;
    margin: auto;
  `,
  pillStyles: css`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-self: center;
    font-family: ProximaNova-Regular;
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
  `,
  appType: css`
    font-size: 13px;
    font-family: 'Proxima Nova', Helvetica, sans-serif;
    color: #fff;
    padding: 19px 0 0 16px;
  `,
};

const ProcessModeBar = ({
  campaigns,
  flags,
  communityFreeConfig,
  uploaderLevel = '',
}) => {
  const [renderTray, setRenderTray] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const { org_uploader, managed_services } = flags;
  // todo: move uploaderLevels out to enums
  const isOrg = uploaderLevel === 'org';
  const orgUploaderFlagEnabled = org_uploader === 1;
  const managedServicesFlagEnabled = managed_services === 1;
  const { isCommunityFree } = communityFreeConfig;

  useEffect(() => {
    const deliveryRouteMatch = matchPath(window.location.pathname, {
      path: '/campaigns/create-campaign/:campaignId/delivery',
    });
    const summaryRouteMatch = matchPath(window.location.pathname, {
      path: '/campaigns/create-campaign/:campaignId/summary',
    });

    setCampaignId(
        get(deliveryRouteMatch, 'params.campaignId') ||
        get(summaryRouteMatch, 'params.campaignId', null)
    );

    if (deliveryRouteMatch || summaryRouteMatch) {
      setRenderTray(true);
      return;
    }

    setRenderTray(false);

    // eslint-disable-next-line consistent-return
    return () => {
      setRenderTray(false);
    };
  }, [window.location.pathname]);

  return (
    <>
      <PJBrandbar logoURI={DASHBOARD_URL} className={classNames.brandbar} dark>
        {isCommunityFree && (
          <span className={classNames.appType}>Community Free Application</span>
        )}

        <div className={classNames.brandbarContent}>
          <ExitProcessMode
            campaigns={campaigns}
            uploaderLevel={uploaderLevel}
          />
        </div>
      </PJBrandbar>
      {!managedServicesFlagEnabled &&
        orgUploaderFlagEnabled &&
        isOrg &&
        renderTray && <FlyerOrderTray campaignId={campaignId} />}
    </>
  );
};

ProcessModeBar.fragments = {
  sodUser: gql`
    fragment Brandbar_sodUser on SodUser {
      id
      permission {
        adminLevel
        approverLevel
        uploaderLevel
      }
      firstName
      email
    }
  `,
};

export default connect(
  state => ({ flags: state.flags }),
  null
)(ProcessModeBar);
