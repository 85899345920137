import React from 'react';
import { css } from 'emotion';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { elements, components } from '@peachjar/components';
import Paper from '@material-ui/core/Paper';
import SubappHeader from '../_app/components/SubappHeader';
import HelpCard from '../../src/components/HelpCard';
import DigitalFlyerSuccess from '../assets/digital-flyer-success.svg';
import SendPostFlyer from '../assets/send-post-flyer.svg';

const { Headline2, Paragraph } = elements.typography;
const { ButtonSecondarySmall } = components.Buttons;

const Help = ({ classes }) => (
  <div>
    <SubappHeader>Help</SubappHeader>
    <Paper className={`${styles.container} ${classes.elevation2}`}>
      <div className={styles.subContainer}>
        <Headline2>Training Guides</Headline2>
        <Paragraph className={styles.paragraph}>
          Review the following guides to help you get the most out of Peachjar.
        </Paragraph>

        <div className={styles.guideContainer}>
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <HelpCard
                link="https://dev-portal-webapp.s3-us-west-2.amazonaws.com/Guide+to+Digital+Flyer+Success+for+Program+Providers.pdf"
                icon={DigitalFlyerSuccess}
                headline="Guide to Digital Flyer Success"
                className={styles.newFlyerButton}
                dataTestId="click-UploadFlyer-help-orgs"
              />
            </Grid>
            <Grid item xs={4}>
              <HelpCard
                link="https://info.peachjar.com/community-orgs/how-to-send-flyers"
                rel="noopener"
                icon={SendPostFlyer}
                headline="How to Send & Post a Flyer"
                className={styles.newFlyerButton}
                dataTestId="click-send-post-help-orgs"
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={styles.subContainer}>
        <Headline2>FAQs</Headline2>

        <Paragraph className={styles.paragraph}>
          Need information? Check out our frequently asked questions.
        </Paragraph>
        <ButtonSecondarySmall
          className={styles.button}
          onClick={function openFAQ() {
            const win = window.open('https://peachjar.com/faq', '_blank');
            win.focus();
          }}
          rel="noopener"
        >
          View FAQs
        </ButtonSecondarySmall>
      </div>
      <div className={styles.subContainer}>
        <Headline2>Contact Us</Headline2>
        <Paragraph className={styles.paragraph}>
          Get in touch and let us know how we can help!
        </Paragraph>

        <ButtonSecondarySmall
          className={styles.button}
          onClick={function openContact() {
            const win = window.open(
              'https://peachjar.com/contact-us',
              '_blank'
            );
            win.focus();
          }}
          rel="noopener"
        >
          Contact Peachjar
        </ButtonSecondarySmall>
      </div>
    </Paper>
  </div>
);

Help.fragments = {
  sodUser: gql`
    fragment Help_sodUser on SodUser {
      id
    }
  `,
};

export const styles = {
  button: css`
    display: flex;
    align-self: flex-start;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    box-shadow: none;

    padding: 64px;
    margin-bottom: 64px;
    border-radius: 3px;
  `,
  guideContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 656px; */
  `,
  paragraph: css`
    padding-bottom: 24px;
  `,
  subContainer: css`
    display: flex;
    flex-direction: column;
    padding-bottom: 56px;
  `,
  superContainer: css`
    padding: 64px;
  `,
};

const materialUIstyles = {
  elevation2: {
    boxShadow: 'none',
  },
};

export default withStyles(materialUIstyles)(Help);
