import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setCampaignId,
  setLegacyFlyerId,
  setCampaignType,
  setCommunityFreeAppId,
  COMMUNITY_FREE_TYPE,
  STANDARD_TYPE,
  CampaignType,
} from '../../campaigns.duck';
import bffClient from '../../../_app/apollo/portalBFF.apolloClient';
import FETCH_CAMPAIGN_TYPE_BY_ID from '../gql/FetchCampaignTypeById.graphql';
import GET_MY_COMMUNITY_FREE_INFO from '../../Overview/gql/getMyCommunityFreeInfo.graphql';
import { APP_ROUTES } from '../../../_app/constants';

const MapCampaignType = ({
  campaignId,
  currentCampaignId,
  campaignType,
  applicationId,
  actions,
  children,
  history,
}) => {
  const { aid } = queryString.parse(window.location.search);

  const APPLICATION_ID = aid || applicationId;

  useEffect(() => {
    const handleCampaignType = async () => {
      if (campaignType !== null && campaignId === currentCampaignId) return;

      if (APPLICATION_ID) {
        actions.setCampaignType(COMMUNITY_FREE_TYPE);
        actions.setCommunityFreeAppId(APPLICATION_ID);
        return;
      }

      if (campaignId && campaignId === 'new') {
        actions.setCampaignType(STANDARD_TYPE);
        return;
      }

      if (campaignId && campaignId !== 'new') {
        const response = await bffClient.query({
          query: FETCH_CAMPAIGN_TYPE_BY_ID,
          variables: {
            campaignId,
          },
        });

        const applicationType: CampaignType = get(
          response,
          'data.campaignById.type'
        );

        const appId = get(response, 'data.campaignById.application.id', null);
        const legacyFlyerId = get(
          response,
          'data.campaignById.legacyFlyerId',
          null
        );

        if (appId) {
          actions.setCommunityFreeAppId(appId);
        }

        actions.setCampaignId(campaignId);
        actions.setLegacyFlyerId(legacyFlyerId);
        actions.setCampaignType(applicationType);
      }
    };

    handleCampaignType();
  }, [campaignId]);

  useEffect(() => {
    const handleCommunityFreeAvailability = async () => {
      if (campaignType === COMMUNITY_FREE_TYPE) {
        const commFreeInfo = await bffClient.query({
          query: GET_MY_COMMUNITY_FREE_INFO,
          fetchPolicy: 'network-only',
        });

        const isCommFreeProgramAvailableForUser = get(
          commFreeInfo,
          'data.myCommFreeInfo.available',
          false
        );

        if (!isCommFreeProgramAvailableForUser) {
          history.push(`${APP_ROUTES.campaigns}?cf=0`);
        }
      }
    };

    handleCommunityFreeAvailability();
  }, [campaignType]);

  return <>{children({ campaignType, applicationId: APPLICATION_ID })}</>;
};

export default connect(
  state => ({
    campaignType: state.campaigns.communityFree.type,
    currentCampaignId: state.campaigns.communityFree.currentCampaignId,
    applicationId: state.campaigns.communityFree.applicationId,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        setCampaignId,
        setLegacyFlyerId,
        setCampaignType,
        setCommunityFreeAppId,
      },
      dispatch
    ),
  })
)(withRouter(MapCampaignType));
