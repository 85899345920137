import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { graphql, compose } from 'react-apollo';
import { matchPath } from 'react-router';
import idx from 'idx';
import { css } from 'emotion';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { colors } from '@peachjar/components';
import { apolloClient as bffClient } from '../apollo/portalBFF.apolloClient';
import { GET_FLYER_PURCHASE_QUOTE } from '../queries/deals';
import DealsContext from '../context/DealsContext';
import FlyerOrderSummary from './FlyerOrderSummary';
import LoadingSpinner from '../../components/LoadingSpinner';
import {
  DELIVERY_QUERY,
  SET_CHECKBOX_SELECTIONS_MUTATION,
} from '../../campaigns/Create-Campaign/Delivery/gql/Delivery.graphql';
import { mapCheckboxSelections } from '../../campaigns/Create-Campaign/gql/utils';
import {
  setPostOnlyForSchool,
  removePostOnlyForSchool,
} from '../../deals/redux/deals.duck';
import { DeliveryModes } from '../../deals/deals.types';
import audienceFactory from '../../deals/utils/audienceFactory';
import SAVE_CAMPAIGN_MUTATION from '../../campaigns/Create-Campaign/gql/SaveCampaignMutation.graphql';
import get from 'lodash/get'

type Props = {
  saveCampaign: () => void;
  postOnlySelections: string[];
  setPostOnlySelections: (scholId: string) => void;
  removePostOnlySelection: (schoolId: string) => void;
  campaignId: string | null;
  campaignType: string,
};

const FlyerOrderSummaryContainer: React.FunctionComponent<Props> = ({
  saveCampaign,
  postOnlySelections,
  campaignType,
  setPostOnlySelections,
  removePostOnlySelection,
  campaignId,
}) => {
  const {
    numberOfDistributions,
    schoolDistributionList,
    campaignDetails,
    summaryTrayExpanded,
    setSummaryTray,
    setDealsInFlight,
    updateSchool,
    updateSchoolDistributionList,
    setSchoolDistributionList,
  } = useContext(DealsContext);
  const [isHovering, setIsHovering] = useState(false);

  const { loading: loadingQuote, error, data: quoteData } = useQuery(
    GET_FLYER_PURCHASE_QUOTE,
    {
      client: bffClient,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          campaignId: campaignType === 'community_free' ? campaignId : undefined,
          promoCodes: [''],
          disableRules: [''],
          numberOfDistributions,
          numberOfReminders: 0,
          audiences: audienceFactory(
            schoolDistributionList,
            postOnlySelections
          ),
        },
      },
    }
  );

  const [
    setCheckboxSelections,
    { loading: checkboxesLoading, error: checkboxesError },
  ] = useMutation(SET_CHECKBOX_SELECTIONS_MUTATION, {
    client: bffClient,
  });

  const handleSaveCampaign = schoolSelections => {
    saveCampaign({
      variables: {
        ...campaignDetails.variables,
        campaignDeliverySettings: {
          targetAudiences: mapCheckboxSelections(schoolSelections),
        },
      },
    });
  };

  const handlePostOnlySelection = (
    schoolId: string,
    isChecked: boolean,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const setPostOnly = isChecked
      ? setPostOnlySelections
      : removePostOnlySelection;

    if (event.target) {
      const schoolMatch = schoolDistributionList.find(
        s => s.schoolId === parseInt(schoolId, 10)
      );

      setPostOnly(schoolId);
      updateSchool({ ...schoolMatch, isPostOnlyChecked: isChecked });
    }
  };

  const handleSchoolRemoval = async id => {
    const isSummaryPage = matchPath(location.pathname, {
      path: '/campaigns/create-campaign/:campaignId/summary',
    });

    setDealsInFlight(true);
    const result = await bffClient.query({
      query: DELIVERY_QUERY,
    });

    const { data } = result;
    const schoolIdNumber = Number(id);
    const cachedSchoolSelection = idx(
      data,
      _ => _.campaignDelivery.checkboxSelections
    );
    const filteredDistributionList = schoolDistributionList.filter(
      ({ schoolId }) => schoolId !== schoolIdNumber
    );
    const filteredSchoolSelection = cachedSchoolSelection.filter(
      ({ schoolId }) => schoolId !== schoolIdNumber
    );

    if (isSummaryPage) {
      handleSaveCampaign(filteredSchoolSelection);
    }

    removePostOnlySelection(id);
    updateSchoolDistributionList(filteredDistributionList);
    setCheckboxSelections({
      variables: {
        checkboxSelections: filteredSchoolSelection,
      },
    });

    setDealsInFlight(false);
  };

  const handleClickAway = () => setSummaryTray(false);
  const handleClickGrip = () => setSummaryTray(!summaryTrayExpanded);

  const mergedProps = {
    isHovering,
    setIsHovering,
    quote: quoteData,
    numberOfDistributions,
    schoolDistributionList,
    postOnlySelections,
    handleSchoolRemoval,
    handlePostOnlySelection,
    panelExpanded: summaryTrayExpanded,
    onPanelExpand: setSummaryTray,
  };

  if (loadingQuote || checkboxesLoading) {
    return (
      <div className={cn.sectionMG}>
        <LoadingSpinner height="72px" />
      </div>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid
        item
        xs={12}
        className={`${cn.sectionMG} ${isHovering ? 'hovering' : ''} ${
          summaryTrayExpanded ? 'expanded' : ''
        }`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <FlyerOrderSummary {...mergedProps} />
        <span className={cn.panelGrip} onClick={handleClickGrip}></span>
      </Grid>
    </ClickAwayListener>
  );
};

const cn = {
  sectionMG: css`
    background-color: ${colors.white};
    width: 100%;
    box-shadow: 0 -4px 10px #333;
    z-index: 1;
    flex-basis: 0 !important;
    position: fixed;
    top: 57px;
    left: 0;
    z-index: 3;

    &.hovering {
      background-color: ${colors.platinum};
    }

    &.expanded {
      background-color: ${colors.white} !important;
    }
  `,
  panelGrip: css`
    width: 38px;
    height: 4px;
    border-radius: 4px;
    background-color: ${colors.silver};
    bottom: 8px;
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
  `,
};

type RootState = {
  deals: {
    postOnly: string[];
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  setPostOnlySelections: (schoolId: string) =>
    dispatch(setPostOnlyForSchool(schoolId)),
  removePostOnlySelection: (schoolId: string) =>
    dispatch(removePostOnlyForSchool(schoolId)),
});

export default connect(
  (state: RootState) => ({
    postOnlySelections: state.deals.postOnly,
    campaignType: get(state, 'campaigns.communityFree.type', 'standard')
  }),
  mapDispatchToProps
)(
  compose(
    graphql(SAVE_CAMPAIGN_MUTATION, {
      name: 'saveCampaign',
      options: {
        client: bffClient,
        awaitRefetchQueries: true,
      },
    })
  )(FlyerOrderSummaryContainer)
);
