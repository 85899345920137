import generateSubappConfig from '../_app/generateSubappConfig';
import ParentsManagement from './ParentsManagement';
import { APP_ROUTES } from '../_app/constants';

const parentsManagementSubappConfig = generateSubappConfig({
  path: APP_ROUTES.parents,
  navItemConfig: {
    label: 'Parents',
  },
  appConfig: {
    Component: ParentsManagement,
  },
});

export default parentsManagementSubappConfig;
