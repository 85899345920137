
import * as React from 'react';
import gql from 'graphql-tag';
import { css, cx } from 'react-emotion';
import idx from 'idx';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { components, colors, elements } from '@peachjar/components';

import {
  SchoolRemovalModalExpandableTableRow_flyerReasons,
} from './__generated__/SchoolRemovalModalExpandableTableRow_flyerReasons';

import Row from './SchoolRemovalModalTableRow';
import StatusIcon from './StatusIcon';
import Checkbox from '../Checkbox';
import getFormattedDate from '../../getFormattedDate';

const { Note } = elements.typography;

const onEnter = (
  callback: Function
): ((e: SyntheticKeyboardEvent<HTMLElement>) => void) => e => {
  if (e.key === 'Enter') {
    callback();
  }
};

// Stateless Helpers

// yep
const TableDivider = ({ className }: { className: string }) => (
  <tr>
    <td colSpan={5}>
      <div className={className} />
    </td>
  </tr>
);

type FlyerReasonNames = {
  [key: string]: string,
}

const { InfoTooltip } = components;
type DeclineReasonProps = {
  flyerReasonNames: FlyerReasonNames,
  declineReason?: string,
  declineReasonExplanation?: string,
};
const DeclineReason = ({
  flyerReasonNames,
  declineReason,
  declineReasonExplanation,
}: DeclineReasonProps) => {
  const defaultDeclineCopy = flyerReasonNames[declineReason];
  const altDeclineCopy = declineReasonExplanation ? 'Details' : null;
  const declineCopy = defaultDeclineCopy || altDeclineCopy;
  return (
    <div className={classNames.verticalCenter}>
      <Note>{declineCopy}</Note>
      {!!declineReasonExplanation && (
        <InfoTooltip
          data-testid="click-declineReasonExplanation-tooltip"
          content={declineReasonExplanation}
        />
      )}
    </div>
  );
};
// End Stateless Helpers

type SchoolRemovalModalExpandableTableRowFragment = {
  id: any,
  status: any,
  schoolName: any,
  deletedReason: any,
  deletedReasonExplanation: any,
  deletedByFirstName: any,
  deletedByLastName: any,
  updated: any,
  history: Array<{
    id: any,
    status: any,
    denialReason: any,
    denialReasonExplanation: any,
    approverFirstName: any,
    approverLastName: any,
    updated: any,
  }>,
  pendingFlyerApprovalSequence: Array<{
    approverUserId: any,
    approverFirstName: any,
    approverLastName: any,
  }>,
};

export type Props = {
   approval: SchoolRemovalModalExpandableTableRowFragment, // TODO (WIP): this should be a generated type
   stageOrUnstageApprovalForRemoval: Function,
   isRowExpanded: boolean,
   isCheckboxSelected: boolean,
   expandOrCollapseRow: Function,
   hasDarkBackground: boolean,
   isDistrictAdmin: boolean,
   flyerReasons: SchoolRemovalModalExpandableTableRow_flyerReasons[],
};

const SchoolRemovalModalExpandableTableRow = (props: Props) => {
  const {
    approval,
    stageOrUnstageApprovalForRemoval,
    isRowExpanded,
    isCheckboxSelected,
    expandOrCollapseRow,
    hasDarkBackground,
    isDistrictAdmin,
    flyerReasons,
  } = props;
  const ArrowIconComponent = isRowExpanded ? ArrowDropUp : ArrowDropDown;

  const id = idx(approval, _ => _.id) || '';
  const schoolName = idx(approval, _ => _.schoolName) || null;
  const isAutoApproved = idx(approval, _ => _.isAutoApproved) || false;
  const realStatus = idx(approval, _ => _.status) || null;
  const updated = idx(approval, _ => _.updated) || null;
  const flyerReasonNames: FlyerReasonNames = flyerReasons.reduce((
    acc: FlyerReasonNames,
    r: SchoolRemovalModalExpandableTableRow_flyerReasons,
  ) => {
    return {
      ...acc,
      [r.code]: r.displayName,
    };
  }, {});
  const deletedReason = idx(approval, _ => _.deletedReason) || null;
  const deletedReasonExplanation =
    idx(approval, _ => _.deletedReasonExplanation) || null;
  const deletedByFirstName = idx(approval, _ => _.deletedByFirstName) || null;
  const deletedByLastName = idx(approval, _ => _.deletedByLastName) || null;
  const wasRemoved = !['approved', 'denied', 'pending'].includes(realStatus);
  const status = wasRemoved ? 'deleted' : realStatus;

  const history = idx(approval, _ => _.history) || [];
  const denialReason = idx(history, _ => _[0].denialReason) || null;
  const denialReasonExplanation =
    idx(history, _ => _[0].denialReasonExplanation) || null;
  const getNameCellContent = () => {
    // only return expandable arrow next to schools that require approval
    if(!isAutoApproved) {
      return (
        <div
          onClick={expandOrCollapseRow}
          onKeyPress={onEnter(expandOrCollapseRow)}
          className={cx(classNames.cursorPointer, classNames.verticalCenter)}
          tabIndex={0}
          role="button"
        >
          <ArrowIconComponent />
          <Note className={classNames.schoolName}>{schoolName}</Note>
        </div>
      )
    }

    return <Note className={classNames.schoolNameNoMargin}>{schoolName}</Note>
  }

  // Earliest dates first. Ideally this an orderby flag on our connection.
  let sortedHistory = [...history].sort(
    (a, b) => (idx(a, _ => _.updated) || 0) - (idx(b, _ => _.updated) || 0)
  );
  if (status === 'deleted') {
    const fakeHistoryItem = {
      id: 'deleted-fake-history-item',
      status,
      denialReason: deletedReason,
      denialReasonExplanation: deletedReasonExplanation,
      approverFirstName: deletedByFirstName,
      approverLastName: deletedByLastName,
      updated,
    };
    sortedHistory = [...sortedHistory, fakeHistoryItem];
  }
  const pendingApprovers =
    idx(approval, _ => _.pendingFlyerApprovalSequence) || [];

  return (
    <>
      <Row
        key={id}
        hasDarkBackground={hasDarkBackground}
        nameCellContent={ getNameCellContent() }
        statusCellContent={<StatusIcon status={status} />}
        dateCellContent={
          updated ? getFormattedDate(updated) : <StatusIcon status="pending" />
        }
        declineReasonCellContent={
          <DeclineReason
            flyerReasonNames={flyerReasonNames}
            declineReason={deletedReason || denialReason}
            declineReasonExplanation={
              deletedReasonExplanation || denialReasonExplanation
            }
          />
        }
        checkboxCellContent={
          wasRemoved ? (
            <React.Fragment>
              <Note muted>Removed</Note>
              <div className={classNames.compressedCheckbox}>
                <Checkbox
                  data-testid={`field-removalCheckbox-${id}`}
                  disabled
                  checked
                />
              </div>
            </React.Fragment>
          ) : (
            <Checkbox
              // if pending or denied, we show disabled, unchecked checkbox
              data-testid={`field-removalCheckbox-${id}`}
              checked={status === 'approved' && isCheckboxSelected}
              onChange={
                status === 'approved'
                  ? stageOrUnstageApprovalForRemoval(id)
                  : null
              }
              value={id}
              disabled={status !== 'approved' || !isDistrictAdmin}
            />
          )
        }
      />
      {isRowExpanded ? (
        <>
          <TableDivider className={classNames.topDivider} />
          {/* history items, including the appended "deleted" fake history */}
          {sortedHistory.map((historyItem, i, items) => {
            const isLastItem = i === items.length - 1;
            const historyId = idx(historyItem, _ => _.id);
            const approverFirstName = idx(
              historyItem,
              _ => _.approverFirstName
            );
            const approverLastName = idx(historyItem, _ => _.approverLastName);
            const historyUpdatedAt = idx(historyItem, _ => _.updated);
            const historyStatus = idx(historyItem, _ => _.status);
            const historyDenialReason = idx(historyItem, _ => _.denialReason);
            const historyDenialReasonExplanation = idx(
              historyItem,
              _ => _.denialReasonExplanation
            );
            // TODO: this logic is different everywhere, fix
            const historyDidRemove = ![
              'approved',
              'denied',
              'pending',
            ].includes(historyStatus);

            const coercedStatus =
              historyStatus === 'pending' ? 'hold' : historyStatus;

            const hasPendingApprovers = pendingApprovers.length > 0;
            const hasMultipleHistoryItems = sortedHistory.length > 1;
            const hasTopDivider =
              isLastItem && hasMultipleHistoryItems && !hasPendingApprovers;
            return (
              <Row
                key={historyId}
                hasTopDivider={hasTopDivider}
                nameCellContent={
                  <Note className={classNames.nameCellContent}>
                    {`${approverFirstName || ''} ${approverLastName || ''}`}
                  </Note>
                }
                statusCellContent={<StatusIcon status={coercedStatus} />}
                dateCellContent={
                  <Note className={classNames.dateCellContent}>
                    {historyUpdatedAt ? (
                      <Note>{getFormattedDate(historyUpdatedAt)}</Note>
                    ) : (
                      <StatusIcon status="pending" />
                    )}
                  </Note>
                }
                declineReasonCellContent={
                  <DeclineReason
                    flyerReasonNames={flyerReasonNames}
                    declineReason={historyDenialReason}
                    declineReasonExplanation={historyDenialReasonExplanation}
                  />
                }
                checkboxCellContent={
                  historyDidRemove ? <Note muted>Removed</Note> : null
                }
                shouldNotShowCheckboxBorder
              />
            );
          })}
          {/* Pending People */}
          {pendingApprovers.map(
            ({ approverFirstName, approverLastName, approverUserId }) => (
              <Row
                key={approverUserId}
                nameCellContent={
                  <Note className={classNames.nameCellContent}>
                    {`${approverFirstName || ''} ${approverLastName || ''}`}
                  </Note>
                }
                statusCellContent={<StatusIcon status="pending" />}
                dateCellContent={<StatusIcon status="pending" />}
              />
            )
          )}
          <TableDivider className={classNames.bottomDivider} />
        </>
      ) : null}
    </>
  );
};

SchoolRemovalModalExpandableTableRow.fragments = {
  approval: gql`
    fragment SchoolRemovalModalExpandableTableRow_approval on FlyerApprovalRequest {
      id
      status
      schoolName
      deletedReason
      deletedReasonExplanation
      deletedByFirstName
      deletedByLastName
      updated
      isAutoApproved
      history {
        # should be a connection to a list of history items, with approver: User
        id
        status
        denialReason
        denialReasonExplanation
        approverFirstName
        approverLastName
        updated
      }
      pendingFlyerApprovalSequence {
        # should be a connection to a list of users
        approverUserId
        approverFirstName
        approverLastName
      }
    }
  `,
  flyerReasons: gql`
    fragment SchoolRemovalModalExpandableTableRow_flyerReasons on FlyerReason {
      code
      displayName
    }
  `,
};

const classNames = {
  verticalCenter: css`
    display: flex;
    align-items: center;
  `,
  dateCellContent: css`
    min-width: 145px;
  `,
  compressedCheckbox: css`
    margin: -10px 0px -5px 0px !important;
  `,
  cursorPointer: css`
    cursor: pointer;
  `,
  nameCellContent: css`
    margin-left: 1.5rem;
  `,
  topDivider: css`
    border-top: 1px solid ${colors.mercury};
    height: 0.5rem;
  `,
  bottomDivider: css`
    border-bottom: 1px solid ${colors.mercury};
    height: 0.5rem;
  `,
  schoolName: css`
    margin-left: 0.5rem;
  `,
  schoolNameNoMargin: css`
    margin-left: 0;
  `
};

export default SchoolRemovalModalExpandableTableRow;
