import React from 'react';
import MutationButton from '../../../../../../components/MutationButton';
import GenericModal from './GenericModal';
import { components } from '@peachjar/components';
const { ButtonFlatLarge } = components.Buttons;

const header = 'Just Checking...';
const getMessage = fileSizeInMB =>
  `Your file is ${fileSizeInMB}MB and may take up to 10 minutes to load. Are you sure you want to continue?`;

type Props = {
  fileSizeInMB: number,
  onConfirm: () => void,
  onCancel: () => void,
  dataTestId: string,
};

const LargeFileConfirmationModal = ({
  fileSizeInMB,
  onCancel,
  onConfirm,
  dataTestId,
}: Props) => (
  <GenericModal
    dataTestId={dataTestId}
    header={header}
    message={getMessage(fileSizeInMB)}
    onCancel={onCancel}
  >
    <ButtonFlatLarge onClick={onConfirm}>Continue</ButtonFlatLarge>
  </GenericModal>
);

export default LargeFileConfirmationModal;
