import React, { useContext } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import DealsContext from '../../../../_app/context/DealsContext';
import isCyberSaleLive from '../../../../helpers/deals/isCyberSaleLive';
import { APP_ROUTES, DEALS_PROMO_CODES } from '../../../../_app/constants';
import AddCreditsModal from '../../../../_app/components/PurchaseModal';
import cyberSaleContent from './cyberSale.json';
import dashboardImg from '../../../../assets/balloons.png';
import dealsImg from '../../../../assets/balloons_deals.png';
import CyberSaleDealsComponent from './CyberSaleDeals.component';
import CyberSaleDashboardComponentCopy from './CyberSaleDashboard.component';

type ModalAction = (component: any, options: any) => void;

type Props = {
  history: {
    [k: string]: any;
  };
  starterKit: boolean;
};

const CyberSaleBannerContainer = ({ history, starterKit }: Props) => {
  const { CYBERSALE20 } = DEALS_PROMO_CODES;
  const { deals: dealsRoute } = APP_ROUTES;
  const { credits, appliedPromoCodes, setAppliedPromoCodes } = useContext(
    DealsContext
  );
  const cyberSalePromoApplied = appliedPromoCodes.includes(CYBERSALE20);
  const isDealsRoute = matchPath(location.pathname, {
    path: dealsRoute,
    exact: true,
    strict: false,
  });
  const imageToRender = isDealsRoute ? dealsImg : dashboardImg;
  const applyPromoCode = () => setAppliedPromoCodes(CYBERSALE20);

  const handleClick = (showModal: ModalAction, closeModal: ModalAction) => {
    if (!!credits) {
      // applyPromoCode();
      history.push(dealsRoute);
    } else {
      showModal(AddCreditsModal, {
        showModal,
        closeModal,
        // applyDeal: applyPromoCode,
      });
    }
  };

  const mergedProps = {
    credits,
    content: starterKit ? (Date.now() > 1733385599000 ? cyberSaleContent.offer4 : cyberSaleContent.offer2) :
      (Date.now() > 1733385599000 ? cyberSaleContent.offer3 : cyberSaleContent.offer1),
    promoApplied: cyberSalePromoApplied,
    bannerImage: imageToRender,
    handleClick,
  };

  if (!isCyberSaleLive()) {
    return null;
  }

  const CyberSaleComponent = isDealsRoute ? CyberSaleDealsComponent : CyberSaleDashboardComponentCopy

  return <CyberSaleComponent {...mergedProps} />;
};

export default withRouter(CyberSaleBannerContainer);
