import React, { Component } from 'react';
import { connect } from 'react-redux';
import { History } from 'react-router-dom';
import { css } from 'react-emotion';
import { elements } from '@peachjar/components';
import { ThunkDispatch } from 'redux-thunk';
import { get, filter, isEmpty, omit, isEqual } from 'lodash';
import AudienceAndSchools from './components/AudienceAndSchools';
import SetDistributions from './components/SetDistributions';
import SaveCampaignDeliveryButton from './components/SaveCampaignDeliveryButton';
import NoStaffList from './components/NoStaffList';
import routePushHandler from '../../shared/routePushHandler';
import DealsContext from '../../../_app/context/DealsContext';
import bffClient from '../../../_app/apollo/portalBFF.apolloClient';
import {
  setTraySchoolListContext,
  setTrayDistributionContext,
} from '../../../helpers/deals';
import {
  gradeFilterDefault,
  DEFAULT_COMM_FREE_SCHOOL_SELECTION_LIMIT,
} from '../../shared/constants';
import DeliveryFragments from './gql/DeliveryFragments.graphql';
import GET_MY_APPLICATION from '../gql/getMyCommFreeInfo.graphql';
import { Flags } from '../../../_app/flagging/flags';
import {
  STANDARD_TYPE,
  COMMUNITY_FREE_TYPE,
  setCommunityFreeSchoolSelectionLimit,
} from '../../campaigns.duck';

const { CircleList } = elements;

const styles = {
  saveButton: css`
    padding-top: 24px;
  `,
};

type School = {
  schoolId?: number;
  districtId: number;
  ncesId?: string;
  name: string;
  audienceType?: string;
  lowestGradeOffered?: string;
  highestGradeOffered?: string;
  latitude: number;
  longitude: number;
};

type Audiences = {
  parents: School[];
  staff: School[];
};

type District = {
  ncesId: string;
  name: string;
  guidelinesUrl?: string;
  audiences: Audiences;
};

type Props = {
  districts: District[];
  selectedAudience: string;
  checkboxSelections: { districts: District[] };
  campaignStartDate: any;
  postExpirationDate: any;
  numberOfDistributions: number;
  uploaderLevel: string;
  postOnlySelections: string[];
  handleCampaignDeliveryFormValidation: ({ variables: Object }) => Promise<any>;
  startDate: Date;
  endDate: Date;
  deadlineDate: Date;
  minGradeLevel: string;
  maxGradeLevel: string;
  eventLocation: string;
  flags: Flags;
  history: History;
  setDeliveryCache: (args: any) => void;
  campaignId: string;
  onSaveFailed: (campaignId: string) => void;
  toggleDistrictGuidelinesConfirmed: () => void;
  areDistrictGuidelinesConfirmed: boolean;
  isCommunityFree: boolean;
  communityFreeSchoolSelectionLimit: number;
};

type State = {
  formValidation: Object;
  isFormValid: boolean;
  shouldPerformDisabledClickValidation: boolean;
};

class Delivery extends Component<Props, State> {
  static fragments: { [key: string]: any };
  static contextType = DealsContext;

  constructor(props) {
    super(props);

    this.state = {
      formValidation: {
        audienceAndSchools: null,
        setDistributions: null,
      },
      isFormValid: false,
      shouldPerformDisabledClickValidation: false,
    };

    this.uploaderLevel = this.props.uploaderLevel;
  }

  async componentDidMount() {
    const {
      numberOfDistributions,
      updateSchoolDistributionList,
      setNumberOfDistributions,
    } = this.context;

    const {
      isCommunityFree,
      applicationId,
      setCommFreeSchoolLimit,
    } = this.props;

    const { org_uploader } = this.props.flags;
    this.org_uploader = org_uploader;

    if (this.uploaderLevel === 'org' && org_uploader === 1) {
      if (isCommunityFree) {
        try {
          const myCommFreeApplication = await bffClient.query({
            query: GET_MY_APPLICATION,
            variables: {
              id: applicationId,
            },
          });

          const myCommFreeSchoolLimit = get(
            myCommFreeApplication,
            'data.getApplication.schoolSelectionLimit',
            DEFAULT_COMM_FREE_SCHOOL_SELECTION_LIMIT
          );

          // limit can be null :(
          const schoolLimit =
            myCommFreeSchoolLimit !== null
              ? myCommFreeSchoolLimit
              : DEFAULT_COMM_FREE_SCHOOL_SELECTION_LIMIT;

          setCommFreeSchoolLimit(schoolLimit);
        } catch (e) {
          setCommunityFreeSchoolSelectionLimit(
            DEFAULT_COMM_FREE_SCHOOL_SELECTION_LIMIT
          );
        }
      }
      setTraySchoolListContext(
        this.props.checkboxSelections,
        {
          updateSchoolDistributionList,
        },
        this.props.postOnlySelections
      );
      setTrayDistributionContext(this.props.numberOfDistributions, {
        numberOfDistributions,
        setNumberOfDistributions,
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const {
      numberOfDistributions,
      updateSchoolDistributionList,
      setNumberOfDistributions,
    } = this.context;

    const {
      numberOfDistributions: numberOfDistributionsNext,
      checkboxSelections,
    } = nextProps;

    if (this.uploaderLevel === 'org' && this.org_uploader === 1) {
      if (numberOfDistributionsNext !== this.props.numberOfDistributions) {
        setTrayDistributionContext(numberOfDistributionsNext, {
          numberOfDistributions,
          setNumberOfDistributions,
        });
      }

      if (!isEqual(checkboxSelections, this.props.checkboxSelections)) {
        setTraySchoolListContext(
          checkboxSelections,
          {
            updateSchoolDistributionList,
          },
          this.props.postOnlySelections
        );
      }
    }
  }

  handleShouldPerformDisabledClickValidation = (boolean: boolean) => {
    this.setState({ shouldPerformDisabledClickValidation: boolean });
  };

  handleIsValid = (name: string, isValid: boolean) => {
    const { formValidation } = this.state;
    const { handleCampaignDeliveryFormValidation } = this.props;

    if (formValidation[name] !== isValid) {
      this.setState(state => {
        const updatedFormValidation = {
          ...state.formValidation,
          [name]: isValid,
        };

        // used to write form validity to apollo cache for the tabber
        handleCampaignDeliveryFormValidation({
          variables: {
            formValidation: {
              __typename: 'CampaignDeliveryFormValidation',
              isFormValid: this.isFormValid(updatedFormValidation),
            },
          },
        });

        return {
          formValidation: {
            ...state.formValidation,
            [name]: isValid,
          },
          isFormValid: this.isFormValid(updatedFormValidation),
        };
      });
    }
  };

  isFormValid = (formValidation: Object) => {
    const { audienceAndSchools, setDistributions } = formValidation;

    return audienceAndSchools && setDistributions;
  };

  render() {
    const { isFormValid, shouldPerformDisabledClickValidation } = this.state;

    const {
      selectedAudience,
      checkboxSelections,
      districts,
      // campaign,
      campaignId,
      setDeliveryCache,
      campaignStartDate,
      numberOfDistributions,
      postExpirationDate,
      eventLocation,
      minGradeLevel,
      maxGradeLevel,
      history,
      uploaderLevel,
      postOnlySelections,
      onSaveFailed,
      toggleDistrictGuidelinesConfirmed,
      areDistrictGuidelinesConfirmed,
      isCommunityFree,
      communityFreeSchoolSelectionLimit,
    } = this.props;

    const isOrg = uploaderLevel === 'org';
    const hasCommunityFreeError =
      isCommunityFree &&
      checkboxSelections.length > communityFreeSchoolSelectionLimit;

    let isParentAudienceEmpty = false;
    let isStaffAudienceEmpty = false;

    const parentAudiences = get(districts, '0.audiences.parents', null);
    const staffAudiences = get(districts, '0.audiences.staff', null);

    if (
      !parentAudiences ||
      (Array.isArray(parentAudiences) && parentAudiences.length === 0)
    ) {
      isParentAudienceEmpty = true;
    }

    if (
      !staffAudiences ||
      (Array.isArray(staffAudiences) && staffAudiences.length === 0)
    ) {
      isStaffAudienceEmpty = true;
    }

    const isTargetAudiencesEmpty =
      isParentAudienceEmpty && isStaffAudienceEmpty;

    const isDistrictOrSchool =
      uploaderLevel === 'district' || uploaderLevel === 'school';

    const minGradeLevelToUse = minGradeLevel
      ? minGradeLevel
      : gradeFilterDefault.min;
    const maxGradeLevelToUse = maxGradeLevel
      ? maxGradeLevel
      : gradeFilterDefault.max;

    return (
      <>
        <CircleList>
          <li>
            <AudienceAndSchools
              name="audienceAndSchools"
              setDeliveryCache={setDeliveryCache}
              selectedAudience={selectedAudience}
              checkboxSelections={checkboxSelections}
              districts={districts}
              eventLocation={eventLocation}
              handleIsValid={this.handleIsValid}
              uploaderLevel={uploaderLevel}
              isTargetAudiencesEmpty={isTargetAudiencesEmpty}
              shouldPerformDisabledClickValidation={
                shouldPerformDisabledClickValidation
              }
              minGradeLevel={minGradeLevelToUse}
              maxGradeLevel={maxGradeLevelToUse}
              toggleDistrictGuidelinesConfirmed={
                toggleDistrictGuidelinesConfirmed
              }
              areDistrictGuidelinesConfirmed={areDistrictGuidelinesConfirmed}
              isCommunityFree={isCommunityFree}
              communityFreeSchoolSelectionLimit={
                communityFreeSchoolSelectionLimit
              }
              campaignId={campaignId}
            />

            {!isDistrictOrSchool &&
              !isOrg &&
              !isCommunityFree &&
              isTargetAudiencesEmpty && <NoStaffList />}
          </li>
          {(isOrg || isCommunityFree || !isTargetAudiencesEmpty) && (
            <li>
              <SetDistributions
                name="setDistributions"
                setDeliveryCache={setDeliveryCache}
                campaignStartDate={campaignStartDate}
                numberOfDistributions={numberOfDistributions}
                postExpirationDate={postExpirationDate}
                handleIsValid={this.handleIsValid}
                shouldPerformDisabledClickValidation={
                  shouldPerformDisabledClickValidation
                }
                isOrg={isOrg}
              />
            </li>
          )}
        </CircleList>

        {(isOrg || isCommunityFree || !isTargetAudiencesEmpty) && (
          <div
            className={`layout-row layout-align-end-center ${styles.saveButton}`}
          >
            <div
              onClick={() =>
                !isFormValid &&
                this.handleShouldPerformDisabledClickValidation(true)
              }
              onKeyPress={() =>
                !isFormValid &&
                this.handleShouldPerformDisabledClickValidation(true)
              }
            >
              <SaveCampaignDeliveryButton
                disabled={!isFormValid || hasCommunityFreeError}
                campaignId={campaignId}
                minGradeLevel={minGradeLevelToUse}
                maxGradeLevel={maxGradeLevelToUse}
                selectedAudience={selectedAudience}
                checkboxSelections={checkboxSelections}
                campaignStartDate={campaignStartDate}
                postExpirationDate={postExpirationDate}
                numberOfDistributions={numberOfDistributions}
                areDistrictGuidelinesConfirmed={areDistrictGuidelinesConfirmed}
                postOnlySelections={postOnlySelections}
                handleSuccess={routePushHandler(
                  history,
                  `/campaigns/create-campaign/${campaignId}/summary`
                )}
                handleError={() => onSaveFailed(campaignId)}
              >
                Continue to Preview
              </SaveCampaignDeliveryButton>
            </div>
          </div>
        )}
      </>
    );
  }
}

Delivery.fragments = DeliveryFragments;

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  setCommFreeSchoolLimit: (limit: number) =>
    dispatch(setCommunityFreeSchoolSelectionLimit(limit)),
});

export default connect(
  state => {
    const campaignType = get(
      state,
      'campaigns.communityFree.type',
      STANDARD_TYPE
    );
    const flags = get(state, 'flags');

    return {
      flags,
      postOnlySelections: state.deals.postOnly,
      isCommunityFree: campaignType === COMMUNITY_FREE_TYPE,
      communityFreeSchoolSelectionLimit:
        state.campaigns.communityFree.schoolSelectionLimit,
      applicationId: state.campaigns.communityFree.applicationId,
    };
  },
  mapDispatchToProps
)(Delivery);
