import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import { colors } from '@peachjar/components';

type Props = {
  link: string;
  icon: string;
  headline: string;
  isExternalLink?: boolean;
  subheadline?: string | null;
  isDisabled?: boolean;
  classes: Record<string, any>;
  className: string;
  children?: React.ReactChild | null;
  dataTestId: string;
};

type LinkComponentProps = {
  link: string;
  children: React.ReactNode;
  isDisabled: boolean;
  clickHandler: (
    event: React.MouseEvent<HTMLAnchorElement>,
    isDisabled: boolean
  ) => void;
};

const LinkComponent = ({
  link,
  children,
  isDisabled,
  clickHandler,
}: LinkComponentProps) => (
  <Link
    to={link}
    className={cn.cardLink}
    onClick={event => clickHandler(event, isDisabled)}
  >
    {children}
  </Link>
);

const AnchorComponent = ({
  link,
  children,
  isDisabled,
  clickHandler,
}: LinkComponentProps) => (
  <a
    href={link}
    className={cn.cardLink}
    onClick={event => clickHandler(event, isDisabled)}
  >
    {children}
  </a>
);

const internalClickHandler = (event: Event, isDisabled: boolean) => {
  if (isDisabled) {
    event.preventDefault();
    return false;
  }
};

const DashboardCard = ({
  link,
  icon,
  headline,
  classes,
  className,
  isDisabled = false,
  isExternalLink = false,
  subheadline = null,
  children = null,
  dataTestId = 'card-dashboard-card',
}: Props) => {
  const LinkedCard = isExternalLink ? AnchorComponent : LinkComponent;

  return (
    <div
      className={`${cn.cardContainer} ${className} ${
        isDisabled ? 'locked' : ''
      }`}
      data-testid={dataTestId}
    >
      <LinkedCard
        link={link}
        isDisabled={isDisabled}
        clickHandler={internalClickHandler}
      >
        <Paper
          className={`${cn.dashboardCard} ${classes.elevation2} ${
            isDisabled ? 'locked' : ''
          }`}
        >
          <div className={cn.iconContainer}>
            <img src={icon} alt="" data-testid="img-dashboard-card-icon" />
          </div>
          <h3
            className={cn.headline}
            data-testid="text-dashboard-card-headline"
          >
            {headline}
          </h3>
          {subheadline && <p className={cn.subheadline}>{subheadline}</p>}
          {children}
        </Paper>
      </LinkedCard>
    </div>
  );
};

// override material-ui default styles
const styles = {
  elevation2: {
    boxShadow: 'none',
  },
};

// classes
const cn = {
  cardContainer: css`
    &.locked {
      opacity: 0.4;
    }
  `,
  dashboardCard: css`
    border: 1px solid #e6e6e6;
    padding: 27px 40px 23px 40px;
    position: relative;

    &:hover {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }

    &.locked:hover {
      box-shadow: none;
      cursor: not-allowed;
    }
  `,
  iconContainer: css`
    text-align: center;
    padding: 2px;
  `,
  cardLink: css`
    &:hover {
      text-decoration: none;
    }
  `,
  headline: css`
    color: ${colors.prussian};
    font-size: 19px;
    padding-top: 12px;
    text-align: center;
  `,
  subheadline: css`
    color: #2d344b;
    font-size: 13px;
    position: absolute;
    bottom: -5px;
    text-align: center;
    width: 77%;
  `,
};

export default withStyles(styles)(DashboardCard);
