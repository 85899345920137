import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { colors, elements } from '@peachjar/components';
import FormHelperText from '@material-ui/core/FormHelperText';
import { css } from 'react-emotion';
import { get } from 'lodash';
import Audiences from './Audiences';
import SodSelections from './SodSelections';
import ManagedServices from './ManagedServices';
import withValidation from '../../../../shared/components/withValidation';
import SchoolListPopper from './SodSelections/SchoolListPopper';
import { SET_CHECKBOX_SELECTIONS_MUTATION } from '../../gql/Delivery.graphql';

const { dragon } = colors;

const { Headline2, Paragraph, Note, strong } = elements.typography;

const messages = {
  district:
    'Pick your audience and then select the schools you want this flyer to go out to. All flyers must meet the distribution guidelines of the school district.',
  school:
    'Pick the audience you want this flyer to go out to. All flyers must meet the distribution guidelines of the school district.',
  org:
    'Select the schools you want your flyer to go out through. Use the grade filter to refine your school search to age-appropriate children.',
  managedServices:
    'Enter all of the school IDs, separated by a newline in the text box. Once all of the desired ID’s are entered click the Update School Selection button to continue.',
};

const styles = {
  paddingAboveSODSelection: css`
    margin-top: 9px;
  `,
  paddingAboveSchoolListPopper: css`
    margin-top: 32px;
  `,
  staffGroupPadding: css`
    margin-top: 49px;
  `,
  errorWrapper: css`
    border-style: solid;
    border-width: 1px;
    border-color: ${dragon};
  `,
  errorText: css`
    color: ${dragon};
  `,
  divAroundPopper: css`
    margin-top: 16px;
    margin-bottom: 11px;
  `,
  aboveStaffPadding: css`
    margin-top: 16px;
  `,
  schoolLimitError: css`
    border: 1px solid ${dragon};
  `,
};

export const useEffectFactory = ({
  performValidation,
  uploaderLevel,
  checkboxSelections,
  isDistrictGuidelinesValid,
}) => {
  const useEffectCallback = () => {
    performValidation({
      target: {
        value: { uploaderLevel, checkboxSelections, isDistrictGuidelinesValid },
      },
    });
  };
  return useEffectCallback;
};

export class AudienceAndSchools extends Component {
  state = {
    isDistrictGuidelinesValid: true,
    isSingleCheckboxAltered: false,
  };

  constructor(props) {
    super(props);
    this.setIsSingleCheckboxAltered = this.setIsSingleCheckboxAltered.bind(
      this
    );
  }

  componentDidMount() {
    const { performValidation, checkboxSelections, uploaderLevel } = this.props;
    const { isDistrictGuidelinesValid } = this.state;
    useEffectFactory({
      performValidation,
      uploaderLevel,
      checkboxSelections,
      isDistrictGuidelinesValid,
    })();
  }

  componentDidUpdate() {
    const { performValidation, checkboxSelections, uploaderLevel } = this.props;
    const { isDistrictGuidelinesValid } = this.state;
    useEffectFactory({
      performValidation,
      uploaderLevel,
      checkboxSelections,
      isDistrictGuidelinesValid,
    })();
  }

  setIsSingleCheckboxAltered(wasAltered) {
    this.setState((state, props) => {
      return {
        ...state,
        isSingleCheckboxAltered: wasAltered,
      };
    });
  }

  handleIsValid = (name, isValid) => {
    const { performValidation, checkboxSelections, uploaderLevel } = this.props;
    const { isDistrictGuidelinesValid } = this.state;

    if (
      name === 'districtGuidelines' &&
      isValid !== isDistrictGuidelinesValid
    ) {
      this.setState({ isDistrictGuidelinesValid: isValid }, () =>
        performValidation({
          uploaderLevel,
          isDistrictGuidelinesValid,
          checkboxSelections,
        })
      );
    }
  };

  render() {
    const {
      selectedAudience,
      setCheckboxSelections,
      setDeliveryCache,
      checkboxSelections,
      districts,
      eventLocation,
      performValidation,
      uploaderLevel,
      flags,
      isTargetAudiencesEmpty,
      shouldPerformDisabledClickValidation,
      minGradeLevel,
      maxGradeLevel,
      toggleDistrictGuidelinesConfirmed,
      areDistrictGuidelinesConfirmed,
      isCommunityFree,
      communityFreeSchoolSelectionLimit,
      campaignId,
    } = this.props;

    const managedServicesFlagEnabled = flags.managed_services === 1;
    const isDistrictOrSchool =
      uploaderLevel === 'district' || uploaderLevel === 'school';
    const isDistrict = uploaderLevel === 'district';
    const hasParentSchools =
      get(districts, `0.audiences.parents`, []).length !== 0;
    const errorText =
      shouldPerformDisabledClickValidation && checkboxSelections.length === 0
        ? 'You missed this requirement.'
        : '';
    const hasStaffSchools =
      get(districts, `0.audiences.staff`, []).length !== 0;

    const shownBool =
      (isDistrictOrSchool && hasParentSchools) ||
      (uploaderLevel === 'org' || !isTargetAudiencesEmpty);

    const hasCommFreeSchoolSelectionLimitError =
      isCommunityFree &&
      checkboxSelections.length > communityFreeSchoolSelectionLimit;

    return (
      <>
        <Headline2
          className={css`
            line-height: 1;
          `}
        >
          Pick Audience
        </Headline2>
        {!isDistrictOrSchool && (
          <>
            <div>
              <Paragraph>
                {managedServicesFlagEnabled
                  ? messages.managedServices
                  : messages[uploaderLevel]}
              </Paragraph>
            </div>
            {/* Hidden for orgs for first school finder release */}
            {isDistrictOrSchool && (
              <Audiences className="mt-4" selectedAudience={selectedAudience} />
            )}
          </>
        )}

        {isDistrictOrSchool && (
          <>
            <div className={styles.paddingAboveSchoolListPopper} />
            <Paragraph>
              <strong>Parents</strong> (including subscribed staff)
            </Paragraph>
          </>
        )}

        {isDistrict && hasParentSchools && (
          <div className={styles.divAroundPopper}>
            <SchoolListPopper
              setCheckboxSelections={setCheckboxSelections}
              isSingleCheckboxAltered={this.state.isSingleCheckboxAltered}
              setIsSingleCheckboxAltered={this.setIsSingleCheckboxAltered}
              districts={districts}
              selectedAudience={selectedAudience}
              checkboxSelections={checkboxSelections}
            />
          </div>
        )}

        {isDistrictOrSchool && (
          <div className={styles.paddingAboveSODSelection} />
        )}
        {!managedServicesFlagEnabled && (
          <div
            className={isDistrictOrSchool && errorText && styles.errorWrapper}
          >
            {((isDistrictOrSchool && hasParentSchools) ||
              (uploaderLevel === 'org' ||
                isCommunityFree ||
                !isTargetAudiencesEmpty)) && (
              <SodSelections
                setDeliveryCache={setDeliveryCache}
                isSingleCheckboxAltered={this.state.isSingleCheckboxAltered}
                isCommunityFree={isCommunityFree}
                setIsSingleCheckboxAltered={this.setIsSingleCheckboxAltered}
                selectedAudience={
                  isDistrictOrSchool ? 'parents' : selectedAudience
                }
                checkboxSelections={checkboxSelections}
                districts={districts}
                eventLocation={eventLocation}
                uploaderLevel={uploaderLevel}
                shouldPerformDisabledClickValidation={
                  shouldPerformDisabledClickValidation
                }
                onSelectionUpdated={updatedCheckboxSelections => {
                  performValidation({
                    checkboxSelections: updatedCheckboxSelections,
                  });
                }}
                minGradeLevel={minGradeLevel}
                maxGradeLevel={maxGradeLevel}
                toggleDistrictGuidelinesConfirmed={
                  toggleDistrictGuidelinesConfirmed
                }
                areDistrictGuidelinesConfirmed={areDistrictGuidelinesConfirmed}
                handleGuidelinesIsValid={this.handleIsValid}
                hasCommFreeSchoolSelectionLimitError={
                  hasCommFreeSchoolSelectionLimitError
                }
                communityFreeSchoolSelectionLimit={
                  communityFreeSchoolSelectionLimit
                }
              />
            )}

            {isDistrictOrSchool && hasStaffSchools && (
              <>
                <div className={styles.staffGroupPadding}></div>
                <Paragraph>
                  <strong>Staff Groups</strong>
                </Paragraph>
                <div className={styles.aboveStaffPadding}></div>
                <SodSelections
                  setDeliveryCache={setDeliveryCache}
                  isSingleCheckboxAltered={this.state.isSingleCheckboxAltered}
                  setIsSingleCheckboxAltered={e => {}}
                  selectedAudience={'staff'}
                  checkboxSelections={checkboxSelections}
                  districts={districts}
                  eventLocation={eventLocation}
                  uploaderLevel={uploaderLevel}
                  shouldPerformDisabledClickValidation={
                    shouldPerformDisabledClickValidation
                  }
                  onSelectionUpdated={updatedCheckboxSelections => {
                    performValidation({
                      checkboxSelections: updatedCheckboxSelections,
                    });
                  }}
                  minGradeLevel={minGradeLevel}
                  maxGradeLevel={maxGradeLevel}
                  toggleDistrictGuidelinesConfirmed={
                    toggleDistrictGuidelinesConfirmed
                  }
                  areDistrictGuidelinesConfirmed={
                    areDistrictGuidelinesConfirmed
                  }
                  handleGuidelinesIsValid={this.handleIsValid}
                  hasCommFreeSchoolSelectionLimitError={
                    hasCommFreeSchoolSelectionLimitError
                  }
                  communityFreeSchoolSelectionLimit={
                    communityFreeSchoolSelectionLimit
                  }
                />
              </>
            )}
          </div>
        )}
        {managedServicesFlagEnabled && (
          <div>
            <ManagedServices
              checkboxSelections={checkboxSelections}
              shouldPerformDisabledClickValidation={
                shouldPerformDisabledClickValidation
              }
              areDistrictGuidelinesConfirmed={areDistrictGuidelinesConfirmed}
              toggleDistrictGuidelinesConfirmed={
                toggleDistrictGuidelinesConfirmed
              }
              handleGuidelinesIsValid={this.handleIsValid}
              campaignId={campaignId}
              setCheckboxSelections={setCheckboxSelections}
              onSelectionUpdated={updatedCheckboxSelections => {
                performValidation({
                  checkboxSelections: updatedCheckboxSelections,
                });
              }}
            />
          </div>
        )}
        {isDistrictOrSchool && errorText && (
          <FormHelperText>
            <Note className={styles.errorText}>{errorText}</Note>
          </FormHelperText>
        )}
      </>
    );
  }
}

const validate = ({
  uploaderLevel,
  checkboxSelections,
  isDistrictGuidelinesValid,
}) =>
  uploaderLevel === 'org'
    ? !!isDistrictGuidelinesValid && checkboxSelections.length > 0
    : checkboxSelections.length > 0;

export default connect(
  state => {
    const flags = get(state, 'flags');
    return {
      flags,
    };
  },
  null
)(
  graphql(SET_CHECKBOX_SELECTIONS_MUTATION, {
    name: 'setCheckboxSelections',
  })(withValidation(AudienceAndSchools, validate, null))
);
