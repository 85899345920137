import React, { useContext, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { colors, components } from '@peachjar/components';
import Dashed from '@peachjar/ui/dist/lib/components/Dashed';
import { css } from 'emotion';
import idx from 'idx';
import orderBy from 'lodash/orderBy';
import LoadingSpinner from '../../../../src/components/LoadingSpinner';
import RemoveFlyerDrawerModal from '../../../../src/components/RemoveFlyerDrawerModal';
import generateFlyerURL from '../../../../src/helpers/generatePreviewFlyerURL';
import approvedIcon from '../../../assets/icon-approved-circular.svg';
import deniedIcon from '../../../assets/icon-denied-circular.svg';
import apolloClient from '../../apollo/portalBFF.apolloClient';
import { NOTIFICATIONS } from '../../constants';
import AppDrawerContext from '../../context/AppDrawerContext';
import { REMOVAL_DRAWER_DETAILS_QUERY } from './FlyerRemovalDrawer.graphql';
import {
  getAllDisabled,
  getApprovalIdArray,
  getCheckedAll,
  getCheckedAllPartial,
  getCheckedDistrict,
  getCheckedDistrictPartial,
  getCheckedItem,
  getDistrictDisabled,
  getDistrictRemoved,
  handleChange,
  handleDistrictSelect,
  handleSelectAll,
  setInitialApprovalState,
} from './FlyerRemovalUtils';
const {
  Buttons: { ButtonSecondaryLarge, ButtonPrimaryLarge },
  Notifications: { notifySuccess, notifyError, notifyWarn },
} = components;

const { silver, leaf, slate, mint } = colors;

const checkStatusMapping = (
  status: string,
  statusBeforeRemoval: string | null
): string => {
  let mappedString = status;

  if (statusBeforeRemoval) {
    mappedString = statusBeforeRemoval;

    return mappedString;
  }

  return mappedString;
};

export const RemoveFlyer = ({
  flyerId,
  classes,
  handleSuccess,
  handleError,
}) => {

  const { toggleDrawer } = useContext(AppDrawerContext);
  const topRef = useRef(null);

  const [approvalRemovalSet, setApprovalRemovalSet] = useState<any[]>([]);
  const [removeFlyerModalOpen, setRemoveFlyerModalOpen] = useState(false);
  const [showError, setShowError] = useState(false);

  const { loading, error, data } = useQuery(REMOVAL_DRAWER_DETAILS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id: parseInt(flyerId, 10) },
    client: apolloClient,
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    console.log('An error occurred removing flyer.', error)
    return null
  }
  const approvals = idx(
    data,
    _ => _.sodUser.flyerReporting.flyerDetailReport.approvals
  );
  const uploaderLevel = idx(data, _ => _.sodUser.permission.uploaderLevel);
  const alphabetizedByDistrict = orderBy(approvals, ['districtName']);

  let lastDistrict = null;

  if (!approvalRemovalSet.length) {
    setInitialApprovalState(alphabetizedByDistrict, setApprovalRemovalSet);
  }

  const generateSchoolLink = (flyerId: number, schoolId: number): string => {
    const sod = 'school';
    return generateFlyerURL({
      sod,
      flyerId,
      districtId: null,
      schoolId,
    });
  };

  const disabledClickShowErrors = () => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
    setShowError(true);
  };

  return (
    <div className={cn.container}>
      {/* <span  style={{ scrollMargin: '10px' }}></span> */}
      <div ref={topRef} className={cn.errorHolder}>
        {showError && (
          <p className={cn.errorText}>You missed this requirement.</p>
        )}
      </div>
      <div
        className={`${
          showError ? `${cn.tableContainerError}` : `${cn.tableContainer}`
        }`}
      >
        <Table
          classes={{
            root: classes.table,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell className={cn.tableHeader}>Name</TableCell>
              <TableCell className={cn.tableHeader}>Approval</TableCell>
              <TableCell className={cn.tableHeader}>Flyer Status</TableCell>
              <TableCell className={cn.checkedHeader}>
                <div className={cn.checkbox}>
                  <p style={{ margin: 'auto', marginTop: '5px' }}>
                    Remove Flyer
                  </p>
                </div>
                <div className={cn.checkbox}>
                  <Checkbox
                    onChange={() => {
                      handleSelectAll(
                        event,
                        approvalRemovalSet,
                        setApprovalRemovalSet,
                        setShowError
                      );
                    }}
                    checked={getCheckedAll(approvalRemovalSet)}
                    indeterminate={getCheckedAllPartial(approvalRemovalSet)}
                    disabled={getAllDisabled(approvalRemovalSet)}
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                      disabled: classes.rootDisabled,
                      indeterminate: classes.indeterminate,
                    }}
                  ></Checkbox>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alphabetizedByDistrict.map(
              ({
                id,
                flyerId,
                schoolName,
                schoolId,
                status,
                statusBeforeRemoval,
                distributionStatus,
                districtName,
                districtId,
              }) => {
                let addSection = false;

                if (lastDistrict != districtName) {
                  lastDistrict = districtName;
                  addSection = true;
                }
                return (
                  <>
                    {addSection && (
                      <TableRow key={id}>
                        <TableCell className={cn.tableCell}>
                          <a className={cn.districtName}>{districtName}</a>
                        </TableCell>
                        <TableCell className={cn.tableCell}></TableCell>
                        <TableCell className={cn.tableCell}></TableCell>
                        <TableCell className={cn.checkedCell}>
                          <div className={cn.checkbox}>
                            {!getDistrictRemoved(
                              districtId,
                              approvalRemovalSet
                            ) && (
                              <Checkbox
                                value={districtId}
                                onClick={() => {
                                  handleDistrictSelect(
                                    event,
                                    approvalRemovalSet,
                                    setApprovalRemovalSet,
                                    setShowError
                                  );
                                }}
                                checked={getCheckedDistrict(
                                  districtId,
                                  approvalRemovalSet
                                )}
                                indeterminate={getCheckedDistrictPartial(
                                  districtId,
                                  approvalRemovalSet
                                )}
                                disabled={getDistrictDisabled(
                                  districtId,
                                  approvalRemovalSet
                                )}
                                classes={{
                                  root: classes.checkboxRoot,
                                  checked: classes.checked,
                                  disabled: classes.rootDisabled,
                                  indeterminate: classes.indeterminate,
                                }}
                              ></Checkbox>
                            )}
                            {getDistrictRemoved(
                              districtId,
                              approvalRemovalSet
                            ) && (
                              <Checkbox
                                value={districtId}
                                checked={true}
                                classes={{
                                  disabled: classes.rootRemovedDisabled,
                                }}
                                disableRipple
                                disabled={true}
                              ></Checkbox>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        <a
                          href={generateSchoolLink(flyerId, schoolId)}
                          className={cn.tableLink}
                          target="_blank"
                        >
                          {schoolName}
                        </a>
                      </TableCell>
                      <TableCell className={cn.statusBeforeRemoval}>
                        {status ? (
                          <div className={cn.statusRow}>
                            <span
                              className={
                                cn[
                                  `approvalStatus${checkStatusMapping(
                                    status,
                                    statusBeforeRemoval
                                  )}`
                                ]
                              }
                            />
                            <span>
                              {checkStatusMapping(status, statusBeforeRemoval)}
                            </span>
                          </div>
                        ) : (
                          <Dashed />
                        )}
                      </TableCell>
                      <TableCell className={cn.tableCell}>
                        {distributionStatus ? (
                          <Chip
                            label={distributionStatus}
                            className={`${cn.flyerStatus} ${cn[distributionStatus]}`}
                          />
                        ) : (
                          <Dashed />
                        )}
                      </TableCell>
                      <TableCell className={cn.checkedCell}>
                        <div className={cn.checkbox}>
                          {distributionStatus !== 'removed' && (
                            <Checkbox
                              value={id}
                              checked={getCheckedItem(id, approvalRemovalSet)}
                              onChange={() => {
                                handleChange(
                                  id,
                                  approvalRemovalSet,
                                  setApprovalRemovalSet,
                                  setShowError
                                );
                              }}
                              classes={{
                                root: classes.checkboxRoot,
                                checked: classes.checked,
                                disabled: classes.rootDisabled,
                              }}
                              disabled={distributionStatus === 'declined'}
                            />
                          )}
                          {distributionStatus === 'removed' && (
                            <Checkbox
                              value={id}
                              checked={true}
                              classes={{
                                disabled: classes.rootRemovedDisabled,
                              }}
                              disableRipple
                              disabled={true}
                            />
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                );
              }
            )}
          </TableBody>
        </Table>
      </div>
      <div className={cn.buttonBar}>
        <ButtonSecondaryLarge
          onClick={() => {
            toggleDrawer();
          }}
        >
          Cancel
        </ButtonSecondaryLarge>
        <span className={cn.buttonSpacer}></span>
        <div
          onClick={() => {
            if (!getApprovalIdArray(approvalRemovalSet)) {
              disabledClickShowErrors();
            }
          }}
        >
          <ButtonPrimaryLarge
            onClick={() => {
              setRemoveFlyerModalOpen(true);
            }}
            disabled={getApprovalIdArray(approvalRemovalSet) === false}
          >
            Remove
          </ButtonPrimaryLarge>
        </div>
      </div>
      <RemoveFlyerDrawerModal
        open={removeFlyerModalOpen}
        onClose={() => {
          setRemoveFlyerModalOpen(false);
          toggleDrawer();
        }}
        handleSuccess={handleSuccess}
        handleError={handleError}
        uploaderLevel={uploaderLevel}
        removalIds={getApprovalIdArray(approvalRemovalSet)}
      />
    </div>
  );
};

const styles = theme => ({
  root: {
    width: 'auto',
    padding: '1rem 1.50rem',
    border: `1px solid ${silver}`,
    borderTopLeftRadius: '3px',
    backgroundColor: theme.palette.background.paper,
    opacity: 1,
  },
  rootDisabled: {
    '& > *': {
      opacity: 0.8,
    },
  },
  rootRemovedDisabled: {
    color: 'rgb(58,131,60)',
  },
  rootSelected: {
    backgroundColor: mint,
  },
  listItemTextRoot: {
    padding: '0 0.5rem',
  },
  checkboxRoot: {
    color: slate,
    '&$checked': {
      color: leaf,
    },
  },
  checked: {},
  indeterminate: {
    color: leaf,
  },
  disabledIndeterminate: {
    color: silver,
  },
  listItemSecondaryRoot: {
    right: '1.5rem',
  },
  listItemTextPrimary: {
    fontSize: '1rem !important',
  },
  table: {
    border: '1px solid rgb(217, 217, 217)',
    padding: '0 !important',
    margin: '0 !important',
    width: '600px !important',
  },
});

const tableIconStyles = css`
  display: inline-block;
  vertical-align: -2px;
  margin-right: 5px;
  height: 12px;
  width: 12px;
  background-size: cover;
`;

const cn = {
  approvalStatusapproved: css`
    background-image: url(${approvedIcon});
    ${tableIconStyles};
  `,
  approvalStatusdenied: css`
    background-image: url(${deniedIcon});
    ${tableIconStyles};
  `,
  approvalStatuspending: css`
    &:before {
      content: '--';
      font-weight: 100;
      display: inline-block;
      margin-right: 5px;
      height: 12px;
      width: 12px;
    }
  `,
  buttonBar: css`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    bottom: 0;
    left: 0;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    background: white;
  `,
  buttonSpacer: css`
    display: flex;
    width: 16px;
  `,
  checkedCell: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 13px;
    color: ${colors.slate};
    padding: 0px 24px 0px 24px !important;
    border: 1px solid rgb(217, 217, 217);
  `,
  checkbox: css`
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  `,
  checkedHeader: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    padding: 0 !important;
    border: 1px solid rgb(217, 217, 217);
  `,
  container: css`
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 100px;
  `,
  districtName: css`
    font-weight: bold;
    color: ${colors.jungle};
    &:hover {
      color: ${colors.jungle};
    }
  `,
  errorText: css`
    color: ${colors.dragon};
    font-size: 0.9rem;
  `,
  errorHolder: css`
    height: 20px;
    margin-bottom: 8px;
    scroll-margin: 30px;
  `,
  flyerStatus: css`
    display: flex;
    color: ${colors.prussian} !important;
    font-size: 13px !important;
    font-weight: normal;
    text-transform: capitalize;
  `,
  statusBeforeRemoval: css`
    display: flex;
    flex-direction: row;
    text-transform: capitalize;
  `,
  statusRow: css`
  display:flex;
  flex-direction: row;
  align-items: center;
  width: 100%
  height: 100%;
  `,
  tableContainerError: css`
    display: flex;
    height: calc(100% - 104px);
    border: 1px solid ${colors.dragon};
    border-radius: 2px;
  `,
  tableContainer: css`
    display: flex;
    height: calc(100% - 104px);
  `,
  tableCell: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 13px;
    color: ${colors.slate};
    padding: 4px 24px 4px 24px !important;
  `,
  tableHeader: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px 0px 24px !important;
  `,
  tableLink: css`
    color: ${colors.jungle};

    &:hover {
      color: ${colors.jungle};
    }
  `,

  submitted: css`
    background-color: ${colors.mercury} !important;
  `,
  declined: css`
    background-color: #ffebe5 !important;
  `,
  active: css`
    background-color: #e6f4e9 !important;
  `,
  scheduled: css`
    background-color: #dfe1e7 !important;
  `,
  removed: css`
    background-color: #ffefc8 !important;
  `,
  denied: css`
    background-color: #ffebe5 !important;
  `,
  expired: css`
    background-color: #fffae5 !important;
  `,
};

const mapDispatchToProps = (dispatch: any): object => ({
  handleSuccess: async (key: string): Promise<any> => {
    dispatch(notifySuccess(NOTIFICATIONS[key]));
  },
  handleError: (key: string): void => {
    dispatch(notifyError(NOTIFICATIONS[key]));
  },
  handleWarning: (key: string): void => {
    dispatch(notifyWarn(NOTIFICATIONS[key]));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(RemoveFlyer));
