import React from 'react';
import CampaignSlat from './CampaignSlat';
import CampaignSlatListFragments from './gql/CampaignSlatListFragments.graphql';

const CampaignSlatList = ({ campaigns, onRemoveDraftCampaign }) => (
  <div>
    {campaigns.map(campaign => (
      <CampaignSlat
        key={campaign.id}
        campaign={campaign}
        onRemoveDraftCampaign={onRemoveDraftCampaign}
      />
    ))}
  </div>
);

CampaignSlatList.fragments = CampaignSlatListFragments;

export default CampaignSlatList;
