import React from 'react';
import { css } from 'react-emotion';
import { components, colors, fonts } from '@peachjar/components';

const { ProximaNova } = fonts;
const { ButtonFlatLarge } = components.Buttons;
const { Modal } = components;

const styles = {
  body: css`
    padding-top: 1rem;
    font-family: ${ProximaNova};
    font-size: 16px;
    color: ${colors.prussian};
    line-height: 24px;
  `,
  footer: css`
    padding-top: 0.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;

    && {
      margin: 0px;
    }
  `,
  children: css`
    padding-left: 0.5rem;
  `,
  note: css`
    padding-top: 1rem;
    font-family: ${ProximaNova};
    font-size: 16px;
    color: #7b7b7b;
    line-height: 24px;
  `,
};

const GenericModal = ({
  header,
  message,
  onCancel,
  children,
  note,
  uploaderLevel,
}) => (
  <Modal width="sm">
    <React.Fragment>
      <Modal.Header color={colors.rose}>{header}</Modal.Header>
      <Modal.Body>
        <div className={styles.body}>{message}</div>
        {uploaderLevel === 'org' && <div className={styles.note}>{note}</div>}
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <ButtonFlatLarge data-testid="removeModalCancel" onClick={onCancel}>
          Cancel
        </ButtonFlatLarge>
        <div className={styles.children}>{children}</div>
      </Modal.Footer>
    </React.Fragment>
  </Modal>
);

export default GenericModal;
