import React from 'react';

const NoStaffList = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 350.69 129.56"
    width="350.69"
    height="129.56"
    {...props}
  >
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '.cls-1{fill:#f0f2f6;}.cls-2{fill:#dfe1e8;}.cls-3{fill:#c6c8d4;}.cls-4,.cls-5{fill:#9ea3b7;}.cls-5{fill-rule:evenodd;}.cls-6{fill:#757d99;}',
        }}
      />
    </defs>
    <title>Asset 22</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_2-2" data-name="Layer 2">
        <path
          className="cls-1"
          d="M265.31,0c-33-1.41-48.18,59-102.35,65.12-24.33,2.74-33.72-12.77-61.73-6.25-26.71,6.22-46.24,26.85-58.87,44h289C305.58.49,282.21.75,265.31,0Z"
        />
        <path
          className="cls-2"
          d="M350.69,107.06H223.76a9.34,9.34,0,0,1,5-4c3.05-1.07,5.86-.58,8.74-.49,7.8.24,10.23-2.65,19.26-4.53a35,35,0,0,1,7.29-.84c.59,0,1.74,0,8.17.49,5.2.41,5.18.47,6,.43,1.86-.07,5.72-.24,8.93-2.48,4.33-3,4.75-7.92,8.38-9.8a16.94,16.94,0,0,1,3.74-1.33,15,15,0,0,1,2.34-.29c6.43-.3,13,3.75,15,7.31a6.49,6.49,0,0,0,1.44,2,13.13,13.13,0,0,0,4.6,2.49,74.73,74.73,0,0,0,11.82,3.12A33.24,33.24,0,0,1,350.69,107.06Z"
        />
        <path
          className="cls-3"
          d="M158.06,112.9,0,112.46a15,15,0,0,1,7.66-7.67c8.44-3.5,16.53,3.08,23.79-.33,6.2-2.92,2.91-9,8.87-12.67,8-5,17.39,3.66,33.47,1.67s18.42-12.26,29.44-11,10,14.64,25.4,19c5.42,1.53,8.24.72,15.32,3.33A48,48,0,0,1,158.06,112.9Z"
        />
        <ellipse
          className="cls-2"
          cx="158.53"
          cy="118.93"
          rx="2.43"
          ry="7.07"
          transform="translate(-15.23 23.63) rotate(-8.13)"
        />
        <ellipse
          className="cls-2"
          cx="153.61"
          cy="107.98"
          rx="1.95"
          ry="5.68"
          transform="translate(-33.98 116.14) rotate(-37.6)"
        />
        <ellipse
          className="cls-2"
          cx="159.58"
          cy="99.14"
          rx="1.95"
          ry="5.68"
          transform="translate(-23.46 56.81) rotate(-18.83)"
        />
        <ellipse
          className="cls-2"
          cx="159.58"
          cy={108}
          rx="4.87"
          ry="1.47"
          transform="translate(-10.52 198.52) rotate(-62.11)"
        />
        <ellipse
          className="cls-2"
          cx="161.24"
          cy="113.91"
          rx="2.87"
          ry="0.87"
          transform="translate(-35.94 123.5) rotate(-38.02)"
        />
        <ellipse
          className="cls-2"
          cx="157.55"
          cy="106.16"
          rx="0.87"
          ry="2.87"
          transform="translate(-20.1 38.84) rotate(-13.22)"
        />
        <path
          className="cls-4"
          d="M176.49,128.84V100.48h7.9A2.5,2.5,0,0,0,186.88,98V82.2a2.5,2.5,0,0,0-2.49-2.49h-.31a2.5,2.5,0,0,0-2.49,2.49V93.67a1.52,1.52,0,0,1-1.52,1.52h-3.58V79.65a3.36,3.36,0,0,0-3.36-3.36h0a3.35,3.35,0,0,0-3.36,3.36v24.1h-2.39a1.53,1.53,0,0,1-1.53-1.53V86.6a2.7,2.7,0,0,0-2.12-2.69,2.64,2.64,0,0,0-3.16,2.59v19.89h0a2.64,2.64,0,0,0,2.64,2.64h6.56v19.81a.72.72,0,0,0,.72.72h5.28A.72.72,0,0,0,176.49,128.84Z"
        />
        <path
          className="cls-3"
          d="M256,120.48V104.42h5.83a1.84,1.84,0,0,0,1.84-1.84V90.93a1.83,1.83,0,0,0-1.84-1.83h-.23a1.83,1.83,0,0,0-1.83,1.83v8.46a1.13,1.13,0,0,1-1.13,1.13H256V85a2.57,2.57,0,0,0-2.22-2.61A2.48,2.48,0,0,0,251,84.83v8.92h-1.77a1.12,1.12,0,0,1-1.12-1.13V87.77a2,2,0,0,0-1.63-2,2,2,0,0,0-2.27,1.93v8h0a2,2,0,0,0,1.95,2H251v22.83a.72.72,0,0,0,.72.72h3.51A.72.72,0,0,0,256,120.48Z"
        />
        <path
          className="cls-5"
          d="M260.51,118.91a5.83,5.83,0,0,0-1.29,1.7c-.12.29-.19.57-.35.59s-.53-.6-.73-1.16a9,9,0,0,0-2.2-3.41,32.19,32.19,0,0,0-3-2.6,6.68,6.68,0,0,1,1.9.6,14,14,0,0,1-1.69-2.42c-.09-.21-.36-.48-.11-.67s.5.12.67.31a2.84,2.84,0,0,1,.59.84c.73,2,2.31,3.41,3.55,5.3a33.74,33.74,0,0,0-4.8-10.29c1.05.33,2.69,2.49,3.57,4.66.76,1.87,1.14,3.86,1.89,5.75-.58-2.91-1.17-5.81-1.75-8.72,1.62,2.48,1.83,5.32,2.15,8.13a72.28,72.28,0,0,0-.2-10.91,12.64,12.64,0,0,1,1.08,4.84,16.36,16.36,0,0,1,1.88-3.09,4.55,4.55,0,0,1-.68,2.5,11.42,11.42,0,0,0-1.24,3.39c-.26.59.11,1.15.08,1.72,0,.81-.26,1.61-.33,2.35a14,14,0,0,1,.67-3.28c.21-.5.3-1.09.88-1.36a7.18,7.18,0,0,1,1.53-1.66,33.13,33.13,0,0,0-2.36,6.15c1.24-3.06,2.59-6,6.08-7.18-3.22,2.71-4.35,4.47-4.74,5.61A6.27,6.27,0,0,1,260.51,118.91Z"
        />
        <path
          className="cls-6"
          d="M189.37,126.09a5,5,0,0,1-1-.49c-.54-.34-.83-.71-1.39-1.08a5.86,5.86,0,0,0-.94-.5,7.87,7.87,0,0,0-3.84-.8c-1.23.1-1,.46-2.46.57-1,.08-1.56-.08-2.27.31-.51.29-.37.45-1.05,1a17.92,17.92,0,0,1-2.14,1.13A5.08,5.08,0,0,0,173,127a3.07,3.07,0,0,0-.59,2.54h19.78a1.22,1.22,0,0,0,.73-.7,1.36,1.36,0,0,0-.19-1,2.79,2.79,0,0,0-.74-1C191.29,126.31,190.79,126.63,189.37,126.09Z"
        />
      </g>
    </g>
  </svg>
);

export default NoStaffList;
