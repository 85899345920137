import React from 'react';
import gql from 'graphql-tag';
import { components, colors, elements } from '@peachjar/components';
import FormHelperText from '@material-ui/core/FormHelperText';
import Joi from 'joi-browser';
import CallToAction from '../../../CallToAction';
import withValidation from '../../../../../../../shared/components/withValidation';
import { MISSED_FIELD_ERROR_MESSAGE } from '../../../../../../../shared/constants';
import EmailFragments from './gql/EmailFragments.graphql';

const { TextInput } = components;
const { dragon } = colors;
const { Note } = elements.typography;

const name = 'Email';
const LABEL = 'Email Address';

export const Email = React.memo(
  ({
    isSelected,
    email,
    handleInputChange,
    handleCancel,
    handleSelection,
    performValidation,
    shouldPerformDisabledClickValidation,
  }) => {
    const props = {
      label: name,
      name,
      isSelected,
      handleSelection,
      handleCancel,
    };

    if (email) {
      performValidation({
        target: {
          name,
          value: email,
        },
      });
    }

    const isError = shouldPerformDisabledClickValidation && email.length === 0;

    // TODO: There was never any real validation/error notification here, currently just showing You missed this. Error when email is '' and shouldPerformDisabledClickValidation is true
    return (
      <CallToAction {...props}>
        <div style={{ padding: '1rem 0' }}>
          <TextInput
            label={LABEL}
            error={isError}
            name="Email"
            data-testid="inputEmailAddress"
            type="email"
            value={email}
            onBlur={event =>
              performValidation({
                target: {
                  name,
                  value: event.target.value,
                },
              })
            }
            onChange={event => {
              handleInputChange({
                target: {
                  value: {
                    email: event.target.value,
                    __typename: 'Email',
                  },
                },
              });
            }}
          />
          {isError && (
            <FormHelperText>
              <Note
                style={{ color: dragon }}
                data-testid="urlInputErrorMessage"
              >
                {MISSED_FIELD_ERROR_MESSAGE}
              </Note>
            </FormHelperText>
          )}
        </div>
      </CallToAction>
    );
  }
);

Email.fragments = EmailFragments;

export const validate = email => {
  const schema = Joi.string()
    .email({ minDomainAtoms: 2 })
    .required();
  const result = Joi.validate(email, schema);
  return !result.error;
};
export default withValidation(Email, validate);
