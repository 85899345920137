export type ErrorMessage = {
  hasError?: boolean;
  messages: Array<string>
  operationName: string
}

type ErrorsInitialState =  ErrorMessage & {};

const SET_MESSAGES_ERRORS = 'erros/SET_MESSAGES_ERRORS';
const CLEAN_MESSAGES_ERRORS = 'erros/CLEAN_MESSAGES_ERRORS';

const initialState: ErrorsInitialState = {
  hasError: false,
  messages: [],
  operationName: ''
};

export function setMessagesErrors({ messages, operationName }: ErrorsInitialState) {
  return async dispatch => {
    dispatch({
      type: SET_MESSAGES_ERRORS,
      payload: { 
        hasError: true,
        messages,
        operationName
      },
    });
  };
}

export function cleanMessagesErrors() {
  return async dispatch => {
    dispatch({
      type: CLEAN_MESSAGES_ERRORS,
      payload: initialState
    });
  };
}

export function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MESSAGES_ERRORS:
      return {
        ...state,
        ...payload,
      };
    case CLEAN_MESSAGES_ERRORS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
