import React from 'react';
import { css } from 'react-emotion';
import { colors } from '@peachjar/components';

const { prussian } = colors;

const styles = {
  miniCTA: css`
    display: inline-block;
    font-size: 0.815rem;
    line-height: 1.23;
    border-radius: 3px;
    border: 1px solid ${prussian};
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    cursor: default;

    &:last-child {
      margin-right: 0;
    }
  `,
};

const MiniCTA = ({ children }) => (
  <div className={styles.miniCTA}>{children}</div>
);

export default MiniCTA;
