import React from 'react';
import AudienceBox from './AudienceBox';

const DistrictAudiences = ({ handleInputChange, selectedAudience }) => (
  <>
    <AudienceBox
      type="parents"
      handleInputChange={handleInputChange}
      selectedAudience={selectedAudience}
    />
    <AudienceBox
      type="staff"
      handleInputChange={handleInputChange}
      selectedAudience={selectedAudience}
    />
  </>
);

export default DistrictAudiences;
