import React, { ReactElement } from 'react';
import { css } from 'emotion';
import emptyStateSVG from '../../assets/empty_state.svg';

type Props = {
  children: any;
  [key: string]: any
};

const EmptyState: React.FunctionComponent<Props> = ({ children }): ReactElement => (
  <div className={cn.emptyState} data-testid="element-empty-state">
    <img src={emptyStateSVG} alt="empty"/>
    <div className={cn.emptyStateContent}>
      {children}
    </div>
  </div>
);

const cn = {
  emptyState: css`
    text-align: center;
  `,
  emptyStateContent: css`
    margin: 15px 0;
  `,
};

export default EmptyState;
