import React, { useEffect as ue, useState } from 'react';
import { graphql, compose } from 'react-apollo';
import { get, filter, intersection, range } from 'lodash';
import { css } from 'emotion';
import { colors, elements } from '@peachjar/components';
import FormHelperText from '@material-ui/core/FormHelperText';
import SchoolSelector from './SchoolSelector';
import OrgSearchForSchools from './Org/OrgSearchForSchools/index';
import { SET_CHECKBOX_SELECTIONS_MUTATION } from '../../../gql/Delivery.graphql';
import {
  toggleCheckbox,
  toggleCheckboxes,
  toggleSubsetOfCheckboxes,
  toggleAllCheckboxes,
} from './utils';

const { dragon } = colors;
const { Note } = elements.typography;

export const useEffectFactory = ({
  checkboxSelections,
  shouldPerformDisabledClickValidation,
  setErrorText,
}) => {
  const useEffectCallback = () => {
    if (shouldPerformDisabledClickValidation) {
      setErrorText(
        checkboxSelections.length === 0 ? 'You missed this requirement.' : ''
      );
    }
  };
  return useEffectCallback;
};
const styles = {
  parentsStaffPadding: css`
    margin-top: 0px;
  `,
  padding: css`
    margin-top: 2rem;
  `,
  errorWrapper: css`
    border-style: solid;
    border-width: 1px;
    border-color: ${dragon};
  `,
  errorText: css`
    color: ${dragon};
  `,
};
export const SodSelections = ({
  isSingleCheckboxAltered,
  setIsSingleCheckboxAltered,
  districts,
  eventLocation,
  selectedAudience,
  isCommunityFree = false,
  uploaderLevel = 'district',
  checkboxSelections,
  setCheckboxSelections,
  setDeliveryCache,
  onSelectionUpdated,
  minGradeLevel,
  maxGradeLevel,
  shouldPerformDisabledClickValidation,
  toggleDistrictGuidelinesConfirmed,
  areDistrictGuidelinesConfirmed,
  handleGuidelinesIsValid,
  useEffect = ue,
  hasCommFreeSchoolSelectionLimitError,
  communityFreeSchoolSelectionLimit,
}) => {
  const targetSchools = get(districts, `0.audiences.${selectedAudience}`, []);
  const isSchoolUploaderWithOnlyOneSchool =
    uploaderLevel === 'school' && targetSchools.length === 1;
  const [errorText, setErrorText] = useState('');
  useEffect(
    useEffectFactory({
      checkboxSelections,
      shouldPerformDisabledClickValidation,
      setErrorText,
    }),
    [isSchoolUploaderWithOnlyOneSchool, checkboxSelections]
  );
  const isDistrictOrSchool =
    uploaderLevel === 'district' || uploaderLevel === 'school';

  return (
    <>
      <div
        className={
          isDistrictOrSchool ? styles.parentsStaffPadding : styles.padding
        }
      />

      {/* <div className={styles.padding} /> */}
      <div className={!isDistrictOrSchool && errorText && styles.errorWrapper}>
        {/* TODO: Put this search back and make it functional */}
        {/* <SodSearch className="mt-2" /> */}

        {(isDistrictOrSchool || !isSchoolUploaderWithOnlyOneSchool) &&
          uploaderLevel !== 'org' &&
          !isCommunityFree && (
            <SchoolSelector
              isSingleCheckboxAltered={isSingleCheckboxAltered}
              setIsSingleCheckboxAltered={setIsSingleCheckboxAltered}
              uploaderLevel={uploaderLevel}
              districts={districts}
              selectedAudience={selectedAudience}
              toggleAllCheckboxes={toggleAllCheckboxes}
              toggleCheckboxes={toggleCheckboxes}
              toggleCheckbox={toggleCheckbox}
              setCheckboxSelections={setCheckboxSelections}
              checkboxSelections={checkboxSelections}
              onSelectionUpdated={onSelectionUpdated}
            />
          )}

        {(uploaderLevel === 'org' || isCommunityFree) && (
          <OrgSearchForSchools
            setDeliveryCache={setDeliveryCache}
            eventLocation={eventLocation}
            selectedAudience={selectedAudience}
            toggleSubsetOfCheckboxes={toggleSubsetOfCheckboxes}
            toggleCheckbox={toggleCheckbox}
            setCheckboxSelections={setCheckboxSelections}
            isCommunityFree={isCommunityFree}
            checkboxSelections={checkboxSelections}
            onSelectionUpdated={onSelectionUpdated}
            minGradeLevel={minGradeLevel}
            maxGradeLevel={maxGradeLevel}
            toggleDistrictGuidelinesConfirmed={
              toggleDistrictGuidelinesConfirmed
            }
            areDistrictGuidelinesConfirmed={areDistrictGuidelinesConfirmed}
            shouldPerformDisabledClickValidation={
              shouldPerformDisabledClickValidation
            }
            handleGuidelinesIsValid={handleGuidelinesIsValid}
            hasCommFreeSchoolSelectionLimitError={
              hasCommFreeSchoolSelectionLimitError
            }
            communityFreeSchoolSelectionLimit={
              communityFreeSchoolSelectionLimit
            }
          />
        )}
      </div>
      {!isDistrictOrSchool && errorText && (
        <FormHelperText>
          <Note className={styles.errorText}>{errorText}</Note>
        </FormHelperText>
      )}
    </>
  );
};

// export default graphql(ORG_SEARCH_QUERY, orgSearchConfig);
export default compose(
  graphql(SET_CHECKBOX_SELECTIONS_MUTATION, { name: 'setCheckboxSelections' })
)(SodSelections);
