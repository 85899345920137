import React from 'react';
import { Link } from 'react-router-dom';

export default function getLink(
  content: any,
  link: string,
  linkType: string,
  className: string,
  onClick?: () => void
): React.ReactElement {
  if (linkType === 'internal') {
    return (
      <Link to={link} className={className} onClick={onClick}>
        {content}
      </Link>
    );
  }

  return (
    <a href={link} className={className} onClick={onClick}>
      {content}
    </a>
  );
}
