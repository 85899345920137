import gql from 'graphql-tag';
import GetAppFragments from '../components/GetApp/gql/GetAppFragments.graphql';
import SignUpFragments from '../components/SignUp/gql/SignUpFragments.graphql';
import CallFragments from '../components/Call/gql/CallFragments.graphql';
import EmailFragments from '../components/Email/gql/EmailFragments.graphql';
import DonateFragments from '../components/Donate/gql/DonateFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment SecondaryCTAsGroup_getMyCampaigns on Campaign {
      ...GetApp_getMyCampaigns
      ...SignUp_getMyCampaigns
      ...Call_getMyCampaigns
      ...Email_getMyCampaigns
      ...Donate_getMyCampaigns
    }
    ${GetAppFragments.getMyCampaigns}
    ${SignUpFragments.getMyCampaigns}
    ${CallFragments.getMyCampaigns}
    ${EmailFragments.getMyCampaigns}
    ${DonateFragments.getMyCampaigns}
  `,
};
