import React from 'react';

import { css } from 'react-emotion';
import { colors } from '@peachjar/components';

const { prussian, silver, white } = colors;

const styles = {
  selectionBox: css`
    background-color: ${white};
    border-radius: 7px;
    border: 1px solid ${prussian};
    min-height: 56px;
    padding: 0.5rem 2rem;
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }

    &:focus {
      outline: 0;
    }
  `,
  unselected: css`
    border-color: ${silver};
  `,
};

const CallToAction = React.memo(
  ({ name, label, isSelected, children, handleCancel, handleSelection }) => {
    if (isSelected) {
      return (
        <>
          <button
            type="button"
            data-testid={`${name}CancelSelectionButton`}
            onClick={() => handleCancel('')}
            className={`flex ${styles.selectionBox} `}
          >
            <span style={{ fontWeight: 600 }}>x &nbsp;</span> {label}
          </button>
          {children}
        </>
      );
    }

    return (
      <button
        type="button"
        data-testid={`${name}SelectionButton`}
        onClick={() => handleSelection(name)}
        className={`flex ${styles.selectionBox} ${styles.unselected} `}
      >
        <span style={{ fontWeight: 600 }}>+ &nbsp;</span> {label}
      </button>
    );
  }
);

export default CallToAction;
