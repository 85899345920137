const infoPopoverConstants = {
  orgTypes: {
    profit: 'For-Profit',
    nonprofit: 'Non-Profit',
    government: 'Government',
    unknown: 'Unknown',
  },
};

export default infoPopoverConstants;
