import FlyerDetails from '../../_app/components/AppDrawer/FlyerDetails';
import FlyerRemoval from '../../_app/components/AppDrawer/FlyerRemoval';
//register components to be rendered in app drawer here
export const APP_DRAWER_COMPONENTS = {
  FlyerDetails: {
    drawerTitle: 'Flyer Details',
    component: FlyerDetails,
  },
  FlyerRemoval: {
    drawerTitle: 'Flyer Removal',
    component: FlyerRemoval
  }
}