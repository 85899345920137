import React from 'react'
import { css } from 'emotion'
import { colors } from '@peachjar/components';
import cyberSaleContent from './cyberSale.json';
import isCyberSaleLive from '../../../../helpers/deals/isCyberSaleLive';

const CyberSaleDisclaimer = () => {

  if(!isCyberSaleLive()) return null

  return (  
    <div className={cn.container}>
      <p className={cn.text}>
       {cyberSaleContent.disclaimer}
      </p>
    </div>               
  )
}

const cn = { 
  container : css`
    position: relative;    
  `,
  text: css`
    font-size: 13px;    
    margin-top: 6px;
    color: ${colors.slate};
  `,
}

export default CyberSaleDisclaimer;