import React, { useContext } from 'react';

const DealsContext = React.createContext({
  credits: 0,
  promoCodes: [],
  starterKitAdded: false,
});

export const useDealsContext = () => useContext(DealsContext);

export default DealsContext;
