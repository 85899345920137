import React, { Component } from 'react';
import AppDrawerContext from '../../context/AppDrawerContext';
import { APP_DRAWER_COMPONENTS } from '../../config/appDrawerConfig';

export default class AppDrawerProvider extends Component {
  constructor(props) {
    super(props);

    this.toggleDrawer = (options: object = {}) => (this.setState({ isOpen: !this.state.isOpen, contextProps: {...options} }));
    this.setDrawerContext = componentName => (this.setState({ context: APP_DRAWER_COMPONENTS[componentName] }));

    this.state = {
      isOpen: false,
      context: APP_DRAWER_COMPONENTS.FlyerDetails, //default component to render inside drawer
      contextProps: {},
      toggleDrawer: this.toggleDrawer,
      setDrawerContext: this.setDrawerContext
    }
  }

  render() {
    return (
      <AppDrawerContext.Provider value={this.state}>
        { this.props.children }
      </AppDrawerContext.Provider>
    );
  }
}
