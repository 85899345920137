import gql from 'graphql-tag';

export default {
  getMyCampaigns: gql`
    fragment GetApp_getMyCampaigns on Campaign {
      secondaryCallToAction {
        id
        type
        data
      }
    }
  `,
};
