import * as React from 'react';
import { css } from 'emotion';
import { colors, components } from '@peachjar/components';
import { SIDEBAR_WIDTH } from '../../constants';

const { VerticalNav } = components;

type Props = {
  drawerOpen: boolean,
  onDrawerOpen: (eventName: string) => any,
  children: React.ReactNode,
};

const Sidebar = ({ drawerOpen, onDrawerOpen, children }: Props) => (
  <div
    onMouseEnter={() => onDrawerOpen('enter')}
    onMouseLeave={() => onDrawerOpen('leave')}
    className={`sidebar-drawer ${classNames.sidebar} ${
      drawerOpen ? classNames.drawerOpen : ''
    }`}
  >
    <div className={classNames.sidebarNav}>
      <VerticalNav label="Main Site Navigation">{children}</VerticalNav>
    </div>
  </div>
);

const classNames = {
  sidebar: css`
    overflow-x: hidden;
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    border-right: 1px solid ${colors.mercury};
    position: absolute;
    height: 100%;
    top: 56px;
    left: 0;
    z-index: 2;
    background-color: #f5f5f5;
    width: ${SIDEBAR_WIDTH.md};

    @media screen and (min-width: 1380px) {
      position: static;
    }

    @media screen and (min-width: 1920px) {
      li > a {
        padding: 1rem 1rem 1rem 0.5rem;
      }
    }

    @media screen and (max-width: 1380px) {
      width: ${SIDEBAR_WIDTH.sm};
      transition: width 0.3s ease-in-out;

      li > a > span > span:first-of-type {
        opacity: 0;
        transition-property: opacity;
        transition-delay: 0.3s;
      }
    }
  `,
  drawerOpen: css`
    @media screen and (max-width: 1380px) {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
      width: ${SIDEBAR_WIDTH.md};

      li > a > span > span:first-of-type {
        opacity: 1;
        transition-delay: 0s;
      }
    }
  `,
  sidebarNav: css`
    width: ${SIDEBAR_WIDTH.md};
  `,
};

export default Sidebar;
