import FlyerTitle from './FlyerTitle';
import withValidation from '../../../../../shared/components/withValidation';
import {
  MAX_FLYER_TITLE_LENGTH,
} from '../../../../../shared/constants';

export const validate = ({ flyerTitle }) =>
  flyerTitle &&
  flyerTitle.length <= MAX_FLYER_TITLE_LENGTH &&
  flyerTitle.length > 0;

export default withValidation(FlyerTitle, validate, null);
