import config from './config';

const {
  ENVIRONMENT,
  REACT_APP_GEOCORE_LOGIN_URL,
  REACT_APP_PEACHJAR_HOMEPAGE,
  REACT_APP_PARENT_APP_URL,
  REACT_APP_ACCOUNTS_APP_URL,
} = config;

export const isProd = ENVIRONMENT === 'production' || ENVIRONMENT === 'staging';
export const PEACHJAR_HOMEPAGE = REACT_APP_PEACHJAR_HOMEPAGE;
export const PARENT_APP_URL = REACT_APP_PARENT_APP_URL;
export const GEOCORE_LOGIN_URL = REACT_APP_GEOCORE_LOGIN_URL;
export const ACCOUNTS_APP_URL = REACT_APP_ACCOUNTS_APP_URL;
