import * as React from 'react';
import idx from 'idx';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { filter as gqlFilter } from 'graphql-anywhere';
import { css } from 'emotion';
import { components } from '@peachjar/components';

import { GraphQLStuff } from '../types';
// import { AllApprovals_sodUser } from './__generated__/AllApprovals_sodUser';
import HistoricalApprovals from './containers/HistoricalApprovals';
import SubappHeader from '../_app/components/SubappHeader';
import areRegionsMultiDistrict from './areRegionsMultiDistrict';
import validateMultiRegionSod from '../helpers/validateMultiRegionSod';
import getHighestSodLevel from '../helpers/getHighestSodLevel';
import { APP_ROUTES } from '../_app/constants';
import MiniBreadcrumb from '../components/MiniBreadcrumb';
import { RoleCheckWithRedirect } from '../_app/components/RoleCheck';
import { allApprovalsAllowedRoles } from '../_app/RolesEnum';

const { ButtonSecondarySmall } = components.Buttons;

type Props = {
  gqlStuff: GraphQLStuff;
  sodUser: object;
};

class AllApprovals extends React.Component<Props> {
  static fragments: { [key: string]: any };

  updateQueryVariables = (updates: {
    offset?: number;
    filter?: string;
  }): void => {
    const { gqlStuff } = this.props;
    const { offset, filter } = updates;

    const {
      AllApprovals_sodUser_offset,
      AllApprovals_sodUser_filter,
    } = gqlStuff.currentQueryVariables;
    const newVariables = {
      AllApprovals_sodUser_offset:
        offset === undefined ? AllApprovals_sodUser_offset : offset,
      AllApprovals_sodUser_filter: filter || AllApprovals_sodUser_filter,
    };

    gqlStuff.updateQueryVariables({
      ...gqlStuff.currentQueryVariables,
      ...newVariables,
    });
  };

  render() {
    // @ts-ignore
    const { sodUser, gqlStuff, flags, flyerReasons, history } = this.props;
    const { portal_nav } = flags;
    const adminLevel = idx(sodUser, _ => _.permission.adminLevel) || null;
    const approverLevel = idx(sodUser, _ => _.permission.approverLevel) || null;
    const isDistrictAdmin = adminLevel === 'district';
    const sod = validateMultiRegionSod(adminLevel, approverLevel);
    const approvalCenterRegions =
      idx(sodUser, _ => _.approvalCenterRegions) || [];
    const shouldUserSeeApproverNotesButton = !areRegionsMultiDistrict(
      approvalCenterRegions
    );
    const sodRole = getHighestSodLevel(sodUser);
    const isPortalNavEnabled = portal_nav === 1;

    return (
      <RoleCheckWithRedirect roles={allApprovalsAllowedRoles}>
        <div>
          {isPortalNavEnabled && (
            <MiniBreadcrumb
              linkTo={APP_ROUTES.approvals.index}
              text="Approvals"
            />
          )}
          <div className={`${isPortalNavEnabled ? cn.sectionHeadline : ''}`}>
            <SubappHeader
              className={`${isPortalNavEnabled ? cn.subappHeader : ''}`}
            >
              All Approvals
            </SubappHeader>
            {isPortalNavEnabled && sodRole === 'APPROVER' && (
              <div className={cn.toggles}>
                <ButtonSecondarySmall
                  data-testid="click-toggle-my-approvals"
                  onClick={() =>
                    history.push(APP_ROUTES.approvals.history.myApprovals)
                  }
                  className={cn.noBorderRadiusRight}
                >
                  My Approvals
                </ButtonSecondarySmall>
                <ButtonSecondarySmall
                  data-testid="click-toggle-all-approvals"
                  disabled={true}
                  className={cn.noBorderRadiusLeft}
                >
                  All Approvals
                </ButtonSecondarySmall>
              </div>
            )}
          </div>
          <HistoricalApprovals
            flyerList={gqlFilter(
              HistoricalApprovals.fragments.flyerList,
              idx(sodUser, _ => _.sodFlyerApprovalHistory) || {}
            )}
            flyerReasons={gqlFilter(
              HistoricalApprovals.fragments.flyerReasons,
              flyerReasons
            )}
            isDistrictAdmin={isDistrictAdmin}
            sod={sod}
            updateQueryVariables={this.updateQueryVariables}
            // TODO
            loading={gqlStuff.apolloHelpers.loading}
            offset={
              gqlStuff.currentQueryVariables.AllApprovals_sodUser_offset || 0
            }
            shouldUserSeeApproverNotesButton={shouldUserSeeApproverNotesButton}
          />
        </div>
      </RoleCheckWithRedirect>
    );
  }
}

const cn = {
  sectionHeadline: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  subappHeader: css`
    padding-top: 1.75rem !important;
  `,
  toggles: css`
    margin-top: -9px;
  `,
  noBorderRadiusRight: css`
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  `,
  noBorderRadiusLeft: css`
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  `,
};

AllApprovals.fragments = {
  sodUser: gql`
    fragment AllApprovals_sodUser on SodUser {
      id
      permission {
        adminLevel
        approverLevel
      }
      approvalCenterRegions {
        districtId
      }
      sodFlyerApprovalHistory(
        limit: $AllApprovals_sodUser_limit
        offset: $AllApprovals_sodUser_offset
        filter: $AllApprovals_sodUser_filter
      ) {
        ...HistoricalFlyerList_flyers
      }
    }
    ${HistoricalApprovals.fragments.flyerList}
  `,
  flyerReasons: gql`
    fragment AllApprovals_flyerReasons on FlyerReason {
      ...HistoricalApprovals_flyerReasons
    }
    ${HistoricalApprovals.fragments.flyerReasons}
  `,
};

export default connect(
  state => {
    const flags = get(state, 'flags');
    return {
      flags,
    };
  },
  null
)(AllApprovals);
