import React from 'react';
import { css } from 'react-emotion';
import { fonts } from '@peachjar/components';

const { ProximaNova } = fonts;
const PJ_LOGO = 'https://cdn-assets.peachjar.com/pj-logo-header.png';

const PeachjarInformationsReport = () => (
  <div className={styles.logoWrapper}>
    <div className={styles.address}>
      <p className={styles.addressText}>Peachjar, Inc.</p>
      <p className={styles.addressText}>15373 Innovation Dr., Ste. 300</p>
      <p className={styles.addressText}>San Diego, CA 92128</p>
      <p className={styles.addressText}>(858) 997-2117</p>
    </div>
    <img src={PJ_LOGO} height="32px" />
  </div>
);

const styles = {
  address: css`
    display: flex;
    flex-direction: column;
  `,
  addressText: css`
    font-family: ${ProximaNova};
    color: rgb(123, 123, 123);
    font-size: 16px;
    margin: 0;
    line-height: 24px;
  `,
  logoWrapper: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 56px;
  `,
};

export default PeachjarInformationsReport;
