import gql from 'graphql-tag';

const ARE_SAFE_URLS_QUERY = gql`
  query areSafeUrls($urls: [String]) {
    areSafeUrls(urls: $urls) {
      url
      isSafe
      isReachable
    }
  }
`;

export default ARE_SAFE_URLS_QUERY;
