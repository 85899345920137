import React, { Component } from 'react';
import { elements } from '@peachjar/components';
import { get, some } from 'lodash';
import OcrPages from './components/OcrPages';
import SaveFlyerUserTextButton from './components/SaveFlyerUserTextButton';
import ReuploadFlyerButton from './components/ReuploadFlyerButton';
import routePushHandler from '../../../../shared/routePushHandler';
import OcrFragments from './gql/OcrFragments.graphql';

const { Headline2, Paragraph } = elements.typography;

type State = {
  userTextEdits: string[],
};

type Props = {
  campaign: Object,
  history: Object,
  campaignId: string,
  onSaveFailed: (campaignId: string) => void,
  setUserTexts: ({ variables: Object }) => Promise<any>,
};

export class Ocr extends Component<Props, State> {
  static fragments: { [key: string]: any };

  state = {
    userTextEdits: [],
  };

  setUserTextEditsState = (index: number, value: string) => {
    const { setUserTexts } = this.props;
    this.setState(previousState => {
      const updatedUserTexts = Object.assign([...previousState.userTextEdits], {
        [index]: value,
      });
      setUserTexts({
        variables: {
          userTexts: updatedUserTexts,
        },
      });
      return {
        userTextEdits: updatedUserTexts,
      };
    });
  };

  render() {
    const { campaign, history, campaignId, onSaveFailed } = this.props;
    const { userTextEdits } = this.state;

    return (
      <div>
        <Headline2>Review and Edit Text Version</Headline2>
        <div className="mt-2">
          <Paragraph>
            To help blind or visually impaired recipients to read your flyer,
            we extracted its content and established a text version of your flyer.
            The design of your flyer may require you to edit the text version. <strong>
            Please review carefully to ensure the text version of your flyer is correct.</strong>
          </Paragraph>
        </div>

        <div className="mt-4">
          <Paragraph>Select anywhere in the text box to edit.</Paragraph>
        </div>
        <div className="mt-4">
          <OcrPages
            pages={get(campaign, 'flyer.flyerPages', [])}
            setUserTextEditsState={this.setUserTextEditsState}
            userTextEdits={userTextEdits}
          />
        </div>

        <div className="layout-row layout-align-end-center mt-5">
          <ReuploadFlyerButton history={history} campaignId={campaignId}>
            Continue to Set Flyer Details
          </ReuploadFlyerButton>

          <div className="ml-3">
            <SaveFlyerUserTextButton
              campaignId={get(campaign, 'id') || campaignId}
              userTexts={userTextEdits}
              disabled={some(userTextEdits, userText => userText.length === 0)}
              handleSuccess={routePushHandler(
                history,
                `/campaigns/create-campaign/${get(
                  campaign,
                  'id',
                  campaignId
                )}/details/`
              )}
              onSaveFailed={() =>
                onSaveFailed(get(campaign, 'id') || campaignId)
              }
            >
              Continue to Set Flyer Details
            </SaveFlyerUserTextButton>
          </div>
        </div>
      </div>
    );
  }
}

Ocr.fragments = OcrFragments;

export default Ocr;
