import React from 'react';
import { css } from 'emotion';
import idx from 'idx';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { elements, components } from '@peachjar/components';
import Paper from '@material-ui/core/Paper';
import SubappHeader from '../_app/components/SubappHeader';
import HelpCard from '../../src/components/HelpCard';
import ManageSettings from '../assets/Illustration_Settings.svg';
import CreateFlyers from '../assets/Illustration_FlyerGuidelines_Fancy.svg';
import UploadPdf from '../assets/Illustration_Upload_Pdf.svg';
import ApproveManageFlyers from '../assets/Illustration_Approvals.svg';
import { styles } from './Help';

const { Headline2, Paragraph } = elements.typography;
const { ButtonSecondarySmall } = components.Buttons;

const openWindow = url => window.open(url, '_blank');

const HelpStaff = ({ classes, sodUser }) => {
  const approverLevel = idx(sodUser, _ => _.permission.approverLevel) || null;
  const adminLevel = idx(sodUser, _ => _.permission.adminLevel) || null;
  const uploaderLevel = idx(sodUser, _ => _.permission.uploaderLevel) || null;

  return (
    <div>
      <SubappHeader>Help</SubappHeader>
      <Paper className={`${styles.container} ${classes.elevation2}`}>
        <div className={styles.subContainer}>
          <Headline2>Training Guides</Headline2>
          <Paragraph className={styles.paragraph}>
            View the guides below to help you with your role and get the most
            out of Peachjar.
          </Paragraph>

          <div className={styles.guideContainer}>
            <Grid container spacing={24}>
              {adminLevel && (
                <Grid item xs={4}>
                  <HelpCard
                    // needs to point to an S3 URL so the pdf isn't hosted locally
                    link="https://info.peachjar.com/manage-school-and-district-settings"
                    icon={ManageSettings}
                    headline="How to Manage Settings"
                    className={styles.newFlyerButton}
                    dataTestId="click-manage-settings-help"
                    tag="Admins"
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <HelpCard
                  link="https://dev-portal-webapp.s3.us-west-2.amazonaws.com/Guide%20to%20Digital%20Flyer%20Success%20for%20School%20Staff%202019.pdf"
                  icon={CreateFlyers}
                  headline="How to Create Successful Flyers"
                  className={styles.newFlyerButton}
                  dataTestId="click-create-flyers-help"
                  tag="Uploaders"
                />
              </Grid>
              {uploaderLevel && (
                <Grid item xs={4}>
                  <HelpCard
                    link="https://info.peachjar.com/how-to-upload-flyers"
                    icon={UploadPdf}
                    headline="How to Send & Post Flyers"
                    className={styles.newFlyerButton}
                    dataTestId="click-upload-pdf-help"
                    tag="Uploaders"
                  />
                </Grid>
              )}
              {approverLevel && (
                <Grid item xs={4}>
                  <HelpCard
                    link="https://info.peachjar.com/how-to-approve-and-manage-flyers"
                    icon={ApproveManageFlyers}
                    headline="How to Approve &amp; Manage Flyers"
                    className={styles.newFlyerButton}
                    dataTestId="click-approve-manage-help"
                    tag="Approvers"
                    />
                </Grid>
              )}
            </Grid>
          </div>
        </div>

        <div className={styles.subContainer}>
          <Headline2>FAQs</Headline2>

          <Paragraph className={styles.paragraph}>
            Looking for information? Check out our frequently asked questions.
          </Paragraph>
          <ButtonSecondarySmall
            className={styles.button}
            onClick={() => openWindow('https://peachjar.com/faq')}
            rel="noopener"
          >
            View FAQs
          </ButtonSecondarySmall>
        </div>
        <div className={styles.subContainer}>
          <Headline2>Contact Us</Headline2>
          <Paragraph className={styles.paragraph}>
            Get in touch and let us know how we can help!
          </Paragraph>

          <ButtonSecondarySmall
            className={styles.button}
            onClick={() => openWindow('https://peachjar.com/contact-us')}
            rel="noopener"
          >
            Contact Peachjar
          </ButtonSecondarySmall>
        </div>
      </Paper>
    </div>
  );
};

HelpStaff.fragments = {
  sodUser: gql`
    fragment Help_sodUser on SodUser {
      id
    }
  `,
};

const materialUIstyles = {
  elevation2: {
    boxShadow: 'none',
  },
};

export default withStyles(materialUIstyles)(HelpStaff);
