import React, { Component } from 'react';
import { omit, get } from 'lodash';
import debounce from 'debounce-promise';
import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import {
  colors,
  elements,
  components as pjComponents,
} from '@peachjar/components';
import GET_SCHOOL_FINDER_AUTOCOMPLETIONS_QUERY from '../../../gql/GetSchoolFinderAutocompletionsQuery.graphql';
import { apolloClient as bffClient } from '../../../../../../../../../../_app/apollo/portalBFF.apolloClient';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { Geocode } from '../../../types';

const { Note, Paragraph } = elements.typography;
const { Spinner } = pjComponents.LoadingIndicators;

const { prussian, dragon, silver } = colors;

type Option = {
  label: string;
  value: string;
};

type Props = {
  formattedAddress: string;
  onChange: (event: any) => void;
  onKeyDown: (event: any) => void;
  onSelect: (option: Option) => void;
  className?: string;
  center: Geocode;
  setSearchValue: (value: string) => void,
  setInputValue: (value: string) => void,
};

type State = {
  input: string;
  selectedOption: Option | null;
  isFocused: boolean;
  isTyping: boolean;
};

const getPromiseOptions = debounce(
  async (inputValue: string, centerPoint: Geocode) => {
    const schoolFinderAutocompletions = await bffClient.query({
      query: GET_SCHOOL_FINDER_AUTOCOMPLETIONS_QUERY,
      variables: {
        filter: inputValue,
        centerPoint: omit(centerPoint, '__typename'),
      },
    });

    const {
      data: {
        getSchools: { schools },
        getDistricts: { districts },
      },
    } = schoolFinderAutocompletions;
    let {
      data: {
        getPlacesAutocomplete: { places },
      },
    } = schoolFinderAutocompletions;

    places = places.slice(0, 3);

    const placesOptions = places.map(place => ({
      value: place.description.toLowerCase(),
      label: place.description,
      placeId: place.placeId,
      searchBy: 'location',
    }));
    const schoolsOptions = schools.map(school => ({
      value: school.name.toLowerCase(),
      label: school.name,
      district: school.district,
      location: school.location,
      placeId: school.placeId,
      searchBy: 'school',
    }));
    const districtsOptions = districts.map(district => ({
      value: district.name.toLowerCase(),
      label: district.name,
      location: district.location,
      placeId: district.placeId,
      searchBy: 'district',
    }));

    return [
      {
        label: 'Locations',
        options: placesOptions,
      },
      {
        label: 'Districts',
        options: districtsOptions,
      },
      {
        label: 'Schools',
        options: schoolsOptions,
      },
    ];
  },
  500
);

export class Autocomplete extends Component<Props, State> {
  state = { input: '', selectedOption: null, isFocused: false, isTyping: false };

  handleChange = (text: string) => {
    this.setState({
      input: text,
    });
  }

  handleSelect = (option: any) => {
    const { onSelect } = this.props;

    if (option) {
      this.setState(
        {
          input: '',
          selectedOption: option,
        },
        () => onSelect(option)
      );
    }

    this.setState({ isTyping: false });
  }

  handleIsFocused = (boolean: boolean) => {
    this.setState({ isFocused: boolean });
  }

  componentDidUpdate(prevProps) {
    const { formattedAddress, inputValue, setInputValue } = this.props;
    const { input, isFocused, selectedOption } = this.state;
    // places the org's default location in the bar, if it is not in focus.
    if (
      formattedAddress && prevProps.formattedAddress !== formattedAddress &&
      input === '' &&
      !isFocused
    ) {
      this.setState({
        selectedOption: { label: formattedAddress, value: formattedAddress },
      });
    }

    // places 'Current Map Area' in the bar after an area search
    if (
      inputValue === 'Current Map Area' &&
      get(selectedOption, 'label') !== 'Current Map Area'
    ) {
      this.setState({
        input: '',
        selectedOption: {
          label: 'Current Map Area',
          value: 'Current Map Area',
        },
      });
      setInputValue('');
    }
  }

  // this allows us to continue editing the input, instead of being stuck with the static option
  swapOptionForInputValue = () => {
    const { selectedOption } = this.state;
    const { setSearchValue  } = this.props;

    if (selectedOption) {
      this.setState({
        selectedOption: null,
        input: selectedOption.label,
      });
      // this will remove 'Current Map Area' from the search value when the user starts typing
      setSearchValue('');
    }
  };

  render() {
    const {
      onChange,
      onKeyDown,
      formattedAddress,
      className,
      placeholder,
      label,
      classes,
      theme,
      options,
      error,
      name,
      center,
    } = this.props;
    const { input, selectedOption, isTyping } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
      indicatorSeparator: base => ({
        ...base,
        display: 'none', // Hide | between ClearIndicator and DropdownIndicator
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: '4px',
      }),
      group: base => ({
        ...base,
        paddingTop: '1rem',
        '&:not(:first-child)': {
          borderTop: `1px solid ${silver}`,
        },
      }),
      option: base => ({
        ...base,
        display: 'block',
      }),
      menuList: base => ({
        ...base,
        maxHeight: 'none',
        overflowY: 'none',
      }),
    };

    return (
      <AsyncSelect
        className={className}
        classes={classes}
        name={name}
        styles={selectStyles}
        error={error}
        components={componentsToReactSelect}
        onChange={this.handleSelect}
        onInputChange={this.handleChange}
        inputValue={input}
        value={selectedOption}
        backspaceRemovesValue={false}
        onKeyDown={e => {
          const isDropdownTriggeringKey = (e.key.length === 1 || e.keyCode === 8);

          if (!isTyping && isDropdownTriggeringKey) {
            this.setState({ isTyping: true });
          }
          // backspace, arrow left, arrow right
          if (
            this.state.selectedOption &&
            (e.keyCode === 8 || e.keyCode === 37 || e.keyCode === 39)
          ) {
            this.swapOptionForInputValue();
          }
        }}
        onMouseDown={() => {
          this.swapOptionForInputValue();
        }}
        loadOptions={(inputValue: string) =>
          getPromiseOptions(inputValue, center)
        }
        onFocus={() => this.handleIsFocused(true)}
        onBlur={() => {
          this.setState({ isTyping: false });
          this.handleIsFocused(false);
        }}
        placeholder={'School / District Name, City, State, Zip'}
        isClearable={false}
        menuIsOpen={input && input.length >= 2 && isTyping}
      />
    );
  }
}

/* -------------------------------------
Start - react-select component overrides
------------------------------------- */

const NoOptionsMessage = props => {
  const { selectProps, innerProps, children } = props;

  return (
    <Note className={selectProps.classes.noOptionsMessage} {...innerProps}>
      No results found. Please try another search.
    </Note>
  );
};

const LoadingMessage = props => {
  const { selectProps, innerProps, children } = props;

  return (
    <div className="layout-row layout-align-center-center">
      <div className={selectProps.classes.loadingMessage}>
        <Spinner
          className={selectProps.classes.autocompleteSpinner}
          color="jungle"
        />
      </div>
    </div>
  );
};

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

const Control = props => {
  const { selectProps, innerRef, children, innerProps } = props;
  // Props passed to react-select Select
  const { error, name, classes, textFieldProps, onMouseDown } = selectProps;
  return (
    <TextField
      fullWidth
      error={error}
      name={name}
      onMouseDown={onMouseDown}
      InputProps={{
        classes: {
          underline: classes.underline,
          error: classes.error,
        },
        inputComponent,
        inputProps: {
          className: classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...textFieldProps}
    />
  );
};

const Option = props => {
  const {
    innerRef,
    isFocused,
    innerProps,
    isSelected,
    children,
    selectProps,
    data,
  } = props;
  const { inputValue, classes } = selectProps;
  let districtName;
  let city;
  let state;

  if (data.district) {
    districtName = data.district.name;
  }

  if (data.location) {
    city = data.location.city;
    state = data.location.stateShort;
  }

  const highlightQueryText = (text: string, filterValue: string) => {
    const reg = new RegExp(`(${filterValue})`, 'gi');
    const textParts = text.split(reg);
    return (
      <span style={{ fontWeight: 600 }}>
        {textParts.map(part =>
          part.match(reg) ? (
            <span style={{ fontWeight: 'normal' }}>{part}</span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        display: 'block',
        height: 'auto',
        minHeight: '24px',
        whiteSpace: 'normal',
        position: 'static',
      }}
      {...innerProps}
    >
      <Paragraph>{highlightQueryText(children, inputValue)}</Paragraph>
      <div>
        {districtName && (
          <Note muted>
            {districtName}
          </Note>
        )}
        {districtName && city && state && <Note muted>
            {' | '}
          </Note>
        }
        {city && state && (
          <Note muted style={{ textTransform: 'capitalize' }}>
            {city.toLowerCase()}, {state}
          </Note>
        )}
      </div>
    </MenuItem>
  );
};

const Placeholder = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const GroupHeading = props => {
  const { children, selectProps } = props;
  const { classes } = selectProps;
  return <Note className={classes.groupHeading}>{children}</Note>;
};

const SingleValue = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
};

const ValueContainer = props => {
  const { selectProps, children } = props;

  return <div className={selectProps.classes.valueContainer}>{children}</div>;
};

const Menu = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
};

const DropdownIndicator = props => null;

const LoadingIndicator = () => null;

// Remove close/x icon when input is clearable
const ClearIndicator = () => null;

const componentsToReactSelect = {
  Control,
  Menu,
  NoOptionsMessage,
  LoadingMessage,
  Option,
  GroupHeading,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
  LoadingIndicator,
};

/* -------------------------------------
End - react-select component overrides
------------------------------------- */

const styles = theme => ({
  root: {
    flexGrow: 1,
    fontFamily: 'Proxima Nova,Arial,Helvetica,sans-serif',
  },
  block: {
    display: 'block',
  },
  groupHeading: {
    display: 'block',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginBottom: '0.5rem',
    textTransform: 'capitalize',
  },
  input: {
    display: 'flex',
    height: '34px',
    padding: 0,
  },
  underline: {
    borderBottomColor: prussian,
    '&:after': {
      borderBottomColor: prussian,
    },
  },
  error: {
    '&$error:after': {
      borderBottomColor: dragon,
    },
  },
  autocompleteSpinner: {
    '&&': {
      height: '100%',
      width: '100%',
    },
  },
  valueContainer: {

    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    '& div, & input': {
      display: 'inline-flex !important',
      flex: '1 1 auto',
    },
  },
  noOptionsMessage: {
    display: 'block',
    padding: `12px ${theme.spacing.unit * 2}px`,
    color: prussian,
  },
  loadingMessage: {
    height: '24px',
    width: '24px',
    margin: '12px 0',
  },
  singleValue: {
    fontSize: 16,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    fontWeight: 'normal',
    fontFamily: 'Proxima Nova,Arial,Helvetica,sans-serif',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    maxHeight: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

export default withStyles(styles, { withTheme: true })(Autocomplete);
