import * as React from 'react';
import Popover from '@material-ui/core/Popover';

const { useState, ReactNode, Component } = React;

type Props = {
  children: ReactNode;
  popperContent: Component; // Component rendered inside Popper. Ensure prop passed as jsx element or wrapped in React.createElement()
  autoFocus: boolean;
  anchorOrigin: Record<string, string>;
  transformOrigin: Record<string, string>;
  paperProps: Record<string, any>;
};

const Popper = ({
  children,
  popperContent,
  autoFocus=false,
  anchorOrigin={
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin={
    vertical: 'top',
    horizontal: 'right',
  },
  paperProps={ 'style': { marginTop: '8px' } }
}: Props) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const isOpen = Boolean(anchorElement);
  const togglePopper = event =>
    setAnchorElement(anchorElement ? null : event.currentTarget);

  return (
    <div id='SchoolGradeLevelSelection'>
      <Popover
        autoFocus={autoFocus}
        PaperProps={paperProps}
        open={isOpen}
        anchorEl={anchorElement}
        onClose={togglePopper}
        anchorPosition={{ top: 100, left: 100 }}
        anchorOrigin
        transformOrigin
      >
        {popperContent(togglePopper)}
      </Popover>

      <div class='popper' onClick={togglePopper} style={{ padding: 0, margin: 0 }}>
        {children(anchorElement)}
      </div>
    </div>
  );
};

export default Popper;
