import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { css } from 'react-emotion';
import { colors } from '@peachjar/components';
import idx from 'idx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { centsToDollars } from '../../helpers/order';

enum Variant {
  Details = 'details',
}

type Quote = {
  accountBalance: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  additionalDiscounts: Array<any>;
  audiences: [{}];
  creditBasePrice: any;
  distBasePrice: any;
  distributionDiscounts: Array<any>;
  firstPurchase: boolean;
  lineItems: [{}];
  msrpSubtotal: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  purchaseDetails: {
    endDate: any;
    payment: any;
    quoteId: any;
    audiences: Array;
    startDate: any;
  };
  startingAccountBalance: number;
  subtotalAfterDiscounts: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  subtotalMinusAccountBalance: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  total: {
    priceInCents: number;
    discountPercent: number;
    numberOfCredits: number;
    msrpPriceInCents: number;
  };
  unfinishedDiscounts: Array<any>;
  unfinishedDistroLabels: Array<any>;
};

type Props = {
  quote: Quote;
  classes: any;
  numberOfDistributions: any;
  panelExpanded?: boolean;
  variant?: Variant;
};

const FlyerOrderSummaryPreview = ({
  quote,
  classes,
  numberOfDistributions,
  panelExpanded,
  variant,
}: Props) => {
  const isDetails = variant === Variant.Details;
  const defaultVariant = !variant && !isDetails;
  const localPanelExpanded = panelExpanded || isDetails;

  const schoolList = (quote && quote.audiences) || [];
  const schoolListLength = schoolList.length;

  const listPriceInCents = idx(quote, _ => _.total.msrpPriceInCents) || 0;
  const amountDueInCents = idx(quote, _ => _.total.priceInCents) || 0;
  const subtotalCreditsAfterDiscounts =
    idx(quote, _ => _.subtotalAfterDiscounts.numberOfCredits) || 0;
  const msrpSubtotalCredits =
    idx(quote, _ => _.msrpSubtotal.numberOfCredits) || 0;

  const formattedListPrice = centsToDollars(listPriceInCents);
  const formattedAmountDue = centsToDollars(amountDueInCents);

  // If Details variant, return customized component
  if (isDetails) {
    return (
      <Table className={classes.table}>
        <TableBody>
          <TableRow style={{ borderBottom: 'none !important' }}>
            <TableCell
              style={{
                width: '28%',
                padding: '2px 8px 2px 32px',
                fontSize: '16px',
                fontFamily: 'Proxima Nova',
                borderBottom: 'none',
              }}
            >
              {schoolListLength} School(s)
            </TableCell>
            <TableCell
              align="right"
              style={{
                width: '45%',
                padding: '2px 8px',
                fontSize: '16px',
                fontFamily: 'Proxima Nova',
                borderBottom: 'none',
              }}
            >
              {distributionFrequency(numberOfDistributions)}
            </TableCell>
            <TableCell
              align="right"
              style={{
                width: '10%',
                padding: '2px 8px',
                fontSize: '16px',
                fontFamily: 'Proxima Nova',
                borderBottom: 'none',
              }}
            >
              {localPanelExpanded
                ? msrpSubtotalCredits
                : subtotalCreditsAfterDiscounts}{' '}
              c
            </TableCell>
            <TableCell
              align="right"
              style={{
                width: '17%',
                padding: '2px 32px 2px 8px',
                fontSize: '16px',
                fontFamily: 'Proxima Nova',
                borderBottom: 'none',
              }}
            >
              {localPanelExpanded ? formattedListPrice : formattedAmountDue}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow>
          <TableCell
            align="left"
            className={`${cn.cell} ${cn.cellHeader} ${
              defaultVariant ? cn.col1 : cn.col1Variant
            }`}
          >
            {schoolListLength} School(s)
          </TableCell>
          <TableCell
            align="right"
            className={`${cn.cell} ${cn.cellHeader} ${cn.col2}`}
          >
            {distributionFrequency(numberOfDistributions)}
          </TableCell>
          <TableCell
            align="right"
            className={`${cn.cell} ${cn.cellHeader} ${cn.col3}`}
          >
            {localPanelExpanded
              ? msrpSubtotalCredits
              : subtotalCreditsAfterDiscounts}{' '}
            c
          </TableCell>
          <TableCell
            align="right"
            className={`${cn.cell} ${cn.cellHeader} ${cn.col4} ${
              !localPanelExpanded ? cn.boldify : ''
            }`}
          >
            {localPanelExpanded ? formattedListPrice : formattedAmountDue}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const distributionFrequency = distributions => {
  if (distributions > 1) {
    return `x ${distributions} Distributions`;
  }

  return 'x 1 Distribution';
};

const muiStyles = () => ({
  table: {
    fontFamily: 'Proxima Nova, Arial, Helvetica, sans-serif',
  },
});

const cn = {
  cell: css`
    font-size: 13px;
    color: ${colors.prussian};
    position: relative;
    border-bottom: none !important;
    padding: 2px 37px !important;
  `,
  cellHeader: css`
    font-size: 16px !important;
  `,
  col1: css`
    width: 38%;

    @media screen and (min-width: 1200px) {
      & {
        width: 25%;
      }
    }
    @media screen and (min-width: 1600px) {
      & {
        width: 21%;
      }
    }
  `,
  col2: css`
    width: 28%;

    @media screen and (min-width: 1200px) {
      & {
        width: 48%;
      }
    }
    @media screen and (min-width: 1600px) {
      & {
        width: 56%;
      }
    }
  `,
  col3: css`
    width: 17%;

    @media screen and (min-width: 1200px) {
      & {
        width: 13.5%;
      }
    }
    @media screen and (min-width: 1600px) {
      & {
        width: 11.5%;
      }
    }
  `,
  col4: css`
    width: 17%;

    @media screen and (min-width: 1200px) {
      & {
        width: 13.5%;
      }
    }
    @media screen and (min-width: 1600px) {
      & {
        width: 11.5%;
      }
    }
  `,
  boldify: css`
    font-weight: bold !important;
  `,
};

export default withStyles(muiStyles)(FlyerOrderSummaryPreview);
