import * as React from 'react';
import { get, filter } from 'lodash';
import { useMutation } from '@apollo/react-hooks';

import portalBffApolloClient from '../../../../_app/apollo/portalBFF.apolloClient';
import routePushHandler from '../../../shared/routePushHandler';
import SUBMIT_CAMPAIGN_MUTATION from '../gql/SubmitCampaignMutation.graphql';
import SAVE_CAMPAIGN_MUTATION from '../../gql/SaveCampaignMutation.graphql';
import MutationButton from '../../../../components/MutationButton';
import { GET_FLYERS_WITH_APPROVAL_STATUS_COUNT } from '../../../Overview/components/RemovePosting/MutationButton';
import { useRefetchContext } from '../../../Campaigns.context';

type Props = {
  campaignId: any;
  handleSuccess: any;
  handleError: any;
  children: any;
  disabled: boolean;
  messageToApprover: string;
  history: Object;
  prepCampaignSubmission?: () => void;
};

export const update = async cache => {
  // manually query so we can update the pending approval list
  const queryResult = await portalBffApolloClient.query({
    query: GET_FLYERS_WITH_APPROVAL_STATUS_COUNT,
    fetchPolicy: 'no-cache',
  });

  portalBffApolloClient.writeQuery({
    query: GET_FLYERS_WITH_APPROVAL_STATUS_COUNT,
    data: get(queryResult, 'data'),
  });
};

export const handleCampaignSubmission = async (
  submitCampaignMutation,
  variables,
  id,
  history,
  refetchGetMyCampaignsQuery,
  refetchUploaderQuery
) => {
  try {
    await submitCampaignMutation({ variables });
    await refetchGetMyCampaignsQuery();
    await refetchUploaderQuery();
    routePushHandler(
      history,
      `/campaigns/create-campaign/${id}/confirmation`
    )();
  } catch (err) {
    console.error(err);
    alert('There was an error submitting the campaign.');
  }
};

const SaveAndSubmitCampaignButton = ({
  campaignId,
  messageToApprover,
  history,
  handleSuccess,
  prepCampaignSubmission = () => {},
  handleError,
  children,
  disabled,
  ...rest
}: Props) => {
  const {
    refetchGetMyCampaignsQuery,
    refetchUploaderQuery,
  } = useRefetchContext();

  const submitCampaignVariables = { campaignId };
  const saveCampaignVariables = {
    campaignId,
    campaignSummary: { messageToApprover },
  };

  const [submitCampaign, { loading, data }] = useMutation(
    SUBMIT_CAMPAIGN_MUTATION,
    {
      client: portalBffApolloClient,
    }
  );

  return (
    <MutationButton
      disabled={disabled}
      client={portalBffApolloClient}
      mutation={SAVE_CAMPAIGN_MUTATION}
      variables={saveCampaignVariables}
      handleSuccess={async () => {
        await prepCampaignSubmission({});
        await handleCampaignSubmission(
          submitCampaign,
          submitCampaignVariables,
          campaignId,
          history,
          refetchGetMyCampaignsQuery,
          refetchUploaderQuery
        );
        if (handleSuccess && typeof handleSuccess === 'function') {
          handleSuccess();
        }
      }}
      handleError={handleError}
      // refetchQueries={() => ['getMyCampaigns']}
      update={update}
      loading={loading}
      {...rest}
    >
      {children}
    </MutationButton>
  );
};

export default SaveAndSubmitCampaignButton;
