import React from 'react';
import { css } from 'emotion';
import { elements, colors } from '@peachjar/components';
import { APP_ROUTES } from '../../../../_app/constants';
import config from '../../../../config';

const { Headline2, Paragraph } = elements.typography;
const { REACT_APP_PORTAL_APP_URL } = config;

const SubmittedMessage = ({ styles, flyerId, history }) => {
  const getFlyerReportURL = () =>
    `${REACT_APP_PORTAL_APP_URL}${APP_ROUTES.reporting.myFlyers}/report/${flyerId}`;

  return (
    <>
      <Headline2 className={styles.headline} data-test="campaign-submitted-msg-headline">
        Your flyer has been submitted.
      </Headline2>

      <Paragraph className={styles.paragraph}>
        To check up on the status of your flyer you can view your{' '}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span
          className={internalStyles.messageLink}
          onClick={() => window.location.href = getFlyerReportURL()}
          data-test="campaign-submitted-msg-flyer-cta"
        >
          Flyer Report.
        </span>
      </Paragraph>
    </>
  );
};

const internalStyles = {
  messageLink: css`
    color: ${colors.jungle};
    cursor: pointer;
  `,
}

export default SubmittedMessage;
