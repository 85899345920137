// Note: This is temporary until was revisit the Save/Cancel buttons, but we must call resetStore when exiting process mode
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import { css } from 'react-emotion';
import { components, colors } from '@peachjar/components';
import { graphql, compose } from 'react-apollo';
import { get } from 'lodash';
import { PREPARE_QUERY } from '../../Prepare/gql/Prepare.graphql';
import FinishCampaignLaterButton from './FinishCampaignLaterButton';
import CreditsMenu from '../../../../_app/components/CreditsMenu';

// import routePushHandler from '../../campaigns/shared/routePushHandler';
const { ButtonSecondarySmall } = components.Buttons;
const { white } = colors;

const styles = {
  white: css`
    && {
      color: ${white};
      border-color: ${white};

      svg {
        stroke: ${white};
      }

      &:hover {
        color: ${white};
      }

      &:not([disabled]):not(.disabled):active {
        background-color: rgba(0, 0, 0, 0.26);
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24),
          0 0 8px 0 rgba(0, 0, 0, 0.12);
      }

      &:disabled,
      &[disabled],
      &.disabled {
        background-color: rgba(0, 0, 0, 0.26);
        color: rgba(255, 255, 255, 0.6);
        border: 0;
        opacity: 1;
      }
    }
  `,
};

// Note: Buttons history push to campaigns route. When entering process mode, client.resetStore() called to ensure cache is reset
export const ExitProcessMode = ({
  campaignPrepare,
  campaigns,
  flags,
  history,
  uploaderLevel,
}) => {
  const localCacheCampaignId = get(campaignPrepare, 'flyerId');
  const matchConfirmation = matchPath(history.location.pathname, {
    path: '/campaigns/create-campaign/:campaignId/confirmation',
  });
  const orgUploaderFFenabled = flags.org_uploader === 1;
  const shouldRenderCreditsMenu = uploaderLevel === 'org';

  return (
    <div
      data-testid="buttonContainer-exitProcessMode"
      className="layout-row layout-align-center-center pr-3"
    >
      {!matchConfirmation && (
        <>
          {localCacheCampaignId ? (
            <>
              {orgUploaderFFenabled && shouldRenderCreditsMenu && (
                <CreditsMenu variant="processbar" />
              )}
              <FinishCampaignLaterButton
                dataTestId="submit-finishCampaignLater"
                campaigns={campaigns}
                history={history}
                localCacheCampaignId={localCacheCampaignId}
                handleSuccess={() => history.push('/campaigns')}
              >
                Finish Later
              </FinishCampaignLaterButton>
            </>
          ) : (
            <>
              {orgUploaderFFenabled && shouldRenderCreditsMenu && (
                <CreditsMenu variant="processbar" />
              )}
              <ButtonSecondarySmall
                dataTestId="click-cancelCampaign"
                className={styles.white}
                onClick={
                  () => history.push('/campaigns')
                  // (window.location.href = '/campaigns')
                }
              >
                Cancel
              </ButtonSecondarySmall>
            </>
          )}
        </>
      )}
    </div>
  );
};

const prepareConfig = {
  props: ({ ownProps, data: { campaignPrepare } }) => ({
    ...ownProps,
    campaignPrepare,
  }),
};

export default connect(
  state => ({ flags: state.flags }),
  null
)(withRouter(compose(graphql(PREPARE_QUERY, prepareConfig))(ExitProcessMode)));
