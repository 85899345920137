import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { components } from '@peachjar/components';
import cn from './PromoCodeInput.styles';
import tagSVG from '../../../assets/tag.svg';
import tagHoverSVG from '../../../assets/tagHover.svg';

type PromoCode = {
  value: string,
};

type Props = {
  promoCode: PromoCode,
  isHovering: boolean,
  panelExpanded: boolean,
  onExpand: (val: boolean) => void,
  onHover: (val: boolean) => void,
  onUpdate: (PromoCode) => void,
  onBlur: () => void,
  onFocus: () => void,
  onPanelChange: () => void,
  registerInput: (name: string) => void,
  handleSubmit: () => void,
  handleKeysSubmit: () => void,
};

const INPUT_NAME = 'promoInput';

const {
  TextInput,
  Buttons: { ButtonSecondarySmall },
} = components;

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 36,
    '&$expanded': {
      minHeight: 36,
    },
    padding: '0',
  },
  content: {
    '&$expanded': {
      margin: '12px 0 0 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: '0',
  }
})(MuiExpansionPanelDetails);

const PromoCodeInput: React.FunctionComponent<Props> = ({
  promoCode,
  isHovering,
  panelExpanded,
  onExpand,
  onHover,
  onUpdate,
  onBlur,
  onFocus,
  registerInput,
  onPanelChange,
  handleSubmit,
  handleKeysSubmit,
}) => {
  const tagImage = isHovering ? tagHoverSVG : tagSVG;
  const { value: promoCodeValue } = promoCode;

  useEffect(() => {
    registerInput(INPUT_NAME);
  }, [registerInput]);
  return (
    <div className={cn.promoCodeExpansionPanel}>
      <ExpansionPanel expanded={panelExpanded} onChange={onPanelChange}>
        <ExpansionPanelSummary className={cn.noPointer}>
          <div
            onMouseEnter={() => onHover(!isHovering)}
            onMouseLeave={() => onHover(!isHovering)}
            onClick={() => onExpand(!panelExpanded)}
          >
            <img
              src={tagImage}
              className={`${cn.imageIcon} ${cn.imageIconPlacement}`}
            />
            <span className={`${cn.promoLink} ${isHovering ? 'hover' : ''}`}>
              Promo Code
            </span>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <form className={cn.promoCodeForm} onSubmit={() => {}}>
            <TextInput
              autoFocus={panelExpanded}
              name={INPUT_NAME}
              className={cn.inputWidth}
              onChange={event => onUpdate({ value: event.target.value })}
              onKeyDown={handleKeysSubmit}
              onBlur={onBlur}
              onFocus={onFocus}
              value={promoCodeValue}
              data-testid="input-order-summary-promo-code"
            />
            {promoCodeValue && (
              <ButtonSecondarySmall
                className={cn.promoCodeCTA}
                data-testid="click-promo-code-apply"
                onClick={handleSubmit}
              >
                Apply
              </ButtonSecondarySmall>
            )}
          </form>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default PromoCodeInput;
