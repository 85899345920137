import React from 'react';
import { components } from '@peachjar/components';

const {
  Buttons: {
    ButtonPrimaryLarge,
    ButtonPrimarySmall,
    ButtonSecondaryLarge,
    ButtonSecondarySmall,
    ButtonFlatLarge,
    ButtonFlatSmall,
  },
} = components;

const ThemedButton = ({ buttonType, ...rest }) => {
  switch (buttonType) {
    case 'primaryLarge':
      return <ButtonPrimaryLarge {...rest} />;
    case 'primarySmall':
      return <ButtonPrimarySmall {...rest} />;
    case 'secondaryLarge':
      return <ButtonSecondaryLarge {...rest} />;
    case 'secondarySmall':
      return <ButtonSecondarySmall {...rest} />;
    case 'flatLarge':
      return <ButtonFlatLarge {...rest} />;
    case 'flatSmall':
      return <ButtonFlatSmall {...rest} />;
    default:
      return <ButtonPrimaryLarge {...rest} />;
  }
};

const SpinningSubmitButton = ({
  loading,
  handleClick,
  buttonType = 'primaryLarge',
  children,
  disabled,
  className,
  dataTestId,
  noExtraClass,
  ...rest
}) => (
  <>
    <ThemedButton
      buttonType={buttonType}
      data-testid={dataTestId || 'btnSpinningSubmit'}
      onClick={handleClick}
      disabled={disabled || loading}
      className={`${noExtraClass ? '' : 'ml-3'} ${className}`}
      isLoading={loading}
      {...rest}
    >
      {children}
    </ThemedButton>
  </>
);

export default SpinningSubmitButton;
