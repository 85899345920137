import * as React from 'react';
import { css } from 'react-emotion';

type Props = {
  children: React.ReactNode
};

const styles = {
  tabContentWrapper: css`
    padding: 64px;
  `,
};

const TabContentWrapper = ({ children }: Props) => (
  <div
    className={`flex-100 ${styles.tabContentWrapper}`}
    style={{ backgroundColor: 'white', minHeight: '500px' }}
  >
    {children}
  </div>
);

export default TabContentWrapper;
