import gql from 'graphql-tag';

export const REMOVE_SCHOOLS_FLYER_MUTATION = gql`
  mutation SubmitFlyerDeletionUpdateMutation($input: DeleteFlyerInput!) {
    deleteFlyer(input: $input) {
      id
    }
  }
`;

export const REMOVAL_DRAWER_DETAILS_QUERY = gql`
  query flyerDetails($id: Int) {
    sodUser: profile {
      id
      permission {
        uploaderLevel
      }
      flyerReporting {
        flyerDetailReport(id: $id) {
          approvals {
            id
            flyerId
            schoolName
            schoolId
            status
            statusBeforeRemoval
            distributionStatus
            districtName
            districtId
          }
        }
      }
    }
  }
`;
