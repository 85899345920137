import gql from 'graphql-tag';
import PrimaryCTAFragments from '../PrimaryCTA/gql/PrimaryCTAFragments.graphql';
import SecondaryCTAsGroupFragments from '../SecondaryCTAsGroup/gql/SecondaryCTAsGroupFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment CallsToAction_getMyCampaigns on Campaign {
      id
      ...PrimaryCTA_getMyCampaigns
      ...SecondaryCTAsGroup_getMyCampaigns
    }
    ${PrimaryCTAFragments.getMyCampaigns}
    ${SecondaryCTAsGroupFragments.getMyCampaigns}
  `,
};
