import gql from 'graphql-tag';

const SearchForSchoolsQuery = gql`
  query SearchForSchoolsQuery(
    $placeId: String
    $searchBy: SchoolFinderSearchType
    $eventLocation: String
    $mapBounds: MapBoundsInput
    $centerPoint: GeocodeInput
  ) {
    searchForSchools(
      placeId: $placeId
      searchBy: $searchBy
      eventLocation: $eventLocation
      mapBounds: $mapBounds
      centerPoint: $centerPoint
    ) {
      formattedAddress
      total
      radius
      zoom
      center {
        latitude
        longitude
      }
      schools {
        schoolId
        districtId
        ncesId
        name
        audienceType
        lowestGradeOffered
        highestGradeOffered
        latitude
        longitude
        distance
        ncesId
        isPostOnly: orgPostOnly
        isOptionalPostOnly: orgOptionalPostOnly
        isPin
        district {
          name
          url
          isPostOnly: orgPostOnly
          isOptionalPostOnly: orgOptionalPostOnly
        }
      }
    }
  }
`;

export default SearchForSchoolsQuery;
