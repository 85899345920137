import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { findIndex, findLastIndex } from 'lodash';
import Button from '@material-ui/core/Button';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Popper from '../../../../../../../../shared/components/Popper';
import GradeFilter, { GradeFilterType } from './GradeFilter';
import AudienceFilter, { AudienceFilterType } from './AudienceFilter';
import { getAudienceFilterButtonLabel } from './utils';
import { grades, gradeFilterDefault } from '../../../../../../../../shared/constants';
import { SCHOOL_FINDER_AUDIENCE_FILTER_DEFAULTS } from './constants';
import GradeLevels from '@peachjar/school-finder-api/dist/api/GradeLevels';

const muiStyles = () => ({
  button: {
    textTransform: 'none',
    fontFamily: 'Proxima Nova',
  },
  buttonFiltered: {
    'textTransform': 'none',
    'fontFamily': 'Proxima Nova',
    'color': '#ffffff',
    'backgroundColor': '#576183',
    '&:hover': { backgroundColor: '#47506B' },
  },
});

type Props = {
  audienceFilter: AudienceFilterType,
  gradeFilter: GradeFilterType,
  setAudienceFilter: (filter: AudienceFilterType) => void,
  setGradeFilter: (filter: GradeFilterType) => void,
  classes: Record<string, any>,
  className?: string,
};

const Filters = ({
  audienceFilter,
  gradeFilter,
  setAudienceFilter,
  setGradeFilter,
  classes,
  className,
}: Props) => {
  const areAudienceFiltersDefault = Object.keys(audienceFilter).every(
    key => audienceFilter[key] === SCHOOL_FINDER_AUDIENCE_FILTER_DEFAULTS[key]
  );
  const areSomeAudienceFiltersOn = Object.keys(audienceFilter).some(filterType => audienceFilter[filterType]);
  const areGradeFiltersDefault =
    gradeFilter.min === gradeFilterDefault.min &&
    gradeFilter.max === gradeFilterDefault.max;
  const buttonClassName = areGradeFiltersDefault ? classes.button : classes.buttonFiltered;
  const gradeFilterText = areGradeFiltersDefault ?
  `Grades ` :
  `Grades: ${gradeFilter.min} \u2013 ${gradeFilter.max}`
  return (
    <div className={`layout-row layout-align-end-end ${className}`}>
      <div className="mr-3">
        <Popper
          paperProps={{ 'style': { marginTop: '48px' } }}
          popperContent={togglePopper => (
            <AudienceFilter
              audienceFilter={audienceFilter}
              setAudienceFilter={setAudienceFilter}
              areAudienceFiltersDefault={areAudienceFiltersDefault}
              togglePopper={togglePopper}
            />
          )}
        >
          {anchorElement => {
            const arrow = anchorElement ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            );
            return (
              <Button
                className={
                  areSomeAudienceFiltersOn
                    ? classes.buttonFiltered
                    : classes.button
                }
                variant="outlined"
              >
                {getAudienceFilterButtonLabel(
                  audienceFilter
                )}
                {arrow}
              </Button>
            );
          }}
        </Popper>
      </div>

      <Popper
        paperProps={{ 'style': { marginTop: '48px' } }}
        popperContent={togglePopper => (
          <GradeFilter
            gradeFilter={gradeFilter}
            setGradeFilter={setGradeFilter}
            togglePopper={togglePopper}
          />
        )}
      >
        {anchorElement => {
          const arrow = anchorElement ? (
            <KeyboardArrowUp />
          ) : (
            <KeyboardArrowDown />
          );
          return (
            <Button
              className={buttonClassName}
              variant="outlined"
            >
              {gradeFilterText}
              {arrow}
            </Button>
          );
        }}
      </Popper>
    </div>
  );
};

// @ts-ignore
export default withStyles(muiStyles)(Filters);
