import React from 'react';
import gql from 'graphql-tag';
import CallToAction from '../../../CallToAction';
import UrlInput from '../../../UrlInput';
import sanitizeUrlInput from '../../../util/sanitizeUrlInput';
import withValidation from '../../../../../../../shared/components/withValidation';
import LearnMoreFragments from './gql/LearnMoreFragments.graphql';

const name = 'LearnMore';
const LABEL = 'Learn More';

export const LearnMore = ({
  isSelected,
  websiteUrl,
  handleInputChange,
  handleCancel,
  handleSelection,
  performValidation,
  shouldPerformDisabledClickValidation,
}) => {
  const props = {
    label: LABEL,
    name,
    isSelected,
    handleSelection,
    handleCancel,
  };

  return (
    <CallToAction {...props}>
      <div className="mt-4">
        <UrlInput
          name={name}
          shouldPerformDisabledClickValidation={
            shouldPerformDisabledClickValidation
          }
          label="Website URL"
          placeholder="http://"
          data-testid="inputWebsiteUrl"
          url={websiteUrl}
          handleInputChange={event => {
            const sanitizedInput = sanitizeUrlInput(event);

            handleInputChange({
              target: {
                value: {
                  websiteUrl: sanitizedInput,
                  __typename: 'WebsiteUrl',
                },
              },
            });
          }}
          // eslint-disable-next-line no-shadow
          handleIsValid={(name, isValid) =>
            performValidation({
              target: {
                name,
                value: {
                  isSelected,
                  isValid,
                },
              },
            })
          }
        />
      </div>
    </CallToAction>
  );
};

LearnMore.fragments = LearnMoreFragments;

// Learn More is valid when it's seleted and and valid.
const validate = ({ isSelected, isValid }) => isSelected && isValid;

const validateOnMount = ({ isSelected }, { isValid }) =>
  validate({ isSelected, isValid });

export default withValidation(LearnMore, validate, validateOnMount);
