
import * as React from 'react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { components } from '@peachjar/components';
import { css, cx } from 'react-emotion';
import idx from 'idx';

import {
  HistoricalFlyerList_flyers,
  Sod,
} from './__generated__/HistoricalFlyerList_flyers';
import {
  HistoricalApprovals_flyerReasons,
} from './__generated__/HistoricalApprovals_flyerReasons';

import LoadingSpinner from '../../components/LoadingSpinner';
import ErrorBoundary from '../components/ErrorBoundary';
import NoResultsView from '../components/NoResultsView';
import HistoricalApprovalCard from '../components/HistoricalApprovalCard';

const { Pagination, ModalConsumer, SearchInput } = components;

const ITEMS_PER_PAGE = 10;
const LIMIT = 10;

const KEYCODE_FOR_ENTER = 13;

type Props = {
  flyerList: HistoricalFlyerList_flyers,
  flyerReasons: HistoricalApprovals_flyerReasons[],
  loading: boolean,
  updateQueryVariables: (object: object) => void,
  sod: Sod,
  isDistrictAdmin: boolean,
  offset: number,
  shouldUserSeeApproverNotesButton: boolean,
};

type State = {
  isSearchTextInputShown: boolean,
  searchTextInputValue: string,
};

class HistoricalApprovals extends React.Component<Props, State> {
  static fragments: { [key: string]: any };

  state = {
    isSearchTextInputShown: false,
    searchTextInputValue: '',
  };

  clearSearchInput = () => {
    const { updateQueryVariables } = this.props;

    this.setState({
      searchTextInputValue: '',
    });
    updateQueryVariables({
      filter: null,
      offset: 0,
    });
  };

  handlePageChange = (newPageNumber: number): void => {
    const { updateQueryVariables } = this.props;
    const newOffset = (newPageNumber - 1) * ITEMS_PER_PAGE;

    updateQueryVariables({
      offset: newOffset,
    });
  };

  handleSearchInputOnEnter = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { updateQueryVariables } = this.props;

    if (e.which === KEYCODE_FOR_ENTER || e.keyCode === KEYCODE_FOR_ENTER) {
      const { searchTextInputValue } = this.state;
      updateQueryVariables({
        filter: searchTextInputValue,
        offset: 0,
      });
    }
  };

  handleSearchInputTextChange = (
    e: SyntheticKeyboardEvent<HTMLInputElement>
  ) => {
    const value = idx(e, _ => _.currentTarget.value) || '';

    this.setState({ searchTextInputValue: value });
  };

  toggleSearchInputVisibility = (): void => {
    const { isSearchTextInputShown } = this.state;
    this.setState({ isSearchTextInputShown: !isSearchTextInputShown });
  };

  render() {
    const {
      loading,
      flyerList,
      flyerReasons,
      sod,
      isDistrictAdmin,
      offset,
      shouldUserSeeApproverNotesButton,
    } = this.props;
    const { searchTextInputValue, isSearchTextInputShown } = this.state;

    // TODO: clean this derived page number up, make sure it is tested
    const pageNumber = (offset || 0) / LIMIT + 1;
    const COUNT = idx(flyerList, _ => _.pagination.total) || 0;
    const pageCount = Math.ceil(COUNT / LIMIT);
    const numberOfFlyersToDisplay = idx(flyerList, _ => _.items.length) || 0;

    const isNotLoadingAndHasNoFlyers =
      !loading && numberOfFlyersToDisplay === 0;

    return (
      <div>
        <div className={classNames.topPaginationContainer}>
          <div className={classNames.searchWrapper}>
            <SearchInput
              placeholder="Search"
              value={searchTextInputValue}
              isTextInputShown={isSearchTextInputShown}
              onChange={this.handleSearchInputTextChange}
              onKeyUp={this.handleSearchInputOnEnter}
              onClickSearch={this.toggleSearchInputVisibility}
              onClickClose={this.clearSearchInput}
            />
          </div>
          <Pagination
            activePageNumber={pageNumber}
            testId="Top-Pagination"
            pageCount={pageCount}
            onPageChange={this.handlePageChange}
          />
        </div>
        {/* TODO FIXME */}
        {/* eslint-disable-next-line */}
        {loading ? (
          <LoadingSpinner />
        ) : isNotLoadingAndHasNoFlyers ? (
          <NoResultsView>No Approvals Found</NoResultsView>
        ) : (
          <ModalConsumer>
            {({ showModal, closeModal }) => {
              const flyers = (idx(flyerList, _ => _.items) || []).filter(
                Boolean
              );

              return flyers.map((flyer, ind) => (
                <div
                  className={cx(
                    classNames.row,
                    ind === 0 && classNames.firstRow
                  )}
                  key={flyer.id}
                >
                  <ErrorBoundary>
                    <div className="flex-100">
                      <HistoricalApprovalCard
                        flyer={filter(
                          HistoricalApprovalCard.fragments.flyer,
                          flyer
                        )}
                        flyerReasons={filter(
                          HistoricalApprovalCard.fragments.flyerReasons,
                          flyerReasons
                        )}
                        sod={sod}
                        showModal={showModal}
                        closeModal={closeModal}
                        isDistrictAdmin={isDistrictAdmin}
                        shouldUserSeeApproverNotesButton={
                          shouldUserSeeApproverNotesButton
                        }
                      />
                    </div>
                  </ErrorBoundary>
                </div>
              ));
            }}
          </ModalConsumer>
        )}
        {!loading && (
          <div className={classNames.bottomPaginationContainer}>
            <Pagination
              activePageNumber={pageNumber}
              testId="Bottom-Pagination"
              pageCount={pageCount}
              onPageChange={newPageNumber =>
                this.handlePageChange(newPageNumber)
              }
            />
          </div>
        )}
      </div>
    );
  }
}

HistoricalApprovals.fragments = {
  flyerList: gql`
    fragment HistoricalFlyerList_flyers on FlyerPaginatedSet {
      pagination {
        total
      }
      items {
        id
        ...HistoricalApprovalCard_flyer
      }
    }
    ${HistoricalApprovalCard.fragments.flyer}
  `,
  flyerReasons: gql`
    fragment HistoricalApprovals_flyerReasons on FlyerReason {
      ...HistoricalApprovalCard_flyerReasons
    }
    ${HistoricalApprovalCard.fragments.flyerReasons}
  `,
};

const classNames = {
  firstRow: css`
    margin-top: 0;
  `,
  row: css`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  `,
  bottomPaginationContainer: css`
    flex: 1 1 auto;
    position: relative;
    display: flex;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    flex-direction: row-reverse;
  `,
  searchWrapper: css`
    width: 345px;
  `,
  topPaginationContainer: css`
    flex: 1 1 auto;
    position: relative;
    display: flex;
    margin-bottom: 1rem !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
};

export default HistoricalApprovals;
