import React, { useContext } from 'react';
import { get, find } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { colors } from '@peachjar/components';
import { ListItemSecondaryAction } from '@material-ui/core';
import DealsContext from '../../../../../../../../../_app/context/DealsContext';
import { School, District } from '../../types';
import SchoolFinderSortStyleDropdown from '../../../../../../../../../components/SchoolFinderSortStyleDropdown';
import { SCHOOL_SORT_OPTIONS, SCHOOL_SORT_BY_DISTANCE_VALUE } from './constants';

const { silver, leaf, slate, prussian } = colors;

const styles = theme => ({
  formControlLabelLabel: {
    marginLeft: "0.5rem",
    fontFamily: "Proxima Nova",
    color: `${prussian} !important`,
  },
  formControlLabelRoot: {
    borderRadius: "3px",
    paddingTop: ".25rem",
    paddingBottom: ".25rem",
    paddingLeft: "0rem",
    paddingRight: "1rem",    
    margin: "0",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.08)",
    },
  },
  aroundSortSelector: {
    borderRadius: "3px",
    paddingTop: ".25rem",
    paddingBottom: ".25rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",    
    display: "flex",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.08)",
    }
  },  
  root: {
    justifyContent: "space-between",
    width: 'auto',
    padding: '1rem 1.50rem',
    border: `1px solid ${silver}`,
    borderTopLeftRadius: '3px',
    opacity: 1,
    "&:focus": {
      backgroundColor: "transparent",
    }
  },
  rootDisabled: {
    '& > *': {
      opacity: 0.5,
      '&:hover': {
        backgroundColor: 'transparent',
      }
    },
  },
  rootSelected: {
    backgroundColor: "transparent",
  },
  listItemTextRoot: {
    padding: '0 0.5rem',
  },
  checkboxRoot: {
    color: slate,
    '&$checked': {
      color: leaf,
    },
  },
  checked: {},
  listItemSecondaryRoot: {
    right: '1.5rem',
  },
  listItemTextPrimary: {
    fontSize: '1rem !important',
  },
});

type Props = {
  schools: School[];
  toggleSubsetOfCheckboxes: (
    schools: any[],
    setCheckboxSelections: () => void,
    checkboxSelections: School[]
  ) => School[];
  checkboxSelections: School[];
  setCheckboxSelections: () => void;
  onSelectionUpdated: (checkboxSelections: School[]) => void;
  onSchoolsSortStyleUpdate: (sortStyle: string) => void;
  classes: { [key: string]: string };
  className?: string;
};

const OrgCheckboxListHeader = ({
  schools,
  className,
  checkboxSelections,
  toggleSubsetOfCheckboxes,
  setCheckboxSelections,
  onSelectionUpdated,
  onSchoolsSortStyleUpdate,
  classes,
}: Props) => {
  const {
    summaryTrayExpanded,
    setSummaryTray,
    updateSchoolDistributionList,
  } = useContext(DealsContext);
  const areNoSchools = schools.length === 0;

  // per-search schools within checkboxSelections
  const checkboxSelectionsSubset = checkboxSelections.filter(selection =>
    schools.find(school => school.schoolId === selection.schoolId)
  );

  const hasSelectedEntireSubset =
    checkboxSelectionsSubset.length === schools.length && schools.length !== 0;

  const areOnlySomeItemsSelected =
    !!checkboxSelectionsSubset.length && !hasSelectedEntireSubset;

  const schoolsCheckboxText = `All Schools Shown (${schools.length})`;

  return (
    <ListItem
      classes={{
        root: hasSelectedEntireSubset
          ? `${classes.rootSelected} ${classes.root}`
          : classes.root,
        disabled: classes.rootDisabled,
      }}
      className={className}
      role="listitem"
      dense
      disabled={areNoSchools}
    >
      <FormControlLabel
        classes={{
          root: classes.formControlLabelRoot,
          label: classes.formControlLabelLabel
        }}
        label={schoolsCheckboxText}
        control={
        <Checkbox
          className="p-0"
          classes={{ root: classes.checkboxRoot, checked: classes.checked }}
          checked={areOnlySomeItemsSelected || hasSelectedEntireSubset}
          tabIndex={-1}
          data-testid="districtCheckbox"
          disableRipple
          disabled={areNoSchools}
          indeterminate={areOnlySomeItemsSelected}
          onChange={() => {
            const updatedCheckboxSelections = toggleSubsetOfCheckboxes(
              schools,
              setCheckboxSelections,
              checkboxSelections
            );
            const schoolDistributionList = updatedCheckboxSelections.map(
              school => ({
                schoolId: school.schoolId,
                districtId: school.districtId,
                audienceType: school.audienceType,
                schoolName: school.name,
                districtName: get(school, 'district.name'),
              })
            );
            updateSchoolDistributionList(schoolDistributionList);
            onSelectionUpdated(updatedCheckboxSelections);
          }}
        />}
      />
  
      <div className={classes.aroundSortSelector}>
        <SchoolFinderSortStyleDropdown
          data-testid="schoolFinderSortyType"
          options={SCHOOL_SORT_OPTIONS}
          customOnChangeHandler={onSchoolsSortStyleUpdate}
          initialValue={SCHOOL_SORT_BY_DISTANCE_VALUE}
          disabled={areNoSchools}
        />
      </div>

    </ListItem>
  );
};

export default withStyles(styles)(OrgCheckboxListHeader);
