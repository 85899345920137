// This is necessary to include inside the bundle b/c
// google maps returns a script that requires the polyfills to work.
// The polyfills are included just before this.

// Also, this needs to come before the GooglePlaces React component is rendered so
// the google.maps object is available beforehand

// Issue was likely caused by Google maps snippet and Apollo Client.
// Food for thought -> https://github.com/apollographql/apollo-client/issues/3043
import config from './config';

const script = document.createElement('script');
script.type = 'text/javascript';
script.src = `https://maps.googleapis.com/maps/api/js?key=${
  config.REACT_APP_GOOGLE_MAPS_API_KEY
}&libraries=places,geometry`;
document.head.appendChild(script);
