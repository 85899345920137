import * as React from 'react';
import { css, cx } from 'emotion';
import { elements, components, colors } from '@peachjar/components';
import approvalsIcon from '../../../assets/iconApprovals.svg';
import dashboardIcon from '../../../assets/iconDashboard.svg';
import distributionIcon from '../../../assets/iconDistribution.svg';
import reportingIcon from '../../../assets/iconReporting.svg';
import sendFlyerIcon from '../../../assets/send_a_flyer.svg';
import pendingApprovalsIcon from '../../../assets/pending_approvals.svg';
import approvalHistoryIcon from '../../../assets/approval_history.svg';
import dealsIcon from '../../../assets/icon_deals.svg';
import distributionScheduleIcon from '../../../assets/distribution_schedule.svg';
import distributionHistoryIcon from '../../../assets/distribution_history.svg';
import { setFlag } from '../../../helpers/localStorage';
import { SCHEDULE_DISTRIBUTION_VISITED_FLAG } from '../../../distribution_schedule/ScheduleDistributionNotification';

const { VerticalNav } = components;
const { Note } = elements.typography;

type Props = {
  path: string,
  label: string,
  badge?: React.ReactNode,
};

const sideBarIcons = {
  dashboard: dashboardIcon,
  deals: dealsIcon,
  approvals: approvalsIcon,
  submissions: distributionIcon,
  reporting: reportingIcon,
  'post&notify': sendFlyerIcon,
  pendingapprovals: pendingApprovalsIcon,
  approvalhistory: approvalHistoryIcon,
  distributionschedule: distributionScheduleIcon,
  distributionhistory: distributionHistoryIcon,
};

const formatLabel = label => label.replace(/\s/g, '').toLowerCase();

const getNavIcon = label => {
  const formattedLabel = formatLabel(label);
  return sideBarIcons[formattedLabel];
};

const dividerArray = [
  'Dashboard',
  'Post & Notify',
  'Approval History',
  'Distribution History',
  'Reporting',
  'Deals',
];

export const setFirstVisitFlag = () => {
  setFlag(SCHEDULE_DISTRIBUTION_VISITED_FLAG);
};

const SidebarDrawerNavItem = ({ path, label, badge }: Props) =>
  path.startsWith('http') ? (
    <li
      className={cx(classNames.item, {
        [classNames.divider]: dividerArray.includes(label),
      })}
    >
      <a
        href={path}
        className={classNames.link}
        onClick={
          label === 'Distribution Schedule' || label === 'Distribution History'
            ? setFirstVisitFlag
            : undefined
        }
      >
        <Note className={classNames.spaceBetween}>
          <img
            className={classNames.icon}
            src={getNavIcon(label)}
            height="16"
            width="16"
            alt={label}
            data-testid={`icon-nav-item-${label}`}
          />
          <span
            className={classNames.itemLabel}
            data-testid={`text-nav-item-label-${label}`}
          >
            {label}
          </span>
          <span
            className={classNames.itemBadge}
            data-testid="text-nav-item-badge"
          >
            {badge}
          </span>
        </Note>
      </a>
    </li>
  ) : (
    <VerticalNav.Item
      path={path}
      label={label}
      className={`${dividerArray.includes(label) ? classNames.divider : ''}`}
    >
      <Note className={classNames.spaceBetween}>
        <img
          className={classNames.icon}
          src={getNavIcon(label)}
          height="16"
          width="16"
          alt={label}
          data-testid={`icon-nav-item-${label}`}
        />
        <span
          className={classNames.itemLabel}
          data-testid={`text-nav-item-label-${label}`}
        >
          {label}
        </span>
        <span
          className={classNames.itemBadge}
          data-testid="text-nav-item-badge"
        >
          {badge}
        </span>
      </Note>
    </VerticalNav.Item>
  );

SidebarDrawerNavItem.defaultProps = {
  badge: null,
};

const classNames = {
  item: css`
    list-style-type: none;
    border-radius: 3px;
    border-left: 4px solid transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  `,
  link: css`
    display: block;
    text-decoration: none;
    color: ${colors.prussian};
    &:hover {
      color: ${colors.prussian};
      text-decoration: none;
    }
    width: 100%;
    padding: 1rem 1rem 1rem 0.5rem;

    @media screen and (min-width: 1920px) {
      padding: 1rem;
    }
  `,
  spaceBetween: css`
    display: block;
    position: relative;
    padding: 10px;
  `,
  icon: css`
    position: absolute;
    left: 4px;
    top: 2px;
  `,
  itemLabel: css`
    position: absolute;
    left: 40px;
    top: 2px;
  `,
  itemBadge: css`
    position: absolute;
    left: 150px;
    top: 2px;
  `,
  divider: css`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  `,
};

export default SidebarDrawerNavItem;
