import * as React from 'react';
import { connect } from 'react-redux';
import { css, cx } from 'react-emotion';
import { colors } from '@peachjar/components';
import { Flags } from '../../../_app/flagging/flags';

type Props = {
  className: string;
  children: React.ReactNode;
  flags: Flags;
};

const Wrapper = ({ className, children, flags }: Props) => {
  // @ts-ignore
  const { managed_services } = flags;
  const managedServicesEnabled = managed_services === 1;
  const managedServicesStyles = managedServicesEnabled
    ? classNames.managedServicesPadding
    : '';

  return (
    <div
      className={cx(
        classNames.subAppContainer,
        className,
        managedServicesStyles
      )}
    >
      <div className={cx('container', classNames.container)}>
        <div className="layout-row">
          <div className="flex-100">{children}</div>
        </div>
      </div>
    </div>
  );
};

const classNames = {
  subAppContainer: css`
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
    background-color: ${colors.platinum};
    flex: 1 1 auto;
  `,
  managedServicesPadding: css`
    padding-top: 0 !important;
  `,
  container: css`
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    background-color: ${colors.platinum};
  `,
};

type RootState = {
  flags: Flags;
};

export default connect(
  (state: RootState) => ({ flags: state.flags }),
  null
)(Wrapper);
