import generateSubappConfig from '../_app/generateSubappConfig';
import { APP_ROUTES } from '../_app/constants';
import { campaignsAllowedRoles } from '../_app/RolesEnum';
import RedirectSubmissions from '../_app/components/RedirectSubmissions';

const getCampaignsSubappConfig = () =>
  generateSubappConfig({
    path: APP_ROUTES.campaigns,
    isLive: true,
    allowedRoles: campaignsAllowedRoles,
    navItemConfig: {
      label: 'Submissions',
    },
    appConfig: {
      Component: RedirectSubmissions,
      fragmentOnUser: {},
    },
  });

export default getCampaignsSubappConfig;
