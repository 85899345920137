import React from 'react';
import gql from 'graphql-tag';
import CallToAction from '../../../CallToAction';
import UrlInput from '../../../UrlInput';
import sanitizeUrlInput from '../../../util/sanitizeUrlInput';
import withValidation from '../../../../../../../shared/components/withValidation';
import GetAppFragments from './gql/GetAppFragments.graphql';

const NAME = 'GetApp';
const LABEL = 'Get App';

export class GetApp extends React.Component {
  state = {};

  handleIsValid = (name, isValid) => {
    const { performValidation } = this.props;
    const { isWebAppUrlValid } = this.state;

    if (name === 'webApp' && isValid !== isWebAppUrlValid) {
      this.setState({ isWebAppUrlValid: isValid }, () =>
        performValidation({ target: { name: 'GetApp', value: this.state } })
      );
    }
  };

  render() {
    const {
      isSelected,
      webAppUrl,
      handleInputChange,
      handleCancel,
      handleSelection,
      shouldPerformDisabledClickValidation,
    } = this.props;

    const getAppCTAData = {
      webAppUrl,
      __typename: 'AppUrls',
    };

    const props = {
      label: LABEL,
      name: NAME,
      isSelected,
      handleSelection,
      handleCancel,
    };

    return (
      <CallToAction {...props}>
        <div className="mt-4">
          <UrlInput
            name="webApp"
            shouldPerformDisabledClickValidation={
              shouldPerformDisabledClickValidation
            }
            label="Web App Download"
            placeholder="http://"
            data-testid="inputWebAppUrl"
            url={webAppUrl}
            handleInputChange={e => {
              getAppCTAData.webAppUrl = sanitizeUrlInput(e);

              handleInputChange({
                target: {
                  value: getAppCTAData,
                },
              });
            }}
            handleIsValid={this.handleIsValid}
          />
        </div>
      </CallToAction>
    );
  }
}

GetApp.fragments = GetAppFragments;

const validation = ({ isWebAppUrlValid }) => isWebAppUrlValid === true;

export default withValidation(GetApp, validation);
