import * as React from 'react';
import idx from 'idx';
import gql from 'graphql-tag';
import { filter as gqlFilter } from 'graphql-anywhere';
import { css } from 'emotion';
import { components } from '@peachjar/components';
import { connect } from 'react-redux';
import { GraphQLStuff } from '../types';
import { MyApprovals_sodUser } from './__generated__/MyApprovals_sodUser';
import { MyApprovals_flyerReasons } from './__generated__/MyApprovals_flyerReasons';
import HistoricalApprovals from '../all_approvals/containers/HistoricalApprovals';
import SubappHeader from '../_app/components/SubappHeader';
import areRegionsMultiDistrict from '../all_approvals/areRegionsMultiDistrict';
import validateMultiRegionSod from '../helpers/validateMultiRegionSod';
import getHishestSodLevel from '../helpers/getHighestSodLevel';
import { Flags } from '../_app/flagging/flags';
import { APP_ROUTES } from '../_app/constants';
import MiniBreadcrumb from '../components/MiniBreadcrumb';
import { RoleCheckWithRedirect } from '../_app/components/RoleCheck';
import { myApprovalsAllowedRoles } from '../_app/RolesEnum';

const { ButtonSecondarySmall } = components.Buttons;

type Props = {
  gqlStuff: GraphQLStuff;
  sodUser: MyApprovals_sodUser;
  flyerReasons: MyApprovals_flyerReasons[];
};

class MyApprovals extends React.Component<Props> {
  static fragments: { [key: string]: any };

  updateQueryVariables = (updates: {
    offset?: number;
    filter?: string;
  }): void => {
    const { gqlStuff } = this.props;
    const { offset, filter } = updates;

    const {
      MyApprovals_sodUser_offset,
      MyApprovals_sodUser_filter,
    } = gqlStuff.currentQueryVariables;
    const newVariables = {
      MyApprovals_sodUser_offset:
        offset === undefined ? MyApprovals_sodUser_offset : offset,
      MyApprovals_sodUser_filter: filter || MyApprovals_sodUser_filter,
    };

    gqlStuff.updateQueryVariables({
      ...gqlStuff.currentQueryVariables,
      ...newVariables,
    });
  };

  render() {
    const { sodUser, gqlStuff, flyerReasons, flags, history } = this.props;
    const adminLevel = idx(sodUser, _ => _.permission.adminLevel) || null;
    const approverLevel = idx(sodUser, _ => _.permission.approverLevel) || null;
    const isDistrictAdmin = adminLevel === 'district';
    const sod = validateMultiRegionSod(adminLevel, approverLevel);
    const approvalCenterRegions =
      idx(sodUser, _ => _.approvalCenterRegions) || [];
    const shouldUserSeeApproverNotesButton = !areRegionsMultiDistrict(
      approvalCenterRegions
    );
    const sodRole = getHishestSodLevel(sodUser);
    // @ts-ignore
    const { portal_nav } = flags;
    const isPortalNavEnabled = portal_nav === 1;

    return (
      <RoleCheckWithRedirect roles={myApprovalsAllowedRoles}>
        <div>
          {isPortalNavEnabled && (
            <MiniBreadcrumb
              linkTo={APP_ROUTES.approvals.index}
              text="Approvals"
            />
          )}
          <div className={`${isPortalNavEnabled ? cn.sectionHeadline : ''}`}>
            <SubappHeader
              className={`${isPortalNavEnabled ? cn.subappHeader : ''}`}
            >
              My Approvals
            </SubappHeader>
            {isPortalNavEnabled && sodRole === 'APPROVER' && (
              <div className={cn.toggles}>
                <ButtonSecondarySmall
                  data-testid="click-toggle-my-approvals"
                  disabled={true}
                  className={cn.noBorderRadiusRight}
                >
                  My Approvals
                </ButtonSecondarySmall>
                <ButtonSecondarySmall
                  data-testid="click-toggle-all-approvals"
                  onClick={() =>
                    history.push(APP_ROUTES.approvals.history.allApprovals)
                  }
                  className={cn.noBorderRadiusLeft}
                >
                  All Approvals
                </ButtonSecondarySmall>
              </div>
            )}
          </div>
          <HistoricalApprovals
            flyerList={gqlFilter(
              HistoricalApprovals.fragments.flyerList,
              idx(sodUser, _ => _.userFlyerApprovalHistory) || {}
            )}
            flyerReasons={gqlFilter(
              HistoricalApprovals.fragments.flyerReasons,
              flyerReasons
            )}
            isDistrictAdmin={isDistrictAdmin}
            sod={sod}
            updateQueryVariables={this.updateQueryVariables}
            // TODO
            loading={gqlStuff.apolloHelpers.loading}
            offset={
              gqlStuff.currentQueryVariables.MyApprovals_sodUser_offset || 0
            }
            shouldUserSeeApproverNotesButton={shouldUserSeeApproverNotesButton}
          />
        </div>
      </RoleCheckWithRedirect>
    );
  }
}

const cn = {
  sectionHeadline: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  subappHeader: css`
    padding-top: 1.75rem !important;
  `,
  toggles: css`
    margin-top: -9px;
  `,
  noBorderRadiusRight: css`
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  `,
  noBorderRadiusLeft: css`
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  `,
};

MyApprovals.fragments = {
  sodUser: gql`
    fragment MyApprovals_sodUser on SodUser {
      id
      permission {
        adminLevel
        approverLevel
      }
      approvalCenterRegions {
        districtId
      }
      userFlyerApprovalHistory(
        limit: $MyApprovals_sodUser_limit
        offset: $MyApprovals_sodUser_offset
        filter: $MyApprovals_sodUser_filter
      ) {
        ...HistoricalFlyerList_flyers
      }
    }
    ${HistoricalApprovals.fragments.flyerList}
  `,
  flyerReasons: gql`
    fragment MyApprovals_flyerReasons on FlyerReason {
      ...HistoricalApprovals_flyerReasons
    }
    ${HistoricalApprovals.fragments.flyerReasons}
  `,
};

type RootState = {
  flags: Flags;
};

export default connect(
  (state: RootState) => ({ flags: state.flags }),
  null
)(MyApprovals);
