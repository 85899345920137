/* global document */
/* eslint-disable react/jsx-filename-extension */
import '@babel/polyfill';
import 'element-scroll-polyfill';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// This import must come after the above imports, or IE11 will break.
import 'react-app-polyfill/ie11';
// polyfill for String.prototype.normalize
import 'unorm';
// initializes Google Maps
import './initGoogleMaps';

/* eslint-disable import/first */
import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';

import './_app/logging/sentry';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import AppContainer from './_app/AppContainer';
// import registerAnalyticsSubscribers from './Analytics/registerAnalyticsSubscribers';
// import registerServiceWorker from './registerServiceWorker';

document.title = 'Peachjar Portal';

// registerAnalyticsSubscribers();

Raven.context(() => {
  ReactDOM.render(<AppContainer />, document.getElementById('root'));
});

// registerServiceWorker();
