import gql from 'graphql-tag';

const SUBMIT_CAMPAIGN_MUTATION = gql`
  mutation saveCampaignDetails($campaignId: String!) {
    submitCampaign(campaignId: $campaignId) {
      campaign {
        id
      }
    }
  }
`;

export default SUBMIT_CAMPAIGN_MUTATION;
