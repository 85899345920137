import gql from 'graphql-tag';
import OcrFragments from '../components/Ocr/gql/OcrFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment Prepare_getMyCampaigns on Flyer {
      ...Ocr_getMyCampaigns
    }
    ${OcrFragments.getMyCampaigns}
  `,
};
