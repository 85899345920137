const getFormattedDate = seconds => {
  const millis = seconds * 1000;
  const date = new Date(millis);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const month = monthIndex + 1;
  const year = date.getFullYear();
  const formatNumber = number => (number < 10 ? `0${number}` : `${number}`);

  return `${formatNumber(month)}-${formatNumber(day)}-${year}`;
};

export default getFormattedDate;
