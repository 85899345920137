import React from 'react';

export default function SchoolGrayscaleSvg(props) {
  return (
    <div style={{ width: '75px', height: '56px' }}>
      <svg
        viewBox="0 0 171.57 127.5"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        {...props}
      >
        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.cls-1{fill:#999;}.cls-2{fill:#848484;}.cls-3{fill:#bababa;}.cls-4{fill:#b2b2b2;}.cls-5{fill:#d6d6d6;}.cls-6{fill:#fff;}.cls-7{fill:#adadad;}.cls-8{fill:#eaeaea;}',
            }}
          />
        </defs>
        <title>Asset 14</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_2-2" data-name="Layer 2">
            <path
              className="cls-1"
              d="M165.87,28.25H122a1.46,1.46,0,0,1-.88-.3L85.74.68A1.44,1.44,0,0,0,84,.68L48.61,28a1.46,1.46,0,0,1-.88.3H6.55a1.45,1.45,0,0,0-1.38,1L.07,45.69a1.44,1.44,0,0,0,1.37,1.87H170.13a1.44,1.44,0,0,0,1.39-1.81l-4.26-16.42A1.42,1.42,0,0,0,165.87,28.25Z"
            />
            <path
              className="cls-2"
              d="M84.82,47.56h85.31a1.44,1.44,0,0,0,1.39-1.81l-4.26-16.42a1.42,1.42,0,0,0-1.39-1.08H122a1.46,1.46,0,0,1-.88-.3L84.86,0"
            />
            <path
              className="cls-3"
              d="M114.55,50H55.18a1.43,1.43,0,0,1-1.44-1.44V35.71a1.41,1.41,0,0,1,.56-1.14L84,11.68a1.44,1.44,0,0,1,1.76,0l29.68,22.89a1.42,1.42,0,0,1,.57,1.14V48.56A1.44,1.44,0,0,1,114.55,50Z"
            />
            <rect
              className="cls-4"
              x="2.74"
              y="47.49"
              width="164.25"
              height="68.51"
            />
            <rect
              className="cls-4"
              x="2.74"
              y={116}
              width="164.25"
              height="11.5"
            />
            <rect
              className="cls-5"
              x="50.99"
              y="121.75"
              width="68.5"
              height="5.75"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="54.28"
              y={116}
              width={62}
              height="5.75"
              rx="0.72"
            />
            <rect
              className="cls-6"
              x="69.15"
              y="81.83"
              width={32}
              height="34.17"
            />
            <rect
              className="cls-2"
              x="56.82"
              y="82.83"
              width="5.33"
              height="33.06"
            />
            <rect
              className="cls-2"
              x="107.99"
              y="82.83"
              width="5.33"
              height="33.06"
            />
            <circle className="cls-7" cx="84.86" cy="34.71" r="7.92" />
            <rect
              className="cls-5"
              x="11.41"
              y="52.01"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="12.56"
              y="53.16"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="33.06"
              y="52.01"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="34.2"
              y="53.16"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="54.7"
              y="52.01"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="55.85"
              y="53.16"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="11.41"
              y="73.66"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="12.56"
              y="74.8"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="33.16"
              y="73.66"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="34.3"
              y="74.8"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="11.41"
              y="95.3"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="12.56"
              y="96.45"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="33.16"
              y="95.3"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="34.3"
              y="96.45"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="119.64"
              y="52.01"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="120.78"
              y="53.16"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="141.28"
              y="52.01"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="142.43"
              y="53.16"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="76.35"
              y="52.01"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="77.49"
              y="53.16"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="97.99"
              y="52.01"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="99.14"
              y="53.16"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="119.54"
              y="73.66"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="120.68"
              y="74.8"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="141.28"
              y="73.66"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="142.43"
              y="74.8"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="119.54"
              y="95.3"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="120.68"
              y="96.45"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-5"
              x="141.28"
              y="95.3"
              width="14.79"
              height="14.79"
              rx="0.72"
            />
            <rect
              className="cls-5"
              x="142.43"
              y="96.45"
              width="12.5"
              height="12.5"
              rx="0.61"
            />
            <rect
              className="cls-8"
              x="70.7"
              y="82.83"
              width="28.83"
              height="33.17"
              rx="0.74"
            />
            <path
              className="cls-5"
              d="M111.18,74.56H60.25a.7.7,0,0,0-.58.3l-5,6.83a.72.72,0,0,0,.58,1.14h60a.72.72,0,0,0,.62-1.09l-4.13-6.83A.71.71,0,0,0,111.18,74.56Z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
