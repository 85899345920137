import React from 'react';
import { components, colors, elements } from '@peachjar/components';
import FormHelperText from '@material-ui/core/FormHelperText';
import CallToAction from '../../../CallToAction';
import withValidation from '../../../../../../../shared/components/withValidation';
import { MISSED_FIELD_ERROR_MESSAGE } from '../../../../../../../shared/constants';
import CallFragments from './gql/CallFragments.graphql';

const { TextInput } = components;
const { dragon } = colors;
const { Note } = elements.typography;

const name = 'Call';
const LABEL = 'Telephone Number';

export const Call = React.memo(
  ({
    isSelected,
    phoneNumber,
    handleInputChange,
    handleCancel,
    handleSelection,
    performValidation,
    shouldPerformDisabledClickValidation,
  }) => {
    const props = {
      label: name,
      name,
      isSelected,
      handleSelection,
      handleCancel,
    };

    if (phoneNumber) {
      performValidation({
        target: {
          name,
          value: phoneNumber,
        },
      });
    }

    const isError =
      shouldPerformDisabledClickValidation && phoneNumber.length === 0;

    // TODO: There was never any real validation/error notification here, currently just showing You missed this. Error when phoneNumber is '' and shouldPerformDisabledClickValidation is true
    return (
      <CallToAction {...props}>
        <div style={{ padding: '1rem 0' }}>
          <TextInput
            label={LABEL}
            name="Call"
            error={isError}
            data-testid="inputPhoneNumber"
            type="tel"
            value={phoneNumber}
            onBlur={event =>
              performValidation({
                target: {
                  name,
                  value: event.target.value,
                },
              })
            }
            onChange={event => {
              handleInputChange({
                target: {
                  value: {
                    phoneNumber: event.target.value,
                    __typename: 'PhoneNumber',
                  },
                },
              });
            }}
          />
          {isError && (
            <FormHelperText>
              <Note
                style={{ color: dragon }}
                data-testid="urlInputErrorMessage"
              >
                {MISSED_FIELD_ERROR_MESSAGE}
              </Note>
            </FormHelperText>
          )}
        </div>
      </CallToAction>
    );
  }
);

Call.fragments = CallFragments;

export const validate = phoneNumber => {
  const regex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
  return regex.test(phoneNumber);
};

export default withValidation(Call, validate);
