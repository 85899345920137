import React, { useState } from 'react';
import queryString from 'query-string';
import { elements } from '@peachjar/components';
import { css } from 'react-emotion';
import { graphql, compose } from 'react-apollo';
import routePushHandler from '../../../../shared/routePushHandler';
import CreateDraftCampaignButton from './components/CreateDraftCampaignButton';
import SaveCampaignButton from './components/SaveCampaignButton';
import UploadFlyerMutation from './components/UploadFlyerMutation';
import ReviewFlyerGuidelines from './components/ReviewFlyerGuidelines';
import {
  SET_USER_TEXTS_MUTATION,
  SET_FLYER_ID_MUTATION,
} from '../../gql/Prepare.graphql';
import { CommunityFreeConfig } from '../../../types';

const { CircleList } = elements;
const { Label } = elements.typography;

type Props = {
  setFlyerId: (arg: object) => void;
  flyerId: string;
  communityFreeConfig: CommunityFreeConfig;
};

const styles = {
  continueButton: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  `,
};

export const Upload = ({
  flyerId,
  history,
  remoteCampaign,
  setFlyerId,
  setUserTexts,
  communityFreeConfig,
}: Props) => {
  const [isUploading, setIsUploading] = useState(false);

  return (
    <CircleList>
      <li>
        <ReviewFlyerGuidelines />
      </li>

      <li>
        <Label>Upload Flyer</Label>

        <UploadFlyerMutation
          flyerId={flyerId}
          setFlyerId={setFlyerId}
          setUserTexts={setUserTexts}
          setIsUploading={setIsUploading}
          isUploading={isUploading}
        />
      </li>

      <div className={styles.continueButton}>
        {remoteCampaign ? (
          <SaveCampaignButton
            disabled={!flyerId || isUploading}
            flyerId={flyerId}
            history={history}
            remoteCampaign={remoteCampaign}
            handleSuccess={routePushHandler(
              history,
              `/campaigns/create-campaign/${remoteCampaign.id}/prepare/preview`
            )}
          >
            Continue to Preview
          </SaveCampaignButton>
        ) : (
          <CreateDraftCampaignButton
            disabled={!flyerId || isUploading}
            flyerId={flyerId}
            history={history}
            applicationId={communityFreeConfig.applicationId}
            skip={true}
            handleSuccess={campaignId =>
              routePushHandler(
                history,
                `/campaigns/create-campaign/${campaignId}/prepare/preview`
              )()
            }
          >
            Continue to Preview
          </CreateDraftCampaignButton>
        )}
      </div>
    </CircleList>
  );
};

export default compose(
  graphql(SET_FLYER_ID_MUTATION, { name: 'setFlyerId' }),
  graphql(SET_USER_TEXTS_MUTATION, { name: 'setUserTexts' })
)(Upload);
