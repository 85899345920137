import gql from 'graphql-tag';
import FlyerTitleFragments from '../FlyerTitle/gql/FlyerTitleFragments.graphql';
import ParticipationFeeFragments from '../ParticipationFee/gql/ParticipationFeeFragments.graphql';
import EventLocationFragments from '../EventLocation/gql/EventLocationFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment ProviderDetailsForm_getMyCampaigns on Campaign {
      id
      ...FlyerTitle_getMyCampaigns
      ...ParticipationFee_getMyCampaigns
      ...EventLocation_getMyCampaigns
    }
    ${FlyerTitleFragments.getMyCampaigns}
    ${ParticipationFeeFragments.getMyCampaigns}
    ${EventLocationFragments.getMyCampaigns}
  `,
};
