import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import SubappHeader from "../../_app/components/SubappHeader";

import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../_app/constants';
import { css } from "emotion";

export default class PurchaseHistoryPage extends Component {
  render() {
    return <div className={`${cn.root}`}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <SubappHeader className={cn.sectionHeadline}>
            Credit Purchasing History
          </SubappHeader>
        </Grid>
        <Grid item xs={12}>
          <div>You purchase history should be displayed below.</div>
          <Link to={APP_ROUTES.credits.index}>Back to Purchasing Page.</Link>
        </Grid>
      </Grid>
    </div>;
  }
}

// classes
const cn = {
  root: css`
    margin-bottom: 130px;
  `,
  sectionHeadline: css`
    padding-bottom: 0.6rem;
  `,
};
