import React from 'react';
import gql from 'graphql-tag';
import CallToAction from '../../../CallToAction';
import UrlInput from '../../../UrlInput';
import sanitizeUrlInput from '../../../util/sanitizeUrlInput';
import withValidation from '../../../../../../../shared/components/withValidation';
import DonateFragments from './gql/DonateFragments.graphql';

const NAME = 'Donate';
const LABEL = 'Donate';

export const Donate = ({
  isSelected,
  donateWebsiteUrl,
  handleInputChange,
  handleCancel,
  handleSelection,
  performValidation,
  shouldPerformDisabledClickValidation,
}) => {
  const props = {
    label: LABEL,
    name: NAME,
    isSelected,
    handleSelection,
    handleCancel,
  };

  return (
    <CallToAction {...props}>
      <div style={{ padding: '1rem 0' }}>
        <UrlInput
          name={NAME}
          shouldPerformDisabledClickValidation={
            shouldPerformDisabledClickValidation
          }
          label="Donate URL"
          placeholder="http://"
          data-testid="donateWebsiteUrl"
          url={donateWebsiteUrl}
          handleInputChange={event => {
            const sanitizedInput = sanitizeUrlInput(event);

            handleInputChange({
              target: {
                value: {
                  donateWebsiteUrl: sanitizedInput,
                  __typename: 'DonateWebsiteUrl',
                },
              },
            });
          }}
          handleIsValid={(name, isValid) =>
            performValidation({
              target: {
                name,
                value: {
                  isSelected,
                  isValid,
                },
              },
            })
          }
        />
      </div>
    </CallToAction>
  );
};

Donate.fragments = DonateFragments;

const validate = ({ isSelected, isValid }) =>
  isSelected === true && isValid === true;
export default withValidation(Donate, validate);
