const getPostDuration = (duration: number): string => {
  if (duration < 0) {
    return '0 days';
  }

  if (duration === 0) {
    return '1 day';
  }

  return `${duration} days`;
};

export default getPostDuration;
