import React, { useState, useEffect } from 'react';
import idx from 'idx';
import gql from 'graphql-tag';
import DealsContext from '../../context/DealsContext';
import { DEALS_PROMO_CODES } from '../../../_app/constants';
import { apolloClient as bffClient } from '../../apollo/portalBFF.apolloClient';

type Props = {
  children: {
    [k: string]: any;
  };
};

type School = {
  [k: string]: any;
};

const GET_MY_CREDITS = gql`
  query {
    getMyCreditBalance {
      numberOfCredits
    }
  }
`;

const getCreditBalance = async () => {
  const result = await bffClient.query({
    query: GET_MY_CREDITS,
    fetchPolicy: 'no-cache',
  });

  const { data } = result;
  const creditBalance =
    idx(data, _ => _.getMyCreditBalance.numberOfCredits) || 0;

  return creditBalance;
};

const DealsProvider = ({ children }: Props) => {
  const [credits, updateCredits] = useState(0);
  const [creditBalance, updateCreditBalance] = useState(0);
  const [starterKitAdded, updateStarterKitAdded] = useState(false);
  const [summaryTrayExpanded, updateSummaryTrayExpanded] = useState(false);
  const [numberOfDistributions, setNumberOfDistributions] = useState(1);
  const [schoolDistributionList, updateSchoolDistributionList] = useState([]);
  // persist campaignDetails in order to save campaign when removing schools from tray - sucks :(
  const [campaignDetails, setCampaignDetails] = useState({});
  const [dealsInFlight, setDealsInFlight] = useState(false);
  const [appliedPromoCodes, updateAppliedPromoCodes] = useState([]);

  useEffect(() => {
    refreshCredits();
  }, []);

  const refreshCredits = async () => {
    const newCreditBalance = (await getCreditBalance()) || 0;

    updateCreditBalance(newCreditBalance);
  };

  const providerAPI = {
    credits,
    creditBalance,
    starterKitAdded,
    campaignDetails,
    dealsInFlight,
    summaryTrayExpanded,
    numberOfDistributions,
    schoolDistributionList,
    updateSchoolDistributionList,
    setNumberOfDistributions,
    setCampaignDetails,
    setDealsInFlight,
    appliedPromoCodes,
    setCredits: (val: number) => updateCredits(val),
    refreshCreditBalance: refreshCredits,
    setStarterKit: (value: boolean) => updateStarterKitAdded(value),
    setSummaryTray: (value: boolean) => updateSummaryTrayExpanded(value),
    setAppliedPromoCodes: (code: string) => {
      const matchCode =
        code === DEALS_PROMO_CODES.CYBERSALE20 ||
        code === DEALS_PROMO_CODES.CYBERSALE50
          ? code
          : code.toLowerCase();
      if (!appliedPromoCodes.includes(matchCode)) {
        updateAppliedPromoCodes([...appliedPromoCodes, matchCode]);
      }
    },
    removeAppliedPromoCode: (code: string) => {
      const matchCode =
        code === DEALS_PROMO_CODES.CYBERSALE20 ||
        code === DEALS_PROMO_CODES.CYBERSALE50
          ? code
          : code.toLowerCase();
      if (appliedPromoCodes.includes(matchCode)) {
        const promoCodes = appliedPromoCodes.filter(c => {
          if (
            matchCode === DEALS_PROMO_CODES.CYBERSALE20 ||
            matchCode === DEALS_PROMO_CODES.CYBERSALE50
          ) {
            return c !== matchCode;
          }

          return c.toLowerCase() !== matchCode;
        });
        updateAppliedPromoCodes(promoCodes);
      }
    },
    clearAppliedPromoCodes: () => updateAppliedPromoCodes([]),
    setSchoolDistributionList: (school: School) => {
      const schoolInDistributionList = schoolDistributionList.find(
        s => s.schoolId === school.schoolId
      );

      if (!schoolInDistributionList) {
        updateSchoolDistributionList([...schoolDistributionList, school]);
      } else {
        const filteredSchoolList = schoolDistributionList.filter(
          s => s.schoolId !== school.schoolId
        );
        updateSchoolDistributionList([...filteredSchoolList]);
      }
    },
    updateSchool: (school: any) => {
      const filteredSchools = schoolDistributionList.filter(
        s => s.schoolId !== school.schoolId
      );

      updateSchoolDistributionList([...filteredSchools, school]);
    },
  };

  return (
    <DealsContext.Provider value={providerAPI}>
      {children}
    </DealsContext.Provider>
  );
};

export default DealsProvider;
