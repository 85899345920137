import * as React from 'react';
import { css, cx } from 'emotion';
import { useQuery } from '@apollo/react-hooks';
import { Spinner } from '@peachjar/ui/dist/lib/components/Spinners/Spinners';
import gql from 'graphql-tag';
import idx from 'idx';
import { elements } from '@peachjar/components';
import bffClient from '../_app/apollo/portalBFF.apolloClient';

// const { Badge } = components;
const { Note } = elements.typography;

type BadgeProps = {
  children: string | number;
  color?: string;
  className?: string;
};

const GET_APPROVAL_CENTER_REGIONS = gql`
  query getApprovalCenterRegions {
    approvalCenterRegions {
      id
      pendingApprovalCount
    }
  }
`;

export const Badge = ({ color, children, className }: BadgeProps) => (
  <Note className={cx(classNames.badge(color), className)}>{children}</Note>
);

const classNames = {
  badge: color => css`
    display: inline-block;
    padding: 0.125rem 0.25rem;
    background-color: ${color};
    border-radius: 10px;
    margin-left: 0.25rem;
    font-weight: normal;
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
    min-width: 1.5rem;
    text-align: center;
  `,
};

Badge.defaultProps = {
  color: '#c5e8c9', // Some variation on mint
  className: '',
};

const PendingApprovalsSidebarNavItemBadge = () => {
  const { loading, data } = useQuery(GET_APPROVAL_CENTER_REGIONS, {
    client: bffClient,
  });

  if (loading) {
    return <Spinner className="spinit" color="jungle" />;
  }

  const regions = idx(data, _ => _.approvalCenterRegions);
  const pendingApprovalsCount = regions.reduce(
    (total, { pendingApprovalCount }) => total + pendingApprovalCount,
    0
  );
  return <Badge>{pendingApprovalsCount}</Badge>;
};

export default PendingApprovalsSidebarNavItemBadge;
