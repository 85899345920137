import React, { Component } from 'react';
import { elements } from '@peachjar/components';
import get from 'lodash/get';
import { css } from 'react-emotion';
import DetailsFragments from './gql/DetailsFragments.graphql';
import SaveCampaignDetailsButton from './components/SaveCampaignDetailsButton';
import ProvideDetailsForm from './components/ProvideDetails';
import CallsToAction from './components/CallsToAction';
import routePushHandler from '../../shared/routePushHandler';
import bffClient from '../../../_app/apollo/portalBFF.apolloClient';
import { GET_CAMPAIGN_CALL_TO_ACTIONS } from '../Summary/gql/Summary.graphql';

const { CircleList } = elements;

const styles = {
  row: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  `,
  formColumnWrapper: css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  `,
  numberedRowWrapper: css`
    margin-top: 4rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  `,
  imageWrapper: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  continueWrapper: css`
    display: flex;
    justify-content: flex-end;
  `,
};

type State = {
  isFormValid: boolean;
  formValidation: {
    provideDetails: boolean | null;
    callsToAction: boolean | null;
  };
};

type Props = {
  campaignId: string;
  history: any;
  setDetails: (details: any) => void;
  flyerTitle: string;
  participationFee: string;
  lowParticipationFee: string;
  highParticipationFee: string;
  eventLocation: string;
  isPrimaryCTASelected: boolean;
  handlePrimaryCTASelection: (selection: any) => void;
  handleSecondaryCTASelection: (selection: any) => void;
  handleCTAData: (ctaData: any) => void;
  primaryCTA: string;
  secondaryCTA: string;
  primaryCTAData: Object;
  secondaryCTAData: Object;
  remoteCampaign: Record<string, any>;
  formValidation: Object;
  handleCampaignDetailsFormValidation: ({ variables: Object }) => Promise<any>;
  shouldPerformDisabledClickValidation: boolean;
  campaignStartDate: any;
  postExpirationDate: any;
  numberOfDistributions: number;
  selectedAudience: string;
  checkboxSelections: any;
  handleShouldPerformDisabledClickValidation: (boolean: boolean) => void;
  onSaveFailed: (campaignId: string) => void;
};

export default class Details extends Component<Props, State> {
  static fragments: { [key: string]: any };

  state = {
    formValidation: {
      provideDetails: null,
      callsToAction: null,
    },
    isFormValid: false,
  };

  handleIsValid = (name: string, isValid: boolean) => {
    const { formValidation } = this.state;
    const { handleCampaignDetailsFormValidation } = this.props;

    if (formValidation[name] !== isValid) {
      // https://reactjs.org/docs/react-component.html#setstate
      // If the next state depends on the current state,
      // we recommend using the updater function form, instead:
      this.setState(state => {
        const updatedFormValidation = {
          ...state.formValidation,
          [name]: isValid,
        };

        // used to write form validity to apollo cache for the tabber
        handleCampaignDetailsFormValidation({
          variables: {
            formValidation: {
              __typename: 'CampaignDetailsFormValidation',
              isFormValid: this.isFormValid(updatedFormValidation),
            },
          },
        });

        return {
          formValidation: {
            ...state.formValidation,
            [name]: isValid,
          },
          isFormValid: this.isFormValid(updatedFormValidation),
        };
      });
    }
  };

  isFormValid = (formValidation: Object) => {
    const { provideDetails, callsToAction } = formValidation;

    return (
      provideDetails === true &&
      (callsToAction === null || callsToAction === true)
    );
  };

  render() {
    const {
      // campaign,
      campaignId,
      history,
      setDetails,
      flyerTitle,
      participationFee,
      lowParticipationFee,
      highParticipationFee,
      eventLocation,
      campaignStartDate,
      postExpirationDate,
      numberOfDistributions,
      isPrimaryCTASelected,
      handlePrimaryCTASelection,
      handleSecondaryCTASelection,
      primaryCTA,
      secondaryCTA,
      primaryCTAData,
      secondaryCTAData,
      handleCTAData,
      remoteCampaign,
      shouldPerformDisabledClickValidation,
      handleShouldPerformDisabledClickValidation,
      onSaveFailed,
      selectedAudience,
      checkboxSelections,
    } = this.props;

    const { isFormValid } = this.state;

    // Props to <SaveCampaignDetailsButton />
    const saveCampaignDetailsProps = {
      campaignId,
      flyerTitle,
      participationFee,
      lowParticipationFee,
      highParticipationFee,
      eventLocation,
      isPrimaryCTASelected,
      primaryCTA,
      primaryCTAData,
      isSecondaryCTASelected: secondaryCTA !== undefined && secondaryCTA !== '',
      secondaryCTA,
      secondaryCTAData,
      handleSuccess: routePushHandler(
        history,
        `/campaigns/create-campaign/${campaignId}/delivery/`
      ),
      campaignStartDate,
      postExpirationDate,
      numberOfDistributions,
      selectedAudience,
      checkboxSelections,
    };

    const provideDetailsFormProps = {
      flyerTitle,
      participationFee,
      eventLocation,
      setDetails,
      remoteCampaign,
      handleIsValid: this.handleIsValid,
      shouldPerformDisabledClickValidation,
      name: 'provideDetails',
    };

    const callsToActionProps = {
      campaignId,
      isPrimaryCTASelected,
      primaryCTA,
      primaryCTAData,
      secondaryCTA,
      secondaryCTAData,
      handlePrimaryCTASelection,
      handleSecondaryCTASelection,
      handleIsValid: this.handleIsValid,
      handleCTAData,
      shouldPerformDisabledClickValidation,
      name: 'callsToAction',
    };

    const flyerImageUrl =
      remoteCampaign &&
      remoteCampaign.flyer &&
      remoteCampaign.flyer.flyerPages[0].jpegUrl;
    return (
      <div>
        <div className={styles.row}>
          <div className={styles.column}>
            <CircleList>
              <li>
                <ProvideDetailsForm {...provideDetailsFormProps} />
              </li>
              <li>
                <div className={styles.numberedRowWrapper}>
                  <CallsToAction {...callsToActionProps} />
                </div>
              </li>
            </CircleList>
          </div>

          <div className={styles.column}>
            <div className={styles.imageWrapper}>
              {flyerImageUrl && (
                <img src={flyerImageUrl} style={{ maxWidth: '320px' }} />
              )}
            </div>
          </div>
        </div>
        <div className={styles.continueWrapper}>
          <div
            role="button"
            tabIndex="0"
            onClick={() =>
              !isFormValid && handleShouldPerformDisabledClickValidation(true)
            }
            onKeyPress={() =>
              !isFormValid && handleShouldPerformDisabledClickValidation(true)
            }
          >
            <SaveCampaignDetailsButton
              disabled={!isFormValid}
              handleError={() => onSaveFailed(campaignId)}
              {...saveCampaignDetailsProps}
            >
              Continue to Flyer Delivery
            </SaveCampaignDetailsButton>
          </div>
        </div>
      </div>
    );
  }
}

Details.fragments = DetailsFragments;
