import React from 'react';
import { css } from 'react-emotion';
import format from 'date-fns/format';
import { elements, fonts } from '@peachjar/components';
import PeachjarInformationsReport from '../../_app/components/PeachjarInformationsReport';

const { ProximaNova } = fonts;
const {
  typography: { Label, Paragraph },
} = elements;

type Props = {
  companyName: string,
  transactionId: string,
  transactionDate: number,
  paymentType: string,
  cardBrand: string,
  cardLastFour: string,
};

const ReceiptHeader = ({
  companyName,
  transactionId,
  transactionDate,
  paymentType,
  cardBrand,
  cardLastFour,
}: Props) => {
  const formattedDate =
    transactionDate && format(new Date(transactionDate), 'PP');

  return (
    <>
      <PeachjarInformationsReport />
      <div>
        <Label className={styles.label}>{companyName}</Label>
      </div>
      <div>
        <div className={styles.headerTextWrapper}>
          <div>
            <Paragraph className={styles.headerText}>
              Transaction #{transactionId}
              <br />
              Transaction Date: {formattedDate}
            </Paragraph>
          </div>
          <div>
            {paymentType === 'paypal' && (
              <p className={styles.paymentText}>Payment Method: PayPal</p>
            )}
            {paymentType === 'stripe' && (cardBrand && cardLastFour) && (
              <p className={styles.paymentText}>
                Payment Method: {cardBrand} - {cardLastFour}
              </p>
            )}
            {paymentType === 'stripe' && (!cardBrand || !cardLastFour) && (
              <p className={styles.paymentText}>Payment Method: Credit Card</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  headerTextWrapper: css`
    display: flex;
    justify-content: space-between;
    width: 70%;
    padding: 0 0 3.5rem 0;
  `,
  headerText: css`
    font-size: 16px;
    color: #7b7b7b;
  `,
  label: css`
    font-weight: bold;
  `,
  paymentText: css`
    font-family: ${ProximaNova};
    color: rgb(123, 123, 123);
    font-size: 16px;
    line-height: 24px;
  `,
};

export default ReceiptHeader;
