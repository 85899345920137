import React from 'react';
import { TableCell } from '@material-ui/core';
import { components } from '@peachjar/components';
import { Link } from 'react-router-dom';
import { Transaction } from '../History';

const { ButtonSecondarySmall } = components.Buttons;

type PurchaseAction = {
  type: string;
  details: { purchaseId: string };
};

type InvoiceAction = {
  type: string;
  details: { invoiceId: string };
};

type Transaction = {
  actions: Array<any>;
  amountInCents: number;
  amountInCredits: number;
  creditBalance: number;
  details: string;
  extraInfo: any;
  id: string;
  sequenceId: number;
  timestamp: number;
};

type Props = {
  actions: PurchaseAction[] | InvoiceAction[] | [];
  width?: string;
  formatButtonText?: (type: string) => string;
  transaction?: Transaction;
};

const ActionTableCell = ({
  actions,
  width,
  formatButtonText,
  transaction,
}: Props) => {
  if (actions.length) {
    const actionType = actions[0].type;
    const purchaseId = actions[0].details.purchaseId;
    const buttonText =
      actions[0] &&
      formatButtonText(actions[0].type)
        // Replace methods format string to match mocks
        .replace('Credit', '')
        .replace('Purchase', '')
        .replace('Flyer', '');
    let actionPath;

    // Credit purchase
    if (actionType === 'view-credit-purchase-receipt') actionPath = 'receipt';

    // Credit usage by flyer posting
    if (actionType === 'view-flyer-purchase-details') actionPath = 'details';

    return (
      <TableCell style={{ padding: '1rem', width }}>
        <Link
          to={`/account/transactions/${purchaseId}/${actionPath}`}
          style={{ textDecoration: 'none' }}
        >
          <ButtonSecondarySmall>{buttonText}</ButtonSecondarySmall>
        </Link>
      </TableCell>
    );
  }

  // If no actions, return empty table cell
  return <TableCell style={{ padding: '1rem', width }} />;
};

export default ActionTableCell;
