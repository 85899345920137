import React, { useContext, useEffect } from 'react';
import idx from 'idx';
import { css } from 'emotion';
import { colors, components } from '@peachjar/components';
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {
  centsToDollars,
  calcCostPerUnit,
  UnitDiscount,
} from '../../helpers/order';
import EditCreditsModal from '../components/PurchaseModal';
import PromoCodeContainer from './PromoCode/PromoCodeContainer';
import { FLYER_PURCHASE_DISCOUNT_CODES } from '../../deals/constants';
import InlineErrors from '../components/InlineErrors';
import PromoCodeErrors from './PromoCode/PromoCodeErrors';
import DealsContext from '../context/DealsContext';
import editSVG from '../../assets/pencil.svg';
import closeSVG from '../../assets/close-icon.svg';

const { ModalConsumer } = components;
const { STARTER_KIT_CODE } = FLYER_PURCHASE_DISCOUNT_CODES;

type PurchaseOrder = {
  total: [Quote];
};

type OrderError = {
  [k: string]: any;
};

type Quote = {
  accountBalance: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  additionalDiscounts: Array<any>;
  audiences: [{}];
  creditBasePrice: any;
  distBasePrice: any;
  distributionDiscounts: Array<any>;
  firstPurchase: boolean;
  lineItems: [{}];
  msrpSubtotal: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  purchaseDetails: {
    endDate: any;
    payment: any;
    quoteId: any;
    audiences: Array;
    startDate: any;
  };
  startingAccountBalance: number;
  subtotalAfterDiscounts: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  subtotalMinusAccountBalance: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  total: {
    priceInCents: number;
    discountPercent: number;
    numberOfCredits: number;
    msrpPriceInCents: number;
  };
  unfinishedDiscounts: Array<any>;
  unfinishedDistroLabels: Array<any>;
};

// Variant allows OrderSummary to be used outside of Org flyer submission. Solution for beta release, should be refactored in the future
enum Variant {
  Receipt = 'receipt',
  Details = 'details',
}

type Props = {
  quote: PurchaseOrder; // quote prop is actually a PurchaseOrder, but leaving prop named quote to edit less code
  errors: OrderError[];
  classes: {};
  setPaymentError: (val: any) => void;
  variant?: Variant;
  handlePromoCodeSubmission: (code: string) => void;
  handlePromoCodeRemoval: (code: string) => void;
  onReset: () => void;
};

const noop = () => { };

const OrderSummary: React.FunctionComponent<Props> = ({
  quote,
  errors,
  classes,
  setPaymentError = noop,
  variant,
  onReset,
  handlePromoCodeSubmission = noop,
  handlePromoCodeRemoval = noop,
}) => {
  const {
    creditBalance: currentCredits,
    setCredits,
    setStarterKit,
  } = useContext(DealsContext);

  // If adding new variant, add new boolean here and include in defaultVariant condition
  const isReceipt = variant === Variant.Receipt;
  const isDetails = variant === Variant.Details;
  const defaultVariant = !variant && !isReceipt && !isDetails;

  const total = idx(quote, _ => _.total) || null;
  const subtotal = idx(quote, _ => _.subtotalAfterDiscounts) || null;
  const msrpCreditsPriceInCents: number | null = idx(total, _ => _.msrpCreditPriceInCents) || null;
  const numberOfCredits: number | null =
    idx(total, _ => _.numberOfCredits) || 0;
  const msrp: number | null = idx(total, _ => _.msrpPriceInCents) || null;
  const amountDue: number | null = idx(total, _ => _.priceInCents) || 0;
  const subtotalCents: number | null = idx(subtotal, _ => _.priceInCents) || null;
  const discountPercent: number = idx(total, _ => _.discountPercent) || 0;
  const discounts: UnitDiscount[] =
    idx(quote, _ => _.additionalDiscounts) || null;
  const dollarDiscounts: UnitDiscount[] =
    idx(quote, _ => _.dollarDiscounts) || null;
  const hasDiscounts: boolean =
    Array.isArray(discounts) && discounts.length >= 1;
  const hasDollarDiscounts: boolean =
    Array.isArray(dollarDiscounts) && dollarDiscounts.length >= 1;
  const hasErrors: boolean = Array.isArray(errors) && errors.length >= 1;

  const newCreditBalance = numberOfCredits + currentCredits;

  if (!numberOfCredits || !msrp || !amountDue) {
    // todo: udpate UI with error state
  }

  const formattedMSRP = () => {
    if (msrpCreditsPriceInCents) {
    return centsToDollars(msrpCreditsPriceInCents);
  } else if (numberOfCredits) {
    return centsToDollars(500 * numberOfCredits);
  } else {
    return centsToDollars(0);
  }
}

  // Code specific for OrderSummary in org campaign flow and buy credits (default variant)
  if (defaultVariant) {
    const hasStarterKit = discounts.some(
      discount => discount.code === STARTER_KIT_CODE
    );

    useEffect(() => setStarterKit(hasStarterKit));
  }
  if (isDetails) {
    return (
      <>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={cn.diffRow}>
              <TableCell
                colSpan="2"
                style={{
                  width: '66%',
                  padding: '2px 8px 2px 32px',
                  fontSize: '16px',
                  fontFamily: 'Proxima Nova',
                  borderBottom: 'none',
                  fontWeight: 'bold',
                }}
              >
                Total
              </TableCell>
              <TableCell
                align="right"
                style={{
                  width: '17%',
                  padding: '2px 8px',
                  fontSize: '16px',
                  fontFamily: 'Proxima Nova',
                  borderBottom: 'none',
                }}
              >
                {numberOfCredits} c
              </TableCell>
              <TableCell
                align="right"
                style={{
                  width: '17%',
                  padding: '2px 32px 2px 8px',
                  fontSize: '16px',
                  fontFamily: 'Proxima Nova',
                  borderBottom: 'none',
                  fontWeight: 'bold',
                }}
              >
                {centsToDollars(amountDue)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className={cn.creditBalance}>
          <span className={cn.balanceText}>
            You saved {discountPercent}%{' '}
            <span className={cn.costPer}>
              (avg. cost per credit{' '}
              {amountDue === 0
                      ? '$0.00'
                      : calcCostPerUnit(amountDue, numberOfCredits)
                    })
            </span>
          </span>
        </div>
      </>
    );
  }

  return (
    <ModalConsumer>
      {({ showModal, closeModal }) => (
        <MuiThemeProvider theme={toolTipTheme}>
          <div className={cn.orderSummary} id="deals-order-summary">
            <Paper className={`${classes.root} ${classes.elevation2}`}>
              <hr className={cn.divvy} />
              <Table className={classes.table}>
                <TableBody>
                  <TableRow className={cn.highlightRow}>
                    <TableCell align="left" className={cn.cell}>
                      {defaultVariant && (
                        <div
                          onClick={() =>
                            showModal(EditCreditsModal, {
                              showModal,
                              closeModal,
                              onReset,
                              variant: 'editCredits',
                              credits: '' + numberOfCredits,
                            })
                          }
                        >
                          <Tooltip
                            title="Edit Item"
                            placement="top"
                            className={cn.ripple}
                          >
                            <IconButton
                              className={`${classes.button} ${cn.iconButton}`}
                              arial-label="Edit Credits"
                            >
                              <img src={editSVG} className={cn.cellIcon} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                      {numberOfCredits} Credits
                    </TableCell>
                    <TableCell
                      align="right"
                      className={`${cn.cell} ${cn.cellSm}`}
                    >
                      {formattedMSRP()}
                      {defaultVariant && (
                        <div
                          onClick={() => {
                            onReset();
                          }}
                        >
                          <Tooltip
                            title="Clear Item"
                            placement="top"
                            className={cn.ripple}
                          >
                            <IconButton
                              className={`${classes.button} ${cn.iconButtonRight}`}
                              arial-label="Clear Item"
                            >
                              <img
                                src={closeSVG}
                                className={cn.cellIconClear}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                  {hasDiscounts &&
                    getOrderDiscounts(
                      discounts,
                      classes,
                      handlePromoCodeRemoval,
                      defaultVariant
                    )}
                  <TableRow className={cn.diffRow}>
                    <TableCell align="left" className={`${cn.cell} highlight`}>
                      Subtotal
                    </TableCell>
                    <TableCell
                      align="right"
                      className={`${cn.cell} ${cn.cellSm} highlight`}
                    >
                      {centsToDollars(subtotalCents)}
                    </TableCell>
                  </TableRow>
                  {defaultVariant && hasErrors && (
                    <PromoCodeErrors errors={errors} columnSpans="2" />
                  )}
                  {(defaultVariant || isReceipt) &&
                    hasDollarDiscounts &&
                    getOrderDiscounts(
                      dollarDiscounts,
                      classes,
                      handlePromoCodeRemoval,
                      defaultVariant
                    )}
                  {!isReceipt &&
                    <TableRow>
                      <TableCell
                        align="left"
                        className={`${cn.cell}`}
                        colSpan="2"
                      >
                        {defaultVariant ? (
                          <PromoCodeContainer
                            handlePromoCodeSubmission={handlePromoCodeSubmission}
                          />
                        ) : (
                            <React.Fragment />
                          )}
                      </TableCell>
                    </TableRow>
                  }
                  <TableRow className={cn.diffRow}>
                    <TableCell align="left" className={`${cn.cell} highlight`}>
                      Total
                    </TableCell>
                    <TableCell
                      align="right"
                      className={`${cn.cell} ${cn.cellSm} highlight`}
                    >
                      {centsToDollars(amountDue)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div className={cn.creditBalance}>
                <span className={cn.balanceText}>
                  You saved {discountPercent}%{' '}
                  <span className={cn.costPer}>
                    (avg. cost per credit{' '}
                    {amountDue === 0
                      ? '$0.00'
                      : calcCostPerUnit(amountDue, numberOfCredits)
                    })                                        
                  </span>
                </span>
                {defaultVariant && (
                  <span className={cn.balanceText}>
                    New credit balance {newCreditBalance} c
                  </span>
                )}
              </div>
            </Paper>
          </div>
        </MuiThemeProvider>
      )}
    </ModalConsumer>
  );
};

const muiStyles = theme => ({
  root: {
    width: '100%',
    marginTop: '0',
    overflowX: 'auto',
  },
  table: {
    fontFamily: 'Proxima Nova, Arial, Helvetica, sans-serif',
  },
  elevation2: {
    boxShadow: 'none',
  },
  button: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#E9F6E9',
    },
  },
  child: {
    backgroundColor: '#E9F6E9',
  },
});

const toolTipTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#2d344b',
        borderRadius: '2px',
        fontSize: '13px',
        padding: '8px',
      },
    },
  },
});

const fauxRow = () => (
  <TableRow>
    <TableCell align="left" className={cn.cell} />
    <TableCell align="right" className={cn.cell} />
  </TableRow>
);

const getOrderDiscounts = (
  discounts: UnitDiscount[],
  muiClasses: { [k: string]: any },
  handleClick, isDefaultVariant = false
): React.ReactNode => {
  return discounts.map(
    (
      {
        code,
        type,
        savingsInCents,
        unit,
        friendlyName,
        friendlyDescription,
        value,
        valueType,
      },
      index
    ) => (
        <TableRow key={`${type}${index}`}>
          <TableCell align="left" className={cn.cell}>
            {friendlyName}{' '}
            <span className={`${cn.discountInfo} discount-description`}>
              {friendlyDescription && friendlyDescription.length
                ? `(${friendlyDescription})`
                : null}
            </span>
          </TableCell>
          <TableCell align="right" className={cn.cell}>
            - {centsToDollars(savingsInCents)}
            {type === 'promo' && isDefaultVariant && (
              <div onClick={() => handleClick(code)}>
                <Tooltip title="Clear Item" placement="top" className={cn.ripple}>
                  <IconButton
                    className={`${muiClasses.button} ${cn.iconButtonRight}`}
                    arial-label="Clear Item"
                  >
                    <img src={closeSVG} className={cn.cellIconClear} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </TableCell>
        </TableRow>
      )
  );
};

const cn = {
  diffRow: css`
    background-color: ${colors.platinum};

    .highlight {
      font-weight: bold;
    }
  `,
  creditBalance: css`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  balanceText: css`
    color: ${colors.prussian};
    font-size: 13px;
  `,
  costPer: css`
    color: ${colors.slate};
  `,
  sectionHeader: css`
    font-size: 19px;
    color: ${colors.prussian};
    border-bottom: 1px solid ${colors.silver};
    padding-bottom: 18px;
  `,
  cell: css`
    font-size: 16px !important;
    color: ${colors.prussian};
    position: relative;
    border-bottom: none !important;
    padding: 4px 37px !important;
  `,
  promoCell: css`
    position: relative;
    border-bottom: none !important;
    padding: 0 17px !important;
    background-color: #ffebe5;
  `,
  promoRowBorder: css`
    height: 8px !important;
  `,
  cellSm: css`
    width: 30% !important;
  `,
  cellLink: css`
    color: ${colors.jungle} !important;
  `,
  shade: css`
    color: ${colors.slate};
  `,
  cellIcon: css`
    height: 12px;
    width: 12px;
    cursor: pointer;
  `,
  cellIconClear: css`
    height: 14px;
    width: 14px;
    cursor: pointer;
  `,
  cellIconPlacement: css`
    position: absolute;
    left: 10px;
    top: 16px;
  `,
  iconButton: css`
    position: absolute !important;
    left: 0px;
    top: 5px;
  `,
  iconButtonRight: css`
    position: absolute !important;
    right: 0px;
    top: 5px;
  `,
  divvy: css`
    height: 0px;
    margin: 0;
    border-top: 1px solid ${colors.silver};
  `,
  discountInfo: css`
    color: ${colors.slate};
    font-size: 16px;
  `,
  ripple: css`
    background-position: center;
    transition: background 0.8s;

    &:hover {
      padding: 12px;
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
      border-radius: 20px;
    }

    &:active {
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
    }
  `,
  highlightRow: css`
    border-bottom: 1px solid ${colors.silver};
  `,
  paddingLeft: css`
    padding-left: 32px !important;
  `,
};

export default withStyles(muiStyles)(OrderSummary);
