import React from 'react';
import { css } from 'emotion';
import { elements, colors } from '@peachjar/components';
import config from '../../../../config';

const { Headline2, Paragraph } = elements.typography;
const { COMMUNITY_FREE_PROGRAM_GUIDELINES_URL } = config;

const CommunityFreeSubmittedMessage = () => (
  <>
    <Headline2
      className={styles.headline}
      data-test="campaign-submitted-msg-headline"
    >
      Your application has been submitted to Peachjar for review.
    </Headline2>

    <Paragraph className={styles.paragraph}>
      Please make note of the last steps for sending and posting your flyer
      through our Community Free Program. View the{' '}
      <a href={COMMUNITY_FREE_PROGRAM_GUIDELINES_URL} className={styles.link}>
        program guidelines
      </a>{' '}
      for more information.
    </Paragraph>
    <Paragraph className={styles.paragraph}>
      Peachjar will review your application and notify you whether your flyer
      qualifies for the Community Free Program within 2 business days.
      <div>
        <ul className={styles.textList}>
          <li>
            If <strong>accepted</strong>, your flyer will automatically be sent
            to the school district for approval to distribute to the families of
            your selected schools.
          </li>
          <li>
            If <strong>declined</strong>, no worries -- your flyer will be saved
            as a draft where you may choose to pay a service fee to distribute
            it.
          </li>
        </ul>
      </div>
    </Paragraph>
  </>
);

const styles = {
  headline: css`
    margin-bottom: 0.5rem;
  `,
  paragraph: css`
    display: block;
    margin-bottom: 25px;
  `,
  link: css`
    color: ${colors.jungle};
    &:hover {
      color: ${colors.jungle};
      text-decoration: none;
    }
  `,
  textList: css`
    padding-left: 19px;
    padding-top: 3px;
  `,
};

export default CommunityFreeSubmittedMessage;
