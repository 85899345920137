import React from 'react';
import gql from 'graphql-tag';
import CallToAction from '../../../CallToAction';
import UrlInput from '../../../UrlInput';
import sanitizeUrlInput from '../../../util/sanitizeUrlInput';
import withValidation from '../../../../../../../shared/components/withValidation';
import SignUpFragments from './gql/SignUpFragments.graphql';

const NAME = 'SignUp';
const LABEL = 'Sign Up';

export const SignUp = ({
  isSelected,
  websiteUrl,
  handleInputChange,
  handleCancel,
  handleSelection,
  performValidation,
  shouldPerformDisabledClickValidation,
}) => {
  const props = {
    label: LABEL,
    name: NAME,
    isSelected,
    handleSelection,
    handleCancel,
  };

  return (
    <CallToAction {...props}>
      <div style={{ padding: '1rem 0' }}>
        <UrlInput
          name={NAME}
          shouldPerformDisabledClickValidation={
            shouldPerformDisabledClickValidation
          }
          label="Sign Up URL"
          placeholder="http://"
          data-testid="inputWebsiteUrl"
          url={websiteUrl}
          handleInputChange={event => {
            const sanitizedInput = sanitizeUrlInput(event);

            handleInputChange({
              target: {
                value: {
                  websiteUrl: sanitizedInput,
                  __typename: 'WebsiteUrl',
                },
              },
            });
          }}
          handleIsValid={(name, isValid) =>
            performValidation({
              target: {
                name,
                value: {
                  isSelected,
                  isValid,
                },
              },
            })
          }
        />
      </div>
    </CallToAction>
  );
};

SignUp.fragments = SignUpFragments;

const validate = ({ isSelected, isValid }) =>
  isSelected === true && isValid === true;
export default withValidation(SignUp, validate);
