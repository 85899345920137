import gql from 'graphql-tag';

export const GET_SCHOOLS_BY_ID_QUERY = gql`
  query GetSchoolsBySchoolId($schoolIds: [Int!]!) {
    getSchoolsBySchoolId(schoolIds: $schoolIds) {
        items {
            audienceType
            district {
                name
                url
                isPostOnly: orgPostOnly
                isOptionalPostOnly: orgOptionalPostOnly
            }
            districtId
            highestGradeOffered
            lowestGradeOffered
            isPostOnly: orgPostOnly
            isOptionalPostOnly: orgOptionalPostOnly
            name
            ncesId
            schoolId
        }
        total
    }
  }
`;
