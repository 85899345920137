import React from 'react';
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import get from 'lodash/get';
import idx from 'idx';
import { css } from 'emotion';
import { colors } from '@peachjar/components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PostOnlyInput from '../../campaigns/Create-Campaign/components/PostOnlyInput';
import { centsToDollars } from '../../helpers/order';
import closeSVG from '../../assets/close-icon.svg';
import { School } from '../../campaigns/Create-Campaign/Delivery/components/AudienceAndSchools/SodSelections/Org/types';

enum Variant {
  Details = 'details',
}

type Quote = {
  accountBalance: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  additionalDiscounts: Array<any>;
  audiences: [{}];
  creditBasePrice: any;
  distBasePrice: any;
  distributionDiscounts: Array<any>;
  firstPurchase: boolean;
  lineItems: [{}];
  msrpSubtotal: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  purchaseDetails: {
    endDate: any;
    payment: any;
    quoteId: any;
    audiences: Array;
    startDate: any;
  };
  startingAccountBalance: number;
  subtotalAfterDiscounts: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  subtotalMinusAccountBalance: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  total: {
    priceInCents: number;
    discountPercent: number;
    numberOfCredits: number;
    msrpPriceInCents: number;
  };
  unfinishedDiscounts: Array<any>;
  unfinishedDistroLabels: Array<any>;
};

type Props = {
  quote: Quote;
  classes: any;
  handleSchoolRemoval?: any;
  panelExpanded?: boolean;
  onPanelExpand?: any;
  variant?: Variant;
  postOnlySelections?: string[];
  schoolDistributionList: School[];
  handlePostOnlySelection: (schoolId: string) => void;
};

const FlyerOrderSummaryDetails = ({
  quote,
  classes,
  schoolDistributionList,
  handleSchoolRemoval,
  postOnlySelections,
  panelExpanded,
  onPanelExpand,
  variant,
  handlePostOnlySelection,
}: Props) => {
  const isDetails = variant === Variant.Details;
  const defaultVariant = !variant && !isDetails;

  const distBasePriceValue = Number(get(quote, 'distBasePrice.value') || 0)
  const schoolList = (quote && quote.audiences) || [];
  const distributionDiscounts = (quote && quote.distributionDiscounts) || [];
  const additionalDiscounts = (quote && quote.additionalDiscounts) || [];
  const dollarDiscounts = (quote && quote.dollarDiscounts) || [];

  const subtotalCreditsAfterDiscounts =
    idx(quote, _ => _.subtotalAfterDiscounts.numberOfCredits) || 0;
  const subtotalCentsAfterDiscounts =
    idx(quote, _ => _.subtotalAfterDiscounts.priceInCents) || 0;
  const formattedSubtotal = centsToDollars(subtotalCentsAfterDiscounts);

  const hasDistributionDiscounts =
    Array.isArray(distributionDiscounts) && distributionDiscounts.length >= 1;
  const hasAdditionalDiscounts =
    Array.isArray(additionalDiscounts) && additionalDiscounts.length >= 1;
  const hasDollarDiscounts =
    Array.isArray(dollarDiscounts) && dollarDiscounts.length >= 1;

  const sortedSchoolList = schoolList.sort(
    (a, b) => parseInt(a.schoolId, 10) - parseInt(b.schoolId)
  );

  return (
    <MuiThemeProvider theme={toolTipTheme}>
      <div className={cn.summaryListItems}>
        {sortedSchoolList.map(
          (
            {
              districtId,
              districtName,
              msrpPriceInCents: schoolPrice,
              numberOfCredits: schoolCredits,
              schoolName,
              schoolId,
              discountsApplied,
            },
            index
          ) => {
            const isPostOnly = distributionDiscounts.some(discounts =>
              discountsApplied.find(d => d === discounts.id)
              && discounts.code.includes('__po:district')
            )
            const priceInCents = isPostOnly ? schoolCredits * distBasePriceValue * 100 : schoolPrice
            const schoolMatch = (schoolDistributionList || []).find(
              school => school.schoolId === parseInt(schoolId, 10)
            );

            return (
              <div className={cn.listItem} key={schoolId}>
                <div className={cn.space1}>
                  {schoolName}
                </div>
                <div className={cn.space2}>
                  <span className={cn.districtName}>{districtName}</span>
                  {isPostOnly &&
                    <PostOnlyInput />
                  }
                </div>
                <div className={defaultVariant ? cn.space3 : cn.space3Variant}>
                  {schoolCredits} c
                </div>
                <div
                  className={`${
                    defaultVariant ? cn.space4 : cn.space4Variant
                  } ${cn.iconRow}`}
                >
                  {centsToDollars(priceInCents)}
                  {defaultVariant && (
                    <div onClick={() => handleSchoolRemoval(schoolId)}>
                      <Tooltip
                        title="Clear Item"
                        placement="top"
                        className={cn.ripple}
                      >
                        <IconButton
                          className={`${classes.button} ${cn.iconButtonRight}`}
                          arial-label="Clear Item"
                        >
                          <img src={closeSVG} className={cn.cellIconClear} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
      <Table className={classes.table}>
        <TableBody>
          {hasDistributionDiscounts &&
            distributionDiscounts.map(
              (
                {
                  code,
                  value,
                  valueType,
                  savingsInCents,
                  creditsSaved,
                  friendlyName,
                  friendlyDescription,
                },
                index
              ) => (
                <TableRow key={value + index}>
                  <TableCell
                    align="left"
                    className={`${cn.cell} ${cn.cellDiscount} ${cn.paddingLeft}`}
                    colSpan="2"
                  >
                    {friendlyName}{' '}
                    <span className={`${cn.discountInfo} discount-description`}>
                      {friendlyDescription && friendlyDescription.length
                        ? `(${friendlyDescription})`
                        : null}
                    </span>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={`${cn.cell} ${cn.cellDiscount} ${
                      defaultVariant ? cn.col3 : cn.col3Variant
                    }`}
                  >
                   -{creditsSaved} c
                  </TableCell>
                  <TableCell
                    align="right"
                    className={`${cn.cell} ${cn.cellDiscount} ${
                      defaultVariant ? cn.col4 : cn.col4Variant
                    }`}
                  >
                    -{centsToDollars(savingsInCents)}
                  </TableCell>
                </TableRow>
              )
            )}
          {hasAdditionalDiscounts &&
            additionalDiscounts.map(
              (
                {
                  code,
                  value,
                  conditions: { min, max },
                  savingsInCents,
                  friendlyName,
                  friendlyDescription,
                },
                index
              ) => (
                <TableRow key={value + index}>
                  <TableCell
                    align="left"
                    className={`${cn.cell} ${cn.cellDiscount} ${cn.paddingLeft}`}
                    colSpan="2"
                  >
                    {friendlyName}{' '}
                    <span className={`${cn.discountInfo} discount-description`}>
                      {friendlyDescription && friendlyDescription.length
                        ? `(${friendlyDescription})`
                        : null}
                    </span>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={`${cn.cell} ${cn.cellDiscount} ${
                      defaultVariant ? cn.col3 : cn.col3Variant
                    }`}
                  ></TableCell>
                  <TableCell
                    align="right"
                    className={`${cn.cell} ${cn.cellDiscount} ${
                      defaultVariant ? cn.col4 : cn.col4Variant
                    }`}
                  >
                    -{centsToDollars(savingsInCents)}
                  </TableCell>
                </TableRow>
              )
            )}
          <TableRow className={cn.orderTotal}>
            <TableCell
              align="left"
              className={`${cn.cell} ${cn.cellTotal} ${cn.paddingLeft}`}
              colSpan="2"
            >
              Subtotal
            </TableCell>
            <TableCell
              align="right"
              className={`${cn.cell} ${cn.cellTotalLight} ${
                defaultVariant ? cn.col3 : cn.col3Variant
              }`}
            >
              {subtotalCreditsAfterDiscounts} c
            </TableCell>
            <TableCell
              align="right"
              className={`${cn.cell} ${cn.cellTotal} ${
                defaultVariant ? cn.col4 : cn.col4Variant
              }`}
            >
              {formattedSubtotal}
            </TableCell>
          </TableRow>
          {isDetails &&
            hasDollarDiscounts &&
            dollarDiscounts.map(
              (
                {
                  code,
                  value,
                  conditions: { min, max },
                  savingsInCents,
                  friendlyName,
                  friendlyDescription,
                },
                index
              ) => (
                <TableRow key={value + index}>
                  <TableCell
                    align="left"
                    className={`${cn.cell} ${cn.cellDiscount} ${cn.paddingLeft}`}
                    colSpan="2"
                  >
                    {friendlyName}{' '}
                    <span className={`${cn.discountInfo} discount-description`}>
                      {friendlyDescription && friendlyDescription.length
                        ? `(${friendlyDescription})`
                        : null}
                    </span>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={`${cn.cell} ${cn.cellDiscount} ${
                      defaultVariant ? cn.col3 : cn.col3Variant
                    }`}
                  ></TableCell>
                  <TableCell
                    align="right"
                    className={`${cn.cell} ${cn.cellDiscount} ${
                      defaultVariant ? cn.col4 : cn.col4Variant
                    }`}
                  >
                    -{centsToDollars(savingsInCents)}
                  </TableCell>
                </TableRow>
              )
            )}
          {defaultVariant && (
            <TableRow
              onClick={() => onPanelExpand(!panelExpanded)}
              className={cn.fakeRow}
            >
              <TableCell colSpan="4" className={cn.fakeCell}></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </MuiThemeProvider>
  );
};

const cn = {
  cell: css`
    font-size: 13px;
    color: ${colors.prussian};
    position: relative;
    border-bottom: none !important;
    padding: 2px 37px !important;
  `,
  cellDiscount: css`
    font-size: 16px !important;
  `,
  districtName: css`
    color: ${colors.slate};
  `,
  cellTotal: css`
    font-size: 16px !important;
    font-weight: bold !important;
  `,
  cellTotalLight: css`
    font-size: 16px !important;
    font-weight: normal;
  `,
  orderTotal: css`
    background-color: ${colors.platinum};
  `,
  col3: css`
    width: 17%;

    @media screen and (min-width: 1200px) {
      & {
        width: 13.5%;
      }
    }
    @media screen and (min-width: 1600px) {
      & {
        width: 11.5%;
      }
    }
  `,
  col3Variant: css`
    width: 17%;
    padding: 2px 8px !important;
  `,
  col4: css`
    width: 17%;

    @media screen and (min-width: 1200px) {
      & {
        width: 13.5%;
      }
    }
    @media screen and (min-width: 1600px) {
      & {
        width: 11.5%;
      }
    }
  `,
  col4Variant: css`
    width: 17%;
    padding-right: 32px !important;
  `,
  summaryListItems: css`
    overflow-y: scroll;
    max-height: 363px;
    border-bottom: 2px solid ${colors.platinum};
    @media print {
      overflow-y: hidden;
      max-height: max-content !important;
    }
  `,
  listItem: css`
    width: 100%;
    height: 38px !important;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      background-color: ${colors.platinum};
    }
  `,
  space1: css`
    width: 38%;
    text-align: left;
    font-size: 13px;
    padding: 2px 2px 2px 37px !important;

    @media print, screen and (min-width: 1200px) {
      & {
        width: 25%;
      }
    }
    @media print, screen and (min-width: 1600px) {
      & {
        width: 22%;
      }
    }
  `,
  space1Variant: css`
    width: 28%;
    text-align: left;
    font-size: 13px;
    padding: 2px 8px 2px 32px !important;
  `,
  space2: css`
    width: 28%;
    text-align: left;
    font-size: 13px;
    padding: 2px 27px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media print, screen and (min-width: 1200px) {
      & {
        width: 48%;
      }
    }
    @media print, screen and (min-width: 1600px) {
      & {
        width: 56%;
      }
    }
  `,
  space2Variant: css`
    width: 45%;
    text-align: left;
    font-size: 13px;
    padding: 2px 8px !important;
  `,
  space3: css`
    width: 17%;
    text-align: right;
    font-size: 13px;
    padding: 2px 32px !important;

    @media screen and (min-width: 1200px) {
      & {
        width: 13.5%;
      }
    }
    @media screen and (min-width: 1600px) {
      & {
        width: 11.5%;
      }
    }
  `,
  space3Variant: css`
    width: 10%;
    text-align: right;
    font-size: 13px;
    /* padding: 2px 8px !important; */
  `,
  space4: css`
    width: 17%;
    text-align: right;
    font-size: 13px;
    padding: 2px 37px !important;

    @media screen and (min-width: 1200px) {
      & {
        width: 13.5%;
      }
    }
    @media screen and (min-width: 1600px) {
      & {
        width: 11.5%;
      }
    }
  `,
  space4Variant: css`
    width: 17%;
    text-align: right;
    font-size: 13px;
    padding: 2px 32px 2px 8px !important;
  `,
  fakeRow: css`
    width: 100%;
    cursor: pointer;
    border: none;
  `,
  fakeCell: css`
    border: none !important;
  `,
  ripple: css`
    background-position: center;
    transition: background 0.8s;

    &:hover {
      padding: 12px;
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
      border-radius: 20px;
    }

    &:active {
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
    }
  `,
  iconButtonRight: css`
    position: absolute !important;
    right: 0px;
    top: -8px;
  `,
  cellIconClear: css`
    height: 14px;
    width: 14px;
    cursor: pointer;
  `,
  iconRow: css`
    position: relative;
  `,
  paddingLeft: css`
    padding-left: 32px !important;
  `,
  discountInfo: css`
    color: ${colors.slate};
    font-size: 16px;
  `,
};

const muiStyles = theme => ({
  root: {
    width: '100%',
    marginTop: '12px 0',
    overflowX: 'auto',
  },
  table: {
    fontFamily: 'Proxima Nova, Arial, Helvetica, sans-serif',
  },
  elevation2: {
    boxShadow: 'none',
  },
  button: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#E9F6E9',
    },
  },
  child: {
    backgroundColor: '#E9F6E9',
  },
});

const toolTipTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#2d344b',
        borderRadius: '2px',
        fontSize: '13px',
        padding: '8px',
      },
    },
  },
});

export default withStyles(muiStyles)(FlyerOrderSummaryDetails);
