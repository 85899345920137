import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';
import { createUploadLink } from 'apollo-upload-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import config from '../../config';
import resolvers from './resolvers';
import defaults from './defaults';
import { setMessagesErrors } from '../errors/erros.duck';
import store from '../store';

const { BFF_GRAPHQL_URI } = config;

const httpOptions = { uri: BFF_GRAPHQL_URI, credentials: 'include' };
const uploadLink = createUploadLink(httpOptions);
const httpLink = new HttpLink(httpOptions);

const cache = new InMemoryCache({
  dataIdFromObject: object => object.id,
});

const errorLink = onError(({ graphQLErrors, networkError, operation  }) => { 
  const { operationName } = operation;
  if (graphQLErrors){
    const messages = [];
    graphQLErrors.forEach(({ message, path }) => {     
        let messageError = `${message}, Path: ${path}`;
        console.error(messageError);
        messages.push(messageError)
    });
    const isNotAuth = messages.some(message => 
        message.toLowerCase().includes("user is not authenticated")
    );

    if(isNotAuth) {
      window.location.reload();
    }
    else{
      store.dispatch(setMessagesErrors({ messages, operationName}))
    }
  }
   
  if (networkError) {    
    const message = `[Network error]: ${networkError}`;
    console.log(message);
    store.dispatch(setMessagesErrors({ messages: [message], operationName }))
  }
});

const stateLink = withClientState({
  cache,
  resolvers,
  defaults,
  schema: '../../local.graphql',
});

export const apolloClient = new ApolloClient({
  ssrMode: typeof window !== 'undefined',
  link: ApolloLink.from([errorLink, stateLink, uploadLink, httpLink]),
  cache,
});

export default apolloClient;
