import React from 'react';
import config from '../../config';

const { REACT_APP_SUBMISSIONS_URL } = config;

const RedirectSubmissions = () => {
  React.useEffect(() => {
    window.location.href = REACT_APP_SUBMISSIONS_URL;
  }, []);

  return null;
};

export default RedirectSubmissions;
