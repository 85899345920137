import gql from 'graphql-tag';
import { OcrEditField as HOClessOcrEditField } from '../OcrEditField';
import { JpegPage as HOClessJpegPage } from '../JpegPage';

export default {
  getMyCampaigns: gql`
    fragment OcrPages_getMyCampaigns on Flyer {
      id
      flyerPages {
        ...JpegPage_getMyCampaigns
        ...OcrEditField_getMyCampaigns
      }
    }
    ${HOClessJpegPage.fragments.getMyCampaigns}
    ${HOClessOcrEditField.fragments.getMyCampaigns}
  `,
};
