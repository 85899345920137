/* global window */

import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import {
  COMM_FREE_SCHOOL_SELECTION_LIMIT_KEY,
  DEFAULT_COMM_FREE_SCHOOL_SELECTION_LIMIT,
} from '../campaigns/shared/constants';

/**
 * Notes:
 * - We'll probably convert this to use configure store when we develop more store dependencies.
 * - Use below if we want to disable devTools for prod
 * const NODE_ENV = process.env.NODE_ENV;
 * const isProduction = NODE_ENV === 'production'
 * isProduction ? devToolsExtension : f => f
 */

const devToolsExtension = window.devToolsExtension
  ? window.devToolsExtension()
  : f => f;

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    devToolsExtension
    /* , add more here */
  )
);

export default store;
