import { DeliveryModes, Audience } from '../deals.types';

const audienceFactory = (
  audiences: Audience[],
  postOnlySelections: string[]
) => {
  return audiences.map(
    ({
      audienceType,
      districtId,
      districtName,
      schoolId,
      schoolName,
      isPostOnly,
      isOptionalPostOnly,
      isPostOnlyChecked,
      highGrade,
      lowGrade,
    }) => {
      const postOnlySelected: boolean =
        postOnlySelections.includes(toString(schoolId)) || isPostOnlyChecked;
      const deliveryMode = postOnlySelected
        ? DeliveryModes.PostOnly
        : DeliveryModes.PostAndDistribute;

      return {
        audienceType,
        districtId,
        districtName,
        schoolId,
        schoolName,
        hierarchyIsPostOnly: isPostOnly,
        hierarchyIsOptionalPostOnly: isOptionalPostOnly,
        deliveryMode,
        highGrade,
        lowGrade,
      };
    }
  );
};

export default audienceFactory;
