import gql from 'graphql-tag';

export default {
  getMyCampaigns: gql`
    fragment LearnMore_getMyCampaigns on Campaign {
      primaryCallToAction {
        id
        type
        data
      }
    }
  `,
};
