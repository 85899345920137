import gql from 'graphql-tag';

const UPLOAD_FLYER_MUTATION = gql`
  mutation uploadFlyer($pdfUrl: String!) {
    uploadFlyer(pdfUrl: $pdfUrl) {
      flyer {
        id
        pdfUrl
        flyerPages {
          id
          jpegUrl
          ocrText
          userText
        }
        createdBy
        createdAt
      }
      validationErrors {
        errorType
        errorMessages
      }
      validationWarnings {
        warningType
        warningMessages
      }
    }
  }
`;

export default UPLOAD_FLYER_MUTATION;
