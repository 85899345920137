import { trim, replace, startCase, lowerCase } from 'lodash'
export type UnitDiscount = {
  code: string,
  type: string,
  value: number,
  valueType: string,
  savingsInCents: number,
  discountedUnit: string,
  conditions: {
    max: number,
    min: number,
  }
};

export const centsToDollars = (cents: number, locale: string = 'en-US', currency: string = 'USD'): string => {
  const dollars = cents / 100;
  return dollars.toLocaleString(locale, { style: 'currency', currency });
};

// calculate total in dollars from array of credits (in cents)
export const calculateAmountDue = (credits: number[], ): string => {
  const totalInCents = credits.reduce((accum, credit) => {
    return accum + credit;
  }, 0);

  return centsToDollars(totalInCents);
};

export const calcCostPerUnit = (price: number, totalUnits: number): string =>
  centsToDollars(price / totalUnits);

export const calcTotalPayment = (
  amountDueInCents: number,
  discounts: UnitDiscount[]
): string => {
  const totalDiscountInCents = discounts.reduce((accum, { savingsInCents }) => {
    return accum + savingsInCents;
  }, 0);

  return centsToDollars(amountDueInCents - totalDiscountInCents);
};
