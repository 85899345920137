import React from 'react';
import PJTextInput, {
  InputTypes,
} from '@peachjar/ui/dist/lib/components/Inputs/TextInput';

type Props = {
  label: string;
  name: string;
  value: string;
  type?: InputTypes;
  error: boolean | string | null;
  dataTestId: string;
  onChange: () => void;
  onKeyUp?: () => void;
  onBlur?: () => void;
};

const noop = () => {};

const TextInput: React.FunctionComponent<Props> = ({
  label,
  name,
  value,
  type = 'text',
  error,
  onChange,
  onBlur = noop,
  onKeyUp = noop,
  dataTestId,
  ...rest
}) => (
  <PJTextInput
    label={label}
    name={name}
    value={value}
    type={type}
    error={error}
    onChange={onChange}
    onBlur={onBlur}
    onKeyUp={onKeyUp}
    data-testid={dataTestId}
    {...rest}
  />
);

export default TextInput;
