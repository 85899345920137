import generateSubappConfig from '../_app/generateSubappConfig';
import Help from './Help';
import HelpContainer from './Help.container';
import { APP_ROUTES } from '../_app/constants';
import { helpAllowedRoles } from '../_app/RolesEnum';

const helpSubappConfig = generateSubappConfig({
  path: APP_ROUTES.help,
  allowedRoles: helpAllowedRoles,
  isLive: true,
  navItemConfig: {
    label: 'Help',
    sideNavEnabled: false,
  },
  appConfig: {
    Component: HelpContainer,
    fragmentOnUser: Help.fragments.sodUser,
  },
});

export default helpSubappConfig;
