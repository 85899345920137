import React, { useState, useContext, useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import idx from 'idx';
import { css } from 'react-emotion';
import { elements } from '@peachjar/components';
import SummaryDetails from './SummaryDetails';
import FlyerPurchaseSummaryContainer from '../../../../_app/components/FlyerPurchaseSummaryContainer';
import JpegFlyerPreview from './JpegFlyerPreview';
import SubmitCampaignButton from './SaveAndSubmitCampaignButton';
import routePushHandler from '../../../shared/routePushHandler';
import { gradeFilterDefault } from '../../../shared/constants';
import { useRolesContext } from '../../../../_app/components/RoleCheck';
import MessageToApprover from './MessageToApprover';
import SaveCampaignSummaryButton from './SaveCampaignSummaryButton';
import getFeatureFlags from '../../../../_app/flagging/flags';
import DealsContext from '../../../../_app/context/DealsContext';
import SummaryFragments from './gql/SummaryFragments.graphql';
import {
  setTraySchoolListContext,
  setTrayDistributionContext,
} from '../../../../helpers/deals';
import purgeCTAMemory from '../../../utils/manageCTAMemory';

const { CircleList, typography } = elements;
const { Headline2, Paragraph, Label } = typography;

type State = {
  formValidation: {
    messageToApprover: boolean | null;
  };
  isFormValid: boolean;
};

type Props = {
  sodUser: {
    [key: string]: any;
  };
  flyerTitle: string;
  history: Object;
  match: any;
  campaignId: string;
  legacyFlyerId: number;
  remoteFlyerPages: any;
  postOnlySelections: string[];
  communityFreeConfig: {
    campaignType: string;
    applicationId: string;
    isCommunityFree: boolean;
  };
  messageToApprover: string;
  numberOfDistributions: number;
  startDate: any;
  endDate: any;
  setSummary: () => void;
  handleCampaignSummaryFormValidation: () => void;
};

const Summary = ({
  loadingDetails,
  loadingDelivery,
  loadingSummary,
  sodUser,
  flyerTitle,
  history,
  match,
  flags,
  campaign,
  legacyFlyerId,
  campaignId: remoteCampaignId,
  messageToApprover,
  remoteFlyerPages,
  postOnlySelections,
  setSummary,
  numberOfDistributions,
  startDate,
  endDate,
  checkboxSelections,
  handleCampaignSummaryFormValidation,
  campaignStartDate,
  postExpirationDate,
  communityFreeConfig,
  ...rest
}) => {
  const [formState, setFormState] = useState({
    formValidation: {
      messageToApprover: null,
    },
    isFormValid: true,
  });
  const [submitting, setSubmitting] = useState(false);

  const { uploaderLevel } = useRolesContext();

  const {
    numberOfDistributions: numberOfDistributionsContext,
    updateSchoolDistributionList,
    setNumberOfDistributions,
    setCampaignDetails,
  } = useContext(DealsContext);

  const campaignIdMatch = get(match, 'params.id', null);

  const handleIsValid = (name: string, isValid: boolean) => {
    const { formValidation } = formState;

    if (formValidation[name] !== isValid) {
      const updatedFormValidation = {
        ...formState.formValidation,
        [name]: isValid,
      };

      handleCampaignSummaryFormValidation({
        variables: {
          formValidation: {
            __typename: 'CampaignSummaryFormValidation',
            isFormValid: isFormValid(updatedFormValidation),
          },
        },
      });

      setFormState({
        formValidation: {
          ...formState.formValidation,
          [name]: isValid,
        },
        isFormValid: isFormValid(updatedFormValidation),
      });
    }
  };

  const isFormValid = (formValidation: Object) => {
    const { messageToApprover } = formValidation;
    return messageToApprover === true;
  };

  const { org_uploader } = flags;
  const isOrgUploaderEnabled = org_uploader === 1;
  const isOrg = uploaderLevel === 'org';
  const isDistrictOrSchool =
    uploaderLevel === 'school' || uploaderLevel === 'district';
  const campaignId = remoteCampaignId;
  const userId = idx(sodUser, _ => _.userId) || null;

  useEffect(() => {
    if (isOrg && isOrgUploaderEnabled) {
      setTraySchoolListContext(
        checkboxSelections,
        {
          updateSchoolDistributionList,
        },
        postOnlySelections
      );
      setTrayDistributionContext(numberOfDistributions, {
        numberOfDistributions: numberOfDistributionsContext,
        setNumberOfDistributions,
      });
    }
  }, [checkboxSelections]);

  useEffect(() => {
    if (isOrg && isOrgUploaderEnabled) {
      setCampaignDetails({
        variables: {
          campaignId,
          campaignSummary: { messageToApprover },
        },
        onError: e => console.log(e),
        // refetchQueries: () => ['getMyCampaigns'],
      });
    }
  }, [messageToApprover, checkboxSelections]);

  const headlineText = communityFreeConfig.isCommunityFree
    ? 'Your Community Free flyer application is ready to be submitted!'
    : 'Your flyer is ready to be submitted!';

  return (
    <>
      <Headline2>{headlineText}</Headline2>
      <div className="mt-2">
        <Paragraph>
          Once you submit your flyer, you will no longer be able to edit it.
          Please carefully review the details to ensure accuracy.
        </Paragraph>
      </div>
      <div className="mt-5">
        <CircleList>
          <li>
            <Headline2
              className={css`
                line-height: 1;
              `}
            >
              Summary
            </Headline2>

            <div className="layout-row">
              <div className="flex-50">
                <div className="mt-4">
                  <Label bold>{flyerTitle}</Label>
                </div>

                <SummaryDetails
                  campaignId={campaignIdMatch}
                  uploaderLevel={uploaderLevel}
                  isDistrictOrSchool={isDistrictOrSchool}
                  isOrg={isOrg}
                  checkboxSelections={checkboxSelections}
                  campaignStartDate={campaignStartDate}
                  postExpirationDate={postExpirationDate}
                  minGradeLevel={get(
                    rest,
                    'minGradeLevel',
                    gradeFilterDefault.min
                  )}
                  maxGradeLevel={get(
                    rest,
                    'maxGradeLevel',
                    gradeFilterDefault.max
                  )}
                  {...rest}
                />
              </div>
              <div className="flex-50 layout-row layout-align-center-start">
                <JpegFlyerPreview flyerPages={remoteFlyerPages} />
              </div>
            </div>
          </li>
          {isOrg && (
            <li>
              <div className="layout-row">
                <MessageToApprover
                  name="messageToApprover"
                  messageToApprover={messageToApprover}
                  setSummary={setSummary}
                  handleIsValid={handleIsValid}
                  className="flex-50"
                />
              </div>
            </li>
          )}
          {isOrg && isOrgUploaderEnabled && (
            <li>
              <Headline2
                className={css`
                  line-height: 1;
                `}
              >
                Payment
              </Headline2>
              <Paragraph>
                Your available credit balance will be applied first. Any
                additional credits needed will be added with the best bulk
                credit discount as a part of your transaction.
              </Paragraph>
              <div className="layout-row">
                <FlyerPurchaseSummaryContainer
                  userId={userId}
                  campaignDetails={{
                    isCampaignFormValid: formState.isFormValid,
                    messageToApprover,
                    isFormValid: formState.isFormValid,
                    history,
                    campaignIdMatch,
                    variables: {
                      campaignId,
                      legacyFlyerId,
                      campaignSummary: { messageToApprover },
                    },
                  }}
                  communityFreeConfig={communityFreeConfig}
                  cleanUpAfterSuccess={() => purgeCTAMemory(campaignIdMatch)}
                />
              </div>
            </li>
          )}
        </CircleList>
      </div>
      <div className="layout-row layout-align-space-between mt-5">
        <SaveCampaignSummaryButton
          campaignId={campaignId}
          messageToApprover={messageToApprover}
          handleSuccess={() =>
            history.push(`/campaigns/create-campaign/${campaignId}/delivery`)
          }
          checkboxSelections={checkboxSelections}
        >
          Edit Flyer
        </SaveCampaignSummaryButton>

        {!isOrg && (
          <span onClick={() => setSubmitting(true)}>
            <SubmitCampaignButton
              campaignId={campaignId}
              messageToApprover={messageToApprover}
              handleError={() =>
                alert('There was an error saving the campaign.')
              }
              disabled={!formState.isFormValid || submitting}
              history={history}
              handleSuccess={() => {
                purgeCTAMemory(campaignIdMatch);
                setSubmitting(false);
              }}
            >
              Submit
            </SubmitCampaignButton>
          </span>
        )}
      </div>
    </>
  );
};

Summary.fragments = SummaryFragments;

export default connect(
  state => ({ flags: state.flags, postOnlySelections: state.deals.postOnly }),
  null
)(withRouter(Summary));
