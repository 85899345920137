import generateSubappConfig from '../_app/generateSubappConfig';
import AllApprovals from './AllApprovals';
import ExternalRedirect from '../_app/components/ExternalRedirect';
import { allApprovalsAllowedRoles } from '../_app/RolesEnum';
import { APP_ROUTES } from '../_app/constants';

const getSubappConfig = portalNavFlag =>
  generateSubappConfig({
    path: APP_ROUTES.approvals.history.allApprovals,
    isLive: portalNavFlag === 1,
    allowedRoles: allApprovalsAllowedRoles,
    navItemConfig: {
      label: 'Approval History',
    },
    appConfig: {
      Component: ExternalRedirect,
      fragmentOnUser: AllApprovals.fragments.sodUser,
    },
  });

export default getSubappConfig;
