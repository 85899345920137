import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { css } from 'react-emotion';
import { components, colors, elements } from '@peachjar/components';
import portalBffApolloClient from '../../../_app/apollo/portalBFF.apolloClient.js';
import HappyPeople from './components/HappyPeople.svg';
import SubmittedMessage from './components/SubmittedMessage';
import CommunityFreeSubmittedMessage from './components/CommunityFreeSubmittedMessage';
import FacebookShareButton from '../../../components/FacebookShareButton';
import TwitterShareButton from '../../../components/TwitterShareButton';
import { useRefetchContext } from '../../Campaigns.context';

const { jungle, leaf } = colors;
const { ButtonPrimaryLarge, ButtonSecondarySmall } = components.Buttons;
const { Note, Headline2, Paragraph } = elements.typography;

type Props = {
  history: Object;
  campaignId: String;
  communityFreeConfig: {
    campaignType: string;
    applicationId: string;
    isCommunityFree: boolean;
  };
};

export const Confirmation = ({
  history,
  campaignId,
  communityFreeConfig,
}: Props) => {
  const { refetchUploaderQuery } = useRefetchContext();
  const { loading, data } = useQuery(GET_DATA_FOR_SHARE_URL, {
    variables: { campaignId },
    client: portalBffApolloClient,
    fetchPolicy: 'no-cache',
  });

  const buildShareUrl = data => {
    const shareUrlBase = 'https://share.peachjar.com';
    const { legacyFlyerId, deliverySettings } = data;
    const districtId =
      deliverySettings && deliverySettings.targetAudiences[0].districtId;

    return `${shareUrlBase}/flyers/${legacyFlyerId}/districts/${districtId}`;
  };

  const handleRefetchAndReroute = async () => {
    try {
      await refetchUploaderQuery();
      // @ts-ignore
      history.push('/campaigns');
    } catch (e) {
      console.log('Error fetching UploaderQuery: ', e);
    }
  };

  if (loading) {
    return null;
  }

  const { campaignById } = data;
  const flyerShareUrl = campaignById && buildShareUrl(campaignById);
  const twitterTitle = campaignById && campaignById.title;
  const flyerId = campaignById && campaignById.legacyFlyerId;

  return (
    <>
      {communityFreeConfig.isCommunityFree ? (
        <CommunityFreeSubmittedMessage />
      ) : (
        <SubmittedMessage styles={styles} flyerId={flyerId} history={history} />
      )}

      <div
        className={`layout-row layout-align-center-center ${styles.imageContainer} mt-3`}
      >
        <HappyPeople />
      </div>

      <div>
        <Headline2 className={styles.headline}>Promote Your Flyer</Headline2>
        <Paragraph className={styles.paragraph}>
          Use these tools to spread the word about your new flyer and together
          we can reach more people.
        </Paragraph>

        <div className={`layout-row ${styles.marginTop32}`}>
          <div style={{ marginRight: '1rem' }}>
            <FacebookShareButton shareUrl={flyerShareUrl} />
          </div>
          <TwitterShareButton shareUrl={flyerShareUrl} title={twitterTitle} />
        </div>

        <div className={`layout-row ${styles.marginTop40}`}>
          <div className={styles.copyLinkContainer}>
            <Paragraph className={styles.link}>{flyerShareUrl}</Paragraph>

            <ButtonSecondarySmall
              onClick={() =>
                window.navigator.clipboard.writeText(flyerShareUrl)
              }
            >
              Copy Link
            </ButtonSecondarySmall>
          </div>
        </div>
      </div>

      <div className="layout-row layout-align-end mt-5">
        <ButtonPrimaryLarge
          data-testid="doneBtn"
          onClick={handleRefetchAndReroute}
        >
          Done
        </ButtonPrimaryLarge>
      </div>
    </>
  );
};

const styles = {
  imageContainer: css`
    padding-top: 2.5rem;
    padding-bottom: 3rem;
  `,
  headline: css`
    margin-bottom: 0.5rem;
  `,
  paragraph: css`
    display: block;
  `,
  link: css`
    color: ${jungle};
  `,
  copyLinkContainer: css`
    background: rgb(245, 245, 245);
    border-radius: 3px;
    padding: 1rem 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  marginTop32: css`
    margin-top: 32px;
  `,
  marginTop40: css`
    margin-top: 40px;
  `,
};

const GET_DATA_FOR_SHARE_URL = gql`
  query($campaignId: String!) {
    campaignById(campaignId: $campaignId) {
      legacyFlyerId
      title
      deliverySettings {
        targetAudiences
      }
    }
  }
`;

export default Confirmation;
