import React from 'react';
import { css } from 'emotion';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import FBicon from '../assets/facebook-icon.svg';
import chevronRight from '../assets/icon-chevron-right.svg';

interface Props {
  shareUrl: String,
  classes: Object,
}

const FacebookShareButton = ({ shareUrl, classes }: Props) => (
  <Button href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`} className={classes.button} target="_blank">
    <img className={cn.fbIcon} src={FBicon} height="24" width="24" />
    Facebook
    <img className={cn.arrow} src={chevronRight} alt="" height="8" width="8"/>
  </Button>
);

const styles = () => ({
  button: {
    backgroundColor: '#3A5998',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '100',
    textTransform: 'capitalize',
    padding: '6px 13px',
    '&:hover': {
      backgroundColor: '#3A5998',
      cursor: 'pointer',
      color: '#fff',
    }
  },
  fbIcon: {
    padding: '0 8px 0 0',
  }
});

const cn = {
  fbIcon: css`
    margin: -10px 1px 0 0;
  `,
  arrow: css`
    margin: 0 0 0 14px;
  `,
}

export default withStyles(styles)(FacebookShareButton);
