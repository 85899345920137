import React from 'react';
import { css } from 'react-emotion';
import { components, colors } from '@peachjar/components';

const { ButtonFlatLarge } = components.Buttons;
const { Modal } = components;

const styles = {
  body: css`
    padding-top: 1rem;
  `,
  footer: css`
    padding-top: 0.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;

    && {
      margin: 0px;
    }
  `,
};

const HEADER = 'Uh-oh!';
const MESSAGE =
  'Your changes cannot be saved because this draft is no longer editable. We will redirect you to your Distribution Overview.';

const SaveFailedModal = ({ onOkay }) => (
  <Modal width="sm">
    <React.Fragment>
      <Modal.Header color={colors.rose}>{HEADER}</Modal.Header>
      <Modal.Body>
        <div className={styles.body}>{MESSAGE}</div>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <ButtonFlatLarge data-testid="saveFailedModal" onClick={onOkay}>
          Okay
        </ButtonFlatLarge>
      </Modal.Footer>
    </React.Fragment>
  </Modal>
);

export default SaveFailedModal;
