import React, { useState, useEffect as ue } from 'react';
import { TextInput, colors, elements } from '@peachjar/components';
import FormHelperText from '@material-ui/core/FormHelperText';
import { css } from 'react-emotion';
import FlyerTitleFragments from './gql/FlyerTitleFragments.graphql';
import {
  MAX_FLYER_TITLE_LENGTH,
  MISSED_FIELD_ERROR_MESSAGE,
  FLYER_TITLE_MAX_LENGTH_ERROR_MESSAGE,
} from '../../../../../shared/constants';

const { dragon } = colors;
const { Note } = elements.typography;

export const useEffectFactory = ({
  title,
  setOnBlurError,
  setMaxLengthError,
  setErrorText,
  shouldPerformDisabledClickValidation,
  performValidation,
}) => {
  const useEffectCallback = () => {
    performValidation({
      target: {
        value: {
          flyerTitle: title,
        },
      },
    });

    setErrorText(
      title.length > MAX_FLYER_TITLE_LENGTH
        ? FLYER_TITLE_MAX_LENGTH_ERROR_MESSAGE
        : MISSED_FIELD_ERROR_MESSAGE
    );
    setMaxLengthError(title.length > MAX_FLYER_TITLE_LENGTH);

    if (title.length > 0) setOnBlurError(false);
    if (shouldPerformDisabledClickValidation) {
      setMaxLengthError(title.length > MAX_FLYER_TITLE_LENGTH);
      setOnBlurError(title.length === 0);
    }
  };
  return useEffectCallback;
};

const FlyerTitle = React.memo(
  ({
    flyerTitle,
    remoteFlyerTitle,
    setDetails,
    performValidation,
    shouldPerformDisabledClickValidation,
    useEffect = ue,
  }) => {
    // Note: Will remove remoteFlyerTitle after beta when we move away from refetchQueries
    const [title, setTitle] = useState(
      (remoteFlyerTitle !== 'Untitled' && remoteFlyerTitle) || flyerTitle || ''
    );
    const [onBlurError, setOnBlurError] = useState(false);
    const [maxLengthError, setMaxLengthError] = useState(false);
    const [errorText, setErrorText] = useState(MISSED_FIELD_ERROR_MESSAGE);
    const [hasTitleBeenRestored, setHasTitleBeenRestored] = useState(false);

    useEffect(
      useEffectFactory({
        title,
        setOnBlurError,
        setMaxLengthError,
        setErrorText,
        shouldPerformDisabledClickValidation,
        performValidation,
      })
    );

    useEffect(() => {
      if (title === '' && flyerTitle && !hasTitleBeenRestored) {
        setTitle(flyerTitle);
        setHasTitleBeenRestored(true);
      }
    });

    return (
      <>
        <TextInput
          fullWidth
          multiline
          label="Flyer Title"
          name="flyerTitle"
          onChange={event => setTitle(event.target.value)}
          value={title}
          error={onBlurError || maxLengthError}
          onBlur={async () => {
            await setDetails({
              variables: { name: 'flyerTitle', value: title },
            });
            setOnBlurError(title.length === 0);
          }}
          data-testid="flyerTitleInput"
        />
        <FormHelperText style={{ textAlign: 'right' }}>
          <div
            className={`layout-row layout-align-${
              onBlurError || maxLengthError ? 'space-between' : 'end'
            }`}
          >
            {(onBlurError || maxLengthError) && (
              <Note
                className={css`
                   {
                    color: ${dragon};
                  }
                `}
              >
                {errorText}
              </Note>
            )}
            <div>{`${
              title ? title.length : 0
            } / ${MAX_FLYER_TITLE_LENGTH}`}</div>
          </div>{' '}
        </FormHelperText>
      </>
    );
  }
);

FlyerTitle.fragments = FlyerTitleFragments;

export default FlyerTitle;
