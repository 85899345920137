import PendingApprovals from './PendingApprovals';
import generateSubappConfig from '../_app/generateSubappConfig';
import PendingApprovalsSidebarNavItemBadge from './PendingApprovalsSidebarNavItemBadge';
import ExternalRedirect from '../_app/components/ExternalRedirect';
import { pendingApprovalsAllowedRoles } from '../_app/RolesEnum';
import { APP_ROUTES } from '../_app/constants';

const getSubappConfig = portalNavFlag =>
  generateSubappConfig({
    path: APP_ROUTES.approvals.pending,
    isLive: portalNavFlag === 1,
    allowedRoles: pendingApprovalsAllowedRoles,
    navItemConfig: {
      label: 'Pending Approvals',
      BadgeComponent: PendingApprovalsSidebarNavItemBadge,
    },
    appConfig: {
      Component: ExternalRedirect,
      fragmentOnUser: PendingApprovals.fragments.sodUser,
    },
  });

export default getSubappConfig;
