import React, { useState, useEffect } from 'react';
import { css } from 'react-emotion';
import get from 'lodash/get';
import Dashed from '@peachjar/ui/dist/lib/components/Dashed';
import { elements } from '@peachjar/components';
import AudienceType from '@peachjar/school-finder-api/dist/api/AudienceType';
import { differenceInCalendarDays } from 'date-fns';
import bffClient from '../../../../_app/apollo/portalBFF.apolloClient';
import {
  shortDateFormatter,
  splitCapsWords,
  dehyphenateAndCapitalize,
  convertGradeToAge,
  convertGradeToNumber,
} from '../../../shared/utils';
import MiniCTA from './MiniCTA';
import SchoolList from './SchoolList';
import utcDateToMidnightLocal from '../../../shared/components/utcDateToMidnightLocal';
import { formatParticipationFee } from '../../Details/components/ProvideDetails/ParticipationFee/ParticipationFee';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { GET_CAMPAIGN_CALL_TO_ACTIONS } from '../gql/Summary.graphql';
import {
  ctaPrimaryKey,
  ctaSecondaryKey,
} from '../../Details/components/CallsToAction/CallsToAction';

const { Paragraph } = elements.typography;
const DAYS_PER_MONTH = 30;

const styles = {
  detailRow: css`
    padding: 0.5rem 0;
  `,
};

export const getPostDuration = (campaignStart, postExpiration) => {
  // Ensure dates are midnight local time
  const localCampaignStart = utcDateToMidnightLocal(campaignStart);
  const localPostExpiration = utcDateToMidnightLocal(postExpiration);

  const difference = differenceInCalendarDays(
    localPostExpiration,
    localCampaignStart
  );

  // Add 1 to difference to include today as a day. Ex: Jan 1 to Jan 30 is 30 days
  return `${difference + 1} days`;
};

export const getDistribution = (start, end) => {
  const localCampaignStart = utcDateToMidnightLocal(start);
  const localPostExpiration = utcDateToMidnightLocal(end);

  const difference = differenceInCalendarDays(
    localPostExpiration,
    localCampaignStart
  );

  // difference < 30 treated is 1 distribution
  return difference < 30
    ? Math.ceil(difference / DAYS_PER_MONTH)
    : Math.ceil((difference + 1) / DAYS_PER_MONTH);
};

const shortUTCDateFormatter = d =>
  shortDateFormatter(utcDateToMidnightLocal(d));

export const SummaryDetails = ({
  campaignId,
  uploaderLevel,
  eventLocation,
  minGradeLevel,
  maxGradeLevel,
  selectedAudience,
  campaignStartDate,
  postExpirationDate,
  checkboxSelections: schools,
  isOrg,
}) => {
  const primaryCTA = localStorage.getItem(`${campaignId}-${ctaPrimaryKey}`);
  const secondaryCTA = localStorage.getItem(`${campaignId}-${ctaSecondaryKey}`);

  const distribution = getDistribution(campaignStartDate, postExpirationDate);

  const isTargettingParents = selectedAudience === 'parents';
  const isDistrictOrSchool =
    uploaderLevel === 'district' || uploaderLevel === 'school';

  const parentsSelected = schools.some(
    selection => selection.audienceType === AudienceType.Parents
  );
  const staffSelected = schools.some(
    selection => selection.audienceType === AudienceType.Staff
  );

  let audience = 'Staff Groups';
  if (parentsSelected && staffSelected) {
    audience = 'Parents and Staff Groups';
  } else if (parentsSelected) {
    audience = 'Parents';
  }

  const useParticipationFeeLabel = true;

  return (
    <>
      <Paragraph
        dataTestId="info-eventLocation"
        className={`layout-row ${styles.detailRow}`}
      >
        <div className="flex-70">Street Address:</div>
        <div className="flex-grow">
          {eventLocation || <Dashed testid="eventLocation" />}
        </div>
      </Paragraph>

      <Paragraph
        dataTestId="info-callsToAction"
        className={`layout-row ${styles.detailRow}`}
      >
        <div className="flex-70">Call-to-Actions:</div>
        <div className="flex-grow">
          {primaryCTA && <MiniCTA>{splitCapsWords(primaryCTA)}</MiniCTA>}
          {secondaryCTA && <MiniCTA>{splitCapsWords(secondaryCTA)}</MiniCTA>}
          {!primaryCTA && !secondaryCTA && <Dashed testid="callsToAction" />}
        </div>
      </Paragraph>

      <Paragraph
        dataTestId="info-audience"
        className={`layout-row ${styles.detailRow}`}
      >
        <div className="flex-70">Audience:</div>
        <div className="flex-grow">{dehyphenateAndCapitalize(audience)}</div>
      </Paragraph>

      <Paragraph
        dataTestId="info-distributions"
        className={`layout-row ${styles.detailRow}`}
      >
        <div className="flex-70">Distributions:</div>
        <div className="flex-grow">
          <Paragraph>{distribution}</Paragraph>
        </div>
      </Paragraph>

      <Paragraph
        dataTestId="info-distributionTargetDate"
        className={`layout-row ${styles.detailRow}`}
      >
        <div className="flex-70">Distribution Target Date:</div>
        <div className="flex-grow">
          <Paragraph>
            {shortUTCDateFormatter(new Date(campaignStartDate))}
          </Paragraph>
        </div>
      </Paragraph>

      <Paragraph
        dataTestId="info-postExpiration"
        className={`layout-row ${styles.detailRow}`}
      >
        <div className="flex-70">Post Expiration Date:</div>
        <div className="flex-grow">
          <Paragraph>
            {postExpirationDate
              ? shortUTCDateFormatter(postExpirationDate)
              : 'No Date'}
          </Paragraph>
        </div>
      </Paragraph>

      <Paragraph
        dataTestId="info-postDuration"
        className={`layout-row ${styles.detailRow}`}
      >
        <div className="flex-70">Post Duration:</div>
        <div className="flex-grow">
          <Paragraph>
            {getPostDuration(campaignStartDate, postExpirationDate)}
          </Paragraph>
        </div>
      </Paragraph>

      <Paragraph
        dataTestId="info-schools"
        className={`layout-row ${styles.detailRow}`}
      >
        <div className="flex-70" data-testid="targetsLabel">
          Schools Selected:
        </div>
        <div className="flex-grow">
          <SchoolList
            isTargettingParents={isTargettingParents}
            schools={schools}
          />
        </div>
      </Paragraph>
    </>
  );
};

export default SummaryDetails;
