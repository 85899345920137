import generateSubappConfig from '../_app/generateSubappConfig';
import { APP_ROUTES } from '../_app/constants';
import RedirectTransactionHistory from './RedirectTransactionHistory';

const transactionHistorySubappConfig = generateSubappConfig({
  path: APP_ROUTES.account_transactions,
  isLive: true,
  navItemConfig: {
    label: 'Transaction History',
    sideNavEnabled: false,
  },
  appConfig: {
    Component: RedirectTransactionHistory,
    fragmentOnUser: null,
  },
});

export default transactionHistorySubappConfig;
