import React, { useState, useContext, FormEvent } from 'react';
import idx from 'idx';
import { withRouter } from 'react-router-dom';
import { css } from 'emotion';
import { components, colors } from '@peachjar/components';
import { PurchaseModalConfig as config } from './PurchaseModal.config';
import { APP_ROUTES } from '../constants';
import envConfig from '../../config';
import DealsContext from '../context/DealsContext';

const { REACT_APP_DEALS_URL } = envConfig;

type Props = {
  variant?: string,
  width?: string,
  credits?: string | null,
  closeModal: () => void,
  history: {
    [key: string]: any
  },
  onReset: () => void,
  applyDeal: () => void,
};

type ValidateInputProps = {
  e: FormEvent<HTMLInputElement>,
  validator: () => void,
  setState: () => void,
};

const { 
  Modal,
  TextInput,
  Buttons: { 
    ButtonFlatLarge 
  }
} = components;

const {
  Header,
  Body,
  Footer
} = Modal;

const PurchaseModal: React.FunctionComponent<Props> = ({
  variant = 'addCredits',
  width = 'sm',
  credits = null,
  onReset = () => {},
  applyDeal = () => {},
  closeModal,
  history,
}) => {
  const [purchase, setPurchase] = useState({
    credits,
    error: false,
  });
  const { credits: storeCredits, setCredits } = useContext(DealsContext);
  const { credits: purchaseCredits } = purchase;
  const _variant = config[variant];

  const submitSelection = (): void => {
    if (!!purchaseCredits) {
      window.location.href = `${REACT_APP_DEALS_URL}?credits=${purchaseCredits}`
    }
  };

  const validateInput = ({
    e,
    validator,
    setState,
  }: ValidateInputProps): void => {
    const {
      target: { value },
    } = e;
    const isNumber: boolean = validator(value);

    setState({ credits: value, error: !isNumber });
  };

  return (
    <Modal width={width} close={closeModal}>
      <Header>{_variant.header}</Header>
      <Body>
        <div className={cn.modalBody}>
          <p className={cn.modalBodyMain}>{_variant.body}</p>
          <p className={cn.modalDisclaimer}>{_variant.disclaimer}</p>
        </div>
        <div className={cn.modalBodyForm}>
          <TextInput
            autoFocus
            fullWidth
            type="text"
            placeholder="0"
            label={_variant.label}
            error={purchase.error}
            onChange={e =>
              validateInput({
                e,
                validator: config.validate,
                setState: setPurchase,
              })
            }
            value={purchaseCredits ? purchaseCredits : ''}
            data-testid={`field-${variant}-input`}
          />
          <div
            role="alert"
            className={`${cn.errorAlert} ${purchase.error ? 'show' : ''}`}
          >
            {_variant.error}
          </div>
        </div>
      </Body>
      <Footer>
        <ButtonFlatLarge
          onClick={closeModal}
          data-testid={`click-cancel-modal-${variant}`}
        >
          Cancel
        </ButtonFlatLarge>
        <ButtonFlatLarge
          disabled={purchase.error || !purchaseCredits}
          data-testid={`click-save-modal-${variant}`}
          onClick={submitSelection}
        >
          {_variant.submitCTA}
        </ButtonFlatLarge>
      </Footer>
    </Modal>
  );
};

const cn = {
  modalBody: css`
    padding: 10px 0 0 0;
  `,
  modalBodyMain: css`
    font-size: 16px;
    margin-bottom: 0;
    color: ${colors.prussian};
  `,
  modalBodyForm: css`
    width: 384px;
    margin: 0 auto;
  `,
  modalDisclaimer: css`
    padding: 0;
    font-weight: 600;
  `,
  errorAlert: css`
    color: ${colors.dragon};
    font-size: 14px;
    display: none;

    &.show {
      display: block;
    }
  `,
};

export default withRouter(PurchaseModal);
