import React from 'react';
import { css } from 'emotion';
import idx from 'idx';
import { colors } from '@peachjar/components';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FlyerOrderSummaryDetails from './FlyerOrderSummaryDetails';
import FlyerOrderSummaryPreview from './FlyerOrderSummaryPreview';

type School = {
  schoolId: string | number;
};

type Quote = {
  accountBalance: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  additionalDiscounts: Array<any>;
  audiences: [{}];
  creditBasePrice: any;
  distBasePrice: any;
  distributionDiscounts: Array<any>;
  firstPurchase: boolean;
  lineItems: [{}];
  msrpSubtotal: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  purchaseDetails: {
    endDate: any;
    payment: any;
    quoteId: any;
    audiences: Array;
    startDate: any;
  };
  startingAccountBalance: number;
  subtotalAfterDiscounts: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  subtotalMinusAccountBalance: {
    isMsrp: boolean;
    priceInCents: number;
    numberOfCredits: number;
  };
  total: {
    priceInCents: number;
    discountPercent: number;
    numberOfCredits: number;
    msrpPriceInCents: number;
  };
  unfinishedDiscounts: Array<any>;
  unfinishedDistroLabels: Array<any>;
};

type Props = {
  quote: Quote;
  classes: {
    [key: string]: any;
  };
  panelExpanded: boolean;
  isHovering: boolean;
  postOnlySelections: string[];
  setIsHovering: (val: boolean) => void;
  onPanelExpand: (val: boolean) => void;
  handleSchoolRemoval: (school: School) => void;
};

const FlyerOrderSummary: React.FunctionComponent<Props> = ({
  quote,
  panelExpanded,
  numberOfDistributions,
  postOnlySelections,
  onPanelExpand,
  isHovering,
  setIsHovering,
  handleSchoolRemoval,
  classes,
  ...rest
}) => {
  const purchaseQuote = idx(quote, _ => _.getFlyerPurchaseQuote.quote) || null;

  return (
    <>
      <div className={cn.orderSummary} id="deals-order-summary">
        <Paper className={`${classes.root} ${classes.elevation2}`}>
          <ExpansionPanel
            expanded={panelExpanded}
            className={`${isHovering ? 'hovering' : ''} ${
              panelExpanded ? 'expanded' : ''
            }`}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <ExpansionPanelSummary
              onClick={() => onPanelExpand(!panelExpanded)}
            >
              {/* Broke out expansion content to re-use in transaction history view credit usage */}
              <FlyerOrderSummaryPreview
                quote={purchaseQuote}
                panelExpanded={panelExpanded}
                numberOfDistributions={numberOfDistributions}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <FlyerOrderSummaryDetails
                quote={purchaseQuote}
                handleSchoolRemoval={handleSchoolRemoval}
                panelExpanded={panelExpanded}
                onPanelExpand={onPanelExpand}
                postOnlySelections={postOnlySelections}
                {...rest}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Paper>
      </div>
    </>
  );
};

const muiStyles = () => ({
  root: {
    width: '100%',
    marginTop: '12px 0',
    overflowX: 'auto',
  },
  elevation2: {
    boxShadow: 'none',
  },
});

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&.hovering': {
      backgroundColor: colors.platinum,
    },
    '&.hovering.expanded': {
      backgroundColor: 'transparent',
    },
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 36,
    '&$expanded': {
      minHeight: 36,
    },
    padding: '0',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: '0',
    marginBottom: '0',
    display: 'block',
  },
})(MuiExpansionPanelDetails);

const cn = {
  orderSummary: css`
    width: 100%;
    padding: 0 130px;
  `,
};

export default withStyles(muiStyles)(FlyerOrderSummary);
