// @ts-ignore
import { get } from 'lodash';

import portalBffApolloClient from '../_app/apollo/portalBFF.apolloClient';
import FileTransferService from "./FileTransferService";
import GqlFileTransferService from "./services/GqlFileTransferService";

export type Dependencies = {
  fileTransferService: FileTransferService,
};

export default function getDependencies(): Dependencies {
  return {
    fileTransferService: new GqlFileTransferService(portalBffApolloClient),
  };
}
