import React, { useEffect as ue, useState } from 'react';
import { get } from 'lodash';
import { elements, colors } from '@peachjar/components';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import withValidation from '../../../../../../../../shared/components/withValidation';
import DistrictGuidelineLinks from './components/DistrictGuidelineLinks';

const { leaf, slate } = colors;
const { Paragraph } = elements.typography;

export const unmountUseEffectFactory = ({ handleIsValid }) => {
  const useEffectCallback = () => () =>
    handleIsValid('districtGuidelines', true);
  return useEffectCallback;
};

export const updateUseEffectFactory = ({
  shouldPerformDisabledClickValidation,
  setGuidelinesErrorText,
  performValidation,
  areDistrictGuidelinesConfirmed,
  selectionsWithDistrictGuidelines,
}) => {
  const useEffectCallback = () => {
    performValidation({
      areDistrictGuidelinesConfirmed,
      selectionsWithDistrictGuidelines,
    });

    if (shouldPerformDisabledClickValidation) {
      setGuidelinesErrorText(
        selectionsWithDistrictGuidelines.length > 0 &&
          !areDistrictGuidelinesConfirmed
          ? 'You missed this requirement.'
          : ''
      );
    }
  };
  return useEffectCallback;
};

const DistrictGuidelines = ({
  areDistrictGuidelinesConfirmed,
  toggleDistrictGuidelinesConfirmed,
  selectionsWithDistrictGuidelines,
  classes,
  performValidation,
  handleIsValid,
  useEffect = ue,
  shouldPerformDisabledClickValidation,
}) => {
  const [guidelinesErrorText, setGuidelinesErrorText] = useState('');
  // componentDidUpdate and componentDidMount
  useEffect(
    updateUseEffectFactory({
      shouldPerformDisabledClickValidation,
      setGuidelinesErrorText,
      performValidation,
      areDistrictGuidelinesConfirmed,
      selectionsWithDistrictGuidelines,
    })
  );
  // componentDidUnmount. Becomes valid when unmounts.
  useEffect(unmountUseEffectFactory({ handleIsValid }), []);

  const checkboxDisabled = selectionsWithDistrictGuidelines.length < 1;
  const onClickHandler = () => {
    if (!checkboxDisabled) {
      toggleDistrictGuidelinesConfirmed({
        variables: { isConfirmed: !areDistrictGuidelinesConfirmed },
      });
      performValidation({
        target: {
          value: {
            selectionsWithDistrictGuidelines,
            areDistrictGuidelinesConfirmed: !areDistrictGuidelinesConfirmed,
          },
        },
      });
    }
  };

  const uniqueSelectionsWithGuidelines = selectionsWithDistrictGuidelines.reduce(
    (acc, curr) => {
      const haveDistrictGuidelineAlready = acc.some(
        school =>
          get(school, 'district.name') &&
          get(school, 'district.name') === get(curr, 'district.name')
      );

      return !haveDistrictGuidelineAlready ? acc.concat(curr) : acc;
    },
    []
  );

  // assumes selections are schools. Unique set
  return (
    <>
      <ListItem
        className={`p-0 ${classes.listItem}`}
        data-testid="districtGuidelinesConfirmedCheckbox"
        onClick={onClickHandler}
      >
        <Checkbox
          data-testid="districtGuidlinesConfirmed"
          classes={{ root: classes.checkboxRoot, checked: classes.checked }}
          className="p-0"
          onClick={onClickHandler}
          checked={areDistrictGuidelinesConfirmed}
          disabled={checkboxDisabled}
        />
        <ListItemText>
          <Paragraph>
            I have read the flyer approval guidelines for the district(s) listed
            above and confirm that my flyer conforms with them.
          </Paragraph>
        </ListItemText>
      </ListItem>

      <DistrictGuidelineLinks
        className="mt-3"
        uniqueSelectionsWithGuidelines={uniqueSelectionsWithGuidelines}
        guidelinesErrorText={guidelinesErrorText}
      />
    </>
  );
};

const validate = ({
  areDistrictGuidelinesConfirmed,
  selectionsWithDistrictGuidelines,
}) => {
  if (selectionsWithDistrictGuidelines.length > 0) {
    return areDistrictGuidelinesConfirmed;
  }
  return true;
};

const validateOnMount = ({
  areDistrictGuidelinesConfirmed,
  selectionsWithDistrictGuidelines,
}) =>
  validate({
    areDistrictGuidelinesConfirmed,
    selectionsWithDistrictGuidelines,
  });

const styles = () => ({
  checkboxRoot: {
    color: slate,
    '&$checked': {
      color: leaf,
    },
  },
  checked: { color: leaf },
  listItem: {
    marginTop: '42px',
  },
});

export default withStyles(styles)(
  withValidation(DistrictGuidelines, validate, validateOnMount)
);

export { DistrictGuidelines };
