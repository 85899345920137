import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import portalBffApolloClient from '../../../src/_app/apollo/portalBFF.apolloClient';
import { Paper } from '@material-ui/core';
import Table from '../components/Table';
import { css } from 'emotion';
import { elements, colors } from '@peachjar/components';
import searchIcon from '../../../src/assets/Search.svg';
import SubappHeader from '../../_app/components/SubappHeader';
import config from '../../../src/config';
const { typography: { Paragraph } } = elements;
const TRANSACTION_HISTORY_COLUMN_HEADERS = [
  'ID',
  'Date',
  'Type',
  'Details',
  'Dollar Amount',
  'Credit Amount',
  'Credit Balance',
  '',
];

type Transaction = {
  actions: Array<any>;
  amountInCents: number;
  amountInCredits: number;
  creditBalance: number;
  details: string;
  extraInfo: any;
  id: string;
  sequenceId: number;
  timestamp: number;
};

type TransactionHistory = {
  transactions: [Transaction];
};

type Props = {
  transactionHistory: TransactionHistory;
  styles: { [key: string]: any };
};

const { OLD_HISTORY_URL } = config;
const fetchOldHistory = () => {
  window.location.href = OLD_HISTORY_URL;
}

const History = ({
  transactionHistory,
  styles
}: Props) => {

  const { loading, data } = useQuery(LEGACY_TRANSACTION_QUERY, {
    client: portalBffApolloClient,
    fetchPolicy: 'no-cache',
  });


  let transactionArray: [] = [];

  if (loading) {
    return null;
  }

  if (!loading && data) {
    transactionArray = data.legacyTransactionHistory.transactions;
  }

  const isLegacy = transactionArray.length > 0;

  const columnHeaders = TRANSACTION_HISTORY_COLUMN_HEADERS;
  // Ensure data sorted by sequenceId desc
  const tableData = transactionHistory
    ? transactionHistory.transactions.sort(
      (a, b) => b.sequenceId - a.sequenceId
    )
    : [];

  return (
    <>
      <SubappHeader>Transaction History</SubappHeader>
      <Paper elevation={0} className={styles.paperMargin}>
        <div className={styles.subAppContainer}>
          <Table columnHeaders={columnHeaders} tableData={tableData} />
        </div>
      </Paper>
      {isLegacy && (<div className={txClasses.bottomContainer}>
        <div className={txClasses.bottomInner}>
          <img
            src={searchIcon}
            alt="search"
            height="60"
            width="60"
          />
        </div>
      </div>)}
      {isLegacy && (<div className={txClasses.paragraphContainer}>
        <Paragraph>Looking for more? This transaction history view contains your new portal<br /> transactions. To see your old portal transactions <a onClick={fetchOldHistory}><Paragraph className={txClasses.hyperlink}>download old history.</Paragraph></a></Paragraph>
      </div>)}
    </>
  );
};

const txClasses = {
  bottomContainer: css`
     display: flex;
     flex-direction: column;
     align-items: center;
     width: 100%;
     margin-top: 50px;
     `,
  bottomInner: css`
      display:flex;
     `,
  hyperlink: css`
  color: ${colors.jungle};
  cursor: pointer;
    &:hover {
      color: #C5E8C9;
    }
  `,
  paragraphContainer: css`
     display:flex;
     flex-direction: column;
     align-items: center;
     text-align:center;
     width: 100%;
     margin-top: 15px;
     margin-bottom: 40px;
     `
};

const LEGACY_TRANSACTION_QUERY = gql`
query  {
  legacyTransactionHistory {
    transactions {
      userId
    }
  }
}
`;

export default History;