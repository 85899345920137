import gql from 'graphql-tag';

const CREATE_DRAFT_CAMPAIGN_MUTATION = gql`
  mutation CreateDraftCampaign($flyerId: String!, $applicationId: String) {
    createDraftCampaign(flyerId: $flyerId, applicationId: $applicationId) {
      id
      title
      legacyFlyerId
      flyer {
        id
        pdfUrl
        flyerPages {
          id
          jpegUrl
          ocrText
          userText
        }
      }
      isSubmitted
      application {
        id
        status
        schoolSelectionLimit
      }
    }
  }
`;

export default CREATE_DRAFT_CAMPAIGN_MUTATION;
