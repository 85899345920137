import React from 'react';
import { css, cx } from 'emotion';
import DefaultPage from '@peachjar/ui/dist/lib/components/PageLayouts/DefaultPage';
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInput';
import SwitchInput from '@peachjar/ui/dist/lib/components/Inputs/SwitchInput';
import Tooltip from '@peachjar/ui/dist/lib/components/Tooltip';
import colors from '@peachjar/ui/dist/lib/styles/colors';
import {
  ButtonFlatLarge,
  ButtonPrimaryLarge,
} from '@peachjar/ui/dist/lib/components/Buttons/Buttons';
import { Field } from './AccountInformationStaff.container';

type Props = {
  firstName: Field,
  lastName: Field,
  email: string,
  isSubscribed: boolean,
  subscriptionOptions: any[],
  handleCancel: () => void,
  handleUpdate: () => void,
  handleFirstNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleLastNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleSubscriptionChange: (isChecked: boolean) => void,
  scopes: string[],
};

const getErrorText = (value, textLimit) =>
  value.length > textLimit
    ? `Enter ${textLimit} characters or less.`
    : 'You missed this field.';

const AccountInformation: React.FunctionComponent<Props> = ({
  firstName,
  lastName,
  email,
  isSubscribed,
  handleCancel,
  handleUpdate,
  subscriptionOptions,
  handleFirstNameChange,
  handleLastNameChange,
  handleSubscriptionChange,
  scopes,
}) => {
  const isVolunteer = scopes.includes('volunteer');

  return (
    <DefaultPage title="Account Information">
      <h3 className={cx(styles.headline, 'mb-4')}>Personal Information</h3>
      <div className={styles.section}>
        <p className={styles.textDiscrete}>
          First Name{' '}
          <Tooltip
            title="To update your name, contact your district or school administrator."
            placement="right"
          />
        </p>
        <p className={styles.textDiscrete}>{firstName.value}</p>
      </div>
      <div className={styles.section}>
        <p className={styles.textDiscrete}>
          Last Name{' '}
          <Tooltip
            title="To update your name, contact your district or school administrator."
            placement="right"
          />
        </p>
        <p className={styles.textDiscrete}>{lastName.value}</p>
      </div>
      <div className={cx(styles.section, styles.emailSection)}>
        <p className={styles.textDiscrete}>
          Email Address{' '}
          <Tooltip
            title="To update your email address, contact your district or school administrator."
            placement="right"
          />
        </p>
        <p className={styles.textDiscrete}>{email}</p>
      </div>
      {/* {!isVolunteer && ( */}
      <div className={styles.subscriptions}>
        <div className={cx(styles.headline, styles.valignTop)}>
          Follow School
        </div>
        <div className={styles.followSchoolsDescription}>
          Follow the school site(s) that you are associated with as a staff or
          volunteer user to receive email notifications for new flyers posted
          for parents. Contact your district administration to add or remove you
          you from a school site.
        </div>
        <div>
          <SwitchInput
            value={isSubscribed}
            onChange={handleSubscriptionChange}
            label="Associated Schools"
          />
          <Tooltip
            title="Schools you are associated with in your district as a staff user. Contact your district administrator to add or remove a school association."
            placement="right"
          />
          {subscriptionOptions.map((option, index) => (
            <p className={styles.districtNameLabel} key={index}>
              {option.groupName}
            </p>
          ))}
        </div>
      </div>
      {/* )} */}
      <div className={styles.pageFooter}>
        <div className={styles.cta}>
          <ButtonFlatLarge onClick={handleCancel}>Cancel</ButtonFlatLarge>
        </div>
        <div className={styles.cta}>
          <ButtonPrimaryLarge
            onClick={handleUpdate}
            disabled={firstName.error || lastName.error}
          >
            Update
          </ButtonPrimaryLarge>
        </div>
      </div>
    </DefaultPage>
  );
};

const styles = {
  headline: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 24px;
    color: ${colors.prussian};
  `,
  section: css`
    margin-bottom: 20px;

    .MuiTooltip-tooltip {
      color: #fff;
      padding: 8px;
      font-size: 13px;
      max-width: 300px;
      word-wrap: break-word;
      font-family: Proxima Nova, Arial, Helvetica, sans-serif;
      font-weight: 500;
      line-height: 1.4em;
      border-radius: 2px;
      background-color: #2d344b;
    }
  `,
  textDiscrete: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: ${colors.slate};
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  `,
  subscriptions: css`
    margin-top: 56px;
    width: 47%;
    .MuiTooltip-tooltip {
      color: #fff;
      padding: 8px;
      font-size: 13px;
      max-width: 300px;
      word-wrap: break-word;
      font-family: Proxima Nova, Arial, Helvetica, sans-serif;
      font-weight: 500;
      line-height: 1.4em;
      border-radius: 2px;
      background-color: #2d344b;
    }
  `,
  valignTop: css`
    vertical-align: top;
    padding-bottom: 8px;
  `,
  emailSection: css`
    position: relative;
  `,
  pageFooter: css`
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: flex-end;
    width: 47%;
  `,
  cta: css`
    margin-left: 14px;
  `,
  districtNameLabel: css`
    margin: 0px;
    padding-left: 47px;
  `,
  followSchoolsDescription: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.prussian};
    margin-bottom: 24px;
  `,
};

export default AccountInformation;
