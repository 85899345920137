import React from 'react';
import gql from 'graphql-tag';
import { Switch, Route } from 'react-router-dom';
import Approvals from './Approvals';
import PendingApprovals from '../pending_approvals/PendingApprovals';
import MyApprovals from '../my_approvals/MyApprovals';
import AllApprovals from '../all_approvals/AllApprovals';
import { APP_ROUTES } from '../_app/constants';

const ApprovalsIndex = props => {
  return (
    <Switch>
      <Route
        path={APP_ROUTES.approvals.history.myApprovals}
        render={() => <MyApprovals {...props} />}
      />
      <Route
        path={APP_ROUTES.approvals.history.allApprovals}
        render={() => <AllApprovals {...props} />}
      />
      <Route
        path={APP_ROUTES.approvals.pending}
        render={() => <PendingApprovals {...props} />}
      />
      <Route
        path={APP_ROUTES.approvals.index}
        render={() => <Approvals {...props} />}
      />
    </Switch>
  );
};

ApprovalsIndex.fragments = {
  sodUser: gql`
    fragment Approvals_sodUser on SodUser {
      permission {
        adminLevel
        approverLevel
      }
    }
  `,
};

export default ApprovalsIndex;
