import React from 'react';
import { css } from 'emotion';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { colors, fonts } from '@peachjar/components';
const { ProximaNova } = fonts;
interface Props {
  open: boolean;
  onRemove: Function;
  onClose: Function;
  classes: object;
  uploaderLevel: string;
}

//material overrides
const styles = {
  dialog: {
    maxWidth: '790px',
  },
  cta: {
    color: `${colors.jungle}`,
    fontSize: '16px',
    textTransform: 'capitalize',
    padding: '12px 30px',
    '&:hover': {
      color: `${colors.jungle}`,
      backgroundColor: '#fff'
    }
  },
  content: {
    padding: '24px',
  }
}

const RemoveFlyerModal = ({ open, onRemove, onClose, classes, uploaderLevel }: Props): any => {
  const handleClose = (): void => {
    onClose();
  }

  const handleRemove = (): void => {
    onClose();
    onRemove();
  }

  return (
    <Dialog
      className={`remove-flyer-dialog ${cn.removeFlyer}`}
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={cn.title}>Flyer Removal Confirmation</DialogTitle>
      <DialogContent style={{ paddingBottom: 0 }} className={cn.content}>
        <p className={cn.mainText}>
          Removing a flyer will take it down from the web flyerboard(s) and stop it from being sent. However, flyers enter the email queue the day they are scheduled to be sent and may not be stopped if removed on that day. This action will not affect any past email distributions.
        </p>
      </DialogContent>
      {uploaderLevel === 'org' && (<DialogContent style={{ paddingBottom: 0, marginTop: '8px' }} className={cn.content}>
        <p className={cn.noteText}>
          Note, your Peachjar credits will be automatically returned to your account within 24 hours when a Submitted or Scheduled flyer has been removed.        </p>
      </DialogContent>)}
      <DialogActions>
        <Button onClick={handleClose} className={classes.cta}>
          Cancel
        </Button>
        <Button onClick={handleRemove} className={classes.cta} autoFocus>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const cn = {
  content: css`
  margin-top: 16px;
  padding-bottom: 0;  `,
  mainText: css`
  font-family: ${ProximaNova};
  font-size: 16px;
  color: ${colors.prussian};
  line-height: 24px;
  `,
  note: css`
  margin: 0;
  padding: 0;
  `,
  noteText: css`
  font-family: ${ProximaNova};
  font-size: 16px;
  color: #7b7b7b;
  line-height: 24px;
  `,
  removeFlyer: css`
  
  `,
  title: css`
    font-size: 25px;
 color: ${colors.prussian};
    background-color: #FFEBE5;
    text-transform: cas
  `,
}

export default withStyles(styles)(RemoveFlyerModal);
