import gql from 'graphql-tag';

const getMyCommunityFreeInfo = gql`
  query getMyCommunityFreeInfo {
    myCommFreeInfo {
      defaultDistrict {
        id
        name
        url
        location {
          city
          stateShort
          country
        }
        placeId
      }
      available
      enabled
      hasDraft
    }
  }
`;

export default getMyCommunityFreeInfo;
