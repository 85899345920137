import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

type Props = {

};

export default function PayPalPaymentForm({}: Props) {
  return <Grid container direction="column" justify="center" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => alert('Not implemented')}
          >Checkout with PayPal</Button>
      </Grid>;
}
