/* global window */
import React from 'react';
import { Button } from '@material-ui/core';
import { components, elements } from '@peachjar/components';

const { H1, H2 } = elements.headers;
const { WhitespaceColumn, FullSpread } = components;

const Error404Screen = () => (
  <FullSpread>
    <WhitespaceColumn full>
      <H1 h1style>404</H1>
      <H2 h3style>Whoops! Nothing here to see.</H2>
      <Button onClick={() => window.history.back()}>Go back</Button>
    </WhitespaceColumn>
  </FullSpread>
);

export default Error404Screen;
