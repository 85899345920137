import * as React from 'react';
import gql from 'graphql-tag';
import axios from 'axios'
import { connect } from 'react-redux';
import { css } from 'emotion';
import Grid from '@material-ui/core/Grid';
import { colors, elements, components } from '@peachjar/components';
import accountsBFFClient from '../../_app/apollo/accountsBFF.client';
import { LinkedAccount } from '../../_api/Profile';
import config from '../../config';
import { NOTIFICATIONS } from '../../_app/constants';

const { 
  REACT_APP_ACCOUNT_BFF_URL, 
  REACT_APP_PORTAL_APP_URL
} = config;

const client = axios.create({
  baseURL: REACT_APP_ACCOUNT_BFF_URL,
  withCredentials: true,
})

const { Note } = elements.typography;

const {  
  Notifications: { notifyError },
} = components;

type Props = {
  userId: string;
  fullName: string;
  linkedAccounts: LinkedAccount[];
  getUserType: (scopes: string[]) => string;
  handleGlobalError: (key: string) => void;
};

export const LOGIN_STATUS = gql`
  query {
    loginStatus {
      loggedIn
      redirectTo
    }
  }
`

const getLinkedAccountsSorted = (linkedAccounts: LinkedAccount[]): Array<LinkedAccount> => {
  const sortedBy = ["district", "school", "organization", "family", "parent"];
  const sortedList = linkedAccounts.sort((a, b) => {
    return sortedBy.indexOf(a.hierarchy.type) - sortedBy.indexOf(b.hierarchy.type)
  })
  return sortedList;
}

const SwitchAccounts = ({
  userId,
  fullName,
  linkedAccounts,
  getUserType,
  handleGlobalError
}: Props) => {

  const filteredAccounts = linkedAccounts.filter(account => account.userId !== parseInt(userId));
  const sortedLinkedAccounts = getLinkedAccountsSorted(filteredAccounts);

  const handleClickSwitchAccount = async (account: LinkedAccount) => {
    let destination = `${REACT_APP_PORTAL_APP_URL}/dashboard`
    try {
      await client.get(`/auth/sessions/switch-to/${account.userId}`)
      if(['parent', 'family'].includes(account.hierarchy.type)) {        
        const { data } = await accountsBFFClient.query({
          query: LOGIN_STATUS,        
          fetchPolicy: 'no-cache',     
        })

        if (data) {
          const { loginStatus: { redirectTo, loggedIn } } = data
          if (loggedIn) {
            destination = redirectTo
          }
        }
      }
      window.location.href = destination      
    } catch (error) {
      console.log(error);
      handleGlobalError('switchAccountError');
    }
  }

  const getAccountName = (account: LinkedAccount): string => {
    const userType = getUserType(account.scopes)

    if (['Parent', 'Staff', 'Volunteer', 'Organization'].includes(fullName) && fullName !== userType) {
      return userType
    }
    return fullName
  }

  if (sortedLinkedAccounts.length > 0) {
    return (
      <div>
        <div className={classNames.otherAccounts}>
          <Note muted>Other Accounts</Note>
        </div>
        <ul className={classNames.otherAccountsList}>
          {sortedLinkedAccounts.map(account => {
            const accountName = getAccountName(account)
            return (
              <li className={classNames.otherAccountsItem}
                onClick={() => handleClickSwitchAccount(account)}
                key={account.userId}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>{accountName}</Grid>
                    <Grid item xs={12} className={classNames.ellipsis}>
                      <small>{account.email}</small>
                    </Grid>
                    <Grid item xs={12}>
                      {accountName !== account.hierarchy.name &&
                        <Note bold>{account.hierarchy.name}</Note>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </li>
              )
            }
          )}
        </ul>
      </div>
    )
  }
  return null;
};

const classNames = {
  otherAccounts: css`
    padding: 1rem 1rem 0rem;    
  `,
  otherAccountsList: css`
    list-style-type: none;
    padding: 8px;
    margin: 0px;
    border-bottom: 1px solid rgba(0,0,0,.1);
  `,
  otherAccountsItem: css`
    color: ${colors.prussian};
    border-radius: 3px;    
    padding: 10px 15px;
    & + li {
      padding: 17px 15px;
    }
    cursor: pointer;
    line-height: 1.23;

    &:hover {
      text-decoration: none;
      background-color: #ededed;
    }
  `,
  ellipsis: css`
    text-overflow: ellipsis;
    overflow: hidden;
  `
};

const mapDispatchToProps = dispatch => ({
  handleGlobalError: key => {
    dispatch(notifyError(NOTIFICATIONS[key]));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(SwitchAccounts);

