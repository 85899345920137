import gql from 'graphql-tag';
import CampaignSlatContentsFragments from './CampaignSlatContentsFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment CampaignSlat_getMyCampaigns on Campaign {
      ...CampaignSlatContents_getMyCampaigns
    }
    ${CampaignSlatContentsFragments.getMyCampaigns}
  `,
};