import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useQuery as uq } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { css } from 'react-emotion';
import { apolloClient as portalBffClient } from '../_app/apollo/portalBFF.apolloClient';
import History from './History';
import Receipt from './Receipt';
import Details from './Details';
import FullPageSpinner from '../_app/components/FullPageSpinner';

type Props = {
  sodUser: object;
  useQuery: () => any;
};

const Transactions = ({ sodUser, useQuery = uq }: Props) => {
  const { data, loading } = useQuery(GET_TRANSACTION_HISTORY, {
    client: portalBffClient,
    fetchPolicy: 'no-cache',
  });

  let transactionHistory;

  if (!loading && data) {
    transactionHistory = data.transactionHistory;
  }

  if (loading) return <FullPageSpinner />;

  return (
    <Switch>
      <Route exact path="/account/transactions">
        <History transactionHistory={transactionHistory} styles={styles} />
      </Route>
      <Route
        exact
        path="/account/transactions/:purchaseId/receipt"
        render={routerProps => <Receipt styles={styles} {...routerProps} />}
      />
      <Route
        exact
        path="/account/transactions/:purchaseId/details"
        render={routerProps => <Details styles={styles} {...routerProps} />}
      />
      {/* If path incomplete, redirect to transactions. This cleans up URL,
      versus default Switch case to transactions. */}
      <Redirect to="/account/transactions" />
    </Switch>
  );
};

const styles = {
  subAppContainer: css`
    display: flex;
    background-color: white;
    padding: 4rem;
  `,
  subAppContainerReceiptVariant: css`
    background-color: white;
    padding: 4rem 4rem 4rem 4rem;
  `,
  subAppContainerDetailsVariant: css`
    background-color: white;
    padding: 4rem 4rem 4rem 4rem;
  `,
  subAppHeaderWithBreadcrumb: css`
    padding: 1.825rem 0 2.5rem 0;
  `,
  subAppHeaderWrapperWithPrintButton: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  reportButtonWrapper: css`
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
  `,
  paperMargin: css`
    margin-bottom: 2rem;
  `,
};

export const GET_TRANSACTION_HISTORY = gql`
  query transactionHistory {
    transactionHistory {
      transactions {
        id
        sequenceId
        timestamp
        type
        details
        amountInCents
        amountInCredits
        creditBalance
        extraInfo {
          ... on TxDetailsInvoice {
            invoiceId
            status
          }
          ... on TxDetailsBasic {
            nope
          }
        }
        actions {
          type
          details {
            ... on TxActionDetailsPurchase {
              purchaseId
            }
            ... on TxActionDetailsInvoice {
              invoiceId
            }
          }
        }
      }
    }
  }
`;

export default Transactions;
