import React from 'react';
import { css } from 'react-emotion';

const styles = {
  container: css`
    width: 225px;
    height: 225px;
    margin-bottom: 1.5rem;
  `,
};

const UhOhTurtleSvg = props => (
  <div className={styles.container}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 421.03 354.65"
      width="auto"
      height="100%"
      {...props}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.turtle-cls-1{fill:#fff;}.turtle-cls-2{fill:#dfe1e8;}.turtle-cls-3{fill:#f0f2f6;}.turtle-cls-4{fill:#9ea3b7;}.turtle-cls-5{fill:#c6c8d4;}.turtle-cls-11,.turtle-cls-12,.turtle-cls-14,.turtle-cls-6{fill:none;}.turtle-cls-10,.turtle-cls-6{stroke:#48516c;}.turtle-cls-10,.turtle-cls-11,.turtle-cls-12,.turtle-cls-14,.turtle-cls-6,.turtle-cls-7,.turtle-cls-8{stroke-linecap:round;}.turtle-cls-10,.turtle-cls-11,.turtle-cls-14,.turtle-cls-6,.turtle-cls-7,.turtle-cls-8{stroke-linejoin:round;}.turtle-cls-10,.turtle-cls-11,.turtle-cls-14,.turtle-cls-6,.turtle-cls-7,.turtle-cls-8,.turtle-cls-9{stroke-width:3px;}.turtle-cls-7{fill:#f9a91a;}.turtle-cls-11,.turtle-cls-7{stroke:#f9a91a;}.turtle-cls-8,.turtle-cls-9{fill:#ffda80;stroke:#ffda80;}.turtle-cls-12,.turtle-cls-9{stroke-miterlimit:10;}.turtle-cls-10{fill:#48516c;}.turtle-cls-12{stroke:#2e354b;}.turtle-cls-13{fill:#2e354b;}.turtle-cls-14{stroke:#9ea3b7;}'
          }}
        />
      </defs>
      <title>Asset 23</title>
      <g id="Turtle_Layer_2" data-name="Layer 2">
        <g id="Turtle_Layer_2-2" data-name="Layer 2">
          <path
            className="turtle-cls-1"
            d="M323.85,143.25c-41.63-1.41-61.1,82.41-129.4,88.5-30.69,2.74-42.53-12.77-77.85-6.25-33.69,6.22-58.31,26.85-74.24,44h364.5C374.3,167.1,345.16,144,323.85,143.25Z"
          />
          <path
            className="turtle-cls-2"
            d="M146.54,270.23H14.5C6.68,263.51-.79,254.92.87,246.86c2.29-11.12,19.9-11.3,22.59-22.59,3.31-13.89-20.33-26.31-16-36.22,2.57-5.81,13.12-7,19.09-6.62,14.55,1,22,12.79,24.93,10.9,5.68-3.7-26.26-47.09-16.36-58.42,10.1-11.57,63.78,10.36,64.65,31.94.3,7.48-5.86,11.9-3.5,15.58,5.86,9.14,46.87-13.56,56.86-1.56,5.74,6.9-3.2,19.93,4.67,27.26,3.51,3.27,6.24,1.57,14.42,5.46,4.84,2.3,13.56,7.5,15.58,12.46C191.69,234.56,175,253.33,146.54,270.23Z"
          />
          <path
            className="turtle-cls-1"
            d="M109.29,172.57s-61,35.31-44,67.44c5.11,9.63,14.37,21,25.21,29.85h37.67c10.91-8.69,20.27-19.58,25.38-29.05C170.92,208.74,109.29,172.57,109.29,172.57Z"
          />
          <path
            className="turtle-cls-3"
            d="M109.29,172.57s.2,87.51.2,97.29h18.72c10.91-8.69,20.27-19.58,25.38-29.05C170.92,208.74,109.29,172.57,109.29,172.57Z"
          />
          <path
            className="turtle-cls-2"
            d="M196.57,267.18h15.7a1.17,1.17,0,0,1,1.17,1.17v1.18a0,0,0,0,1,0,0h-18a0,0,0,0,1,0,0v-1.18A1.17,1.17,0,0,1,196.57,267.18Z"
          />
          <path
            className="turtle-cls-2"
            d="M216.95,267.18h24.22a1.44,1.44,0,0,1,1.44,1.44v.92a0,0,0,0,1,0,0h-27.1a0,0,0,0,1,0,0v-.92A1.44,1.44,0,0,1,216.95,267.18Z"
          />
          <path
            className="turtle-cls-3"
            d="M202,267.18a10.9,10.9,0,0,1-2.73-2.25,25.73,25.73,0,0,1,3.31.85s-2.65-5.41-2.45-5.46,3.65,4.78,3.65,4.78-.57-6.92-.13-6.92S205,264.9,205,264.9s2.53-5.75,2.82-5.64-1.66,5.85-1.66,5.85,4.56-3.58,4.72-3.37-4,4.13-4,4.13a22.82,22.82,0,0,1,3.45-.62,27.69,27.69,0,0,1-3.33,1.93Z"
          />
          <path
            className="turtle-cls-3"
            d="M226.76,267.18a10.72,10.72,0,0,1-2.73-2.25,25.73,25.73,0,0,1,3.31.85s-2.66-5.41-2.45-5.46,3.64,4.78,3.64,4.78-.56-6.92-.13-6.92,1.33,6.72,1.33,6.72,2.53-5.75,2.82-5.64-1.67,5.85-1.67,5.85,4.56-3.58,4.72-3.37-4,4.13-4,4.13a23,23,0,0,1,3.46-.62,28.61,28.61,0,0,1-3.34,1.93Z"
          />
          <path
            className="turtle-cls-4"
            d="M162.41,269.87s28.85-29.43,28.34-30.21-32.18,30-32.18,30Z"
          />
          <path
            className="turtle-cls-4"
            d="M162.41,269.87s12.29-26,11.49-26-15.33,25.78-15.33,25.78Z"
          />
          <path
            className="turtle-cls-4"
            d="M162.41,269.87s13-33.1,12.25-33.69-16.09,33.43-16.09,33.43Z"
          />
          <path
            className="turtle-cls-5"
            d="M134.93,223s-3.77,7-3.32,7.47,4.9-7.47,4.9-7.47a1.28,1.28,0,0,0-.88-.28A1.25,1.25,0,0,0,134.93,223Z"
          />
          <polygon
            className="turtle-cls-5"
            points="143.74 270.05 134.93 222.97 136.51 222.97 147.35 270.05 143.74 270.05"
          />
          <polygon
            className="turtle-cls-5"
            points="147.35 270.05 146.14 198.32 145.55 198.32 144.54 270.05 147.35 270.05"
          />
          <rect
            className="turtle-cls-5"
            x="143.45"
            y="198.08"
            width="4.99"
            height="30.48"
            rx="2.5"
          />
          <rect
            className="turtle-cls-4"
            x="153.07"
            y="176.92"
            width="4.99"
            height="30.48"
            rx="2.5"
          />
          <path
            className="turtle-cls-4"
            d="M159.31,269.53s1.24-31.77.37-31.77-3.27,31.77-3.27,31.77Z"
          />
          <path
            className="turtle-cls-4"
            d="M160.57,269.53s1.33-22.7.88-22.7-2.88,22.7-2.88,22.7Z"
          />
          <polyline
            className="turtle-cls-4"
            points="157.11 269.53 155.93 178.01 155.2 178.09 154.03 269.53"
          />
          <path
            className="turtle-cls-5"
            d="M149.44,269.53s1.63-42.13.67-42.13-4.29,42.13-4.29,42.13Z"
          />
          <line
            className="turtle-cls-6"
            x1="280.81"
            y1="353.15"
            x2="225.33"
            y2="353.15"
          />
          <path
            className="turtle-cls-3"
            d="M100.15,75.28q-29.81,0-59.59,0c-4.1,0-5.2-1.71-3.57-5.52a9.21,9.21,0,0,1,10.95-5.2c1.7.54,2.86.4,4-1A13.78,13.78,0,0,1,71,61.79c2.23,1.85,5.28.56,6.22-2.27.83-2.51.53-5.19,1.86-7.68C82.79,45,89.27,41.51,97,43c1.69.31,2.23-.14,3-1.6,2.17-4.31,7-6.8,10.91-5.82,4.55,1.16,8.77,3.92,8.29,10.33-.13,1.66.26,2.52,2.14,3a13,13,0,0,1,8.17,6.33c1.51,2.57,4.51,3,6.72,4.55.73.51,1.43-.16,2.1-.58,7.25-4.46,15.37-1.43,18.19,6.18.08.21.25.41.25.61-.08,3.35.89,5.77,4.73,6.29.89.13,2.24,1.25,1.9,2.38-.4,1.31-1.94.65-2.95.66Q130.26,75.31,100.15,75.28Z"
          />
          <path
            className="turtle-cls-3"
            d="M312.9,25.72H277.18c-3.95,0-4.4-.59-2.45-3.18a2.8,2.8,0,0,1,2-1.42c3.74-.16,5-2.83,7.68-3.9,3.54-1.42,4.09-3.21,4.54-5.95C290,5.1,298.8.35,306.2,0c11.15-.47,18.9,4.53,20.44,10.64.83,3.3,1.89,4.29,6.57,4.05a8.42,8.42,0,0,1,8.55,5.8c.33,1.24.66,1.51,2.39,1.18,2.75-.53,5.48-.45,7.3,1.59.52.57,1.26,1.18.93,1.88-.42.88-1.62.54-2.49.54q-12.33,0-24.66,0Z"
          />
          <path
            className="turtle-cls-3"
            d="M193.47,97.41c1.64-3.43,3.58-5,5.9-3.79,2.74,1.43,5.32,3.1,8.33,4,.4.11.69.74,1,1.18s.88,1.11.51,1.8-1,.39-1.61.39q-30.44,0-60.89,0c-1.8,0-2-.55-1.27-2.07a5.68,5.68,0,0,1,4-3.17,2.23,2.23,0,0,0,1.79-1.59A8.91,8.91,0,0,1,162.4,89c1.36.43,1.85.08,2.51-1.08,2.71-4.82,11-8.6,17.29-3.41a12.18,12.18,0,0,1,4.6,7.68c.12.75.27,1.31,1.15,1.26C190.75,93.26,192.1,95.16,193.47,97.41Z"
          />
          <path
            className="turtle-cls-3"
            d="M293.87,216.25s-4.26,8.45-3.76,8.45,5.54-8.45,5.54-8.45a1.5,1.5,0,0,0-.89-.29A1.47,1.47,0,0,0,293.87,216.25Z"
          />
          <path
            className="turtle-cls-3"
            d="M299.57,269.5s-13.69-26.35-13.27-26.76,17.27,26.79,17.27,26.79Z"
          />
          <path
            className="turtle-cls-3"
            d="M306.34,269.53s-18.35-44.66-19-44,13,44,13,44Z"
          />
          <polygon
            className="turtle-cls-3"
            points="303.84 269.53 293.87 216.25 295.65 216.25 307.93 269.53 303.84 269.53"
          />
          <polygon
            className="turtle-cls-3"
            points="306.74 269.53 307.62 190.71 308.19 190.78 308.92 269.53 306.74 269.53"
          />
          <rect
            className="turtle-cls-3"
            x="305.9"
            y="189.16"
            width="3.86"
            height="23.56"
            rx="1.93"
            transform="translate(615.66 401.88) rotate(-180)"
          />
          <path
            className="turtle-cls-3"
            d="M421,269.53H294.09a9.41,9.41,0,0,1,5-4c3.05-1.07,5.86-.57,8.74-.48,7.79.24,10.22-2.66,19.26-4.54a34.82,34.82,0,0,1,7.29-.83c.59,0,1.73,0,8.17.49,5.2.41,5.18.47,6,.43,1.85-.08,5.72-.24,8.93-2.49,4.33-3,4.74-7.92,8.37-9.79a17.11,17.11,0,0,1,3.75-1.33,13.73,13.73,0,0,1,2.33-.29c6.44-.31,13,3.74,15,7.31a6.43,6.43,0,0,0,1.44,2,13,13,0,0,0,4.6,2.48,75.26,75.26,0,0,0,11.82,3.13A33.38,33.38,0,0,1,421,269.53Z"
          />
          <rect
            className="turtle-cls-4"
            x="357.25"
            y="190.19"
            width="2.62"
            height="109.12"
          />
          <rect
            className="turtle-cls-5"
            x="341.4"
            y="153.6"
            width="34.31"
            height="45.49"
            rx="3.99"
          />
          <path
            className="turtle-cls-4"
            d="M158.06,269.53,0,269.08a15,15,0,0,1,7.66-7.66c8.44-3.5,16.53,3.08,23.79-.34,6.2-2.92,2.91-8.95,8.87-12.66,8-5,17.39,3.66,33.47,1.66s18.42-12.26,29.44-11,10,14.65,25.4,19c5.42,1.54,8.24.73,15.32,3.34A48,48,0,0,1,158.06,269.53Z"
          />
          <path
            className="turtle-cls-7"
            d="M210.43,290.19c-.21-1.31.82-2.26,3.41-5,3.76-4,4.8-5.59,5.62-5.24.65.28.79,1.68.88,2.61a12.81,12.81,0,0,1-.08,2.18,7,7,0,0,0,1.54,4.19,7.28,7.28,0,0,0,4.33,2.33c1.68.35,2.21,0,5.63-.18,3.07-.13,4.61-.2,5.13.4,1.47,1.65-1.27,6.61-3.66,9.6a18.65,18.65,0,0,1-10.17,6.61c-3.87.92-10,1-11.22-1.67-.67-1.42,0-3.65.58-5.56a12.48,12.48,0,0,0,.93-4.21,5,5,0,0,0-.69-2.56c-.2-.32-.35-.43-.6-.77A7.92,7.92,0,0,1,210.43,290.19Z"
          />
          <path
            className="turtle-cls-7"
            d="M252.92,278c.34-1.28,1.67-1.72,5.15-3.15,5.07-2.07,6.68-3.12,7.28-2.46s0,1.85-.27,2.74a12.19,12.19,0,0,1-1,1.95,7,7,0,0,0-.32,4.46,7.29,7.29,0,0,0,3,3.9c1.39,1,2,.87,5.21,2.15,2.85,1.14,4.28,1.71,4.52,2.47.65,2.11-3.88,5.51-7.29,7.25a18.66,18.66,0,0,1-12,1.84c-3.91-.75-9.48-3.2-9.54-6.13,0-1.56,1.5-3.33,2.81-4.83a12.42,12.42,0,0,0,2.58-3.46,5,5,0,0,0,.42-2.61,8.46,8.46,0,0,0-.22-.95C252.92,279.45,252.74,278.61,252.92,278Z"
          />
          <path
            className="turtle-cls-8"
            d="M201.63,294.29c-.39-1.26.51-2.34,2.7-5.4,3.19-4.45,4-6.19,4.86-5.95.68.19,1,1.55,1.22,2.47a11.51,11.51,0,0,1,.23,2.16,7.08,7.08,0,0,0,2.09,3.95,10,10,0,0,0,6.58,3.17c2.36.09,3.52-.91,6-1.89,4.3-1.72,10.65-2.64,11.63-1.13,2.47,3.82-25.89,28.43-30,25-1.32-1.09-1-5.71-.25-8.5.56-2.17,1.35-3.25,1-5.27a8.36,8.36,0,0,0-2.11-4.29c-.55-.57-1-.8-1.95-1.82A7.87,7.87,0,0,1,201.63,294.29Z"
          />
          <path
            className="turtle-cls-8"
            d="M261,271c.38-1.49,2.6-2,5.23-2.87,5.14-1.8,6.56-3.52,7.57-2.72s.36,3.71.21,4.28c-.42,1.6-1,1.8-1.46,3.56a8.58,8.58,0,0,0-.24,3.64,7.94,7.94,0,0,0,3.39,4.65c1.89,1.42,4.33,2.14,9.15,3.5,2.35.67,2.77.71,3.24,1.24,2.13,2.44-.91,8.33-1.72,9.9-7.72,15-55.42,25.95-59,18.4-1.22-2.59,4.07-7.86,7.53-11.31,4.52-4.51,9.06-7,18.18-14.29a20.9,20.9,0,0,0,5.56-6.53,23.63,23.63,0,0,0,1.72-4.77,14.15,14.15,0,0,0,.43-2.47c.07-.85,0-1,.07-2.39A15.87,15.87,0,0,1,261,271Z"
          />
          <path
            className="turtle-cls-9"
            d="M209.92,306.93c-1.94.92-3.43,1.7-4.75,2.38-4,2.05-3.93,2-5,2.5-4.88,2.28-7.54,7.43-7,8s3.49-3.19,7.75-2.88a9.44,9.44,0,0,1,5.76,3,1,1,0,0,0,.74.35c3.43.47,8.89-13.6,6.85-15C213.93,305.05,212.59,305.67,209.92,306.93Z"
          />
          <path
            className="turtle-cls-8"
            d="M278.77,283.13c.81-.23,2.7-.68,7.65.18a41.2,41.2,0,0,1,13.63,4.37,39.23,39.23,0,0,1,13.33,11.92,16.8,16.8,0,0,0,4.33,3.16,29.57,29.57,0,0,0,7.42,2.34,11.4,11.4,0,0,1,3.5.91c.71.36,2.57,1.29,3.17,2.84,1.41,3.64-4.89,8.74-5.42,9.16a19,19,0,0,1-6.92,3.42c-3.44,1-6.3,1.84-9,.42-3.57-1.89-4.29-6.42-4.41-7.25-.76-3.91-2.56-9.9-7.5-14.29-10.44-9.29-27-4.37-28.13-9.13C269.76,288.48,274.41,284.37,278.77,283.13Z"
          />
          <path
            className="turtle-cls-10"
            d="M215.46,324.76c3.82,2.09,4.64,6.12,6.17,8.84,5.35,9.46,16,13.1,19.67,14.33,2,.7,18.05,5.87,31.66-3,1.37-.89,12.07-8.06,14.84-19.67.94-4,.51-8.13,3.83-11.83s6.75-2.86,7.65-5.32c2.34-6.4-17.13-21.46-23.48-17.68-1.26.75-2.64,2.61-3.67,2.17s-.45-2.48-1-3.84c-2.7-6.69-33.89-1.72-34.5,4.84-.08.91.35,2.62-.5,3.16s-2.31-.47-3-.83c-4.11-2.14-12,2.74-16.45,6.88-6.88,6.35-12.05,16.73-9.22,19.95C209,324.53,211.7,322.71,215.46,324.76Z"
          />
          <path
            className="turtle-cls-11"
            d="M225.3,322.43a38.1,38.1,0,0,1,32.33-16.67"
          />
          <path
            className="turtle-cls-12"
            d="M312.19,303A6.61,6.61,0,0,0,315,304a4.57,4.57,0,0,0,2.72-.32,4.24,4.24,0,0,0,1.6-1.39"
          />
          <circle className="turtle-cls-13" cx="319.55" cy="315.99" r="1.36" />
          <path
            className="turtle-cls-1"
            d="M369.07,167.43c-4.43-1.46-3.25,5.92-4.31,5.61,0,0-1.56-4.23-3.41-5.55a6.33,6.33,0,0,0-7.53-.11c-2.12,1.43-3.5,4.78-3.93,6.1a2.25,2.25,0,0,1-.74,1.15c-.81.63-1.81.28-1.87.52-.13.46,3.38,1.89,3.38,1.88s2.14,3.25,3.29,3,.08-1.7,1.34-2.55a5.43,5.43,0,0,1,3.59-.46c.33.1,3.71,3.91,4.79,2.12.37-.61-1.16-1.64-.66-2.74.41-.9,2.87-1.64,3.6-2.48C367.93,172.45,371.38,168.19,369.07,167.43Z"
          />
          <line className="turtle-cls-14" x1="391.12" y1={298} x2="347.72" y2={298} />
          <path
            className="turtle-cls-4"
            d="M357.87,296.53s7.68-32.17,8.16-32.17S362,296.53,362,296.53Z"
          />
          <path
            className="turtle-cls-4"
            d="M361.1,296.53s8-20.08,8.47-19.65-4.38,19.65-4.38,19.65Z"
          />
          <rect
            className="turtle-cls-1"
            x="348.53"
            y="185.4"
            width="20.07"
            height="1.79"
            rx="0.9"
          />
        </g>
      </g>
    </svg>
  </div>
);

export default UhOhTurtleSvg;
