import React from 'react';
import { css } from 'react-emotion';
import GetApp from './components/GetApp/GetApp';
import SignUp from './components/SignUp/SignUp';
import Call from './components/Call/Call';
import Email from './components/Email/Email';
import Donate from './components/Donate/Donate';
import SecondaryCTAsGroupFragments from './gql/SecondaryCTAsGroupFragments.graphql';

const Components = {
  CallComponent: Call,
  GetAppComponent: GetApp,
  SignUpComponent: SignUp,
  EmailComponent: Email,
  DonateComponent: Donate,
};

export const SecondaryCTAsGroup = ({
  secondaryCTA,
  secondaryCTAData,
  handleCTAData,
  handleSelection,
  handleCancel,
  performValidation,
  shouldPerformDisabledClickValidation,
  isOrg,
}) => {
  if (
    secondaryCTA === undefined ||
    secondaryCTA === '' ||
    secondaryCTA === null
  ) {
    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.itemWrapper}>
            <SignUp name="SignUp" handleSelection={handleSelection} />
          </div>
          <div className={styles.itemWrapper}>
            <Call name="Call" handleSelection={handleSelection} />
          </div>
          <div className={styles.itemWrapper}>
            <Email name="Email" handleSelection={handleSelection} />
          </div>
          <div className={styles.itemWrapper}>
            <GetApp name="GetApp" handleSelection={handleSelection} />
          </div>
          {!isOrg && (
            <div className={styles.itemWrapper}>
              <Donate name="Donate" handleSelection={handleSelection} />
            </div>
          )}
        </div>
      </>
    );
  }

  const CallToActionComponent = Components[`${secondaryCTA}Component`];
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.selectedItemWrapper}>
          <CallToActionComponent
            name={secondaryCTA}
            shouldPerformDisabledClickValidation={
              shouldPerformDisabledClickValidation
            }
            {...secondaryCTAData}
            handleInputChange={event => {
              handleCTAData({
                name: 'secondaryCTAData',
                data: event.target.value,
              });
            }}
            handleCancel={handleCancel}
            isSelected
            handleIsValid={(name, isValid) =>
              performValidation({
                target: {
                  name: 'secondaryCTA',
                  value: {
                    isSelected: true,
                    isValid,
                  },
                },
              })
            }
          />
        </div>
      </div>
    </>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  `,
  itemWrapper: css`
    padding: 0 1rem 1.5rem 0;
  `,
  selectedItemWrapper: css`
    width: 100%;
  `,
};

SecondaryCTAsGroup.fragments = SecondaryCTAsGroupFragments;

export default SecondaryCTAsGroup;
