import generateSubappConfig from '../_app/generateSubappConfig';
import Transactions from './Transactions';
import { APP_ROUTES } from '../_app/constants';

const getSubappConfig = orgUploaderFlag =>
  generateSubappConfig({
    path: APP_ROUTES.transactions,
    isLive: orgUploaderFlag === 1,
    // allowedRoles: transactionsAllowedRoles,
    navItemConfig: {
      label: 'Transactions',
      sideNavEnabled: false,
    },
    appConfig: {
      Component: Transactions,
      fragmentOnUser: null,
    },
  });

export default getSubappConfig;
