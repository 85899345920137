import React from 'react';
import Select, { components } from 'react-select';
import { get } from 'lodash';
import { colors } from '@peachjar/components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

const { slate, prussian, dragon } = colors;
type OwnProps = {
  classes: { [key: string]: any },
  theme: any,
};
type Props = OwnProps & {
  options: Array<{ value: string, label: string }>,
  error: boolean,
  name: string,
  label: string,
  defaultValue: string,
  placeholder: string,
  onChange: (event: { target: { value: any } }) => void,
  onSelect?: (value: string) => void,
};

type State = {
  inputValue: string,
  item: { label: string, value: string } | null,
  hasCategoryBeenRestored: boolean,
  isFocused: boolean,
};

// Wrapped with PlacesAutocomplete API component
export class WrappedAutocompleteSelect extends React.Component<Props, State> {
  state = {
    item: null,
    // what's currently being typed in the input
    inputValue: '',
    hasCategoryBeenRestored: false,
    isFocused: false,
  };

  componentDidMount() {
    const { defaultValue } = this.props;
    if (defaultValue) {
      this.setState(
        { item: { label: defaultValue, value: defaultValue } },
        () => this.handleHasCategoryBeenRestored(true)
      );
    }
  }

  componentDidUpdate(prevProps: any) {
    const { defaultValue } = this.props;
    const { item, hasCategoryBeenRestored } = this.state;

    if (
      prevProps.defaultValue !== defaultValue &&
      (!item && !hasCategoryBeenRestored && !!defaultValue)
    ) {
      this.setState(
        { item: { label: defaultValue, value: defaultValue } },
        () => this.handleHasCategoryBeenRestored(true)
      );
    }
  }

  handleSelect = (itemObj: any) => {
    // onChange from React Places wrapper
    // this only fires when selecting a choice from the dropdown(or
    // strangely, when backspacing on nothing)
    const { onChange, onSelect } = this.props;
    this.setState(
      {
        item: itemObj,
      },
      () => {
        onChange({
          target: {
            value: get(itemObj, 'value', ''),
          },
        });
        if (onSelect) onSelect(get(itemObj, 'value', ''));
      }
    );
  };

  handleIsFocused = (boolean: boolean) => {
    this.setState({ isFocused: boolean });
  };

  handleHasCategoryBeenRestored = (boolean: boolean) => {
    this.setState({ hasCategoryBeenRestored: boolean });
  };

  render() {
    const {
      classes,
      theme,
      options,
      error,
      name,
      label,
      placeholder,
      // this onChange specifically comes from the PlacesWrapper
      onChange,
    } = this.props;
    const { item, inputValue, isFocused } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
      indicatorSeparator: base => ({
        ...base,
        display: 'none', // Hide | between ClearIndicator and DropdownIndicator
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: '4px',
      }),
    };

    return (
      <div className={classes.root}>
        <Select
          classes={classes}
          name={name}
          styles={selectStyles}
          options={options}
          error={error}
          components={componentsToReactSelect}
          value={item}
          dataTestId="wrappedReactSelect"
          // on keystroke, trigger onChange from React Places wrapper component
          onInputChange={address => {
            this.setState({inputValue: address});
            onChange({
              target: {
                value: address,
              },
            });
          }}
          menuIsOpen={!!inputValue}
          // on select(or backspace with nothing in the field), write to
          // Autcomplete state AND React Places wrapper component
          onChange={this.handleSelect}
          onFocus={() => this.handleIsFocused(true)}
          onBlur={() => this.handleIsFocused(false)}
          placeholder={isFocused ? placeholder : null}
          textFieldProps={{
            label,
            InputLabelProps: {
              shrink: !!item || isFocused,
              style: { color: slate, fontSize: '19px', fontWeight: '400', fontFamily: 'Proxima Nova,Arial,Helvetica,sans-serif' },
            },
          }}
          isClearable
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    fontFamily: 'Proxima Nova,Arial,Helvetica,sans-serif',
  },
  input: {
    display: 'flex',
    padding: '0px 0px 3px 0px',
  },
  underline: {
    borderBottomColor: prussian,
    '&:after': {
      borderBottomColor: prussian,
    },
  },
  error: {
    '&$error:after': {
      borderBottomColor: dragon,
    },
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    fontWeight: 'normal',
    fontFamily: 'Proxima Nova,Arial,Helvetica,sans-serif',
  },
  paper: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

/* -------------------------------------
Start - react-select component overrides
------------------------------------- */

const NoOptionsMessage = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

const Control = props => {
  const { selectProps, innerRef, children, innerProps } = props;
  // Props passed to react-select Select
  const { error, name, classes, textFieldProps } = selectProps;
  return (
    <TextField
      fullWidth
      error={error}
      name={name}
      InputProps={{
        classes: {
          underline: classes.underline,
          error: classes.error,
        },
        inputComponent,
        inputProps: {
          className: classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...textFieldProps}
    />
  );
};

const Option = props => {
  const { innerRef, isFocused, innerProps, isSelected, children } = props;
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
};

const Placeholder = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const SingleValue = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
};

const ValueContainer = props => {
  const { selectProps, children } = props;
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
};

const Menu = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
};

// Overriding default dropdown indicator by hiding it
const DropdownIndicator = () => null;

// Remove close/x icon when input is clearable
const ClearIndicator = () => null;

const componentsToReactSelect = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
  ClearIndicator,
};

/* -------------------------------------
End - react-select component overrides
------------------------------------- */

export default withStyles(styles, { withTheme: true })(
  WrappedAutocompleteSelect
);
