
import * as React from 'react';
import { elements } from '@peachjar/components';

const { Label } = elements.typography;

type Props = {
   children: string,
};

const NoResultsView = ({ children }: Props) => (
  <div className="mt-4">
    <Label muted>{children}</Label>
  </div>
);

// eslint-disable-next-line
NoResultsView.defaultProps = { children: 'No Results Found' };

export default NoResultsView;
