import React from 'react'
import Raven from 'raven-js';
import ErrorBoundaryPage from './ErrorBoundaryPage';
import { ErrorMessage } from '../_app/errors/erros.duck';
import store from './store';

type ErrorInfo = {
  componentStack: string,
};

type Props =  {
  children: React.ReactNode,
};

type State = {
  error?: Error,
  hasError: boolean
};


class AppErrorBoundary extends React.Component<Props, State> {
  state = { error: null, hasError: false };


  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
    // eslint-disable-next-line
    console.error(error, errorInfo);

    Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    const { children } = this.props;    
    const graphqlErrors = store.getState().errors as ErrorMessage;
    const { hasError } = this.state;

    if (hasError && graphqlErrors.hasError) {      
      return (     
       <ErrorBoundaryPage/>   
      );
    }    
    return children;
  }
}

export default AppErrorBoundary;
