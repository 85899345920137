import getDependencies from "./getDependencies";

import { omit } from 'lodash';
import { Quote, CreditBalance as Balance, StripePurchaseAuthorization } from './CommonModels';

const GET_CREDIT_PURCHASING_PRICE = 'credit-purchasing/GET_CREDIT_PURCHASING_PRICE';
const GET_CREDIT_PURCHASING_PRICE_ERRORED = 'credit-purchasing/GET_CREDIT_PURCHASING_PRICE_ERRORED';
const RECEIVE_CREDIT_PURCHASING_PRICE = 'credit-purchasing/RECEIVE_CREDIT_PURCHASING_PRICE';

const CHECKOUT_WITH_STRIPE = 'credit-purchasing/CHECKOUT_WITH_STRIPE';
const CHECKOUT_WITH_STRIPE_ERRORED = 'credit-purchasing/CHECKOUT_WITH_STRIPE_ERRORED';
const RECEIVE_STRIPE_CHECKOUT_SESSION = 'credit-purchasing/RECEIVE_STRIPE_CHECKOUT_SESSION';

const GET_CREDIT_BALANCE = 'credit-purchasing/GET_CREDIT_BALANCE';
const GET_CREDIT_BALANCE_ERROR = 'credit-purchasing/GET_CREDIT_BALANCE_ERRORED';
const RECEIVE_CREDIT_BALANCE = 'credit-purchasing/RECEIVE_CREDIT_BALANCE';

const { creditPurchasingService, creditService, stripe } = getDependencies();

export type CodedError = Error & {
  code: string,
};

export enum RetrievalStates {
  NeverRequested = 'never-requested',
  Retrieving = 'retrieving',
  Errored = 'errored',
  Retrieved = 'retrieved',
}

export type GetCreditPurchasingPrice = {
  type: 'credit-purchasing/GET_CREDIT_PURCHASING_PRICE',
  numberOfCredits: number,
};

export type GetCreditPurchasingPriceErrored = {
  type: 'credit-purchasing/GET_CREDIT_PURCHASING_PRICE_ERRORED',
  error: CodedError,
};

export type ReceiveCreditPurchasingPrice = Quote & {
  type: 'credit-purchasing/RECEIVE_CREDIT_PURCHASING_PRICE',
};

export type CheckoutWithStripe = {
  type: 'credit-purchasing/CHECKOUT_WITH_STRIPE',
  authorization: StripePurchaseAuthorization,
};

export type CheckoutWithStripeErrored = {
  type: 'credit-purchasing/CHECKOUT_WITH_STRIPE_ERRORED',
  error: CodedError,
};

export type ReceiveStripeCheckoutSession = {
  type: 'credit-purchasing/RECEIVE_STRIPE_CHECKOUT_SESSION',
  session: string,
};

export type GetCreditBalance = {
  type: 'credit-purchasing/GET_CREDIT_BALANCE',
};

export type GetCreditBalanceErrored = {
  type: 'credit-purchasing/GET_CREDIT_BALANCE_ERRORED',
  error: CodedError,
};

export type ReceiveCreditBalance = Balance & {
  type: 'credit-purchasing/RECEIVE_CREDIT_BALANCE',
};

export function getCreditPurchasingPrice(numberOfCredits: number) {
  return async (dispatch) => {
    dispatch({
      type: GET_CREDIT_PURCHASING_PRICE,
      numberOfCredits,
    });
    try {
      const quote = await creditPurchasingService.getCreditPurchasingQuote([{
        lineItemId: '1',
        numberOfCredits,
      }]);
      dispatch({
        type: RECEIVE_CREDIT_PURCHASING_PRICE,
        ...quote,
      });
    } catch (error) {
      dispatch({
        type: GET_CREDIT_PURCHASING_PRICE_ERRORED,
        error,
      });
    }
  };
}

export function checkoutWithStripe(authorization: StripePurchaseAuthorization) {
  return async (dispatch) => {
    dispatch({
      type: CHECKOUT_WITH_STRIPE,
      authorization,
    });
    try {
      const { session } = await creditPurchasingService.checkoutWithStripe(authorization);
      dispatch({
        type: RECEIVE_STRIPE_CHECKOUT_SESSION,
        session,
      });
      await stripe.redirectToCheckout({ sessionId: session });
    } catch (error) {
      dispatch({
        type: CHECKOUT_WITH_STRIPE_ERRORED,
        error,
      });
    }
  };
}

export function getCreditBalance() {
  return async (dispatch) => {
    dispatch({ type: GET_CREDIT_BALANCE });
    try {
      const balance = await creditService.getBalance();
      dispatch({
        type: RECEIVE_CREDIT_BALANCE,
        ...balance,
      });
    } catch (error) {
      dispatch({
        type: GET_CREDIT_BALANCE_ERROR,
        error,
      });
    }
  };
}

type Actions = GetCreditPurchasingPrice | ReceiveCreditPurchasingPrice | GetCreditPurchasingPriceErrored
  | CheckoutWithStripe | CheckoutWithStripeErrored | ReceiveStripeCheckoutSession
  | GetCreditBalance | GetCreditBalanceErrored | ReceiveCreditBalance;

export type CreditPurchasingState = {
  quoteFulfilled: RetrievalStates,
  quoteFulfilledError: null | CodedError,
  quote: Quote,
  balanceFulfilled: RetrievalStates,
  balanceFulfilledError: null | CodedError,
  balance: Balance,
  checkoutFulfilled: RetrievalStates,
  checkoutFulfilledError: null | CodedError,
  checkoutSession: string,
};

export const initialState: CreditPurchasingState = {
  quoteFulfilled: RetrievalStates.NeverRequested,
  quoteFulfilledError: null,
  quote: {
    hasError: false,
    lineItems: [{
      lineItemId: '1',
      numberOfCredits: 5,
      price: 25,
      msrpValue: 25,
      pricePerCredits: 5,
    }],
    total: {
      msrpValue: 0,
      numberOfCredits: 0,
      price: 0,
    }
  },
  balanceFulfilled: RetrievalStates.NeverRequested,
  balanceFulfilledError: null,
  balance: {
    numberOfCredits: 0,
    msrpValue: 0,
    updatedAt: '1970-01-01T00:00:00.000Z',
  },
  checkoutFulfilled: RetrievalStates.NeverRequested,
  checkoutFulfilledError: null,
  checkoutSession: '',
};

export function reducer(
  state: CreditPurchasingState = initialState,
  action: Actions
) {
  switch (action.type) {
    case GET_CREDIT_PURCHASING_PRICE:
      return {
        ...state,
        quoteFulfilled: RetrievalStates.Retrieving,
        quoteFulfilledError: null,
      };
    case GET_CREDIT_PURCHASING_PRICE_ERRORED:
      return {
        ...state,
        quoteFulfilled: RetrievalStates.Errored,
        quoteFulfilledError: action.error,
      };
    case RECEIVE_CREDIT_PURCHASING_PRICE:
      return {
        ...state,
        quoteFulfilled: RetrievalStates.Retrieved,
        quote: omit(action, ['type']),
      };
    case CHECKOUT_WITH_STRIPE:
      return {
        ...state,
        checkoutSession: '',
        checkoutFulfilled: RetrievalStates.Retrieving,
      };
    case RECEIVE_STRIPE_CHECKOUT_SESSION:
      return {
        ...state,
        checkoutSession: action.session,
        checkoutFulfilled: RetrievalStates.Retrieved,
      };
    case CHECKOUT_WITH_STRIPE_ERRORED:
      return {
        ...state,
        checkoutFulfilled: RetrievalStates.Errored,
        checkoutFulfilledError: action.error,
      };
    case GET_CREDIT_BALANCE:
      return {
        ...state,
        balanceFulfilled: RetrievalStates.Retrieving,
        balanceFulfilledError: null,
      }
    case GET_CREDIT_BALANCE_ERROR:
      return {
        ...state,
        balanceFulfilled: RetrievalStates.Errored,
        balanceFulfilledError: action.error,
      };
    case RECEIVE_CREDIT_BALANCE:
      return {
        ...state,
        balanceFulfilled: RetrievalStates.Retrieved,
        balance: omit(action, ['type']),
      };
    default: return state;
  }
}
