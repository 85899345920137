import Approvals from './index';
import generateSubappConfig from '../_app/generateSubappConfig';
import ApprovalsSidebarNavItemBadge from './ApprovalsSidebarNavItemBadge';
import { approvalsAllowedRoles } from '../_app/RolesEnum';
import { APP_ROUTES } from '../_app/constants';
import ExternalRedirect from '../_app/components/ExternalRedirect';

const getSubappConfig = portalNavFlag =>
  generateSubappConfig({
    path: APP_ROUTES.approvals.index,
    isLive: portalNavFlag === 1,
    allowedRoles: approvalsAllowedRoles,
    navItemConfig: {
      label: 'Approvals',
      fragmentOnUser: ApprovalsSidebarNavItemBadge.fragments.sodUser,
    },
    appConfig: {
      Component: ExternalRedirect,
      fragmentOnUser: Approvals.fragments.sodUser,
    },
  });

export default getSubappConfig;
