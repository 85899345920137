import React, { Fragment, Component } from 'react';
import { components } from '@peachjar/components';
import { Mutation } from 'react-apollo';
import { get } from 'lodash';
import { connect } from 'react-redux';
import axios from 'axios';
import Raven from 'raven-js';

import portalBffApolloClient from '../../../../../../_app/apollo/portalBFF.apolloClient';
import { genericUploadError, convertBToMB } from './constants';
import { getPdfUrlFromResponse } from '../utils';
import UPLOAD_FLYER_MUTATION from '../../../gql/UploadFlyerMutation.graphql';
import LargeFileConfirmationModal from './LargeFileConfirmationModal';
import getDependencies from '../../../../../../file_transfering/getDependencies';
import GetPresignedUrlError from '../errors/GetPresignedUrlError';
import InvalidFileTypeError from '../errors/InvalidFileTypeError';
import PeachjarFileUploadError from '../errors/PeachjarFileUploadError';
import S3PutFileUploadError from '../errors/S3PutFileUploadError';

const { fileTransferService } = getDependencies();

const { FileUploadStatusBox, FileUpload } = components;

const MAX_FILE_SIZE_IN_MB = 50;
const LARGE_FILE_SIZE_IN_MB = 10;

type State = {
  filename: string;
  fileSizeInMB: number;
  file: Record<string, any>;
  isLargeFileModalOpen: boolean;
  isUploading: boolean;
  didUploadError: boolean;
};

type Props = {
  flyerId: string;
  setFlyerId: (obj: Object) => void;
  setUserTexts: (obj: Object) => void;
  setIsUploading: (bool: boolean) => void;
  isUploading: boolean;
};

class UploadFlyerMutation extends Component<Props, State> {
  state = {
    filename: '',
    fileSizeInMB: 0,
    file: {},
    isLargeFileModalOpen: false,
    didUploadError: false,
  };

  handleState(key: string | number, value: any) {
    this.setState({
      [key]: value,
    });
  }

  curriedOnConfirmModal = (
    uploadFlyer,
    setFlyerId,
    setUserTexts,
    blob
  ) => () => {
    this.uploadFlyerAndSetStates(
      uploadFlyer,
      setFlyerId,
      setUserTexts,
      blob,
    );
    this.setState({
      isLargeFileModalOpen: false,
    });
  };

  uploadFlyerAndSetStates = async (
    uploadFlyer,
    setFlyerId,
    setUserTexts,
    file
  ) => {
    const { setIsUploading } = this.props;
    let signedUrl;
    let putResult;
    let flyerData;
    let pdfUrl = '';
    setIsUploading(true);
    this.setState({ didUploadError: false });

    try {
      signedUrl = await fileTransferService.getPresignedUrl();
    } catch (err) {
      setIsUploading(false);
      this.handleState('didUploadError', true);
      console.error('Error getting Presigned URL', err);
      Raven.captureException(new GetPresignedUrlError(err.message));
    }

    try {
      const options = {
        headers: {
          'Content-Type': file.type,
          'x-amz-acl': 'public-read',
        },
      };

      putResult = await axios.put(signedUrl, file, options);

      pdfUrl = getPdfUrlFromResponse(putResult);
    } catch (err) {
      setIsUploading(false);
      this.handleState('didUploadError', true);
      console.error('Error PUTing file upload to S3:', err);
      Raven.captureException(new S3PutFileUploadError(err.message));
    }

    if (pdfUrl.includes('.pdf')) {
      try {
        flyerData = await uploadFlyer({ variables: { pdfUrl } });

        setFlyerId({
          variables: {
            flyerId: get(flyerData, 'data.uploadFlyer.flyer.id', ''),
          },
        });
        setUserTexts({
          variables: {
            userTexts: get(
              flyerData,
              'data.uploadFlyer.flyer.flyerPages',
              []
            ).map(flyerPage => flyerPage.userText),
          },
        });
      } catch (err) {
        setIsUploading(false);
        this.handleState('didUploadError', true);
        console.error('Error uploading flyer', err);
        Raven.captureException(new PeachjarFileUploadError(err.message));
      }
    } else {
      alert('File was not a PDF!');
    }
    setIsUploading(false);
  };

  render() {
    const { setFlyerId, setUserTexts, flyerId, isUploading } = this.props;
    const {
      isLargeFileModalOpen,
      fileSizeInMB,
      file,
      didUploadError,
    } = this.state;

    return (
      <Mutation client={portalBffApolloClient} mutation={UPLOAD_FLYER_MUTATION}>
        {(uploadFlyer, { data, loading, error }) => {
          const { filename } = this.state;

          const errors =
            ((error || didUploadError) && [genericUploadError]) ||
            get(data, 'uploadFlyer.validationErrors', []);
          const warnings = get(data, 'uploadFlyer.validationWarnings', []);
          return (
            <Fragment>
              {(loading || data || error || isUploading || didUploadError) &&
                !isLargeFileModalOpen && (
                  <FileUploadStatusBox
                    className="mt-2"
                    isComplete={!isUploading && !!get(data, 'uploadFlyer')}
                    filename={filename}
                    filetype="pdf"
                    errors={errors}
                    warnings={warnings}
                  />
                )}

              <FileUpload
                className="mt-3 pb-5 pt-5"
                data-testid="fileupload-flyer"
                documentType="pdf"
                componentWidth="auto"
                componentHeight="auto"
                didAttemptUpload={!!flyerId}
                maxFileSizeInMB={MAX_FILE_SIZE_IN_MB}
                onDrop={acceptedFiles => {
                  if (acceptedFiles.length > 0) {
                    const acceptedFile = get(acceptedFiles, '[0]');

                    this.handleState('filename', acceptedFile.name);
                    const fileSize = Math.round(
                      convertBToMB(acceptedFile.size)
                    );
                    this.setState({ fileSizeInMB: fileSize });
                    const isLargeFile = fileSize >= LARGE_FILE_SIZE_IN_MB;

                    // Use Blob since IE11 does not support File API completely
                    // const blob = new Blob([acceptedFiles[0]], {
                    //   type: 'application/pdf',
                    // });
                    // blob.name = acceptedFiles[0].name;
                    // blob.lastModifiedDate = new Date();

                    isLargeFile
                      ? this.setState({
                          isLargeFileModalOpen: true,
                          file: acceptedFile,
                        })
                      : this.uploadFlyerAndSetStates(
                          uploadFlyer,
                          setFlyerId,
                          setUserTexts,
                          acceptedFile
                        );
                  } else {
                    alert('Your file was not accepted.');
                  }
                }}
              />

              {isLargeFileModalOpen && (
                <LargeFileConfirmationModal
                  dataTestId="modal-largeFileConfirmation"
                  fileSizeInMB={fileSizeInMB}
                  onConfirm={this.curriedOnConfirmModal(
                    uploadFlyer,
                    setFlyerId,
                    setUserTexts,
                    file
                  )}
                  onCancel={() =>
                    this.setState({ isLargeFileModalOpen: false })
                  }
                />
              )}
            </Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default UploadFlyerMutation;
