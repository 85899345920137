/* eslint-disable graphql/template-strings */
import gql from 'graphql-tag';

import Prepare from '../Prepare/Prepare';
import Details from '../Details/Details';
import Delivery from '../Delivery/Delivery';

const getCampaignFragment = () => {
  // TODO: this is a hack to make the unit tests pass.
  // During unit testing, the references to Prepare/Detilas/Delivery are not defined for unknow reason
  if (Prepare && Details && Delivery) {
    return gql`
      fragment fragment on Campaign {
        id
        legacyFlyerId
        createdAt
        updatedAt
        isDeleted
        isSubmitted
        primaryCallToAction {
          id
          type
          data
        }
        secondaryCallToAction {
          id
          type
          data
        }
        flyer {
          ...Prepare_getMyCampaigns
        }
        ...Details_getMyCampaigns
        deliverySettings {
          ...Delivery_getMyCampaigns_targetAudiences
        }
        application {
          id
          schoolSelectionLimit
        }
      }
      ${Prepare.fragments.getMyCampaigns}
      ${Details.fragments.getMyCampaigns}
      ${Delivery.fragments.getMyCampaigns_targetAudiences}
    `;
  }

  return gql`
    fragment fragment on Campaign {
      id
      createdAt
      updatedAt
      isDeleted
      isSubmitted
      primaryCallToAction {
        id
        type
        data
      }
      secondaryCallToAction {
        id
        type
        data
      }
      deliverySettings {
        id
        selectedAudience
        targetAudiences
        districtGuidelinesConfirmed
        startDate
        endDate
        numberOfDistributions
      }
    }
  `;
};

export default getCampaignFragment();
