import gql from 'graphql-tag';
export default {
  sodUser_campaignDistricts: gql`
    fragment Delivery_sodUser_campaignDistricts on CampaignDistrict {
      ncesId
      name
      guidelinesUrl
      audiences {
        parents {
          schoolId
          districtId
          ncesId
          name
          audienceType
          lowestGradeOffered
          highestGradeOffered
          isPostOnly
          isOptionalPostOnly
          district {
            name
            url
            isPostOnly
            isOptionalPostOnly
          }
        }
        staff {
          schoolId
          districtId
          ncesId
          name
          audienceType
          lowestGradeOffered
          highestGradeOffered
          isPostOnly
          isOptionalPostOnly
          district {
            name
            url
            isPostOnly
            isOptionalPostOnly
          }
        }
      }
    }
  `,
  getMyCampaigns_targetAudiences: gql`
    fragment Delivery_getMyCampaigns_targetAudiences on DeliverySettings {
      id
      selectedAudience
      targetAudiences
      districtGuidelinesConfirmed
      startDate
      endDate
      numberOfDistributions
    }
  `,
};
