import gql from 'graphql-tag';
import CampaignSlatFragments from './CampaignSlatFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment CampaignSlatList_getMyCampaigns on Campaign {
      ...CampaignSlat_getMyCampaigns
    }
    ${CampaignSlatFragments.getMyCampaigns}
  `,
};
