import * as React from 'react';
import portalBffApolloClient from '../../../../_app/apollo/portalBFF.apolloClient';
import SAVE_CAMPAIGN_MUTATION from '../../gql/SaveCampaignMutation.graphql';
import MutationButton from '../../../../components/MutationButton';
import { mapCampaignSummaryToGql, mapCheckboxSelections } from '../../gql/utils';

type CheckboxSelections = {
  [k: string]: any,
};

type Props = {
  campaignId: any;
  messageToApprover: string;
  checkboxSelections?: CheckboxSelections[] | null; 
  handleSuccess?: any;
  handleError?: any;
  disabled?: any;
  children?: any;
};

const SaveCampaignDeliveryButton = ({
  campaignId,
  messageToApprover,
  checkboxSelections = null,
  handleSuccess,
  handleError,
  disabled,
  children,
}: Props) => {
  const campaignSummary = mapCampaignSummaryToGql({
    messageToApprover,
  });
  const variables = {
    campaignId,
    campaignSummary,
    campaignDeliverySettings: {
      targetAudiences: mapCheckboxSelections(checkboxSelections),
    },
  };

  return (
    <MutationButton
      client={portalBffApolloClient}
      mutation={SAVE_CAMPAIGN_MUTATION}
      variables={variables}
      buttonType="secondaryLarge"
      // refetchQueries={() => ['getMyCampaigns']}
      handleSuccess={handleSuccess}
      handleError={handleError}
      disabled={disabled}
    >
      {children}
    </MutationButton>
  );
};

export default SaveCampaignDeliveryButton;
