import { get } from 'lodash'
import FlyerPurchaseQuote from '../FlyerPurchaseQuote'

interface FlyerPurchaseVerificationFields {
  expectedTotalPaymentInCents: number,
  expectedTotalPaymentInCredits: number,
  expectedBalancePaymentInCredits: number,
}

export default function quoteToPurchaseVerificationFields(
  quote: FlyerPurchaseQuote,
): FlyerPurchaseVerificationFields {
  const expectedTotalPaymentInCents = quote.total.priceInCents
  const expectedTotalPaymentInCredits = quote.total.numberOfCredits
  const expectedBalancePaymentInCredits = parseInt(
    get(
      quote.distributionDiscounts.find(d => d.type === 'balance'),
      'value',
      '0',
    )
  )
  const expectedSubtotalMinusAccountBalanceInCredits = quote
    .subtotalMinusAccountBalance
    .numberOfCredits

  return {
    expectedTotalPaymentInCents,
    expectedTotalPaymentInCredits,
    expectedBalancePaymentInCredits,
  }
}
