import { isEmpty, omit, get, pickBy, toString } from 'lodash';
import GradeLevels from '@peachjar/school-finder-api/dist/api/GradeLevels';
import {
  gradesToAges,
  agesToGrades,
  gradeFilterDefault,
} from '../../shared/constants';
import { DeliveryModes } from '../../../deals/deals.types';

export const isSecondaryCTADataEmpty = data => {
  if (!data) return true;

  return (
    data.webAppUrl.length === 0 &&
    data.phoneNumber.length === 0 &&
    data.websiteUrl.length === 0 &&
    data.email.length === 0 &&
    data.donateWebsiteUrl.length === 0
  );
};

export const getTypenamedCheckboxSelections = checkboxSelections =>
  checkboxSelections.map(selection => {
    const typenamedDistrict = isEmpty(selection.district)
      ? null
      : { ...selection.district, __typename: 'AudienceDistrict' };
    return {
      ...selection,
      district: typenamedDistrict,
      __typename: 'Audience',
    };
  });

export const mapCampaignDetailsToGql = campaignDetails => {
  const {
    flyerTitle,
    participationFee,
    lowParticipationFee,
    highParticipationFee,
    minGradeLevel,
    maxGradeLevel,
    eventLocation,
    deadlineDate,
    startDate,
    endDate,
    categoryMain,
    categoryAdd1,
    categoryAdd2,
    primaryCTA,
    primaryCTAData,
    secondaryCTA,
    secondaryCTAData,
  } = campaignDetails;

  let mappedCampaignDetails = {
    title: flyerTitle || '', // Ensure flyerTitle is never null
    participationFee: participationFee || '',
    lowParticipationFee: lowParticipationFee || '',
    highParticipationFee: highParticipationFee || '',
    eventLocation,
    primaryCategory: categoryMain || null,
  };

  if (minGradeLevel !== '') {
    mappedCampaignDetails = {
      ...mappedCampaignDetails,
      minGradeLevel: get(
        gradesToAges,
        minGradeLevel,
        gradesToAges[gradeFilterDefault.min]
      ),
    };
  }

  if (maxGradeLevel !== '') {
    mappedCampaignDetails = {
      ...mappedCampaignDetails,
      maxGradeLevel: get(
        gradesToAges,
        maxGradeLevel,
        gradesToAges[gradeFilterDefault.max]
      ),
    };
  }

  // if (categoryMain) mappedCampaignDetails.primaryCategory = categoryMain;

  mappedCampaignDetails.deadlineDate = deadlineDate && new Date(deadlineDate);
  mappedCampaignDetails.startDate = startDate && new Date(startDate);
  mappedCampaignDetails.endDate = endDate && new Date(endDate);

  const optionalCategoryArray = [];
  if (categoryAdd1) {
    optionalCategoryArray.push(categoryAdd1);
  }
  if (categoryAdd2) {
    optionalCategoryArray.push(categoryAdd2);
  }
  mappedCampaignDetails.optionalCategories = optionalCategoryArray;

  if (primaryCTA && get(primaryCTAData, 'websiteUrl', '') !== '') {
    mappedCampaignDetails = {
      ...mappedCampaignDetails,
      primaryCallToAction: {
        type: primaryCTA,
        data: primaryCTAData,
      },
    };
  }

  // Only add secondaryCallToAction to mappedCampaignDetails if inputs contain data
  if (secondaryCTA && !isSecondaryCTADataEmpty(secondaryCTAData)) {
    mappedCampaignDetails = {
      ...mappedCampaignDetails,
      secondaryCallToAction: {
        type: secondaryCTA,
        data: secondaryCTAData,
      },
    };
  }

  return mappedCampaignDetails;
};

export const mapCheckboxSelections = selections => {
  if (!selections) {
    return [];
  }

  return selections.map(selection => {
    if (typeof selection.district === 'object') {
      selection.district = omit(selection.district, '__typename');
    }
    return omit(selection, '__typename');
  });
};

export const mapCampaignDeliveryToGql = campaignDelivery => {
  const {
    selectedAudience,
    checkboxSelections,
    areDistrictGuidelinesConfirmed,
    campaignStartDate,
    postExpirationDate,
    postOnlySelections = [],
    numberOfDistributions,
  } = campaignDelivery;

  const normalizedCheckboxes = mapCheckboxSelections(checkboxSelections);
  // hierarchyIsPostOnly
  // hierarchyIsOptionalPostOnly
  // deliveryMode
  const checkboxSelectionsWithPostOnly = normalizedCheckboxes.map(
    ({
      schoolId,
      districtId,
      ncesId,
      name,
      audienceType,
      isPostOnly,
      isOptionalPostOnly,
      isPostOnlyChecked,
      district,
      lowestGradeOffered,
      highestGradeOffered,
    }) => {
      const postOnlySelected =
        postOnlySelections.includes(toString(schoolId)) || isPostOnlyChecked;
      const deliveryMode = postOnlySelected
        ? DeliveryModes.PostOnly
        : DeliveryModes.PostAndDistribute;

      return {
        schoolId,
        districtId,
        ncesId,
        name,
        audienceType,
        hierarchyIsPostOnly: isPostOnly,
        hierarchyIsOptionalPostOnly: isOptionalPostOnly,
        deliveryMode,
        district,
        highestGradeOffered,
        lowestGradeOffered,
      };
    }
  );

  const campaignDeliverySettings = {
    selectedAudience,
    targetAudiences: checkboxSelectionsWithPostOnly,
    numberOfDistributions,
    districtGuidelinesConfirmed: !!areDistrictGuidelinesConfirmed,
    endDate: postExpirationDate || null,
  };

  if (campaignStartDate) campaignDeliverySettings.startDate = campaignStartDate;

  return campaignDeliverySettings;
};

export const mapCampaignSummaryToGql = campaignSummary => {
  const { messageToApprover, legacyFlyerId } = campaignSummary;

  return {
    messageToApprover,
    legacyFlyerId,
  };
};

export const rehydrateCache = async ({
  defaultSelections,
  remoteCampaign,
  rehydratePrepareCache,
  rehydrateDetailsCache,
  rehydrateDeliveryCache,
  rehydrateSummaryCache,
  rehydratePrimaryCTA,
  rehydrateSecondaryCTA,
  rehydrateCTAData,
}) => {
  await rehydratePrepareCache({
    variables: {
      id: remoteCampaign.id,
      flyerId: remoteCampaign.flyer.id,
      userTexts: get(remoteCampaign, 'flyer.flyerPages', []).map(
        flyerPage => flyerPage.userText
      ),
    },
  });

  const primaryCallToAction = get(remoteCampaign, 'primaryCallToAction', null);
  const secondaryCallToAction = get(
    remoteCampaign,
    'secondaryCallToAction',
    null
  );

  const cleanDetailsVariables = pickBy({
    flyerTitle: get(remoteCampaign, 'title'),
    participationFee: get(remoteCampaign, 'participationFee'),
    lowParticipationFee: get(remoteCampaign, 'lowParticipationFee'),
    highParticipationFee: get(remoteCampaign, 'highParticipationFee'),
    eventLocation: get(remoteCampaign, 'eventLocation'),
    deadlineDate: get(remoteCampaign, 'deadlineDate'),
    startDate: get(remoteCampaign, 'startDate'),
    endDate: get(remoteCampaign, 'endDate'),
    categoryMain: get(remoteCampaign, 'primaryCategory'),
    categoryAdd1: get(remoteCampaign, 'optionalCategories[0]'),
    categoryAdd2: get(remoteCampaign, 'optionalCategories[1]'),
  });

  const isPrimaryCTASelected = Boolean(
    primaryCallToAction && primaryCallToAction.type
  );

  const detailsVariables = {
    ...cleanDetailsVariables,
    isPrimaryCTASelected,
  };

  // Skips rehydrating details cache if flyer uploaded without confirming OCR text and no details data has been submitted
  if (remoteCampaign.title !== 'Untitled') {
    await rehydrateDetailsCache({
      variables: detailsVariables,
    });
  }

  if (primaryCallToAction) {
    await rehydratePrimaryCTA({
      variables: {
        name: primaryCallToAction.type,
      },
    });

    await rehydrateCTAData({
      variables: {
        name: 'primaryCTAData',
        data: {
          websiteUrl: primaryCallToAction.data.websiteUrl,
          __typename: 'CampaignDetails',
        },
      },
    });
  }

  if (secondaryCallToAction) {
    await rehydrateSecondaryCTA({
      variables: {
        name: secondaryCallToAction.type,
      },
    });

    await rehydrateCTAData({
      variables: {
        name: 'secondaryCTAData',
        data: {
          __typename: 'CampaignDetails',
          androidAppUrl: secondaryCallToAction.data.androidAppUrl,
          iosAppUrl: secondaryCallToAction.data.iosAppUrl,
          webAppUrl: secondaryCallToAction.data.webAppUrl,
          phoneNumber: secondaryCallToAction.data.phoneNumber,
          websiteUrl: secondaryCallToAction.data.websiteUrl,
          donateWebsiteUrl: secondaryCallToAction.data.donateWebsiteUrl,
          email: secondaryCallToAction.data.email,
        },
      },
    });
  }

  const remoteTargetAudiences = get(
    remoteCampaign,
    'deliverySettings.targetAudiences',
    defaultSelections
  ).map(targetAudience => ({
    ...targetAudience,
    __typename: 'CampaignSchool',
  }));

  await rehydrateDeliveryCache({
    variables: {
      minGradeLevel: get(
        agesToGrades,
        remoteCampaign.minGradeLevel,
        gradeFilterDefault.min
      ),
      maxGradeLevel: get(
        agesToGrades,
        remoteCampaign.maxGradeLevel,
        gradeFilterDefault.max
      ),
      campaignStartDate: get(
        remoteCampaign,
        'deliverySettings.startDate',
        null
      ),
      postExpirationDate: get(remoteCampaign, 'deliverySettings.endDate'),
      numberOfDistributions: get(
        remoteCampaign,
        'deliverySettings.numberOfDistributions'
      ),
      areDistrictGuidelinesConfirmed: get(
        remoteCampaign,
        'deliverySettings.districtGuidelinesConfirmed'
      ),
      checkboxSelections: remoteTargetAudiences,
      selectedAudience: get(
        remoteCampaign,
        'deliverySettings.selectedAudience',
        'parents'
      ),
    },
  });

  await rehydrateSummaryCache({
    variables: {
      messageToApprover: get(remoteCampaign, 'messageToApprover'),
      legacyFlyerId: get(remoteCampaign, 'legacyFlyerId'),
    },
  });
};
