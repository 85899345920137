import React from 'react';
import { TableCell } from '@material-ui/core';
import { elements } from '@peachjar/components';
import StatusPill from './StatusPill';

// TODO: Add new colors to shared library
const PILL_COLORS = {
  mercury: '#e6e6e6',
  rose: '#ffc7c7',
  mint: '#d4e8d9',
  peach: '#ffe6aa',
};

const { Note } = elements.typography;

type Props = {
  value: string | number;
  width: string;
  align?: any;
  displayStatusPill?: boolean;
  type?: string;
  typeInfo?: any;
};

const FormattedTableCell = ({
  value,
  width,
  align,
  displayStatusPill,
  type,
  typeInfo,
}: Props) => (
  <TableCell align={align} style={{ padding: '0.5rem 1rem', width }}>
    {displayStatusPill ? (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Note>{value}</Note>
        {displayStatusPill && generatePillStatus(typeInfo.status)}
      </div>
    ) : (
      <div>
        <Note>{value}</Note>
      </div>
    )}
  </TableCell>
);

const generatePillStatus = status => {
  let text;
  let color;

  if (status === 'Paid') {
    text = 'Paid';
    color = PILL_COLORS.mint;
  }

  if (status === 'Cancelled') {
    text = 'Cancelled';
    color = PILL_COLORS.mercury;
  }

  if (status === 'Overdue') {
    text = 'Overdue';
    color = PILL_COLORS.rose;
  }

  if (status === 'Outstanding') {
    text = 'Outstanding';
    color = PILL_COLORS.peach;
  }

  return <StatusPill pillText={text} pillColor={color} />;
};

export default FormattedTableCell;
export { generatePillStatus };
