
import * as React from 'react';
import { History } from 'react-router-dom';
import { components } from '@peachjar/components';
import routePushHandler from '../../../../../shared/routePushHandler';

const { ButtonSecondaryLarge } = components.Buttons;

type Props = {
  // eslint-disable-next-line
   campaignId: string,
   history: History,
   children: React.ReactNode,
};

const ReuploadFlyerButton = ({ history, campaignId }: Props) => (
  <ButtonSecondaryLarge
    onClick={routePushHandler(
      history,
      `/campaigns/create-campaign/${campaignId}/prepare/upload`
    )}
  >
    Reupload Flyer
  </ButtonSecondaryLarge>
);

export default ReuploadFlyerButton;
