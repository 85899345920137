import React from 'react';
import get from 'lodash/get';
import LearnMore from './components/LearnMore/LearnMore';
import PrimaryCTAFragments from './gql/PrimaryCTAFragments.graphql';

const PrimaryCTA = ({
  isPrimaryCTASelected,
  primaryCTA,
  primaryCTAData,
  handleCTAData,
  handleSelection,
  handleCancel,
  performValidation,
  shouldPerformDisabledClickValidation,
}) => {
  const websiteUrl = get(primaryCTAData, 'websiteUrl', '');

  return (
    <>
      <LearnMore
        name="learnMore"
        isSelected={isPrimaryCTASelected || (primaryCTA && primaryCTA !== '')}
        websiteUrl={websiteUrl}
        handleInputChange={event => {
          handleCTAData({
            name: 'primaryCTAData',
            data: event.target.value,
          });
        }}
        handleSelection={handleSelection}
        handleCancel={handleCancel}
        shouldPerformDisabledClickValidation={
          shouldPerformDisabledClickValidation
        }
        handleIsValid={(name, isValid) =>
          performValidation({
            target: {
              name: 'primaryCTA',
              value: isValid,
            },
          })
        }
      />
    </>
  );
};

PrimaryCTA.fragments = PrimaryCTAFragments;

export default PrimaryCTA;
