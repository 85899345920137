import { Grid } from '@material-ui/core';
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons';
import MessageBoxNotification from '@peachjar/ui/dist/lib/components/Notifications/MessageBoxNotification';
import React, { FC } from 'react';
import scheduleDistributionIcon from '../assets/icon_celebration.svg';
import { setFlag } from '../helpers/localStorage';
import config from '../config';

export const SCHEDULE_DISTRIBUTION_VISITED_FLAG = 'scheduleDistributionScreen';

export const setFirstVisitFlag = () => {
  setFlag(SCHEDULE_DISTRIBUTION_VISITED_FLAG);
  window.location.href = `${config.REACT_APP_PORTAL_BFF_URL}/settings/distribution`;
};

const ScheduleDistributionNotification: FC = () => (
  <Grid item xs={12}>
    <MessageBoxNotification
      headline="Schedule Distribution"
      subheadline="Schedule distribution is now available. This allows you to approve flyers individually and see the flyer's schedule distributions. Therefore, you can now approve the flyers with clarity as to when they will be distributed."
      iconSrc={scheduleDistributionIcon}
      variant="pop"
    >
      <ButtonSecondarySmall onClick={setFirstVisitFlag}>
        View Schedule Distribution
      </ButtonSecondarySmall>
    </MessageBoxNotification>
  </Grid>
);

export default ScheduleDistributionNotification;
