import React from 'react';
import { css } from 'react-emotion';
import { elements, fonts, colors } from '@peachjar/components';
import NoStaffListSvg from './NostaffList.svg';

const { Label } = elements.typography;
const { ProximaNova } = fonts;
const { silver } = colors;

const styles = {
  container: css`
    border: 1px solid ${silver};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 448px;
    margin-top: 2rem;
  `,
  labelContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  `,
  label: css`
    font: ${ProximaNova};
  `,
};

const NoStaffList = () => (
  <div className={styles.container}>
    <NoStaffListSvg />
    <div className={styles.labelContainer}>
      <Label className={styles.label}>
        Looks like you don&apos;t have any staff lists.
      </Label>
      <Label className={styles.label}>
        Contact us at (858) 997-2117 to setup your staff groups.
      </Label>
    </div>
  </div>
);

export default NoStaffList;
