import generateSubappConfig from '../_app/generateSubappConfig';
import { reportingAllowedRoles } from '../_app/RolesEnum';
import { APP_ROUTES } from '../_app/constants';
import RedirectReporting from '../_app/components/RedirectReporting';

const getMyFlyersSubappConfig = portalNavFlag =>
  generateSubappConfig({
    path: APP_ROUTES.reporting.myFlyers,
    isLive: portalNavFlag === 1,
    allowedRoles: reportingAllowedRoles,
    navItemConfig: {
      label: 'Reporting',
    },
    appConfig: {
      Component: RedirectReporting,
      fragmentOnUser: {},
    },
  });

export default getMyFlyersSubappConfig;
