import gql from 'graphql-tag';
/* eslint-disable graphql/template-strings */
const CAMPAIGN_DETAILS_VALIDATION_CLIENT_QUERY = gql`
  query campaignDetailsValidation {
    campaignDetails @client {
      formValidation {
        __typename
        isFormValid
      }
    }
  }
`;

export default CAMPAIGN_DETAILS_VALIDATION_CLIENT_QUERY;
