import React, { useState } from "react";
import { css } from "react-emotion";
import { get } from "lodash";
import { elements, colors } from "@peachjar/components";

const { Note } = elements.typography;
const { mercury } = colors;

const styles = {
  image: css`
    width: 250px;
    height: 350px;
  `,
  imageContainer: css`
    border: 1px solid ${mercury};
  `,
};

const JpegFlyerPreview = ({ flyerPages }) => {
  const initialURL = get(flyerPages, "[0].jpegUrl");
  const [jpgURL, setJpgURL] = useState(initialURL);
  return (
    <div className="layout-column layout-align-center-start">
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={jpgURL}
          alt="Flyer Preview"
          data-testid="info-jpegFlyerPreview"
        />
      </div>
      <div>
        <Note muted>Page 1 of {get(flyerPages, "length")}</Note>
      </div>
    </div>
  );
};

export default JpegFlyerPreview;
