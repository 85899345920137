import generateSubappConfig from '../_app/generateSubappConfig';
import CreditPurchasing from './index';
import { APP_ROUTES } from '../_app/constants';

const config = generateSubappConfig({
  path: APP_ROUTES.credits.index,
  isLive: true,
  navItemConfig: {
    label: 'Credits',
  },
  appConfig: {
    Component: CreditPurchasing,
  },
});

export default config;
