import generateSubappConfig from '../_app/generateSubappConfig';
import StaffManagement from './StaffManagement';
import { APP_ROUTES } from '../_app/constants';

const staffManagementSubappConfig = generateSubappConfig({
  path: APP_ROUTES.staff,
  navItemConfig: {
    label: 'Staff',
  },
  appConfig: {
    Component: StaffManagement,
  },
});

export default staffManagementSubappConfig;
