
import * as React from 'react';
import gql from 'graphql-tag';
import { css } from 'react-emotion';
import { colors, elements, components } from '@peachjar/components';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles
} from '@material-ui/core';

const { Headline2, Paragraph } = elements.typography;
const { MaterialIcon } = elements;
const { ButtonSecondaryLarge } = components.Buttons;

// TODO: add to helper functions to help with accessibility rules
const onEnter = (
  callback: Function
): ((e: SyntheticKeyboardEvent<HTMLElement>) => void) => e => {
  if (e.key === 'Enter') {
    callback();
  }
};

type Props = {
   closeModal: () => void,
   flyer: any,
   classes: { paper: any, root: any },
   previewFlyerURL: string,
};

const FlyerDetailLightBoxModal = ({ flyer, closeModal, classes, previewFlyerURL }: Props) => (
  <Dialog open onBackdropClick={closeModal} classes={{ paper: classes.paper }}>
    <DialogTitle classes={{ root: classes.root }}>
      <div className="d-flex justify-content-between">
        <Headline2 bold className={classNames.header}>
          {flyer.title}
        </Headline2>
        <IconButton
          aria-label="Close flyer details"
          onClick={closeModal}
          onKeyPress={onEnter(closeModal)}
          data-testid="click-close-flyerDetailLightboxModal"
        >
          <MaterialIcon name="close" className={classNames.closeIcon} />
        </IconButton>
      </div>
    </DialogTitle>
    <DialogContent>
      {flyer.districtPages.map(({ id, cdnImageUrl, ocrText, pageNumber }) => (
        <div className="layout-row mb-4" key={id}>
          <div className="flex-66 pr-2 pt-4">
            <div className={classNames.borderBox}>
              <img
                src={cdnImageUrl}
                className={classNames.fillBox}
                alt={`Page ${pageNumber} of flyer titled: ${flyer.title}.`}
              />
            </div>
          </div>
          <div className="flex-33 pl-2 pt-4">
            <div className={classNames.borderBox}>
              <div className={classNames.fillBox}>
                <Paragraph>{ocrText}</Paragraph>
              </div>
            </div>
          </div>
        </div>
      ))}
    </DialogContent>
    <div className={classNames.ctas}>
      <p className={classNames.kicker}>Review Flyer Posting and Call-to-Actions</p>
      <ButtonSecondaryLarge
        color="default"
        data-testid="click-preview-flyer"
        href={previewFlyerURL}
        target="_blank"
        className={classNames.previewCTA}
      >
        Preview Flyer
            </ButtonSecondaryLarge>
    </div>
  </Dialog>
);

FlyerDetailLightBoxModal.fragments = {
  flyer: gql`
    fragment FlyerDetailLightBoxModal on Flyer {
      title
      districtPages {
        id
        pageNumber
        cdnImageUrl
        ocrText
      }
    }
  `,
};

const classNames = {
  borderBox: css`
    border: 1px solid ${colors.silver};
    border-radius: 6px;
    padding: 2rem;
    height: 100%;
    width: 100%;
  `,
  fillBox: css`
    max-width: 100%;
    max-height: 100%;
  `,
  header: css`
    margin: auto 0;
  `,
  pointer: css`
    cursor: pointer;
  `,
  closeIcon: css`
    line-height: 1;
    height: 1rem;
  `,
  ctas: css`
    padding: 20px 0 31px 24px;
    box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
  `,
  kicker: css`
    font-size: 1.2rem;
  `,
  previewCTA: css`
    &:hover {
      color: ${colors.jungle}
    }
  `
};

const styles = {
  root: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    zIndex: '1'
  },
  paper: {
    minWidth: '90%',
  },
};

export default withStyles(styles)(FlyerDetailLightBoxModal);
