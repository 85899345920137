import { Switch, Route } from 'react-router-dom';
import { APP_ROUTES } from '../_app/constants';

import React, { FunctionComponent } from 'react';
import PurchaseCreditsPage from './containers/PurchaseCreditsPage';
import StripePurchaseCancelledPage from './containers/StripePurchaseCancelledPage';
import StripePurchaseCompletedPage from './containers/StripePurchaseCompletedPage';
import PurchaseHistoryPage from './containers/PurchaseHistoryPage';

const CreditPurchasingIndex: FunctionComponent = () => {
  return (
    <Switch>
      <Route
        path={APP_ROUTES.credits.cancelled}
        render={() => <StripePurchaseCancelledPage />}
      />
      <Route
        path={APP_ROUTES.credits.completed}
        render={() => <StripePurchaseCompletedPage />}
      />
      <Route
        path={APP_ROUTES.credits.history}
        render={() => <PurchaseHistoryPage />}
      />
      <Route
        path={APP_ROUTES.credits.index}
        render={() => <PurchaseCreditsPage />}
      />
    </Switch>
  );
}

export default CreditPurchasingIndex;
