import React, { Component, useEffect, useState } from 'react';
import { some } from 'lodash';
import { css } from 'react-emotion';
import { colors, elements } from '@peachjar/components';
import FormHelperText from '@material-ui/core/FormHelperText';
import OcrPagesFragments from './gql/OcrPagesFragments.graphql';
import OcrEditFieldFragments from './gql/OcrEditFieldFragments.graphql';

const { mercury, leaf, dragon, slate } = colors;
const { Note } = elements.typography;

const styles = {
  ocrEditBox: css`
    min-height: 56px;
    padding: 1.5rem;
    margin-right: 2rem;
    border: 1px solid ${mercury};
    border-radius: 7px;
    resize: none;
    white-space: pre-line;

    &:focus {
      border: 2px solid ${leaf};
      outline: none;
    }
  `,
  errorEditBox: css`
    min-height: 56px;
    padding: 1.5rem;
    margin-left: 2rem;
    border: 1px solid ${dragon};
    border-radius: 7px;
    resize: none;

    &:focus {
      border: 2px solid ${dragon};
      outline: none;
    }
  `,
};

type Props = {
  index: number;
  userText: string;
  setUserTextEditsState: (number, string) => void;
  userTextEdit: string;
};

export const OcrEditField = ({
  index,
  userText,
  setUserTextEditsState,
  userTextEdit,
}: Props) => {
  const [error, setError] = useState(false);
  const [messageText, setMessageText] = useState(
    'Maximum length 4000 characters'
  );

  useEffect(() => {
    setUserTextEditsState(index, userText);
  }, []);

  useEffect(() => {
    setError(userTextEdit.length === 0);
    setMessageText(
      error ? 'Description required.' : 'Maximum length 4000 characters'
    );
  });

  const onChange = (e, ind) => {
    setUserTextEditsState(ind, e.target.innerText)
  }

  const formattedUserText = userText.replace(/[\n\r]/g, '<br />');

  return (
    <div className="flex-40 layout-column">
      <div
        contentEditable
        data-testid={`textareaOcrText${index}`}
        autoFocus={index === 0}
        className={`flex-auto ${
          error ? styles.errorEditBox : styles.ocrEditBox
        }`}

        // DIV does not support onChange!!
        onInput={(e) => onChange(e, index)}
        onBlur={(e) => onChange(e, index)}
        // onChange={e => setUserTextEditsState(index, e.target.value)}

        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: formattedUserText }}
      />
      <FormHelperText>
        <Note
          className={
            error
              ? css`
                   {
                    color: ${dragon};
                  }
                `
              : css`
                   {
                    color: ${slate};
                  }
                `
          }
        >
          {messageText}
        </Note>
      </FormHelperText>
      {/* <div className="ml-2">
        <Note muted>Maximum length 4000 characters</Note>
      </div> */}
    </div>
  );
};

OcrEditField.fragments = OcrEditFieldFragments;

export default OcrEditField;
