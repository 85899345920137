import React, { useState, useEffect as ue } from 'react';
import { TextInput, colors, elements, InfoTooltip } from '@peachjar/components';

import FormHelperText from '@material-ui/core/FormHelperText';
import { css } from 'react-emotion';
import withValidation from '../../../shared/components/withValidation';
import MessageToApproverFragments from './gql/MessageToApproverFragments.graphql';

import {
  MESSAGE_TO_APPROVER_MAX_LENGTH,
  MESSAGE_TO_APPROVER_MAX_LENGTH_ERROR_MESSAGE,
  tooltipCopy,
} from '../constants';

const { dragon } = colors;
const { Note, Headline2 } = elements.typography;

export const useEffectFactory = ({
  message,
  setHasMaxLengthError,
  shouldPerformDisabledClickValidation,
  performValidation,
}) => {
  const useEffectCallback = () => {
    performValidation({
      target: {
        value: {
          messageToApprover: message,
        },
      },
    });

    setHasMaxLengthError(message.length > MESSAGE_TO_APPROVER_MAX_LENGTH);

    if (shouldPerformDisabledClickValidation) {
      setHasMaxLengthError(message.length > MESSAGE_TO_APPROVER_MAX_LENGTH);
      // setOnBlurError(message.length === 0);
    }
  };
  return useEffectCallback;
};

export const MessageToApprover = React.memo(
  ({
    messageToApprover = '',
    setSummary,
    performValidation,
    shouldPerformDisabledClickValidation,
    className,
    useEffect = ue,
  }) => {
    const [message, setMessage] = useState(messageToApprover || '');
    const [hasMaxLengthError, setHasMaxLengthError] = useState(false);
    const [hasMessageBeenRestored, setHasMessageBeenRestored] = useState(false);

    useEffect(
      useEffectFactory({
        message,
        setHasMaxLengthError,
        shouldPerformDisabledClickValidation,
        performValidation,
      })
    );

    useEffect(() => {
      if (message === '' && messageToApprover && !hasMessageBeenRestored) {
        setMessage(messageToApprover);
        setHasMessageBeenRestored(true);
      }
    });

    return (
      <div className={`layout-row layout-align-start-end ${className}`}>
        <div className="flex-auto">
          <Headline2
            className={css`
              line-height: 1;
            `}
          >
            Message to Approver
          </Headline2>

          <TextInput
            className="mt-3"
            fullWidth
            multiline
            label="Message to Approver (optional)"
            name="messageToApprover"
            onChange={event => setMessage(event.target.value)}
            value={message}
            error={hasMaxLengthError}
            onBlur={async () => {
              await setSummary({
                variables: { name: 'messageToApprover', value: message },
              });
              // setOnBlurError(message.length === 0);
            }}
            data-testid="messageToApproverInput"
          />
          <FormHelperText style={{ textAlign: 'right' }}>
            <div
              className={`layout-row layout-align-${
                hasMaxLengthError ? 'space-between' : 'end'
              }`}
            >
              {hasMaxLengthError && (
                <Note
                  className={css`
                    {
                      color: ${dragon};
                    }
                  `}
                >
                  {MESSAGE_TO_APPROVER_MAX_LENGTH_ERROR_MESSAGE}
                </Note>
              )}
              <div>{`${
                message ? message.length : 0
              } / ${MESSAGE_TO_APPROVER_MAX_LENGTH}`}</div>
            </div>{' '}
          </FormHelperText>
        </div>
        <div className="flex-none">
          <InfoTooltip
            dataTestId="tooltip-messageToApprover"
            content={tooltipCopy.messageToApprover}
          />
        </div>
      </div>
    );
  }
);

MessageToApprover.fragments = MessageToApproverFragments;

export const validate = ({ messageToApprover }) =>
  messageToApprover
    ? messageToApprover.length <= MESSAGE_TO_APPROVER_MAX_LENGTH
    : true;
export default withValidation(MessageToApprover, validate, null);
