/* global window */

import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { css } from 'react-emotion';
import { GoogleMap, Marker } from '@react-google-maps/api';
import RedoSearchButton from './RedoSearchButton';
import {
  getCurrentBounds,
  getCurrentCenter,
  scrollToListItem,
  getZIndex,
} from '../../utils';
import {
  DEFAULT_MIN_ZOOM,
  DEFAULT_MAX_ZOOM,
  DEFAULT_MAX_LATITUDE,
  DEFAULT_MIN_LATITUDE,
  DEFAULT_MAX_LONGITUDE,
  DEFAULT_MIN_LONGITUDE,
  DEFAULT_MAP_STYLES,
} from '../constants';

const mapOptions = {
  styles: DEFAULT_MAP_STYLES,
  mapTypeControl: false,
  streetViewControl: false,
  minZoom: DEFAULT_MIN_ZOOM,
  maxZoom: DEFAULT_MAX_ZOOM,
  restriction: {
    latLngBounds: {
      north: DEFAULT_MAX_LATITUDE,
      south: DEFAULT_MIN_LATITUDE,
      west: DEFAULT_MIN_LONGITUDE,
      east: DEFAULT_MAX_LONGITUDE,
    },
    strictBounds: true,
  },
  scrollwheel: false,
};

const MemoizedMap = React.memo(
  ({
    map,
    schoolList,
    setMap,
    mapVariables,
    setMapVariables,
    center,
    currentCenter,
    setCurrentCenter,
    zoom,
    initialMapBounds,
    shouldUpdateCurrentBounds,
    setShouldUpdateCurrentBounds,
    areInitialMapBoundsSet,
    setInitialMapBounds,
    previousRedoSearchZoom,
    setPreviousRedoSearchZoom,
    setSearchValue,
    setInputValue,
    showRedoSearch,
    setShowRedoSearch,
  }) => {
    const [markerMap, setMarkerMap] = useState(new Map());

    const updateMarkerMap = (schoolId, markerInstance) =>
      setMarkerMap(new Map(markerMap.set(schoolId, markerInstance)));

    const onMarkerClick = school => {
      const { schoolId, isPin } = school;

      const list = document.getElementById('schoolList');
      const listItem = document.getElementById(schoolId);

      if (list && listItem) {
        scrollToListItem(list, listItem);
      }

      if (!isPin) {
        setMapVariables({
          center: getCurrentCenter(map),
          redoMapBounds: getCurrentBounds(map),
        });
        setSearchValue('Current Map Area');
        setInputValue('Current Map Area');
        setPreviousRedoSearchZoom(map.getZoom());

        // Remove redo search
        setShowRedoSearch(false);
      }
    };

    const onMarkerMouseOver = (mapControls, isPin, schoolId) => {
      const shouldDisplayDotHoverMessage = !isPin;
      const shouldHideRedoSearch =
        mapControls.length > 0 && !isPin && !!mapControls.i;

      if (shouldHideRedoSearch) mapControls.i[0].style.display = 'none';
      if (shouldDisplayDotHoverMessage) mapControls.push(controlDivDotMsg);

      // Enlarge icon size
      const hoveredMarker = markerMap.get(schoolId);
      const scaledIcon = {
        url: hoveredMarker.icon.url,
        scaledSize: isPin
          ? new google.maps.Size(36, 46)
          : new google.maps.Size(34, 34),
      };

      hoveredMarker.setIcon(scaledIcon);
    };

    const onMarkerMouseOut = (mapControls, isPin, schoolId) => {
      const shouldRemoveDotMarkerHoverMessage = !isPin;
      const shouldRestoreRedoSearch =
        showRedoSearch && !!mapControls.i && mapControls.i.length;

      if (shouldRemoveDotMarkerHoverMessage) mapControls.pop();
      if (shouldRestoreRedoSearch) mapControls.i[0].style.display = 'block';

      // Restore icon size
      const hoveredMarker = markerMap.get(schoolId);
      const scaledIcon = {
        url: hoveredMarker.icon.url,
        scaledSize: isPin
          ? new google.maps.Size(28, 36)
          : new google.maps.Size(28, 28),
      };

      hoveredMarker.setIcon(scaledIcon);
    };

    const PIN_AVAILABLE_ICON = {
      url: 'https://cdn-assets.peachjar.com/mapIcons/png/pin_available.png',
      origin: null,
      anchor: null,
      scaledSize: new google.maps.Size(28, 36),
    };

    const PIN_SELECTED_ICON = {
      url: 'https://cdn-assets.peachjar.com/mapIcons/png/pin_selected.png',
      scaledSize: new google.maps.Size(28, 36),
    };

    const DOT_AVAILABLE_ICON = {
      url: 'https://cdn-assets.peachjar.com/mapIcons/png/dot_available.png',
      scaledSize: new google.maps.Size(28, 28),
    };

    const DOT_SELECTED_ICON = {
      url: 'https://cdn-assets.peachjar.com/mapIcons/png/dot_selected.png',
      scaledSize: new google.maps.Size(28, 28),
    };

    const getIcon = (type, isPin) => {
      if (isPin === 1 && type === 'default') {
        return PIN_AVAILABLE_ICON;
      }

      if (isPin === 1 && type === 'selected') {
        return PIN_SELECTED_ICON;
      }

      if (type === 'default') {
        return DOT_AVAILABLE_ICON;
      }

      return DOT_SELECTED_ICON;
    };

    // Dot marker hover message control
    const DOT_PIN_HOVER_MESSAGE =
      'Click to list schools outside of your search';
    const position = window.google.maps.ControlPosition.TOP_CENTER;
    const controlDivDotMsg = document.createElement('div');
    const controlUI = document.createElement('div');
    const controlText = document.createElement('div');

    controlUI.className = styles.controlUi;
    controlDivDotMsg.appendChild(controlUI);

    controlText.className = styles.controlText;
    controlText.innerHTML = DOT_PIN_HOVER_MESSAGE;
    controlUI.appendChild(controlText);

    const controls = map && map.controls[position];

    return (
      <GoogleMap
        id="school-finder"
        mapContainerStyle={{
          height: '100%',
          width: '100%',
        }}
        zoom={zoom ? zoom : previousRedoSearchZoom}
        center={{
          lat: showRedoSearch
            ? currentCenter && currentCenter.latitude
            : center && center.latitude,
          lng: showRedoSearch
            ? currentCenter && currentCenter.longitude
            : center && center.longitude,
        }}
        options={mapOptions}
        onLoad={map => {
          setMap(map);
          setInitialMapBounds(getCurrentBounds(map));
          window.map = map;
        }}
        onDragStart={() => {
          if (!showRedoSearch) {
            setShowRedoSearch(true);
          }
        }}
        onDragEnd={() => setCurrentCenter(getCurrentCenter(map))}
        onTilesLoaded={() => {
          if (shouldUpdateCurrentBounds) {
            const currentBounds = getCurrentBounds(map);
            if (currentBounds) {
              setInitialMapBounds(currentBounds);
              setShouldUpdateCurrentBounds(false);
            }
          }
        }}
        onZoomChanged={() => areInitialMapBoundsSet && setShowRedoSearch(true)}
      >
        <RedoSearchButton
          map={map}
          showRedoSearch={showRedoSearch}
          setShowRedoSearch={setShowRedoSearch}
          setMapVariables={setMapVariables}
          getCurrentCenter={getCurrentCenter}
          currentCenter={currentCenter}
          getCurrentBounds={getCurrentBounds}
          previousRedoSearchZoom={previousRedoSearchZoom}
          setPreviousRedoSearchZoom={setPreviousRedoSearchZoom}
          setSearchValue={setSearchValue}
          setInputValue={setInputValue}
        />

        {areInitialMapBoundsSet &&
          schoolList.map(school => (
            <Marker
              key={school.schoolId}
              position={{ lat: school.latitude, lng: school.longitude }}
              onLoad={marker => updateMarkerMap(school.schoolId, marker)}
              icon={getIcon(school.type, school.isPin)}
              zIndex={getZIndex(school.type, school.isPin)}
              onClick={() => onMarkerClick(school)}
              onMouseOver={() =>
                onMarkerMouseOver(controls, school.isPin, school.schoolId)
              }
              onMouseOut={() =>
                onMarkerMouseOut(controls, school.isPin, school.schoolId)
              }
            />
          ))}
      </GoogleMap>
    );
  },
  // shouldSkipUpdate
  null
);

export default MemoizedMap;

// const shouldSkipUpdate = (prevProps, nextProps) => {
//   if (prevProps.foo !== nextProps.foo) {
//     return true;
//   } else {
//     return false;
//   }
// };

const styles = {
  controlUi: css`
    pointer-events: none !important;
    background-color: #969696;
    border: 1px solid #969696;
    padding: 0.5rem 1rem;
    height: 32px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 22px;
    text-align: center;
    margin-top: 1rem;
    z-index: -999;
  `,
  controlText: css`
    pointer-events: none;
    color: #fff;
    font-family: 'Proxima Nova', Arial, sans-serif;
    line-height: 16px;
    font-size: 13px;
  `,
};
