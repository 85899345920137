import React from 'react';
import config from '../../config';

const { REACT_APP_REPORTING_URL } = config;

const RedirectReporting = () => {
  React.useEffect(() => {
    window.location.href = REACT_APP_REPORTING_URL;
  }, []);

  return null;
};

export default RedirectReporting;
