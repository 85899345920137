
import * as React from 'react';
import portalBffApolloClient from '../../../../../../_app/apollo/portalBFF.apolloClient';
import SAVE_CAMPAIGN_MUTATION from '../../../../gql/SaveCampaignMutation.graphql';
import MutationButton from '../../../../../../components/MutationButton';
import { PREPARE_QUERY } from '../../../gql/Prepare.graphql';

export const update = (cache: Object, { data: { saveCampaign } }: Object) => {
  // overwrites our apollo state defaults to include the newly created draft campaign
  cache.writeQuery({
    query: PREPARE_QUERY,
    data: { campaignPrepare: saveCampaign },
  });
};

type Props = {
   flyerId: string,
   disabled: boolean,
   remoteCampaign: Record<string, any>,
   handleSuccess: (num: number) => void,
   children: React.ReactNode,
};

const SaveCampaignButton = ({ flyerId, remoteCampaign, ...rest }: Props) => {
  const variables = {
    campaignId: remoteCampaign.id,
    campaignPrepare: { flyerId },
  };

  return (
    <MutationButton
      client={portalBffApolloClient}
      mutation={SAVE_CAMPAIGN_MUTATION}
      variables={variables}
      update={update}
      // refetchQueries={() => ['getMyCampaigns']}
      {...rest}
    />
  );
};

export default SaveCampaignButton;
