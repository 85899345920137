import React from 'react';
import { addDays } from 'date-fns';
import UTCDatePicker from '../../../../shared/components/UTCDatePicker';

const CampaignStartDate = ({
  setDeliveryCache,
  campaignStartDate,
  numberOfDistributions,
  maxDate,
  onChange,
  onClose,
  error,
}) => (
  <>
    <UTCDatePicker
      name="campaignStartDate"
      label="Distribution Target Date"
      data-testid="campaignStartDateInput"
      value={campaignStartDate}
      maxDate={maxDate}
      error={error}
      onChange={date => {
        // Clear post expiration date after campaign start date update
        setDeliveryCache({
          variables: {
            name: 'postExpirationDate',
            value: null,
          },
        })
          .then(() =>
            setDeliveryCache({
              variables: { name: 'campaignStartDate', value: date },
            })
          )
          .then(() => onChange(date));
      }}
      onClose={onClose}
    />
  </>
);

export default CampaignStartDate;
