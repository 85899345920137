import gql from 'graphql-tag';

// Defaults

export const prepareDefaults = {
  campaignPrepare: {
    __typename: 'CampaignPrepare',
    campaignId: '',
    isSubmitted: false,
    flyer: {
      id: '',
      __typename: 'CampaignFlyer',
      pdfUrl: '',
      flyerPages: {
        id: '',
        __typename: 'CampaignFlyerPage',
        jpegUrl: '',
        userText: '',
      },
    },
    flyerId: '',
    userTexts: [],
  },
};

// Queries
/* eslint-disable */
export const PREPARE_QUERY = gql`
  query campaignPrepare {
    campaignPrepare @client {
      campaignId
      isSubmitted
      flyer {
        id
        pdfUrl
        flyerPages {
          id
          jpegUrl
          userText
        }
      }
      flyerId
      userTexts
    }
  }
`;

export const SET_FLYER_ID_MUTATION = gql`
  mutation setFlyerId($flyerId: String!) {
    setFlyerId(flyerId: $flyerId) @client
  }
`;

export const REHYDRATE_PREPARE_CACHE = gql`
  mutation rehydratePrepareCache(
    $campaignId: String
    $flyerId: String
    $userTexts: [String]
  ) {
    rehydratePrepareCache(
      campaignId: $campaignId
      flyerId: $flyerId
      userTexts: $userTexts
    ) @client
  }
`;

export const SET_USER_TEXTS_MUTATION = gql`
  mutation setUserTexts($userTexts: [String]) {
    setUserTexts(userTexts: $userTexts) @client
  }
`;
/* eslint-enable */

// Trigger Functions

export const setFlyerId = (_obj, { flyerId }, { cache }) => {
  cache.writeQuery({
    query: PREPARE_QUERY,
    data: {
      campaignPrepare: {
        __typename: 'CampaignPrepare',
        flyerId,
      },
    },
  });
  return null;
};

export const rehydratePrepareCache = (
  _obj,
  { campaignId, flyerId, userTexts },
  { cache }
) => {
  cache.writeQuery({
    query: PREPARE_QUERY,
    data: {
      campaignPrepare: {
        __typename: 'CampaignPrepareRehydrate',
        campaignId,
        flyerId,
        userTexts,
      },
    },
  });
  return null;
};

export const setUserTexts = (_obj, { userTexts }, { cache }) => {
  cache.writeQuery({
    query: PREPARE_QUERY,
    data: {
      campaignPrepare: {
        __typename: 'CampaignPrepare',
        userTexts,
      },
    },
  });
  return null;
};

// Resolvers

export const prepareResolvers = {
  setFlyerId,
  rehydratePrepareCache,
  setUserTexts,
};
