import { graphql, compose } from 'react-apollo';
import {
  DELIVERY_QUERY,
  SET_DELIVERY_CACHE_MUTATION,
  HANDLE_CAMPAIGN_DELIVERY_FORM_VALIDATION_MUTATION,
  TOGGLE_DISTRICT_GUIDELINES_CONFIRMED_MUTATION,
} from './gql/Delivery.graphql';
import { DETAILS_QUERY } from '../Details/gql/Details.graphql';
import Delivery from './Delivery';
import { isEmpty, omit, get } from 'lodash';

export const deliveryConfig = {
  props: ({
    ownProps,
    data: {
      loading,
      campaignDelivery: {
        minGradeLevel,
        maxGradeLevel,
        selectedAudience,
        checkboxSelections,
        areDistrictGuidelinesConfirmed,
        campaignStartDate,
        numberOfDistributions,
        postExpirationDate,
      },
    },
  }: any) => {
    // this(district omission) is due to apollo link state not allowing district
    // within checkboxSelections to be null for some reason.
    // If you can find a way to fix this, kudos.
    const cleanedCheckboxSelections = checkboxSelections.map(
      checkboxSelection => {
        const district = get(checkboxSelection, 'district');
        return typeof district === 'object' && isEmpty(district)
          ? omit(checkboxSelection, 'district')
          : checkboxSelection;
      }
    );

    return {
      ...ownProps,
      loadingDelivery: loading,
      minGradeLevel,
      maxGradeLevel,
      selectedAudience,
      checkboxSelections: cleanedCheckboxSelections,
      areDistrictGuidelinesConfirmed,
      campaignStartDate,
      numberOfDistributions,
      postExpirationDate,
    };
  },
};

export const detailsConfig = {
  props: ({
    ownProps,
    data: {
      campaignDetails: { eventLocation },
    },
  }) => ({
    ...ownProps,
    eventLocation,
  }),
};

export default compose(
  graphql(DELIVERY_QUERY, deliveryConfig),
  graphql(DETAILS_QUERY, detailsConfig),
  graphql(SET_DELIVERY_CACHE_MUTATION, { name: 'setDeliveryCache' }),
  graphql(TOGGLE_DISTRICT_GUIDELINES_CONFIRMED_MUTATION, {
    name: 'toggleDistrictGuidelinesConfirmed',
  }),
  graphql(HANDLE_CAMPAIGN_DELIVERY_FORM_VALIDATION_MUTATION, {
    name: 'handleCampaignDeliveryFormValidation',
  })
)(Delivery);
