import generateSubappConfig from '../_app/generateSubappConfig';
import { APP_ROUTES } from '../_app/constants';
import { dealsAllowedRoles } from '../_app/RolesEnum';
import RedirectDeals from '../_app/components/RedirectDeals';

const getSubappConfig = orgUploaderFlag =>
  generateSubappConfig({
    path: APP_ROUTES.deals,
    isLive: orgUploaderFlag === 1,
    allowedRoles: dealsAllowedRoles,
    navItemConfig: {
      label: 'Deals',
    },
    appConfig: {
      Component: RedirectDeals,
      fragmentOnUser: {},
    },
  });

export default getSubappConfig;
