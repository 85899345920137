import React from 'react';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { components } from '@peachjar/components';
import { useQuery as uq } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Paper } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import { apolloClient as portalBffClient } from '../../_app/apollo/portalBFF.apolloClient';
import SubappHeader from '../../_app/components/SubappHeader';
import MiniBreadcrumb from '../../components/MiniBreadcrumb';
import ReceiptHeader from '../components/ReceiptHeader';
import OrderSummary from '../../_app/components/OrderSummary';
import FullPageSpinner from '../../_app/components/FullPageSpinner';

const {
  Buttons: { ButtonSecondarySmall },
} = components;

type Props = {
  match: RouteComponentProps<TParams>;
  location: RouteComponentProps<TParams>;
  history: RouteComponentProps<TParams>;
  styles: { [k: any]: any };
  useQuery: () => any;
};

const Receipt = ({
  match,
  location,
  history,
  styles,
  useQuery = uq,
}: Props) => {
  // Passed as url param for getPurchaseOrder query
  const { purchaseId } = match.params;

  const { data, loading, error } = useQuery(GET_RECEIPT, {
    variables: { purchaseId },
    client: portalBffClient,
    fetchPolicy: 'no-cache',
  });

  if (error) {
    history.push('/account/transactions');
  }

  let quote, companyName, transactionId, transactionDate, paymentType, cardDetails, cardBrand, cardLastFour;

  if (!loading && data) {
    const { getPurchaseOrder } = data;

    quote = getPurchaseOrder.quote;
    companyName = getPurchaseOrder.organizationInfo.companyName;
    transactionId = getPurchaseOrder.creditPurchaseTransaction.id;
    transactionDate = getPurchaseOrder.creditPurchaseTransaction.timestamp;
    paymentType = getPurchaseOrder.quote.purchaseDetails.payment.type;
    cardDetails = paymentType === 'stripe' ? getPurchaseOrder.creditPurchase.paymentDetails.card || null : null;
    cardBrand = cardDetails ? cardDetails.brand : null;
    cardLastFour = cardDetails ? cardDetails.last4 : null;
  }

  if (loading) return <FullPageSpinner />;
  return (
    <>
      <MiniBreadcrumb
        linkTo="/account/transactions"
        text="Transaction History"
      />
      <div className={styles.subAppHeaderWrapperWithPrintButton}>
        <SubappHeader className={styles.subAppHeaderWithBreadcrumb}>
          Credit Purchase Receipt
        </SubappHeader>
        <ReactToPrint
          trigger={() => (
            <ButtonSecondarySmall>Print Receipt</ButtonSecondarySmall>
          )}
          content={() => document.getElementById('receipt')}
        />
      </div>
      <Paper elevation={0} className={styles.paperMargin}>
        <div className={styles.subAppContainerReceiptVariant} id="receipt">
          <ReceiptHeader
            companyName={companyName}
            transactionId={transactionId}
            transactionDate={transactionDate}
            paymentType={paymentType}
            cardBrand={cardBrand}
            cardLastFour={cardLastFour}
            styles={styles}
          />
          <OrderSummary variant="receipt" quote={quote} />
        </div>
      </Paper>
    </>
  );
};

const GET_RECEIPT = gql`
  query getReceipt($purchaseId: String!) {
    getPurchaseOrder(purchaseId: $purchaseId) {
      id
      purchaserId
      organizationInfo {
        companyName
      }
      creditPurchase {
        paymentDetails {
        ...on StripeDetails {
            id
            card {
              brand
              last4
            }
          }
         ...on PaypalDetails {
            id
            payer {
              id
              email
            }
          }
        }
      }
      creditPurchaseTransaction {
        id
        transactionGroupId
        timestamp
      }
      quote
    }
  }
`;

export default Receipt;
