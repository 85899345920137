import React from 'react';
import { css } from 'react-emotion';
import FormHelperText from '@material-ui/core/FormHelperText';
import { colors, elements } from '@peachjar/components';

const { Paragraph, Note } = elements.typography;

const styles = {
  container: css`
    border: 1px solid ${colors.silver};
    border-radius: 3px;
    padding: 1rem 2.25rem;
  `,
  link: css`
    cursor: pointer;
  `,
  errorBorder: css`
    border-color: ${colors.dragon};
  `,
  errorText: css`
    color: ${colors.dragon};
  `
};

const DistrictGuidelineLinks = ({
  uniqueSelectionsWithGuidelines,
  guidelinesErrorText,
  className,
}) => (
  <>
    <div
      className={`${styles.container} ${!!guidelinesErrorText && styles.errorBorder} layout-row layout-align-start layout-wrap ${className}`}
    >
      {uniqueSelectionsWithGuidelines.map((selection, i) => (
        <Paragraph link className={`${styles.link} flex-none`}>
          <a href={decodeURIComponent(selection.district.url)} target="_blank">
            {selection.district.name}
          </a>
          {i !== uniqueSelectionsWithGuidelines.length - 1 ? (
            <span className="pr-2">,</span>
          ) : null}
        </Paragraph>
      ))}
    </div>

    {guidelinesErrorText && (
      <FormHelperText>
        <Note className={styles.errorText}>{guidelinesErrorText}</Note>
      </FormHelperText>
    )}
  </>
);

export default DistrictGuidelineLinks;
