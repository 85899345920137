import React, { Component, FormEvent } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { css } from "emotion";
import { CreditPurchaseDetails, StripePurchaseAuthorization } from "../CommonModels";
import { connect } from 'react-redux';
import { checkoutWithStripe, CodedError, CreditPurchasingState, RetrievalStates } from "../credits-purchasing.duck";
import { ThunkDispatch } from "redux-thunk";

// classes
const cn = {
  root: css`
    padding: 1rem;
    overflowX: 'auto',
  `,
  cardElementContainer: css`
    margin: 20px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  `,
};

type Props = {
  retrievalState: RetrievalStates,
  error: null | CodedError,
  details: CreditPurchaseDetails,
  redirectOnSuccess: string,
  redirectOnCancel: string,
  executeCheckout: (authorization: StripePurchaseAuthorization) => void,
};

export class StripeCreditCheckout extends Component<Props> {

  async beginCheckout(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { executeCheckout, details, redirectOnSuccess, redirectOnCancel } = this.props;
    executeCheckout({
      details,
      redirectOnSuccess,
      redirectOnCancel,
    });
  }

  getComponentForRetrievalState() {
    const { retrievalState } = this.props;
    switch (retrievalState) {
      case RetrievalStates.Errored: return this.displayError();
      case RetrievalStates.Retrieving: return this.retrieving();
      default: return this.showButton();
    }
  }

  displayError() {
    const { error } = this.props;
    return <div>Error: {error!.message}</div>;
  }

  retrieving() {
    return <div>Checking out...</div>;
  }

  showButton() {
    return <Button
      variant="contained"
      color="primary"
      type="submit"
      onClick={(e: any) => this.beginCheckout(e as FormEvent<HTMLFormElement>)}
    >Checkout with Stripe
    </Button>;
  }

  render() {
    return (
      <Grid container className={`${cn.root}`}>
        <Grid item xs={12}>
          <Grid container>
            <Grid container justify="center">
              {this.getComponentForRetrievalState()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

type RootState = {
  creditPurchasing: CreditPurchasingState,
  [k: string]: any,
};

const mapStateToProps = (state: RootState) => ({
  error: state.creditPurchasing.checkoutFulfilledError,
  retrievalState: state.creditPurchasing.checkoutFulfilled,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  executeCheckout: (authorization: StripePurchaseAuthorization) =>
      dispatch(checkoutWithStripe(authorization))
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeCreditCheckout);
