import React, { useState } from 'react';
import { css } from 'emotion';
import idx from 'idx';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { colors, components } from '@peachjar/components';
import closeIcon from '../../assets/close-icon.svg';
import desktopSVG from '../../assets/icon-desktop-decorative.svg';

const {
  Buttons: { ButtonSecondarySmall },
} = components;
const MAX_CHEAT_SHEET_DISPLAYS = 5;
const MIN_CHEAT_SHEET_HOURS = 24;

const metricsContent = [
  {
    label: 'Deliveries',
    text: 'number of emails delivered to a valid inbox.',
  },
  {
    label: 'Impressions',
    text: 'number of emails opened and instances the flyer was presented on web flyerboards.',
  },
  {
    label: 'Views',
    text: 'number of instances the full flyer webpage was viewed from emails and web flyerboards.',
  },
  {
    label: 'Actions',
    text: 'number of actions taken from emails and web flyerboards such as Sign Up, Share, Add To Calendar, etc.',
  },
];

export const getCheatSheetStorage = (): object => {
  const storage = JSON.parse(localStorage.getItem('reportingCheatSheet'));
  const dismissCount = idx(storage, _ => _.dismissCount) || null;
  const lastDismissDate = idx(storage, _ => _.lastDismissDate) || null;

  return {
    dismissCount,
    lastDismissDate,
  };
};

export const setCheatSheetStorage = () => {
  const { dismissCount } = getCheatSheetStorage();
  const count = dismissCount ? dismissCount + 1 : 1;
  localStorage.setItem(
    'reportingCheatSheet',
    JSON.stringify({ dismissCount: count, lastDismissDate: Date.now() })
  );
};

export const shouldCheatSheetRender = (setState: Function): void => {
  const { dismissCount, lastDismissDate } = getCheatSheetStorage();
  if (!dismissCount || !lastDismissDate) {
    setState(true);
  }

  const hoursSinceLastDismiss =
    Math.abs(Date.now() - lastDismissDate) / (60 * 60 * 1000);
  if (
    dismissCount >= MAX_CHEAT_SHEET_DISPLAYS ||
    hoursSinceLastDismiss < MIN_CHEAT_SHEET_HOURS
  ) {
    setState(false);
  }
};

const MetricsCheatSheet = ({ onCloseCheatSheet, classes }) => {
  return (
    <Paper className={classes.elevation2}>
      <div className={cn.cheatSheet}>
        <div className={cn.iconContainer}>
          <img src={desktopSVG} alt="" height="56" width="56" />
        </div>
        <div className={cn.content}>
          <div className={cn.contentListContainer}>
            <p className={cn.contentKicker}>
              The following cheat sheet provides definitions of the metrics.
            </p>
            <ul className={cn.contentList}>
              {metricsContent.map((metric, idx) => {
                const { label, text } = metric;
                return (
                  <li className={cn.listItem} key={idx}>
                    <span className={cn.emph}>{`${label} - `}</span>
                    {text}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={cn.cta}>
            <img
              className={cn.ctaDismiss}
              src={closeIcon}
              width="20"
              height="20"
              onClick={onCloseCheatSheet}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
};

//material overrides
const styles = {
  elevation2: {
    boxShadow: 'none',
  },
};

const cn = {
  cheatSheet: css`
    border-top: 3px solid #61c16c;
    display: flex;
    flex-direction: row;
    padding: 20px 15px 24px 15px;
  `,
  iconContainer: css`
    width: 60px;
    text-align: center;
  `,
  contentList: css`
    padding-left: 20px;
  `,
  cta: css`
    position: absolute;
    top: 0;
    right: 8px;
    height: 20px;
    width: 20px;
  `,
  ctaDismiss: css`
    cursor: pointer;
  `,
  emph: css`
    font-weight: 600;
  `,
  contentKicker: css`
    margin-bottom: 0.55rem;
  `,
  content: css`
    width: 95%;
    position: relative;
    padding-left: 12px;
  `,
  contentListContainer: css``,
  listItem: css``,
};

export default withStyles(styles)(MetricsCheatSheet);
