import React, { useState } from 'react';
import PlacesAutocompleteSelect from '../../../../../shared/components/PlacesAutocompleteSelect';

import EventLocationFragments from './gql/EventLocationFragments.graphql';

const EventLocation = ({ eventLocation, setDetails }) => {
  const [locationInputState, setLocationInputState] = useState('');
  return (
    <PlacesAutocompleteSelect
      label="Street Address (optional)"
      value={locationInputState}
      defaultValue={eventLocation}
      name="eventLocation"
      placeholder="e.g. 123 Main St. Springfield, VA"
      onChange={location => {
        setLocationInputState(location);
      }}
      onSelect={location => {
        setDetails({
          variables: { name: 'eventLocation', value: location },
        });
      }}
      googleCallbackName="willCrashWithoutThisInIE11"
    />
  );
};

EventLocation.fragments = EventLocationFragments;

export default EventLocation;
