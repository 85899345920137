import React, { useState } from 'react';
import SearchBar from './SearchBar';
import SearchSummaryBar from './SearchSummaryBar';
import { Geocode } from '../../types';
import { AudienceFilterType } from '../Filters/AudienceFilter';
import { GradeFilterType } from '../Filters/GradeFilter';

type Props = {
  inputValue: string,
  formattedAddress: string,
  total: number,
  searchValue: string,
  searchByValue: string,
  placeId: string,
  setInputValue: (value: string) => void,
  setSearchValue: (searchValue: string) => void,
  setSearchByValue: (searchyByValue: string) => void,
  setPlaceId: (placeId: string) => void,
  setMapVariables: (mapVariables: any) => void,
  gradeFilter: GradeFilterType,
  audienceFilter: AudienceFilterType,
  setGradeFilter: (filter: AudienceFilterType) => void;
  setAudienceFilter: (filter: GradeFilterType) => void;
  map: any,
  center: Geocode,
};

const Search = ({
  inputValue,
  setInputValue,
  total,
  setMapVariables,
  formattedAddress,
  setSearchByValue,
  searchValue,
  searchByValue,
  placeId,
  setSearchValue,
  setPlaceId,
  gradeFilter,
  audienceFilter,
  setAudienceFilter,
  setGradeFilter,
  map,
  center,
}: Props) => {
  return (
    <>
      <SearchBar
        inputValue={inputValue}
        setInputValue={setInputValue}
        setSearchByValue={setSearchByValue}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        placeId={placeId}
        searchByValue={searchByValue}
        setPlaceId={setPlaceId}
        gradeFilter={gradeFilter}
        audienceFilter={audienceFilter}
        setGradeFilter={setGradeFilter}
        setAudienceFilter={setAudienceFilter}
        setMapVariables={setMapVariables}
        formattedAddress={formattedAddress}
        center={center}
        map={map}
      />
      {/* Hiding for now, will adjust in later tickets */}
      {/* <SearchSummaryBar
        resultsCount={total}
        formattedAddress={formattedAddress}
        inputValue={inputValue}
        setInputValue={setInputValue}
      /> */}
    </>
  );
};

export default Search;
