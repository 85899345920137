import React, { useContext, useEffect, useState } from 'react';
import idx from 'idx';
import queryString from 'query-string';
import gql from 'graphql-tag';
import { css } from 'emotion';
import Grid from '@material-ui/core/Grid';
import { components, colors } from '@peachjar/components';
import SubappHeader from '../_app/components/SubappHeader';
import ReportingFlyersContent from './ReportingFlyersContent';
import { APP_ROUTES } from '../_app/constants';
import { redirect } from '../_app/redirect';
import AppDrawerContext from '../_app/context/AppDrawerContext';
import {
  setCheatSheetStorage,
  shouldCheatSheetRender,
} from './components/MetricsCheatSheet';
import { QUERY_MAPPING } from '../_app/constants';

const {
  Buttons: { ButtonSecondarySmall },
} = components;

interface Props {
  sodUser: object;
  gqlStuff: object;
  location: object;
  history: object;
}

const AllFlyers = ({ sodUser, gqlStuff, location, history }: Props) => {
  const [cheatSheetOpen, setCheatSheetState] = useState(true);
  const { setDrawerContext, toggleDrawer } = useContext(AppDrawerContext);
  const adminLevel = idx(sodUser, _ => _.permission.adminLevel) || null;
  const isAdmin = adminLevel === 'district' || adminLevel === 'school';

  useEffect(() => {
    checkUserPermissions();
    shouldCheatSheetRender(setCheatSheetState);
    setDrawerContext('FlyerDetails'); //define component to render inside AppDrawer
    checkDrawerDeepLink();
  }, []);

  // TODO: move to another file and import
  const checkDrawerDeepLink = () => {
    if (
      location &&
      location.hasOwnProperty('search') &&
      location.search !== ''
    ) {
      const parsedQueries = queryString.parse(location.search);
      if (
        parsedQueries[QUERY_MAPPING.flyerDetails] &&
        parsedQueries[QUERY_MAPPING.flyerDetails] === 'true' &&
        parsedQueries[QUERY_MAPPING.flyerDetailsId]
      ) {
        toggleDrawer({ flyerId: parsedQueries[QUERY_MAPPING.flyerDetailsId] });
      }
    }
  };

  const checkUserPermissions = (): void => {
    if (!isAdmin) {
      redirect(APP_ROUTES.reporting.myFlyers);
    }
  };

  const handleCheatSheetUpdate = (): void => {
    setCheatSheetStorage();
    toggleCheatSheet();
  };

  const toggleCheatSheet = (): void => {
    setCheatSheetState(!cheatSheetOpen);
  };

  const updateQueryVariables = (updates: object): void => {
    const { offset, filter } = updates;

    const {
      Reporting_sodUser_offset,
      Reporting_sodUser_filter,
    } = gqlStuff.currentQueryVariables;
    const newVariables = {
      Reporting_sodUser_offset:
        offset === undefined ? Reporting_sodUser_offset : offset,
      Reporting_sodUser_filter: filter || null,
    };

    gqlStuff.updateQueryVariables({
      ...gqlStuff.currentQueryVariables,
      ...newVariables,
    });
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <div className="view-reporting">
      <Grid container spacing={24}>
        <Grid item xs={12} className={cn.headerContainer}>
          <SubappHeader className={cn.subAppHeader}>
            All Flyer Reporting
          </SubappHeader>
          <div className={cn.cheatSheetLink}>
            <a href="javascript:void(0);" onClick={toggleCheatSheet}>
              <span className={cn.iIconContainer}>
                <span className={cn.iIcon}>i</span>
              </span>
              Reporting Cheat Sheet
            </a>
          </div>
          {isAdmin && (
            <div className={cn.toggles}>
              <ButtonSecondarySmall
                data-testid="click-toggle-my-flyers"
                onClick={() => redirect(APP_ROUTES.reporting.myFlyers)}
                className={cn.noBorderRadiusRight}
              >
                My Flyers
              </ButtonSecondarySmall>
              <ButtonSecondarySmall
                data-testid="click-toggle-all-flyers"
                disabled={true}
                className={cn.noBorderRadiusLeft}
              >
                All Flyers
              </ButtonSecondarySmall>
            </div>
          )}
        </Grid>
        <ReportingFlyersContent
          sodUser={sodUser}
          activeFlyerSet="allFlyers"
          offset={gqlStuff.currentQueryVariables.Reporting_sodUser_offset || 0}
          updateQueryVariables={updateQueryVariables}
          loading={gqlStuff.apolloHelpers.loading}
          cheatSheetOpen={cheatSheetOpen}
          onCloseCheatSheet={handleCheatSheetUpdate}
          history={history}
        />
      </Grid>
    </div>
  );
};

const cn = {
  headerContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  subAppHeader: css`
    width: 63%;
    padding-bottom: 1rem;
  `,
  toggles: css`
    margin-top: 2rem;
  `,
  noBorderRadiusRight: css`
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  `,
  noBorderRadiusLeft: css`
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  `,
  iIconContainer: css`
    border: 1px solid ${colors.jungle};
    border-radius: 12px;
    height: 16px;
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    padding: 0 5px;
    margin-right: 5px;
  `,
  iIcon: css`
    margin-top: -3px;
    color: ${colors.jungle};
    height: 18px;
    display: inline-block;
    width: 18px;
    vertical-align: top;
    font-size: 13px;
  `,
  cheatSheetLink: css`
    margin-top: 2rem;

    > a {
      color: ${colors.jungle};
      &:hover {
        text-decoration: none;
      }
    }
  `,
};

AllFlyers.fragments = {
  sodUser: gql`
    fragment Reporting_sodUser on Profile {
      userId
      permission {
        adminLevel
        approverLevel
        uploaderLevel
      }
      flyerReporting {
        allFlyers(
          limit: $Reporting_sodUser_limit
          offset: $Reporting_sodUser_offset
          filter: $Reporting_sodUser_filter
        ) {
          items {
            flyerId
            thumbnailUrl
            uniqueDeliveries
            uniqueDisplays
            uniqueActions
            uniqueViews
            distributionStatus
            distributions
            distributionsFirstDate
            distributionsSchoolCount
            expirationDate
            removalDate
            title
            type
          }
          pagination {
            total
          }
        }
        myFlyers(
          limit: $Reporting_sodUser_limit
          offset: $Reporting_sodUser_offset
          filter: $Reporting_sodUser_filter
        ) {
          items {
            flyerId
            thumbnailUrl
            uniqueDeliveries
            uniqueDisplays
            uniqueActions
            uniqueViews
            distributionStatus
            distributions
            submittedDate
            distributionsFirstDate
            distributionsSchoolCount
            expirationDate
            removalDate
            title
            type
          }
          pagination {
            total
          }
        }
      }
    }
  `,
};

export default AllFlyers;
