import React from 'react';
import { css } from 'emotion';
import colors from '@peachjar/ui/dist/lib/styles/colors';

// common styles for account info and org settings
const AccountInfoStyles = {
  bottomButtonContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 250px;
  `,
  bottomSubContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
  button: css`
    display: flex;
    align-self: flex-start;
  `,
  cellIcon: css`
    height: 12px;
    width: 12px;
    cursor: pointer;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    box-shadow: none;
    padding: 64px 64px 64px 64px;
    margin-bottom: 64px;
    border-radius: 3px;
  `,
  errorText: css`
    color: ${colors.dragon};
  `,
  fieldContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
  guideContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 656px;
  `,
  iconButtonRight: css`
    position: absolute !important;
    right: 0px;
    top: 5px;
  `,
  letterDetFileDiv: css`
    display: inline;
    width: 100%;
    color: ${colors.leaf};
    padding: 8px 8px 16px 8px;
  `,
  letterDetFileSVG: css`
    padding-right: 8px;
    margin-bottom: 8px;
  `,
  letterDetLink: css`
    color: ${colors.leaf};
    &:hover {
      color: ${colors.leaf};
      text-decoration: none;
    }
  `,
  letterDetButtonDiv: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  `,
  letterDetContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: space between;
    width: 440px;
    margin-bottom: 24px;
    margin-top: 0px;
  `,
  letterDetLabel: css`
    display: flex;
    flex-direction: row;
    align-content: center;
  `,
  nonProfit: css`
    flex-direction: column;
  `,
  paragraph: css`
    padding-bottom: 24px;
  `,
  resetContainer: css`
    margin-top: 10px;
  `,
  ripple: css`
    background-position: center;
    transition: background 0.8s;

    &:hover {
      padding: 12px;
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
      border-radius: 20px;
    }

    &:active {
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
    }
  `,
  saveButton: css`
    margin-left: 16px;
  `,
  subContainer: css`
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 80px;
  `,
  subLabel: css`
    color: ${colors.slate};
    display: flex;
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
  `,
};

export default AccountInfoStyles;
