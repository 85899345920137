import React from 'react';
import { css } from 'emotion';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import { colors, components } from '@peachjar/components';

const {
  Buttons: { ButtonSecondarySmall },
} = components;

type Props = {
  testId: string,
  inputRef: object,
  classes: object,
  value: string,
  placeholder: string,
  inputMaxLength?: string,
  onSearch: () => void,
  onChange: () => void,
  onKeyDown: () => number,
};

const Search = ({
  placeholder,
  testId,
  value,
  classes,
  onSearch,
  onChange,
  onKeyDown,
  inputRef,
  inputMaxLength = '128',
}: Props) => {
  return (
    <div className={classes.root}>
      <IconButton
        data-testid={`click-search-icon-${testId}`}
        aria-label="Search"
        disableRipple
        color="primary"
        classes={{ colorPrimary: classes.overrides }}
      >
        <SearchIcon />
      </IconButton>
      <Input
        id="search-input"
        inputRef={inputRef}
        value={value}
        placeholder={placeholder}
        className={classes.cssUnderline}
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputProps={{
          maxLength: inputMaxLength,
        }}
      />
      <ButtonSecondarySmall
        data-testid={`click-search-button-${testId}`}
        onClick={onSearch}
        className={cn.searchButton}
      >
        Search
      </ButtonSecondarySmall>
    </div>
  );
};

const cn = {
  textField: css`
    &:after {
      border-bottom: 2px solid ${colors.prussian} !important;
    }
  `,
  searchButton: css`
    margin-left: 8px !important;
  `,
};

//material overrides
const styles = {
  root: {
    display: 'flex',
    height: '36px',
    marginTop: '11px',
  },
  overrides: {
    color: colors.slate,
    padding: '7px',
    cursor: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cssUnderline: {
    width: '344px',
    '&:after': {
      borderBottomColor: colors.prussian,
    },
  },
};

export default withStyles(styles)(Search);
