import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import WrappedAutoCompleteSelect from './components/WrappedAutcompleteSelect';

type Props = {
  label: string,
  value: string,
  defaultValue: string,
  name: string,
  placeholder: string,
  error: boolean,
  onChange: (value: string) => void,
  onSelect?: (value: string) => void,
};

const PlacesAutocompleteSelect = ({
  label,
  value,
  defaultValue,
  name,
  placeholder,
  error,
  onChange,
  onSelect,
}: Props) => (
  <PlacesAutocomplete value={value} onChange={onChange} searchOptions={{types: ['address']}}>
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <WrappedAutoCompleteSelect
        options={suggestions.map(suggestion => ({
          value: suggestion.description,
          label: suggestion.description,
        }))}
        error={error}
        placeholder={placeholder}
        label={label}
        defaultValue={defaultValue}
        name={name}
        onSelect={onSelect}
        {...getInputProps({
          className: 'location-search-input',
        })}
      />
    )}
  </PlacesAutocomplete>
);

export default PlacesAutocompleteSelect;
