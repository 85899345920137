import React from 'react';
import { css } from 'emotion';
import { colors } from '@peachjar/components';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import illustrationSVG from '../../assets/icon-desktop-decorative.svg';

const materialOverrides = {
  elevation2: {
    boxShadow: 'none',
  },
};

const MaintenanceBanner = ({ classes }) => (
  <Grid item xs={12}>
    <Paper className={classes.elevation2}>
      <div className={styles.container}>
        <div className={styles.contentLeft}>
          <img src={illustrationSVG} alt="Maintenance icon" />
        </div>
        <div className={styles.contentRight}>
          <h4 className={styles.headline}>Scheduled Maintenance</h4>
          <p className={styles.body}>
            We will be performing planned system maintenance this Saturday morning,
            June 18, 2022 between 7 a.m. and 1 p.m. PDT. Services will be suspended
            for a few hours and access to all Peachjar websites, tools and functionality
            will be unavailable.
          </p>
        </div>
      </div>
    </Paper>
  </Grid>
);

const styles = {
  container: css`
    padding: 15px 15px 18px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${colors.blonde};
    border-top: 3px solid ${colors.orange};
    border-radius: 4px;
  `,
  contentLeft: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    padding-right: 14px;
  `,
  contentRight: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    padding-top: 6px;
    padding-right: 30px;
  `,
  headline: css`
    font-family: inherit;
    font-size: 17px;
    font-weight: 600;
    color: ${colors.prussian};
  `,
  body: css`
    font-family: inherit;
    font-size: 16px;
    color: ${colors.prussian};
  `,
};

export default withStyles(materialOverrides)(MaintenanceBanner);
