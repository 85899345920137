
import * as React from 'react';
import portalBffApolloClient from '../../../../../../_app/apollo/portalBFF.apolloClient';
import SAVE_CAMPAIGN_MUTATION from '../../../../gql/SaveCampaignMutation.graphql';
import MutationButton from '../../../../../../components/MutationButton';

type Props = {
   campaignId: string,
   userTexts: string[],
  children: React.ReactNode,
  onSaveFailed: (campaignId: string) => void,
};

const SaveFlyerUserTextButton = ({
  campaignId,
  userTexts,
  onSaveFailed,
  ...rest
}: Props) => {
  const variables = { campaignId, campaignPrepare: { userTexts } };

  return (
    <MutationButton
      client={portalBffApolloClient}
      mutation={SAVE_CAMPAIGN_MUTATION}
      variables={variables}
      // refetchQueries={() => ['getMyCampaigns']}
      handleError={onSaveFailed}
      {...rest}
    />
  );
};

export default SaveFlyerUserTextButton;
