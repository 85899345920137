import { DEFAULT_COMM_FREE_SCHOOL_SELECTION_LIMIT } from '../campaigns/shared/constants';

const SET_CAMPAIGN_ID = 'campaigns/SET_CAMPAIGN_ID';
const SET_LEGACY_FLYER_ID = 'campaigns/SET_LEGACY_FLYER_ID';
const SET_CAMPAIGN_TYPE = 'campaigns/SET_CAMPAIGN_TYPE';
const SET_APPLICATION_ID = 'campaigns/SET_APPLICATION_ID';
const SET_COMMUNITY_FREE_SCHOOL_SELECTION_LIMIT =
  'campaigns/SET_COMMUNITY_FREE_SCHOOL_SELECTION_LIMIT';
const RESET_COMMUNITY_FREE = 'campaigns/RESET_COMMUNITY_FREE';

export const COMMUNITY_FREE_TYPE = 'community_free';
export const STANDARD_TYPE = 'standard';
export const COMMUNITY_FREE_UPLOADER_TYPE = 'org';

export type CampaignType = 'standard' | 'community_free' | null;

export function setCampaignType(type: CampaignType) {
  return async dispatch => {
    dispatch({
      type: SET_CAMPAIGN_TYPE,
      payload: type,
    });
  };
}

export const setCommunityFreeAppId = (appId: string) => {
  return async dispatch => {
    dispatch({
      type: SET_APPLICATION_ID,
      payload: appId,
    });
  };
};

export const setCommunityFreeSchoolSelectionLimit = (limit: number) => ({
  type: SET_COMMUNITY_FREE_SCHOOL_SELECTION_LIMIT,
  payload: limit,
});

export const setCampaignId = (id: string) => {
  return async dispatch => {
    dispatch({
      type: SET_CAMPAIGN_ID,
      payload: id,
    });
  };
};

export const setLegacyFlyerId = (legacyFlyerId: number) => {
  return async dispatch => {
    dispatch({
      type: SET_LEGACY_FLYER_ID,
      payload: legacyFlyerId,
    });
  };
};

export const resetCommunityFreeConfig = () => {
  return async dispatch => {
    dispatch({
      type: RESET_COMMUNITY_FREE,
      payload: true,
    });
  };
};

export const initialState = {
  communityFree: {
    type: null,
    applicationId: null,
    currentCampaignId: null,
    schoolSelectionLimit: DEFAULT_COMM_FREE_SCHOOL_SELECTION_LIMIT,
  },
};

export function reducer(state = initialState, { type, payload }) {
  const { communityFree } = state;

  switch (type) {
    case SET_CAMPAIGN_TYPE:
      return {
        ...state,
        communityFree: {
          ...communityFree,
          type: payload,
        },
      };
    case SET_APPLICATION_ID:
      return {
        ...state,
        communityFree: {
          ...communityFree,
          applicationId: payload,
        },
      };
    case SET_COMMUNITY_FREE_SCHOOL_SELECTION_LIMIT:
      return {
        ...state,
        communityFree: {
          ...communityFree,
          schoolSelectionLimit: payload,
        },
      };
    case SET_CAMPAIGN_ID:
      return {
        ...state,
        communityFree: {
          ...communityFree,
          currentCampaignId: payload,
        },
      };
    case SET_LEGACY_FLYER_ID:
      return {
        ...state,
        communityFree: {
          ...communityFree,
          legacyFlyerId: payload,
        },
      };
    case RESET_COMMUNITY_FREE:
      return {
        ...state,
        communityFree: {
          ...initialState.communityFree,
        },
      };
    default:
      return state;
  }
}
