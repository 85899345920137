import * as shortid from 'shortid';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl, {
  FormControlClassKey,
} from '@material-ui/core/FormControl';

import { colors, elements } from '@peachjar/components';
const { prussian } = colors;
const { Note } = elements.typography;

const SchoolFinderSortStyleDropdown = ({
  testId,
  classes,
  options,
  customOnChangeHandler,
  initialValue,
  disabled,
}) => {
  const [dropDownLabel, setDropDownLabel] = useState(initialValue);

  return (
    <FormControl>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          display: 'flex',
        }}
      >
        <Note style={{ display: 'block', flex: 1 }}>Sort:</Note>
        <Select
          disabled={disabled}
          data-testid={testId}
          value={dropDownLabel}
          style={{
            marginLeft: '0.5rem',
            flex: 1,
          }}
          classes={{
            select: classes.selectInput,
            root: classes.selectRoot,
          }}
          onChange={ev => {
            setDropDownLabel(ev.target.value);
            if (customOnChangeHandler) {
              customOnChangeHandler(ev.target.value);
            }
          }}
          margin="dense"
          inputProps={{
            disableUnderline: true,
          }}
          input={<Input style={{}} disableUnderline={true} />}
        >
          {options.map(option => (
            <MenuItem key={shortid.generate()} value={option.value}>
              <Note style={{ display: 'block' }}>{option.label}</Note>
            </MenuItem>
          ))}
        </Select>
      </div>
    </FormControl>
  );
};

const styles = {
  selectInput: {
    paddingBottom: '0',
    width: '65px',
    '& > span': {
      lineHeight: '1.3',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectRoot: {
    '& > svg': {
      color: prussian,
    },
  },
};

export default withStyles(styles)(SchoolFinderSortStyleDropdown);
