import React, { useState, useRef, useContext } from 'react';
import { matchPath } from 'react-router';
import { connect } from 'react-redux';
import { css } from 'emotion';
import toString from 'lodash/toString';
import gql from 'graphql-tag';
import idx from 'idx';
import DateFnsUtils from '@date-io/date-fns';
import { Query, Mutation } from 'react-apollo';
import Dashed from '@peachjar/ui/dist/lib/components/Dashed';
import linkOut from '@peachjar/ui/dist/lib/helpers/linkOut';
import { colors, components, elements } from '@peachjar/components';
import Chip from '@material-ui/core/Chip';
import config from '../../../config';
import RemoveFlyerModal from '../../../components/RemoveFlyerModal';
import flyerPlaceHolderSVG from '../../../assets/flyer_placeholder.svg';
import FacebookShareButton from '../../../components/FacebookShareButton';
import TwitterShareButton from '../../../components/TwitterShareButton';
import {
  clientSupportsCopyToClipboard,
  copyToClipboard,
} from '../../../helpers/copyToClipboard';
import { NOTIFICATIONS, APP_ROUTES } from '../../constants';
import AppDrawerContext from '../../context/AppDrawerContext';
import { FLYER_DETAILS_QUERY } from '../../../reporting/gql/FlyerDetails.graphql';
import generatePreviewFlyerURL from '../../../helpers/generatePreviewFlyerURL';
import getPostDuration from '../../../helpers/getPostDuration';
import LoadingSpinner from '../../../components/LoadingSpinner';
import flyerApprovedIcon from '../../../assets/icon-approved-circular.svg';
import flyerDeniedIcon from '../../../assets/icon-denied-circular.svg';
import diffInDays from '../../../helpers/diffInDays';
import { redirect } from '../../redirect';
import { utcDateToMidnightLocal } from '@peachjar/ui/dist/lib/utils/dates';
// Align distribution across UI
import {
  getDistribution,
  getPostDuration as getPostDurationUtil,
} from '../../../campaigns/Create-Campaign/Summary/components/SummaryDetails';
import { CampaignDetails } from './CampaignDetails';

const { errorKey } = NOTIFICATIONS;

const {
  Buttons: { ButtonSecondarySmall, ButtonPrimaryLarge },
  Notifications: { notifySuccess, notifyError, notifyWarn },
} = components;
const {
  typography: { Note },
} = elements;
const dateFns = new DateFnsUtils();

const REMOVE_FLYER_MUTATION = gql`
  mutation removeMyFlyer($flyerId: Int!) {
    removeMyFlyer(flyerId: $flyerId) {
      id
    }
  }
`;

export const FLYER_REPORTING_FRAGMENT = gql`
  fragment flyerReporting on SodUser {
    flyerReporting {
      allFlyers(limit: 15, offset: 0) {
        items {
          flyerId
          distributionStatus
          removalDate
        }
        pagination {
          total
        }
      }
      myFlyers(limit: 15, offset: 0) {
        items {
          flyerId
          distributionStatus
          removalDate
        }
        pagination {
          total
        }
      }
    }
  }
`;

const redirectToDashboard = (): void => {
  localStorage.setItem(errorKey, 'flyerDetailsInvalid');
  redirect(APP_ROUTES.dashboard);
};

const sanitizeUrl = (url: string): string => {
  const decodedURI = decodeURIComponent(url);

  if (!url.includes('http')) {
    return `http://${decodedURI}`;
  }

  return decodedURI;
};

const getSecondaryCTA = (ctaObj: object): any => {
  const { type, data } = ctaObj;
  switch (type) {
    case 'Call':
      return (
        <ButtonSecondarySmall
          data-testid="click-flyer-details-cta"
          className={`${cn.flyerCTA} ${cn.ctaTrails}`}
          href={`tel:${data.phoneNumber}`}
        >
          Call
        </ButtonSecondarySmall>
      );
    case 'SignUp':
      return (
        <ButtonSecondarySmall
          data-testid="click-flyer-details-cta"
          className={`${cn.flyerCTA} ${cn.ctaTrails}`}
          href={linkOut({
            url: sanitizeUrl(data.websiteUrl),
            labels: { linkType: 'flyerDetails-cta' },
          })}
          target="_blank"
        >
          Sign Up
        </ButtonSecondarySmall>
      );
    case 'Email':
      return (
        <ButtonSecondarySmall
          data-testid="click-flyer-details-cta"
          className={`${cn.flyerCTA} ${cn.ctaTrails}`}
          href={`mailto:${data.email}`}
          target="_blank"
        >
          Email
        </ButtonSecondarySmall>
      );
    case 'GetApp':
      return (
        <ButtonSecondarySmall
          data-testid="click-flyer-details-cta"
          className={`${cn.flyerCTA} ${cn.ctaTrails}`}
          href={linkOut({
            url: sanitizeUrl(data.webAppUrl ? data.webAppUrl : data.websiteUrl),
            labels: { linkType: 'flyerDetails-cta' },
          })}
          target="_blank"
        >
          Get App
        </ButtonSecondarySmall>
      );
    case 'Donate':
      return (
        <ButtonSecondarySmall
          data-testid="click-flyer-details-cta"
          className={`${cn.flyerCTA} ${cn.ctaTrails}`}
          href={linkOut({
            url: sanitizeUrl(data.donateWebsiteUrl),
            labels: { linkType: 'flyerDetails-cta' },
          })}
          target="_blank"
        >
          Donate
        </ButtonSecondarySmall>
      );
    default:
      return null;
  }
};

export const FlyerDetails = props => {
  const [removeFlyerModalOpen, setRemoveFlyerModalState] = useState(false);
  const [removeCampaignModalOpen, setRemoveCampaignModalState] = useState(
    false
  );
  const { toggleDrawer, setDrawerContext } = useContext(AppDrawerContext);
  const textAreaRef = useRef(null);
  const {
    sodUser,
    flyerId,
    campaign,
    handleSuccess,
    handleError,
    handleWarning,
    history,
  } = props;
  const approverLevel = idx(sodUser, _ => _.permission.approverLevel) || null;
  const uploaderLevel = idx(sodUser, _ => _.permission.uploaderLevel) || null;
  const closeRemoveFlyerModal = (): void => {
    setRemoveFlyerModalState(false);
  };

  const onCancelRemoveModal = () => {
    setRemoveCampaignModalState(false);
  };

  const onRemoveFlyer = (mutate: Function): void => {
    // flyer removed. Update UI state
    setTimeout(() => {
      mutate({ variables: { flyerId } });
      toggleDrawer();
      handleSuccess('removeFlyerSuccess');
    }, 500);

    setTimeout(() => {
      // DEV-5586
      window.location.reload();
    }, 1000);
  };

  const handleCopyToClipBoard = (event: HTMLElement): void => {
    const { status } = copyToClipboard(event, textAreaRef);
    if (status === 'SUCCESS') {
      handleSuccess('clipboardSuccess');
    }
    if (status === 'ERROR') {
      handleError('clipboardError');
    }
  };

  const renderify = (val: any) => (val !== null ? val : <Dashed />);

  const handleViewReport = event => {
    event.stopPropagation();
    setDrawerContext('FlyerDetails');
    toggleDrawer();
    window.location.href = `${config.REPORTING_URL}/report/${flyerId}`;
  };
  const getSchoolsTargetedSummary = (
    total: number,
    pending: number,
    approved: number,
    denied: number
  ) => (
    <p className={cn.schoolSummary}>
      <span>{`${total} total - `}</span>
      <span>{`${pending} pending`}</span>
      <span>
        <img
          className={cn.statusIcon}
          src={flyerApprovedIcon}
          height="16"
          width="16"
        />
        {` ${approved} approved`}
      </span>
      <span>
        <img
          className={cn.statusIcon}
          src={flyerDeniedIcon}
          height="16"
          width="16"
        />
        {` ${denied} denied`}
      </span>
    </p>
  );

  const getSchoolsTargetedCount = (
    totalSchools: number,
    schoolsPending: number,
    schoolsApproved: number,
    schoolsDenied: number
  ) =>
    getSchoolsTargetedSummary(
      totalSchools,
      schoolsPending,
      schoolsApproved,
      schoolsDenied
    );

  if (!flyerId) {
    return null;
  }
  if (campaign !== null && campaign !== undefined) {
    return (
      <CampaignDetails
        campaign={campaign}
        flyerId={flyerId}
        handleError={handleError}
        handleSuccess={handleSuccess}
        handleWarning={handleWarning}
        history={history}
      />
    );
  }

  return (
    <Query
      query={FLYER_DETAILS_QUERY}
      variables={{ id: parseInt(flyerId, 10) }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingSpinner />;
        }
        if (error) {
          redirectToDashboard();
          return null;
        }
        const detailReport =
          idx(data, _ => _.sodUser.flyerReporting.flyerDetailReport) || null;
        const uploaderLevel =
          idx(sodUser, _ => _.permission.uploaderLevel) || null;
        const isDistrictOrSchool =
          uploaderLevel === 'district' || uploaderLevel === 'school';
        // handle incoming requests with invalid flyerid
        if (!detailReport) {
          redirectToDashboard();
          return null;
        }

        const {
          sodUser: {
            flyerReporting: {
              flyerDetailReport: {
                campaign,
                flyerReport,
                approvalStatusCounts,
              },
            },
          },
        } = data;

        const sodId = idx(data, _ => _.sodUser.userId) || null;
        const sodFlyerReportingId = idx(campaign, _ => _.ownerId) || null;
        const minGradeLevel = idx(campaign, _ => _.minGradeLevel) || null;
        const maxGradeLevel = idx(campaign, _ => _.maxGradeLevel) || null;
        const formattedAgeRange =
          minGradeLevel && maxGradeLevel
            ? `${minGradeLevel} - ${maxGradeLevel}`
            : null;
        const sellerCompanyName =
          idx(campaign, _ => _.sellerCompanyName) || null;
        const submittedDate = idx(flyerReport, _ => _.submittedDate) || null;
        const distributionsFirstDate =
          idx(flyerReport, _ => _.distributionsFirstDate) || null;
        const eventLocation = idx(campaign, _ => _.eventLocation) || null;
        const deadlineDate = idx(campaign, _ => _.deadlineDate) || null;
        const startDate = idx(campaign, _ => _.startDate) || null;
        const postExpirationDate =
          idx(flyerReport, _ => _.expirationDate) || null;
        const primaryCallToAction =
          idx(campaign, _ => _.primaryCallToAction) || null;
        const secondaryCallToAction =
          idx(campaign, _ => _.secondaryCallToAction) || null;
        const primaryCategory = idx(campaign, _ => _.primaryCategory) || null;
        const optionalCategories =
          idx(campaign, _ => _.optionalCategories) || null;
        const deliverySettings = idx(campaign, _ => _.deliverySettings) || null;
        const flyer = idx(campaign, _ => _.flyer) || null;
        const title = idx(flyerReport, _ => _.title) || null;
        const distributionStatus =
          idx(flyerReport, _ => _.distributionStatus) || null;
        const removalDate = idx(flyerReport, _ => _.removalDate) || null;

        const statusBeforeRemoval = idx(
          flyerReport,
          _ => _.statusBeforeRemoval
        );

        const formattedRemovalDate = removalDate
          ? dateFns.format(new Date(removalDate * 1000), 'MMM dd, yyyy')
          : null;

        const formattedDeadlineDate = deadlineDate
          ? dateFns.format(
              // Date stored in UTC
              utcDateToMidnightLocal(new Date(deadlineDate)),
              'MMM dd, yyyy'
            )
          : null;
        const formattedSubmittedDate = submittedDate
          ? dateFns.format(
              // Date stored in UTC
              new Date(submittedDate * 1000),
              'MMM dd, yyyy'
            )
          : null;
        const formattedStartDate = startDate
          ? dateFns.format(
              // Converted because this is a Date without time
              utcDateToMidnightLocal(startDate),
              'MMM dd, yyyy'
            )
          : null;
      const formattedDistributionDate = distributionsFirstDate
      ? dateFns.format(new Date(distributionsFirstDate * 1000), 'MMM dd, yyyy')
      : null;
        const hasOptionalCategories =
          optionalCategories !== null &&
          Array.isArray(optionalCategories) &&
          optionalCategories.length > 0;
        const hasDeliverySettings = deliverySettings !== null;
        const hasPostDuration =
          hasDeliverySettings &&
          deliverySettings.startDate !== null &&
          postExpirationDate !== null;

        const postDurationInDays = () => {
          if (distributionStatus === 'removed' && removalDate) {
            return (
              diffInDays(
                new Date(deliverySettings.startDate),
                new Date(removalDate * 1000),
              ) + 1
            );
          }
          if (hasPostDuration) {
            return getPostDurationUtil(
              new Date(deliverySettings.startDate),
              new Date(postExpirationDate * 1000),
            );
          }
          return null;
        };
        const totalSelectedSchools =
          hasDeliverySettings && deliverySettings.selectedAudience !== null ? (
            deliverySettings.selectedAudience
          ) : (
            <Dashed />
          );

        const flyerThumbnail = idx(flyerReport, _ => _.thumbnailUrl) || null;
        const numberOfPages = idx(flyer, _ => _.numberOfPages) || 1;
        const districtId =
          idx(
            data,
            _ =>
              _.sodUser.flyerReporting.flyerDetailReport.approvals[0].districtId
          ) || null;
        const schoolId =
          idx(
            data,
            _ =>
              _.sodUser.flyerReporting.flyerDetailReport.approvals[0].schoolId
          ) || null;
        const flyerShareUrl = generatePreviewFlyerURL({
          sod: approverLevel,
          flyerId,
          districtId,
          schoolId,
        }).replace('app.peachjar.com', 'share.peachjar.com');
        const renderRemoveFlyerBtn =
          toString(sodId) === sodFlyerReportingId &&
          !['removed', 'expired'].includes(distributionStatus.toLowerCase());
        const formattedEndDate =
          postExpirationDate !== null
            ? dateFns.format(
                // Converted because this is a Date without time
                utcDateToMidnightLocal(postExpirationDate * 1000),
                'MMM dd, yyyy'
              )
            : null;
        const totalSchoolsCount =
          idx(approvalStatusCounts, _ => _.totalCount) || 0;
        const approvedSchoolCount =
          idx(approvalStatusCounts, _ => _.approvedStatusCount) || 0;
        const pendingSchoolCount =
          idx(approvalStatusCounts, _ => _.pendingStatusCount) || 0;
        const deniedSchoolCount =
          idx(approvalStatusCounts, _ => _.deniedStatusCount) || 0;
        const postDuration = postDurationInDays();
        const distributions = startDate
          ? getDistribution(
              new Date(startDate),
              new Date(postExpirationDate * 1000)
            )
          : getDistribution(
              new Date(deliverySettings.startDate),
              new Date(postExpirationDate * 1000)
            );

        return (
          <>
            <div className={cn.pageContainer}>
              <div className={cn.flyerDetailsContainer}>
                <div className={cn.flyerDetails}>
                  <div className={cn.flyerTitleContainer}>
                    <h3
                      className={cn.flyerTitle}
                      data-testid="text-flyer-title"
                    >
                      {title}
                    </h3>
                    <span className={cn.flyerId}>{`#${flyerId}`}</span>
                  </div>
                  <div className={cn.flyerInformation}>
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>Status: </p>
                      <p className={cn.sectionValue}>
                        {distributionStatus ? (
                          <Chip
                            label={distributionStatus}
                            className={`${cn.flyerStatus} ${
                              cn[`chip${distributionStatus}`]
                            }`}
                          />
                        ) : (
                          <Dashed />
                        )}
                      </p>
                    </div>
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>Uploader: </p>
                      <p className={cn.sectionValue}>
                        {renderify(sellerCompanyName)}
                      </p>
                    </div>
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>Submitted Date: </p>
                      <p className={cn.sectionValue}>
                        {submittedDate ? formattedSubmittedDate : <Dashed />}
                      </p>
                    </div>
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>Street Address: </p>
                      <p className={cn.sectionValue}>
                        {renderify(eventLocation)}
                      </p>
                    </div>
                    {/* {!isDistrictOrSchool && (
                      <div className={cn.flyerInformationSection}>
                        <p className={cn.sectionLabel}>Participation Fee: </p>
                        <p className={cn.sectionValue}>
                          {participationFee !== ' - ' ? (
                            formatedParticationFee
                          ) : (
                              <Dashed />
                            )}
                        </p>
                      </div>
                    )} */}
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>Call-To-Actions: </p>
                      <p className={cn.sectionValue}>
                        {primaryCallToAction && primaryCallToAction.data && (
                          <ButtonSecondarySmall
                            data-testid="click-flyer-details-cta"
                            className={cn.flyerCTA}
                            href={linkOut({
                              url: sanitizeUrl(
                                primaryCallToAction.data.websiteUrl
                              ),
                              labels: { linkType: 'flyerDetails-cta' },
                            })}
                            target="_blank"
                          >
                            Learn More
                          </ButtonSecondarySmall>
                        )}

                        {secondaryCallToAction &&
                          secondaryCallToAction.data &&
                          getSecondaryCTA(secondaryCallToAction)}

                        {primaryCallToAction === null &&
                          secondaryCallToAction === null && <Dashed />}
                      </p>
                    </div>
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>Audience: </p>
                      <p className={`${cn.sectionValue} ${cn.capitalize}`}>
                        {totalSelectedSchools}
                      </p>
                    </div>
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>Distributions: </p>
                      <p className={cn.sectionValue}>
                        {renderify(distributions)}{' '}
                        <span style={{ color: 'grey' }}>(send and post)</span>
                      </p>
                    </div>
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>
                        Distribution Target Date:{' '}
                      </p>
                      {hasDeliverySettings &&
                      deliverySettings.startDate !== null ? (
                        <p className={cn.sectionValue}>
                          {`${dateFns.format(
                            utcDateToMidnightLocal(
                              new Date(deliverySettings.startDate)
                            ),
                            'MMM dd, yyyy'
                          )} `}
                        </p>
                      ) : (
                        <Dashed />
                      )}
                    </div>
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>
                        First Distribution Date:{' '}
                      </p>
                      <p className={cn.sectionValue}>
                        {formattedDistributionDate || <Dashed />}
                        <span className={cn.slater}>
                          {distributions && distributions > 1
                            ? ' (remaining distributions are 30 days apart)'
                            : ''}
                        </span>
                      </p>
                    </div>
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>
                        {distributionStatus &&
                        distributionStatus.toLowerCase() === 'removed'
                          ? 'Removed:'
                          : 'Last Post Expiration Date:'}
                      </p>
                      <p className={cn.sectionValue}>
                        {renderify(
                          distributionStatus &&
                            distributionStatus.toLowerCase() === 'removed'
                            ? formattedRemovalDate
                            : formattedEndDate
                        )}
                      </p>
                    </div>
                    <div className={cn.flyerInformationSection}>
                      <p className={cn.sectionLabel}>Post Duration: </p>
                      <p className={cn.sectionValue}>
                        {distributionStatus &&
                        distributionStatus.toLowerCase() === 'removed'
                          ? postDurationInDays
                          : postDuration || <Dashed />}
                      </p>
                    </div>
                    <div className={cn.flyerInformationSectionBottom}>
                      <p className={cn.sectionLabel}>Schools Targeted: </p>
                      <p className={cn.sectionValue}>
                        {getSchoolsTargetedCount(
                          totalSchoolsCount,
                          pendingSchoolCount,
                          approvedSchoolCount,
                          deniedSchoolCount
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={cn.flyerPreviewContainer}>
                  <div className={cn.flyerPreview}>
                    <img
                      className={cn.flyerThumbnail}
                      src={flyerThumbnail || flyerPlaceHolderSVG}
                      alt=""
                    />
                  </div>
                  <div className={cn.flyerThumbnailCount}>
                    <span>{`1 of ${numberOfPages}`}</span>
                  </div>
                </div>
              </div>
              <div className={cn.viewReport}>
                <ButtonPrimaryLarge
                  data-testid="click-flyer-details-view-report"
                  onClick={handleViewReport}
                  className={cn.viewReportCTA}
                >
                  View Report
                </ButtonPrimaryLarge>
              </div>

              {renderRemoveFlyerBtn && (
                <div className={cn.flyerSection}>
                  <h4 className={cn.flyerSectionHeadline}>Manage Flyer</h4>
                  <Note className={cn.flyerSectionDescription}>
                    Remove flyers that have been inappropriately distributed.
                    All flyers are automatically removed from the web
                    flyerboard(s) upon post expiration.
                  </Note>
                  <div className={cn.flyerSectionCTA}>
                    <ButtonSecondarySmall
                      data-testid="click-flyer-details-remove-flyer"
                      onClick={() => {
                        !renderRemoveFlyerBtn
                          ? handleWarning('removeFlyerWarning')
                          : toggleDrawer();
                        setDrawerContext('FlyerRemoval');
                        setTimeout(() => {
                          toggleDrawer({ flyerId });
                        }, 5);
                      }}
                      className={`${cn.flyerCTA} ${
                        !renderRemoveFlyerBtn ? cn.flyerCTAdisabled : ''
                      }`}
                    >
                      Remove Flyer
                    </ButtonSecondarySmall>
                  </div>
                </div>
              )}

              <div className={cn.flyerSection}>
                <h4 className={cn.flyerSectionHeadline}>Promote Flyer</h4>
                <Note className={cn.flyerSectionDescription}>
                  Use these tools to spread the word about your new flyer and
                  together we can reach more people.
                </Note>
                <div className={`${cn.flyerSectionCTA} ${cn.promoteSection}`}>
                  <div className={cn.promoteCTA}>
                    <FacebookShareButton shareUrl={flyerShareUrl} />
                  </div>
                  <div className={`${cn.promoteCTA} ${cn.twitterCTA}`}>
                    <TwitterShareButton
                      shareUrl={flyerShareUrl}
                      title={title}
                    />
                  </div>
                </div>
                <div className={cn.flyerLink}>
                  <form className={cn.flyerLinkForm}>
                    <textarea
                      rows={1}
                      ref={textAreaRef}
                      className={cn.link}
                      value={flyerShareUrl}
                      readOnly
                    />
                    {clientSupportsCopyToClipboard && (
                      <ButtonSecondarySmall
                        data-testid="click-flyer-details-copy-link"
                        onClick={handleCopyToClipBoard}
                        className={`${cn.flyerCTA} ${cn.ctaTrails}`}
                        href=""
                      >
                        Copy Link
                      </ButtonSecondarySmall>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <Mutation
              mutation={REMOVE_FLYER_MUTATION}
              update={(
                cache,
                {
                  data: {
                    removeMyFlyer: { id },
                  },
                }
              ) => {
                const queryResult = cache.readFragment({
                  id: sodFlyerReportingId,
                  fragment: FLYER_REPORTING_FRAGMENT,
                });

                const {
                  flyerReporting: { allFlyers, myFlyers },
                } = queryResult;

                const allFlyerContext = allFlyers.items.find(
                  item => item.flyerId === id
                );
                const myFlyerContext = myFlyers.items.find(
                  item => item.flyerId === id
                );

                if (allFlyerContext) {
                  allFlyerContext.distributionStatus = 'removed';
                  allFlyerContext.removalDate = Math.round(
                    new Date().getTime() / 1000
                  );
                }

                if (myFlyerContext) {
                  myFlyerContext.distributionStatus = 'removed';
                  myFlyerContext.removalDate = Math.round(
                    new Date().getTime() / 1000
                  );
                }

                cache.writeFragment({
                  id: sodFlyerReportingId,
                  fragment: FLYER_REPORTING_FRAGMENT,
                  data: queryResult,
                });
              }}
              refetchQueries={() => [
                {
                  query: FLYER_DETAILS_QUERY,
                  variables: { id: parseInt(flyerId, 10) },
                },
              ]}
            >
              {removeMyFlyer => (
                <RemoveFlyerModal
                  open={removeFlyerModalOpen}
                  onRemove={() => {
                    onRemoveFlyer(removeMyFlyer);
                  }}
                  onClose={closeRemoveFlyerModal}
                  uploaderLevel={uploaderLevel}
                />
              )}
            </Mutation>
          </>
        );
      }}
    </Query>
  );
};

const cn = {
  pageContainer: css`
    width: 1020px;
    max-width: 1020px;
    padding-top: 28px;
  `,
  flyerDetailsContainer: css`
    display: flex;
    flex-direction: row;
  `,
  flyerDetails: css`
    width: 67%;
    padding-right: 40px;
    position: relative;
  `,
  flyerTitle: css`
    font-size: 19px;
    color: ${colors.prussian};
    font-weight: 600;
    width: 310px;
    line-height: 1.45;
    margin-bottom: 1.5rem;
    word-break: break-word;
  `,
  flyerTitleContainer: css`
    width: 358px;
    position: relative;
  `,
  flyerId: css`
    font-size: 13px;
    font-weight: normal !important;
    color: ${colors.slate};
    position: absolute;
    top: 6px;
    right: 0;
    padding-left: 10px;
  `,
  flyerPreview: css`
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    padding: 12px 5px;
  `,
  flyerPreviewContainer: css`
    width: 33%;
    max-width: 325px;
    overflow: hidden;
  `,
  flyerThumbnail: css`
    width: 100%;
  `,
  flyerInformation: css``,
  flyerInformationSection: css`
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
  `,
  flyerInformationSectionBottom: css`
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
  `,
  flyerThumbnailCount: css`
    color: #707070;
    font-size: 13px;
  `,
  sectionLabel: css`
    width: 30%;
    font-size: 15px;
    padding: 0;
  `,
  sectionValue: css`
    width: 70%;
    font-size: 15px;
    padding: 0;
  `,
  flyerStatus: css`
    color: ${colors.prussian} !important;
    font-size: 13px !important;
    font-weight: normal;
    text-transform: capitalize;
  `,
  flyerCTA: css`
    margin-right: 8px !important;
  `,
  twitterCTA: css`
    margin-left: 8px !important;
  `,
  ctaTrails: css``,
  chipsubmitted: css`
    background-color: ${colors.mercury} !important;
  `,
  chipdeclined: css`
    background-color: #ffebe5 !important;
  `,
  chipactive: css`
    background-color: #e7f4e9 !important;
  `,
  chipscheduled: css`
    background-color: #dfe1e7 !important;
  `,
  chipremoved: css`
    background-color: #ffefc8 !important;
  `,
  chipgray: css`
    background-color: #f5f5f5 !important;
  `,
  chipexpired: css`
    background-color: #fffae5 !important;
  `,
  flyerSection: css`
    margin-top: 56px;
  `,
  flyerSectionHeadline: css`
    font-size: 25px;
    color: ${colors.prussian};
    margin-bottom: 4px;
  `,
  flyerSectionCTA: css`
    margin-top: 20px;
  `,
  flyerSectionDescription: css`
    margin-top: 8px;
    font-size: 16px;
  `,
  flyerLinkForm: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  flyerLink: css`
    background-color: #f5f5f5;
    padding: 20px;
    margin-top: 22px;
  `,
  deleteBtn: css`
    margin-left: 16px;
  `,
  layoutRow: css`
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 24px;
  `,
  link: css`
    color: #3a833c;
    font-size: 16px;
    line-height: 1.85;
    outline: 0;
    border: none;
    background-color: transparent;
    resize: none;
    overflow-y: hidden;
    width: 80%;
  `,
  viewReport: css`
    display: flex;
    justify-content: flex-start;
    margin: 0px;
  `,
  viewReportCTA: css`
    margin-top: 0px;
  `,
  promoteSection: css`
    display: flex;
    flex-direction: row;
  `,
  promoteCTA: css``,
  capitalize: css`
    text-transform: capitalize;
  `,
  slater: css`
    color: ${colors.slate};
  `,
  schoolSummary: css`
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  statusIcon: css`
    margin: -2px 1px 0 0;
  `,
  flyerCTAdisabled: css`
    background-color: #d9d9d9 !important;
    color: ${colors.slate} !important;
    border-color: #d9d9d9 !important;
  `,
};

const mapDispatchToProps = (dispatch: any): object => ({
  handleSuccess: (key: string): void => {
    dispatch(notifySuccess(NOTIFICATIONS[key]));
  },
  handleError: (key: string): void => {
    dispatch(notifyError(NOTIFICATIONS[key]));
  },
  handleWarning: (key: string): void => {
    dispatch(notifyWarn(NOTIFICATIONS[key]));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(FlyerDetails);
