const PREFIX = 'PJ_FLAG_';
const ON_VALUE = '1';

export function setFlag(name: string) {
  const key = `${PREFIX}${name}`;
  localStorage.setItem(key, ON_VALUE);
}

export function unsetFlag(name: string) {
  const key = `${PREFIX}${name}`;
  localStorage.removeItem(key);
}

export function isFlagSet(name: string) {
  const key = `${PREFIX}${name}`;
  const value = localStorage.getItem(key);
  return value === ON_VALUE;
}
