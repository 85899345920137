

// ACTIONS
const SET_APPROVAL_STATUS = 'SET_APPROVAL_STATUS';
const RESET_APPROVALS = 'RESET_APPROVALS';

// UTILITY TYPES
type ApprovalId = string;
type FlyerId = string;
export type Status = 'approved' | 'denied' | 'pending';

// NOTE: this type should be generated from a GraphQL enum
export type DeclineReason =
  | 'FOR_PROFIT'
  | 'CONTENT_INAPPROPRIATE'
  | 'TRANSLATION_NEEDED'
  | 'SOLICITATION'
  | 'COMPETING_PROGRAMS'
  | 'TEXT_MISTAKE'
  | 'CONTENT_RELIGIOUS'
  | 'POSTING_DATE'
  | 'ADDITIONAL_INFO';

// NOTE: all the necessary information to process all the updates for a FlyerApproval
// Redux representation of staged actions by user for a single flyer
export type StagedFlyer = {
  flyerId: FlyerId,
  approvals: Record<ApprovalId, Status>
  denialReason?: DeclineReason,
  denialReasonExplanation?: string,
};

export type StagedFlyersById = Record<FlyerId, StagedFlyer>;

// NOTE: the individual approval update
export type ApprovalForMutation = {
  approvalId: ApprovalId,
  status: Status,
  denialReason?: DeclineReason,
  denialReasonExplanation?: string,
};

// ACTION TYPES
export type ResetApprovalsAction = {
  type: 'RESET_APPROVALS',
};
export type SetApprovalStatusAction = StagedFlyer & {
  type: 'SET_APPROVAL_STATUS',
};

// ACTION CREATORS
export function setApprovalStatus({
  flyerId,
  approvals,
  denialReason,
  denialReasonExplanation,
}: StagedFlyer): SetApprovalStatusAction {
  return {
    type: SET_APPROVAL_STATUS,
    flyerId,
    approvals,
    denialReason,
    denialReasonExplanation,
  };
}
export const resetApprovals = (): ResetApprovalsAction => ({
  type: RESET_APPROVALS,
});

// REDUCER
export type ApprovalsState = {
  flyers: StagedFlyersById,
};

type Action = SetApprovalStatusAction | ResetApprovalsAction;

export const initialState = {
  flyers: {},
};

export function reducer(
  state: ApprovalsState = initialState,
  action: Action
): ApprovalsState {
  switch (action.type) {
    case SET_APPROVAL_STATUS: {
      const {
        flyerId,
        approvals,
        denialReason,
        denialReasonExplanation,
      } = action;
      return {
        ...state,
        flyers: {
          ...state.flyers,
          [flyerId]: {
            flyerId,
            approvals,
            denialReason,
            denialReasonExplanation,
          },
        },
      };
    }
    case RESET_APPROVALS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
