
import generateSubappConfig from '../_app/generateSubappConfig';
import SchoolsManagement from './SchoolsManagement';
import { APP_ROUTES } from '../_app/constants';

const schoolsManagementSubappConfig = generateSubappConfig({
  path: APP_ROUTES.schools,
  navItemConfig: {
    label: 'Schools',
  },
  appConfig: {
    Component: SchoolsManagement,
  },
});

export default schoolsManagementSubappConfig;
