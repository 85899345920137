import gql from 'graphql-tag';
import OcrPagesFragments from '../components/gql/OcrPagesFragments.graphql';

export default {
  getMyCampaigns: gql`
    fragment Ocr_getMyCampaigns on Flyer {
      ...OcrPages_getMyCampaigns
    }
    ${OcrPagesFragments.getMyCampaigns}
  `,
};
