import React from 'react';
import DatePicker from './DatePicker';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import format from 'date-fns/format';
import utcDateToMidnightLocal from './utcDateToMidnightLocal';
import localDateToMidnightUTC from './localDateToMidnightUTC';

const UTCDatePicker = ({
  label,
  name,
  value,
  onChange,
  onClose,
  classes,
  isDisabled,
  clearable,
  minDate,
  maxDate,
  error,
}) => {
  const onChangeUTC = date => {
    const utcDate = localDateToMidnightUTC(date);
    return onChange(utcDate);
  };

  return (
    <DatePicker
      label={label}
      name={name}
      value={utcDateToMidnightLocal(value)}
      onChange={onChangeUTC}
      onClose={onClose}
      classes={classes}
      isDisabled={isDisabled}
      clearable={clearable}
      minDate={utcDateToMidnightLocal(minDate)}
      maxDate={utcDateToMidnightLocal(maxDate)}
      error={error}
    />
  );
};

export default UTCDatePicker;
