import React from 'react';
import { css } from 'react-emotion';
import { elements, components } from '@peachjar/components';
import HidingTurtlePNG from '../../../../../../../../../../../assets/hiding_turtle.png';
import { getCurrentBounds, getCurrentCenter } from '../../../utils';
import { MapVariables } from '../../../../types';

const { Paragraph, Note } = elements.typography;
const { ButtonFlatSmall } = components.Buttons;

const styles = {
  block: css`
    display: block;
  `,
  marginBottom: css`
    && {
      margin-bottom: 0.75rem;
    }
  `,
  muted: css`
    /* This is the 'iron' color. It needs to be added to our component library. It's the new muted color */
    color: #969696;
  `,
  textAlignCenter: css`
    text-align: center;
  `,
  maxWidth: css`
    max-width: 300px;
  `,
  image: css`
    height: 225px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  `,
};

type Props = {
  totalNearbySchools: number,
  setMapVariables: (mapVariables: MapVariables) => void;
  setSearchValue: (value: string) => void;
  setInputValue: (value: string) => void;
  setPreviousRedoSearchZoom: (zoom: number | null) => void;
  map: any,
};

const NearbySchoolsMessage = ({
  totalNearbySchools = 0,
  setMapVariables,
  setSearchValue,
  setInputValue,
  setPreviousRedoSearchZoom,
  map,
}: Props) => (
  <div className="flex-auto layout-column layout-align-start-center pt-0 p-3">
    <img className={styles.image} src={HidingTurtlePNG} />

    <div className={`${styles.textAlignCenter}`}>
      <Paragraph className={`${styles.block} ${styles.marginBottom}`}>
        No schools found in this specific area,<br />
        but {totalNearbySchools} found nearby.
      </Paragraph>

      <ButtonFlatSmall
        className={styles.marginBottom}
        onClick={() => {
          setMapVariables({
            center: getCurrentCenter(map),
            redoMapBounds: getCurrentBounds(map),
          });
          setSearchValue('Current Map Area');
          setInputValue('Current Map Area');
          setPreviousRedoSearchZoom(map.getZoom());      
        }}
      >
        List Nearby Schools
      </ButtonFlatSmall>

      <Note
        className={`${styles.block} ${styles.muted} ${styles.maxWidth}`}
      >
        Otherwise, try searching a larger area or an area nearby.
      </Note>
    </div>
  </div>
);

export default NearbySchoolsMessage;
