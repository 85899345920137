
import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { cx, css } from 'react-emotion';
import { Mutation } from 'react-apollo';
import idx from 'idx';
import { components, elements } from '@peachjar/components';

import withLoading from '../withLoading';
import {
  STRINGS,
  MAX_ALLOWED_APPROVER_MESSAGE_CHARACTERS,
} from '../../constants';

const { notifySuccess, notifyError } = components.Notifications;
const { Modal, TextInput } = components;
const { Paragraph } = elements.typography;
const { ButtonFlatLarge } = components.Buttons;

const ButtonFlatLargeWithLoading = withLoading(ButtonFlatLarge);

type ReduxDispatchProps = {
  handleSuccess: () => void,
  handleError: () => void,
};

type OwnProps = {
   closeModal: () => void,
   flyer: any,
};

type Props = OwnProps & ReduxDispatchProps;

type State = {
  messageToUploader: string,
};

const SEND_MESSAGE_TO_UPLOADER_MUTATION = gql`
  mutation SendMessageToUploaderMutation(
    $input: FlyerApproverMessageToUploaderInput!
  ) {
    sendFlyerApproverMessageToUploader(input: $input) {
      flyerId
      message
    }
  }
`;

class ApproverMessageModal extends React.Component<Props, State> {
  static fragments: { [key: string]: any };

  state = { messageToUploader: '' };

  onChange = (e: SyntheticKeyboardEvent<HTMLInputElement>): void => {
    // Prevent against pasting or typing in messages over character limit
    const message = idx(e, _ => _.currentTarget.value) || '';

    if (message.length > MAX_ALLOWED_APPROVER_MESSAGE_CHARACTERS) {
      const shortenedMessage = message.slice(
        0,
        MAX_ALLOWED_APPROVER_MESSAGE_CHARACTERS
      );
      this.setState({ messageToUploader: shortenedMessage });
    } else {
      this.setState({ messageToUploader: message });
    }
  };

  render() {
    const { closeModal, handleSuccess, handleError } = this.props;
    const sellerNote = idx(this.props, _ => _.flyer.sellerNote);
    const flyerId = idx(this.props, _ => _.flyer.fml);

    const { messageToUploader } = this.state;

    return (
      <Modal width="sm" close={closeModal}>
        <Modal.Header>Approver Message</Modal.Header>
        <Modal.Body>
          {sellerNote && (
            <div className={cx('my-3', classNames.uploaderMessageSection)}>
              <Paragraph muted>Uploader Message:</Paragraph>
              <Paragraph className="mt-2">{sellerNote}</Paragraph>
            </div>
          )}
          <div className={classNames.textFieldWrapper}>
            <TextInput
              autoFocus
              fullWidth
              multiline
              label="Message to uploader"
              onChange={this.onChange}
              value={messageToUploader}
              data-testid="field-approverMessage"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonFlatLarge
            data-testid="click-cancel-sendMessageToUploader"
            onClick={closeModal}
          >
            Cancel
          </ButtonFlatLarge>
          <Mutation
            mutation={SEND_MESSAGE_TO_UPLOADER_MUTATION}
            onCompleted={handleSuccess}
            onError={handleError}
            variables={{
              input: {
                flyerId,
                message: messageToUploader,
              },
            }}
          >
            {(sendMessageToUploader, { loading }) => (
              <ButtonFlatLargeWithLoading
                data-testid="submit-sendMessageToUploader"
                disabled={messageToUploader.length < 1}
                loading={loading}
                onClick={sendMessageToUploader}
              >
                Send
              </ButtonFlatLargeWithLoading>
            )}
          </Mutation>
        </Modal.Footer>
      </Modal>
    );
  }
}

const classNames = {
  textFieldWrapper: css`
    margin-left: 4.875rem;
    margin-right: 4.875rem;
    margin-top: 2.5rem;
  `,
  uploaderMessageSection: css`
    display: flex;
    flex-direction: column;
  `,
  icon: css`
    font-size: 1.5rem;
  `,
};

ApproverMessageModal.fragments = {
  flyer: gql`
    fragment ApproverMessageModal on Flyer {
      fml: id
      sellerNote
    }
  `,
};

const mapDispatchToProps = (
  dispatch,
  { closeModal }: OwnProps
): ReduxDispatchProps => ({
  handleSuccess: () => {
    dispatch(notifySuccess(STRINGS.MESSAGE_THE_UPLOADER_SUCCESS_MESSAGE));
    closeModal();
  },
  handleError: () => {
    dispatch(notifyError(STRINGS.MESSAGE_THE_UPLOADER_ERROR_MESSAGE));
    closeModal();
  },
});

export default connect(
  null,
  mapDispatchToProps
)(ApproverMessageModal);
