type School = {
  audienceType: string;
  district: {
    [k: string]: any;
  };
  districtId: number;
  name: string;
  ncesId: number;
  schoolId: number;
};

type SchoolContext = {
  updateSchoolDistributionList: (schoolList: School[]) => void;
};

type DistroContext = {
  numberOfDistributions: number;
  setNumberOfDistributions: (num: number) => void;
};

export const setTraySchoolListContext = (
  checkboxSelections: School[],
  ctx: SchoolContext,
  postOnlySelections
): void => {
  const { updateSchoolDistributionList } = ctx;

  if (
    checkboxSelections &&
    Array.isArray(checkboxSelections) &&
    checkboxSelections.length >= 1
  ) {

    const schoolDistroList = checkboxSelections.map(school => ({
      schoolId: school.schoolId,
      districtId: school.districtId,
      audienceType: school.audienceType,
      schoolName: school.name,
      districtName: school.district.name,
      isPostOnly: school.isPostOnly,
      isOptionalPostOnly: school.isOptionalPostOnly,
      isPostOnlyChecked: postOnlySelections.includes(school.schoolId),
      lowGrade: school.lowestGradeOffered,
      highGrade: school.highestGradeOffered,
    }));

    updateSchoolDistributionList(schoolDistroList);
  } else {
    updateSchoolDistributionList([]);
  }
};

export const setTrayDistributionContext = (
  distributionCount: number,
  ctx: DistroContext
): void => {
  const { numberOfDistributions, setNumberOfDistributions } = ctx;

  if (distributionCount !== numberOfDistributions) {
    setNumberOfDistributions(distributionCount);
  }
};
