import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { css } from "emotion";
import { LineItemQuote, Quote } from "../CommonModels";

// classes
const cn = {
  priceTableRowHeader: css`
    font-weight: bold;
  `,
};

type Props = {
  quote: Quote,
};

export default function CreditPurchasingQuote({ quote }: Props) {
  return <Table padding="dense">
    <TableBody>
      <TableRow>
        <TableCell>
          <div className={`${cn.priceTableRowHeader}`}>Deal</div>
        </TableCell>
        <TableCell>
          <div className={`${cn.priceTableRowHeader}`}># of Credits</div>
        </TableCell>
        <TableCell>
          <div className={`${cn.priceTableRowHeader}`}>MSRP</div>
        </TableCell>
        <TableCell>
          <div className={`${cn.priceTableRowHeader}`}>Price</div>
        </TableCell>
      </TableRow>
      {
        quote.lineItems.map((li: LineItemQuote) => (
          <TableRow id={li.lineItemId}>
            <TableCell>{li.deal ? li.deal.name : 'n/a'}</TableCell>
            <TableCell>{li.numberOfCredits}</TableCell>
            <TableCell>${li.msrpValue}</TableCell>
            <TableCell>${li.price}</TableCell>
          </TableRow>
        ))
      }
    </TableBody>
  </Table>;
}
