import { colors } from '@peachjar/components';

export const ERROR_TYPES = Object.freeze({
  PRICE_MISMATCH: 'price_mismatch',
  PROMO_CODE_ERRORS: [
    'promo_expired',
    'promo_not_applicable',
    'promo_not_valid',
    'promo_already_used',
    'promo_unknown',
    'promo_invalid',
  ],
});

export const FLYER_PURCHASE_DISCOUNT_CODES = Object.freeze({
  AVAILABLE_CREDITS_APPLIED: 'AVAILABLE_CREDITS_APPLIED',
  STARTER_KIT_CODE: 'STARTER_KIT',
});

export const DEALS_SYSTEM_ERROR = `Your payment did not go through because something has recently changed. Please <span onClick={window.location.reload()} style="color: ${colors.jungle}; cursor: pointer;">refresh</span> and try again. If you need assistance, contact us at (858) 997-2117.`;

export default ERROR_TYPES;
