import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import portalBffApolloClient from '../../../../../../_app/apollo/portalBFF.apolloClient';
import CREATE_DRAFT_CAMPAIGN_MUTATION from '../../../gql/CreateDraftCampaignMutation.graphql';
import MutationButton from '../../../../../../components/MutationButton';
import {
  setCommunityFreeAppId,
  setCommunityFreeSchoolSelectionLimit,
} from '../../../../../campaigns.duck';
import { DEFAULT_COMM_FREE_SCHOOL_SELECTION_LIMIT } from '../../../../../shared/constants';

type Props = {
  flyerId: string;
  disabled: boolean;
  skip: boolean;
  handleSuccess: (num: number) => void;
  children: React.ReactNode;
  applicationId?: string | null;
};

const CreateDraftCampaignButton = ({
  flyerId,
  handleSuccess,
  applicationId = null,
  actions,
  skip = false,
  ...rest
}: Props) => {
  const variables = applicationId ? { flyerId, applicationId } : { flyerId };

  return (
    <MutationButton
      client={portalBffApolloClient}
      mutation={CREATE_DRAFT_CAMPAIGN_MUTATION}
      variables={variables}
      handleSuccess={data => {
        const campaignId = get(data, 'createDraftCampaign.id');
        const appId = get(data, 'createDraftCampaign.application.id', null);
        const commFreeSchoolLimit = get(
          data,
          'createDraftCampaign.application.schoolSelectionLimit',
          DEFAULT_COMM_FREE_SCHOOL_SELECTION_LIMIT
        );

        if (appId) {
          actions.setCommunityFreeAppId(appId);
        }

        actions.setCommunityFreeSchoolSelectionLimit(commFreeSchoolLimit);
        handleSuccess(campaignId);
      }}
      refetchQueries={() => ['getMyCampaigns']}
      {...rest}
    />
  );
};

export default connect(
  null,
  dispatch => ({
    actions: bindActionCreators(
      { setCommunityFreeAppId, setCommunityFreeSchoolSelectionLimit },
      dispatch
    ),
  })
)(CreateDraftCampaignButton);
