import React from 'react';
import config from '../../config';

const { REACT_APP_DEALS_URL } = config;

const RedirectDeals = () => {
  React.useEffect(() => {
    window.location.href = REACT_APP_DEALS_URL;
  }, []);

  return null;
};

export default RedirectDeals;
