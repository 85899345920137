import dev1 from './dev-1.png';
import dev2 from './dev-2.png';
import dev3 from './dev-3.png';
import dev4 from './dev-4.png';
import dev5 from './dev-5.png';
import dev6 from './dev-6.png';
import dev7 from './dev-7.png';
import dev8 from './dev-8.png';
import dev9 from './dev-9.png';
import dev10 from './dev-10.png';
import dev11 from './dev-11.png';

export const assetArray = [
  dev1,
  dev2,
  dev3,
  dev4,
  dev5,
  dev6,
  dev7,
  dev8,
  dev9,
  dev10,
  dev11,
];
