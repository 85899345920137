import { graphql, compose } from 'react-apollo';
import { REHYDRATE_PREPARE_CACHE, PREPARE_QUERY } from './Prepare/gql/Prepare.graphql';
import {
  REHYDRATE_DETAILS_CACHE,
  HANDLE_CTA_DATA_MUTATION,
  HANDLE_PRIMARY_CTA_SELECTION_MUTATION,
  HANDLE_SECONDARY_CTA_SELECTION_MUTATION,
} from './Details/gql/Details.graphql';
import { REHYDRATE_DELIVERY_CACHE } from './Delivery/gql/Delivery.graphql';
import { REHYDRATE_SUMMARY_CACHE } from './Summary/gql/Summary.graphql';

import CreateCampaign from './CreateCampaign';

export default compose(
  graphql(REHYDRATE_PREPARE_CACHE, { name: 'rehydratePrepareCache' }),
  graphql(REHYDRATE_DETAILS_CACHE, { name: 'rehydrateDetailsCache' }),
  graphql(REHYDRATE_DELIVERY_CACHE, { name: 'rehydrateDeliveryCache' }),
  graphql(REHYDRATE_SUMMARY_CACHE, { name: 'rehydrateSummaryCache' }),
  graphql(HANDLE_CTA_DATA_MUTATION, { name: 'rehydrateCTAData' }),
  graphql(HANDLE_PRIMARY_CTA_SELECTION_MUTATION, {
    name: 'rehydratePrimaryCTA',
  }),
  graphql(HANDLE_SECONDARY_CTA_SELECTION_MUTATION, {
    name: 'rehydrateSecondaryCTA',
  })
)(CreateCampaign);
