import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import { elements, link } from '@peachjar/components';
import Dashed from '@peachjar/ui/dist/lib/components/Dashed';
import LastStatus from '../../all_approvals/components/LastStatus';
import styles from './InfoPopover.styles';
import popoverConstants from './InfoPopover.constants';
import { Status } from '../../all_approvals/approvals.duck';

const { Headline2, Note } = elements.typography;
const { orgTypes } = popoverConstants;

type Props = {
  data: { [key: string]: any };
  styleOverrides?: { [key: string]: any };
  originSettings?: {
    vertical: string;
    horizontal: string;
  };
  transformSettings?: {
    vertical: string;
    horizontal: string;
  };
};

type State = {
  anchorEl: null | React.ReactNode;
};

class InfoPopover extends React.Component<Props, State> {
  state = {
    anchorEl: null,
  };

  // set default props values for popover positioning
  static defaultProps = {
    styleOverrides: {
      top: '-4px',
    },
    originSettings: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformSettings: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  };

  setPopoverState = (event: any): void => {
    const { currentTarget } = event;
    const { anchorEl } = this.state;

    this.setState({ anchorEl: anchorEl ? null : currentTarget });
  };

  anchored = (
    url: string,
    customText: null | string = null
  ): React.ReactNode => {
    if (customText) {
      return (
        <a
          href={url}
          className={`${link} ${styles.orgLink}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {customText}
        </a>
      );
    }

    return (
      <a
        href={url}
        className={`${link} ${styles.orgLink}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {url}
      </a>
    );
  };

  renderSellerTaxIdAndLetter = (
    taxId: string,
    letterUrl: string
  ): React.ReactNode => {
    const sellerTaxId = taxId || (
      <Dashed testid="renderSellerTaxIdAndLetter-sellerTaxId" />
    );
    const sellerLetterUrl = letterUrl ? (
      this.anchored(letterUrl, 'View')
    ) : (
      <Dashed testid="renderSellerTaxIdAndLetter-sellerLetterUrl" />
    );

    return (
      <>
        <Note className={styles.note}>
          <span className={styles.label} data-testid="text-org-taxid">
            Tax ID or Business Number:
          </span>{' '}
          {sellerTaxId}
        </Note>
        <Note className={styles.note}>
          <span className={styles.label} data-testid="text-org-lod">
            Letter of Determination:
          </span>{' '}
          {sellerLetterUrl}
        </Note>
      </>
    );
  };

  render() {
    const {
      originSettings,
      transformSettings,
      styleOverrides,
      data: {
        sellerType,
        sellerTaxId,
        sellerTaxLodUrl,
        sellerEmail,
        sellerUrl,
        sellerPhone,
        sellerCompanyName,
        lastStatus,
        lastStatusUpdate,
      },
    } = this.props;
    const { anchorEl } = this.state;
    const isOpen = Boolean(anchorEl); // is popover currently open

    const showSellerTaxIdAndLetter = sellerType === 'nonprofit';
    const orgUrl = sellerUrl ? (
      this.anchored(sellerUrl)
    ) : (
      <Dashed testid="website" />
    );
    const orgEmail = sellerEmail || <Dashed testid="InfoPopover-orgEmail" />;
    const orgPhone = sellerPhone || <Dashed testid="InfoPopover-orgPhone" />;

    return (
      <div data-testid="link-info-popover" className={styles.wrapper}>
        <span
          className={styles.popoverText}
          onClick={this.setPopoverState}
          data-testid="click-popover-icon"
        >
          <svg
            className={styles.profileIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              id="profileIcon"
              fill="#3A833C"
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          Profile
        </span>
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={this.setPopoverState}
          anchorOrigin={originSettings as any}
          transformOrigin={transformSettings as any}
          style={styleOverrides}
        >
          <div className={styles.content} data-testid="section-popover">
            <div className={styles.kicker}>
              <Headline2
                className={styles.orgHeadline}
                data-testid="text-org-companyname"
              >
                {sellerCompanyName}
              </Headline2>
              <div className="statusUpdate">
                <LastStatus
                  status={lastStatus as Status}
                  updatedAt={lastStatusUpdate}
                />
              </div>
            </div>
            <div className={styles.section}>
              <Note className={styles.note}>
                <span className={styles.label} data-testid="text-org-website">
                  Website:
                </span>{' '}
                {orgUrl}
              </Note>
              <Note className={styles.note}>
                <span className={styles.label} data-testid="text-org-email">
                  Email:
                </span>{' '}
                {orgEmail}
              </Note>
              <Note className={styles.note}>
                <span className={styles.label} data-testid="text-org-phone">
                  Phone:
                </span>{' '}
                {orgPhone}
              </Note>
            </div>
            <div className={styles.section}>
              <Note className={styles.note}>
                <span className={styles.label} data-testid="text-org-type">
                  Org Type:
                </span>{' '}
                {orgTypes[sellerType]}
              </Note>
              {showSellerTaxIdAndLetter &&
                this.renderSellerTaxIdAndLetter(sellerTaxId, sellerTaxLodUrl)}
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

export default InfoPopover;
