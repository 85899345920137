import React from 'react';
import { css } from 'emotion';
import { colors } from '@peachjar/components';
import { DEALS_SYSTEM_ERROR } from '../../deals/constants';

const InlineErrors = ({ errors }) => (
  <div className={cn.inlineError}>
    {errors.map(({ message }) => {
      //check for Graph or Broker errors and swap out for rendering
      const messageToRender = 
        (message.indexOf('GraphQL') !== -1 || message.indexOf('DefaultBroker') !== -1) ? DEALS_SYSTEM_ERROR : message;
      return (
        <p className={cn.inlineErrorTxt}>
          <span className={cn.errorType}>Error: </span>
          <span dangerouslySetInnerHTML={{ __html: messageToRender }}></span>
        </p>
      );
    })}
  </div>
);

const cn = {
  inlineError: css`
    font-family: 'Proxima Nova', Helvetica, sans-serif;
    padding: 16px;
    background-color: #ffebe5;
    border-radius: 3px;
  `,
  inlineErrorTxt: css`
    color: ${colors.prussian};
    font-size: 13px;
    margin: 0;
  `,
  errorType: css`
    font-weight: bold;
  `,
};

export default InlineErrors;
