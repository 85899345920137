import React, { useContext } from 'react';

import CampaignSlatContents from './CampaignSlatContents';
import CampaignSlatFragments from './gql/CampaignSlatContentsFragments.graphql';
import AppDrawerContext from '../../../_app/context/AppDrawerContext';
import Slat from './Slat';
const CampaignSlat = ({ campaign, onRemoveDraftCampaign }) => {
  const { toggleDrawer, setDrawerContext } = useContext(AppDrawerContext);
  const flyerId = campaign.legacyFlyerId;
  return (
    <div
      onClick={() => {
        setDrawerContext('FlyerDetails');
        toggleDrawer({ flyerId, campaign });
      }}
    >
      <Slat>
        <CampaignSlatContents
          campaign={campaign}
          onRemoveDraftCampaign={onRemoveDraftCampaign}
        />
      </Slat>
    </div>
  );
};

CampaignSlat.fragments = CampaignSlatFragments;

export default CampaignSlat;
