import React from 'react';
import { css } from 'react-emotion';
import { elements } from '@peachjar/components';
import { graphql, compose } from 'react-apollo';
import HouseSvg from './House.svg';
import HouseGrayscaleSvg from './HouseGrayscale.svg';
import SchoolSvg from './School.svg';
import SchoolGrayscaleSvg from './SchoolGrayscale.svg';
import SelectionBox from './SelectionBox';
import {
  SET_SELECTED_AUDIENCE_MUTATION,
  SET_CHECKBOX_SELECTIONS_MUTATION,
} from '../../../gql/Delivery.graphql';

const { Label } = elements.typography;

const audienceMap = {
  parents: {
    label: 'Parents',
    imageSelected: <HouseSvg />,
    imageUnselected: <HouseGrayscaleSvg />,
  },
  staff: {
    label: 'Staff',
    imageSelected: <SchoolSvg />,
    imageUnselected: <SchoolGrayscaleSvg />,
  },
};

const styles = {
  audience: css`
    padding-top: 32px;
    padding-bottom: 32px;
  `,
};

const AudienceBox = ({
  type,
  selectedAudience,
  setSelectedAudience,
  setCheckboxSelections,
  disabled,
}) => (
  <SelectionBox
    onClick={() => {
      // reset selections when change audience
      setCheckboxSelections({ variables: { checkboxSelections: [] } });
      setSelectedAudience({ variables: { type } });
    }}
    dataTestId={`click-${type}Audience`}
    isSelected={selectedAudience === type}
    disabled={disabled}
  >
    <div
      className={`layout-column layout-align-center-center ${styles.audience}`}
    >
      {selectedAudience === type
        ? audienceMap[type].imageSelected
        : audienceMap[type].imageUnselected}

      <div className="mt-3">
        <Label>{audienceMap[type].label}</Label>
      </div>
    </div>
  </SelectionBox>
);

export default compose(
  graphql(SET_SELECTED_AUDIENCE_MUTATION, { name: 'setSelectedAudience' }),
  graphql(SET_CHECKBOX_SELECTIONS_MUTATION, { name: 'setCheckboxSelections' })
)(AudienceBox);
