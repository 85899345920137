import { MapBounds } from '../types';
import { get } from 'lodash';
import {
  DEFAULT_MAX_LONGITUDE,
  DEFAULT_MIN_LONGITUDE,
} from './SchoolFinder/constants';
import { range, intersection } from 'lodash';

// assumes the selections are schools
export const getSelectionsWithDistrictGuidelines = checkboxSelections => {
  const result = checkboxSelections.filter(
    selection =>
      get(selection, 'district.url') && get(selection, 'district.name')
  );

  return result;
};

// Filter

export const filterSchoolsByGradeRange = (schools, gradesArr, gradeFilter) => {
  const { min, max } = gradeFilter;

  // Get filter index from gradesArr
  const minIndex = gradesArr.findIndex(grade => grade.value === min);
  const maxIndex = gradesArr.findIndex(grade => grade.value === max);
  const indexGradeRange = range(minIndex, maxIndex + 1);

  // Add index from gradesArr to schools to have something to filter on
  const schoolsWithMappedGradeIndex = schools.map(school => ({
    ...school,
    lowestGradeOfferedIndex: gradesArr.findIndex(
      grade => grade.value === school.lowestGradeOffered
    ),
    highestGradeOfferedIndex: gradesArr.findIndex(
      grade => grade.value === school.highestGradeOffered
    ),
  }));

  const filteredSchools = schoolsWithMappedGradeIndex.filter(school => {
    const schoolMinIndex = gradesArr.findIndex(
      grade => grade.value === school.lowestGradeOffered
    );
    const schoolMaxIndex = gradesArr.findIndex(
      grade => grade.value === school.highestGradeOffered
    );
    const schoolRange = range(schoolMinIndex, schoolMaxIndex + 1);
    return intersection(indexGradeRange, schoolRange).length > 0;
  });

  return filteredSchools;
};

export const filterSchoolsByAudience = (schools, audienceFilter) => {
  const activeFilters = Object.keys(audienceFilter).filter(
    filterType => audienceFilter[filterType]
  );

  if (!activeFilters.length) {
    return schools;
  } else {
    return schools.filter(school => audienceFilter[school.audienceType]);
  }
};

export const getCurrentCenter = map => {
  if (map) {
    const center = map.getCenter();

    return { latitude: center.lat(), longitude: center.lng() };
  }
};

export const getCurrentBounds = map => {
  const bounds = map.getBounds();
  let currentBounds;

  if (bounds) {
    currentBounds = {
      northEast: {
        latitude: bounds.getNorthEast().lat(),
        longitude: bounds.getNorthEast().lng(),
      },
      southWest: {
        latitude: bounds.getSouthWest().lat(),
        longitude: bounds.getSouthWest().lng(),
      },
    };
  }

  return currentBounds;
};

export const handleMapEdgeCoordinates = (mapBounds: MapBounds) => {
  const { northEast, southWest } = mapBounds;
  // we don't need to worry about unflipping latitudes because google maps doesn't stitch the maps north/south.

  // Corrects ranges on the west/east stitching of the map. This assumes we are not selecting schools in Russia.
  // If we, in the future, would like to select schools in Russia(or anywhere on the east of the map), we need to
  // do 2 filters: 1 from SW long to max long(180) and one from min long(-180) to NE long and combine the two sets.
  if (southWest.longitude > northEast.longitude) {
    southWest.longitude =
      DEFAULT_MIN_LONGITUDE - (DEFAULT_MAX_LONGITUDE - southWest.longitude);
  }

  return mapBounds;
};

export const scrollToListItem = (list: HTMLElement, listItem: HTMLElement) => {
  let scrollTimeout: ReturnType<typeof setTimeout>;

  const triggerListItemFocus = () => {
    listItem.focus();
    list.scrollTo({ top: listItem.offsetTop, left: 0, behavior: 'smooth' });
    list.removeEventListener('scroll', scrollToListItemHandler);
  };

  const scrollToListItemHandler = () => {
    // constantly clear and set timeout during scrolling until last scroll event. Then fires.
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(triggerListItemFocus, 100);
  };

  list.scrollTo({ top: listItem.offsetTop, left: 0, behavior: 'smooth' });
  list.addEventListener('scroll', scrollToListItemHandler);

  // this will focus in the case that there is no scroll. It's overwritten if there is a scroll.
  scrollTimeout = setTimeout(triggerListItemFocus, 100);
};

export const getZIndex = (type, isPin) => {
  if (isPin === 1 && type === 'default') {
    return 10;
  }

  if (isPin === 1 && type === 'selected') {
    return 100;
  }

  if (type === 'default') {
    return 5;
  }

  return 50;
};
