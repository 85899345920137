import * as React from 'react';
import { css, cx } from 'emotion';
import { elements } from '@peachjar/components';

const { Headline1 } = elements.typography;

type Props = {
  children: React.ReactNode;
  className?: string;
};

const SubappHeader = ({ children, className }: Props) => (
  <Headline1 className={cx(classNames.subappHeader, className)}>
    {children}
  </Headline1>
);

SubappHeader.defaultProps = { className: '' };

const classNames = {
  subappHeader: css`
    font-family: Proxima Nova;
    padding: 4rem 0 2.5rem 0;
    margin: 0;
  `,
};

export default SubappHeader;
