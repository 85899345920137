import React from 'react';

const CommunityFreeBadge = () => (
  <svg
    width="65px"
    height="65px"
    viewBox="0 0 65 65"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Org-Portal---Jared"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Internal-Portal:-Comm-Free---Pending-Apps"
        transform="translate(-254.000000, -192.000000)"
      >
        <g id="comm-free-tag" transform="translate(254.000000, 192.000000)">
          <path
            d="M6,0 L65,0 L65,0 L0,65 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z"
            id="Rectangle-29"
            fill="#576183"
          ></path>
          <g
            id="favorite-24px"
            transform="translate(10.000000, 11.000000)"
            fill="#FFFFFF"
          >
            <path
              d="M14.5,1.09601217e-12 C17.58,1.09601217e-12 20,2.42 20,5.5 C20,9.28 16.6,12.36 11.45,17.04 L10,18.35 L8.55,17.03 C3.4,12.36 0,9.28 0,5.5 C0,2.42 2.42,1.09601217e-12 5.5,1.09601217e-12 C7.24,1.09601217e-12 8.91,0.81 10,2.09 C11.09,0.81 12.76,1.09601217e-12 14.5,1.09601217e-12 Z M7.84,4.22 C5.89,4.22 4.34,5.62 4.34,7.67 C4.34,9.72 5.89,11.12 7.84,11.12 C9.36,11.12 10.2,10.31 10.68,9.48 L9.46,8.89 C9.18,9.44 8.58,9.86 7.84,9.86 C6.68,9.86 5.8,8.93 5.8,7.67 C5.8,6.41 6.68,5.48 7.84,5.48 C8.58,5.48 9.18,5.91 9.46,6.45 L10.68,5.85 C10.21,5.02 9.36,4.22 7.84,4.22 Z M16.25,4.33 L11.53,4.33 L11.53,11 L12.95,11 L12.95,8.22 L16.18,8.22 L16.18,7 L12.95,7 L12.95,5.55 L16.25,5.55 L16.25,4.33 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CommunityFreeBadge;
