import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { components } from '@peachjar/components';
import portalBffApolloClient from '../../../../_app/apollo/portalBFF.apolloClient';
import DraftCampaigns from '../DraftCampaigns';

const { ButtonFlatLarge } = components.Buttons;

export const REMOVE_DRAFT_CAMPAIGN = gql`
  mutation deleteDraftCampaign($campaignId: String!) {
    deleteDraftCampaign(campaignId: $campaignId) {
      id
      isDeleted
    }
  }
`;

const MutationButton = ({ campaignId, onCompleted, onError }) => {
  return (
    <Mutation
      client={portalBffApolloClient}
      mutation={REMOVE_DRAFT_CAMPAIGN}
      onCompleted={onCompleted}
      onError={onError}
    >
      {removeDraftCampaign => (
        <ButtonFlatLarge
          onClick={() => removeDraftCampaign({ variables: { campaignId } })}
        >
          Submit
        </ButtonFlatLarge>
      )}
    </Mutation>
  );
};

export default MutationButton;
