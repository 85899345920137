import React from 'react';
import { get, find } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { colors } from '@peachjar/components';
import { getAreAllItemsSelected } from './utils';

const { silver, jungle, slate, mint, leaf } = colors;

const styles = theme => ({
  roundedCorner: {
    'border-radius': '3px 3px 0 0',
  },
  root: {
    width: 'auto',
    padding: '.75rem 1.50rem',
    border: `1px solid ${silver}`,
    backgroundColor: theme.palette.background.paper,
  },
  rootSelected: {
    backgroundColor: mint,
  },
  checkboxRoot: {
    color: slate,
    '&$checked': {
      color: jungle,
    },
  },
  checkboxRootNew: {
    color: slate,
    '&$checked': {
      color: leaf,
    },
  },
  checked: {},
});

const DistrictCheckboxListHeader = ({
  uploaderLevel,
  setIsSingleCheckboxAltered,
  district,
  selectedAudience,
  className,
  checkboxSelections,
  toggleAllCheckboxes,
  setCheckboxSelections,
  onSelectionUpdated,
  classes,
}) => {
  const isDistrictOrSchool =
    uploaderLevel === 'district' || uploaderLevel === 'school';
  const hasItems = district && district.audiences[selectedAudience].length > 0;
  const areAllItemsSelected = getAreAllItemsSelected(
    checkboxSelections,
    selectedAudience,
    district
  );
  const areSomeItemsSelected =
    district &&
    district.audiences[selectedAudience].some(school => {
      return checkboxSelections.find(selectedSchool => {
        return selectedSchool.schoolId === school.schoolId;
      });
    }) &&
    !areAllItemsSelected;

  const parentSchoolsCheckboxText = `All Schools (${district.audiences[selectedAudience].length})`;
  const staffSchoolsCheckboxText = `All Staff Groups (${district.audiences[selectedAudience].length})`;
  const schoolsCheckboxText =
    selectedAudience === 'parents'
      ? parentSchoolsCheckboxText
      : staffSchoolsCheckboxText;

  return (
    <ListItem
      className={`${classes.root} ${areAllItemsSelected &&
        classes.rootSelected} ${isDistrictOrSchool &&
        classes.roundedCorner} ${className}`}
      key={schoolsCheckboxText}
      role="listitem"
      dense
      button
    >
      <Checkbox
        className="p-0"
        classes={{
          root: isDistrictOrSchool
            ? classes.checkboxRootNew
            : classes.checkboxRoot,
          checked: classes.checked,
        }}
        checked={hasItems && (areSomeItemsSelected || areAllItemsSelected)}
        tabIndex={-1}
        data-testid="districtCheckbox"
        disableRipple
        indeterminate={areSomeItemsSelected}
        onChange={() => {
          const updatedCheckboxSelections = toggleAllCheckboxes(
            district,
            selectedAudience,
            setCheckboxSelections,
            checkboxSelections
          );
          onSelectionUpdated(updatedCheckboxSelections);
          setIsSingleCheckboxAltered(true);
        }}
      />
      <ListItemText primary={schoolsCheckboxText} />
    </ListItem>
  );
};

export default withStyles(styles)(DistrictCheckboxListHeader);
