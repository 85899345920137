import React from 'react';
import NearbySchoolsMessage from './components/NearbySchoolsMessage';
import HiddenSchoolsMessage from './components/HiddenSchoolsMessage';
import GenericNoSchoolsMessage from './components/GenericNoSchoolsMessage';
import { MapVariables } from '../../../types';
import { AudienceFilterType } from '../../Filters/AudienceFilter';
import { GradeFilterType } from '../../Filters/GradeFilter';
import { gradeFilterDefault } from '../../../../../../../../../shared/constants';

type Props = {
  areFiltersSetToDefault: boolean,
  totalHiddenSchools: number,
  totalNearbySchools: number,
  setPreviousRedoSearchZoom: (zoom: number | null) => void;
  setSearchValue: (value: string) => void;
  setMapVariables: (mapVariables: MapVariables) => void;
  setInputValue: (value: string) => void;
  setGradeFilter: (filter: AudienceFilterType) => void;
  setAudienceFilter: (filter: GradeFilterType) => void;
  map: any,
};

const EmptyListMessage = ({
  areFiltersSetToDefault,
  setGradeFilter,
  setAudienceFilter,
  totalHiddenSchools,
  totalNearbySchools,
  setMapVariables,
  setSearchValue,
  setInputValue,
  setPreviousRedoSearchZoom,
  map,
}: Props) => {
  const areHiddenSchools = totalHiddenSchools > 0 && !areFiltersSetToDefault;
  const areNearbySchools =
    (totalHiddenSchools === 0 || areFiltersSetToDefault) &&
    totalNearbySchools > 0;

  switch (true) {
    case areHiddenSchools:
      return (
        <HiddenSchoolsMessage
          totalHiddenSchools={totalHiddenSchools}
          setGradeFilter={setGradeFilter}
          setAudienceFilter={setAudienceFilter}
          gradeFilterDefault={gradeFilterDefault}
        />
      );
    case areNearbySchools:
      return (
        <NearbySchoolsMessage
          totalNearbySchools={totalNearbySchools}
          setMapVariables={setMapVariables}
          setSearchValue={setSearchValue}
          setInputValue={setInputValue}
          setPreviousRedoSearchZoom={setPreviousRedoSearchZoom}
          map={map}
        />
      );
    default:
      return <GenericNoSchoolsMessage />;
  }
};

export default EmptyListMessage;
