import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { cloneDeep } from 'lodash';
import { components, elements, colors } from '@peachjar/components';
import idx from 'idx';
import { css } from 'emotion';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import ErrorBoundary from '../all_approvals/components/ErrorBoundary';
import Search from '../components/Search';
import SearchDetails from '../components/SearchDetails';
import FlyerReportingCard from '../_app/components/FlyerReportingCard/FlyerReportingCard';
import LoadingSpinner from '../components/LoadingSpinner';
import MetricsCheatSheet from './components/MetricsCheatSheet';
import { APP_ROUTES } from '../_app/constants';
import reportingSearchSVG from '../assets/reporting-search.svg';
import noSearchResultsSVG from '../assets/turtle.svg';
import reportingEmptyStateSVG from '../assets/reporting_empty_uploadflyer.svg';
import portalBffApolloClient from '../_app/apollo/portalBFF.apolloClient';
import { FLYER_METRICS_QUERY } from '../_app/components/FlyerReportingCard/FlyerReportingCard.graphql';
import { isSecondaryCTADataEmpty } from '../campaigns/Create-Campaign/gql/utils';

const { Pagination } = components;
const {
  typography: { Note },
} = elements;

const emptyStateContent = {
  allFlyers: {
    copy:
      "Looks like you don't have any flyers in reporting yet. Flyers will appear once they have been sent to the distribution queue.",
    illustration: reportingEmptyStateSVG,
  },
  myFlyers: {
    copy:
      "Looks like you haven't uploaded any flyers yet. Flyers will appear once you have uploaded a flyer and it has been sent to the distribution queue.",
    illustration: reportingEmptyStateSVG,
  },
};

const getEmptyPageState = location => {
  const emptyStateObj =
    location.pathname === APP_ROUTES.reporting.myFlyers
      ? emptyStateContent.myFlyers
      : emptyStateContent.allFlyers;
  return (
    <div className={cn.searchIllustration}>
      <img src={emptyStateObj.illustration} width="500" height="352" />
      <div className={cn.searchTextContainer}>
        <Note className={cn.searchText}>{emptyStateObj.copy}</Note>
      </div>
    </div>
  );
};

const ReportingFlyersContent = ({
  sodUser,
  activeFlyerSet,
  offset,
  updateQueryVariables,
  loading,
  cheatSheetOpen,
  onCloseCheatSheet,
  history,
  location,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [showSearchDetails, setShowSearchDetails] = useState(false);
  // don't show search results illustration on first render
  const [showNoResultsIllustration, setNoResultsIllustration] = useState(false);
  const searchInputRef = useRef(null);
  const LIMIT = 15;
  const ITEMS_PER_PAGE = 15;
  const KEYCODE_FOR_ENTER = 13;
  const pageNumber = (offset || 0) / LIMIT + 1;
  const COUNT =
    idx(sodUser, _ => _.flyerReporting[activeFlyerSet].pagination.total) || 0;
  const pageCount = Math.ceil(COUNT / LIMIT);
  const showSearchIllustration = pageNumber === pageCount;
  const showEmptyResultsState = currentQuery === '' && COUNT === 0;
  const flyers =
    idx(sodUser, _ => _.flyerReporting[activeFlyerSet].items) || null;

  const flyerIds = flyers.map(flyer => flyer.flyerId.toString());
  const { loading: flyerMetricsLoading, data: flyerMetricsData } = useQuery(
    FLYER_METRICS_QUERY,
    {
      variables: { flyerIds },
      client: portalBffApolloClient,
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    // show search illustrations from now on (after first render)
    setNoResultsIllustration(true);
  }, []);

  const clearSearch = () => {
    setSearchQuery('');
    focusSearchInput();
    setShowSearchDetails(false);
    updateQueryVariables({
      filter: null,
      offset: 0,
    });
  };

  const focusSearchInput = () => {
    searchInputRef.current.focus();
  };

  const handleSearchOnKeyDown = e => {
    if (e.which === KEYCODE_FOR_ENTER || e.keyCode === KEYCODE_FOR_ENTER) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (searchQuery !== '') {
      updateQueryVariables({
        filter: searchQuery,
        offset: 0,
      });
      setCurrentQuery(searchQuery);
      setShowSearchDetails(true);
    }
  };

  const handleSearchChange = e => {
    const value = idx(e, _ => _.currentTarget.value) || '';
    setSearchQuery(value);
  };

  const generateFlyers = () => {
    // const flyers =
    //   idx(sodUser, _ => _.flyerReporting[activeFlyerSet].items) || null;
    if (!flyers) {
      return null;
    }
    const modFlyers = flyers.map(flyer => {
      const accflyer = cloneDeep(flyer);
      const metricsIndex = flyerMetricsData
        ? flyerMetricsData.getFlyerMetrics.findIndex(
            p => p.flyerId == flyer.flyerId
          )
        : -1;
      let metricsData = null;
      if (metricsIndex > -1) {
        metricsData =
          idx(flyerMetricsData, _ => _.getFlyerMetrics[metricsIndex]) || null;
      }

      if (metricsData) {
        accflyer.uniqueDeliveries = metricsData.uniqueDeliveries.email || 0;
        accflyer.uniqueDisplays =
          parseInt(metricsData.uniqueDisplays.email || '0', 10) +
          parseInt(metricsData.uniqueDisplays.flyerBoard || '0', 10);
        accflyer.uniqueViews =
          parseInt(metricsData.uniqueViews.email || '0', 10) +
          parseInt(metricsData.uniqueViews.flyerBoard || '0', 10);
        accflyer.uniqueActions =
          parseInt(metricsData.uniqueActions.email || '0', 10) +
          parseInt(metricsData.uniqueActions.flyerBoard || '0', 10);
      } else {
        accflyer.uniqueDeliveries = null;
        accflyer.uniqueDisplays = null;
        accflyer.uniqueViews = null;
        accflyer.uniqueActions = null;
      }
      return accflyer;
    });
    return modFlyers.map((flyer, index) => (
      <FlyerReportingCard flyer={flyer} key={index} history={history} />
    ));
  };

  const handlePageChange = newPageNumber => {
    const newOffset = (newPageNumber - 1) * ITEMS_PER_PAGE;

    updateQueryVariables({
      offset: newOffset,
    });
  };
  if (flyerMetricsLoading || loading) {
    return (
      <div style={{ margin: '0 auto' }}>
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <Grid item xs={12} className={cn.zoneA}>
        <div className={cn.search}>
          <Search
            inputRef={searchInputRef}
            value={searchQuery}
            placeholder="Flyer title, keyword, or ID"
            testId="reporting"
            onSearch={handleSearch}
            onChange={handleSearchChange}
            onKeyDown={handleSearchOnKeyDown}
          />
        </div>
        {COUNT !== 0 && (
          <div className={cn.pagination}>
            <Pagination
              activePageNumber={pageNumber}
              pageCount={pageCount}
              testId="reporting-flyer-pagination"
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </Grid>
      {showSearchDetails && !loading && (
        <Grid item xs={12} className={cn.zoneA}>
          <SearchDetails
            searchQuery={currentQuery}
            onReset={clearSearch}
            totalCount={COUNT}
            countPerPage={ITEMS_PER_PAGE}
            pageNumber={pageNumber}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className={`${cn.cheatSheet} ${
          cheatSheetOpen ? cn.cheatSheetOpen : cn.cheatSheetClosed
        }`}
      >
        <MetricsCheatSheet onCloseCheatSheet={onCloseCheatSheet} />
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <ErrorBoundary>{COUNT !== 0 && generateFlyers()}</ErrorBoundary>
            {showEmptyResultsState && getEmptyPageState(location)}
            {!showEmptyResultsState &&
              showNoResultsIllustration &&
              COUNT === 0 && (
                <div className={cn.noResults}>
                  <img src={noSearchResultsSVG} width="541" height="455" />
                  <p className={cn.noResultsText}>
                    Uh oh, unable to find any results. Please try again.
                  </p>
                </div>
              )}
          </>
        )}
      </Grid>
      {!loading && (
        <>
          {COUNT !== 0 && (
            <Grid item xs={12}>
              <div className={`${cn.pagination} footer`}>
                <Pagination
                  activePageNumber={pageNumber}
                  pageCount={pageCount}
                  testId="reporting-flyer-pagination"
                  onPageChange={handlePageChange}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12} className={cn.searching}>
            {showSearchIllustration && (
              <div className={cn.searchIllustration}>
                <img src={reportingSearchSVG} width="500" height="352" />
                <div className={cn.searchTextContainer}>
                  <Note className={cn.searchText}>
                    Looking for more? Flyer reporting contains up to 2 years of
                    history. Contact Peachjar at (858) 997-2117 to go further
                    back.
                  </Note>
                </div>
              </div>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

const cn = {
  zoneA: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  pagination: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 23px;

    &.footer {
      margin-top: -28px;
    }
  `,
  searching: css`
    margin-bottom: 60px !important;
  `,
  searchIllustration: css`
    text-align: center;
    margin-top: 30px;
  `,
  searchTextContainer: css`
    padding: 40px 343px 0 343px;
  `,
  searchText: css`
    font-size: 16px;
  `,
  cheatSheet: css`
    overflow: hidden;
  `,
  cheatSheetOpen: css`
    height: 100%;
  `,
  cheatSheetClosed: css`
    height: 0px;
    padding: 0 !important;
  `,
  noResults: css`
    margin-top: 60px;
    text-align: center;
  `,
  noResultsText: css`
    font-size: 16px;
    color: ${colors.prussian};
    padding-top: 40px;
  `,
};

export default withRouter(ReportingFlyersContent);
