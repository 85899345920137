import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from '@material-ui/core';
import { colors, elements } from '@peachjar/components';
import Dashed from '@peachjar/ui/dist/lib/components/Dashed';
import { format } from 'date-fns';
import FormattedTableCell from './FormattedTableCell';
import ActionTableCell from './ActionTableCell';

const { platinum } = colors;
const { Note } = elements.typography;

type Props = {
  columnHeaders: Array<string>;
  tableData: object[];
  classes: object;
};

const Table = ({ columnHeaders, tableData, classes }: Props) => (
  <Paper elevation={0} style={{ border: '1px #e0e0e0 solid', width: '100%' }}>
    <MuiTable className={classes.table} padding="none">
      <TableHead>
        {columnHeaders.map(columnHeader => (
          <TableCell
            className={classes.head}
            align={getHeaderAlignment(columnHeader)}
            style={{
              height: '64px',
              padding: '0.5rem 1rem',
              textAlign:
                getHeaderAlignment(columnHeader) === 'right'
                  ? 'center'
                  : 'left',
            }}
          >
            <Note muted>{columnHeader}</Note>
          </TableCell>
        ))}
      </TableHead>
      <TableBody>
        {tableData.map(transaction => (
          <TableRow className={classes.row} key={transaction.id}>
            <FormattedTableCell value={transaction.id} width="70px" />

            <FormattedTableCell
              value={formatTimestamp(transaction.timestamp)}
              width="120px"
            />

            <FormattedTableCell
              value={formatType(transaction.type)}
              width="140px"
            />
            <FormattedTableCell
              value={transaction.details}
              displayStatusPill={displayStatusPill(
                transaction.type,
                transaction.extraInfo
              )}
              type={transaction.type}
              typeInfo={transaction.extraInfo}
              width="330px"
            />

            <FormattedTableCell
              align="right"
              value={formatAmountInCents(
                transaction.amountInCents,
                transaction.type
              )}
              width="50px"
            />

            <FormattedTableCell
              align="right"
              value={formatAmountInCredits(transaction.amountInCredits)}
              width="50px"
            />

            <FormattedTableCell
              align="right"
              value={transaction.creditBalance}
              width="50px"
            />

            <ActionTableCell
              actions={transaction.actions}
              width="150px"
              formatButtonText={formatType}
              transaction={transaction}
            />
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  </Paper>
);

const displayStatusPill = (type: string, typeInfo: any): boolean =>
  // Display status pill if listed types and status are provided
  (type === 'view-invoice' && !!typeInfo.status) ||
  (type === 'invoice-created' && !!typeInfo.status);

const getHeaderAlignment = (name: string): string => {
  // Mock requires these columns to be aligned right
  if (
    name === 'Dollar Amount' ||
    name === 'Credit Amount' ||
    name === 'Credit Balance'
  )
    return 'right';
  return 'left';
};

const formatAmountInCents = (cents: number, type: string): string => {
  // Only return amount if value is provided, is positive and is a credits purchased
  if (
    !cents ||
    cents < 0 ||
    !type.match(/credits-purchased/i)
  )
    return <Dashed />;
  if (cents < 0) cents = -1 * cents;
  const centsToDollars = cents / 100;
  const formatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formattedDollars = Number(centsToDollars).toLocaleString(
    'en',
    formatOptions
  );

  return `$${formattedDollars}`;
};

const formatAmountInCredits = (credits: number): string | number => {
  if (credits < 0) return credits;
  if (credits > 0) return `+${credits}`;
  return credits;
};

const formatTimestamp = (timestamp: number): string =>
  format(timestamp, 'MMM d, yyyy');

const formatType = (type: string): string => {
  switch (type) {
    case 'credits-purchased':
      return 'Credit Purchase';
    case 'credits-used':
      return 'Credit Use';
    case 'credits-added':
      return 'Credit Addition';
    case 'credits-removed':
      return 'Credit Removal';
    case 'credits-returned':
      return 'Credit Return';
    case 'credits-transferred-in':
    case 'credits-transferred-out':
      return 'Credit Transfer';
    case 'invoice-created':
      return 'Invoice';
    case 'invoice-cancelled':
      return 'Invoice Cancellation';
    case 'invoice-deleted':
      return 'Invoice Deletion';
    default:
      return type
        .split('-')
        .map(word => word.replace(word[0], word[0].toUpperCase()))
        .join(' ');
  }
};

const styles = {
  table: {
    fontFamily: 'Proxima Nova',
    minWidth: 700,
  },
  row: {
    height: '64px',
    '&:nth-of-type(odd)': {
      backgroundColor: platinum,
    },
  },
  head: {
    backgroundColor: '#ffffff',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
};

export default withStyles(styles)(Table);
export {
  displayStatusPill,
  getHeaderAlignment,
  formatAmountInCents,
  formatAmountInCredits,
  formatTimestamp,
  formatType,
};
