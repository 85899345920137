/* global window */

import * as React from 'react';
import get from 'lodash/get';
import { ApolloConsumer } from 'react-apollo';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { components } from '@peachjar/components';
import { css, cx } from 'emotion';
import gql from 'graphql-tag';
import idx from 'idx';
import { RouteComponentProps } from 'react-router-dom';
import Brandbar from './components/Brandbar/Brandbar';
import Sidebar from './components/Sidebar';
import SidebarDrawer from './components/Sidebar/SidebarDrawer';
import SidebarNavItem from './components/Sidebar/SidebarNavItem';
import SidebarDrawerNavItem from './components/Sidebar/SidebarDrawerNavItem';
import SidebarNavSection from './components/Sidebar/SidebarNavSection';
import FullPageSpinner from './components/FullPageSpinner';
import Error404Screen from './components/Error404Screen';
import { GEOCORE_LOGIN_URL } from '../constants';
import LoadingSpinner from '../components/LoadingSpinner';
import getFeatureFlags from './flagging/flags';
import { APP_ROUTES } from './constants';
import { redirect } from './redirect';
import { SodUser } from '../_api/SodUser';
import config from '../config';
import AppDrawer from './components/AppDrawer/AppDrawer';
import getSubApps from './appConfig';
import { setReduxFeatureFlags } from './flagging/flags.duck';
import { Flags } from './flagging/flags';
import ApprovalSettingsDraftNotification from '../approval_settings/ApprovalSettingsDraftNotification';

import 'bootstrap/dist/css/bootstrap.min.css';

// Custom SidebarNavItemBadgess imported here: (TODO: do programatically, only imported for fragment)
import PendingApprovalsSidebarNavItemBadge from '../pending_approvals/PendingApprovalsSidebarNavItemBadge';

// Subapps imported here: (TODO: do programatically, only imported for fragment)
import PendingApprovals from '../pending_approvals/PendingApprovals';
import AllApprovals from '../all_approvals/AllApprovals';
import MyApprovals from '../my_approvals/MyApprovals';
import Reporting from '../reporting/AllFlyers';
import RoleCheck from './components/RoleCheck';
import ScrollToTop from '../campaigns/shared/components/ScrollToTop';

export type ViewerQueryVariables = Record<string, any>;

const { NotificationContainer: NotificationRoot } = components.Notifications;
const { ModalRoot, SubappContainer } = components;

const { REACT_APP_PEACHJAR_ACCOUNT_LINK } = config;

export type GQLStuff = {
  apolloHelpers: any; // TODO: rename, type this
  updateQueryVariables: (updates: ViewerQueryVariables) => void;
  resetQueryVariables: (...args: any[]) => any;
  currentQueryVariables: ViewerQueryVariables;
  handleUseUploaderQuery: (bool: boolean) => void;
  useUploaderQuery: boolean;
};

type ReduxFlags = Flags & {
  initialized: boolean;
};

type Props = RouteComponentProps & {
  gqlStuff: GQLStuff;
  sodUser?: SodUser;
  flyerReasons: any[] /* App_flyerReasons[], */;
  flags: Flags & {
    initialized: boolean;
  };
  setFeatureFlags: (flags: ReduxFlags) => void;
};

type State = {
  navDrawerOpen: boolean;
  isNavDrawerHovering: boolean;
  appData: any;
};

// type AnyProps = Record<string, any>

class App extends React.Component<Props, State> {
  scrollToRef: React.Ref<any> | null; // eslint-disable-line
  fragments?: Record<string, any>;
  portalNavFeatureFlag: any;
  isPortalNavEnabled: boolean = true;
  shouldRedirect: boolean = false;

  constructor(props: Props) {
    super(props);

    this.scrollToRef = React.createRef();

    this.state = {
      navDrawerOpen: false,
      isNavDrawerHovering: false,
      appData: null,
    };
  }

  async componentDidMount() {
    const { setFeatureFlags } = this.props;
    const flags: Flags = await getFeatureFlags();
    const { apps, sidebarSections } = await getSubApps({ flags });
    this.shouldRedirect =
      (apps === null && sidebarSections === null) || flags.portal_nav === 0;
    setFeatureFlags({ initialized: true, ...flags });
    this.setState({
      appData: {
        apps,
        sidebarSections,
      },
    });
  }

  isSodUser = sodUser => {
    const adminLevel = idx(sodUser, _ => _.permission.adminLevel) || null;
    const approverLevel = idx(sodUser, _ => _.permission.approverLevel) || null;
    const uploaderLevel = idx(sodUser, _ => _.permission.uploaderLevel) || null;
    const staffLevel = idx(sodUser, _ => _.permission.staffLevel) || null;
    const volunteerLevel =
      idx(sodUser, _ => _.permission.volunteerLevel) || null;
    const isAdmin = adminLevel !== null;
    const isApprover = approverLevel !== null;
    const isUploader = uploaderLevel !== null;
    const isStaff = staffLevel !== null;
    const isVolunteer = volunteerLevel !== null;

    return isAdmin || isApprover || isUploader || isStaff || isVolunteer;
  };

  getSidebarConfig = isPortalNavEnabled => {
    return {
      Sidebar: isPortalNavEnabled ? SidebarDrawer : Sidebar,
      SidebarNavItem: isPortalNavEnabled
        ? SidebarDrawerNavItem
        : SidebarNavItem,
      SidebarNavSection,
    };
  };

  handleNavDrawerOpen = action => {
    if (
      action === 'enter' &&
      !this.state.navDrawerOpen &&
      !this.state.isNavDrawerHovering
    ) {
      this.setState({ navDrawerOpen: true, isNavDrawerHovering: true });
    }

    if (action === 'leave') {
      this.setState({ navDrawerOpen: false, isNavDrawerHovering: false });
    }
  };

  render() {
    const { gqlStuff, sodUser, history, flyerReasons, flags } = this.props;
    const portalNavFlag = get(flags, 'portal_nav', 0);
    const isPortalNavEnabled = portalNavFlag === 1;
    const { navDrawerOpen, appData } = this.state;
    const { loading, error } = gqlStuff.apolloHelpers;
    const SidebarConfig = this.getSidebarConfig(isPortalNavEnabled);

    if (!flags.initialized) {
      return <LoadingSpinner />;
    }

    if (!loading && !this.isSodUser(sodUser) && !error) {
      redirect(GEOCORE_LOGIN_URL);
      return <LoadingSpinner />;
    }

    // What this actually means is that the browser path does not match
    // a subapp.  So we should redirect to /dashboard, NOT login...
    if (this.shouldRedirect) {
      redirect('/dashboard');
      return <LoadingSpinner />;
    }

    if (!appData) {
      return <LoadingSpinner />;
    }

    const isReady = sodUser;

    const processModeApps = ['create-campaign'];
    const pathName = history.location.pathname;
    const isProcessMode = pathName.includes(processModeApps);
    const {
      appData: { apps, sidebarSections },
    } = this.state;

    // get active sidebar sections to ensure we don't show unintended items in the nav.
    const activeSideBarSections = {
      sectionLabel: sidebarSections[0].sectionLabel,
      items: sidebarSections[0].items.filter(
        item => item.sideNavEnabled === undefined || item.sideNavEnabled === true
      )
    };

    return isReady ? (
      <ApolloConsumer>
        {client => (
          <div className={classNames.app}>
            <NotificationRoot />
            <ModalRoot />
            <Brandbar
              sodUser={sodUser}
              processMode={isProcessMode}
              pathname={window.location.pathname}
              apolloError={error}
            />
            <div className={classNames.main}>
              {!isProcessMode && (
                <SidebarConfig.Sidebar
                  drawerOpen={navDrawerOpen}
                  onDrawerOpen={this.handleNavDrawerOpen}
                >
                  {isReady ? (
                    <SidebarConfig.SidebarNavSection
                      label={activeSideBarSections.sectionLabel}
                      key={activeSideBarSections.sectionLabel}
                    >
                      {activeSideBarSections.items.map(
                        ({
                          path,
                          label: itemLabel,
                          BadgeComponent,
                          fragmentOnUser,
                          allowedRoles,
                        }) => {
                          if (
                            path === APP_ROUTES.reporting.allFlyers ||
                            path === APP_ROUTES.reporting.myFlyers
                          ) {
                            client.writeData({
                              data: { activeReportingSection: path },
                            });
                          }
                          const user = fragmentOnUser ? { sodUser } : {};
                          const BadgeToRender = BadgeComponent ? (
                            <BadgeComponent {...user} />
                          ) : null;
                          return (
                            <RoleCheck key={path} roles={allowedRoles}>
                              {itemLabel === 'Post & Notify' &&
                              window.location.pathname !== '/campaigns' ? (
                                <div
                                  onClick={() =>
                                    (window.location.href = `${path}`)
                                  }
                                >
                                  <SidebarConfig.SidebarNavItem
                                    path={path}
                                    label={itemLabel}
                                    key={itemLabel}
                                    badge={BadgeToRender}
                                  />
                                </div>
                              ) : (
                                <SidebarConfig.SidebarNavItem
                                  path={path}
                                  label={itemLabel}
                                  key={itemLabel}
                                  badge={BadgeToRender}
                                />
                              )}
                            </RoleCheck>
                          );
                        }
                      )}
                    </SidebarConfig.SidebarNavSection>
                  ) : null}
                </SidebarConfig.Sidebar>
              )}

              <div ref={this.scrollToRef} className={cx(classNames.content)}>
                <SubappContainer
                  className={
                    isPortalNavEnabled ? classNames.portalNavSubapp : ''
                  }
                >
                  <ApprovalSettingsDraftNotification />
                  {isReady && (
                    <Switch>
                      {apps.map(
                        ({ path, Component, fragmentOnUser, allowedRoles }) => {
                          const user = fragmentOnUser ? { sodUser } : {};
                          return (
                            <Route
                              key={path}
                              path={path}
                              render={props => (
                                <ScrollToTop scrollToRef={this.scrollToRef}>
                                  {/* <RoleCheck roles={allowedRoles}> */}
                                  <Component
                                    {...props}
                                    gqlStuff={gqlStuff}
                                    flyerReasons={flyerReasons}
                                    {...user}
                                  />
                                  {/* </RoleCheck> */}
                                </ScrollToTop>
                              )}
                            />
                          );
                        }
                      )}
                      <Route component={Error404Screen} />
                    </Switch>
                  )}
                  <AppDrawer {...this.props} />
                </SubappContainer>
              </div>
            </div>
          </div>
        )}
      </ApolloConsumer>
    ) : (
      <FullPageSpinner />
    );
  }
}

const classNames = {
  app: css`
    height: 100%;
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
  `,
  main: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  `,
  portalNavSubapp: css`
    margin-left: 80px;

    @media screen and (min-width: 1380px) {
      margin-left: 0;
    }
  `,
  content: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    /* min-width: 1188px; 1140px container + 48px padding */
    overflow-y: auto;
    overflow-x: auto;
  `,
};

App.fragments = {
  sodUser: gql`
    fragment App_sodUser on Profile {
      userId
      permission {
        approverLevel
        adminLevel
        uploaderLevel
        staffLevel
        volunteerLevel
      }
      ...Brandbar_sodUser
      # SidebarNavItemBadge Fragments
      # ...PendingApprovalsSidebarNavItemBadge_sodUser
      # Subapp Fragments
      # ...PendingApprovals_sodUser
      # ...AllApprovals_sodUser
      # ...MyApprovals_sodUser
      ...Reporting_sodUser
    }
    ${Brandbar.fragments.sodUser}
    # SidebarNavItem Fragments
    ${/* PendingApprovalsSidebarNavItemBadge.fragments.sodUser */ ''}
    # Subapp Fragments
    ${/* PendingApprovals.fragments.sodUser */ ''}
    ${/* AllApprovals.fragments.sodUser */ ''}
    ${/* MyApprovals.fragments.sodUser */ ''}
    ${Reporting.fragments.sodUser}
  `,
  flyerReasons: gql`
    fragment App_flyerReasons on FlyerReason {
      ...PendingApprovals_flyerReasons
      ...AllApprovals_flyerReasons
      ...MyApprovals_flyerReasons
    }
    ${PendingApprovals.fragments.flyerReasons}
    ${AllApprovals.fragments.flyerReasons}
    ${MyApprovals.fragments.flyerReasons}
  `,
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  setFeatureFlags: (flags: Flags) => dispatch(setReduxFeatureFlags(flags)),
});

export default connect(
  state => {
    const flags = get(state, 'flags');
    return {
      flags,
    };
  },
  mapDispatchToProps
)(withRouter(App));
