import generateSubappConfig from '../_app/generateSubappConfig';
import AccountInfo from './AccountInfo';
import AccountInfoContainer from './AccountInfo.container';
import { APP_ROUTES } from '../_app/constants';
import { accountInfoAllowedRoles } from '../_app/RolesEnum';

const accountInfoSubappConfig = generateSubappConfig({
  path: APP_ROUTES.account_info,
  allowedRoles: accountInfoAllowedRoles,
  isLive: true,
  navItemConfig: {
    label: 'Account Information',
    sideNavEnabled: false,
  },
  appConfig: {
    Component: AccountInfoContainer,
    fragmentOnUser: AccountInfo.fragments.sodUser,
  },
});

export default accountInfoSubappConfig;
