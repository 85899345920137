import React, { Component } from 'react';
import { get } from 'lodash';

export default function withValidation(
  UnwrappedComponent,
  validateFunction,
  validateOnMount
) {
  // eslint-disable-next-line react/display-name
  const WrappedComponent = class extends Component {
    static fragments = UnwrappedComponent.fragments;

    constructor(props) {
      super(props);

      if (!props.name) {
        throw Error(`"name" prop must be provided.`);
      }

      this.state = {
        isValid: true,
      };
    }

    componentDidMount() {
      if (validateOnMount) {
        const { handleIsValid, name } = this.props;
        const isValid = validateOnMount(this.props, this.state);

        // eslint-disable-next-line react/destructuring-assignment
        if (isValid !== this.state.isValid) {
          this.setState({
            isValid,
          });
        }

        handleIsValid(name, isValid);
      }
    }

    performValidation = eventOrPayload => {
      const isEvent =
        eventOrPayload.hasOwnProperty('target') &&
        eventOrPayload.target.hasOwnProperty('value');
      const payload = isEvent ? eventOrPayload.target.value : eventOrPayload;
      const isValid = validateFunction(payload);
      const { handleIsValid, name } = this.props;

      // eslint-disable-next-line react/destructuring-assignment
      if (isValid !== this.state.isValid) {
        this.setState({
          isValid,
        });
      }
      handleIsValid(name, isValid);
    };

    render() {
      return (
        <UnwrappedComponent
          {...this.props}
          // eslint-disable-next-line react/destructuring-assignment
          isValid={this.state.isValid}
          performValidation={this.performValidation}
        />
      );
    }
  };

  Object.keys(UnwrappedComponent).forEach(key => {
    WrappedComponent[key] = UnwrappedComponent[key];
  });

  return WrappedComponent;
}
