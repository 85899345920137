import React, { useState, useContext, useEffect } from 'react';
import idx from 'idx';
import get from 'lodash/get';
import { css } from 'emotion';
import { toString } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { components, colors, elements } from '@peachjar/components';
import quoteToFlyerPurchaseVerificationFields from '../../deals/verification/quoteToFlyerPurchaseVerificationFields';
import { apolloClient as bffClient } from '../apollo/portalBFF.apolloClient';
import useSaveCampaignMutation from '../../helpers/useSaveCampaignMutation';
import { update as updateCampaign } from '../../campaigns/Create-Campaign/Summary/components/SaveAndSubmitCampaignButton';
import {
  GET_FLYER_PURCHASE_QUOTE,
  SUBMIT_FLYER_PURCHASE,
} from '../queries/deals';
import audienceFactory from '../../deals/utils/audienceFactory';
import DealsContext from '../context/DealsContext';
import FlyerPurchaseSummary from './FlyerPurchaseSummary';
import DealsPaymentForm from '../../components/stripe/DealsPaymentForm';
import { NOTIFICATIONS } from '../../_app/constants';
import { ERROR_TYPES, DEALS_SYSTEM_ERROR } from '../../deals/constants';
import SubmitCampaignButton from '../../campaigns/Create-Campaign/Summary/components/SaveAndSubmitCampaignButton';
import FlyerPurchaseEmptyState from '../../_app/components/EmptyState';
import happyTurtle from '../../assets/happy_turtle.svg';
import { useRefetchContext } from '../../campaigns/Campaigns.context';
import { COMMUNITY_FREE_TYPE } from '../../campaigns/campaigns.duck';

type Props = {
  campaignType: string;
  userId: string;
  history: {
    [k: string]: any;
  };
  match: {
    [k: string]: any;
  };
  campaignDetails: {
    [k: string]: any;
  };
  communityFreeConfig: {
    applicationId: string;
    isCommunityFree: boolean;
  };
  postOnlySelections: string[];
  handleGlobalError: (key: string) => void;
  cleanUpAfterSuccess?: () => void;
};

type QuoteError = {
  type: string;
  code: string;
};

const {
  Notifications: { notifyError },
} = components;

const {
  typography: { Headline2, Paragraph },
} = elements;

const FlyerPurchaseSummaryContainer: React.FunctionComponent<Props> = ({
  userId,
  match,
  campaignType,
  history,
  campaignDetails: {
    isFormValid,
    messageToApprover,
    isCampaignFormValid,
    campaignIdMatch,
    history: campaignHistory,
    variables: campaignVariables,
  },
  postOnlySelections,
  communityFreeConfig,
  handleGlobalError,
  cleanUpAfterSuccess = () => {},
}) => {
  const {
    numberOfDistributions,
    dealsInFlight,
    schoolDistributionList,
    refreshCreditBalance,
    setCampaignDetails,
    appliedPromoCodes: APPLIED_PROMO_CODES,
    setAppliedPromoCodes,
    removeAppliedPromoCode,
    clearAppliedPromoCodes,
  } = useContext(DealsContext);
  const [fatalError, setFatalError] = useState(false);
  const [quoteErrors, setQuoteErrors] = useState([]);
  const [purchasing, setPurchasing] = useState(false);
  const [paymentError, setPaymentError] = useState([]);
  const [skipPurchase, setSkipPurchase] = useState(false);
  const [hydratedQuote, setHydratedQuote] = useState(null);
  const purchaseQuoteConfig = {
    promoCodes: [...APPLIED_PROMO_CODES],
    disableRules: [],
    numberOfDistributions,
    numberOfReminders: 0,
    audiences: audienceFactory(schoolDistributionList, postOnlySelections),
  };

  if (campaignType === COMMUNITY_FREE_TYPE && campaignIdMatch !== null) {
    // @ts-ignore
    purchaseQuoteConfig.campaignId = campaignIdMatch;
  }

  const { loading: loadingQuote, error, data: quoteData } = useQuery(
    GET_FLYER_PURCHASE_QUOTE,
    {
      client: bffClient,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          ...purchaseQuoteConfig,
        },
      },
      onCompleted: data => {
        const {
          getFlyerPurchaseQuote: {
            quote: {
              total: { priceInCents },
            },
          },
        } = data;
        if (priceInCents !== undefined && priceInCents === 0) {
          setSkipPurchase(true);
        } else {
          setSkipPurchase(false);
        }
      },
    }
  );

  const purchaseQuote = hydratedQuote
    ? hydratedQuote
    : idx(quoteData, _ => _.getFlyerPurchaseQuote.quote) || null;
  const quoteId = idx(purchaseQuote, _ => _.id) || null;

  const [
    purchaseFlyer,
    { loading: purchaseFlyerLoading, error: purchaseFlyerError },
  ] = useMutation(SUBMIT_FLYER_PURCHASE, {
    client: bffClient,
  });

  const {
    saveCampaign,
    saveCampaignLoading,
    saveCampaignError,
  } = useSaveCampaignMutation({
    client: bffClient,
    variables: campaignVariables,
    update: updateCampaign,
    onError: () => alert('There was an error saving the campaign.'),
    // refetchQueries: () => ['getMyCampaigns'],
  });

  const {
    refetchGetMyCampaignsQuery,
    refetchUploaderQuery,
  } = useRefetchContext();

  const getNewQuote = async (
    code: string | null,
    codeToOmitFromQuote: string | null
  ) => {
    const filteredPromoCodes = codeToOmitFromQuote
      ? APPLIED_PROMO_CODES.filter(c => c !== codeToOmitFromQuote)
      : [];
    const promoCodes = code
      ? new Set([...APPLIED_PROMO_CODES, code])
      : [...filteredPromoCodes];
    const updatedPurchaseQuoteConfig = Object.assign({}, purchaseQuoteConfig, {
      ...purchaseQuoteConfig,
      promoCodes: [...promoCodes],
    });

    const result = await bffClient.query({
      query: GET_FLYER_PURCHASE_QUOTE,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          ...updatedPurchaseQuoteConfig,
        },
      },
    });

    const { data } = result;
    const newQuote = idx(data, _ => _.getFlyerPurchaseQuote.quote) || null;
    const newQuoteErrors =
      idx(data, _ => _.getFlyerPurchaseQuote.errors) || null;

    return { newQuote, newQuoteErrors };
  };

  const resetPromoCodes = errors => {
    errors.forEach(({ code }) => removeAppliedPromoCode(code));
  };

  const handlePromoDiscountRemoval = async (code: string) => {
    const casedCode = code.toLowerCase();
    removeAppliedPromoCode(casedCode);
    const { newQuote, newQuoteErrors } = await getNewQuote(null, casedCode);

    if (
      newQuoteErrors &&
      Array.isArray(newQuoteErrors) &&
      newQuoteErrors.length
    ) {
      handleQuoteAndPurchaseErrors(newQuoteErrors);
    } else {
      setQuoteErrors([]); //reset summary table from any previously thrown promo errors
    }

    setHydratedQuote(newQuote);
  };

  const handlePromoCodeSubmission = async (code: string) => {
    const casedCode = code.toLowerCase();
    const { newQuote, newQuoteErrors } = await getNewQuote(casedCode, null);

    if (
      newQuoteErrors &&
      Array.isArray(newQuoteErrors) &&
      newQuoteErrors.length
    ) {
      if (!newQuoteErrors.some(err => err.code.toLowerCase() === casedCode)) {
        setAppliedPromoCodes(casedCode);
      }
      handleQuoteAndPurchaseErrors(newQuoteErrors);
    } else {
      setAppliedPromoCodes(casedCode);
      setQuoteErrors([]);
    }

    setHydratedQuote(newQuote);
  };

  const resetOrderSummary = () => {
    setQuoteErrors([]);
    setHydratedQuote(null);
  };

  const handleQuoteAndPurchaseErrors = (errors: QuoteError[]) => {
    if (errors && Array.isArray(errors) && errors.length >= 1) {
      const priceMismatchError = errors.filter(
        err => err.type === ERROR_TYPES.PRICE_MISMATCH
      );

      const orderSummaryErrors = errors.filter(err => {
        if (ERROR_TYPES.PROMO_CODE_ERRORS.includes(err.type)) {
          return err;
        }
      });

      if (priceMismatchError && priceMismatchError.length) {
        setPaymentError([
          {
            message: DEALS_SYSTEM_ERROR,
          },
        ]);
      }

      if (orderSummaryErrors && orderSummaryErrors.length) {
        resetPromoCodes(orderSummaryErrors);
        setQuoteErrors([...orderSummaryErrors]);
      }

      if (!priceMismatchError.length && !orderSummaryErrors.length) {
        setPaymentError([...errors]);
      }

      setPurchasing(false);
    }
  };

  const handlePurchase = async ({
    token = '',
    orderId = '',
    paymentType = 'stripe',
  }): void => {
    const paymentDetailsConfig = () => {
      const stripeDetails = { token };
      const paypalDetails = { orderId };
      return paymentType === 'stripe' ? stripeDetails : paypalDetails;
    };

    if (!purchaseFlyerLoading) {
      try {
        setPurchasing(true);
        const purchaseValidationFields = quoteToFlyerPurchaseVerificationFields(
          purchaseQuote
        );
        const { newQuote, newQuoteErrors } = await getNewQuote(null, null);
        const hasPurchaseErrors = newQuoteErrors.some(
          err => err.type === ERROR_TYPES.PRICE_MISMATCH
        );
        const orderSummaryErrors = newQuoteErrors.filter(err => {
          if (ERROR_TYPES.PROMO_CODE_ERRORS.includes(err.type)) {
            return err;
          }
        });

        //always save the campaign
        saveCampaign();

        if (orderSummaryErrors && orderSummaryErrors.length) {
          resetPromoCodes(orderSummaryErrors);
        }

        if (hasPurchaseErrors) {
          handleQuoteAndPurchaseErrors(newQuoteErrors);
          return;
        }

        if (!newQuote) {
          handleGlobalError('genericError');
          return;
        }

        try {
          const updatedPurchaseQuoteConfig = Object.assign(
            {},
            purchaseQuoteConfig,
            { ...purchaseQuoteConfig, promoCodes: [...APPLIED_PROMO_CODES] }
          );

          const purchaseResponse = await purchaseFlyer({
            variables: {
              input: {
                ...updatedPurchaseQuoteConfig,
                ...purchaseValidationFields,
                campaignId: campaignVariables.campaignId,
                legacyFlyerId: toString(campaignVariables.legacyFlyerId),
                quoteId,
                payment: {
                  type: paymentType,
                  details: {
                    ...paymentDetailsConfig(),
                  },
                },
              },
            },
          });

          const {
            errors = [], //system related errors don't follow purchaseFlyer envelope schema
            data: {
              purchaseFlyer: { errors: purchaseErrors },
            },
          } = purchaseResponse;

          if (errors && Array.isArray(errors) && errors.length) {
            setPaymentError([...errors]);
            return;
          }

          if (
            purchaseErrors &&
            Array.isArray(purchaseErrors) &&
            purchaseErrors.length >= 1
          ) {
            handleQuoteAndPurchaseErrors(purchaseErrors);
            setPurchasing(false);
            return;
          }

          setCampaignDetails({});
          // Refetch queries after successful submission
          refetchGetMyCampaignsQuery();
          refetchUploaderQuery();
          cleanUpAfterSuccess();
          history.push(
            `/campaigns/create-campaign/${campaignVariables.campaignId}/confirmation`
          );
        } catch (e) {
          const errorMessage = e.message || e;
          setFatalError(true);
          setPaymentError([{ message: errorMessage }]);
          return;
        }

        clearAppliedPromoCodes();
        refreshCreditBalance();
        setPurchasing(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onPaymentError = () => {
    setPaymentError([
      {
        message: DEALS_SYSTEM_ERROR,
      },
    ]);
    setPurchasing(false);
  };

  useEffect(() => {
    if (dealsInFlight) {
      setPurchasing(true);
      setTimeout(() => {
        setPurchasing(false);
      }, 1000);
    }
  }, [dealsInFlight]);

  const disablePurchase =
    (!isFormValid || purchasing || purchaseFlyerLoading || dealsInFlight) &&
    !fatalError;

  const hasDistributionList =
    schoolDistributionList &&
    Array.isArray(schoolDistributionList) &&
    schoolDistributionList.length >= 1;

  if (!purchaseQuote) {
    return null;
  }

  const submitCopy = communityFreeConfig.isCommunityFree
    ? 'Submit to apply for our Community Free Program.'
    : 'Hit Submit to apply your credits and submit your flyer.';

  return (
    <>
      <Grid item xs={hasDistributionList ? 7 : 12} className={cn.sectionMG}>
        {hasDistributionList && (
          <FlyerPurchaseSummary
            quote={purchaseQuote}
            numberOfDistributions={numberOfDistributions}
            errors={quoteErrors}
            onReset={resetOrderSummary}
            handlePromoCodeSubmission={handlePromoCodeSubmission}
            handlePromoCodeRemoval={handlePromoDiscountRemoval}
          />
        )}
        {!hasDistributionList && (
          <div className={cn.emptyState}>
            <FlyerPurchaseEmptyState>
              <span className={cn.emptyStateText}>
                Edit flyer to select at least one school for flyer distribution.
              </span>
            </FlyerPurchaseEmptyState>
          </div>
        )}
      </Grid>
      {hasDistributionList && (
        <Grid item xs={5} className={`${cn.sectionMG} ${cn.gutter}`}>
          {skipPurchase ? (
            <div className={cn.pymt0}>
              <img src={happyTurtle} />
              <div className={cn.pymt0Content}>
                <Headline2 className={cn.pymt0Headline}>
                  You're all set!
                </Headline2>
                <Paragraph className={cn.pymt0SubHeadline}>
                  {submitCopy}
                </Paragraph>
                <SubmitCampaignButton
                  campaignId={campaignVariables.campaignId}
                  messageToApprover={messageToApprover}
                  prepCampaignSubmission={handlePurchase}
                  handleSuccess={cleanUpAfterSuccess}
                  handleError={() =>
                    alert('There was an error saving the campaign.')
                  }
                  disabled={disablePurchase}
                  history={history}
                  className={css`
                    width: 100%;
                    margin-left: 0 !important;
                  `}
                >
                  Submit
                </SubmitCampaignButton>
              </div>
            </div>
          ) : (
            <DealsPaymentForm
              fieldWidth="337px"
              loading={disablePurchase}
              fatalError={fatalError}
              onError={onPaymentError}
              onSubmit={handlePurchase}
              paymentError={paymentError}
              isFormValid={isCampaignFormValid}
              quote={purchaseQuote}
              purchaseType="flyer"
            />
          )}
        </Grid>
      )}
    </>
  );
};

const cn = {
  sectionMG: css`
    margin-top: 36px !important;
  `,
  emptyState: css`
    margin-top: 88px;
  `,
  emptyStateText: css`
    font-size: 16px;
  `,
  gutter: css`
    margin-left: 32px !important;
  `,
  pymt0: css`
    text-align: center;
    padding: 24px;
    border: 1px solid ${colors.silver};
    border-radius: 6px;
  `,
  pymt0Headline: css`
    font-size: 16px;
  `,
  pymt0SubHeadline: css`
    font-size: 13px;
    margin-bottom: 24px;
    display: block;
  `,
  pymt0Content: css`
    margin-top: 24px;
  `,
  emptyState: css`
    margin-top: 88px;
  `,
  emptyStateText: css`
    font-size: 16px;
  `,
};

const mapDispatchToProps = dispatch => ({
  handleGlobalError: key => {
    dispatch(notifyError(NOTIFICATIONS[key]));
  },
});

export default connect(
  state => ({
    postOnlySelections: state.deals.postOnly,
    campaignType: get(state, 'campaigns.communityFree.type', 'standard'),
  }),
  mapDispatchToProps
)(withRouter(FlyerPurchaseSummaryContainer));
