import React, { useState, useEffect, useContext } from 'react';
import idx from 'idx';
import { css } from 'emotion';
import Grid from '@material-ui/core/Grid';
import { components, colors } from '@peachjar/components';
import useStripe from '../../components/stripe/useStripe';
import InlineErrors from '../../_app/components/InlineErrors';
import PayPalButton from '../../_app/components/PayPalButton/PayPalButton';
import { centsToDollars } from '../../helpers/order';

type IStripeElementsError = {
  error: {
    [prop: string]: any;
  };
};

type IStripeElements = {
  on(type: string, eventCallback: ({ error: object }) => void): void;
};

type SubmitProps = {
  token: string;
  orderId: string;
};

type Props = {
  fieldWidth: string;
  paymentError: string;
  isFormValid?: boolean;
  loading: boolean;
  purchaseType: 'credit' | 'flyer';
  fatalError?: boolean;
  quote: {
    [k: string]: any;
  };
  onError: () => void;
  onSubmit: (obj: SubmitProps) => void;
};

const MISSED_FIELD_ERROR_TXT = 'You missed this field.';

const {
  Buttons: { ButtonPrimaryLarge },
  TextInput,
} = components;

const elementStyles = {
  base: {
    color: '#32325D',
    fontWeight: 500,
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    ':-webkit-autofill': {
      color: '#e39f48',
    },
  },
  invalid: {
    color: '#E25950',

    '::placeholder': {
      color: '#FFCCA5',
    },
  },
};

const elementClasses = {
  focus: 'focused',
  empty: 'empty',
  invalid: 'invalid',
};

const { createElement, getStripeToken } = useStripe();

const stripeCardElement = createElement({
  type: 'card',
  options: {
    style: elementStyles,
    classes: elementClasses,
  },
});

const DealsPaymentForm: React.FunctionComponent<Props> = ({
  fieldWidth = '356px',
  isFormValid = true,
  fatalError = false,
  paymentError,
  purchaseType,
  quote,
  onSubmit,
  onError,
  loading,
}) => {
  const [elementField, setElementState] = useState({
    isActive: false,
    isCompleted: false,
    error: false,
    dirty: false,
  });
  const [elementFieldError, setElementFieldError] = useState('');
  const [name, setName] = useState({
    value: '',
    dirty: false,
  });

  const [stripeLoading, setStripeLoading] = useState(false);

  const isFormDisabled = (): boolean =>
    !elementField.isCompleted || !name.value || !isFormValid;

  const validateForm = () => {
    if (!name.value) {
      setName({ ...name, dirty: true });
    }

    if (!elementField.isCompleted) {
      setElementFieldError(MISSED_FIELD_ERROR_TXT);
      setElementState({
        ...elementField,
        error: true,
        dirty: false,
        isActive: false,
      });
    }
  };

  const addStripeListeners = (element: IStripeElements): void => {
    element.on('change', ({ error, complete }) => {
      const errorMessage = idx(error, _ => _.message) || null;
      if (errorMessage) {
        setElementFieldError(errorMessage);
        setElementState({ ...elementField, error: true, dirty: true });
      } else {
        setElementState({
          ...elementField,
          isCompleted: complete,
          isActive: true,
          error: false,
        });
        setElementFieldError('');
      }
    });

    element.on('blur', ({ error }: IStripeElementsError) => {
      if (element._empty) {
        setElementFieldError(MISSED_FIELD_ERROR_TXT);
        setElementState({
          ...elementField,
          isCompleted: element._complete,
          isActive: false,
          error: true,
        });
      }
    });
  };

  const hasPaymentError = paymentError && paymentError.length >= 1;
  const totalPriceInCents = idx(quote, _ => _.total.priceInCents) || null;
  const totalPayment = centsToDollars(totalPriceInCents).substr(1);

  if (hasPaymentError && stripeLoading) {
    setStripeLoading(false);
  }

  useEffect(() => {
    stripeCardElement.mount('#card-number-element');
    // listen for changes to stripe element
    addStripeListeners(stripeCardElement);
  }, []);

  const disablePayment: boolean = fatalError
    ? true
    : stripeLoading || loading || isFormDisabled();
  const isLoading: boolean = fatalError ? false : stripeLoading || loading;

  return (
    <div className={cn.dealsPymt} data-testid="form-deals-payment">
      <form
        className={cn.stripeForm}
        id="deals-pmt-form"
        onSubmit={async e => {
          e.preventDefault();
          setStripeLoading(true);
          const {
            target: { id: formId },
          } = e;
          const { error: tokenError, token } = await getStripeToken({
            element: stripeCardElement,
          });

          if (tokenError) {
            onError();
            setStripeLoading(false);
            return;
          }

          onSubmit({ token });
        }}
      >
        {hasPaymentError && (
          <Grid item xs={12} className={cn.formGroup}>
            <div className={cn.formError}>
              <InlineErrors errors={paymentError} />
            </div>
          </Grid>
        )}
        <Grid item xs={12} className={cn.formGroup}>
          <TextInput
            fullWidth
            label="Cardholder Name"
            error={!name.value && name.dirty}
            onChange={event =>
              setName({ value: event.target.value, dirty: true })
            }
            onBlur={() => setName({ ...name, dirty: true })}
            value={name.value}
            data-testid="field-cardholderName"
          />
          <div
            role="alert"
            className={`${cn.errorAlert} ${
              !name.value && name.dirty ? 'show' : ''
            }`}
          >
            {MISSED_FIELD_ERROR_TXT}
          </div>
        </Grid>
        <Grid item xs={12} className={`${cn.formGroup} ${cn.groupMT}`}>
          <label htmlFor="card-number-element" className={cn.elementFormLabel}>
            <span
              className={`${cn.formLabel} ${
                elementField.dirty || elementField.isActive ? 'focused' : ''
              } ${elementField.error ? 'error' : ''}`}
              onClick={() =>
                setElementState({ ...elementField, isActive: true })
              }
            >
              Card Information
            </span>
            <div
              id="card-number-element"
              className={`${cn.formField} ${elementFieldError ? 'error' : ''}`}
              style={{ width: fieldWidth }}
            >
              {/*  strip element inserted here */}
            </div>
            <div id="card-errors" role="alert" className={cn.stripeCardError}>
              {/* card error inserted here */}
              {elementFieldError}
            </div>
          </label>
        </Grid>
        <Grid item xs={12} className={cn.pymtCTAContainer}>
          <div onClick={validateForm}>
            <ButtonPrimaryLarge
              className={cn.pymtCTA}
              data-testId="submit-stripe-pymt"
              disabled={disablePayment}
              type="submit"
              isLoading={isLoading}
            >
              Pay
            </ButtonPrimaryLarge>
          </div>
        </Grid>
      </form>
      <div className={cn.secondaryCTAContainer}>
        <Grid item xs={12}>
          <PayPalButton
            handlePurchase={onSubmit}
            purchaseDetails={{
              type: purchaseType,
              value: totalPayment,
            }}
            handleError={onError}
            loading={isLoading}
          />
        </Grid>
      </div>
    </div>
  );
};

const cn = {
  dealsPymt: css`
    width: auto;
    overflow: hidden;
  `,
  stripeForm: css`
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 26px 20px 20px 20px;
    overflow: hidden;
  `,
  elementFormLabel: css`
    position: relative;
  `,
  formLabel: css`
    color: rgb(112, 112, 112);
    font-size: 1.1875rem;
    width: 100%;
    text-align: left;
    position: absolute;
    top: -33px;
    background: ${colors.white};
    z-index: 1;
    transform: translate(0, 24px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &.error {
      border-bottom: 2px solid ${colors.dragon};
    }

    &.focused {
      border-bottom: none;
      font-size: 17px;
      transform: scale(0.85) translateY(-1px) translateX(-31px);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
  `,
  formField: css`
    background: transparent;
    font-weight: 300;
    border: 0;
    color: #31325f;
    outline: none;
    flex: 1;
    cursor: text;
    /* width: 356px; */
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);

    &.focused {
      border-bottom: 2px solid rgba(0, 0, 0, 1);
    }

    &.error {
      border-bottom: 2px solid ${colors.dragon};
    }
  `,
  formFieldMid: css`
    width: 180px;
  `,
  formGroupContainer: css`
    display: flex;
    flex-direction: row;
  `,
  formGroup: css`
    margin-bottom: 30px !important;
  `,
  pymtCTAContainer: css`
    margin-top: 20px !important;
  `,
  pymtCTA: css`
    width: 100% !important;
  `,
  secondaryCTA: css`
    display: none;
    color: ${colors.jungle} !important;
    font-size: 13px;

    &:hover {
      text-decoration: none;
    }
  `,
  groupMT: css`
    margin-top: 60px !important;
  `,
  errorAlert: css`
    color: ${colors.dragon};
    font-size: 14px;
    display: none;

    &.show {
      display: block;
    }
  `,
  stripeCardError: css`
    font-size: 14px;
    color: ${colors.dragon};
  `,
  linkDisabled: css`
    color: ${colors.stone} !important;
    cursor: not-allowed;
  `,
  formError: css``,
  secondaryCTAContainer: css`
    /* display: none; */
  `,
};

export default DealsPaymentForm;
