
import * as React from 'react';
import Raven from 'raven-js';
import { css } from 'emotion';
import { colors, elements } from '@peachjar/components';

const { Paragraph } = elements.typography;

type ErrorInfo = {
  componentStack: string,
};

type Props = {
  children: React.ReactNode,
};

type State = {
  error?: Error,
};

const onEnter = (
  callback: Function
): ((e: SyntheticKeyboardEvent<HTMLElement>) => void) => e => {
  if (e.key === 'Enter') {
    callback();
  }
};

const reportToSentry = () => Raven.lastEventId() && Raven.showReportDialog();
const reportToSentryOnEnter = onEnter(reportToSentry);

// Tech Debt: expand on this error boundary, or make use of open source
class ErrorBoundary extends React.Component<Props, State> {
  state = { error: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
    // eslint-disable-next-line
    console.error(error, errorInfo);

    Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      // render fallback UI
      return (
        <div
          className={classNames.card}
          onClick={reportToSentry}
          onKeyPress={reportToSentryOnEnter}
          role="button"
          tabIndex={0}
          data-testid="click-ErrorBoundary"
        >
          <Paragraph>
            We&apos;re sorry — something&apos;s gone wrong. &ndash; Our team has
            been notified, but click here fill out a report.
          </Paragraph>
        </div>
      );
    }
    // when there is not an error, render children untouched
    return children;
  }
}

const classNames = {
  card: css`
    padding: 1rem;
    border-radius: 6px;
    box-shadow: 0 0 4px 0 ${colors.stone};
    background-color: white;
    width: 100%;
    cursor: pointer;
  `,
};
export default ErrorBoundary;
