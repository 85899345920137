
import * as React from 'react';
import gql from 'graphql-tag';
import { css, cx } from 'react-emotion';
import idx from 'idx';
import { groupBy, pick } from 'lodash';
import { components, colors, elements } from '@peachjar/components';

import { DeclineReason, StagedFlyer } from '../../approvals.duck';
import {
  DenialReasonModalContainer_flyerReasons,
} from './__generated__/DenialReasonModalContainer_flyerReasons';
import {
  STRINGS,
  MAX_ALLOWED_DENIAL_REASON_EXPLANATION_CHARACTERS,
} from '../../constants';
import FlyerReasonModal from './FlyerReasonModal';

const { Modal } = components;
const { GreyBox } = elements;
const { Paragraph, Note } = elements.typography;
const {
  Buttons: { ButtonFlatLarge },
  Dropdown,
} = components;

type Props = {
   closeModal: () => void,
   onStageFlyer: (stagedFlyer: StagedFlyer) => void,
   stagedFlyer: StagedFlyer,
   flyer: any,
   flyerReasons: DenialReasonModalContainer_flyerReasons[],
};

type State = {
  denialReason?: DeclineReason,
  denialReasonExplanation?: string,
};

type ReasonNames = {
  label?: string,
  value?: string,
}[];

class DenialReasonModalContainer extends React.Component<Props, State> {
  static fragments: { [key: string]: any };

  state = {
    denialReason: null,
    denialReasonExplanation: null,
  };

  onReasonChange = (reasonNames: ReasonNames) => {
    return (e: any) => {
      const val = idx(e, _ => _.target.value) || '';

      if (reasonNames.map(r => r.value).includes(val)) {
        this.setState({
          denialReason: val,
        });
      }
    }
  };

  onReasonExplanationChange = (
    e: SyntheticKeyboardEvent<HTMLInputElement>
  ): void => {
    // Prevent against pasting or typing in explanations over predefined character limit
    const explanation = idx(e, _ => _.currentTarget.value) || '';

    if (explanation.length > MAX_ALLOWED_DENIAL_REASON_EXPLANATION_CHARACTERS) {
      const shortenedExplanation = explanation.slice(
        0,
        MAX_ALLOWED_DENIAL_REASON_EXPLANATION_CHARACTERS
      );
      this.setState({ denialReasonExplanation: shortenedExplanation });
    } else {
      this.setState({ denialReasonExplanation: explanation });
    }
  };

  getDeniedApprovalIds = (): Array<string> => {
    const approvals = idx(this.props, _ => _.stagedFlyer.approvals) || {};

    return Object.entries(approvals).reduce(
      (result, [approvalId, status]) =>
        status === 'denied' ? [...result, approvalId] : result,
      []
    );
  };

  handleSave = (): void => {
    const { denialReason, denialReasonExplanation } = this.state;
    const { stagedFlyer, closeModal, onStageFlyer } = this.props;
    const { approvals } = stagedFlyer;

    if (
      // $Flow F Me
      denialReason !== null &&
      denialReason !== undefined
    ) {
      onStageFlyer({
        ...stagedFlyer,
        approvals: {
          ...approvals,
        },
        denialReason,
        ...(denialReasonExplanation ? { denialReasonExplanation } : {}),
      });
      closeModal();
    }
  };

  render() {
    const { closeModal, flyer, flyerReasons } = this.props;
    const flyerTitle = idx(flyer, _ => _.title) || '';
    const sellerCompanyName = idx(flyer, _ => _.sellerCompanyName) || '';
    const districtName = idx(flyer, _ => _.approvals[0].districtName) || '';
    const { denialReason, denialReasonExplanation } = this.state;
    const hasDenialReason = denialReason !== null && denialReason !== undefined;
    // Denial reason select options
    const denialReasonNames: {
      label?: string,
      value?: string,
    }[] = flyerReasons
      .filter(r => (r.isDenial && !r.retiredAt))
      .map(r => ({value: r.code, label: r.displayName}));
    // Denial reason code -> verbiage lookup
    const denialReasonVerbiages = flyerReasons
      .filter(r => r.isDenial)
      .reduce((acc, r, idx) => {
        return {
          ...acc,
          [r.code]: r.suggestedVerbiage,
        };
      }, {});

    return (
      <FlyerReasonModal
        closeModal={closeModal}
        onSubmit={this.handleSave}
        onReasonChange={this.onReasonChange(denialReasonNames)}
        onReasonExplanationChange={this.onReasonExplanationChange}
        reason={denialReason}
        reasonExplanation={denialReasonExplanation}
        sellerCompanyName={flyerTitle}
        districtName={districtName}
        reasonNames={denialReasonNames}
        reasonVerbiages={denialReasonVerbiages}
        isSubmitButtonLoading={false}
        isDenialMode={true}
      />
    );
  }
}

const classNames = {
  icon: css`
    font-size: 1.5rem;
  `,
  fieldLabel: css`
    margin-bottom: 0.5rem;
  `,
  blockText: css`
    display: block;
    margin-bottom: 1.5rem;
  `,
  marginTop: css`
    margin-top: 1.5rem;
  `,
  footerShadow: css`
    margin: 0 !important;
    padding: 1rem;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  `,
  textarea: css`
    width: 100%;
    min-height: 5.875rem;
    display: block;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    border: 1px solid ${colors.silver};
    border-radius: 3px;
  `,
};

DenialReasonModalContainer.fragments = {
  flyer: gql`
    fragment DenialReasonModalContainer_flyer on Flyer {
      # id
      title
      sellerCompanyName
      approvals {
        districtName
      }
    }
  `,
  flyerReasons: gql`
    fragment DenialReasonModalContainer_flyerReasons on FlyerReason {
      code
      displayName
      suggestedVerbiage
      isDenial
      retiredAt
    }
  `,
};

export default DenialReasonModalContainer;
