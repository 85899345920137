import React from 'react';
import { css } from 'react-emotion';
import { colors, elements } from '@peachjar/components';
import gql from 'graphql-tag';

const { mercury } = colors;
const { Note } = elements.typography;

const styles = {
  jpegPageBox: css`
    border: 1px solid ${mercury};
  `,
  img: css`
    border-radius: 7px;
    max-height: 100%;
    max-width: 100%;
  `,
};

type Props = {
  jpegUrl: string;
  pageNumber: number;
  totalPages: number;
};

export const JpegPage = ({ jpegUrl, pageNumber, totalPages }: Props) => (
  <div className="flex-60">
    <div className={styles.jpegPageBox}>
      <img className={styles.img} src={jpegUrl} alt={`Page ${pageNumber}`} />
    </div>
    <div>
      <Note muted>
        {pageNumber} of {totalPages}
      </Note>
    </div>
  </div>
);

JpegPage.fragments = {
  getMyCampaigns: gql`
    fragment JpegPage_getMyCampaigns on FlyerPage {
      id
      jpegUrl
    }
  `,
};

export default JpegPage;
