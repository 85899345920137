import React from 'react';
import { css } from 'react-emotion';
import { colors } from '@peachjar/components';
import { withStyles } from '@material-ui/core/styles';
const { white, stone } = colors;

const Slat = ({ children, classes }) => (
  <div className={`${cn.paper} ${classes.elevation2}`}>{children}</div>
);

const cn = {
  paper: css`
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 0.8rem 1.5rem;
    background-color: ${white};
    margin-bottom: 1rem;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }
  `,
};

const materialUIstyles = {
  elevation2: {
    boxShadow: 'none',
  },
};

export default withStyles(materialUIstyles)(Slat);
