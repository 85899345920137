import React from 'react';
import { css } from 'emotion';
import { colors } from '@peachjar/components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { assetArray } from './assets';

const isLocal = window.location.host === 'portal.peachjar.net:10443';
const randomIndex = Math.floor(Math.random() * assetArray.length);
const randomAsset = assetArray[randomIndex];

const FullPageSpinner = () => (
  <div className={styles.container}>
    {isLocal ? (
      <img
        src={randomAsset}
        className={styles.localSpinner}
        alt="Super cool person spinner"
      />
    ) : (
      <CircularProgress style={{ color: colors.leaf }} />
    )}
  </div>
);

const styles = {
  container: css`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  localSpinner: css`
    border-radius: 50%;
    height: 150px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  `,
};

export default FullPageSpinner;
