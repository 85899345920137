import generateAppConfig from './generateAppConfig';

// Import your App's config file here
import getApprovalsSubappConfig from '../approvals/approvalsSubappConfig';
import getV2PendingApprovalsSubappConfig from '../pending_approvals/v2PendingApprovalsSubappConfig';
import getV2AllApprovalsSubappConfig from '../all_approvals/v2AllApprovalsSubappConfig';
import getDashboardSubappConfig from '../dashboard/dashboardSubappConfig';
import getCampaignsSubappConfig from '../campaigns/campaignsSubappConfig';
import getCreateCampaignSubappConfig from '../campaigns/createCampaignSubappConfig';
import getAllFlyersSubappConfig from '../reporting/allFlyersSubappConfig';
import getMyFlyersSubappConfig from '../reporting/myFlyersSubappConfig';
import getDealsSubappConfig from '../deals/dealsSubappConfig';
import getTransactionsSubappConfig from '../Transactions/transactionsSubappConfig';
import transactionHistorySubappConfig from '../Transactions/transactionHistorySubappConfig';
import helpSubappConfig from '../help/helpSubappConfig';
import accountInfoSubappConfig from '../account_info/accountInfoSubappConfig';
import staffManagementSubappConfig from '../staff_management/staffManagementSubappConfig';
import schoolsManagementSubappConfig from '../schools_management/schoolsManagementSubappConfig';
import parentsManagementSubappConfig from '../parents_management/parentsManagementSubappConfig';
import districtManagementSubappConfig from '../district_management/districtManagementSubappConfig';
import creditsSubappConfig from '../credit_purchasing/subappConfig';
import { APP_ROUTES } from './constants';
import { redirect } from './redirect';
import config from '../config';
import { distributionAllowedRoles } from './RolesEnum';
import orgSettingsSubappConfig from '../org_settings/orgSettingsSubappConfig';

const currentLocation = window.location.pathname;

const getV2portalApps = (flags, reportingView = 'allFlyers') => {
  const { orgUploaderFeatureFlag, portalNavFeatureFlag } = flags;

  const reportingConfig =
    reportingView === 'allFlyers'
      ? getAllFlyersSubappConfig(portalNavFeatureFlag)
      : getMyFlyersSubappConfig(portalNavFeatureFlag);
  const apps = [
    getDashboardSubappConfig(portalNavFeatureFlag),
    getCampaignsSubappConfig(),
    getCreateCampaignSubappConfig(),
    getApprovalsSubappConfig(portalNavFeatureFlag),
    getV2PendingApprovalsSubappConfig(portalNavFeatureFlag),
    getV2AllApprovalsSubappConfig(portalNavFeatureFlag),
    {
      path: config.DISTRIBUTION_SCHEDULE_URL,
      isLive: true,
      allowedRoles: distributionAllowedRoles,
      navItemConfig: {
        label: 'Distribution Schedule',
      },
      appConfig: {},
    },
    {
      path: config.DISTRIBUTION_HISTORY_URL,
      isLive: true,
      allowedRoles: distributionAllowedRoles,
      navItemConfig: {
        label: 'Distribution History',
      },
      appConfig: {},
    },
    reportingConfig,
    helpSubappConfig,
    accountInfoSubappConfig,
    orgSettingsSubappConfig,
    transactionHistorySubappConfig,
  ];

  if (orgUploaderFeatureFlag === 1) {
    apps.push(
      getDealsSubappConfig(orgUploaderFeatureFlag),
      getTransactionsSubappConfig(orgUploaderFeatureFlag)
    );
  }

  return apps;
};

// Register new apps here
export const getAppSections = (path, flags) => {
  const {
    portal_nav: portalNavFeatureFlag,
    org_uploader: orgUploaderFeatureFlag,
  } = flags;
  const isPortalNavEnabled = portalNavFeatureFlag === 1;
  const v2Apps = getV2portalApps({
    orgUploaderFeatureFlag,
    portalNavFeatureFlag,
  });

  switch (true) {
    case path.includes(APP_ROUTES.postFlyer):
      return [
        {
          sectionLabel: 'Post & Notify',
          apps: v2Apps,
        },
      ];
    case path.includes(APP_ROUTES.campaigns):
      return [
        {
          sectionLabel: 'Flyer Distribution',
          apps: v2Apps,
        },
      ];
    case path.includes(APP_ROUTES.dashboard):
      return [
        {
          sectionLabel: 'Dashboard',
          apps: v2Apps,
        },
      ];
    case path.includes(APP_ROUTES.reporting.allFlyers):
      return [
        {
          sectionLabel: 'Reporting',
          apps: v2Apps,
        },
      ];
    case path.includes(APP_ROUTES.reporting.myFlyers):
      return [
        {
          sectionLabel: 'Reporting',
          apps: getV2portalApps(
            { orgUploaderFeatureFlag, portalNavFeatureFlag },
            'myFlyers'
          ),
        },
      ];
    case path.includes(APP_ROUTES.deals):
      return [
        {
          sectionLabel: 'Deals',
          apps: v2Apps,
        },
      ];
    case path.includes(APP_ROUTES.transactions):
      return [
        {
          sectionLabel: 'Transactions',
          apps: v2Apps,
        },
      ];
    case path.includes(APP_ROUTES.help):
      return [
        {
          sectionLable: 'Help',
          apps: v2Apps,
        },
      ];
    case path.includes(APP_ROUTES.account_info):
      return [
        {
          sectionLabel: 'Account Information',
          apps: v2Apps,
        },
      ];
    case path.includes(APP_ROUTES.orgSettings):
      return [
        {
          sectionLabel: 'Organization Settings',
          apps: v2Apps,
        },
      ];
    case path.includes(APP_ROUTES.account_transactions):
      return [
        {
          sectionLabel: 'Transaction History',
          apps: v2Apps,
        },
      ];
    case path.includes(APP_ROUTES.staff):
    case path.includes(APP_ROUTES.schools):
    case path.includes(APP_ROUTES.parents):
    case path.includes(APP_ROUTES.organizations):
    case path.includes(APP_ROUTES.district):
      if (!isPortalNavEnabled) {
        redirect(APP_ROUTES.dashboard);
      }
      return [
        {
          sectionLabel: 'Settings and management',
          apps: [
            staffManagementSubappConfig,
            schoolsManagementSubappConfig,
            parentsManagementSubappConfig,
            districtManagementSubappConfig,
          ],
        },
      ];
    case path.includes(APP_ROUTES.credits.index):
      return [
        {
          sectionLabel: 'Credits',
          apps: [creditsSubappConfig],
        },
      ];
    default:
      // not a valid route
      return [];
  }
};

const getSubApps = ({ flags }) => {
  const subapps = getAppSections(currentLocation, flags);

  const { apps, sidebarSections } = generateAppConfig(subapps);
  return {
    apps,
    sidebarSections,
  };
};

export default getSubApps;
