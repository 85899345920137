import React, { useState, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { css } from 'emotion';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import Dashed from '@peachjar/ui/dist/lib/components/Dashed';
import linkOut from '@peachjar/ui/dist/lib/helpers/linkOut';
import { colors, components, elements } from '@peachjar/components';
import Chip from '@material-ui/core/Chip';
import flyerPlaceHolderSVG from '../../../assets/flyer_placeholder.svg';
import { NOTIFICATIONS, APP_ROUTES } from '../../constants';
import AppDrawerContext from '../../context/AppDrawerContext';
import getPostDuration from '../../../helpers/getPostDuration';
import diffInDays from '../../../helpers/diffInDays';
import utcDateToMidnightLocal from '../../../campaigns/shared/components/utcDateToMidnightLocal';
import { formatParticipationFee } from '../../../campaigns/Create-Campaign/Details/components/ProvideDetails/ParticipationFee/ParticipationFee';
import { getFurthestCompletedTab } from '../../../../src/campaigns/shared/utils';
import MutationButton from '../../../../src/campaigns/Overview/components/RemoveDraftCampaign/MutationButton';
import RemoveConfirmationModal from '../../../../src/campaigns/Overview/components/RemoveConfirmationModal';
import bffClient from '../../apollo/portalBFF.apolloClient';
import config from '../../../config';

const { COMMUNITY_FREE_QUESTIONNAIRE_URL } = config;

const {
  Buttons: { ButtonSecondarySmall, ButtonPrimaryLarge },
  Notifications: { notifySuccess, notifyError, notifyWarn },
} = components;
const {
  typography: { Note },
} = elements;
const dateFns = new DateFnsUtils();

const GET_COMM_FREE_APPLICATION = gql`
  query getCommFreeApplication($campaignId: String!) {
    campaignById(campaignId: $campaignId) {
      id
      application {
        id
      }
    }
  }
`;

export const FLYER_REPORTING_FRAGMENT = gql`
  fragment flyerReporting on SodUser {
    flyerReporting {
      allFlyers(limit: 15, offset: 0) {
        items {
          flyerId
          distributionStatus
          removalDate
        }
        pagination {
          total
        }
      }
      myFlyers(limit: 15, offset: 0) {
        items {
          flyerId
          distributionStatus
          removalDate
        }
        pagination {
          total
        }
      }
    }
  }
`;

const sanitizeUrl = (url: string): string => {
  const decodedURI = decodeURIComponent(url);

  if (!url.includes('http')) {
    return `http://${decodedURI}`;
  }

  return decodedURI;
};

const getSecondaryCTA = (ctaObj: object): any => {
  const { type, data } = ctaObj;
  switch (type) {
    case 'Call':
      return (
        <ButtonSecondarySmall
          data-testid="click-flyer-details-cta"
          className={`${cn.flyerCTA} ${cn.ctaTrails}`}
          href={`tel:${data.phoneNumber}`}
        >
          Call
        </ButtonSecondarySmall>
      );
    case 'SignUp':
      return (
        <ButtonSecondarySmall
          data-testid="click-flyer-details-cta"
          className={`${cn.flyerCTA} ${cn.ctaTrails}`}
          href={linkOut({
            url: sanitizeUrl(data.websiteUrl),
            labels: { linkType: 'flyerDetails-cta' },
          })}
          target="_blank"
        >
          Sign Up
        </ButtonSecondarySmall>
      );
    case 'Email':
      return (
        <ButtonSecondarySmall
          data-testid="click-flyer-details-cta"
          className={`${cn.flyerCTA} ${cn.ctaTrails}`}
          href={`mailto:${data.email}`}
          target="_blank"
        >
          Email
        </ButtonSecondarySmall>
      );
    case 'GetApp':
      return (
        <ButtonSecondarySmall
          data-testid="click-flyer-details-cta"
          className={`${cn.flyerCTA} ${cn.ctaTrails}`}
          href={linkOut({
            url: sanitizeUrl(data.webAppUrl ? data.webAppUrl : data.websiteUrl),
            labels: { linkType: 'flyerDetails-cta' },
          })}
          target="_blank"
        >
          Get App
        </ButtonSecondarySmall>
      );
    case 'Donate':
      return (
        <ButtonSecondarySmall
          data-testid="click-flyer-details-cta"
          className={`${cn.flyerCTA} ${cn.ctaTrails}`}
          href={linkOut({
            url: sanitizeUrl(data.donateWebsiteUrl),
            labels: { linkType: 'flyerDetails-cta' },
          })}
          target="_blank"
        >
          Donate
        </ButtonSecondarySmall>
      );
    default:
      return null;
  }
};

export const CampaignDetails = ({
  campaign,
  flyerId,
  handleError,
  handleSuccess,
  handleWarning,
  history,
}) => {
  const [removeCampaignModalOpen, setRemoveCampaignModalState] = useState(
    false
  );
  const { toggleDrawer, setDrawerContext } = useContext(AppDrawerContext);

  const {
    loading: loadingCommFreeApp,
    data: commFreeAppData,
    error: commFreeAppError,
  } = useQuery(GET_COMM_FREE_APPLICATION, {
    client: bffClient,
    variables: { campaignId: campaign.id },
    fetchPolicy: 'no-cache',
  });

  if (loadingCommFreeApp) {
    return null;
  }

  const onCancelRemoveModal = () => {
    setRemoveCampaignModalState(false);
  };
  const renderify = (val: any) => (val !== null ? val : <Dashed />);
  if (!flyerId) {
    return null;
  }
  const commFreeApplicationId = get(
    commFreeAppData,
    'campaignById.application.id',
    null
  );
  const submittedDate = campaign.createdAt;
  const minAge = campaign.minGradeLevel || null;
  const maxAge = campaign.maxGradeLevel || null;
  const eventLocation = campaign.eventLocation || null;
  const startDate = campaign.deliverySettings
    ? campaign.deliverySettings.startDate || null
    : null;
  const endDate = campaign.deliverySettings
    ? campaign.deliverySettings.endDate || null
    : null;
  const primaryCallToAction = campaign.primaryCallToAction || null;
  const secondaryCallToAction = campaign.secondaryCallToAction || null;
  const deliverySettings = campaign.deliverySettings || null;
  const flyerThumbnail = campaign.flyer.flyerPages[0].jpegUrl || null;
  const campaignId = campaign.id;
  const id = campaign.legacyFlyerId;
  const title = campaign.title || null;
  const tab = getFurthestCompletedTab(campaign);
  const flyerPaidButNotSubmitted =
    campaign.paymentStatus === 'paid' && !campaign.isSubmitted;
  const distributionStatus = 'Draft';
  const formattedSubmittedDate = dateFns.format(
    new Date(campaign.createdAt),
    'MMM dd, yyyy'
  );
  const formattedEndDate =
    endDate != null
      ? dateFns.format(
          // Date stored in UTC
          utcDateToMidnightLocal(new Date(endDate)),
          'MMM dd, yyyy'
        )
      : null;
  const formattedRemovalDate = null;
  const numberOfPages = campaign.flyer.flyerPages.length || null;
  const hasDeliverySettings = deliverySettings != null;
  const hasPostDuration =
    hasDeliverySettings && startDate != null && endDate != null;
  const postDurationInDays = () => {
    if (hasPostDuration) {
      return diffInDays(new Date(startDate), new Date(endDate));
    }
    return null;
  };
  const postDuration = postDurationInDays();
  const distributions = campaign.deliverySettings
    ? campaign.deliverySettings.numberOfDistributions || null
    : null;
  const targetAudiences =
    distributions && campaign.deliverySettings.targetAudiences
      ? campaign.deliverySettings.targetAudiences || null
      : null;
  const aggregateAudiences = () => {
    if (targetAudiences) {
      const audienceContainer: string[] = [];
      for (let i = 0; i < targetAudiences.length; i++) {
        if (!audienceContainer.includes(targetAudiences[i].audienceType)) {
          audienceContainer.push(targetAudiences[i].audienceType);
        }
      }
      if (!audienceContainer.length) {
        return <Dashed />;
      } else if (audienceContainer.length === 1) {
        return audienceContainer[0].replace(/./, x => x.toUpperCase());
      } else {
        return (
          audienceContainer[0].replace(/./, x => x.toUpperCase()) +
          ' and ' +
          'Staff Groups'
        );
      }
    } else {
      return <Dashed />;
    }
  };
  const header = 'Draft Flyer Deletion Confirmation';
  const getMessage = title => `Please confirm you want to delete ${title}.`;

  const getDraftSchoolsTargetedSummary = (total: number) => {
    return (
      <p className={cn.schoolSummary}>
        <span>{`${total} total`}</span>
      </p>
    );
  };

  const getDraftSchoolsTargetedCount = () => {
    const returnZero = () => {
      return 0;
    };
    if (targetAudiences) {
      let totalSchools = () => {
        let uniqueSchools: string[] = [];
        for (let i = 0; i < targetAudiences.length; i++) {
          if (!uniqueSchools.includes(targetAudiences[i].ncesId)) {
            uniqueSchools.push(targetAudiences[i].ncesId);
          }
        }
        return uniqueSchools.length;
      };
      return getDraftSchoolsTargetedSummary(totalSchools());
    } else {
      return getDraftSchoolsTargetedSummary(returnZero());
    }
  };
  return (
    <>
      <div className={cn.pageContainer}>
        <div className={cn.flyerDetailsContainer}>
          <div className={cn.flyerDetails}>
            <div className={cn.flyerTitleContainer}>
              <h3 className={cn.flyerTitle} data-testid="text-flyer-title">
                {title}
              </h3>
              <span className={cn.flyerId}>{`#${flyerId}`}</span>
            </div>
            <div className={cn.flyerInformation}>
              <div className={cn.flyerInformationSection}>
                <p className={cn.sectionLabel}>Status: </p>
                <p className={cn.sectionValue}>
                  {distributionStatus ? (
                    <Chip
                      label={distributionStatus}
                      className={`${cn.flyerStatus} ${
                        cn[`chip${distributionStatus}`]
                      }`}
                    />
                  ) : (
                    <Dashed />
                  )}
                </p>
              </div>
              <div className={cn.flyerInformationSection}>
                <p className={cn.sectionLabel}>Street Address: </p>
                <p className={cn.sectionValue}>{renderify(eventLocation)}</p>
              </div>

              <div className={cn.flyerInformationSection}>
                <p className={cn.sectionLabel}>Call-To-Actions: </p>
                <p className={cn.sectionValue}>
                  {primaryCallToAction && primaryCallToAction.data && (
                    <ButtonSecondarySmall
                      data-testid="click-flyer-details-cta"
                      className={cn.flyerCTA}
                      href={linkOut({
                        url: sanitizeUrl(primaryCallToAction.data.websiteUrl),
                        labels: { linkType: 'flyerDetails-cta' },
                      })}
                      target="_blank"
                    >
                      Learn More
                    </ButtonSecondarySmall>
                  )}

                  {secondaryCallToAction &&
                    secondaryCallToAction.data &&
                    getSecondaryCTA(secondaryCallToAction)}

                  {primaryCallToAction === null &&
                    secondaryCallToAction === null && <Dashed />}
                </p>
              </div>
              <div className={cn.flyerInformationSection}>
                <p className={cn.sectionLabel}>Audience: </p>
                <p className={`${cn.sectionValue}`}>{aggregateAudiences()}</p>
              </div>
              <div className={cn.flyerInformationSection}>
                <p className={cn.sectionLabel}>Distributions: </p>
                <p className={cn.sectionValue}>
                  {startDate != null && renderify(distributions)}{' '}
                  {distributions >= 1 && startDate != null && (
                    <span style={{ color: 'grey' }}>(send and post)</span>
                  )}
                  {startDate == null && <Dashed />}
                </p>
              </div>
              <div className={cn.flyerInformationSection}>
                <p className={cn.sectionLabel}>Distribution Target Date: </p>
                {hasDeliverySettings && startDate != null ? (
                  <p className={cn.sectionValue}>
                    {`${dateFns.format(
                      utcDateToMidnightLocal(new Date(startDate)),
                      'MMM dd, yyyy'
                    )} `}
                  </p>
                ) : (
                  <p className={cn.sectionValue}>
                    <Dashed />
                  </p>
                )}
              </div>
              <div className={cn.flyerInformationSection}>
                <p className={cn.sectionLabel}>
                  {distributionStatus &&
                  distributionStatus.toLowerCase() === 'removed'
                    ? 'Removed:'
                    : 'Post Expiration Date:'}
                </p>
                <p className={cn.sectionValue}>
                  {renderify(
                    distributionStatus &&
                      distributionStatus.toLowerCase() === 'removed'
                      ? formattedRemovalDate
                      : formattedEndDate
                  )}
                </p>
              </div>
              <div className={cn.flyerInformationSection}>
                <p className={cn.sectionLabel}>Post Duration: </p>
                <p className={cn.sectionValue}>
                  {distributionStatus &&
                  distributionStatus.toLowerCase() === 'removed' ? (
                    postDurationInDays
                  ) : postDuration ? (
                    getPostDuration(postDuration)
                  ) : (
                    <Dashed />
                  )}
                </p>
              </div>
              <div className={cn.flyerInformationSectionBottom}>
                <p className={cn.sectionLabel}>Schools Targeted: </p>
                <p className={cn.sectionValue}>
                  {getDraftSchoolsTargetedCount()}
                </p>
              </div>
            </div>
          </div>
          <div className={cn.flyerPreviewContainer}>
            <div className={cn.flyerPreview}>
              <img
                className={cn.flyerThumbnail}
                src={flyerThumbnail ? flyerThumbnail : flyerPlaceHolderSVG}
                alt=""
              />
            </div>
            <div className={cn.flyerThumbnailCount}>
              <span>{`1 of ${numberOfPages}`}</span>
            </div>
          </div>
        </div>
        <div className={cn.flyerSection}>
          <h4 className={cn.flyerSectionHeadline}>Manage Flyer</h4>
          <Note className={cn.flyerSectionDescription}>
            Edit or delete your flyer draft.
          </Note>
          <div className={cn.layoutRow}>
            <div onClick={() => flyerPaidButNotSubmitted && handleWarning()}>
              <ButtonSecondarySmall
                data-testid="click-editCampaign"
                onClick={() => {
                  setDrawerContext('FlyerDetails');
                  toggleDrawer();
                  if (commFreeApplicationId) {
                    window.location.href = `${COMMUNITY_FREE_QUESTIONNAIRE_URL}?aid=${commFreeApplicationId}`;
                    return;
                  }
                  history.push(
                    `/campaigns/create-campaign/${campaignId}/${tab}`
                  );
                }}
                disabled={flyerPaidButNotSubmitted}
              >
                Edit Draft
              </ButtonSecondarySmall>
            </div>
            <div onClick={() => flyerPaidButNotSubmitted && handleWarning()}>
              <ButtonSecondarySmall
                className="ml-2"
                onClick={() => {
                  setRemoveCampaignModalState(true);
                }}
                disabled={flyerPaidButNotSubmitted}
              >
                Delete Draft
              </ButtonSecondarySmall>
            </div>
          </div>
        </div>
      </div>
      {removeCampaignModalOpen && (
        <RemoveConfirmationModal
          header={header}
          message={getMessage(title)}
          onCancel={onCancelRemoveModal}
        >
          <MutationButton
            campaignId={campaignId}
            onCompleted={() => {
              handleSuccess('removeCampaignSuccess');
              onCancelRemoveModal();
              toggleDrawer();
            }}
            onError={() => {
              handleError('removeCampaignError');
            }}
          />
        </RemoveConfirmationModal>
      )}
    </>
  );
};
const cn = {
  pageContainer: css`
    width: 1020px;
    max-width: 1020px;
    padding-top: 28px;
  `,
  flyerDetailsContainer: css`
    display: flex;
    flex-direction: row;
  `,
  flyerDetails: css`
    width: 67%;
    padding-right: 40px;
    position: relative;
  `,
  flyerTitle: css`
    font-size: 19px;
    color: ${colors.prussian};
    font-weight: 600;
    width: 310px;
    line-height: 1.45;
    margin-bottom: 1.5rem;
    word-break: break-word;
  `,
  flyerTitleContainer: css`
    width: 358px;
    position: relative;
  `,
  flyerId: css`
    font-size: 13px;
    font-weight: normal !important;
    color: ${colors.slate};
    position: absolute;
    top: 6px;
    right: 0;
    padding-left: 10px;
  `,
  flyerPreview: css`
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    padding: 12px 5px;
  `,
  flyerPreviewContainer: css`
    width: 33%;
    max-width: 325px;
    overflow: hidden;
  `,
  flyerThumbnail: css`
    width: 100%;
  `,
  flyerInformation: css``,
  flyerInformationSection: css`
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
  `,
  flyerInformationSectionBottom: css`
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
  `,
  flyerThumbnailCount: css`
    color: #707070;
    font-size: 13px;
  `,
  sectionLabel: css`
    width: 30%;
    font-size: 15px;
    padding: 0;
    margin: 0;
  `,
  sectionValue: css`
    width: 70%;
    font-size: 15px;
  `,
  flyerStatus: css`
    color: ${colors.prussian} !important;
    font-size: 13px !important;
    font-weight: normal;
    text-transform: capitalize;
  `,
  flyerCTA: css`
    margin-right: 8px !important;
  `,
  twitterCTA: css`
    margin-left: 8px !important;
  `,
  ctaTrails: css``,
  chipsubmitted: css`
    background-color: ${colors.mercury} !important;
  `,
  chipdeclined: css`
    background-color: #ffebe5 !important;
  `,
  chipactive: css`
    background-color: #e7f4e9 !important;
  `,
  chipscheduled: css`
    background-color: #dfe1e7 !important;
  `,
  chipremoved: css`
    background-color: #ffefc8 !important;
  `,
  chipgray: css`
    background-color: #f5f5f5 !important;
  `,
  chipexpired: css`
    background-color: #fffae5 !important;
  `,
  flyerSection: css`
    margin-top: 24px;
  `,
  flyerSectionHeadline: css`
    font-size: 25px;
    color: ${colors.prussian};
    margin-bottom: 4px;
  `,
  flyerSectionCTA: css`
    margin-top: 20px;
  `,
  flyerSectionDescription: css`
    margin-top: 8px;
    font-size: 16px;
  `,
  flyerLinkForm: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  flyerLink: css`
    background-color: #f5f5f5;
    padding: 20px;
    margin-top: 22px;
  `,
  deleteBtn: css`
    margin-left: 16px;
  `,
  layoutRow: css`
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 24px;
  `,
  link: css`
    color: #3a833c;
    font-size: 16px;
    line-height: 1.85;
    outline: 0;
    border: none;
    background-color: transparent;
    resize: none;
    overflow-y: hidden;
    width: 80%;
  `,
  viewReport: css`
    display: flex;
    justify-content: flex-start;
    margin: 0px;
  `,
  viewReportCTA: css`
    margin-top: 0px;
  `,
  promoteSection: css`
    display: flex;
    flex-direction: row;
  `,
  promoteCTA: css``,
  capitalize: css`
    text-transform: capitalize;
  `,
  slater: css`
    color: ${colors.slate};
  `,
  schoolSummary: css`
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  statusIcon: css`
    margin: -2px 1px 0 0;
  `,
  flyerCTAdisabled: css`
    background-color: #d9d9d9 !important;
    color: ${colors.slate} !important;
    border-color: #d9d9d9 !important;
  `,
};

const mapDispatchToProps = (dispatch: any): object => ({
  handleSuccess: (key: string): void => {
    dispatch(notifySuccess(NOTIFICATIONS[key]));
  },
  handleError: (key: string): void => {
    dispatch(notifyError(NOTIFICATIONS[key]));
  },
  handleWarning: (key: string): void => {
    dispatch(notifyWarn(NOTIFICATIONS[key]));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(CampaignDetails);
