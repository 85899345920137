import * as React from 'react';
import portalBffApolloClient from '../../../../_app/apollo/portalBFF.apolloClient';
import SAVE_CAMPAIGN_MUTATION from '../../gql/SaveCampaignMutation.graphql';
import MutationButton from '../../../../components/MutationButton';
import { mapCampaignDeliveryToGql } from '../../gql/utils';
import { gradesToAges, gradeFilterDefault } from '../../../shared/constants';
import GradeLevels from '@peachjar/school-finder-api/dist/api/GradeLevels';
import { get } from 'lodash';

type Props = {
  minGradeLevel: string;
  maxGradeLevel: string;
  campaignId: any;
  selectedAudience: string;
  checkboxSelections: Object;
  campaignStartDate: any;
  postExpirationDate: any;
  numberOfDistributions: number;
  areDistrictGuidelinesConfirmed: boolean;
  handleSuccess: any;
  handleError: any;
  disabled: any;
  children: any;
  postOnlySelections?: string[];
};

// export const update = (cache: any, { data: { saveCampaign } }) => {
//   // overwrites our apollo state defaults to include the newly created draft campaign
//   const queryResult = cache.readQuery({
//     query: GET_CAMPAIGNS_QUERY,
//   });

//   const idToReplace = saveCampaign.id;
//   const indexToReplace = queryResult.getMyCampaigns.findIndex(campaign => campaign.id === idToReplace);

//   queryResult.getMyCampaigns[indexToReplace] = saveCampaign;

//   cache.writeQuery({
//     query: GET_CAMPAIGNS_QUERY,
//     data: { getMyCampaigns: queryResult },
//   });
// };

const SaveCampaignDeliveryButton = ({
  campaignId,
  minGradeLevel,
  maxGradeLevel,
  selectedAudience,
  checkboxSelections,
  campaignStartDate,
  postExpirationDate,
  postOnlySelections = [],
  numberOfDistributions,
  areDistrictGuidelinesConfirmed,
  handleSuccess,
  handleError,
  disabled,
  children,
}: Props) => {
  const campaignDetails = {
    minGradeLevel: get(
      gradesToAges,
      minGradeLevel,
      gradesToAges[gradeFilterDefault.min]
    ),
    maxGradeLevel: get(
      gradesToAges,
      maxGradeLevel,
      gradesToAges[gradeFilterDefault.max]
    ),
  };
  const campaignDeliverySettings = mapCampaignDeliveryToGql({
    selectedAudience,
    checkboxSelections,
    campaignStartDate,
    postExpirationDate,
    numberOfDistributions,
    postOnlySelections,
    areDistrictGuidelinesConfirmed,
  });
  const variables = { campaignId, campaignDeliverySettings, campaignDetails };

  return (
    <MutationButton
      client={portalBffApolloClient}
      mutation={SAVE_CAMPAIGN_MUTATION}
      // update={update}
      variables={variables}
      handleSuccess={handleSuccess}
      handleError={handleError}
      disabled={disabled}
    >
      {children}
    </MutationButton>
  );
};

export default SaveCampaignDeliveryButton;
