import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { css } from 'emotion';
import { colors } from '@peachjar/components';

type Props = {
  link: string;
  icon: string;
  headline: string;
  classes: Record<string, any>;
  className: string;
  dataTestId: string;
  tag?: string | null;
  size?: string;
};

const HelpCard = ({
  link,
  icon,
  headline,
  classes,
  className,
  tag = null,
  dataTestId = 'card-help-card',
  size ='normal'
}: Props) => (
  <div className={`help-card ${className}`} data-testid={dataTestId}>
    <a href={link} target="_blank" rel="noopener" className={cn.cardLink}>
      <Paper className={`${cn.helpCard} ${classes.elevation2} ${cn.size[size]}`}>
        <div className={cn.iconContainer}>
          <img src={icon} alt="" data-testid="img-help-card-icon" />
        </div>
        <h3 className={cn.headline} data-testid="text-helpcard-headline">
          {headline}
        </h3>
        {tag && <p className={cn.tag}>{tag}</p>}
      </Paper>
    </a>
  </div>
);

// override material-ui default styles
const styles = {
  elevation2: {
    boxShadow: 'none',
  },
};

// classes
const cn = {
  helpCard: css`
    border: 1px solid rgb(217, 217, 217);
    border-radius: 6px;
    height: 239px;
    padding: 27px 20px 23px 20px;

    &:hover {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }

    &:active {
      box-shadow: 0px 5px 8px 3px rgba(0, 0, 0, 0.2),
        0px 2px 3px 1px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }
  `,
  iconContainer: css`
    text-align: center;
    // padding: 2px;
  `,
  cardLink: css`
    &:hover {
      text-decoration: none;
    }
  `,
  headline: css`
    color: ${colors.prussian};
    font-size: 18px;
    padding-top: 12px;
    text-align: center;
    margin-bottom: 0;
  `,
  tag: css`
    color: ${colors.prussian};
    font-size: 16px;
    padding-top: 4px;
    text-align: center;
  `,
  size: {
    normal: css`
      width: 322px;      
    `,
    large: css`
      width: 374px;      
    `,    
  },
};

export default withStyles(styles)(HelpCard);
