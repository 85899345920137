
import * as React from 'react';
import { css, cx } from 'react-emotion';
import { colors, elements, svg } from '@peachjar/components';

import { ApprovalStatus } from '../types';

const { CircleCheck } = svg;
const { MaterialIcon } = elements;
const { Paragraph } = elements.typography;

type Props = {
   status?: ApprovalStatus, // because we have no clue what this can really be
};

const SchoolStatusBarContent = ({ status }: Props) => {
  if (status === null) {
    return null;
  }
  if (status === 'approved') {
    return (
      <React.Fragment>
        <CircleCheck size="16" fill={colors.jungle} />
        <Paragraph className={classNames.margin}>Approved</Paragraph>
      </React.Fragment>
    );
  }
  // This really shouldn't happen (the approval would still be in pending)
  if (status === 'pending') {
    return (
      <Paragraph className={cx(classNames.margin, classNames.weNeedAnIconHack)}>
        &ndash; &ndash; Pending
      </Paragraph>
    );
  }
  // We've been told all other statuses should be treated as 'denied'
  return (
    <React.Fragment>
      <MaterialIcon
        color={colors.dragon}
        name="highlight_off"
        className={classNames.buttonIcon}
      />
      <Paragraph className={classNames.margin}>Denied</Paragraph>
    </React.Fragment>
  );
};

const classNames = {
  buttonIcon: css`
    line-height: 0;
  `,
  margin: css`
    margin: 0 1rem 0 0.375rem;
  `,
  media: css`
    @media (max-width: 996px) {
      display: none;
    }
  `,
  weNeedAnIconHack: css`
    line-height: 0;
    min-width: 1.5rem;
    transform: translateY(-0.5px);
  `,
};

export default SchoolStatusBarContent;
