import * as React from 'react';
import { isProd } from '../constants';
import { Roles } from './RolesEnum';

type SubappNavItemConfig = {
  label: string;
  fragmentOnUser?: any; // TODO fragment type
  BadgeComponent?: React.ComponentType<any>;
};
type SubappAppConfig = {
  Component: React.ComponentType<any>;
  fragmentOnUser?: any; // TODO fragment type
};
export type SubappConfig = {
  path: string;
  allowedRoles?: Array<Roles | Array<Roles>>;
  isLive?: boolean;
  navItemConfig: SubappNavItemConfig;
  appConfig: SubappAppConfig;
};
type SectionConfig = {
  sectionLabel: string;
  apps: Array<SubappConfig>;
  processMode: boolean;
};

// Types transforming app setup to app (not used when adding an app)
// App specific setup code: get things ready for the sidebar, etc.
type SidebarNavItem = SubappNavItemConfig & {
  path: string;
  allowedRoles?: Array<Roles | Array<Roles>>;
};
type SidebarSection = {
  sectionLabel: string;
  allowedRoles: Array<Roles | Array<Roles>>;
  items: Array<SidebarNavItem>;
  processMode: boolean;
};
export type SidebarConfig = Array<SidebarSection> | null;

type SubappApp =
  | (SubappAppConfig & {
      path: string;
      allowedRoles?: Array<Roles | Array<Roles>>;
    })
  | null;

type AppConfig = {
  apps: Array<SubappApp> | null;
  sidebarSections: Array<SidebarSection> | null;
};

const getSidebarItemsForSection = (
  section: SectionConfig
): Array<SidebarNavItem> =>
  section.apps
    .filter(({ isLive }) => isLive || isLive === undefined)
    .map(item => ({
      ...item.navItemConfig,
      path: item.path,
      allowedRoles: item.allowedRoles,
    }));

const getSubappAppsForSection = (section: SectionConfig): Array<SubappApp> =>
  section.apps
    .filter(({ isLive }) => isLive || isLive === undefined)
    .map(item => ({
      ...item.appConfig,
      path: item.path,
      allowedRoles: item.allowedRoles,
    }));

const getSidebarSectionForSection = (
  section: SectionConfig
): SidebarSection => {
  const sidebarItems = getSidebarItemsForSection(section);
  const sectionAllowedRoles = section.apps.reduce((accum, { allowedRoles }) => {
    const itemAllowedRoles = Array.isArray(allowedRoles) ? allowedRoles : [];
    return [...accum, ...itemAllowedRoles];
  }, []);

  return {
    sectionLabel: section.sectionLabel,
    allowedRoles: sectionAllowedRoles,
    items: sidebarItems,
    processMode: section.processMode,
  };
};

const generateAppConfig = (sectionConfigs: Array<SectionConfig>): AppConfig => {
  const isValidConfig = sectionConfigs && sectionConfigs.length;

  if (!isValidConfig) {
    return {
      sidebarSections: null,
      apps: null,
    };
  }

  const appConfig = sectionConfigs.reduce(
    (accum, section) => {
      const sidebarSection = getSidebarSectionForSection(section);
      const subapps = getSubappAppsForSection(section);

      return {
        sidebarSections: [...accum.sidebarSections, sidebarSection],
        apps: [...accum.apps, ...subapps],
      };
    },
    { sidebarSections: [], apps: [] }
  );

  return appConfig;
};

export default generateAppConfig;
