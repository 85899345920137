import React, { ChangeEvent, Component } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import StripeCreditCheckout from './StripeCreditCheckout';
import PayPalPaymentForm from '../components/PayPalPaymentForm';
import CreditPurchasingQuote from '../components/CreditPurchasingQuote';
import CreditBalance from '../components/CreditBalance';

import { css } from "emotion";
import { isNumber } from 'lodash';
import { connect } from 'react-redux';
import {
  CreditPurchasingState,
  getCreditPurchasingPrice,
  getCreditBalance as getBalance, RetrievalStates, CodedError,
} from "../credits-purchasing.duck";
import { ThunkDispatch } from "redux-thunk";
import { Quote, CreditBalance as Balance} from "../CommonModels";

import config from '../../config';

// classes
const cn = {
  root: css`
    padding: 50px;
    overflowX: 'auto';
    margin: 40px;
  `,
  paymentContainer: css`
    margin-top: 50px;
  `,
  leadIn: css`
    font-size: 0.9rem;
    margin: 30px 0 20px 0;
    color: #757575;
    font-weight: bold;
  `,
  priceTableRowHeader: css`
    font-weight: bold;
  `,
};

export type Props = {
  quote: Quote,
  balance: Balance,
  balanceFulfilled: RetrievalStates,
  balanceFulfilledError: CodedError,
  getCreditPurchasingQuote: (numberOfCredits: number) => void,
  getCreditBalance: () => void,
};

export type State = {
  numberOfCredits: string,
  numberOfCreditsIsValid: boolean,
};

class PurchaseCredits extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      numberOfCredits: '5',
      numberOfCreditsIsValid: true,
    };
  }

  componentDidMount() {
    const { getCreditBalance } = this.props;
    getCreditBalance();
  }

  updateNumberOfCredits(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const value = parseInt(e.target.value, 10);
    const numberOfCreditsIsValid = isNumber(value) && value > 0
    this.setState({
      ...this.state,
      numberOfCredits: e.target.value,
      numberOfCreditsIsValid,
    });
    if (numberOfCreditsIsValid) {
      this.props.getCreditPurchasingQuote(value);
    }
  }

  renderCreditBalance() {
    switch (this.props.balanceFulfilled) {
      case RetrievalStates.Retrieving: return <div>Loading...</div>;
      case RetrievalStates.Errored: return <div>Error: {this.props.balanceFulfilledError.message}</div>;
      case RetrievalStates.Retrieved: return <CreditBalance
        numberOfCredits={this.props.balance.numberOfCredits}
        showMsrp={true}
        msrpValue={this.props.balance.msrpValue}
        updatedAt={this.props.balance.updatedAt}
      />;
      default: return <div>Never loaded</div>;
    }
  }

  render() {
    const { numberOfCredits } = this.state;
    const intNumCredits = parseInt(numberOfCredits, 10);
    return (
      <Paper className={`${cn.root}`}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {this.renderCreditBalance()}
          </Grid>
          <Grid item xs={12}><hr /></Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <div className={`${cn.leadIn}`}>I want to purchase:</div>
                <TextField
                  label={'Credits:'}
                  type={'number'}
                  value={this.state.numberOfCredits}
                  onChange={this.updateNumberOfCredits.bind(this)}
                  error={!this.state.numberOfCreditsIsValid}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={6}>
                <CreditPurchasingQuote quote={this.props.quote} />
              </Grid>
            </Grid>
          </Grid>
          <div className={`${cn.paymentContainer}`}>
            <Grid container justify="center" alignItems="center" spacing={8}>
              <StripeCreditCheckout
                  details={{
                    lineItems: [{
                      numberOfCredits: intNumCredits,
                      lineItemId: '1',
                    }],
                  }}
                  redirectOnSuccess={`${config.REACT_APP_PORTAL_APP_URL}/credits/completed`}
                  redirectOnCancel={`${config.REACT_APP_PORTAL_APP_URL}/credits/cancelled`}
              />
              <PayPalPaymentForm />
            </Grid>
          </div>
        </Grid>
      </Paper>
    );
  }
}

type RootState = {
  creditPurchasing: CreditPurchasingState,
  balance: Balance,
  [k: string]: any,
};

const mapStateToProps = (state: RootState) => ({
  quote: state.creditPurchasing.quote,
  balance: state.creditPurchasing.balance,
  balanceFulfilled: state.creditPurchasing.balanceFulfilled,
  balanceFulfilledError: state.creditPurchasing.balanceFulfilledError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  getCreditPurchasingQuote: (numberOfCredits: number) =>
    dispatch(getCreditPurchasingPrice(numberOfCredits)),
  getCreditBalance: () => dispatch(getBalance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseCredits);
