import ApolloClient from "apollo-client";

import gql from "graphql-tag";
import FileTransferService from '../FileTransferService';

const GetPresignedUrlQuery = gql`
  query GetPresignedUrlQuery {
    getPresignedPdfUrl
  }
`;

const GetPresignedLODUrlQuery = gql`
  query GetPresignedLODUrlQuery {
    getPresignedLODPdfUrl
  }
`;

type GetPresignedUrlResponse = {
  getPresignedPdfUrl: string,
};

type GetPresignedLODUrlResponse = {
  getPresignedLODPdfUrl: string,
};

export default class GqlFileTransferService implements FileTransferService {

  constructor(
      protected client: ApolloClient<any>
  ) {}

  async getPresignedUrl(): Promise<string> {
    const result = await this.client.query<GetPresignedUrlResponse>({
      query: GetPresignedUrlQuery,
      fetchPolicy: 'no-cache'
    });
    const { data: { getPresignedPdfUrl } } = result;
    return getPresignedPdfUrl;
  }

  async getPresignedLODUrl(): Promise<string> {
    const result = await this.client.query<GetPresignedLODUrlResponse>({
      query: GetPresignedLODUrlQuery,
      fetchPolicy: 'no-cache'
    });
    const { data: { getPresignedLODPdfUrl } } = result;
    return getPresignedLODPdfUrl;
  }
}
