export const tooltipCopy = {
  gradeLevels:
    'Grade levels that are applicable to the flyer to help reach the appropriate people.',
  ages:
    'Ages that are applicable to the flyer to help reach the appropriate people.',
  eventLocation:
    'The physical address of the flyer location to help people with distance and directions.',
  deadlineDate:
    'The last day that people can register for your program to optimize the distribution of your flyer and inform people in time. If your program does not have an end date, the flyer posting will automatically be removed 24 hours after this registration deadline date has passed.',
  startDate:
    'The day your program starts to optimize the distribution of your flyer and inform people in time.',
  endDate:
    'The day your program ends to optimize the distribution of your flyer and inform people in time. The flyer posting will automatically be removed 24 hours after the end date has passed.',
  participationFee:
    'The average cost to participate in the program to help people find flyers within their budget.',
};
