export enum DeliveryModes {
  PostOnly = 'post-only',
  PostAndDistribute = 'post-and-distribute',
  PostAndOptIn = 'post-and-opt-in',
}

export type Audience = {
  audienceType: string;
  districtId: number;
  districtName: string;
  schoolId: number;
  schoolName: string;
  isPostOnly: boolean;
  isOptionalPostOnly: boolean;
  isPostOnlyChecked: boolean;
  highGrade: string;
  lowGrade: string;
};
