import React, { useContext } from 'react';
import { css } from 'emotion';
import { colors } from '@peachjar/components';
import Drawer from '@material-ui/core/Drawer';
import AppDrawerContext from '../../context/AppDrawerContext';
import closeIcon from '../../../assets/close-icon.svg';

const AppDrawer = (props: any) => {
  const { isOpen, toggleDrawer, context, contextProps } = useContext(
    AppDrawerContext
  );
  const { component: DrawerContent, drawerTitle } = context; //child component to render inside AppDrawer

  //pass down all props in case drawer needs them
  const mergedProps = {
    ...props,
    ...contextProps,
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <div className={cn.titleSection}>
        <div className={cn.titleWrapper}>
          <h3 className={cn.titleText}>{drawerTitle}</h3>
        </div>
        <div className={cn.drawerControl}>
          <img
            className={cn.closeIcon}
            src={closeIcon}
            onClick={toggleDrawer}
            alt="close drawer"
            height="20"
            width="20"
          />
        </div>
      </div>
      <div className={cn.content}>
        <DrawerContent {...mergedProps} />
      </div>
    </Drawer>
  );
};

const cn = {
  titleSection: css`
    background-color: #ffe6aa;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 16px 8px 30px;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  `,
  titleWrapper: css``,
  titleText: css`
    color: ${colors.prussian};
    font-size: 25px;
    margin: 0;
    line-height: 1.45;
  `,
  drawerControl: css`
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  closeIcon: css`
    color: ${colors.prussian};
    font-size: 18px;
    cursor: pointer;
    line-height: 1.25;
  `,
  content: css`
    padding: 32px;
    overflow-y: scroll;
    margin-top: 28px;
  `,
};

export default AppDrawer;
