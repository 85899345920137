import gql from 'graphql-tag';

import PrepareFragments from '../Prepare/gql/PrepareFragments.graphql';
import DetailsFragments from '../Details/gql/DetailsFragments.graphql';
import DeliveryFragments from '../Delivery/gql/DeliveryFragments.graphql';
import SummaryFragments from '../Summary/components/gql/SummaryFragments.graphql';

export default {
  sodUser: gql`
    fragment CreateCampaign_sodUser on Uploader {
      campaignDistricts {
        ...Delivery_sodUser_campaignDistricts
      }
    }
    ${DeliveryFragments.sodUser_campaignDistricts}
  `,
  getMyCampaigns: gql`
    fragment CreateCampaign_getMyCampaigns on Campaign {
      flyer {
        ...Prepare_getMyCampaigns
      }
      ...Details_getMyCampaigns
      deliverySettings {
        ...Delivery_getMyCampaigns_targetAudiences
      }
      ...Summary_getMyCampaigns
    }
    ${PrepareFragments.getMyCampaigns}
    ${DetailsFragments.getMyCampaigns}
    ${DeliveryFragments.getMyCampaigns_targetAudiences}
    ${SummaryFragments.getMyCampaigns}
  `,
};
