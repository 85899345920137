import React from 'react';
import { css } from 'emotion';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { elements, colors } from '@peachjar/components';
import historyIcon from '../../../assets/icon_approvalHistory.svg';

const { orange, jungle } = colors;
const {
  typography: { Paragraph },
} = elements;

interface IProps {
  classes: { [key: string]: string };
  headline: string;
  subheadline: string;
  iconSrc?: any;
  variant?: 'default' | 'pop' | 'covid' | 'covidWhite' | 'orange';
  children?: any;
  inlineStyles: { [key: string]: string };
  isSubheadlineHTML?: boolean;
}

/**
 * Generic message banner intended for use on next gen portal dashboard.
 * Example usage:
 * <MessageBanner
 *     headline="Verify Your Non-Profit Status"
 *    subheadline="subheadline text here..."
 *     variant="pop"
 *     iconSrc={distributionIcon}
 *   >
 *     <ButtonSecondarySmall
 *       data-testid="click-community-free-deal"
 *     onClick={() => window.location = `${REACT_APP_PEACHJAR_HOMEPAGE}/index.php?a=4&b=4`}
 *     >
 *       Update Account Information
 *   </ButtonSecondarySmall>
 */
const MessageBanner = ({
  headline,
  subheadline,
  iconSrc = historyIcon,
  variant = 'default',
  inlineStyles,
  classes,
  isSubheadlineHTML = false,
  children,
  ...rest
}: IProps): React.ReactElement => (
  <Grid item xs={12} {...rest}>
    <Paper
      style={inlineStyles}
      className={`${cn.welcomeContent} ${classes.elevation2} ${cn.variants[variant]}`}
    >
      <div className={cn.flexRow}>
        <div className={cn.icon}>
          <img src={iconSrc} alt="Metrics Icon" />
        </div>
        <div className={cn.kicker}>
          {headline && <h3 className={cn.headline}>{headline}</h3>}
          {isSubheadlineHTML ? (
            <Paragraph dangerouslySetInnerHTML={{ __html: subheadline }} />
          ) : (
            <Paragraph>{subheadline}</Paragraph>
          )}
        </div>
      </div>
      {children && (
        <div className={cn.flexColumn}>
          <div className={cn.ctasContainer}>
            <div className={cn.ctas}>{children}</div>
          </div>
        </div>
      )}
    </Paper>
  </Grid>
);

// Override default material-ui styles
const materialOverrides = {
  elevation2: {
    boxShadow: 'none',
  },
};

const cn = {
  welcomeContent: css`
    padding: 15px 15px 27px 15px;
  `,
  flexRow: css`
    display: flex;
    flex-direction: row;
  `,
  flexColumn: css`
    display: flex;
    flex-direction: column;
  `,
  kickerPadding: css`
    padding-top: 9px;
  `,
  kicker: css`
    span > a {
      color: ${jungle};
      &:hover {
        text-decoration: none;
        color: #c5e8c9;
      }
    }
  `,
  headline: css`
    font-size: 17px;
    font-weight: 600;
  `,
  icon: css`
    padding: 0 14px 0 0;
  `,
  ctasContainer: css`
    padding-top: 4px;
    padding-left: 66px;
    display: flex;
    flex-direction: column;
  `,
  ctas: css`
    margin-top: 14px;
  `,
  variants: {
    default: css``,
    pop: css`
      border-top: 3px solid #61c16c;
    `,
    covid: css`
      border-top: 3px solid ${orange};
    `,
    covidWhite: css`
      border-top: none;
    `,
    warning: css`
      border-top: 3px solid ${orange};
      background: #fffae5 !important;
    `,
    orange: css`
      background: #fffae5 !important;
    `,
  },
};

export default withStyles(materialOverrides)(MessageBanner);
