import React from 'react';
import { get } from 'lodash';
import { css } from 'emotion';
import { RouteComponentProps } from 'react-router-dom';
import { components } from '@peachjar/components';
import { useQuery as uq } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Paper } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import { apolloClient as portalBffClient } from '../../_app/apollo/portalBFF.apolloClient';
import SubappHeader from '../../_app/components/SubappHeader';
import MiniBreadcrumb from '../../components/MiniBreadcrumb';
import DetailsHeader from '../components/DetailsHeader';
import OrderSummary from '../../_app/components/OrderSummary';
import FlyerOrderSummaryPreview from '../../_app/components/FlyerOrderSummaryPreview';
import FlyerOrderSummaryDetails from '../../_app/components/FlyerOrderSummaryDetails';
import FullPageSpinner from '../../_app/components/FullPageSpinner';
import config from '../../config';

const {
  Buttons: { ButtonSecondarySmall, ButtonFlatSmall, ButtonPrimaryLarge },
} = components;

const { REACT_APP_PORTAL_APP_URL } = config;

type Props = {
  match: RouteComponentProps<TParams>,
  location: RouteComponentProps<TParams>,
  history: RouteComponentProps<TParams>,
  styles: { [k: any]: any },
  useQuery: () => any,
};

const Details = ({
  match,
  location,
  history,
  styles,
  useQuery = uq,
}: Props) => {
  // Passed as url param for getPurchaseOrder query
  const { purchaseId } = match.params;

  const { data, loading, error } = useQuery(GET_DETAILS, {
    variables: { purchaseId },
    client: portalBffClient,
    fetchPolicy: 'no-cache',
  });

  if (error) {
    history.push('/account/transactions');
  }

  let quote;

  let transactionId;

  let transactionDate;

  let flyerTitle;

  let flyerId;

  let numberOfDistributions;

  if (!loading && data) {
    const { getPurchaseOrder } = data;

    const creditTransactionId = get(
      getPurchaseOrder,
      'creditPurchaseTransaction.id'
    );
    const creditTransactionDate = get(
      getPurchaseOrder,
      'creditPurchaseTransaction.timestamp'
    );
    const flyerTransactionId = get(
      getPurchaseOrder,
      'flyerPurchaseTransaction.id'
    );
    const flyerTransactionDate = get(
      getPurchaseOrder,
      'flyerPurchaseTransaction.timestamp'
    );

    quote = getPurchaseOrder.quote;
    transactionId = flyerTransactionId || creditTransactionId;
    transactionDate = flyerTransactionDate || creditTransactionDate;
    flyerTitle = getPurchaseOrder.campaign.title;
    flyerId = getPurchaseOrder.campaign.legacyFlyerId;
    numberOfDistributions =
      getPurchaseOrder.campaign.deliverySettings.numberOfDistributions;
  }

  if (loading) return <FullPageSpinner />;

  return (
    <>
      <MiniBreadcrumb
        linkTo="/account/transactions"
        text="Transaction History"
      />
      <div className={styles.subAppHeaderWrapperWithPrintButton}>
        <SubappHeader className={styles.subAppHeaderWithBreadcrumb}>
          Credit Use Details
        </SubappHeader>
        <div>
          <span
            className={css`
              padding-right: 20px;
            `}
          >
            <ButtonFlatSmall
              onClick={() => {
                window.location.href = `${REACT_APP_PORTAL_APP_URL}/reporting/my-flyers/report/${flyerId}`;
              }}
            >
              View Report
            </ButtonFlatSmall>
          </span>
          <ReactToPrint
            trigger={() => (
              <ButtonSecondarySmall>Print Details</ButtonSecondarySmall>
            )}
            content={() => document.getElementById('transaction-details')}
          />
        </div>
      </div>
      <Paper elevation={0} className={styles.paperMargin}>
        <div
          className={styles.subAppContainerDetailsVariant}
          id="transaction-details"
        >
          <DetailsHeader
            flyerTitle={flyerTitle}
            flyerId={flyerId}
            transactionId={transactionId}
            transactionDate={transactionDate}
            styles={styles}
          />
          <hr style={{ margin: 0 }} />
          <FlyerOrderSummaryPreview
            quote={quote}
            numberOfDistributions={numberOfDistributions}
            variant="details"
          />
          <FlyerOrderSummaryDetails quote={quote} variant="details" />
          <OrderSummary quote={quote} variant="details" />
          {/* Commenting out until flyer reporting issue addressed */}
          {/* <div className={styles.reportButtonWrapper}>
            <ButtonPrimaryLarge
              onClick={() =>
                // history.push('/reporting/my-flyers/report', { flyerId })
                history.push(`/reporting/all-flyers?flyer=true&id=${flyerId}`)
              }
            >
              <span style={{ fontFamily: 'Proxima Nova' }}>View Report</span>
            </ButtonPrimaryLarge>
          </div> */}
        </div>
      </Paper>
    </>
  );
};

const GET_DETAILS = gql`
  query getDetails($purchaseId: String!) {
    getPurchaseOrder(purchaseId: $purchaseId) {
      id
      purchaserId
      campaign {
        title
        legacyFlyerId
        deliverySettings {
          numberOfDistributions
        }
      }
      flyerPurchaseTransaction {
        id
        transactionGroupId
        timestamp
      }
      creditPurchaseTransaction {
        id
        transactionGroupId
        timestamp
      }
      quote
    }
  }
`;

export default Details;
