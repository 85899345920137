import React, { useContext, useState } from 'react';
import idx from 'idx';
import toString from 'lodash/toString';
import { connect } from 'react-redux';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { css } from 'emotion';
import numeral from 'numeral';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import { colors, elements, components } from '@peachjar/components';
import Dashed from '@peachjar/ui/dist/lib/components/Dashed';
import apolloClient from '../../apollo/portalBFF.apolloClient';
import AppDrawerContext from '../../context/AppDrawerContext';
import flyerPlaceHolderThumbnail from '../../../assets/flyer_placeholder.svg';
import { utcDateToMidnightLocal } from '@peachjar/ui/dist/lib/utils/dates';
import RemoveFlyerModal from '../../../components/RemoveFlyerModal';
import { NOTIFICATIONS } from '../../constants';
import {
  REMOVE_FLYER_MUTATION,
  FLYER_DETAILS_QUERY,
} from './FlyerReportingCard.graphql';
import CommunityFreeBadge from '../CommunityFreeBadge';

const {
  Buttons: { ButtonSecondarySmall, SplitButton },
  Notifications: { notifySuccess, notifyError },
} = components;
const { Label, Note } = elements.typography;
const dateFns = new DateFnsUtils();

const truncate = str => {
  const length = 45;
  if (str.length > length) {
    return `${str.substring(0, length)}...`;
  }
  return str;
};

const FlyerReportingCard = ({
  flyer,
  classes,
  history,
  handleSuccess,
  handleError,
}) => {
  const { loading: flyerDetailsLoading, data: flyerDetailsData } = useQuery(
    FLYER_DETAILS_QUERY,
    {
      variables: { id: parseInt(flyer.flyerId, 10) },
      client: apolloClient,
    }
  );

  const [removeMyFlyer, { error: removeMyFlyerError }] = useMutation(
    REMOVE_FLYER_MUTATION,
    {
      client: apolloClient,
    }
  );
  const [removeFlyerModalOpen, setRemoveFlyerModalOpen] = useState(false);
  const { toggleDrawer, setDrawerContext } = useContext(AppDrawerContext);
  const flyerImageUrl = idx(flyer, _ => _.thumbnailUrl) || null;
  const flyerTitle = truncate(idx(flyer, _ => _.title)) || null;
  const flyerId = idx(flyer, _ => _.flyerId) || null;
  const removalDate = idx(flyer, _ => _.removalDate) || null;
  const distributionsSchoolCount =
    idx(flyer, _ => _.distributionsSchoolCount) || null;
  const flyerDistributions = idx(flyer, _ => _.distributions) || null;
  const submittedDate = idx(flyer, _ => _.submittedDate) || null;
  const flyerDistributionDate =
    idx(flyer, _ => _.distributionsFirstDate) || null;
  const flyerExpirationDate = idx(flyer, _ => _.expirationDate) || null;
  const flyerStatus = idx(flyer, _ => _.distributionStatus) || null;
  const uniqueDeliveries = idx(flyer, _ => _.uniqueDeliveries) || null;
  const uniqueDisplays =
    numeral(idx(flyer, _ => _.uniqueDisplays)).format('0,0') || null;
  const uniqueViews =
    numeral(idx(flyer, _ => _.uniqueViews)).format('0,0') || null;
  const uniqueActions =
    numeral(idx(flyer, _ => _.uniqueActions)).format('0,0') || null;

  const checkMetricRendering = val => val && val !== 0 && val !== '0';
  const formattedDistributionDate = flyerDistributionDate
    ? dateFns.format(new Date(flyerDistributionDate * 1000), 'MMM dd, yyyy')
    : null;

  // Expiration is a Date without time, so we need to translate from UTC date
  const formattedExpirationDate = flyerExpirationDate
    ? dateFns.format(
        new Date(utcDateToMidnightLocal(flyerExpirationDate * 1000)),
        'MMM dd, yyyy'
      )
    : null;
  const formattedRemovalDate = removalDate
    ? dateFns.format(new Date(removalDate * 1000), 'MMM dd, yyyy')
    : null;
  const formattedSubmittedDate = submittedDate
    ? dateFns.format(new Date(submittedDate * 1000), 'MMM dd, yyyy')
    : null;
  const isCommFree = idx(flyer, _ => _.type) === 'community_free';

  const expirationDateLabel = () =>
    flyerStatus === 'removed' ? 'Removed' : 'Post Expiration Date';

  const getExpirationDateOrRemovalDate = () => {
    const label = expirationDateLabel();
    if (label === 'Removed') {
      return formattedRemovalDate;
    }
    return formattedExpirationDate;
  };

  const expirationOrRemovalDate = getExpirationDateOrRemovalDate();

  const sodId = idx(flyerDetailsData, _ => _.sodUser.userId) || null;
  const uploaderLevel =
    idx(flyerDetailsData, _ => _.sodUser.permission.uploaderLevel) || null;
  const sodFlyerReportingId =
    idx(
      flyerDetailsData,
      _ => _.sodUser.flyerReporting.flyerDetailReport.campaign.ownerId
    ) || null;

  const renderRemoveFlyerBtn = toString(sodId) === sodFlyerReportingId;
  const renderSplitButton =
    renderRemoveFlyerBtn &&
    !['removed', 'expired'].includes(flyerStatus.toLowerCase());

  const handleViewReport = event => {
    event.stopPropagation();
    history.push(`${window.location.pathname}/report/${flyerId}`);
  };

  const removeFlyer = () => {
    removeMyFlyer({ variables: { flyerId } });
    if (!removeMyFlyerError) {
      handleSuccess('removeFlyerSuccess');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      handleError('removeFlyerError');
    }
  };

  const splitButtonConfig = {
    primaryButtonHandler: event => handleViewReport(event),
    menuItems: [
      {
        label: 'Remove Flyer',
        clickHandler: e => {
          e.stopPropagation();
          setDrawerContext('FlyerRemoval');
          toggleDrawer({ flyerId });
        },
      },
    ],
  };

  if (flyerDetailsLoading) {
    return null;
  }

  return (
    <Grid item xs={12} className={cn.flyerSection}>
      <Paper className={`${cn.paper} ${classes.elevation2}`}>
        {isCommFree && (
          <div className={cn.commFreeBadge}>
            <CommunityFreeBadge />
          </div>
        )}
        <div
          className={cn.flyerContent}
          onClick={() => {
            setDrawerContext('FlyerDetails');
            toggleDrawer({ flyerId });
          }}
        >
          <div className={cn.thumbnailContainer}>
            <img
              className={cn.thumbnail}
              src={flyerImageUrl || flyerPlaceHolderThumbnail}
              alt=""
              width="89"
              height="120"
              data-testid="image-flyer-card-thumbnail"
            />
          </div>
          <div className={cn.flyerDetails}>
            <div className={cn.flyertitleContainer}>
              <Label
                bold
                className={cn.flyerTitle}
                data-testid="text-flyer-card-title"
              >
                {flyerTitle}
              </Label>
              <span className={cn.flyerId} data-testid="text-flyer-id">
                #{flyerId}
              </span>
            </div>
            <div
              className={cn.flyerStatusContainer}
              data-testid="text-flyer-status"
            >
              <Chip
                label={flyerStatus}
                className={`${cn.flyerStatus} ${cn[flyerStatus]}`}
              />
            </div>
            <Note
              className={cn.spaceBetween}
              data-testid="text-flyer-submittedDate"
            >
              Submitted Date: {formattedSubmittedDate}
            </Note>
            <Note
              className={cn.spaceBetween}
              data-testid="text-flyer-distributions"
            >
              Distributions: {flyerDistributions}
            </Note>
            <Note
              className={cn.spaceBetween}
              data-testid="text-flyer-distribution-date"
            >
              First Distribution Date: {formattedDistributionDate || <Dashed />}
            </Note>
            <Note
              className={cn.spaceBetween}
              data-testid="text-flyer-expiration-date"
            >
              {`${expirationDateLabel()}: `}
              {expirationOrRemovalDate || <Dashed />}
            </Note>
            <Note
              className={cn.spaceBetween}
              data-testid="text-flyer-school-distributions"
            >
              Schools Distributed: {distributionsSchoolCount || <Dashed />}
            </Note>
          </div>
          <div className={cn.metricsContainer}>
            <div className={cn.metricsSection}>
              <Note
                className={cn.metricsCount}
                data-testid="text-delivery-count"
              >
                {checkMetricRendering(uniqueDeliveries) ? (
                  numeral(uniqueDeliveries).format('0,0')
                ) : (
                  <Dashed />
                )}
              </Note>
              <Note className={cn.metricsLabel}>Deliveries</Note>
            </div>
            <div className={cn.metricsSection}>
              <Note
                className={cn.metricsCount}
                data-testid="text-display-count"
              >
                {checkMetricRendering(uniqueDisplays) ? (
                  numeral(uniqueDisplays).format('0,0')
                ) : (
                  <Dashed />
                )}
              </Note>
              <Note className={cn.metricsLabel}>Impressions</Note>
            </div>
            <div className={cn.metricsSection}>
              <Note className={cn.metricsCount}>
                {checkMetricRendering(uniqueViews) ? (
                  numeral(uniqueViews).format('0,0')
                ) : (
                  <Dashed />
                )}
              </Note>
              <Note className={cn.metricsLabel}>Views</Note>
            </div>
            <div className={cn.metricsSection}>
              <Note className={cn.metricsCount}>
                {checkMetricRendering(uniqueActions) ? (
                  numeral(uniqueActions).format('0,0')
                ) : (
                  <Dashed />
                )}
              </Note>
              <Note className={cn.metricsLabel}>Actions</Note>
            </div>
          </div>
          <div
            className={`${cn.flyerCTAcontainer} ${
              renderSplitButton ? 'splitMenu' : ''
            }`}
          >
            {renderSplitButton ? (
              <SplitButton splitButtonConfig={splitButtonConfig}>
                View Report
              </SplitButton>
            ) : (
              <ButtonSecondarySmall
                data-testid="click-flyer-card-view-report"
                onClick={handleViewReport}
                className={cn.flyerCTA}
              >
                View Report
              </ButtonSecondarySmall>
            )}
          </div>
        </div>
      </Paper>
      <RemoveFlyerModal
        open={removeFlyerModalOpen}
        onRemove={removeFlyer}
        onClose={() => setRemoveFlyerModalOpen(false)}
        uploaderLevel={uploaderLevel}
      />
    </Grid>
  );
};

const cn = {
  flyerSection: css`
    margin-bottom: 24px !important;
  `,
  paper: css`
    border: 1px solid #e6e6e6;
    position: relative;
    &:hover {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }
  `,
  thumbnailContainer: css`
    padding: 0 16px 0 0;
  `,
  thumbnail: css`
    padding: 0;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
  `,
  flyertitleContainer: css`
    position: relative;
  `,
  flyerContent: css`
    display: flex;
    flex-direction: row;
    padding: 24px;
    cursor: pointer;
  `,
  flyerTitle: css`
    color: ${colors.prussian};
    display: block;
    width: 228px;
    word-break: break-word;
  `,
  flyerId: css`
    font-size: 14px;
    color: ${colors.slate};
    font-weight: 400;
    vertical-align: 2px;
    position: absolute;
    top: 2px;
    right: 0;
  `,
  flyerStatus: css`
    color: ${colors.prussian} !important;
    font-size: 13px !important;
    font-weight: normal;
    text-transform: capitalize;
  `,
  flyerDetails: css`
    display: flex;
    flex-direction: column;
    width: 290px;
    margin-right: 16px;
  `,
  flyerStatusContainer: css`
    margin: 8px 0 4px 0;
    width: 94px;
  `,
  spaceBetween: css`
    font-size: 16px;
    padding-top: 5px;
    color: ${colors.slate};
  `,
  metricsContainer: css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 523px;
  `,
  metricsCount: css`
    display: block;
    font-size: 24px;
    padding-bottom: 8px;
  `,
  metricsLabel: css`
    display: block;
    padding: 0 20px;
  `,
  metricsSection: css`
    text-align: center;
  `,
  submitted: css`
    background-color: ${colors.mercury} !important;
  `,
  declined: css`
    background-color: #ffebe5 !important;
  `,
  active: css`
    background-color: #e7f4e9 !important;
  `,
  scheduled: css`
    background-color: #dfe1e7 !important;
  `,
  removed: css`
    background-color: #ffefc8 !important;
  `,
  expired: css`
    background-color: #fffae5 !important;
  `,
  flyerCTA: css``,
  flyerCTAcontainer: css`
    display: flex;
    align-items: center;
    width: 150px;
    padding-left: 45px;

    &.splitMenu {
      padding-left: 15px !important;
    }
  `,
   commFreeBadge: css`
    position: absolute;
    top: 0;
    left: 0;
  `,
};

const materialUIstyles = {
  elevation2: {
    boxShadow: 'none',
  },
};

const mapDispatchToProps = dispatch => ({
  handleSuccess: key => {
    dispatch(notifySuccess(NOTIFICATIONS[key]));
  },
  handleError: key => {
    dispatch(notifyError(NOTIFICATIONS[key]));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(materialUIstyles)(FlyerReportingCard));
