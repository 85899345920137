export const MAX_FIRST_NAME_LENGTH = 50;
export const MAX_LAST_NAME_LENGTH = 50;
export const MAX_ORGANIZATION_NAME_LENGTH = 50;

export const MAX_PHONE_NUMBER_LENGTH = 10;

export const MISSED_FIELD_ERROR_MESSAGE = 'You missed this field.';
export const FIRST_NAME_MAX_LENGTH_ERROR_MESSAGE = `Enter ${MAX_FIRST_NAME_LENGTH} characters or less.`;
export const LAST_NAME_MAX_LENGTH_ERROR_MESSAGE = `Enter ${MAX_LAST_NAME_LENGTH} characters or less.`;
export const ORGANIZATION_NAME_MAX_LENGTH_ERROR_MESSAGE = `Enter ${MAX_ORGANIZATION_NAME_LENGTH} characters or less.`;
export const PHONE_NUMBER_MAX_LENGTH_ERROR_MESSAGE = `Enter 10 characters or less.`;

export const INVALID_FED_TAX_ID = 'Enter a valid Federal Tax ID or EIN.';
export const INVALID_POSTAL_CODE = 'Enter a valid postal code.';
export const INVALID_PHONE_NUMBER = 'Enter a valid phone number.';
export const INVALID_EMAIL_ADDRESS = 'Enter a valid email address.';
export const INVALID_ORGANIZATION_WEBSITE = 'Enter a valid web address.';

export const TOOLTIP_TEXT =
  'Uploading your Letter of Determination will facilitate flyer approval by district staff.';

export const orgTypes = [
  { value: 0, label: 'For Profit' },
  { value: 1, label: 'Non-profit' },
  { value: 2, label: 'Government' },
];
