import { useQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import { ButtonPrimarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons';
import MessageBoxNotification from '@peachjar/ui/dist/lib/components/Notifications/MessageBoxNotification';
import gql from 'graphql-tag';
import idx from 'idx';
import React, { FC } from 'react';
import distributionSettingIcon from '../assets/icon_orange_checklist.svg';
import portalBFFClient from '../_app/apollo/portalBFF.apolloClient';
import config from '../config';

const DISTRIBUTION_SETTINGS = gql`
  query getDistributionSettings($districtId: String!) {
    distributionScheduleSettings(
      input: { hierarchyType: "district", hierarchyId: $districtId }
    ) {
      hasBeenSaved
    }
  }
`;

interface DistributionsQueryVars {
  districtId: string;
}

interface DistributionsQueryResult {
  distributionScheduleSettings: {
    hasBeenSaved: boolean,
  };
}

interface Props {
  districtId: string;
}

const DistributionSettingsNotification: FC<Props> = ({ districtId }) => {
  const { data } = useQuery<DistributionsQueryResult, DistributionsQueryVars>(
    DISTRIBUTION_SETTINGS,
    {
      variables: {
        districtId,
      },
      client: portalBFFClient,
    }
  );

  return (
    (idx(data, _ => _.distributionScheduleSettings.hasBeenSaved) === false && (
      <Grid item xs={12}>
        <MessageBoxNotification
          headline="Distribution Setting"
          subheadline="There are new distribution settings that impact the scheduling of your flyers. Please confirm these distribution settings are set to your preference."
          iconSrc={distributionSettingIcon}
          variant="popWarning"
        >
          <ButtonPrimarySmall
            href={`${config.REACT_APP_PORTAL_BFF_URL}/settings/distribution`}
          >
            View Distribution Setting
          </ButtonPrimarySmall>
        </MessageBoxNotification>
      </Grid>
    )) || <></>
  );
};

export default DistributionSettingsNotification;
