import React from 'react';
import { elements } from '@peachjar/components';
import CampaignSlatList from './CampaignSlatList';
import DraftCampaignsFragments from './gql/DraftCampaignFragments.graphql';

const { Headline2 } = elements.typography;

const DraftCampaigns = ({ campaigns, onRemoveDraftCampaign }) => (
  <>
    <Headline2 className="mb-4" data-testid="drafts-count">
      Drafts ({campaigns.length})
    </Headline2>

    <CampaignSlatList
      campaigns={campaigns}
      onRemoveDraftCampaign={onRemoveDraftCampaign}
    />
  </>
);

DraftCampaigns.fragments = DraftCampaignsFragments;

export default DraftCampaigns;
