import React from 'react';
import { Mutation } from 'react-apollo';
import SpinningSubmitButton from './SpinningSubmitButton';
import { APP_ROUTES } from '../_app/constants';

const RemoveFlyerMutationButton = ({
  mutation,
  variables,
  handleError,
  handleSuccess,
  handleClose,
  update,
  refetchQueries,
  client,
  something,
  children,
  disabled,
  loading: moreLoading,
  ...rest
}) => {
  return (
    <Mutation
      mutation={mutation}
      variables={variables}
      update={update}
      onCompleted={() => {}}
      refetchQueries={refetchQueries}
      awaitRefetchQueries={!!refetchQueries}
      client={client}
    >
      {(mutate, { loading, error }) => {
        const props = {
          loading: loading || moreLoading,
          error,
          handleClick: async () => {
            try {
              mutate();
              handleClose();
              handleSuccess('removeFlyerSuccess');
              if (window.location.href.includes(APP_ROUTES.reporting.index)) {
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            } catch {
              handleError('removeFlyerError');
            }
          },
          disabled,
          ...rest,
        };
        return (
          <SpinningSubmitButton {...props}>{children}</SpinningSubmitButton>
        );
      }}
    </Mutation>
  );
};

export default RemoveFlyerMutationButton;
