import * as React from 'react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { connect } from 'react-redux';
import idx from 'idx';
import { css, cx } from 'emotion';
import { components, elements } from '@peachjar/components';

import { GQLStuff } from '../_app/App';
import {
  PendingApprovals_sodUser,
  Sod,
} from './__generated__/PendingApprovals_sodUser';
import { PendingApprovals_flyerReasons } from './__generated__/PendingApprovals_flyerReasons';

import SubappHeader from '../_app/components/SubappHeader';
import ErrorBoundary from '../all_approvals/components/ErrorBoundary';
import NoPendingApprovalsView from './components/NoPendingApprovalsView';
import ApprovalCard from '../all_approvals/components/ApprovalCard';
import SubmitPublicationRequestReviewsButton from '../all_approvals/components/SubmitPublicationRequestReviewsButton';
import { getNumberOfStagedFlyers } from '../all_approvals/selectors';
import LoadingSpinner from '../components/LoadingSpinner';

import RegionSelectionModal from './components/RegionSelectionModal';
import RegionSelectionButton from './components/RegionSelectionButton';
import areRegionsMultiDistrict from '../all_approvals/areRegionsMultiDistrict';
import { Flags } from '../_app/flagging/flags';
import { APP_ROUTES } from '../_app/constants';
import MiniBreadcrumb from '../components/MiniBreadcrumb';
import { RoleCheckWithRedirect } from '../_app/components/RoleCheck';
import { pendingApprovalsAllowedRoles } from '../_app/RolesEnum';

const { ModalConsumer } = components;
const { Paragraph } = elements.typography;

type ReduxStateProps = {
  flags: Flags;
  numberOfStagedApprovals: number;
};

type OwnProps = {
  sodUser: PendingApprovals_sodUser;
  flyerReasons: PendingApprovals_flyerReasons[];
  gqlStuff: GQLStuff;
  showModal: (node: React.ReactNode, any: any) => void;
  closeModal: () => void;
  shouldUserSeeApproverNotesButton: boolean;
};

type Props = ReduxStateProps & OwnProps;

type State = {
  selectedRegionName?: string;
};

class PendingApprovals extends React.Component<Props, State> {
  static fragments: { [key: string]: any };

  state = {
    selectedRegionName: null,
  };

  changeRegion = (sod: Sod, sodId: number, regionName: string): void => {
    const { gqlStuff } = this.props;
    this.setState({ selectedRegionName: regionName });
    gqlStuff.updateQueryVariables({
      PendingApprovals_sodUser_sod: sod,
      PendingApprovals_sodUser_sodId: sodId.toString(),
    });
  };

  render() {
    // @ts-ignore
    const { portal_nav } = this.props.flags;
    const isPortalNavEnabled = portal_nav === 1;
    const {
      numberOfStagedApprovals,
      sodUser,
      gqlStuff,
      flyerReasons,
    } = this.props;
    const { selectedRegionName } = this.state;
    const { loading } = gqlStuff.apolloHelpers;

    const adminLevel = idx(sodUser, _ => _.permission.adminLevel) || null;
    const approverLevel = idx(sodUser, _ => _.permission.approverLevel) || null;

    // Subapp visibility should screen out any non-approvers.
    const sod = approverLevel || 'school';

    const flyers = (idx(sodUser, _ => _.flyers) || []).filter(Boolean);
    const numberOfPendingApprovals = flyers.length;
    const isSubmitButtonEnabled =
      numberOfStagedApprovals > 0 &&
      numberOfStagedApprovals === numberOfPendingApprovals;
    const hasApprovalsToShow = numberOfPendingApprovals > 0;

    const approvalCenterRegions =
      idx(sodUser, _ => _.approvalCenterRegions) || [];
    const shouldUserSeeApproverNotesButton = !areRegionsMultiDistrict(
      approvalCenterRegions
    );
    const shouldUserSeeRegionSelection = approvalCenterRegions.length > 1;
    const defaultRegionName = idx(approvalCenterRegions, _ => _[0].name);
    const selectedRegionNameToRender = selectedRegionName || defaultRegionName;

    return (
      <RoleCheckWithRedirect roles={pendingApprovalsAllowedRoles}>
        <ModalConsumer>
          {({ showModal, closeModal }) => (
            <>
              {isPortalNavEnabled && (
                <MiniBreadcrumb
                  linkTo={APP_ROUTES.approvals.index}
                  text="Approvals"
                />
              )}
              <SubappHeader
                className={`${classNames.header} ${
                  isPortalNavEnabled ? classNames.subappHeader : ''
                }`}
              >
                <div className={classNames.headerRow}>
                  Pending Approvals
                  {sodUser && shouldUserSeeRegionSelection && (
                    <RegionSelectionButton
                      sodUser={sodUser}
                      changeRegion={this.changeRegion}
                      showModal={showModal}
                      closeModal={closeModal}
                    />
                  )}
                </div>
                {shouldUserSeeRegionSelection && (
                  <Paragraph className={classNames.subheader}>
                    {selectedRegionNameToRender}
                  </Paragraph>
                )}
              </SubappHeader>

              {loading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  {!hasApprovalsToShow ? (
                    <NoPendingApprovalsView />
                  ) : (
                    flyers.map(flyer => (
                      <div className={classNames.row} key={flyer.fml}>
                        <div>
                          <ErrorBoundary>
                            <ApprovalCard
                              flyer={filter(
                                ApprovalCard.fragments.flyer,
                                flyer
                              )}
                              flyerReasons={filter(
                                ApprovalCard.fragments.flyerReasons,
                                flyerReasons
                              )}
                              sod={
                                gqlStuff.currentQueryVariables
                                  .PendingApprovals_sodUser_sod || sod
                              }
                              showModal={showModal}
                              closeModal={closeModal}
                              shouldUserSeeApproverNotesButton={
                                shouldUserSeeApproverNotesButton
                              }
                            />
                          </ErrorBoundary>
                        </div>
                      </div>
                    ))
                  )}

                  <div className="layout-row">
                    {hasApprovalsToShow && (
                      <div
                        className={`${classNames.submitNotification} layout-row flex`}
                      >
                        <p
                          className={`flex-90 ${classNames.submitNotificationText}`}
                        >
                          A decision to Approve, Hold, or Deny is required for{' '}
                          <strong>each flyer</strong> in order to submit.
                        </p>
                        <span className="flex-10">
                          <SubmitPublicationRequestReviewsButton
                            disabled={!isSubmitButtonEnabled}
                            sod={
                              gqlStuff.currentQueryVariables
                                .PendingApprovals_sodUser_sod || sod
                            }
                          >
                            Submit
                          </SubmitPublicationRequestReviewsButton>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </ModalConsumer>
      </RoleCheckWithRedirect>
    );
  }
}

PendingApprovals.fragments = {
  sodUser: gql`
    fragment PendingApprovals_sodUser on SodUser {
      # id
      permission {
        adminLevel
        approverLevel
      }
      approvalCenterRegions {
        id
        name
        districtId
      }
      flyers: pendingFlyerApprovalRequests(
        sod: $PendingApprovals_sodUser_sod
        sodId: $PendingApprovals_sodUser_sodId
      ) {
        fml: id
        ...ApprovalCard
      }
      ...RegionSelectionModal_sodUser
    }
    ${ApprovalCard.fragments.flyer}
    ${RegionSelectionModal.fragments.sodUser}
  `,
  flyerReasons: gql`
    fragment PendingApprovals_flyerReasons on FlyerReason {
      ...ApprovalCard_flyerReasons
    }
    ${ApprovalCard.fragments.flyerReasons}
  `,
};

const classNames = {
  header: css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  `,
  subheader: css`
    font-weight: normal;
  `,
  subappHeader: css`
    padding-top: 1.75rem !important;
  `,
  headerRow: css`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  `,
  row: css`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    &:first-child {
      margin-top: 0 !important;
    }
  `,
  submitNotification: css`
    background: #ebebeb;
    margin: 10px 0 60px 0;
    padding: 26px 40px;
  `,
  submitNotificationText: css`
    color: #707070;
    font-size: 15px;
    padding-top: 15px;
  `,
};

const mapStateToProps = (state): ReduxStateProps => ({
  flags: state.flags,
  numberOfStagedApprovals: getNumberOfStagedFlyers(state),
});

export default connect(mapStateToProps)(PendingApprovals);
