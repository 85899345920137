import generateSubappConfig from '../_app/generateSubappConfig';
import DistrictManagement from './DistrictManagement';
import { APP_ROUTES } from '../_app/constants';

const districtManagementSubappConfig = generateSubappConfig({
  path: APP_ROUTES.district,
  navItemConfig: {
    label: 'District',
  },
  appConfig: {
    Component: DistrictManagement,
  },
});

export default districtManagementSubappConfig;
