import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { css } from 'react-emotion';
import { get } from 'lodash';
import portalBffApolloClient from '../../../../../../../../_app/apollo/portalBFF.apolloClient.js';
import SEARCH_FOR_SCHOOLS_QUERY from '../gql/SearchForSchoolsQuery.graphql';
import OrgSchoolSearch from './Search';
import OrgSchoolSelector from './OrgSchoolSelector';
import {
  grades,
  gradeFilterDefault,
} from '../../../../../../../shared/constants';
import DistrictGuidelines from './DistrictGuidelines';
import PostOnlyBanner from '../../../../../../../Create-Campaign/components/PostOnlyBanner';
import {
  handleMapEdgeCoordinates,
  filterSchoolsByGradeRange,
  filterSchoolsByAudience,
  getSelectionsWithDistrictGuidelines,
} from './utils';
import { SCHOOL_FINDER_AUDIENCE_FILTER_DEFAULTS } from './Filters/constants';

const styles = {
  marginTop: css`
    margin-top: 2rem;
  `,
};

const OrgSearchForSchools = ({
  setDeliveryCache,
  eventLocation,
  selectedAudience,
  isCommunityFree,
  toggleSubsetOfCheckboxes,
  toggleCheckbox,
  setCheckboxSelections,
  checkboxSelections,
  onSelectionUpdated,
  minGradeLevel,
  maxGradeLevel,
  toggleDistrictGuidelinesConfirmed,
  areDistrictGuidelinesConfirmed,
  handleGuidelinesIsValid,
  shouldPerformDisabledClickValidation,
  hasCommFreeSchoolSelectionLimitError,
  communityFreeSchoolSelectionLimit,
}) => {
  const [map, setMap] = useState();
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [placeId, setPlaceId] = useState('');
  const [searchByValue, setSearchByValue] = useState('');
  const [gradeFilter, setGradeFilter] = useState({
    min: minGradeLevel,
    max: maxGradeLevel,
  });

  const renderPostOnlyBanner =
    Array.isArray(checkboxSelections) && checkboxSelections.length
      ? checkboxSelections.some(
          ({ district }) => district.isOptionalPostOnly || district.isPostOnly
        )
      : false;

  useEffect(() => {
    setGradeFilter({
      min: minGradeLevel,
      max: maxGradeLevel,
    });
  }, [minGradeLevel, maxGradeLevel]);

  function setGradeFilterAndUpdateCache(minMaxObj) {
    setGradeFilter(minMaxObj);
    Promise.all([
      setDeliveryCache({
        variables: {
          name: 'minGradeLevel',
          value: minMaxObj.min,
        },
      }),
      setDeliveryCache({
        variables: {
          name: 'maxGradeLevel',
          value: minMaxObj.max,
        },
      }),
    ]).then(() => {
      // Finished setting cached min and max grade levels
    });
  }
  const [audienceFilter, setAudienceFilter] = useState(
    SCHOOL_FINDER_AUDIENCE_FILTER_DEFAULTS
  );
  const [mapVariables, setMapVariables] = useState({
    center: { latitude: null, longitude: null },
    redoMapBounds: null,
  });
  const areFiltersSetToDefault =
    Object.keys(audienceFilter).every(
      key => audienceFilter[key] === SCHOOL_FINDER_AUDIENCE_FILTER_DEFAULTS[key]
    ) &&
    gradeFilter.min === gradeFilterDefault.min &&
    gradeFilter.max === gradeFilterDefault.max;

  const [initialMapBounds, setInitialMapBounds] = useState();
  const [previousRedoSearchZoom, setPreviousRedoSearchZoom] = useState(null);
  const areInitialMapBoundsSet = !!initialMapBounds;

  const buildQueryVariables = ({
    searchByValue,
    placeId,
    mapVariables,
    eventLocation,
  }) => {
    const { center, redoMapBounds } = mapVariables;
    let queryVariables = {};

    if (
      center.latitude &&
      center.longitude &&
      get(redoMapBounds, 'southWest.latitude') &&
      get(redoMapBounds, 'southWest.longitude') &&
      get(redoMapBounds, 'northEast.latitude') &&
      get(redoMapBounds, 'northEast.longitude')
    ) {
      queryVariables = {
        ...queryVariables,
        centerPoint: center,
        mapBounds: redoMapBounds,
      };
    }

    // if (searchValue) {
    //   queryVariables = { ...queryVariables, query: searchValue };
    // }

    if (placeId && searchByValue) {
      queryVariables = { ...queryVariables, placeId, searchBy: searchByValue };
    }

    // If no searchValue, but event location provided, assume initial search
    if (!placeId && eventLocation) {
      queryVariables = { ...queryVariables, eventLocation };
    }

    return queryVariables;
  };

  // Only using front end filtering by not passing a gradeFilter object similar
  // to:
  // gradeFilter: {
  //   min: minGradeLevel,
  //   max: maxGradeLevel
  // },
  // The min/maxGradeLevel's are now the persisted values from the Delivery tab's
  // grade level filter instead of coming from the Details tab. We can use these
  // variables in the SEARCH_FOR_SCHOOLS_QUERY query below but that would cause
  // a request to the server on every change of the filter
  const { loading, error, data } = useQuery(SEARCH_FOR_SCHOOLS_QUERY, {
    variables: buildQueryVariables({
      searchByValue,
      placeId,
      mapVariables,
      eventLocation,
    }),
    client: portalBffApolloClient,
    fetchPolicy: 'no-cache',
  });

  const searchForSchools = get(data, 'searchForSchools', []);
  const {
    formattedAddress = '',
    schools = [],
    radius,
    zoom,
    center,
  } = searchForSchools;

  // used to filter schools into view
  let schoolsWithinView = schools;

  if (initialMapBounds) {
    // handles the edges of the global map where -180 lng flips to 180
    const correctedInitialMapBounds = handleMapEdgeCoordinates(
      initialMapBounds
    );
    const { northEast, southWest } = correctedInitialMapBounds;
    const { latitude: maxLatitude, longitude: maxLongitude } = northEast;
    const { latitude: minLatitude, longitude: minLongitude } = southWest;

    schoolsWithinView = schools.filter(
      school =>
        school.latitude >= minLatitude &&
        school.latitude <= maxLatitude &&
        school.longitude >= minLongitude &&
        school.longitude <= maxLongitude
    );
  }

  const filteredSchoolsByGradeRange = filterSchoolsByGradeRange(
    schoolsWithinView,
    grades,
    gradeFilter
  );
  const filteredSchoolsByAudience = filterSchoolsByAudience(
    filteredSchoolsByGradeRange,
    audienceFilter
  );
  const filteredSchoolsWithinView = filteredSchoolsByAudience;

  const total = filteredSchoolsWithinView.length;
  const totalHiddenSchools =
    schoolsWithinView.length - filteredSchoolsWithinView.length;
  const totalNearbySchools = filteredSchoolsWithinView.filter(
    school => !school.isPin
  ).length;

  const selectionsWithDistrictGuidelines = getSelectionsWithDistrictGuidelines(
    checkboxSelections
  );

  return (
    <div className={styles.marginTop}>
      <OrgSchoolSearch
        inputValue={inputValue}
        setInputValue={setInputValue}
        total={initialMapBounds ? total : ''}
        formattedAddress={formattedAddress}
        setSearchByValue={setSearchByValue}
        searchByValue={searchByValue}
        placeId={placeId}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        setPlaceId={setPlaceId}
        setMapVariables={setMapVariables}
        gradeFilter={gradeFilter}
        audienceFilter={audienceFilter}
        setGradeFilter={setGradeFilterAndUpdateCache}
        setAudienceFilter={setAudienceFilter}
        map={map}
        center={center}
      />
      <div className="mt-3">
        <OrgSchoolSelector
          schools={filteredSchoolsWithinView}
          totalHiddenSchools={totalHiddenSchools}
          totalNearbySchools={totalNearbySchools}
          radius={radius}
          zoom={zoom}
          center={center}
          mapVariables={mapVariables}
          setMapVariables={setMapVariables}
          loading={loading}
          error={error}
          selectedAudience={selectedAudience}
          toggleSubsetOfCheckboxes={toggleSubsetOfCheckboxes}
          toggleCheckbox={toggleCheckbox}
          areInitialMapBoundsSet={areInitialMapBoundsSet}
          setInitialMapBounds={setInitialMapBounds}
          initialMapBounds={initialMapBounds}
          setCheckboxSelections={setCheckboxSelections}
          checkboxSelections={checkboxSelections}
          onSelectionUpdated={onSelectionUpdated}
          minGradeLevel={minGradeLevel}
          maxGradeLevel={maxGradeLevel}
          previousRedoSearchZoom={previousRedoSearchZoom}
          setPreviousRedoSearchZoom={setPreviousRedoSearchZoom}
          setSearchValue={setSearchValue}
          setInputValue={setInputValue}
          setGradeFilter={setGradeFilterAndUpdateCache}
          setAudienceFilter={setAudienceFilter}
          areFiltersSetToDefault={areFiltersSetToDefault}
          map={map}
          setMap={setMap}
          hasCommFreeSchoolSelectionLimitError={
            hasCommFreeSchoolSelectionLimitError
          }
          communityFreeSchoolSelectionLimit={communityFreeSchoolSelectionLimit}
        />
      </div>

      {!!selectionsWithDistrictGuidelines.length && (
        <>
          <DistrictGuidelines
            name="districtGuidelines"
            selectionsWithDistrictGuidelines={selectionsWithDistrictGuidelines}
            areDistrictGuidelinesConfirmed={areDistrictGuidelinesConfirmed}
            shouldPerformDisabledClickValidation={
              shouldPerformDisabledClickValidation
            }
            toggleDistrictGuidelinesConfirmed={
              toggleDistrictGuidelinesConfirmed
            }
            handleIsValid={handleGuidelinesIsValid}
          />
          {!isCommunityFree && renderPostOnlyBanner && <PostOnlyBanner />}
        </>
      )}
    </div>
  );
};

export default OrgSearchForSchools;
