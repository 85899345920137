import { useMutation } from '@apollo/react-hooks';
import SAVE_CAMPAIGN_MUTATION from '../campaigns/Create-Campaign/gql/SaveCampaignMutation.graphql';

const useSaveCampaignMutation = ({
  client,
  variables,
  update,
  onError,
  // onSuccess,
  // refetchQueries,
}) => {
  const [saveCampaign, { loading: saveCampaignLoading, error: saveCampaignError }] = useMutation(
    SAVE_CAMPAIGN_MUTATION,
    {
      client,
      variables,
      update,
      // refetchQueries,
      // awaitRefetchQueries: !!refetchQueries,
      onError,
      // onCompleted: onSuccess
    }
  );

  return {
    saveCampaign,
    saveCampaignLoading,
    saveCampaignError
  };
};

export default useSaveCampaignMutation;
