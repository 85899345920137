import { AsYouType } from 'libphonenumber-js';

export const PHONE_NUMBER_MAX_LENGTH = 13; // example: (858)998-3321

export function validateEmailFormat(email: string): boolean {
  const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return regex.test(email);
}

export function formatPhoneNumber(phoneNumber: string): string {
  return new AsYouType('US').input(phoneNumber);
}

export function validatePhoneNumber(phoneNumber: string) {
  const cleanPhoneNum = phoneNumber.replace(/\s|-|\(|\)|\./g, '');
  const phoneCode = /^(\d{1,3})(\d{0,3})(\d{0,4})$/;
  const match = cleanPhoneNum.match(phoneCode);

  return match;
}

export function validateWebsiteUrlFormat(url: string): boolean {
  const regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  return regex.test(url);
}

export function validateZipCode(zip: string): boolean {
  return /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/.test(zip);
}
