/* eslint-disable graphql/template-strings */
import gql from 'graphql-tag';

export const GET_MY_APPLICATION = gql`
  query getMyCommFreeApplication($id: ID) {
    getApplication(id: $id) {
      id
      schoolSelectionLimit
    }
  }
`;

export default GET_MY_APPLICATION;
