import * as React from 'react';
import { graphql, compose } from 'react-apollo';
import { get, omit, isEmpty } from 'lodash';
import { matchPath } from 'react-router-dom';
import { History } from 'react-router-dom';
import { css } from 'react-emotion';
import { colors } from '@peachjar/components';
import portalBffApolloClient from '../../../../_app/apollo/portalBFF.apolloClient';
import MutationButton from '../../../../components/MutationButton';
import { PREPARE_QUERY } from '../../Prepare/gql/Prepare.graphql';
import { DETAILS_QUERY } from '../../Details/gql/Details.graphql';
import { DELIVERY_QUERY } from '../../Delivery/gql/Delivery.graphql';
import { SUMMARY_QUERY } from '../../Summary/gql/Summary.graphql';
import SAVE_CAMPAIGN_MUTATION from '../../gql/SaveCampaignMutation.graphql';
import CREATE_DRAFT_CAMPAIGN_MUTATION from '../../Prepare/gql/CreateDraftCampaignMutation.graphql';
import {
  mapCampaignDetailsToGql,
  mapCampaignDeliveryToGql,
  mapCampaignSummaryToGql,
} from '../../gql/utils';
import { gradesToAges, gradeFilterDefault } from '../../../shared/constants';
import GradeLevels from '@peachjar/school-finder-api/dist/api/GradeLevels';
import {
  ctaPrimaryKey,
  ctaPrimaryCTADataKey,
} from '../../Details/components/CallsToAction/CallsToAction';

const { white } = colors;

const styles = {
  white: css`
    && {
      color: ${white};
      border-color: ${white};

      svg {
        stroke: ${white};
      }

      &:hover {
        color: ${white};
      }

      &:not([disabled]):not(.disabled):active {
        background-color: rgba(0, 0, 0, 0.26);
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24),
          0 0 8px 0 rgba(0, 0, 0, 0.12);
      }

      &:disabled,
      &[disabled],
      &.disabled {
        background-color: rgba(0, 0, 0, 0.26);
        color: rgba(255, 255, 255, 0.6);
        border: 0;
        opacity: 1;
      }
    }
  `,
};

type Props = {
  dataTestId: string;
  campaignId: string;
  mutation: string;
  userTexts: string[];
  children: React.ReactNode;
  campaignPrepare: Object;
  unmappedCampaignDetails: Object;
  unmappedCampaignDeliverySettings: Object;
  unmappedCampaignSummary: Object;
  handleError: () => any;
  handleSuccess: () => any;
  update: () => any;
  disabled: boolean;
  campaigns: Object;
  history: History;
};

export const FinishCampaignLaterButton = ({
  campaigns: remoteCampaigns,
  dataTestId,
  history,
  campaignPrepare,
  unmappedCampaignDetails,
  unmappedCampaignDeliverySettings,
  unmappedCampaignSummary,
  handleError,
  handleSuccess,
  update,
  children,
  disabled,
  ...rest
}: Props) => {
  const localCacheFlyerId = get(campaignPrepare, 'flyerId');
  const match = matchPath(history.location.pathname, {
    path: '/campaigns/create-campaign/:id',
  });

  const remoteCampaign = remoteCampaigns.find(
    campaign => campaign.id === match.params.id
  );
  const remoteCampaignId = remoteCampaign ? remoteCampaign.id : '';
  const primaryCTAType = localStorage.getItem(
    `${remoteCampaignId}-${ctaPrimaryKey}`
  );
  const primaryCTAData = localStorage.getItem(
    `${remoteCampaignId}-${ctaPrimaryCTADataKey}`
  );
  const { userTexts, flyerId } = campaignPrepare;
  const campaignDetails =
    unmappedCampaignDetails && mapCampaignDetailsToGql(unmappedCampaignDetails);
  const campaignDeliverySettings =
    unmappedCampaignDeliverySettings &&
    mapCampaignDeliveryToGql(unmappedCampaignDeliverySettings);
  const campaignSummary =
    unmappedCampaignSummary && mapCampaignSummaryToGql(unmappedCampaignSummary);

  const variables = {};

  const mergedCampaignDetails =
    primaryCTAType && primaryCTAData
      ? {
          ...campaignDetails,
          primaryCallToAction: {
            type: primaryCTAType,
            data: JSON.parse(primaryCTAData),
          },
        }
      : campaignDetails;

  if (remoteCampaign) {
    variables.campaignId = remoteCampaign.id;
    variables.campaignPrepare = { flyerId, userTexts };
    variables.campaignDetails = {
      ...mergedCampaignDetails,
      minGradeLevel: get(
        gradesToAges,
        unmappedCampaignDeliverySettings.minGradeLevel,
        gradesToAges[gradeFilterDefault.min]
      ),
      maxGradeLevel: get(
        gradesToAges,
        unmappedCampaignDeliverySettings.maxGradeLevel,
        gradesToAges[gradeFilterDefault.max]
      ),
    };
    variables.campaignDeliverySettings = campaignDeliverySettings;
    variables.campaignSummary = campaignSummary;
  } else {
    variables.flyerId = localCacheFlyerId;
  }

  return (
    <MutationButton
      dataTestId={dataTestId}
      buttonType="secondarySmall"
      className={styles.white}
      client={portalBffApolloClient}
      mutation={
        remoteCampaign ? SAVE_CAMPAIGN_MUTATION : CREATE_DRAFT_CAMPAIGN_MUTATION
      }
      variables={variables}
      handleSuccess={handleSuccess}
      handleError={handleError}
      disabled={disabled}
      // refetchQueries={() => ['getMyCampaigns']}
      {...rest}
    >
      {children}
    </MutationButton>
  );
};

const prepareConfig = {
  props: ({ ownProps, data: { campaignPrepare } }) => ({
    ...ownProps,
    campaignPrepare,
  }),
};

const detailsConfig = {
  props: ({ ownProps, data: { campaignDetails } }) => {
    return {
      ...ownProps,
      unmappedCampaignDetails: campaignDetails,
    };
  },
};

const deliveryConfig = {
  props: ({ ownProps, data: { campaignDelivery } }) => {
    // this is due to apollo link state not allowing district
    // within checkboxSelections to be null for some reason
    const cleanedCheckboxSelections =
      campaignDelivery &&
      campaignDelivery.checkboxSelections.map(checkboxSelection => {
        const district = get(checkboxSelection, 'district');
        return typeof district === 'object' && isEmpty(district)
          ? omit(checkboxSelection, 'district')
          : checkboxSelection;
      });

    return {
      ...ownProps,
      unmappedCampaignDeliverySettings: {
        ...campaignDelivery,
        checkboxSelections: cleanedCheckboxSelections,
      },
    };
  },
};

const summaryConfig = {
  props: ({ ownProps, data: { campaignSummary } }) => ({
    ...ownProps,
    unmappedCampaignSummary: campaignSummary,
  }),
};

export default compose(
  graphql(PREPARE_QUERY, prepareConfig),
  graphql(DELIVERY_QUERY, deliveryConfig),
  graphql(DETAILS_QUERY, detailsConfig),
  graphql(SUMMARY_QUERY, summaryConfig)
)(FinishCampaignLaterButton);
